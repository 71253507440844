import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal'
import { FaPlus, FaMinus } from "react-icons/fa";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import Link from '@material-ui/core/Link';
import { MdOutlineArrowDropDown } from "react-icons/md";

const useStyles = makeStyles((theme) => ({
    table: {
        width: '100%',
    },
    modalHeader: {
        borderBottom: 'none !important',
        paddingTop: '11px',
        paddingRight: '4px',
        marginTop: '-7px',
        width: '100%',
        fontFamily: 'verdana, helvetica',
        fontSize: '12px',
    },
    modalClassContent: {
        height: '30px',
        width: '200px',
        margin: '10px',
        fontSize: '14px',
        color: 'rgb(119, 119, 119)',
        fontFamily: 'verdana, helvetica',
        fontWeight: '400'
    },
    modalBody: {
        textAlign: 'center',
        marginBottom: '8px'
    },
    tableStyle: {
        position: 'absolute',
        marginLeft: '662px',
        backgroundColor: '#ffffff',
        border: '1px solid #b5b5b5'
    },
    onRowHover: {
        textAlign: 'left',
        '&:hover': {
            border: '1px solid #b5b5b5',
            backgroundColor: '#eeece1',
            color: 'black',
        },
    }
}));

const toolTipStyles = {
    tooltip: {
        width: "auto",
        fontSize: '12px',
        textAlign: 'justify',
        padding: "8px 14px 8px 14px",
        boxShadow: "0px 1px 7px #888",
        backgroundColor: '#777',
        color: '#FFF'
    }
};

const CustomTooltip = withStyles(toolTipStyles)(Tooltip);

const PnSearchGroupModal = (props) => {
    const classes = useStyles();
    const [startingValue, setStartingValue] = useState([{ field: 'All fields', field_type: 'string', operator: 'CT', value: '' }]);
    const [openSelection, setOpenSelection] = useState(false);
    const [inputValue, setInputValue] = useState('Ungrouped');
    const [numValue, setNumValue] = useState('3');
    const [groupClick, setGroupClick] = useState(false);
    const [resultClick, setResultClick] = useState(false);
    const [showResult, setShowResult] = useState(false);

    const widgetDelAtom = (idx) => {
        startingValue.splice(idx, 1);
        setStartingValue([...startingValue]);
    };
    const widgetAddAtom = (idx) => {
        const newElement = { field: 'All fields', field_type: 'string', operator: 'CONTAINS', value: '' };
        startingValue.splice(idx + 1, 0, newElement);
        setStartingValue([...startingValue]);
    };

    function handleSelection() {
        setOpenSelection(!openSelection);
    }

    function handleInputValue(value) {
        setInputValue(value);
        setOpenSelection(false);
        setGroupClick(true);
    }
    function handleShowResult() {
        setShowResult(!showResult);
    }
    function handleNumValue(value) {
        setNumValue(value);
        setShowResult(false);
        if (value === '1') {
            setResultClick(true);
        }
        else {
            setResultClick(false);
        }
    }

    return (
        <Modal
            id="modalCss"
            aria-labelledby="contained-modal-title-vcente"
            centered
            size="lg"
            show={props.showModal}
            contentClassName={classes.modalHeader}
        >
            <div>
                <Link href="#" onClick={(e) => e.preventDefault()} className="pull-right">
                    <CloseIcon style={{ fill: '#b5b5b5' }} onClick={props.closeModal} />
                </Link>
            </div>
            <Modal.Body className={classes.modalBody}>
                <div>
                    <form>
                        <table className={classes.table}>
                            <tbody>
                                <tr>
                                    <tr>
                                        <td>
                                            <label style={{ padding: '1px' }}><strong style={{color: 'black'}}>Group by&nbsp; &nbsp;</strong></label>
                                        </td>
                                        <td style={{ border: '1px solid #b5b5b5', padding: '1px 2px 5px 6px' }}>
                                            <span style={{marginRight: '5px'}} onClick={handleSelection}>{inputValue}</span>
                                            <MdOutlineArrowDropDown style={{ height: '25px', width: '21px', borderLeft: '1px solid #b5b5b5'}} onClick={handleSelection} />
                                        </td>
                                        {groupClick &&
                                            <div style={{ marginLeft: '12px', marginTop: '1px' }}>
                                                <td>
                                                    and show&nbsp;
                                                </td>
                                                <td style={{ border: '1px solid #b5b5b5', padding: '3px' }}>
                                                    <span style={{marginRight: '6px' , marginLeft: '6px'}} onClick={handleShowResult}>{numValue}</span>
                                                    <MdOutlineArrowDropDown style={{ height: '22px', width: '21px',borderLeft: '1px solid #b5b5b5' }} onClick={handleShowResult} />
                                                </td>
                                                {showResult && <td>
                                                    <table style={{ position: 'absolute', backgroundColor: '#ffffff', border: '1px solid #b5b5b5', marginTop: '28px' ,marginLeft: '-49px' }}>
                                                        <tbody>
                                                            <tr className={classes.onRowHover} onClick={() => handleNumValue('1')}>
                                                                <td>1</td>
                                                            </tr>
                                                            <tr className={classes.onRowHover} onClick={() => handleNumValue('3')}>
                                                                <td>3</td>
                                                            </tr>
                                                            <tr className={classes.onRowHover} onClick={() => handleNumValue('5')}>
                                                                <td>5</td>
                                                            </tr>
                                                            <tr className={classes.onRowHover} onClick={() => handleNumValue('ALL')}>
                                                                <td>ALL</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>}
                                                <td>
                                                    {!resultClick && <span>&nbsp;results per group.</span>}
                                                    {resultClick && <span>&nbsp;result per group.</span>}
                                                </td>
                                            </div>}
                                    </tr>
                                    {openSelection && <td>
                                        <table className={classes.tableStyle} style={{ zIndex: '1000', marginLeft: '-674px', marginTop: '17px' }}>
                                            <tbody>
                                                <tr className={classes.onRowHover} onClick={() => handleInputValue('Ungrouped')}>
                                                    <td>Ungrouped</td>
                                                </tr>
                                                <tr className={classes.onRowHover} onClick={() => handleInputValue('Organism')}>
                                                    <td>Organism</td>
                                                </tr>
                                                <tr className={classes.onRowHover} onClick={() => handleInputValue('database name')}>
                                                    <td>database name</td>
                                                </tr>
                                                <tr className={classes.onRowHover} onClick={() => handleInputValue('Keywords')}>
                                                    <td>Keywords</td>
                                                </tr>
                                                <tr className={classes.onRowHover} onClick={() => handleInputValue('Earliest priority number')}>
                                                    <td>Earliest priority number</td>
                                                </tr>
                                                <tr className={classes.onRowHover} onClick={() => handleInputValue('Normalized Patent Assignee')}>
                                                    <td>Normalized Patent Assignee</td>
                                                </tr>
                                                <tr className={classes.onRowHover} onClick={() => handleInputValue('Unique Family Sequence ID')}>
                                                    <td>Unique Family Sequence ID</td>
                                                </tr>
                                                <tr className={classes.onRowHover} onClick={() => handleInputValue('Normalized Parent')}>
                                                    <td>Normalized Parent</td>
                                                </tr>
                                                <tr className={classes.onRowHover} onClick={() => handleInputValue('Patent number')}>
                                                    <td>Patent number</td>
                                                </tr>
                                                <tr className={classes.onRowHover} onClick={() => handleInputValue('Patent family ID')}>
                                                    <td>Patent family ID</td>
                                                </tr>
                                                <tr className={classes.onRowHover} onClick={() => handleInputValue('Application number')}>
                                                    <td>Application number</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>}
                                </tr>
                                <div><tr>
                                    {!groupClick && <td><label style={{marginTop: '13px', color: 'black'}}><b>* Group Filtering only active when results are grouped</b></label></td>}
                                    {groupClick && <td> <label style={{marginTop: '13px', color: 'black'}}><b>Show only groups with</b></label></td>}
                                </tr>
                                </div>
                                <div>
                                    <tr>
                                        {startingValue.map((atom, idx) => (
                                            <tr key={idx}>
                                                <div>
                                                    <td>
                                                        <div className="control-spacer">
                                                            <select style={{ height: '30px', width: '200px', fontFamily: 'verdana, helvetica !important', fontSize: '15px', color: '#777777', fontWeight: '400' }}
                                                                value={atom.field}>
                                                                <optgroup label=''>
                                                                    <option value="SEQUENCE_VT_GSZ" label="Group size">Group size</option>
                                                                </optgroup>
                                                                <optgroup label='Identifiers'>
                                                                    <option value="SEQUENCE_AC" label="Accession">Accession</option>
                                                                    <option value="SEQUENCE_AN" label="Application number">Application number</option>
                                                                    <option value="SEQUENCE_ID" label="Identifier">Identifier</option>
                                                                    <option value="SEQUENCE_P4" label="PCT pub. no.">PCT pub. no.</option>
                                                                    <option value="SEQUENCE_PV" label="Patent Id">Patent Id</option>
                                                                    <option value="SEQUENCE_SI" label="Patent SEQ ID NO">Patent SEQ ID NO</option>
                                                                    <option value="SEQUENCE_PN" label="Patent Number">Patent Number</option>
                                                                    <option value="SEQUENCE_SV" label="Sequence version">Sequence version</option>
                                                                    <option value="SEQUENCE_PJ" label="Unique Family Sequence ID">Unique Family Sequence ID</option>
                                                                </optgroup>
                                                                <optgroup label='Text'>
                                                                    <option value="SEQUENCE_P1" label="Abstract">Abstract</option>
                                                                    <option value="SEQUENCE_CT" label="Claims">Claims</option>
                                                                    <option value="SEQUENCE_CC" label="Comments">Comments</option>
                                                                    <option value="SEQUENCE_DR" label="Database cross-references">Database cross-references</option>
                                                                    <option value="SEQUENCE_GNAME" label="Database name">Database name</option>
                                                                    <option value="SEQUENCE_DE" label="Description">Description</option>
                                                                    <option value="SEQUENCE_L2" label="Detailed legal status">Detailed legal status</option>
                                                                    <option value="SEQUENCE_PE" label="Earliest priority number">Earliest priority number</option>
                                                                    <option value="SEQUENCE_EM" label="Equivalents member type">Equivalents member type</option>
                                                                    <option value="SEQUENCE_PG" label="Extended Legal Status">Extended Legal Status</option>
                                                                    <option value="SEQUENCE_KW" label="Keywords">Keywords</option>
                                                                    <option value="SEQUENCE_PH" label="Legal Status National Phase">Legal Status National Phase</option>
                                                                    <option value="SEQUENCE_LS" label="Legal status">Legal status</option>
                                                                    <option value="SEQUENCE_PZ" label="Normalized Parent">Normalized Parent</option>
                                                                    <option value="SEQUENCE_PU" label="Normalized Patent Assignee">Normalized Patent Assignee</option>
                                                                    <option value="SEQUENCE_OS" label="Organism">Organism</option>
                                                                    <option value="SEQUENCE_OO" label="Organism (non-normalized name)">Organism (non-normalized name)</option>
                                                                    <option value="SEQUENCE_PA" label="Patent assignee">Patent assignee</option>
                                                                    <option value="SEQUENCE_PM" label="Patent family">Patent family</option>
                                                                    <option value="SEQUENCE_PB" label="Patent family ID">Patent family ID</option>
                                                                    <option value="SEQUENCE_PI" label="Patent inventors">Patent inventors</option>
                                                                    <option value="SEQUENCE_PS" label="Patent sequence location">Patent sequence location</option>
                                                                    <option value="SEQUENCE_PR" label="Priority info">Priority info</option>
                                                                    <option value="SEQUENCE_RA" label="Reference author">Reference author</option>
                                                                    <option value="SEQUENCE_RL" label="Reference location">Reference location</option>
                                                                    <option value="SEQUENCE_PQ" label="Seq. listing equivalents">Seq. listing equivalents</option>
                                                                    <option value="SEQUENCE_PK" label="Sequence Note">Sequence Note</option>
                                                                    <option value="SEQUENCE_OX" label="Taxonomy ID">Taxonomy ID</option>
                                                                    <option value="SEQUENCE_PT" label="Title">Title</option>
                                                                    <option value="SEQUENCE_L3" label="US PAIR legal status">US PAIR legal status</option>
                                                                </optgroup>
                                                                <optgroup label="Dates">
                                                                    <option value="SEQUENCE_D2" label="Date of entry">Date of entry</option>
                                                                    <option value="SEQUENCE_D4" label="Earliest priority date">Earliest priority date</option>
                                                                    <option value="SEQUENCE_D5" label="Earliest pub. date">Earliest pub. date</option>
                                                                    <option value="SEQUENCE_P5" label="PCT pub. date">PCT pub. date</option>
                                                                    <option value="SEQUENCE_P8" label="PCT related dates">PCT related dates</option>
                                                                    <option value="SEQUENCE_D3" label="Patent filing date">Patent filing date</option>
                                                                    <option value="SEQUENCE_D1" label="Publication date">Publication date</option>
                                                                </optgroup>
                                                                <optgroup label="Sequence Properties">
                                                                    <option value="SEQUENCE_L" label="Length">Length</option>
                                                                    <option value="SEQUENCE_MT" label="Molecule type">Molecule type</option>
                                                                </optgroup>
                                                                <optgroup label="Other">
                                                                    <option value="SEQUENCE_PO" label="Claimed SEQ ID">Claimed SEQ ID</option>
                                                                    <option value="SEQUENCE_FM" label="Family size">Family size</option>
                                                                    <option value="SEQUENCE_FH" label="Feature header">Feature header</option>
                                                                    <option value="SEQUENCE_FT" label="Feature table">Feature table</option>
                                                                    <option value="SEQUENCE_HL" label="Hyperlinks coding">Hyperlinks coding</option>
                                                                    <option value="SEQUENCE_HO" label="Nb. claimed seqs">Nb. claimed seqs</option>
                                                                    <option value="SEQUENCE_P9" label="Number of SEQ (nuc/prot)">Number of SEQ (nuc/prot)</option>
                                                                    <option value="SEQUENCE_P7" label="Number of claims">Number of claims</option>
                                                                    <option value="SEQUENCE_PC" label="Patent classification">Patent classification</option>
                                                                    <option value="SEQUENCE_KC" label="Patent kind code">Patent kind code</option>
                                                                    <option value="SEQUENCE_SQ" label="Sequence information">Sequence information</option>
                                                                </optgroup>
                                                            </select>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <select className={classes.modalClassContent}>
                                                            <option value="LTE" label="less than or equal to">less than or equal to</option>
                                                            <option value="LT" label="less than">less than</option>
                                                            <option value="EQ" label="equals">equals</option>
                                                            <option value="GT" label="greater than">greater than</option>
                                                            <option value="GTE" label="greater than or equal to">greater than or equal to</option>
                                                        </select>
                                                    </td>
                                                    <td>
                                                        <div><input style={{ width: '200px', height: '30px' }}></input></div>
                                                    </td>
                                                    <td className={"filter-widget-adddel "}>
                                                        {startingValue.length > 1 &&
                                                            <CustomTooltip arrow title="Remove this Condition" placement="bottom">
                                                                <div className="control-spacer plus-min-button custom-filter-label"
                                                                    onClick={() => widgetDelAtom(idx)}>
                                                                    <FaMinus />
                                                                </div>
                                                            </CustomTooltip>
                                                        }
                                                    </td>
                                                    <td className={"filter-widget-adddel "}>
                                                        <CustomTooltip arrow title="Add Condition below" placement="bottom">
                                                            <div className="control-spacer plus-min-button custom-filter-label"
                                                                onClick={() => widgetAddAtom(idx)}>
                                                                <FaPlus />
                                                            </div>
                                                        </CustomTooltip>
                                                    </td>
                                                </div>
                                            </tr>
                                        ))}
                                    </tr>
                                </div>
                                <tr>
                                    <td colSpan="2" className="clearfix">
                                        <div className="clearfix filter-widget-bottom">
                                            <button className="pull-right normal-button gqblue">Apply</button>
                                            <button className="pull-right normal-button grey">Reset</button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </form>
                </div>
            </Modal.Body>
        </Modal >
    );
};

export default PnSearchGroupModal;