import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import Link from '@material-ui/core/Link';
import { ModalBody } from 'react-bootstrap';

const useStyles = makeStyles((theme) => ({
	modalClassConDSI: {
		position: 'absolute',
		width: '96%',
		padding: '35px 15px 0px',
		right: 'auto',
		bottom: 'auto',
	},
	modalBoxContent: {
		maxHeight: '600px',
	},
	modalText: {
		position: 'absolute',
		top: '10px',
		right: '10px'
	},
}));

const DiscoverExportVmModal = props => {
	const classes = useStyles();

	const callback = () => {
		props.actionModal(props.fieldConfig, props.filter, props.resInfo, props.downloadType, true);
	}

	const closeModal = () => {
		props.actionModal(props.fieldConfig, props.filter, props.resInfo, props.downloadType, false);
	}

	return (
		<Modal
			size="lg"
			aria-labelledby="contained-modal-title-vcente"
			centered
			show={props.showModal}
			//contentClassName={classes.modalClassConDSI}
		>
			<Link href="#" onClick={(e) => e.preventDefault()} className={classes.modalText}>
				<CloseIcon onClick={closeModal} />
			</Link>
			<Modal.Body>
			<div className="jumbotron appTextColor">
				<h1>Export to {props.downloadType}</h1>
				{(props.downloadType === 'Word' || props.downloadType === 'Word Simple' || props.downloadType === 'HTML') &&
					<p className="mb-3">
						Do you want to include Variation filter graphics or Variation hits (limit 500 alignments)?
					</p>
				}
				{(props.downloadType === 'EXCEL' || props.downloadType === 'CSV' || props.downloadType === 'TSV') &&
					<p className="mb-3">
						Do you want to include Alignment Variations hits (limit 500 alignments)?
					</p>
				}
				<br></br>
				<div style={{padding: '10px'}}>
					<button onClick={closeModal} className="pull-right large-button grey">No</button>
					<button onClick={callback} className="pull-right large-button gqblue">Yes</button>
				</div>
			</div>
			</Modal.Body>
		</Modal>
	);
}

export default DiscoverExportVmModal;
