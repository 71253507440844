import _ from "lodash";

const colors = ['#337ab7', '#98C747', '#db862c', '#c74848', '#ebd023', '#a843a8', '#64B5F6', '#26A69A', '#7E57C2', '#607D8B'];
const groups = ['RI', 'RIQ', 'RID'];
const yAxis = [
    {value: 'ALI', label: 'Alignments'},
    {value: 'PN',  label: 'Patent Numbers'},
    {value: 'PF',  label: 'Patent Families'},
    {value: 'UFS', label: 'Unique Family Sequences'},
    {value: 'SSV', label: 'Subject Sequences'}];
const labels = ['<65%', '>65%-70%', '>70%-75%', '>75%-80%', '>80%-85%', '>85%-90%', '>90%-95%', '>95%-99.99%', '100%'];
const xAxis = [
    {value: '65', label: '<65%'},
    {value: '70', label: '>65%-70%'},
    {value: '75', label: '>70%-75%'},
    {value: '80', label: '>75%-80%'},
    {value: '85', label: '>80%-85%'},
    {value: '90', label: '>85%-90%'},
    {value: '95', label: '>90%-95%'},
    {value: '99', label: '>95%-99.99%'},
    {value: '100', label: '100%'}];
const relGroups = [
    {value: 'PN', label: 'Patent Number hits'},
    {value: 'PF', label: 'Patent Family hits'},
    {value: 'SSV', label: 'Subject Sequence hits'}];

// configure doughnut charts
const doughnutCharts = [
    {
        value: 'SUBJECT_PG',
        full: {ctx: 'statusFacetChart', label: 'Legal Status'},
        thumb: {ctx: 'statusFacetChart_thumb', label: 'Legal Status'}
    },
    {
        value: 'SUBJECT_PS',
        full: {ctx: 'PslFacetChart', label: 'Patent Sequence Location'},
        thumb: {ctx: 'PslFacetChart_thumb', label: 'Seq.Location'}
    },
    {
        value: 'SUBJECT_PN',
        full: {ctx: 'authFacetChart', label: 'Patent Authorities'},
        thumb: {ctx: 'authFacetChart_thumb', label: 'Patent Authorities'}
    }
];

function drawNow(facetData, querySeq) {
    let chartObjects = {};
    _.forEach(groups, function (key) {
        let obj = {};
        // prepare data for RI/RIQ/RID trend chart
        _.forEach(yAxis, function (y) {
             obj[y.value] = [];
            _.forEach(xAxis, function (x) {
                let mapKey = y.value + '_' + key + '_' + x.value;

                let val = 0;
                if (facetData[querySeq][mapKey]) {
                    val = parseInt(facetData[querySeq][mapKey]['TOTAL']['TOTAL']);
                }
                //obj[y.value].push({x: parseInt(x.value), y: val});
                //obj[y.value].push({x: parseInt(x.value), y: val, xLabel: x.label});
                obj[y.value].push(val);
            });
        });

        // Draw charts
        if (key === 'RI') {
            drawDateTrendChart(chartObjects, 'riTrendChart', 'Number of Documents over %Identity of Alignment', 'full', obj);
        } else if (key === 'RIQ') {
            drawDateTrendChart(chartObjects, 'riqTrendChart', 'Number of Documents over %Identity of Query', 'full', obj);
        } else {
            drawDateTrendChart(chartObjects, 'ridTrendChart', 'Number of Documents over %Identity of Subject', 'full', obj);
        }
   });
   return chartObjects;
}

function drawSubCharts(facetData, querySeq, key) {
    let chartObjects = {};
    if (!facetData[querySeq][key]) {
        return chartObjects;
    }
    let subData = {};
    let facet = facetData[querySeq][key];
    // draw doughnut charts using config above
    for (let i = 0; i < doughnutCharts.length; i++) {
        formatDoughnutChartData(subData, facet, doughnutCharts[i].value);
        drawDoughnutChart(
            chartObjects,
            doughnutCharts[i].full.ctx,
            subData[doughnutCharts[i].value],
            doughnutCharts[i].full.label,
            'full'
        );
    }
    return chartObjects;
}

function formatDoughnutChartData(subData, facet, field) {
    let idx = 1, count = 0;
    subData[field] = {values: [], labels: []};
    if (facet && facet[field]) {
        // Sort the array first by count first
        let objects = Object.fromEntries(Object.entries(facet[field]).sort((a, b) => parseInt(b[1]) - parseInt(a[1])));
        _.each(objects, function (val, key) {
            if (key) {
                if (idx >= 10) {
                    // more than 9 entries, count the remainder as "Other"
                    count += parseInt(val);
                } else if (val > 0) {
                    subData[field].values.push(val);
                    //subData[field].labels.push(key.substring(0, 26));
                    subData[field].labels.push(key);
                }
                idx++;
            }
        });
    }
    if (idx >= 10 && count > 0) {
        // add the "Other" category
        subData[field].values.push(count);
        subData[field].labels.push("Other");
    }
}

function drawDoughnutChart(chartObjects, ctx, data, label, size) {
    let chartSetup = {
        options: {
            animation: {duration: 0},
            cutout: '46%',
            responsive: false,
            //aspectRatio: 1,
            maintainAspectRatio: false,
            plugins:{
                legend: {
                    display: false,
                    //position: 'right',
                    labels: {
                        boxWidth: 25,
                        boxHeight: 15,
                        font:{ size: 14}
                    }
                },
                title: {
                    display: true,
                    font: { weight: ((size === 'full') ? 'bold' : 'normal'),
                            size: (size === 'full') ? 18 : 14
                            },
                    text: label,
                    padding: ((size === 'full') ? 18 : 8)
                },
                tooltip: {enabled: (size === 'full')},
                datalabels: {
                  display: true,
                  anchor: 'end',
                  align: 'end',
                  offset: '0',
                  color: 'black',
                  formatter: (value) => {
                    return value > 0 ? value : '';
                  }
                }
            },
            scales: {
              x: {
                  grid: { display: false },
              },
              y: {
                  grid: { display: true },
              }
            },
            barThickness: 30
        },
        data: {
            labels: data.labels,
            datasets: [{
                data: data.values,
                backgroundColor: colors,
                hoverBackgroundColor: colors,
                hoverBorderWidth: 2,
                borderWidth: 2,
                hoverBorderColor: '#FFF',
                borderColor: '#FFF'
            }]
        }
    };
    chartObjects[ctx] = [ctx, chartSetup];
}

function drawDateTrendChart(chartObjects, ctx, label, size, dataObj) {
    // set up the chart
    let chartSetup = {
        data: {
            labels: labels,
            datasets: []
        },
        options: {
            plugins:{
                tooltip: {enabled: (size === 'full')},
                legend: {
                    display: (size === 'full'),
                    position: 'bottom',
                    labels: {'boxWidth': 20}
                },
                title: {
                    display: true,
                    font:{
                            weight: ((size === 'full') ? 'bold' : 'normal'),
                            size: (size === 'full') ? 18 : 14
                    },
                    text: label
                },
                datalabels: {
                  display: false,
                  color: 'black',
                  formatter: (value) => {
                    return value > 0 ? value : '';
                  }
                }
            },
            responsive: false,
            animation: { duration: 0 },
            maintainAspectRatio: false,
            elements:{
                line:{tension: 0.4}
            },
            scales: {
                x: {
                    //type: 'linear', // This setting will default it to be integer values
                    position: 'bottom',
                    grid: { display: false },
                    offset: true,
                    ticks: {
                       display:(size==='full'),
                       //min: 65, max: 105, stepSize: 5,
                        /*callback: (value, index) => {
                            let label = value;
                            if (xAxis[index]) {
                                label = xAxis[index].label;
                            } else {
                                label = '';
                            }
                            return label;
                        }*/
                    },
                    fontSize: 8
                },
                y: {
                    type: 'linear',
                    position: 'left',
                    ticks: { display:(size==='full') },
                    grid: { display: true },
                }
            }
        }
    };

    // insert the data
    let count = 0;
    _.forEach(yAxis, function (y) {
        chartSetup.data.datasets.push(getXyScatterPlotDataSet(y.value, y.label, colors[count++], size, dataObj));
    });
    chartObjects[ctx] = [ctx, chartSetup];
}

function getXyScatterPlotDataSet(field, label, color, size, dataObj) {
    //console.log("dataObj[field]:" + dataObj[field]);
    return {
        label: label,
        backgroundColor: color,
        borderColor: color,
        pointBorderColor: color,
        pointBackgroundColor: color,
        pointStyle: 'circle',
        fill: false,
        borderWidth: (size==='full' ? 2 : 1),
        pointBorderWidth: 1,
        pointRadius: (size==='full' ? 3 : 1),
        pointHoverRadius: (size==='full' ? 3 : 1),
        data: dataObj[field],
        //parsing: { xAxisKey: 'xLabel', yAxisKey: 'y' },
    };
}

function drawVennCharts(facetData, allSeqs, selectedSeqs) {
    let chartObjects = {};
    if (!selectedSeqs || selectedSeqs.length < 2) {
        return chartObjects;
    }

    let vennLabels = [];
    vennLabels.push(selectedSeqs[0]);
    vennLabels.push(selectedSeqs[1]);
    if (selectedSeqs.length === 3) {
        vennLabels.push(selectedSeqs[2]);
    }
    vennLabels.push(selectedSeqs[0] + " & " + selectedSeqs[1]);
    if (selectedSeqs.length === 3) {
        vennLabels.push(selectedSeqs[0] + " & " + selectedSeqs[2]);
        vennLabels.push(selectedSeqs[1] + " & " + selectedSeqs[2]);
        vennLabels.push(selectedSeqs[0] + " & " + selectedSeqs[1] + " & " + selectedSeqs[2]);
    }

    let relData = {};
    // draw venn charts
    for (let i = 0; i < relGroups.length; i++) {
        let group = relGroups[i].value;
        relData[group] = {
            label: relGroups[i].label,
            data: []
        };

        let chartSetup = {
          type: 'venn',
          data: {
            labels: vennLabels,
            datasets: [],
          },
          options: {
            backgroundColor: colors,
            animation: {duration: 0},
            responsive: false,
            maintainAspectRatio: false,
            //ticks: {color: 'white'},
            scales: {
              x: { // Control the venn intersections display
                  ticks: {color: 'black'}},
              y: { // Control the venn outside display (sequence IDs)
                  ticks: {display: false}},
            },
            plugins:{
                datalabels: {display: false},
                title: {
                  display: true,
                  text: relGroups[i].label,
                  font: {
                    size: 20,
                    //style: 'italic',
                    //family: 'Helvetica Neue'
                  }
                },
                tooltip: {enabled: true},
                legend: {display: false},
            },
          },
        };

        // All sequences selected
        if (selectedSeqs.length === allSeqs.length) {
            _.each(facetData[group], function (val, key) {
                let obj = {
                    //sets: key,
                    value: Number(val)
                };
                relData[group].data.push(obj);
            });
        } else {
            // 2 out of 3 sequences selected, re-calculate values
            if (selectedSeqs.includes(allSeqs[0])) {
                // P1 & P2
                if (selectedSeqs.includes(allSeqs[1])) {
                    relData[group].data.push({value: Number(facetData[group].P1) + Number(facetData[group].P13)});
                    relData[group].data.push({value: Number(facetData[group].P2) + Number(facetData[group].P23)});
                    relData[group].data.push({value: Number(facetData[group].P12) + Number(facetData[group].P123)});
                } else { // P1 & P3
                    relData[group].data.push({value: Number(facetData[group].P1) + Number(facetData[group].P12)});
                    relData[group].data.push({value: Number(facetData[group].P3) + Number(facetData[group].P23)});
                    relData[group].data.push({value: Number(facetData[group].P13) + Number(facetData[group].P123)});
                }
            } else {
                // P2 & P3
                relData[group].data.push({value: Number(facetData[group].P2) + Number(facetData[group].P12)});
                relData[group].data.push({value: Number(facetData[group].P3) + Number(facetData[group].P13)});
                relData[group].data.push({value: Number(facetData[group].P23) + Number(facetData[group].P123)});
            }
        }
        chartSetup.data.datasets.push(relData[group]);
        chartObjects[group + "_VENN"] = [group + "_VENN", chartSetup];
    }
    return chartObjects;
}

const ResultReportChartService = {
    drawNow,
    drawSubCharts,
    drawVennCharts,
    xAxis,
    yAxis,
};

export default ResultReportChartService;
