import React, {useState, useEffect, useRef} from 'react';
import {useHistory, useParams, Link} from 'react-router-dom';
import Button from "@material-ui/core/Button";
import CloseIcon from '@material-ui/icons/Close';
import {makeStyles} from "@material-ui/core/styles";
import { toast } from "react-toastify";
import _ from "lodash";
import DiscoverService from "../../../services/discoverService";
import ResultReportChartService from './ResultReportChartService';
import {Chart as ChartJs} from 'chart.js';
import 'chart.js/auto';
import { Chart, getElementAtEvent } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { VennDiagramController, ArcSlice } from 'chartjs-chart-venn';
import { Checkbox } from '@material-ui/core';
import DropDownBox from '../../../shared/Fields/DropDownBox';
import SelectBox from '../../../shared/Fields/SelectBox';
import ReactHtmlParser from 'react-html-parser';

const useStyles = makeStyles((theme) => ({

    loaderHide: {
        display: 'none'
    },

    loader :{
        position: 'fixed',
        top: '0',
        left: '0',
        width: '100%',
        height:' 100%',
        zIndex: '999999',
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
        display: 'block',

    },
    loaderPosition:{
        marginLeft: '602px',
        marginTop: '300px',
    },
    /*
    textField: {
        width: '400px',
        padding: '10px 0px',
        margin: '-8px 0 0 -5px'
      }*/
}));


const group_items = [
    {value: 'RIQ', label: 'Query'},
    {value: 'RI', label: 'Alignment'},
    {value: 'RID', label: 'Subject'}];
const xAxis_items = [
    {value: 'TOTAL', label: 'All'},
    {value: '65', label: '<65%'},
    {value: '70', label: '>65%-70%'},
    {value: '75', label: '>70%-75%'},
    {value: '80', label: '>75%-80%'},
    {value: '85', label: '>80%-85%'},
    {value: '90', label: '>85%-90%'},
    {value: '95', label: '>90%-95%'},
    {value: '99', label: '>95%-99.99%'},
    {value: '100', label: '100%'}];

function ResultReport() {
    const { resid, reportName } = useParams();
    const history = useHistory();
    const classes = useStyles();
    const [gqApiKey, setGqApiKey] = useState('');
    const [showLoader, setShowLoader] = useState(false);
    //const [reportFilters, setReportFilters] = useState({});
    const [dataMap, setDataMap] = useState({});
    const [relMap, setRelMap] = useState({});
    const [chartObj, setChartObj] = useState({});
    const [subChartObj, setSubChartObj] = useState({});
    const [vennChartObj, setVennChartObj] = useState({});
    const [sequences, setSequences] = useState([]);
    const [selectedSeq, setSelectedSeq] = useState('');
    const [selectedPoint, setSelectedPoint] = useState('');
    const riChartRef = useRef(null);
    const riqChartRef = useRef(null);
    const ridChartRef = useRef(null);
    const [grouping, setGrouping] = useState('');
    const [xAxis, setXAxis] = useState('');
    const [yAxis, setYAxis] = useState('');
    const [totalCount, setTotalCount] = useState('');
    const [allSeqs, setAllSeqs] = useState([]);
    const [checkedSeqs, setCheckedSeqs] = useState([]);

    // Workflow information
    const [workflowInfo, setWorkflowInfo] = useState("");
    const [filters, setFilters] = useState("");
    const [vmFilters, setVmFilters] = useState("");
    const [groupBy, setGroupBy] = useState("");
    const [collName, setCollName] = useState("");

    //save and print
    const [isSaveEnabled, setIsSaveEnabled] = useState(false);
    const [openSaveUI, setOpenSaveUI] = useState(false);
    const [saveIsSubmited, setSaveIsSubmited] = useState(false);
    const [userNamedReport, setUserNamedReport] = useState("");
    const [saveFilters, setSaveFilters] = useState({})
    //const [saveAuth, setSaveAuth] = useState({});

    useEffect(() => {
        ChartJs.register(ChartDataLabels, VennDiagramController, ArcSlice);
        document.title = 'GenomeQuest - Discover Result Report';
        setShowLoader(true);
        console.log("Result Report ID:" + resid + ", Name:" + reportName);
        // Fetch gqApiKey and check the access
        let workflowId = 'wf:' + resid + '.resdb';
        async function getAuthInfo() {
            let response = await DiscoverService.getAuthInfo(workflowId, history);
            let userId;
            if (response && response.response_content) {
                if (response.response_content.type === 'GQEXCEPTION') {
                    alert(response.response_content.message);
                    setShowLoader(false);
                    return;
                }

                setGqApiKey(response.response_content.gqApiKey);
                let apiKey = response.response_content.gqApiKey;
                let workflowDir = response.response_content.userdir;
                userId = workflowDir.replace('workflow/' + workflowId + '/results.res', '');

                if (reportName !== undefined && !_.isEmpty(reportName)) {
                    // Load the existing report
                    let data = {
                        workflowId: resid,
                        userId: userId,
                        reportName: reportName
                    }
                    response = await DiscoverService.loadResultReport(data, apiKey);
                } else {
                    // Create new report
                    setIsSaveEnabled(true);
                    let filters = localStorage.getItem(resid + '_reportFilters');
                    if (!filters || filters === '{}') {
                        filters = {
                           workflowId: resid,
                           userId: userId
                       }
                    } else {
                        filters = JSON.parse(filters);
                    }
                    setSaveFilters(filters);
                    response = await DiscoverService.createResultReport(filters, apiKey);

                    // Clear the storage
                    localStorage.removeItem(resid + '_reportFilters');
                }

                if (response) {
                    if (response.status === 'error') {
                        toast.error(response.error.message);
                    } else {
                        // Draw charts
                        let items = [];
                        _.forEach(response.sequences, function (seq) {
                            let obj = {};
                            obj.value = seq;
                            obj.label = seq;
                            items.push(obj);
                        });
                        setSequences(items);
                        setSelectedSeq(response.sequences[0]);
                        setDataMap(response.statsMap);
                        setRelMap(response.relMap);
                        setChartObj(ResultReportChartService.drawNow(response.statsMap, response.sequences[0]));
                        setWorkflowInfo(response.workflowInfo);
                        setFilters(response.filtersDesc);
                        setVmFilters(response.vmFiltersDesc);
                        setGroupBy(response.grouping);
                        setCollName(response.collName);

                        // Draw default sub charts
                        setGrouping('RIQ');
                        setXAxis('TOTAL');
                        setYAxis(ResultReportChartService.yAxis[0].value);
                        let key = ResultReportChartService.yAxis[0].value + '_RIQ_TOTAL';
                        setSubChartObj(ResultReportChartService.drawSubCharts(response.statsMap, response.sequences[0], key));
                        if (response.statsMap[response.sequences[0]][key]) {
                            setTotalCount(response.statsMap[response.sequences[0]][key]['TOTAL']['TOTAL']);
                        } else {
                            setTotalCount('0');
                        }
                        setSelectedPoint(key);

                        // Draw Venn diagrams when total sequences == 2 or == 3
                        if (response.sequences && response.sequences.length > 1 && response.sequences.length < 4 && response.relMap) {
                            setAllSeqs(response.sequences);
                            setVennChartObj(ResultReportChartService.drawVennCharts(response.relMap, response.sequences, response.sequences));
                            setCheckedSeqs(response.sequences);
                        }
                    }
                    setShowLoader(false);
                }
            }
        }
        getAuthInfo();

        const timer = setInterval(() => {
            (async () => {
                await callGQApi(workflowId);
            })();
        }, 882000); // every 14.7 mins as the api token is valid for 15 mins

        // To fix the charts disappear issue in Chrome, https://issues.chromium.org/issues/328755781
        document.addEventListener("visibilitychange", function () {
          if (document.visibilityState === "visible") {
            for (let i = 0; i < 9; i++) {
                const context = document.getElementById('chart' + i)?.getContext("2d");
                if (context) {
                    context.fillStyle = "transparent";
                    context.fillRect(0, 0, 1, 1);
                }
            }
          }
        });

        return () => {
            clearInterval(timer);
        };
    }, []);

    async function callGQApi(workflowId) {
        let api = await DiscoverService.getApi(workflowId, history);
        if (api && api.response_content) {
            if (api.response_content.type === 'GQEXCEPTION') {
                alert(api.response_content.message);
                return;
            }

            setGqApiKey(api.response_content.gqApiKey);
        }
    }

    const handleSequence = (event) => {
        if (event.target.value !== selectedSeq) {
            setChartObj(ResultReportChartService.drawNow(dataMap, event.target.value));
            setSelectedSeq(event.target.value);
            //setSelectedPoint('');

            // Draw default sub charts
            let key = yAxis + '_' + grouping + '_' + xAxis;
            setSubChartObj(ResultReportChartService.drawSubCharts(dataMap, event.target.value, key));
            if (dataMap[event.target.value][key]) {
                setTotalCount(dataMap[event.target.value][key]['TOTAL']['TOTAL']);
            } else {
                setTotalCount('0');
            }
            setSelectedPoint(key);
        }
    }

    const clickPoint = (event, group) => {
        setGrouping(group);
        let ref;
        if (group === 'RI') {
            //setGrouping('Number of Documents over %Identity of Alignment');
            ref = riChartRef.current;
        } if (group === 'RIQ') {
           //setGrouping('Number of Documents over %Identity of Query');
           ref = riqChartRef.current;
        } else {
            //setGrouping('Number of Documents over %Identity of Subject');
            ref = ridChartRef.current;
        }
        let firstPoint = getElementAtEvent(ref, event)[0];
        if (firstPoint && firstPoint.datasetIndex >= 0 && firstPoint.index >= 0) {
            setXAxis(ResultReportChartService.xAxis[firstPoint.index].value);
            setYAxis(ResultReportChartService.yAxis[firstPoint.datasetIndex].value);
            let key = ResultReportChartService.yAxis[firstPoint.datasetIndex].value + '_' + group
                        + '_' + ResultReportChartService.xAxis[firstPoint.index].value;
            setSubChartObj(ResultReportChartService.drawSubCharts(dataMap, selectedSeq, key));
            if (dataMap[selectedSeq][key]) {
                setTotalCount(dataMap[selectedSeq][key]['TOTAL']['TOTAL']);
            } else {
                setTotalCount('0');
            }
            setSelectedPoint(key);
        } /*else {
            setSelectedPoint('');
        }*/
    }

    const changeSelection = (e, type, grouping, xAxis, yAxis) => {
        let key = "";
        if (type === 'xAxis' && e.target.value !== xAxis) {
            setXAxis(e.target.value);
            key = yAxis + '_' + grouping + '_' + e.target.value;
        } else if (type === 'yAxis' && e.target.value !== yAxis) {
            setYAxis(e.target.value);
            key = e.target.value + '_' + grouping + '_' + xAxis;
        } else if (type === 'grouping' && e.target.value !== grouping) {
            setGrouping(e.target.value);
            key = yAxis + '_' + e.target.value + '_' + xAxis;
        }
        if (key !== "") {
            setSubChartObj(ResultReportChartService.drawSubCharts(dataMap, selectedSeq, key));
            if (dataMap[selectedSeq][key]) {
                setTotalCount(dataMap[selectedSeq][key]['TOTAL']['TOTAL']);
            } else {
                setTotalCount('0');
            }
            setSelectedPoint(key);
        }
    }

    /*function print(divName) {
        const printContents = document.getElementById(divName).innerHTML;
        if (navigator.userAgent.toLowerCase().indexOf('chrome') > -1) {
            const popupWin = window.open('', '_blank', 'width=600,height=600,scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no');
            popupWin.window.focus();
            popupWin.document.write('<!DOCTYPE html><html><body onload="window.print()"><div style="width: 900px">' + printContents + '</div></body></html>');
            popupWin.document.close();
            popupWin.onbeforeunload = function (event) {
                popupWin.close();
                return '.\n';
            };
            popupWin.onabort = function (event) {
                popupWin.close();
            }
        } else {
            const popupWin = window.open('', '_blank', 'width=800,height=600');
            popupWin.document.open();
            popupWin.document.write('<html><body onload="window.print()" style="width: 900px">' + printContents + '</body></html>');
            popupWin.document.close();
        }
    }*/

    const changeOpenSaveState = () =>{
        setOpenSaveUI(!openSaveUI);
    }

    function saveReportName(event){
        if(event != null){
            if(event["target"]["value"] != null || event["target"]["value"] === ""){
                setUserNamedReport(event["target"]["value"]);
            }
        }
    }

    async function saveReport() {
        if (userNamedReport === "" ) {
            toast.error("Please input the report name.");
        } 
        else if (/[!@#$%^&*(),.?":{}|<>]/.test(userNamedReport)){
            toast.error("Special characters are not acceptable ");
        }
        else {
            let data = saveFilters;
            data.statsMap = dataMap;
            data.relMap = relMap;
            let items = [];
            _.forEach(sequences, function (seq) {
                items.push(seq.value);
            });
            data.sequences = items;
            data.reportName = userNamedReport;
    
            data.reportFiltersDesc = filters;
            data.reportVmFiltersDesc = vmFilters;
            data.reportGrouping = groupBy;
            await DiscoverService.saveResultReport(data, gqApiKey);
            console.log("report");
            setSaveIsSubmited(true); // Update state to indicate that report is submitted
            setIsSaveEnabled(false); // Disable save button
        }
    }
    

    function handleSeqChange(id, name) {
        let sequences = [...checkedSeqs];
        if (checkedSeqs.includes(id)) {
            sequences = checkedSeqs.filter(seq => seq !== id);
        }
        else {
            sequences.push(id);
        }
        setCheckedSeqs(sequences);

        if (sequences.length > 1 && sequences.length < 4) {
            setVennChartObj(ResultReportChartService.drawVennCharts(relMap, allSeqs, sequences));
        }

        if (sequences.length < 2) {
            toast.error('At least 2 sequence IDs are required.', {
            });
        }
    }

    return (
        <div>
            <div style={{float: "right", paddingTop: '20px', paddingRight: '50px'}}>
                <a href="/#" className="large-font" onClick={(e)=>{window.print();e.preventDefault();}}>Print</a><span> </span> {/*print('printable')*/}
                {isSaveEnabled === true &&
                    <a href="/#" className="large-font" onClick={(e) => {changeOpenSaveState();e.preventDefault();}}>Save</a>
                }
            </div>
            <div id='printable'>
            <style>
            {`
                h4 {font-style:normal;}
                table {border:1px solid gray; border-collapse:collapse}
                .rs_resinfo_title{padding:1px 4px 1px 1px;border-style:solid;border-width:0px 0px 1px 0px;width:100px;vertical-align:top;background-color:#EFEFEF}
                .rs_resinfo_content{padding:1px 4px 1px 7px;border-style:solid;border-width:0px 0px 1px 0px;vertical-align:top;}
            `}
            </style>
            <div style={{paddingTop: '20px'}}>
                <DropDownBox margin="normal" variant="outlined"
                     name="sequences" id="sequences"
                     value={selectedSeq} items={sequences}
                     onChange={handleSequence}
                     label={"Query Sequence ID"}
                 />
            </div>
            {chartObj && chartObj['riqTrendChart'] &&
            <div className="clearfix chart-thumb-container" style={{width: '100%', height: '400px'}}>
                <div style={{float: "left"}}>
                   <Chart id="chart0" type='line' width={380} height={400} data={chartObj['riqTrendChart'][1].data} ref={riqChartRef}
                         options={chartObj['riqTrendChart'][1].options} onClick={(e) => clickPoint(e, 'RIQ')}></Chart>
                </div>
                <div style={{float: "left", paddingLeft: '20px'}}>
                   <Chart id="chart1" type='line' width={380} height={400} data={chartObj['riTrendChart'][1].data} ref={riChartRef}
                         options={chartObj['riTrendChart'][1].options} onClick={(e) => clickPoint(e, 'RI')}></Chart>
                </div>
                <div style={{float: "left", paddingLeft: '20px'}}>
                   <Chart id="chart2" type='line' width={380} height={400} data={chartObj['ridTrendChart'][1].data} ref={ridChartRef}
                         options={chartObj['ridTrendChart'][1].options} onClick={(e) => clickPoint(e, 'RID')}></Chart>
                </div>
            </div>}
            {selectedPoint !== '' &&
                <div>
                    <br></br>
                    <label style={{marginTop: '10px'}}><b>No. of Docs over %Identity of </b>&nbsp;&nbsp;&nbsp;</label>
                    <SelectBox margin="normal" variant="outlined" id="grouping" value={grouping} items={group_items} className={"bodyText " + classes.textField}
                               onChange={(e)=>{changeSelection(e, 'grouping', grouping, xAxis, yAxis)}}/>
                    <br></br>
                    <label style={{marginTop: '10px'}}><b>X-axis:</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>
                    <SelectBox margin="normal" variant="outlined" id="xAxis" value={xAxis} items={xAxis_items} className={"bodyText"}
                               onChange={(e)=>{changeSelection(e, 'xAxis', grouping, xAxis, yAxis)}}/>
                    {/*{xAxis} <Button className={"submitButtonClass"} onClick={()=>{showAll(grouping, yAxis);}}>
                        {xAxis === 'All' ? '' : 'Show all'}</Button>*/}
                    <br></br>
                    <label style={{marginTop: '10px'}}><b>Y-axis:</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>
                    <SelectBox margin="normal" variant="outlined" id="yAxis" value={yAxis} items={ResultReportChartService.yAxis} className={"bodyText"}
                               onChange={(e)=>{changeSelection(e, 'yAxis', grouping, xAxis, yAxis)}}/>
                    <br></br>
                    <label><b>Total count: {totalCount}</b></label>
                </div>
            }
            {selectedPoint !== '' && totalCount > 0 && subChartObj['statusFacetChart'] &&
                <div className="clearfix chart-thumb-container" style={{width: '100%', height: '320px'}}>
                    <span style={{float: "left", height: '100%'}}>
                        <Chart id="chart3" type='bar' width={350} height={300} data={subChartObj['statusFacetChart'][1].data} options={subChartObj['statusFacetChart'][1].options}></Chart>
                    </span>
                    <span style={{float: "left", paddingLeft: '20px', height: '100%'}}>
                        <Chart id="chart4" type='bar' width={350} height={300} data={subChartObj['PslFacetChart'][1].data} options={subChartObj['PslFacetChart'][1].options}></Chart>
                    </span>
                    <span style={{float: "left", paddingLeft: '20px', height: '100%'}}>
                        <Chart id="chart5" type='bar' width={400} height={300} data={subChartObj['authFacetChart'][1].data} options={subChartObj['authFacetChart'][1].options}></Chart>
                    </span>
                </div>}

            {allSeqs && allSeqs.length > 1 && allSeqs.length <= 3 &&
                <div style={{ whiteSpace: 'nowrap' }}>
                    <br></br>
                    <h4>Relationship between query sequences</h4>
                    <label><b>Query Sequence ID:</b> </label>
                    {allSeqs.map((seq, index) => (
                        <span key={'cb_' + index}>
                            <Checkbox
                                id={seq}
                                checked={checkedSeqs.includes(seq)}
                                onChange={e => handleSeqChange(e.target.id, e.target.name)}
                                //className={"absolutePosition " + classes.checkBox}
                                color="primary"
                            />
                            <label htmlFor={seq}>{seq}</label>
                        </span>
                    ))}
                    {checkedSeqs.length > 1 && <div className="clearfix chart-thumb-container" style={{width: '100%'}}>
                        <span style={{float: "left"}}>
                            <Chart id="chart6" type='venn' width={380} height={350} data={vennChartObj['PN_VENN'][1].data} options={vennChartObj['PN_VENN'][1].options}></Chart>
                        </span>
                        <span style={{float: "left", paddingLeft: '20px'}}>
                            <Chart id="chart7" type='venn' width={380} height={350} data={vennChartObj['PF_VENN'][1].data} options={vennChartObj['PF_VENN'][1].options}></Chart>
                        </span>
                        <span style={{float: "left", paddingLeft: '20px'}}>
                            <Chart id="chart8" type='venn' width={380} height={350} data={vennChartObj['SSV_VENN'][1].data} options={vennChartObj['SSV_VENN'][1].options}></Chart>
                        </span>
                    </div>}
            </div>}

                <br></br>
                {workflowInfo && <div>
                    <h4>Workflow Information:</h4>
                    <span>This result is part of the output of the workflow below, and it is </span>
                    <a href={process.env.REACT_APP_URL + 'discover/wf:' + resid + '.resdb/report/' + collName} target="_blank" rel="noreferrer">
                        {!filters && !vmFilters &&
                            <span>Full results</span>
                        }
                        {(filters || vmFilters) &&
                            <span>Filtered results</span>
                        }
                    </a>
                    {ReactHtmlParser(workflowInfo)}
                </div>}
                <br></br>
                {groupBy &&
                <div>
                    <h4>Filtering and Grouping:</h4>
                    <table width={"100%"}>
                        <tr>
                            <td className="rs_resinfo_title">Filter</td>
                            <td className="rs_resinfo_content">{filters}</td>
                        </tr>
                        <tr>
                            <td className="rs_resinfo_title">Variation Filter</td>
                            <td className="rs_resinfo_content">{vmFilters}</td>
                        </tr>
                        <tr>
                            <td className="rs_resinfo_title">Group by</td>
                            <td className="rs_resinfo_content">{groupBy}</td>
                        </tr>
                    </table>
                </div>}
                <br></br>
            </div>
            {showLoader &&
                <div className={classes.loader}>
                    <div className={classes.loaderPosition}>
                        <div className="spinner-container">
                            <div className="spinner-socket">
                                <div className="spinner-hex spinner-tile-1">
                                    <div className="spinner-brick rotate-0"></div>
                                    <div className="spinner-brick rotate-1"></div>
                                    <div className="spinner-brick rotate-2"></div>
                                </div>
                                <div className="spinner-hex spinner-tile-2">
                                    <div className="spinner-brick rotate-0"></div>
                                    <div className="spinner-brick rotate-1"></div>
                                    <div className="spinner-brick rotate-2"></div>
                                </div>
                                <div className="spinner-hex spinner-tile-3 spinner-row-2">
                                    <div className="spinner-brick rotate-0"></div>
                                    <div className="spinner-brick rotate-1"></div>
                                    <div className="spinner-brick rotate-2"></div>
                                </div>
                                <div className="spinner-hex spinner-tile-4 spinner-row-2">
                                    <div className="spinner-brick rotate-0"></div>
                                    <div className="spinner-brick rotate-1"></div>
                                    <div className="spinner-brick rotate-2"></div>
                                </div>
                                <div className="spinner-hex spinner-tile-5 spinner-row-2">
                                    <div className="spinner-brick rotate-0"></div>
                                    <div className="spinner-brick rotate-1"></div>
                                    <div className="spinner-brick rotate-2"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {openSaveUI === true &&
                <div>
                    <div className="popupBackdrop" onClick={changeOpenSaveState}>
                    </div>
                    <div className="saveAbPopup">
                        <div>
                            <Link href="#" className={"p-2 appTextColor"}><CloseIcon onClick={changeOpenSaveState} /></Link>
                        </div>
                        {!saveIsSubmited &&
                        <div className="ABSavejumbotron">
                            <br></br>
                                <span><strong>Result Report Name</strong></span>
                            <br></br>
                            <input
                                id="reportname"
                                onChange={(event)=>saveReportName(event)}
                                className="abInput"
                                type="text"
                                width={"100%"}
                                placeholder="Name your Report"
                                autoComplete="off" spellCheck="false"
                            >
                            </input>

                            <div className="ABSaveBuffer">
                                <Button className={"cancelButtonClass "} onClick={()=>{changeOpenSaveState();}}>Cancel</Button>
                                <span className="ml-1"></span>
                                {userNamedReport === '' &&
                                <Button 
                                    className={"disableButtonClass"} onClick={()=>{saveReport()}}  disabled={!userNamedReport.trim()}> Save </Button>
                                }
                                {userNamedReport !== '' &&
                                <Button 
                                    className={"submitButtonClass"} onClick={()=>{saveReport()}}> Save </Button>
                                }                            </div>
                        </div>
                        }
                        {saveIsSubmited &&
                        <div className="ABSaveSubmittedJumbotron">
                            <br></br>
                                <span className="center historyPopupHeader tableCaption">Your Report has been saved...</span>
                            <br></br>
                            <br></br>
                            <div className="ABSaveBuffer">
                                <Button className={"cancelButtonClass"} onClick={changeOpenSaveState}>Ok</Button>
                            </div>
                        </div>
                        }
                    </div>
                </div>
            }
        </div>
    );
}
export default ResultReport;
