import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import FolderTreeMenu from '../../shared/FolderTreeStructure/FolderTreeMenu';
import ListGroup from 'react-bootstrap/ListGroup'


const useStyles = makeStyles((theme) => ({
    modalHeader: {
        fontFamily: 'verdana, helvetica',
        fontSize: '12px',
        width: '75%'
    },
    projectListItem: {
        padding: '0.2rem 0.25rem !important',
        border: 'none !important'
    }
}));



const SaveVirtualDatabase = (props) => {

    const classes = useStyles();
    const [defaultTitle, setDefaultTitle] = useState('My Data');
    const [folderDetail, setFolderDetail] = useState([]);
    const [expandedFolderIds, setExpandedFolderIds] = useState([]);

    useEffect(() => {
    }, []);

    function onCancel(props) {
        props.closeModal();
    }
    function clickOnOk(props) {
        props.closeModal();
    }

    
    return (
        <Modal
            id='modalCss'
            aria-labelledby="contained-modal-title-vcente"
            centered
            size='lg'
            show={props.showModal}
            contentClassName={classes.modalHeader}
        >
            <div>
                <div className="titlebar">
                    <span>Save as Virtual Database</span>
                    <Link href="#" onClick={(e) => e.preventDefault()} className="pull-right">
                        <CloseIcon style={{ fill: '#b5b5b5' }} onClick={() => onCancel(props)} />
                    </Link>
                </div>
                <div style={{ borderTop: '1px solid #b5b5b5', padding:'12px' }}>
                    <br />
                    <p style={{color: 'black'}}>You can save this result set so that can it can be used as Virtual Query or Subject database.</p>
                    <p style={{color: 'black'}}>Note: Checkboxes will be ignored, this saves the entire filtered result.</p>
                    <table>
                        <tbody>
                            <tr>
                                <td>Title</td>
                                <td><input style={{width: '200px', outline: 'none'}}></input></td>
                            </tr>
                            <tr>
                                <td>Description</td>
                                <td><textarea></textarea></td>
                            </tr>
                            <tr>
                                <td>Save To Folder</td>
                                <td>
                                 <ListGroup.Item key={123} className={classes.projectListItem}>
                                        <FolderTreeMenu items={folderDetail} selectedTitle={defaultTitle} expandedIds={expandedFolderIds} type="selectFolder" />
                                    </ListGroup.Item>   
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div align='right'>
                    <button className='okButton DMTBtn'>Ok</button>
                    <button className='okButton DMTBtn' onClick={() => clickOnOk(props)}>Cancel</button>
                </div>
            </div>
        </Modal>
    );
}

export default SaveVirtualDatabase;