
import React, { useState, useEffect } from "react";

import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ArrowLeft from '@material-ui/icons/ArrowLeft';

import ArrowRight from '@material-ui/icons/ArrowRight';
import ChevronRight from '@material-ui/icons/ChevronRight';




function Paging(props) {

    const {currentPage, numberOfPages, goToPage} = props

    const [pageList, setPageList] = useState(null);

    useEffect(()=>{
        if(currentPage >=1 && numberOfPages >= 1){
            setPageList(
                getPaging()
            )
        }
    },[props.currentPage,  props.numberOfPages]);

    function getPaging(){

        let pageRay = [currentPage];

        let upCount = 0;
        for(let i=currentPage-1; i >= 1 && upCount <= 4; i--){
            
            pageRay.unshift(i);
            upCount++;

            if(upCount > 3){

                pageRay = ['1',"..."].concat(pageRay)

                break;
            }
        }

        upCount =0;
        for(let i=currentPage+1; i <= numberOfPages && upCount <= 4; i++){
            
            pageRay.push(i);
            upCount++

            if(upCount > 3){

                pageRay = pageRay.concat(['...', numberOfPages])
                break;
            }
        }

        return pageRay
    }

return(
<div className="pagingContainer">
{pageList!=null &&
    <div className="pagingContainer">
        <ChevronLeft className={ currentPage > 1? "pagingArrow": "pagingArrowDisabled"} onClick={()=>{if(currentPage > 1){goToPage(1)}}} disabled={currentPage ==1}/>
        <ArrowLeft className={ currentPage > 1? "pagingArrow": "pagingArrowDisabled"}  onClick={()=>{if(currentPage > 1){goToPage(currentPage-1)}}} disabled={currentPage ==1 }/>

        {pageList.map((obj, index)=>{
            return(
                <span className={ (currentPage != obj && obj !="...")? "pagingNumber": "pagingNumberDisabled"} key={index} onClick={()=>{if(currentPage != obj && obj !="..."){goToPage(obj)}}}>
                    {obj}
                </span>
            );
        })}
        <ArrowRight className={ currentPage < numberOfPages? "pagingArrow": "pagingArrowDisabled"} onClick={()=>{if(currentPage < numberOfPages){goToPage(currentPage+1)}}}/>
        <ChevronRight className={ currentPage < numberOfPages? "pagingArrow": "pagingArrowDisabled"}  onClick={()=>{if(currentPage < numberOfPages){goToPage(numberOfPages)}}}/>
    </div>
}
</div>
);}

export default Paging