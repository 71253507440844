import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import CloseIcon from '@material-ui/icons/Close';
import { toast } from 'react-toastify';


function FullTextPatentListModal(props) {

	const {patentString, missingPns , openOrClosed, editMode, cancel, save} = props

	const [patentErrorList, setPatentErrorList]= useState([]);
	const [pnText, setPnText] = useState("")

	const clearPatentList = () =>{
		document.getElementById("pnPopup").innerHTML = "";
		changePnText();
	}


    useEffect(() => {
		if(patentString!= null && patentString != undefined && patentString !=""){
			save(patentString)
		}
	},[patentString]);


	const [lastCopyTime, setLastCopyTime] = useState(0);
	const copyMissingPatentList = async() => {

		if(Date.now() - lastCopyTime > 2000){
		let mspnNode = document.getElementById("missingPnPopup");
			var text = mspnNode.textContent;
			await navigator.clipboard.writeText(text);
			toast.success("Text Copied");
			setLastCopyTime(Date.now())
		}
	}

	const changePnText = () => {
		let node = document.getElementById("pnPopup");
		if(node != null){
			setPnText(node.innerText);
		}
	}

	const savePatentList = async() => {

		let pnStringHolder = pnText
		if(pnStringHolder == ""){
			let node = document.getElementById("pnPopup");
			if(node != null){
				pnStringHolder = node.innerText;
			}

			if(pnStringHolder == ""){
				setPatentErrorList([]);
				toast.success("Patent List Saved")
				save("")
				return
			}
		}

		pnStringHolder = pnStringHolder.trim();
		pnStringHolder = pnStringHolder.replaceAll(/\"+/g, '');
		pnStringHolder = pnStringHolder.replaceAll(/\n+/g, ', ');
		pnStringHolder = pnStringHolder.replaceAll(/\s+/g, ', ');
		pnStringHolder = pnStringHolder.replaceAll(/,+\s+/g, ', ');

		let patError = false;
		if(pnStringHolder != ""){

			let pnRay = pnStringHolder.split(/, /g);
			pnRay.forEach((pnRayItem) => {
				if(!(pnRayItem.match(/[A-Za-z]+[0-9A-Za-z]+/))){
					patError = true;
				}
			});
		}

		if(patError){
			setPatentErrorList(["Format Error"]);
			toast.error("incorrect patent format")
		}else{
			setPatentErrorList([]);
			toast.success("Patent List Saved")
		}

		save(pnStringHolder);
	}

	function format(string){
		let pnStrHolder = string.replace(/[,\s\n]+/ig, '\n')
		return pnStrHolder
	}
    
    return (
          <div>
			{openOrClosed &&
				<div>
					<div className="popupBackdrop" onClick={() => cancel()}> 
					</div>
					<div className="pnListPopup">
						<div>
							<Link href="#" className={"p-2 float-right appTextColor"}><CloseIcon onClick={() => cancel()} /></Link>
						</div>
						<br></br>
						<span className="historyPopupHeader">Patent Number List</span>

                        {editMode &&
                        <>
						<div 
							id="missingPnPopup"
							
							spellcheck="false"
							className = {patentErrorList.length>0? "queryError patentListTable" : "patentListTable"}
						>	
						{format(missingPns)}			
						</div>
						<div className="pnListBuffer">
							<Button className={"cancelButtonClass "} onClick={() => cancel()}>Close</Button>
							<span className="ml-1"></span>
							<Button className={"submitButtonClass"} onClick={() => copyMissingPatentList()}>Copy</Button>
						</div>
                        </>
                        }
                        {!editMode &&
                        <>
                        <div 
							id="pnPopup"
							onInput = {(event) =>{changePnText()}}
							spellcheck="false"
							contentEditable = "true"
							className = {patentErrorList.length>0? "queryError patentListTable" : "patentListTable"}
						>	
						{format(patentString)}			
						</div>
                        <div className="pnListBuffer">
							<Button className={"cancelButtonClass "} onClick={() => clearPatentList()}>Clear</Button>
							<span className="ml-1"></span>
							<Button className={"cancelButtonClass "} onClick={() => cancel()}>Close</Button>
							<span className="ml-1"></span>
							<Button className={"submitButtonClass"} onClick={() => savePatentList()}>Save</Button>				
						</div>
                        </>
                        }
                	</div>
            	</div>	
			}
        </div>
    );
}

export default FullTextPatentListModal;