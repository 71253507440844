import {post} from "../../helpers/fetchServicesMethods";
import _, {debounce} from "lodash";

const lvaEventOptions = [
    {event: 'M', label: 'Any match'},
    {event: 'R', label: 'Any replacement'},
    {event: 'I', label: 'Any insertion'},
    {event: 'D', label: 'Any deletion'}
];

let resultLvaFilterState = {};
let lvaQueries = {};
let query = {};
let state = {
    smartTextFilter: {},
    smartText: '',
    smartTextPopup: false,
    smartTextOptions: [],

    smartHighlightPopup: false,
    smartHighlight: '',
    smartHighlightOptions: [],
    smartHighlightFilter: {},
    smartFiltersHover: [],
};

// holds all information related to the advanced filtering widget functionality
let widgetState = {
    // filtering widget under construction (by user)
    // holds all the atoms in the filter
    widgetContent: [
        //_.cloneDeep(defaultAtom)
    ],
    // filtering widget under construction (by user)
    // mode AND|OR|CUSTOM
    widgetMode: 'AND',
    // filtering widget under construction (by user)
    // state variables that hold whether date widgets are open right now
    widgetDate: {},
    // filtering widget under construction (by user)
    // if widgetMode === 'CUSTOM' this holds the query the user typed
    widgetCustomQuery: '',
    // filtering widget under construction (by user)
    // if widgetMode === 'CUSTOM' this holds which atoms are used in the typed query
    // used by the UI to color used atoms green (as visibile reminder to user)
    widgetCustomAtomUsed: {},
    // filtering widget under construction (by user)
    // Name of the filter given by the user in the UI
    customFilterName: '',
    defaultCustomFilters: [],
    // holds all the custom filters available to the user
    customFilters: [],
    // set to true when user hovers over a filter (to show edit|del menu on hover, no restore)
    customFiltersHover: [],
    // holds whether a filter has been deleted (true means deleted)
    customFilterDeleted: [],
    smartHighlightFilterHover: [],
    // holds all the Global VM filters available to the user
    globalLvaFilters: [],
    // set to true when user hovers over a filter (to show edit|del menu on hover, no restore)
    globalLvaFiltersHover: []
};

let gqApiKey = "";
let userdir = "";
let displaySciNotation = false; // Flag to display the notation scientifically
let displaySingleMarker = true; // Flag to display only one insertion/deletion in the beginning

const facetFieldConfig = {
    SUBJECT_PN: {
        desc: 'Patent Authorities',
        collapse: false
    },
    // SUBJECT_PZ: {
    //     desc: 'Normalized Parent',
    //     collapse: false
    // },
    SUBJECT_PG: {
        desc: 'Extended Legal Status',
        collapse: false
    },
    SUBJECT_PS: {
        desc: 'Patent Sequence Location',
        collapse: false
    }
};

function returnQuery() {
    return query;
}
function getQuery(state, filter) {
    query = {};
    /*
    self.prepAlignFilter();*/
    prepLvaFilters(filter, query);
    prepCustomFilters(filter, query);
    //query.annot = state.annot;
    prepAnnotationFilters(state, query);
    prepFacetFilter(state, query);
    prepPublicationNumberFilter(state, query);
    prepDatabaseFilter(state, query);
    prepQuerySeqIdFilter(state, query);
    prepSmartFilter(state, query);
    if (state.singleAlignmentId !== undefined) {
        query['singleAlignmentId'] = state.singleAlignmentId;
    }
    if (state.abExp !== undefined) {
        query['abExp'] = state.abExp;
    }
    if (state.vmExp !== undefined) {
        query['vmExp'] = state.vmExp;
        query['vmQueryId'] = state.vmQueryId;
    }
    filter.query = query;
    return query;
}

function prepAnnotationFilters(state, query, text) {
    query.annot = state.annot;
    query.annot.notes = [];
    let notes = state.annot.noteList;
    if (text) {
        notes = text;
    }
    if (notes) {
        let rawText, rawList, i;
        rawText = notes.slice();
        rawText = _.replace(rawText, "\r", "\n");
        //rawText = _.replace(rawText, new RegExp(/ +/g), "");
        rawList = _.split(rawText, "\n");
        for (i = 0; i < rawList.length; i++) {
            if (rawList[i].length > 0) {
                query.annot.notes.push(rawList[i]);
            }
        }
    }
}

function prepLvaFilters(filter, query) {
    query.lvaFilters = [];
    if (filter.lvaWidgetState.lvaFilters) {
        for (let i = 0; i < filter.lvaWidgetState.lvaFilters.length; i++) {
            if (filter.state.lvaFilterCheckboxes[i] === true) {
                query.lvaFilters.push(filter.lvaWidgetState.lvaFilters[i]);
            }
        }
    }
}

function prepCustomFilters(filter, query) {
    let defaultSize = filter.widgetState.defaultCustomFilters.length;
    query.customFilters = [];
    let i = 0;
    if (filter.widgetState.customFilters) {
        for (i = 0; i < filter.widgetState.customFilters.length; i++) {
            if (filter.state.customFilterCheckboxes[i + defaultSize] === true) {
                // Because we have 3 default Filters, so index +x for new one
                query.customFilters.push(filter.widgetState.customFilters[i]);
            }
        }
    }
    if (filter.widgetState.defaultCustomFilters) {
        for (i = 0; i < filter.widgetState.defaultCustomFilters.length; i++) {
            if (filter.state.customFilterCheckboxes[i] === true) {
                query.customFilters.push(filter.widgetState.defaultCustomFilters[i]);
            }
        }
    }
}

function prepFacetFilter(state, query) {
    _.each(facetFieldConfig, function (info, sideMenuItem) {
        query[sideMenuItem] = [];
        _.each(state[sideMenuItem], function (value, objkey) {
            if (value) {
                // hack to make sure TBD search in SUBJECT_PS field works with SQL query in backend
                if (sideMenuItem === 'SUBJECT_PS' && objkey === 'To Be Determined') {
                    objkey = "TBD";
                }
                query[sideMenuItem].push(objkey);
            }
        });
    });
}
function prepPublicationNumberFilter(state, query, text) {
    query.publicationNumberList = [];
    let pnList = state.publicationNumberList;
    if (text) {
        pnList = text;
    }
    if (pnList) {
        let rawText, rawList, i;
        rawText = pnList.slice();
        rawText = _.replace(rawText, "\r", "\n");
        rawText = _.replace(rawText, new RegExp(/ +/g), "");
        rawList = _.split(rawText, "\n");
        for (i = 0; i < rawList.length; i++) {
            if (rawList[i].length > 0) {
                query.publicationNumberList.push(rawList[i]);
            }
        }
    }
}

function prepDatabaseFilter(state, query) {
    query.databaseNames = {};
    _.forEach(
        state.databaseNames,
        function (val, key) {
            if (val === true) {
                query.databaseNames[key] = key;
            }
        }
    );
}

function prepQuerySeqIdFilter(state, query) {
    query.querySeqDbs = {};
    _.forEach(
        state.querySeqDbs,
        function (val, key) {
            if (val === true) {
                query.querySeqDbs[key] = key;
            }
        }
    )
}

function prepSmartFilter(state, query) {
    let searchStrings, negateSearchStrings;
    // all text search from side menu
    if (_.size(state.smartTextFilter) > 0) {
        query.smartText = {};
        query.smartTextNegate = {};
        _.each(state.smartTextFilter, function (val, key) {
            searchStrings = [];
            negateSearchStrings = [];
            _.each(val, function (obj) {
                if (obj.value === true) {
                    if (obj.negate) {
                        negateSearchStrings.push(obj.text);
                    } else {
                        searchStrings.push(obj.text);
                    }
                }
            });
            if (searchStrings.length > 0) {
                query.smartText[key] = searchStrings.slice();
            }
            if (negateSearchStrings.length > 0) {
                query.smartTextNegate[key] = negateSearchStrings.slice();
            }
        });
    }
}

/**
 * Get a data structure containing the hint lines to use to decorate an alignment display, as well as a tree
 * structure representing the and/or logic of the LVA filter, the individual LVA filter hints used to create
 * the hint lines, and finally a copy of the core sequence data used to produce the LVA alignment decorations.
 * @param seqData
 * @returns {{hintLines: Array, logicTree: {}, hints: [], seqData: *}}
 */
function getLvaAlignmentHints(resultFilter, seqData) {

    let hintLines = [],
        hints = [],
        logicTree = {},
        chunks = getAlignmentChunks(seqData),
        filterIdx,
        filter,
        methodIdx,
        method;

    // TODO, how to support OR/custom conditions
    //  Compile a list of the hints that apply to this alignment
    for (filterIdx in resultFilter.lvaWidgetState.lvaFilters) {
        // Check if selected
        if (resultFilter.state.lvaFilterCheckboxes && resultFilter.state.lvaFilterCheckboxes[filterIdx]) {
            filter = resultFilter.lvaWidgetState.lvaFilters[filterIdx];
            filter.filterIdx = filterIdx;
            if (filter.lvaQuery.QUERY_ID === seqData.QUERY_ID) {
                for (methodIdx in filter.widgetContent) {
                    method = filter.widgetContent[methodIdx];
                    addHint(hints, seqData, chunks, filter, method, method.colors);
                }
            }
        }
    }

    //  Construct the lines needed to display the hints within the alignment
    addHintLines(hintLines, hints);

    //  Return an object with everything we've got so far
    return {hintLines: hintLines, logicTree: logicTree, hints: hints, seqData: seqData};
}

/**
 * Get the lines of the display alignment, separated into chunks of query-alignment-subject lines,
 * annotated with start and end positions for each line.
 *
 * @param seqData
 * @returns [{}]
 */
function getAlignmentChunks(seqData) {
    let chunks = [],
        lines = _.split(seqData.RESULT_FALI_FORMATTED, "\n"),
        chunk = {},
        i = 0,
        spacer,
        matchStart, matchEnd,
        groupNum = -1,
        line,
        aLine, aChunk,
        qLine, qChunk, qLeft, qRight,
        sLine, sChunk, sLeft, sRight,
        qFrLeft, qFrRight, qFrLow, qFrHigh;

    while (i < lines.length) {

        line = lines[i];

        if (line.indexOf("Q:") === 0) {    //  This is an alignment group of query, alignment, subject
            groupNum += 1;
            // get the three lines
            qLine = lines[i];
            aLine = lines[i + 1];
            sLine = lines[i + 2];
            // find out where the query sequence starts
            matchStart = 2 + (/[0-9] /.exec(qLine)).index;
            matchEnd = (/[^ :] [0-9]/.exec(qLine)).index + 1;
            spacer = aLine.substr(0, matchStart); // leading spaces that we don't want to replace
            aChunk = aLine.substring(matchStart, matchEnd); // the alignment characters for this chunk
            qChunk = qLine.substring(matchStart, matchEnd); // the query     characters for this chunk
            sChunk = sLine.substring(matchStart, matchEnd); // the subject   characters for this chunk

            qLeft = parseInt((/[0-9]+/.exec(qLine))[0]);
            qRight = parseInt((/[0-9]+$/.exec(qLine))[0]);
            sLeft = parseInt((/[0-9]+/.exec(sLine))[0]);
            sRight = parseInt((/[0-9]+$/.exec(sLine))[0]);

            //  Positions in the query always display from low to high, even when an alignment has been
            //  reverse complemented, so the actual position needed in evalutating filter application
            //  isn't the same as what is beign displayed.  The computed qFrLeft and qFrRight values
            //  are the values that must be used when comparing to filter method positions (not the display values).
            if (seqData.RESULT_NFQ >= 3 && seqData.RESULT_NFQ <= 6) {
                //  Reverse complemented  -> Test for correctness in frames 3, 4, 5 Nuc->Prot reverse!!!
                // qFrLeft = (seqData.RESULT_OOBQ - qLeft) + seqData.RESULT_OOBQ;
                // qFrRight = (seqData.RESULT_OOBQ - qRight) + seqData.RESULT_OOBQ;
                qFrLeft = qRight;
                qFrRight = qLeft;
                qFrLow = qFrRight;
                qFrHigh = qFrLeft;
            } else {
                //  Forward frame ->  Test for correctness in frames 0, 1, 2 Nuc->Prot forward!!!
                qFrLeft = qLeft;
                qFrRight = qRight;
                qFrLow = qFrLeft;
                qFrHigh = qFrRight;
            }

            chunk = {
                chunkIdx: groupNum,     // The index into this chunk; we'll need it later
                aChunk: aChunk,       // Alignment Chunk (match characters only)
                qChunk: qChunk,       // Query     Chunk (sequence only)
                sChunk: sChunk,       // Subject   Chunk (sequence only)
                aLine: aLine,        // Alignment line (complete)
                qLine: qLine,        // Query     line (complete)
                sLine: sLine,        // Subject   line (complete)
                qLeft: qLeft,        // Position value in display alignment for query   start (left  side)
                qRight: qRight,       // Position value in display alignment for query   end   (right side)
                sLeft: sLeft,        // Position value in display alignment for subject start (left  side)
                sRight: sRight,       // Position value in display alignment for subject end   (right side)
                qFrLeft: qFrLeft,      // Position value in true    alignment for query left  side
                qFrRight: qFrRight,     // Position value in true    alignment for query right side
                qFrLow: qFrLow,       // Position value in true    alignment for query, lowest w/o direction
                qFrHigh: qFrHigh,      // Position value in true    alignment for query, highest w/o direction
                charOffset: spacer.length // Characters in from left of line where actual alignment starts
            };
            chunks[groupNum] = chunk;

            i += 3;
        } else {
            i += 1;
        }
    }

    return chunks;
}

/**
 * Construct a hint for a method, pointing to affected trunks.
 */
function addHint(hints, seqData, chunks, filter, method, colors) {

    let chunkIdx,
        chunk,
        coveredChunks = [],
        hint,  //  startLine, endLine, startLinePos, endLinePos
        start,
        stop,
        increment;

    //  For this method, find the position(s) to which it applies
    //  Then find the spots in the line/lines

    if (!method.stop) {
        method.stop = method.start;
    }
    //  Build a list of covered chunks
    for (chunkIdx in chunks) {
        chunk = chunks[chunkIdx];
        if (chunk.qFrLow <= method.stop && chunk.qFrHigh >= method.start) {
            start = Math.max(chunk.qFrLow, method.start);
            stop = Math.min(chunk.qFrHigh, method.stop);
            increment = chunk.qFrLeft <= chunk.qFrRight ? +1 : -1;
            coveredChunks.push({
                chunkIdx: chunkIdx,
                start: start,
                stop: stop,
                increment: increment,
                chunk: chunk
            });
        }
    }

    //  If the method never covers this portion of
    if (coveredChunks.length === 0) {
        return;
    }

    hint = getMethodHint(coveredChunks, method, seqData);

    if (hint !==null && !hint.failed) {
        hint.filter = filter;
        hint.colors = colors;
        hints.push(hint);
    }
}

function addHintLines(hintLines, hints) {
    //  Sort the hints by filter, and position within filter
    //      Overlapping positions have to go onto different lines... put the same filter
    //          on the same line whenever possible
    //  Color multiple filters with different gray colors (blue, green, red, etc.)
    //  Color pointers in filters with bold version of the filter color
    //
    //  Future: Henk wants options to choose the colors (by filter, by method)
    //          Maybe vary the colors within a filter by method (and provide feedback
    //          in the LVA Filter dialog as to which method will be which color)
    //
    //  Spans may cross chunks; for each chunk, create the span and the starting position
    //
    //  Each span needs the outer bracket (with appropriate ends and the rollover text
    //      that may optionally zoom in on the filtered area),
    //      as well as pointers within the span.
    //
    //  hintLines has one entry for each chunk with LVA filter hints
    //  Each hintLines chunk entry has one entry for each LVA filter line
    //  Each LVA filter line has a line of HTML to put over the normal alignment display
    //
    //  Then the receiver must apply the hintLines along with traditional highlighting
    //  by updating the alignment display (with an option for a "zoom")
    //

    let hintIdx, hint;

    if (hints === null || hints.length === 0) {
        return;
    }

    setHintsStartEnd(hints);   //  Set query start and end to make sorting easier

    //  Sort hints for consistent, logical display and overlap detection
    hints.sort(function (a, b) {
        // Won't take care of the reversed case, in order to better merge the hints in the same line
        // if (a.filter.filterIdx === b.filter.filterIdx) {
        //     return a.complemented ? -result : result;
        // }
        return hintCompare(a, b);
    });

    // Merge the hints if existing in the same filter
    let mergedHints = [];
    mergeHintByFilter(hints, mergedHints);
    // Sort it again to take care of the reversed case
    mergedHints.sort(function (a, b) {
        let result = hintCompare(a, b);
        // TODO, below sorting would lead to one bug for reversed case
        // Take care of the reversed case, so that we could create the marks correctly
        /*if (a.filter.filterIdx === b.filter.filterIdx) {
            return a.complemented ? -result : result;
        }*/
        return result;
    });

    //  Assemble the hint lines
    for (hintIdx in mergedHints) {
        hint = mergedHints[hintIdx];
        setHintAbove(hint);
        setHintLevel(hint, hintLines);
        addHintLinesForHint(hint, hintLines);
    }
}

/**
 * Add this hint to all of the chunk/level lines it affects.
 *
 * Hints prior to this one should have been processed already, so it can just append to existing hint lines
 * if they exist.
 *
 * @param hint
 * @param hintLines
 */
function addHintLinesForHint(hint, hintLines) {
    let markerIdx, marker,
        chunk, chunkIdx = null,
        hintText = hint.hintText, //self.getHintText(hint),  //  Text to display on rollover for this hint
        hintLine = {line: "", cPos: 0, qChunkFlags: [], sChunkFlags: [], insAdjIdx: 0},
        firstChunkIdx = null,
        lastChunkIdx,
        endClass, chunkFlag,
        qChunk, sChunk;

    hint.markers.sort(compareMarkerCPos);  //  Last ditch effort to make sure these are in the right order

    // Change the hint display to be more scientifically
    if (displaySciNotation) {
        hintText = hint.filter.lvaFilterName + ":  ";
    }

    //  Determine the first and last chunks for this marker
    let lastInsMarker = null, lastDelMarker = null, firstHint = true, markerHintText = "";
    hint.markersSumm = [];
    for (markerIdx in hint.markers) {
        lastChunkIdx = hint.markers[markerIdx].chunk.chunkIdx;
        if (firstChunkIdx === null) {
            firstChunkIdx = lastChunkIdx;
        }

        // Populate/combine the display hint for markers/hint line
        marker = hint.markers[markerIdx];
        if (marker.action !== null) {
            chunk = marker.chunk;
            qChunk = chunk.qChunk.charAt(marker.cPos);
            sChunk = chunk.sChunk.charAt(marker.cPos);

            markerHintText = "";
            if (qChunk === "" && sChunk === "") {
                // Should not happen
            } else if (qChunk !== "-" && sChunk !== "-") { // Replacement
                marker.hintText = qChunk + marker.qPos + sChunk;
                markerHintText = marker.hintText;
                hint.markersSumm.push({
                    pos: marker.qPos,
                    type: "Replacement",
                    qChunk: qChunk,
                    sChunk: sChunk,
                    description: markerHintText
                });
            } else if (qChunk !== "-" && sChunk === "-") { // Deletion
                if (displaySingleMarker && lastDelMarker !== null
                    && (hint.complemented ? (lastDelMarker.newQPos === marker.qPos + 1) : (lastDelMarker.newQPos === marker.qPos - 1))) {
                    // Combine multiple deletions
                    marker.hintText = "";
                    lastDelMarker.hintChunk += qChunk;
                    if (hint.complemented) {
                        lastDelMarker.startPos = marker.qPos;
                        markerHintText = lastDelMarker.hintChunk + lastDelMarker.startPos + "_" + lastDelMarker.endPos + "del";
                    } else {
                        lastDelMarker.endPos = marker.qPos;
                        markerHintText = lastDelMarker.hintChunk + lastDelMarker.startPos + "_" + lastDelMarker.endPos + "del";
                    }
                    if (displaySciNotation) {
                        hintText = hintText.substring(0, hintText.length - lastDelMarker.hintText.length) + markerHintText;
                    }
                    //hintText = hintText.replace(new RegExp(lastDelMarker.hintText, 'g'), markerHintText);
                    lastDelMarker.hintText = markerHintText;
                    lastDelMarker.newQPos = marker.qPos;

                    hint.markersSumm.pop();
                    hint.markersSumm.push({
                        pos: lastDelMarker.startPos,
                        type: "Deletion",
                        qChunk: lastDelMarker.hintChunk,
                        sChunk: "-",
                        description: markerHintText
                    });
                    markerHintText = "";
                } else {
                    markerHintText = qChunk + marker.qPos + "del";
                    //marker.hintText = "Del at " + marker.qPos + ":  " + qChunk + (hint.complemented ? "(r)" : "");
                    marker.hintText = markerHintText;
                    marker.startPos = marker.qPos;
                    marker.endPos = marker.qPos;
                    marker.hintChunk = qChunk;
                    marker.newQPos = marker.qPos;
                    lastDelMarker = marker;

                    hint.markersSumm.push({
                        pos: marker.qPos,
                        type: "Deletion",
                        qChunk: qChunk,
                        sChunk: "-",
                        description: markerHintText
                    });
                }
            } else if (qChunk === "-" && sChunk !== "-") { // Insertion
                if (displaySingleMarker && lastInsMarker !== null && lastInsMarker.qPos === marker.qPos) {
                    // Combine multiple insertions
                    marker.hintText = "";
                    lastInsMarker.hintInsChunk += sChunk;
                    markerHintText = lastInsMarker.lastSeq + lastInsMarker.qPos + "_" + (lastInsMarker.qPos + 1) + "ins" + lastInsMarker.hintInsChunk;
                    if (displaySciNotation) {
                        hintText = hintText.substring(0, hintText.length - lastInsMarker.hintText.length) + markerHintText;
                    }
                    //hintText = hintText.replace(new RegExp(lastInsMarker.hintText, 'g'), markerHintText);
                    lastInsMarker.hintText = markerHintText;

                    hint.markersSumm.pop();
                    hint.markersSumm.push({
                        pos: lastInsMarker.qPos,
                        type: "Insertion",
                        qChunk: lastInsMarker.lastSeq + "-",
                        sChunk: lastInsMarker.hintInsChunk,
                        description: markerHintText
                    });
                    markerHintText = "";
                } else {
                    markerHintText = marker.lastSeq + marker.qPos + "_" + (marker.qPos + 1) + "ins" + sChunk;
                    //markerHintText = chunk.qChunk.charAt(marker.cPos - 1) + marker.qPos + "ins" + sChunk + (hint.complemented ? "(r)" : "");
                    //marker.hintText = "Ins after " + marker.qPos + ":  " + sChunk + (hint.complemented ? "(r)" : "");
                    marker.hintText = markerHintText;
                    //marker.hintChunk = chunk.qChunk.charAt(marker.cPos - 1);
                    marker.hintInsChunk = sChunk;
                    lastInsMarker = marker;

                    hint.markersSumm.push({
                        pos: marker.qPos,
                        type: "Insertion",
                        qChunk: marker.lastSeq + "-",
                        sChunk: marker.hintInsChunk,
                        description: markerHintText
                    });
                }
            }

            if (displaySciNotation && markerHintText !== "") {
                hintText += (firstHint ? "" : ", ") + markerHintText;
                firstHint = false;
            }
        }
    }

    //  Add spans and characters for every marker, in order, filling space where necessary
    for (markerIdx in hint.markers) {
        marker = hint.markers[markerIdx];

        //  Handle end of chunk
        if (chunkIdx === null || marker.chunk.chunkIdx !==chunkIdx) {
            //  End prior chunk
            if (chunkIdx !==null) {
                hintLine.line += "<span class='tooltiptext'>" + hintText + "</span></span>"; //"</span></span>";    //  End the span in the previous chunk
            }

            //  Start new chunk
            chunk = marker.chunk;
            chunkIdx = chunk.chunkIdx;
            hintLine = getHintLine(hintLines, hint, marker);

            //  Fill line with spaces to this marker
            while (hintLine.cPos < marker.cPos) {
                qChunk = chunk.qChunk.charAt(hintLine.cPos);
                sChunk = chunk.sChunk.charAt(hintLine.cPos);
                if (qChunk === "-" && sChunk !== "-") {
                    hintLine.insAdjIdx++;
                }

                hintLine.line += " ";
                hintLine.cPos++;

                hintLine.qChunkFlags.push(0);
                hintLine.sChunkFlags.push(0);
            }

            //  Determine the class for a line with a left end, right end, both ends or no ends
            //  lva-left-end if markers = 1 but this marker goes to another chunk
            //  lva-right-end if this marker is not in the first chunk but ends in this chunk
            //  lva-no-ends if there are chunks before and after this chunk
            //  No further class if the marker starts and ends on this chunk
            if (chunkIdx === firstChunkIdx) {
                if (chunkIdx !== lastChunkIdx) {
                    endClass = ' lva-left-end';
                } else {
                    endClass = '';
                }
            } else {
                if (chunkIdx === lastChunkIdx) {
                    endClass = ' lva-right-end';
                } else {
                    endClass = ' lva-no-ends';
                }
            }

            hintLine.line += "<span class='lva-hint lva-bar lva-"
                + (hint.filter ? hint.filter.selectedColor : (hint.colors ? hint.colors.color : 'blue'))
                + " lva-bg-white" + endClass + "'>";
        }


        //  Fill line with spaces to this marker
        while (hintLine.cPos < marker.cPos) {
            qChunk = chunk.qChunk.charAt(hintLine.cPos);
            sChunk = chunk.sChunk.charAt(hintLine.cPos);
            if (qChunk === "-" && sChunk !== "-") {
                hintLine.insAdjIdx++;
            }

            hintLine.line += " ";
            hintLine.cPos++;

            hintLine.qChunkFlags.push(0);
            hintLine.sChunkFlags.push(0);
        }

        qChunk = chunk.qChunk.charAt(hintLine.cPos);
        sChunk = chunk.sChunk.charAt(hintLine.cPos);
        if (qChunk === "-" && sChunk !== "-") {
            hintLine.insAdjIdx++;
        }

        //  Add this marker, "&#9660;" is to display the triangle
        hintLine.line += marker.action === null ? "&nbsp;" : "<span>&#9660;";
        hintLine.cPos++;

        if (marker.action === null) {
            chunkFlag = 0;
            qChunk = "";
            sChunk = "";
            if (marker.optionValue !== undefined) { // Not exact match
                chunkFlag = 4;
            }
        } else if (marker.optionValue !== undefined) { // Not exact match
            chunkFlag = 4;
        } else {
            chunkFlag = 1;
        }

        if (chunk.qChunk.charAt(hintLine.cPos - 1) === '-' || chunkFlag === 0) {
            hintLine.qChunkFlags.push(0);
        } else {
            hintLine.qChunkFlags.push(chunkFlag);
        }
        if (chunk.sChunk.charAt(hintLine.cPos - 1) === '-' || chunkFlag === 0) {
            hintLine.sChunkFlags.push(0);
        } else {
            hintLine.sChunkFlags.push(chunkFlag);
        }

        if (marker.action !== null) {
            if (marker.hintText !== "") {
                hintLine.line += "<span class='singletooltiptext'>" + marker.hintText + "</span></span>";
            } else {
                hintLine.line = hintLine.line.substring(0, hintLine.line.length - 13); // Remove the triangle
                hintLine.line += "&nbsp;";
            }
        }

    }

    if (chunkIdx !== null) {
        hintLine.line += "<span class='tooltiptext'>" + hintText + "</span></span>"; //"</span></span>";
    }

}

function compareMarkerCPos(a, b) {
    if (a.chunk.chunkIdx < b.chunk.chunkIdx) return -1;
    if (a.chunk.chunkIdx > b.chunk.chunkIdx) return +1;

    if (a.cPos < b.cPos) return -1;
    if (a.cPos > b.cPos) return +1;
    return 0;
}

/**
 * Get an existing hint line, or create a new one, for this marker's chunk and level.
 *
 * @param hintLines
 * @param marker
 * @returns {*}
 */
function getHintLine(hintLines, hint, marker) {
    let hintLine;
    if (marker.chunk.chunkIdx in hintLines) {
        if (hint.level in hintLines[marker.chunk.chunkIdx]) {
            hintLine = hintLines[marker.chunk.chunkIdx][hint.level];
        } else {
            hintLine = {
                line: "",
                qChunk: marker.chunk.qChunk,
                sChunk: marker.chunk.sChunk,
                qChunkFlags: [],
                sChunkFlags: [],
                cPos: 0,
                insAdjIdx: 0,
                chunkIdx: marker.chunk.chunkIdx,
                level: hint.level,
                above: hint.above,
                hint: hint
            };
            hintLines[marker.chunk.chunkIdx][hint.level] = hintLine;
        }
    } else {
        hintLines[marker.chunk.chunkIdx] = [];
        hintLine = {
            line: "",
            qChunk: marker.chunk.qChunk,
            sChunk: marker.chunk.sChunk,
            qChunkFlags: [],
            sChunkFlags: [],
            cPos: 0,
            insAdjIdx: 0,
            chunkIdx: marker.chunk.chunkIdx,
            level: hint.level,
            above: hint.above,
            hint: hint
        };
        hintLines[marker.chunk.chunkIdx][hint.level] = hintLine;
    }
    return hintLine;
}

function setHintAbove(hint) {
    hint.above = 'true';
}

/**
 * Decide how many levels up this hint should display, to avoid overlapping spans, by looking at the
 * hintLines we've generated so far, and making sure this hint doesn't conflict.
 *
 * @param hint
 * @param hintLines
 */
function setHintLevel(hint, hintLines) {
    let chunkIdx, lineIdx, line, level = 0;
    for (chunkIdx in hintLines) {
        for (lineIdx in hintLines[chunkIdx]) {
            line = hintLines[chunkIdx][lineIdx];
            if (line.above !== hint.above) {
                continue;
            }
            if (JSON.stringify(hint.filter) === JSON.stringify(line.hint.filter)) { // Same filter group
                continue;
            } else if (level <= line.level) {
                level = line.level + 1;
            }
        }
    }
    hint.level = level;
}

/**
 * Merge the hints if in the same filter group and having the overlap start/end positions.
 *
 * @param hint
 * @param newHints
 */
function mergeHintByFilter(hints, newHints) {
    let hintIdx, hint, lastHint = null,
        markerIdx, marker, tmpMarkerIdx, tmpMarker, matched;
    for (hintIdx in hints) {
        hint = hints[hintIdx];
        if (lastHint === null) {
            hint.hintText = getHintText(hint);
            newHints.push(hint);
            lastHint = hint;
        } else if (JSON.stringify(hint.filter) === JSON.stringify(lastHint.filter)) {
            // Same filter, try to merge the hints if overlap
            if (hint.qEnd >= lastHint.qStart && hint.qStart <= lastHint.qEnd) {
                var tmpHint = newHints.pop();
                if (hint.qEnd > tmpHint.qEnd) {
                    tmpHint.qEnd = hint.qEnd;
                }
                if (hint.qStart < tmpHint.qStart) {
                    tmpHint.qStart = hint.qStart;
                }
                if (hint.method.stop > tmpHint.method.stop) {
                    tmpHint.method.stop = hint.method.stop;
                }
                if (hint.method.start < tmpHint.method.start) {
                    tmpHint.method.start = hint.method.start;
                }
                if (tmpHint.method.method === 'deletions') {
                    tmpHint.method.method = hint.method.method;
                }
                tmpHint.hintText = getHintText(tmpHint);

                for (markerIdx in hint.markers) {
                    marker = hint.markers[markerIdx];
                    matched = false;
                    for (tmpMarkerIdx in tmpHint.markers) {
                        tmpMarker = tmpHint.markers[tmpMarkerIdx];
                        if ((JSON.stringify(marker.chunk) === JSON.stringify(tmpMarker.chunk)) && (marker.cPos === tmpMarker.cPos)) {
                            // Find the matched marker, change the action/methodValue when needed
                            matched = true;
                            if (marker.action !== null) {
                                tmpHint.markers[tmpMarkerIdx].action = marker.action;
                            }
                            if (marker.optionValue !== undefined) {
                                tmpHint.markers[tmpMarkerIdx].optionValue = marker.optionValue;
                            }

                            break;
                        }
                    }
                    // New marker for the existing hint, just add it in
                    if (!matched) {
                        tmpHint.markers.push(marker);
                    }
                }

                newHints.push(tmpHint);
                lastHint = tmpHint;
            } else {
                hint.hintText = getHintText(hint);
                newHints.push(hint);
                lastHint = hint;
            }
        } else {
            hint.hintText = getHintText(hint);
            newHints.push(hint);
            lastHint = hint;
        }
    }
}

/**
 * Get the descriptive text for this hint that will display when the user rolls over the span.
 *
 * @param hint
 * @returns {string}
 */
function getHintText(hint) {

    let text = hint.filter.lvaFilterName;
    let method = hint.method;
    if (method.method === 'deletions') {
        text += ":  from pos " + method.start;
    } else if (method.stop && method.stop !== method.start) {
        text += ":  pos " + method.start + "-" + method.stop;
    } else {
        text += ":  at pos " + method.start;
    }

    return text;
}

/**
 * Set the actual start/end for the hint within the method (i.e. the spots where characters affect the filter.
 * @param hints
 */
function setHintsStartEnd(hints) {
    let hintIdx, hint, markerIdx, marker, qStart, qEnd;
    for (hintIdx in hints) {
        qStart = null;
        qEnd = null;
        hint = hints[hintIdx];
        for (markerIdx in hint.markers) {
            marker = hint.markers[markerIdx];
            if (marker.qPos < qStart || qStart === null) {
                qStart = marker.qPos;
            }
            if (marker.qPos > qEnd || qEnd === null) {
                qEnd = marker.qPos;
            }
        }
        hint.qStart = qStart;
        hint.qEnd = qEnd;
    }
}

/**
 * Compare two hints, for consistent sorting for overlap detection.
 * @param a
 * @param b
 * @returns {number}
 */
function hintCompare(a, b) {
    // Keep the sort order same as the fitler list
    if (a.filter.filterIdx !== b.filter.filterIdx) return -(a.filter.filterIdx - b.filter.filterIdx);

    if (a.method.start < b.method.start) return -1;
    if (a.method.start > b.method.start) return +1;
    if (a.method.stop < b.method.stop) return -1;
    if (a.method.stop > b.method.stop) return +1;

    if (a.method.method < b.method.method) return -1;
    if (a.method.method > b.method.method) return +1;

    if (a.qStart < b.qStart) return -1;
    if (a.qStart > b.qStart) return +1;
    if (a.qEnd < b.qEnd) return -1;
    if (a.qEnd > b.qEnd) return +1;

    if (a.method.id < b.method.id) return -1;
    if (a.method.id > b.method.id) return +1;

    return 0;
}

/**
 * Get the hint using logic specific to the method.
 */
function getMethodHint(coveredChunks, method, seqData) {
    // TODO, how to draw the hint for %id
    /*if (method.method === "percIdentity") {
        return null;
    }*/
    let stateFunction;
    switch (method.method) {
        case "atleastone"   :
            stateFunction = hintAtLeastOne;
            break;
        case "matches"      :
            stateFunction = hintMatches;
            break;
        case "replacements" :
            stateFunction = hintReplacements;
            break;
        case "deletions"    :
            stateFunction = hintDeletions;
            break;
        case "anyinsert"    :
            stateFunction = hintAnyInsert;
            break;
        case "specinserts"  :
            stateFunction = hintSpecInsert;
            break;
        case "percIdentity"      :
            stateFunction = hintAtLeastOne;
            break;
        case "maxDiff"      :
            stateFunction = hintAtLeastOne;
            break;
        default             :
            return {};
    }

    let state = parseChunks(coveredChunks, method, seqData, stateFunction);
    if (method.negated) {
        state.failed = !state.failed;
    }

    return state;

}

/**
 * Parse all of the characters in the alignment in the range for a chunk.
 *
 * This approach uses a "stateUpdateFunction" function, so that the same parsing logic can be used for all methods.
 *
 * Each method passes in a different stateUpdateFunction function, which takes care of updating the state (basically,
 * the variables passed from one call to the next to the next to the last) for each character encountered in
 * the method range from start to stop.
 *
 * The last call to the state function is passed with only the state as a parameter, as a signal that processing
 * is complete, the entire range has been covered, and the hint can be returned.
 *
 * @param coveredChunks
 * @param method
 * @param seqData
 * @param stateUpdateFunction function(state, action, cPos, qPos, value, chunk)
 * @returns {*}
 */
function parseChunks(coveredChunks, method, seqData, stateUpdateFunction) {
    let qPos,   // query position (i.e. residue from 1 in the query)
        cPos,   // chunk position (i.e character from 0 in the chunk)
        reverse = seqData.RESULT_NFQ >= 3 && seqData.RESULT_NFQ <= 6,
        increment = +1,
        chunkIdx,
        cChunk, // coveredChunk
        chunk,
        qChunk,
        aChunk,
        sChunk,
        state = {   //  Every state contains the parameters needed through the test
            failed: true,
            coveredChunks: coveredChunks,
            method: _.cloneDeep(method), // clone the object, because of the method.stop change for max deletions
            seqData: seqData,
            complemented: reverse,
            markers: []
        },
        qChar,
        sChar, lastQFrRight = -1,
        qInsertAdj = -1;    //  For "insert after" forward, the match advanced us too soon; go back 1

    //  Initialize the state for this method
    stateUpdateFunction(state, 'init');

    //  Parse each chunk
    for (chunkIdx in coveredChunks) {
        cChunk = coveredChunks[chunkIdx];
        chunk = cChunk.chunk;
        qChunk = chunk.qChunk;
        aChunk = chunk.aChunk;
        sChunk = chunk.sChunk;
        qPos = reverse ? chunk.qFrRight : chunk.qFrLeft;
        // Special case handling, entire row was inserted, then qInsertAdj = 0
        qInsertAdj = -(lastQFrRight < 0 ? 1 : (chunk.qFrLeft - lastQFrRight));
        lastQFrRight = chunk.qFrRight;
        //  Parse each character in the chunk, determine the event, and pass it to the stateUpdateFunction function
        for (cPos = 0; cPos < qChunk.length; cPos++) {
            qChar = qChunk[cPos];
            sChar = sChunk[cPos];
            if (qChar === '-') {
                //  Query position must be adjusted up because inserts are recorded "before" the query position
                //  and we're walking backwards, so we've retreated too quickly relative to the insert position
                if (qPos + qInsertAdj < state.method.start || qPos + qInsertAdj > state.method.stop) {
                    continue;
                }
                //  Insertion
                stateUpdateFunction(state, 'I', cPos, qPos + qInsertAdj, sChar, chunk, method.negated, increment);
                //  qPos does not increment for an insertion
            } else {
                if (qPos >= state.method.start && qPos <= state.method.stop) {
                    if (sChar === '-') {
                        //  Deletion
                        stateUpdateFunction(state, 'D', cPos, qPos, qChar, chunk, method.negated, increment);
                    } else if (aChunk[cPos] === '|') {
                        //  Match
                        stateUpdateFunction(state, 'M', cPos, qPos, qChar, chunk, method.negated, increment);
                    } else {
                        //  Replacement
                        stateUpdateFunction(state, 'R', cPos, qPos, sChar, chunk, method.negated, increment);
                    }
                }
                qPos += increment;
            }
        }
    }

    //  Complete the method, returning any hints detected
    state = stateUpdateFunction(state, 'complete', null, null, null, null, method.negated, increment); //  Return the hints from the state

    //   Make sure the character positions are in the right order
    state.markers.sort(compareMarkerCPos);

    return state;
}

function hintAtLeastOne(state, action, cPos, qPos, value, chunk, negated, increment) {
    if (action === 'init') {

        state.failed = true;
        state.events = "";
        for (var event in state.method.events) {
            state.events += state.method.events[event].event;
        }

    } else if (action === 'complete') {

        return state;

    } else {
        if (state.events.indexOf(action) >= 0) {
            state.failed = false;   //  We found at least one matching event
            if (action === 'M') {
                state.markers.push({cPos: cPos, qPos: qPos, chunk: chunk, action: null}); // action: action
            } else {
                state.markers.push({
                    cPos: cPos, qPos: qPos, chunk: chunk, action: action,
                    lastSeq: getSeqBeforeInsertion(state, chunk, cPos)
                });
            }
        } else {
            if (state.events.indexOf('M') >= 0 && negated) {
                // Mark the not match one when "At least one, match, not true" selected
                state.markers.push({cPos: cPos, qPos: qPos, chunk: chunk, action: null, optionValue: 1});
            } else {
                state.markers.push({cPos: cPos, qPos: qPos, chunk: chunk, action: null});
            }
        }
    }
}

function hintMatches(state, action, cPos, qPos, value, chunk, negated, increment) {

    if (action === 'init') {

        state.failed = false;
        state.matched = 0;
        state.toMatch = state.method.stop - state.method.start + 1;

    } else if (action === 'complete') {

        if (state.matched < state.toMatch) {
            state.failed = true;
        }
        return state;

    } else {

        if (action === 'M') {
            state.matched += 1;
           // if (!negated) { // When 'Not true' is not seleted
                state.markers.push({cPos: cPos, qPos: qPos, chunk: chunk, action: null}); // action: action
           // } else { // When 'Not true' is seleted
           //     state.markers.push({cPos: cPos, qPos: qPos, chunk: chunk, action: null});
            //}
        } else {
            state.failed = true;
            if (!negated) { // When 'Not true' is not seleted
                state.markers.push({cPos: cPos, qPos: qPos, chunk: chunk, action: null});
            } else { // When 'Not true' is seleted
                state.markers.push({cPos: cPos, qPos: qPos, chunk: chunk, action: null, optionValue: 1});
            }
        }
    }
}

function hintReplacements(state, action, cPos, qPos, value, chunk, negated, increment) {

    if (action === 'init') {

        state.failed = true;
        //  Save the list of values treating any U or T as both U and T
        state.replacements = (state.method.replacements && state.method.replacements.length > 0) ? state.method.replacements.toUpperCase().replace(/[UT]/, 'UT') : '';

    } else if (action === 'complete') {

        return state;

    } else {

        if (action === 'R') {
            if (state.replacements.length > 0 && state.replacements.indexOf(value) >= 0) {
                state.failed = false;
                state.markers.push({cPos: cPos, qPos: qPos, chunk: chunk, action: action});
            } else if (state.replacements.length === 0) { // Any replacement
                state.failed = false;
                state.markers.push({cPos: cPos, qPos: qPos, chunk: chunk, action: action});
            } else {
                state.markers.push({cPos: cPos, qPos: qPos, chunk: chunk, action: null});
            }
        } else {
            state.markers.push({cPos: cPos, qPos: qPos, chunk: chunk, action: null});
        }

    }
}

function hintDeletions(state, action, cPos, qPos, value, chunk, negated, increment) {

    if (action === 'init') {

        state.failed = true;
        state.deletions = 0;
        state.nonDelete = false;

    } else if (action === 'complete') {

        state.failed = state.deletions <= 0 ||
            (state.method.maxDel !== null && state.deletions >= state.method.maxDel);
        return state;

    } else {
        if (action === 'D' && !state.nonDelete) {    //  Count continuous deletions only
            state.markers.push({cPos: cPos, qPos: qPos, chunk: chunk, action: action});
            state.deletions++;
        } else {
            state.nonDelete = true;
            state.method.stop = qPos - increment; // Stop it in the last 'D'
        }
    }
}

function hintAnyInsert(state, action, cPos, qPos, value, chunk, negated, increment) {

    if (action === 'init') {

        state.failed = true;
        state.sequence = "";

    } else if (action === 'complete') {

        return state;

    } else {
        if (action === 'I') {
            state.failed = false;
            state.markers.push({
                cPos: cPos, qPos: qPos, chunk: chunk, action: action,
                lastSeq: getSeqBeforeInsertion(state, chunk, cPos)
            });
            if (state.complemented) {
                state.sequence = value + state.sequence;
            } else {
                state.sequence += value;
            }
        } else {
            state.markers.push({cPos: cPos, qPos: qPos, chunk: chunk, action: null});
        }
    }
}

function hintSpecInsert(state, action, cPos, qPos, value, chunk, negated, increment) {

    if (action === 'init') {

        state.failed = true;
        state.sequence = '';
        state.seqList = [];
        state.posList = [];

        state.regexValues = [];
        let tmpValues = state.method.inserts.replace(',', "\n").replace(';', "\n");
        let value, values = tmpValues.split('\n');
        //  Convert all of our value lists into regular expressions, with special values for wildcards and UT
        for (let valueIdx in values) {
            value = values[valueIdx];
            if (value.length > 0) {
                let regexStr = value.toUpperCase()
                    .replace('*', '.*')      //  * wildcard is .* in regex
                    .replace('+', '.+')      //  + wildcard is .+ in regex
                    .replaceAll('?', '.')       //  ? wildcard is .  in regex
                    .replace('U', '[UT]')   // U and T match themselves and each other in regex
                    .replace('T', '[UT]');
                state.regexValues.push(new RegExp(regexStr, 'i'));
            }
        }

    } else if (action === 'complete') {

        if (state.sequence.length > 0) {
            state.seqList.push({
                sequence: state.sequence,
                posList: state.posList
            });
        }

        //  For any sequence that matches a regular expression sequence, add markers for it
        let seqIdx, sequence, regexIdx, posIdx, pos;
        for (seqIdx in state.seqList) {
            sequence = state.seqList[seqIdx];
            for (regexIdx in state.regexValues) {
                if (sequence.sequence.match(state.regexValues[regexIdx])) {
                    state.failed = false;
                    for (posIdx in sequence.posList) {
                        pos = sequence.posList[posIdx];
                        state.markers.push({
                            cPos: pos.cPos,
                            qPos: pos.qPos,
                            chunk: pos.chunk,
                            action: 'I',
                            sequence: sequence,
                            lastSeq: getSeqBeforeInsertion(state, pos.chunk, pos.cPos)
                        });
                    }
                    break;
                }
            }
            state.markers.sort(compareMarkerCPos);
        }

        return state;

    } else {

        if (action === 'I') {
            state.posList.push({
                cPos: cPos,
                qPos: qPos,
                chunk: chunk
            });
            if (state.complemented) {
                state.sequence = value + state.sequence;
            } else {
                state.sequence += value;
            }
        } else {
            //  End any in progress sequence
            if (state.sequence.length > 0) {
                state.seqList.push({
                    sequence: state.sequence,
                    posList: state.posList
                });
                state.sequence = "";
                state.posList = [];
            }
            state.markers.push({cPos: cPos, qPos: qPos, chunk: chunk, action: null});
        }
    }
}

function getSeqBeforeInsertion(state, currentChunk, cPos) {
    if (cPos >= 1) {
        return currentChunk.qChunk.charAt(cPos - 1);
    } else {
        let lastChunk, chunkIdx;
        // Loop state.coveredChunks to get the previous chunk
        for (chunkIdx in state.coveredChunks) {
            if (state.coveredChunks[chunkIdx].chunkIdx === currentChunk.chunkIdx - 1) {
                lastChunk = state.coveredChunks[chunkIdx].chunk;
                break;
            }
        }
        //var lastChunk = state.coveredChunks[currentChunk.chunkIdx - 2];
        if (lastChunk && lastChunk.qChunk) {
            return lastChunk.qChunk.charAt(lastChunk.qChunk.length - 1);
        }
        return "";
    }
}

function extractLvaHintInfo(seqData) {
    return {
        QUERY_N: seqData.QUERY_N, //  Query ID number
        QUERY_ID: seqData.QUERY_ID,
        QUERY_L: Number(seqData.QUERY_L), //  Query length
        SUBJECT_T: seqData.SUBJECT_T,
        RESULT_FALI_FORMATTED: seqData.RESULT_FALI_FORMATTED,   //  Formatted alignment
        RESULT_NFD: seqData.RESULT_NFD,              //  Subject frame translation
        RESULT_NFQ: seqData.RESULT_NFQ,              //  Query   frame translation
        RESULT_OBD: Number(seqData.RESULT_OBD),      //  Original subject start
        // RESULT_OBQ: Number(seqData.RESULT_OBQ),      //  Original query   start
        RESULT_OED: Number(seqData.RESULT_OED),      //  Original subject end
        // RESULT_OEQ: Number(seqData.RESULT_OEQ),      //  Original query   end
        RESULT_OOBD: Number(seqData.RESULT_OOBD),     //  Translated frame subject start
        RESULT_OOBQ: Number(seqData.RESULT_OOBQ),     //  Translated frame query   start
        RESULT_OOED: Number(seqData.RESULT_OOED),     //  Translated frame subject end
        RESULT_OOEQ: Number(seqData.RESULT_OOEQ),     //  Translated frame query   end
        RESULT_ALI_DIR: seqData.RESULT_ALI_DIR // Direction (frame) display arrow
    };
}

/**
 * Get a data structure containing the hint lines to use to decorate an alignment summary table display.
 * @param seqData
 * @returns {{hintLines: Array, hints: [], seqData: *}}
 */
function getAlignmentSummary(seqData) {
    let hintLines = [],
        hints = [],
        chunks = getAlignmentChunks(seqData),
        filter = {},
        methodIdx,
        method;

    // Create a dummy filter to get all "Replacement", "Insertion" and "Deletion" markers
    filter.filterIdx = 0;
    filter.lvaFilterName = "dummyAlignSumm";
    filter.selectedColor = "blue";
    filter.selectedColorIdx = 5;
    filter.lvaQuery = {
        QUERY_L: seqData.QUERY_L,
        QUERY_N: seqData.QUERY_N,
        RESULTS: 1,
        RESULT_NFQ: seqData.RESULT_NFQ,
        SUBJECT_T: seqData.SUBJECT_T,
        copyFromOther: false,
        copyFromQuery: ""
    };
    filter.widgetContent = [
        {
            colors: {color: "blue", bgColor: "white"},
            events: [{event: "R"}, {event: "I"}, {event: "D"}],
            hasPosMaxValue: true, method: "atleastone",
            negated: false,
            start: 1,
            stop: seqData.QUERY_L,
            lvaEventOptions: _.cloneDeep(lvaEventOptions)
        }];

    for (methodIdx in filter.widgetContent) {
        method = filter.widgetContent[methodIdx];
        addHint(hints, seqData, chunks, filter, method, method.colors);
    }

    //  Construct the lines needed to display the hints within the alignment
    addHintLines(hintLines, hints);

    return {hintLines: hintLines, hints: hints, seqData: seqData};
}

const minSmartTextLen = 2;

// tells the UI whether the popup with smart text autocompletions should be shown
function filterTextLongEnough(filter) {
    return (filter.state.smartText.length >= minSmartTextLen);
}

function highlightTextLongEnough(filter) {
    return (filter.state.smartHighlight.length >= minSmartTextLen);
}


function generateUUID() { // Generate UUID for filters
    let d = new Date().getTime();
    if (typeof performance !== 'undefined' && typeof performance.now === 'function'){
        d += performance.now(); //use high-precision timer if available
    }
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        let r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
}

function addDefaultCustomFilters(filter, fieldConfig) {
    let defaultFilters = [
        {
            widgetContent: [{
                field: "RESULT_RIQ",
                field_type: "percentage",
                operator: "MORE",
                value: 80,
                second_value: ""
            }, {
                field: "RESULT_RID",
                field_type: "percentage",
                operator: "MORE",
                value: 80,
                second_value: ""
            }, {
                field: "RESULT_RI",
                field_type: "percentage",
                operator: "MORE",
                value: 90,
                second_value: ""
            }, {
                field: "RESULT_RCQ",
                field_type: "percentage",
                operator: "MORE",
                value: 50,
                second_value: ""
            }],
            widgetMode: "CUSTOM",
            widgetCustomQuery: "f1 or f2 or (f3 and f4)",
            customFilterName: "% id simple Boolean",
            defaultFilter: true
        },
        {
            widgetContent: [{
                field: "RESULT_RID",
                field_type: "percentage",
                operator: "MORE",
                value: 80,
                second_value: ""
            }],
            widgetMode: "AND",
            widgetCustomQuery: "",
            customFilterName: "Query comprises subject",
            defaultFilter: true
        },
        {
            widgetContent: [{
                field: "RESULT_GAPQ",

                field_type: "int",
                operator: "EQUALS",
                value: 0,
                second_value: ""
            }, {
                field: "RESULT_GAPD",
                field_type: "int",
                operator: "EQUALS",
                value: 0,
                second_value: ""
            }],
            widgetMode: "AND",
            widgetCustomQuery: "",
            customFilterName: "Remove gapped hits",
            defaultFilter: true
        },
        {
            widgetContent: [{
                field: "QUERY_ID",
                field_type: "string",
                operator: "CONTAINS",
                value: "*cdr*",
                second_value: ""
            }, {
                field: "RESULT_RS",
                field_type: "percentage",
                operator: "LESS",
                value: 1,
                second_value: ""
            }, {
                field: "QUERY_ID",
                field_type: "string",
                operator: "EQUALS",
                value: "lc\nhc",
                second_value: ""
            }, {
                field: "RESULT_RIQ",
                field_type: "percentage",
                operator: "MORE",
                value: 90,
                second_value: ""
            }],
            widgetMode: "CUSTOM",
            widgetCustomQuery: "(f1 and f2) or (f3 and f4)",
            customFilterName: "Ab Filter One Diff 90 Chain",
            defaultFilter: true
        }
    ];
    //if(filter.widgetState == undefined ){
      // filter.widgetState.defaultCustomFilters=[];
   // }
    if (filter.widgetState.defaultCustomFilters == null) {
        filter.widgetState.defaultCustomFilters = [];
    }
    filter.widgetState.defaultCustomFilters = defaultFilters;

    // save the config after the filters have been updated
    filter.widgetState.defaultFilterAdded = true;
    fieldConfig.saveConfig(fieldConfig, 'default', filter);
}

function addLvaFilterToList(filter, fieldConfig) {
    // Clear the copy from flags
    filter.lvaWidgetState.lvaQuery.copyFromOther = false;
    filter.lvaWidgetState.lvaQuery.copyFromQuery = "";

    // push the new filter to the end of the list
    let newLvaFilter = {
        uuid: generateUUID(),
        widgetContent: _.cloneDeep(filter.lvaWidgetState.widgetContent),
        lvaFilterName: filter.lvaWidgetState.lvaFilterName,
        widgetMode: filter.lvaWidgetState.widgetMode,
        widgetCustomQuery: filter.lvaWidgetState.widgetCustomQuery,
        lvaQuery: filter.lvaWidgetState.lvaQuery,
        selectedColor: filter.lvaWidgetState.selectedColor ? filter.lvaWidgetState.selectedColor : "blue",
        selectedColorIdx: filter.lvaWidgetState.selectedColorIdx ? filter.lvaWidgetState.selectedColorIdx : "5",
    };
    /*
    newLvaFilter.widgetContent.sort(
        function (a, b) {
            if (a.start < b.start) return -1;
            if (a.start > b.start) return 1;
            if (a.stop < b.stop) return -1;
            if (a.stop > b.stop) return 1;
            return 0;
        }
    );*/
    let existingFilterIndex = filter.lvaWidgetState.filterNameUsed;

    if (existingFilterIndex === -1) {
        // add the new filter to list of filters
        if (!filter.lvaWidgetState.lvaFilters) {
            filter.lvaWidgetState.lvaFilters = [];
        }
        filter.lvaWidgetState.lvaFilters.push(newLvaFilter);
        // check the checkbox in the UI
        let indexLastFilter = filter.lvaWidgetState.lvaFilters.length - 1;
        filter.state.lvaFilterCheckboxes[indexLastFilter] = true;
        //UIlog.record('LVAFILTER_NEW', filter.lvaWidgetState.lvaFilterName);
    } else {
        // overwrite existing filter with the new version of it
        newLvaFilter.uuid = filter.lvaWidgetState.lvaFilters[existingFilterIndex].uuid;
        filter.lvaWidgetState.lvaFilters.splice(existingFilterIndex, 1, newLvaFilter);
        //UIlog.record('LVAFILTER_UPDATE', self.lvaWidgetState.lvaFilterName);
    }

    // Before sorting, maintain a filtername => index map,
    let nameIdxMap = [];
    let idx = 0;
    _.forEach(filter.lvaWidgetState.lvaFilters, function (val) {
        nameIdxMap[val.lvaFilterName] = idx;
        idx++;
    });

    // Sort by name
    filter.lvaWidgetState.lvaFilters.sort(
        function (a, b) {
            return a.lvaFilterName.localeCompare(b.lvaFilterName);
        }
    );

    // After sorting
    // Sort the checkboxes by name as well
    let sortedCheckboxes = [];
    idx = 0;
    _.forEach(filter.lvaWidgetState.lvaFilters, function (val) {
        // nameIdxMap[val.lvaFilterName] returns the previous index in lvaFilterCheckboxes
        sortedCheckboxes[idx] = filter.state.lvaFilterCheckboxes[nameIdxMap[val.lvaFilterName]];
        idx++;
    });
    filter.state.lvaFilterCheckboxes = [];
    filter.state.lvaFilterCheckboxes = _.cloneDeep(sortedCheckboxes);
    nameIdxMap = null;
    sortedCheckboxes = null;

    // _.forEach(newLvaFilter.widgetContent, function (val) {
    //    UIlog.record('LVAFILTER_SPEC', val.spec);
    // });
    //self.enableApplyButton();
    // save the config after the filters have been updated, moved to resbrowseCtrl.js
    // self.saveResultState();

    if (filter.lvaWidgetState.saveAsGlobal) {
        let newGlobalLvaFilter = _.cloneDeep(newLvaFilter);
        newGlobalLvaFilter.lvaQuery.QUERY_ID = "";
        newGlobalLvaFilter.lvaQuery.copyFromOther = true;
        existingFilterIndex = filter.lvaWidgetState.globalLvaFilterNameUsed;
        if (existingFilterIndex === -1) {
            // add the new filter to list of filters
            filter.widgetState.globalLvaFilters.push(newGlobalLvaFilter);
            //UIlog.record('GLOBAL_LVAFILTER_NEW', self.lvaWidgetState.lvaFilterName);
        } else {
            // overwrite existing filter with the new version of it
            filter.widgetState.globalLvaFilters.splice(existingFilterIndex, 1, newGlobalLvaFilter);
            //UIlog.record('GLOBAL_LVAFILTER_UPDATE', self.lvaWidgetState.lvaFilterName);
        }

        // Sort by name
        filter.widgetState.globalLvaFilters.sort(
            function (a, b) {
                return a.lvaFilterName.localeCompare(b.lvaFilterName);
            }
        );

        // save the config after the filters have been updated
        fieldConfig.saveConfig(fieldConfig, 'default', filter);
    }
}

function addCustomFilterToList(filter, fieldConfig) {
    let defaultSize = filter.widgetState.defaultCustomFilters.length;
    // push the new filter to the end of the list
    let newFilter = {
        uuid: generateUUID(),
        widgetContent: _.cloneDeep(filter.widgetState.widgetContent),
        widgetMode: filter.widgetState.widgetMode,
        widgetCustomQuery: filter.widgetState.widgetCustomQuery,
        customFilterName: filter.widgetState.customFilterName,
        defaultFilter: false
    };

    let wContent = newFilter.widgetContent;
    for (let i = 0; i < wContent.length; i++) {
        let content = wContent[i];
        if (content.field_type === 'date') {
            //content.value = new Date(content.value).toDateString();
            //content.second_value = new Date(content.second_value).toDateString();
        } else if (content.field_type === 'int' || content.field_type === 'float' || content.field_type === 'percentage') {
            content.value = Number(content.value);
            if (content.second_value) {
                content.second_value = Number(content.second_value);
            }
        }
    }

    let existingFilterIndex = filter.widgetState.filterNameUsed; //self.customFilterNameUsed();

    if (existingFilterIndex === -1) {
        // add the new filter to list of filters
        filter.widgetState.customFilters.push(newFilter);
        // check the checkbox in the UI, Because we have 3 default filters, so the index should +3
        filter.state.customFilterCheckboxes[filter.widgetState.customFilters.length - 1 + defaultSize] = true;
        //UILog.record('CUSTOMFILTER_NEW', filter.widgetState.customFilterName);
    } else {
        // overwrite existing filter with the new version of it
        newFilter.uuid = filter.widgetState.customFilters[existingFilterIndex].uuid;
        filter.widgetState.customFilters.splice(existingFilterIndex, 1, newFilter);
        //UILog.record('CUSTOMFILTER_UPDATE', filter.widgetState.customFilterName);
    }

    // Before sorting, maintain a filtername => index map,
    let nameIdxMap = [];
    let idx = 0;
    _.forEach(filter.widgetState.customFilters, function (val) {
        nameIdxMap[val.customFilterName] = idx + defaultSize;
        idx++;
    });
    // Sort by name
    filter.widgetState.customFilters.sort(
        function (a, b) {
            return a.customFilterName.localeCompare(b.customFilterName);
        }
    );
    // Sort the checkboxes by name as well
    let sortedCheckboxes = [];
    // Default filters
    for (let i = 0; i < defaultSize; i++) {
        sortedCheckboxes[i] = filter.state.customFilterCheckboxes[i];
    }
    idx = defaultSize;
    _.forEach(filter.widgetState.customFilters, function (val) {
        // nameIdxMap[val.customFilterName] returns the previous index in customFilterCheckboxes
        sortedCheckboxes[idx] = filter.state.customFilterCheckboxes[nameIdxMap[val.customFilterName]];
        idx++;
    });
    filter.state.customFilterCheckboxes = [];
    filter.state.customFilterCheckboxes = _.cloneDeep(sortedCheckboxes);
    nameIdxMap = null;
    sortedCheckboxes = null;

    //self.enableApplyButton();
    // save the config after the filters have been updated
    fieldConfig.saveConfig(fieldConfig, 'default', filter);
}

const ResultFilter = {
    state,
    gqApiKey,
    userdir,
    widgetState,
    extractLvaHintInfo,
    getLvaAlignmentHints,
    returnQuery,
    getQuery,
    getAlignmentSummary,
    filterTextLongEnough,
    highlightTextLongEnough,
    prepPublicationNumberFilter,
    generateUUID,
    addDefaultCustomFilters,
    addCustomFilterToList,
    addLvaFilterToList,
};
export default ResultFilter;
