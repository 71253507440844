import React, { useState, Fragment, useEffect } from "react";
import { url } from "../../reducers/url";
import { useHistory } from "react-router-dom";
import { post } from '../../helpers/fetchServicesMethods';
import PubSub from 'pubsub-js';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/Warning';
import CancelIcon from '@material-ui/icons/Cancel';

function showLoader() {
    PubSub.publish('msg', true);
}

function hideLoader() {
    PubSub.publish('msg', false);
}

const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1,
        width: '96%',
    },
}));

async function getPatentDetails(queryID, searchType, history) {
    try {
        let patentSearchResult = url.patentSearchResult;
        if(searchType === 'type1'){
            patentSearchResult = patentSearchResult.replace('**', false)
        }
        else{
            patentSearchResult = patentSearchResult.replace('**', true)
        }
        const data = {
            q: queryID,
            field: "PN",
        };

        showLoader();
        return await post(patentSearchResult, data, history)
            .then((response) => {
                hideLoader();
                return response;
            })
            .catch((error) => {
                console.log("error::", error);
                hideLoader();
            });
    } catch (error) {
        hideLoader();
        console.error(error);
    }
}

function PatentSearchResult(props) {
    const queryID = props.location.state?.textareaId || localStorage.getItem('queryID');
    const searchType = props.location.state?.searchType || localStorage.getItem('searchType');

    const [qmap, setQmap] = useState([]);
    const [patentFamilies, setPatentFamilies] = useState([]);
    const [dbs, setDbs] = useState();
    const classes = useStyles();
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [start, setStart] = useState(1);
    const [adjustedStop, setAdjustedStop] = useState(100);
    const [qmapCount, setQmapCount] = useState(null);
    const [qmapKeys, setQmapKeys] = useState([]);
    const [pnResult, setPnResult] = useState([]);

    useEffect(() => {
        async function fetchMyAPI() {
            try {
                const result = await getPatentDetails(queryID, searchType);
                setPnResult(result);
                console.log('getPatentDetails',result);
                const initialData = result.response_content;
                setDbs(result.response_content.dbs.join(','));
                const qmapList = initialData.qmap ? Object.entries(result.response_content.qmap).map(([key, value]) => ({ key, value })) : [];
                setQmap(qmapList);
                console.log('qmapList', qmapList);
                setQmapCount(qmapList.length);
                setAdjustedStop(qmapList.length > 100 ? 100 : qmapList.length);
                const familiesList = initialData.families ? Object.values(initialData.families) : [];
                setPatentFamilies(familiesList);
                setLoading(false);
                localStorage.setItem('queryID', queryID); // Store queryID in local storage

                // Extract and store keys
                const keys = qmapList.map(item => item.key);
                setQmapKeys(keys);
                console.log('qmapKeys', keys);
                localStorage.setItem('qmapKeys', JSON.stringify(keys));
            } catch (error) {
                console.error(error);
                setLoading(false);
            }
        }
        fetchMyAPI()
    }, [])

    if (loading) {
        return <div>
            “please wait while we search”
        </div>
    }
    const handleLaunchButton = () => {
        history.push('/patentnumbersearch/');
    };
    
    // Filter the qmap to get the current page items
    const currentPageQmap = qmap && qmap.length > 0 ? qmap.slice(start - 1, adjustedStop) : [];

    // Filter the patent families to include only those present in the current page
    const filteredPatentFamilies = patentFamilies && patentFamilies.length > 0 ? patentFamilies.filter(family =>
        currentPageQmap.some(item =>
            item.value.family_id === family[0]?.SEQUENCE_PB
        )
    ) : [];

    //new page for patent number filter result
    function openLinkInNewTab() {
        const workflowUrl = "#/patentDatabases";
        const newWindow = window.open(workflowUrl, '_blank');
        newWindow.window.focus();
    }

    // function matchingResultTab(filter) {
    //     const workflowUrl = process.env.REACT_APP_API_URL + 'do=gqfetch&db='+dbs+'&masterview=';
    //     const data = {
    //         filter: filter,
    //     };
 
    //     // Create a form element
    //     var form = document.createElement("form");
    //     form.method = "post";
    //     form.action = workflowUrl;
    //     form.target = "_blank";
    //     form.style.display = "none";
 
    //     // Add form fields for each key-value pair in the data object
    //     for (var key in data) {
    //         var input = document.createElement("input");
    //         input.type = "hidden";
    //         input.name = key;
    //         input.value = data[key];
    //         form.appendChild(input);
    //         console.log('input', input)
    //     }
    //     console.log('form', form)
 
    //     // Append the form to the body and submit it
    //     document.body.appendChild(form);
    //     form.submit();
    // }

    async function matchingResultTab(filter, pn) {
        const workflowUrl = process.env.REACT_APP_API_URL + 'do=gqfetch&db=' + dbs + '&masterview=&';
        const data = {
            filter: filter,
        };
    
        // Check if the URL length exceeds 2070 characters
        const fullUrl = `${workflowUrl}${new URLSearchParams(data).toString()}`;
    
        if (fullUrl.length > 2070) {
            // Perform the two service calls
            const sessionizeUrl = process.env.REACT_APP_API_URL + "do=gqfetch.sessionize&db=def_id:" + dbs + '&masterview=';
            const filterUrl = process.env.REACT_APP_API_URL + "do=gqfetch&filter=session&db=def_id:" + dbs + '&masterview=';
            
            try {
                const sessionizeResponse = await fetch(sessionizeUrl, {
                    method: 'POST',
                    body: JSON.stringify(data),
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                if (sessionizeResponse && sessionizeResponse.ok) {
                    // Open the filter URL in a new tab
                    window.open(filterUrl, '_blank');
                }
            } catch (error) {
                console.error('Error making the sessionize request:', error);
            }
        } else {
            try {
                let smallPnUrl = '';
                if (pn) {
                    smallPnUrl = process.env.REACT_APP_API_URL + url.matchingPN.replace('**', encodeURIComponent(qmapKeys)).replace('##', dbs);
                } else {
                    smallPnUrl = process.env.REACT_APP_API_URL + url.matchingPNorRF.replace('**', encodeURIComponent(qmapKeys)).replace('$$', encodeURIComponent(qmapKeys)).replace('##', dbs);
                }
    
                console.log('Exurl:', smallPnUrl);
                window.open(smallPnUrl, '_blank');
 
            } catch (error) {
                console.error('Error making the GET request:', error);
            }
        }
    }
    
    const handleLeftDoubleShift = async () => {
        setStart(1);
        setAdjustedStop(100);
    }

    const handleRightDoubleShift = async (mtTotalCount) => {
        const itemsPerPage = 50;
        let newStart = Math.max(Math.ceil(mtTotalCount / itemsPerPage) * itemsPerPage - itemsPerPage + 1, 1);
        const stop = mtTotalCount;

        setStart(newStart);
        setAdjustedStop(stop);
    };

    const handleRightShift = async (adjustedStop, totalQmapCount) => {
        console.log("clicked on right shift");

        const itemsPerPage = 100;
        const newStart = adjustedStop + 1;
        const newStop = adjustedStop + itemsPerPage;

        const newAdjustedStop = Math.min(newStop, totalQmapCount);
        setStart(newStart);
        setAdjustedStop(newAdjustedStop);
    };
    const handleLeftShift = async (totalMtDataCount) => {
        console.log("clicked on left shift");

        const itemsPerPage = 100;
        let newStart = start - itemsPerPage;
        if (newStart < 1) {
            newStart = 1;
        }
        const newStop = newStart + itemsPerPage - 1;
        const newAdjustedStop = Math.min(newStop, totalMtDataCount);

        setStart(newStart);
        setAdjustedStop(newAdjustedStop);
    };

    function handleEmailClick(id){
        const subject = encodeURIComponent('GQ Error ['+id+']');
        const mailtoLink = `mailto:support@gqlifesciences.com?subject=${subject}`;
        window.location.href = mailtoLink;
    };

    return (
        <div className={classes.grow}>
            {pnResult.response_status === 0 && <div align='center' style={{ fontFamily: 'verdana, helvetica', fontSize: '12px' }} >
                <div style={{ paddingTop: '14px' }}> See all sequences with either
                    <a href="#" onClick={(e) => {
                        e.preventDefault();
                        const encodedFilter = `(filters:!((O:CT,P:SEQUENCE_PN,V:'${qmapKeys}')),matchtype:AND,ver:2)`;
                        matchingResultTab(encodedFilter, true);
                    }}><b> matching patent number </b></a>
                    or
                    <a href="#" onClick={(e) => {
                        e.preventDefault();
                        const encodedFilter = `(filters:!((O:CT,P:SEQUENCE_PN,V:'${qmapKeys}'),(O:CT,P:SEQUENCE_PM,V:'${qmapKeys}')),matchtype:OR,ver:2)`;
                        matchingResultTab(encodedFilter,false);
                    }}><b> matching patent number or in related families</b></a>
                    <div align='right' style={{ marginTop: '-20px', marginRight: '55px' }}>
                        <button className='DMTBtn' style={{ padding: '6px' }} onClick={handleLaunchButton}>Launch New</button>
                    </div>
                </div>
                <div className='toolbar mToolbar'>
                    <table style={{ width: '100%' }}>
                        <tbody>
                            <tr>
                                <td style={{ width: '33%' }}>
                                </td>
                                <td style={{ width: '33%', fontSize: '12px', fontFamily: 'verdana, helvetica' }} align='center'>
                                    <button
                                        className={`columnNo ${start === 1 ? 'disabled' : ''}`}
                                        style={{ color: start === 1 ? 'gray' : 'black' }}
                                        onClick={() => start !== 1 && handleLeftDoubleShift()}
                                    >
                                        &lt;&lt;
                                    </button>
                                    <button
                                        className={`columnNo ${start === 1 ? 'disabled' : ''}`}
                                        style={{ color: start === 1 ? 'gray' : 'black' }}
                                        onClick={() => start !== 1 && handleLeftShift(qmapCount)}
                                    >
                                        &lt;
                                    </button>
                                    <span className='colNoCSS'>
                                        {qmap && `${start} - ${adjustedStop} of ${qmap.length}`}
                                    </span>
                                    <button
                                        className={`columnNo ${adjustedStop === qmapCount ? 'disabled' : ''}`}
                                        style={{ color: adjustedStop === qmapCount ? 'gray' : 'black' }}
                                        onClick={() => adjustedStop !== qmapCount && handleRightShift(adjustedStop, qmapCount)}
                                    >
                                        &gt;
                                    </button>
                                    <button
                                        className={`columnNo ${adjustedStop === qmapCount ? 'disabled' : ''}`}
                                        style={{ color: adjustedStop === qmapCount ? 'gray' : 'black' }}
                                        onClick={() => handleRightDoubleShift(qmapCount)}
                                    >
                                        &gt;&gt;
                                    </button>
                                </td>
                                <td style={{ width: '33%' }} align='right'>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <br />
                <div>
                    <table style={{ width: '100%', fontFamily: 'verdana, helvetica', fontSize: '12px' }}>
                        <thead className='resulttable_head'>
                            <tr>
                                <th style={{ width: '5%', padding: '4px', fontWeight: 'normal' }}></th>
                                <th style={{ borderLeft: '1px solid white', fontWeight: 'normal' }}>Patent Number</th>
                                <th style={{ borderLeft: '1px solid white', fontWeight: 'normal', width: '10%' }}>Family Id</th>
                                <th style={{ borderLeft: '1px solid white', fontWeight: 'normal' }}>Title</th>
                            </tr>
                        </thead>
                        <tbody className='tableCSS' style={{ verticalAlign: 'top' }}>
                            {qmap && qmap.slice(start - 1, start + 99).map((item, index) => (
                                <tr className="table_body" key={index}>
                                    <td style={{ padding: '4px' }} >
                                        <Tooltip title={item.value.found === 'DIRECT' ? 'found' : item.value.found === 'MISS' ? 'not found' : 'found only in family'}>
                                            {item.value.found === 'DIRECT' ? (
                                                <CheckCircleIcon style={{ color: 'green' }} />
                                            ) : item.value.found === 'MISS' ? (
                                                <CancelIcon style={{ color: '#d44848' }} />
                                            ) : (
                                                <WarningIcon style={{ color: '#e9b34f' }} />
                                            )}
                                        </Tooltip>
                                    </td>
                                    <td>
                                        {item.value.found !== "MISS" ? (
                                            item.value.found !== "INDIRECT" ? (
                                                <a
                                                    href={`${process.env.REACT_APP_API_URL}${url.patentNumberOldLink.replace('**', encodeURIComponent(item.key)).replace('##', dbs)}`}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    {item.key}
                                                </a>
                                            ) : (
                                                item.key
                                            )
                                        ) : item.key}
                                        {item.key !== item.value.original && `(input PN: ${item.value.original})`}
                                    </td>
                                    <td style={{ padding: '4px' }} >{item.value.family_id}</td>
                                    <td style={{ padding: '4px' }} >{item.value.found === 'INDIRECT' ? 'This patent is not found, but its family member is found like this data' : item.value.title}</td>
                                </tr>
                            ))}
                        </tbody>

                    </table>
                    <br></br>
                    <h6 align='left'>Related Patent Families</h6>
                    <table style={{ width: '100%', fontFamily: 'verdana, helvetica', fontSize: '12px', border: '1px solid #dcd7c0' }}>
                        <thead className='resulttable_head'>
                            <tr>
                                <th style={{ width: '2%', padding: '4px', fontWeight: 'normal' }}></th>
                                <th style={{ borderLeft: '1px solid white', padding: '4px', fontWeight: 'normal' }}>Patent Number</th>
                                <th style={{ borderLeft: '1px solid white', fontWeight: 'normal', width: '10%' }}>Legal Status</th>
                                <th style={{ borderLeft: '1px solid white', fontWeight: 'normal' }}>Nb. Seqs</th>
                                <th style={{ borderLeft: '1px solid white', fontWeight: 'normal' }}>Claimed Seqs</th>
                                <th style={{ borderLeft: '1px solid white', fontWeight: 'normal' }}>Pub. Date</th>
                                <th style={{ borderLeft: '1px solid white', fontWeight: 'normal' }}>Patent Assignee	</th>
                                <th style={{ borderLeft: '1px solid white', fontWeight: 'normal' }}>Data Source</th>
                            </tr>
                        </thead>

                        <tbody>
                            {filteredPatentFamilies && filteredPatentFamilies.map((item, index) => (
                                <Fragment key={index}>
                                    <tr className="table_body tableCSS">
                                        <td colSpan="8" style={{ padding: '4px' }}>
                                            <ul>
                                                <li><b>Family Id: <a href={process.env.REACT_APP_API_URL + url.pnFamilyId.replace('**', encodeURIComponent(item[0]?.SEQUENCE_PB)).replace('##', dbs)} target="_blank" rel="noreferrer">
                                                    {item[0]?.SEQUENCE_PB}</a></b></li>
                                            </ul>
                                        </td>
                                    </tr>
                                    <tr className="tableCSS">
                                        <td style={{ padding: '4px' }}>&nbsp;</td>
                                        <td colSpan="7" style={{ padding: '4px' }}>All members: {item[0]?.SEQUENCE_PM}</td>
                                    </tr>
                                    {item.map((sequence, seqIndex) => (
                                        <tr key={seqIndex} className="tableCSS">
                                            <td style={{ padding: '4px' }}>&nbsp;</td>
                                            <td style={{ padding: '4px' }}><a href={process.env.REACT_APP_API_URL + url.relatedPatentNumber.replace('**', encodeURIComponent(sequence?.SEQUENCE_PN)).replace('##', dbs)} target="_blank" rel="noreferrer">{sequence?.SEQUENCE_PN}</a></td>
                                            {/* <td style={{ padding: '4px' }}><a onClick={openLinkInNewTab}>{sequence?.SEQUENCE_PN}</a></td> */}
                                            <td style={{ padding: '4px' }}>{sequence?.SEQUENCE_LS}</td>
                                            <td style={{ padding: '4px' }}>{sequence?.SEQUENCE_P9}</td>
                                            <td style={{ padding: '4px' }}>{sequence?.SEQUENCE_PO}</td>
                                            <td style={{ padding: '4px' }}>{sequence?.SEQUENCE_D1}</td>
                                            <td style={{ padding: '4px' }}>{sequence?.SEQUENCE_PA}</td>
                                            <td style={{ padding: '4px' }}>{sequence?.SEQUENCE_GNAME_desc}</td>
                                        </tr>
                                    ))}
                                </Fragment>
                            ))}
                        </tbody>

                    </table>
                </div>
            </div>}

            {/*error messages*/}
            {pnResult.response_status === 1 && (
                pnResult.response_content.type === 'GQSCRIPTKEYWORDSYNTAXEXCEPTION' ? (
                    <div style={{ padding: '12px' }}>
                        <p style={{ color: 'black' }}>{pnResult.response_content.message}</p>
                        <a href="#" onClick={(e) => {
                            e.preventDefault();
                            history.goBack();
                        }}>Click here to return to the previous page</a>
                    </div>
                ) : (
                    <div style={{ padding: '12px' }}>
                        <p style={{ color: 'black', fontFamily: 'verdana, helvetica', fontSize: '12px' }}>{pnResult.response_content.message}</p>
                        <a style={{ fontFamily: 'verdana, helvetica', fontSize: '12px' }} href="#" onClick={handleEmailClick(pnResult.response_content.id)}>
                            E-mail this Error to GenomeQuest Support</a>
                        <br/>
                        <br/>
                        <a style={{ fontFamily: 'verdana, helvetica', fontSize: '12px' }} href="#"
                            onClick={(e) => {
                                e.preventDefault();
                                history.goBack();
                            }}>
                            Click here to return to the previous page
                        </a>
                    </div>
                )
            )}
        </div>
    );
}

export default PatentSearchResult;
