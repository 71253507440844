import Modal from 'react-bootstrap/Modal'
import _ from "lodash";
import React, { useState, useEffect } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import TextInput from '../../shared/Fields/TextInput';
import {FiChevronDown,FiChevronUp,FiChevronRight,FiChevronLeft} from "react-icons/fi";
import { ModalBody } from 'react-bootstrap';
import Link from '@material-ui/core/Link';
 
const useStyles = makeStyles((theme) => ({
    modalClassContent: {
        position: 'absolute',
        width: '100%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        transform: 'translate(-50%, -0%)'
    },
    modalBoxContent: {
        maxHeight: '675px',
    },
    modalHeader: {
        borderBottom: 'none !important',
        position: 'absolute',
        right: '5px',
        top: '3px',
        zIndex: '1',
        display: "block !important"
    },
    colorContainer: {
        //backgroundColor: '#EEEEEE',
        textAlign: 'left',
        margin: '15px 0 12px',
        padding: '15px 15px 0px 15px',
        borderRadius: '5px',
    },
    closeButton: {
        '&:hover': {
            cursor: 'pointer'
        }
    }
}))
const DefineColumnsModal = props => {
    const classes = useStyles();
 
    function onCancel(props) {
        props.closeModal();
    }
 
    const moveSelectedOptions = () => {
        const availableSelect = document.getElementById('availableColumns');
        const displayedSelect = document.getElementById('displayedColumns');
 
        const selectedOptions = Array.from(availableSelect.selectedOptions);
        selectedOptions.forEach(option => {
            const newOption = document.createElement('option');
            newOption.text = option.text;
            newOption.value = option.value;
            displayedSelect.appendChild(newOption);
            option.remove();
        });
    };
 
    const removeSelectedOptions = () =>{
        const availableSelect = document.getElementById('displayedColumns');
        const displayedSelect = document.getElementById('availableColumns');
 
        const selectedOptions = Array.from(availableSelect.selectedOptions);
        selectedOptions.forEach(option => {
            const newOption = document.createElement('option');
            newOption.text = option.text;
            newOption.value = option.value;
            displayedSelect.appendChild(newOption);
            option.remove();
        });
    }
    const moveUp = () => {
        var selectElement = document.getElementById('displayedColumns');
        var selectedIndex = selectElement.selectedIndex;
        if (selectedIndex > 0) {
            var selectedOption = selectElement.options[selectedIndex];
            selectElement.removeChild(selectedOption);
            selectElement.insertBefore(selectedOption, selectElement.options[selectedIndex - 1]);
        }
    }
 
    const moveDown = () =>{
        var selectElement = document.getElementById('displayedColumns');
        var selectedIndex = selectElement.selectedIndex;
        if (selectedIndex > 0) {
            var selectedOption = selectElement.options[selectedIndex];
            selectElement.removeChild(selectedOption);
            selectElement.insertBefore(selectedOption, selectElement.options[selectedIndex + 1]);
        }
    }
 
    return(
 
        <Modal
            show={props.showModal}
            fieldConfig={props.fieldConfig}
            size="lg"
            aria-labelledby="contained-modal-title-vcente"
            centered
            contentClassName={classes.modalClassContent}
            className={classes.modalBoxContent}
            // onEntered={modalLoaded}
        >
            <div>
                <div className="titlebar">
                    <span>Define Columns</span>
                    <Link href="#" onClick={(e) => e.preventDefault()} className="pull-right">
                        <CloseIcon style={{ fill: '#b5b5b5' }} onClick={() => onCancel(props)} />
                    </Link>
                </div>
                <div style={{ borderTop: '1px solid #b5b5b5', padding: '12px' }}>
                    <table>
                        <tbody>
                            <tr>
                                <td valign='top'>Available Columns</td>
                                <td></td>
                                <td valign='top'>Currently Displayed</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td valign='top'>
                                    <select style={{width: '220px'}} multiple='multiple' class="field-container" id='availableColumns'>
                                        <optgroup label="Identifiers"></optgroup>
                                            <option value="SEQUENCE_AC">Accession</option>
                                            <option value="SEQUENCE_AN">Application Number</option>
                                            <option value="SEQUENCE_ID">Identifier</option>
                                            <option value="SEQUENCE_P4">PCT pub. no.</option>
                                            <option value="SEQUENCE_PV">Patent Id</option>
                                            <option value="SEQUENCE_SI">Patent SEQ ID NO</option>
                                            <option value="SEQUENCE_SV">Sequence version</option>
                                            <option value="SEQUENCE_PJ">Unique Family Sequence ID</option>
                                        <optgroup label="Text"></optgroup>
                                            <option value="SEQUENCE_P1">Abstract</option>
                                            <option value="SEQUENCE_CT">Claims</option>
                                            <option value="SEQUENCE_CC">Comments</option>
                                            <option value="SEQUENCE_DR">Database cross-references</option>
                                            <option value="SEQUENCE_GNAME">Database name</option>
                                            <option value="SEQUENCE_DE">Description</option>
                                            <option value="SEQUENCE_VT_DE-PT">Description / Title</option>
                                            <option value="SEQUENCE_L2">Detailed Legal Status</option>
                                            <option value="SEQUENCE_PE">Earliest priority number</option>
                                            <option value="SEQUENCE_EM">Equivalents member type</option>
                                            <option value="SEQUENCE_KW">Keywords</option>
                                            <option value="SEQUENCE_PH">Legal Status National Phase</option>
                                            <option value="SEQUENCE_LS">Legal Status</option>
                                            <option value="SEQUENCE_LS">Normalized Parent</option>
                                            <option value="SEQUENCE_LS">Normalized Patent Assignee</option>
                                            <option value="SEQUENCE_LS">Organism</option>
                                            <option value="SEQUENCE_LS">Organism (non-nomalized name)</option>
                                            <option value="SEQUENCE_LS">Patent family</option>
                                            <option value="SEQUENCE_LS">Patent family ID</option>
                                            <option value="SEQUENCE_LS">Patent inventors</option>
                                            <option value="SEQUENCE_LS">Patent sequence locations</option>
                                            <option value="SEQUENCE_LS">Priority info</option>
                                            <option value="SEQUENCE_LS">Reference author</option>
                                            <option value="SEQUENCE_LS">Reference location</option>
                                            <option value="SEQUENCE_LS">Seq. listing equivalents</option>
                                            <option value="SEQUENCE_LS">Sequence Note</option>
                                            <option value="SEQUENCE_LS">Taxonomy ID</option>
                                            <option value="SEQUENCE_LS">US PAIR legal Status</option>
                                        <optgroup label='Dates'></optgroup>
                                            <option value='SEQUENCE_D2'>Date of entry</option>
                                            <option value='SEQUENCE_D5'>Earliest pub. date</option>
                                            <option value='SEQUENCE_P5'>PCT pub. date</option>
                                            <option value='SEQUENCE_P8'>PCT related dates</option>
                                            <option value='SEQUENCE_D3'>Patent filing date</option>
                                        <optgroup label='Sequence Properties'></optgroup>
                                            <option value='SEQUENCE_L'>length</option>
                                            <option value='SEQUENCE_MT'>Molecule type</option>
                                        <optgroup label='Other'></optgroup>
                                            <option value='SEQUENCE_PO'>Claimed SEQ ID</option>
                                            <option value='SEQUENCE_FM'>Family size</option>
                                            <option value='SEQUENCE_FH'>Feature header</option>
                                            <option value='SEQUENCE_FT'>Feature table</option>
                                            <option value='SEQUENCE_HO'>Nb. claimed seqs</option>
                                            <option value='SEQUENCE_P7'>Number of claims</option>
                                            <option value='SEQUENCE_PC'>Patent classification</option>
                                            <option value='SEQUENCE_KC'>patent kind code</option>
                                            <option value='SEQUENCE_SQ'>Sequence information</option>
                                    </select>
 
                                </td>
                                <td style={{verticalAlign:'middle'}}>
                                    <div>
                                        <FiChevronRight onClick={moveSelectedOptions} />
                                    </div>
                                    <div>
                                        <FiChevronLeft onClick={removeSelectedOptions}/>
                                    </div>
                                </td>
                                <td valign='top'>
                                    <select style={{width: '220px'}} multiple='multiple' class="field-container" id='displayedColumns'>
                                        <option value="SEQUENCE_MEMBERCHECKBOX" disabled>Checkbox</option>
                                            <option value="SEQUENCE_PN" >Patent Number</option>
                                            <option value="SEQUENCE_PA">Patent assignee</option>
                                            <option value="SEQUENCE_PT">Title</option>
                                            <option value="SEQUENCE_PG">Extended Legal Status</option>
                                            <option value="SEQUENCE_D1">Publication date</option>
                                            <option value="SEQUENCE_D4">Earliest priority date</option>
                                            <option value="SEQUENCE_P9">Number of SEQ (nuc/prot)</option>
                                            <option value="SEQUENCE_VT_LINKS">Link out</option>
                                            <option value="SEQUENCE_VT_PDF">PDF Link</option>
                                    </select>
                                </td>
                                <td style={{verticalAlign:'middle'}}>
                                    <div>
                                        <FiChevronUp onClick={moveUp}/>
                                    </div>
                                    <div>
                                        <FiChevronDown onClick={moveDown}/>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div style={{margin_top:"1em"}}>
                        <button className='okButton DMTBtn' onClick={() => onCancel(props)}>Cancel</button>
                        <button className='okButton DMTBtn' >Save</button>
                    </div>
                </div>
            </div>
        </Modal>
    )
 
}
export default DefineColumnsModal;