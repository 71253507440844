import React from 'react';

function MiniSpinner() {

    const useMousePosition = () => {
        const [
          mousePosition,
          setMousePosition
        ] = React.useState({ x: null, y: null });
      
        React.useEffect(() => {
          const updateMousePosition = ev => {
            setMousePosition({ x: ev.clientX, y: ev.clientY });
          };
          
          window.addEventListener('mousemove', updateMousePosition);
      
          return () => {
            window.removeEventListener('mousemove', updateMousePosition);
          };
        }, []);
      
        return mousePosition;
      };
    
    const mousePosition = useMousePosition();
    
    return(<div
    className={"mini-spinner-container "}
    style={{ top: mousePosition.y+10, left: mousePosition.x+10 }}
    >
    	<div class="mini-spinner-socket">
    		<div class="mini-spinner-hex mini-spinner-tile-1">
    			<div class="mini-spinner-brick rotate-0"></div>
    			<div class="mini-spinner-brick rotate-1"></div>
    			<div class="mini-spinner-brick rotate-2"></div>
    		</div>
    		<div class="mini-spinner-hex mini-spinner-tile-2">
    			<div class="mini-spinner-brick rotate-0"></div>
    			<div class="mini-spinner-brick rotate-1"></div>
    			<div class="mini-spinner-brick rotate-2"></div>
    		</div>
    		<div class="mini-spinner-hex mini-spinner-tile-3 mini-spinner-row-2">
    			<div class="mini-spinner-brick rotate-0"></div>
    			<div class="mini-spinner-brick rotate-1"></div>
    			<div class="mini-spinner-brick rotate-2"></div>
    		</div>
    		<div class="mini-spinner-hex mini-spinner-tile-4 mini-spinner-row-2">
    			<div class="mini-spinner-brick rotate-0"></div>
    			<div class="mini-spinner-brick rotate-1"></div>
    			<div class="mini-spinner-brick rotate-2"></div>
    		</div>
    		<div class="mini-spinner-hex mini-spinner-tile-5 mini-spinner-row-2">
    			<div class="mini-spinner-brick rotate-0"></div>
    			<div class="mini-spinner-brick rotate-1"></div>
    			<div class="mini-spinner-brick rotate-2"></div>
    		</div>
    	</div>
    </div>)
}

export default MiniSpinner;