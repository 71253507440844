import React, { useState, useEffect } from "react";
//import "../TableStyles.css"

import lctriple from "../tableImages/lcTripleVenn.png";
import hctriple from "../tableImages/hcTripleVenn.png";

import { Link } from 'react-router-dom';
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

function DocSeqMatchOneCdr(props){

    const { LcCDR, HcCDR, Switch, collName} = props;
    const {resid, reportName} = useParams();

    const [docOrSeq, setDocOrSeq] = useState("sequences")

    function getStyles(int, bool, extra = false){

        let style = ""
        if(int <= 0 || int == undefined){
            style += "disabled "
        }
        if(!extra && bool && int >= 0){
            style += "overlaps "
        }
        if(extra){
            style += "bolding f15pt"
        }

        return style;
    }

    useEffect(() =>{
        if(Switch){
            setDocOrSeq("documents")
        }
    },[props.LcCDR, props.HcCDR, Switch])


    function applyMatching(totalQueries){
        for(var i=0; i< totalQueries.length; i++){
            totalQueries[i] = totalQueries[i].toUpperCase();
            totalQueries[i] = totalQueries[i].replaceAll('-','_');
        }

        var abReportFilters = {
                //matchingSequences: totalQueries,
                groupBy: Switch? 'document':'ssv',
                reportName: decodeURIComponent(reportName) == "undefined"? "Unfiltered_Entire_Report": decodeURIComponent(reportName)
        };
        // Correct the parameter for Matching Documents
        if (Switch) {
            abReportFilters.matchingDocuments = totalQueries;
        } else {
            abReportFilters.matchingSequences = totalQueries;
        }
        
        return encodeURIComponent(JSON.stringify(abReportFilters))

    }

	return (
        <div class="tableFrame">
            <p class="textFlat pCenter paddingTitle"><strong>{docOrSeq.toUpperCase()}</strong> matching at least one CDR sequence</p>
            <table class="tabler">
                <tr>
                <td>
                <table class="tableMatchOneCdr">
                        <td>
                            <img class="imgMatchOneCdr" src={lctriple}></img>
                            <Link 
                                className={getStyles(LcCDR.LC_CDR1, false )+" AbTriple0 textFlatCenter"}                                   
                                target="_blank"
                                disabled={LcCDR.LC_CDR1==0}
                                to={{
                                    pathname: '/discover/wf:'+resid+".resdb/antibody/"+applyMatching(['LC_CDR1']) + "/" + collName
                                }}>{LcCDR.LC_CDR1 != null? LcCDR.LC_CDR1 : 0}
                            </Link>
                            <Link 
                                className={getStyles(LcCDR.LC_CDR1LC_CDR2, true )+" AbTriple1 textFlatCenter"}                                  
                                target="_blank"
                                disabled={LcCDR.LC_CDR1LC_CDR2==0}
                                to={{
                                    pathname: '/discover/wf:'+resid+".resdb/antibody/"+applyMatching(['LC_CDR1','LC_CDR2']) + "/" + collName
                                }}>{LcCDR.LC_CDR1LC_CDR2 != null? LcCDR.LC_CDR1LC_CDR2 : 0}
                            </Link>
                            <Link 
                                className={getStyles(LcCDR.LC_CDR1LC_CDR2LC_CDR3, true, true)+" AbTriple2 textFlatCenter"}                                  
                                target="_blank"
                                disabled={LcCDR.LC_CDR1LC_CDR2LC_CDR3==0}
                                to={{
                                    pathname: '/discover/wf:'+resid+".resdb/antibody/"+applyMatching(['LC_CDR1','LC_CDR2','LC_CDR3']) + "/" + collName
                                }}>{LcCDR.LC_CDR1LC_CDR2LC_CDR3 != null? LcCDR.LC_CDR1LC_CDR2LC_CDR3 : 0}
                            </Link>
                            <Link 
                                className={getStyles(LcCDR.LC_CDR1LC_CDR3, true )+" AbTriple3 textFlatCenter"}
                                target="_blank"
                                disabled={LcCDR.LC_CDR1LC_CDR3==0}
                                to={{
                                    pathname: '/discover/wf:'+resid+".resdb/antibody/"+applyMatching(['LC_CDR1','LC_CDR3']) + "/" + collName
                                }}>{LcCDR.LC_CDR1LC_CDR3 != null? LcCDR.LC_CDR1LC_CDR3 : 0}
                            </Link>
                            <Link 
                                className={getStyles(LcCDR.LC_CDR2, false )+" AbTriple4 textFlatCenter"}
                                target="_blank"
                                disabled={LcCDR.LC_CDR2==0}
                                to={{
                                    pathname: '/discover/wf:'+resid+".resdb/antibody/"+applyMatching(['LC_CDR2']) + "/" + collName
                                }}>{LcCDR.LC_CDR2 != null? LcCDR.LC_CDR2 : 0}
                            </Link>
                            <Link 
                                className={getStyles(LcCDR.LC_CDR2LC_CDR3, true )+" AbTriple5 textFlatCenter"}
                                target="_blank"
                                disabled={LcCDR.LC_CDR2LC_CDR3==0}
                                to={{
                                    pathname: '/discover/wf:'+resid+".resdb/antibody/"+applyMatching(['LC_CDR2','LC_CDR3']) + "/" + collName
                                }}>{LcCDR.LC_CDR2LC_CDR3 != null? LcCDR.LC_CDR2LC_CDR3 : 0}
                            </Link>
                            <Link 
                                className={getStyles(LcCDR.LC_CDR3, false )+" AbTriple6 textFlatCenter"}
                                target="_blank"
                                disabled={LcCDR.LC_CDR3==0}
                                to={{
                                    pathname: '/discover/wf:'+resid+".resdb/antibody/"+applyMatching(['LC_CDR3']) + "/" + collName
                                }}>{LcCDR.LC_CDR3!= null? LcCDR.LC_CDR3 : 0}
                            </Link>
                        </td>
                    </table>
                </td>
                <td>
                    <table class="tableMatchOneCdr">
                        <td>
                            <img class="imgMatchOneCdr" src={hctriple}></img>
                            <Link 
                                className={getStyles(HcCDR.HC_CDR1, false )+" AbTriple0 textFlatCenter"} 
                                target="_blank"
                                disabled={HcCDR.HC_CDR1==0}
                                to={{
                                    pathname: '/discover/wf:'+resid+".resdb/antibody/"+applyMatching(['HC_CDR1']) + "/" + collName
                                }}>{HcCDR.HC_CDR1 != null? HcCDR.HC_CDR1 : 0}
                            </Link>
                            <Link 
                                className={getStyles(HcCDR.HC_CDR1HC_CDR2, true )+" AbTriple1 textFlatCenter"} 
                                target="_blank"
                                disabled={HcCDR.HC_CDR1HC_CDR2==0}
                                to={{
                                    pathname: '/discover/wf:'+resid+".resdb/antibody/"+applyMatching(['HC_CDR1','HC_CDR2']) + "/" + collName
                                }}>{HcCDR.HC_CDR1HC_CDR2 != null? HcCDR.HC_CDR1HC_CDR2 : 0}
                            </Link>
                            <Link 
                                className={getStyles(HcCDR.HC_CDR1HC_CDR2HC_CDR3, true, true)+" AbTriple2 textFlatCenter"} 
                                target="_blank"
                                disabled={HcCDR.HC_CDR1HC_CDR2HC_CDR3==0}
                                to={{
                                    pathname: '/discover/wf:'+resid+".resdb/antibody/"+applyMatching(['HC_CDR1','HC_CDR2','HC_CDR3']) + "/" + collName
                                }}>{HcCDR.HC_CDR1HC_CDR2HC_CDR3 != null? HcCDR.HC_CDR1HC_CDR2HC_CDR3 : 0}
                            </Link>
                            <Link 
                                className={getStyles(HcCDR.HC_CDR1HC_CDR3, true )+" AbTriple3 textFlatCenter"}
                                target="_blank"
                                disabled={HcCDR.HC_CDR1HC_CDR3==0}
                                to={{
                                    pathname: '/discover/wf:'+resid+".resdb/antibody/"+applyMatching(['HC_CDR1','HC_CDR3']) + "/" + collName
                                }}>{HcCDR.HC_CDR1HC_CDR3 != null? HcCDR.HC_CDR1HC_CDR3 : 0}
                            </Link>
                            <Link 
                                className={getStyles(HcCDR.HC_CDR2, false )+" AbTriple4 textFlatCenter"}
                                target="_blank"
                                disabled={HcCDR.HC_CDR2==0}
                                to={{
                                    pathname: '/discover/wf:'+resid+".resdb/antibody/"+applyMatching(['HC_CDR2']) + "/" + collName
                                }}>{HcCDR.HC_CDR2 != null? HcCDR.HC_CDR2 : 0}
                            </Link>
                            <Link 
                                className={getStyles(HcCDR.HC_CDR2HC_CDR3, true )+" AbTriple5 textFlatCenter"} 
                                target="_blank"
                                disabled={HcCDR.HC_CDR2HC_CDR3==0}
                                to={{
                                    pathname: '/discover/wf:'+resid+".resdb/antibody/"+applyMatching(['HC_CDR2','HC_CDR3']) + "/" + collName
                                }}>{HcCDR.HC_CDR2HC_CDR3 != null? HcCDR.HC_CDR2HC_CDR3 : 0}
                            </Link>
                            <Link 
                                className={getStyles(HcCDR.HC_CDR3, false )+" AbTriple6 textFlatCenter"}  
                                target="_blank"
                                disabled={HcCDR.HC_CDR3==0}
                                to={{
                                    pathname: '/discover/wf:'+resid+".resdb/antibody/"+applyMatching(['HC_CDR3']) + "/" + collName
                                }}>{HcCDR.HC_CDR3!= null? HcCDR.HC_CDR3 : 0}
                            </Link>
                        </td>
                    </table>
                </td>
                </tr>
            </table>
            
            {!docOrSeq &&
                <p class="bottomText pCenter">The number of patent documents matching one, two, or three CDR sequences. Click the numbers to see the results.</p>

            }
            {docOrSeq &&
                <p class="bottomText pCenter">The number of sequences from patent documents matching one, two, or three CDR sequences. Click the numbers to see the results.</p>
            }
        
        </div>        
    );
}

export default DocSeqMatchOneCdr;
