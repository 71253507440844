import React from 'react';
import Modal from 'react-bootstrap/Modal'
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import Link from '@material-ui/core/Link';
import { MdOutlineArrowDropDown } from "react-icons/md";
import { useState } from 'react';

const useStyles = makeStyles((theme) => ({
    table: {
        width: '100%',
    },
    modalHeader: {
        borderBottom: 'none !important',
        paddingTop: '11px',
        paddingRight: '4px',
        marginTop: '-7px',
        width: '59%',
        fontFamily: 'verdana, helvetica',
        fontSize: '12px',
    },
    modalClassContent: {
        height: '30px',
        width: '200px',
        margin: '10px',
        fontSize: '14px',
        color: 'rgb(119, 119, 119)',
        fontFamily: 'verdana, helvetica',
        fontWeight: '400'
    },
    modalBody: {
        textAlign: 'center',
        marginBottom: '8px'
    },
    onRowHover: {
        textAlign: 'left',
        '&:hover': {
            border: '1px solid #b5b5b5',
            backgroundColor: '#eeece1',
            color: 'black',
        },
    },
    tableStyle: {
        position: 'absolute',
        marginLeft: '662px',
        backgroundColor: '#ffffff',
        border: '1px solid #b5b5b5',
    },
    scrollableTable: {
        maxHeight: '400px',
        overflowY: 'auto',
    }
}));

const PnSearchSortingModal = (props) => {
    const classes = useStyles();
    const [sortBy, setSortBy] = useState(false);
    const [inputValue, setInputValue] = useState('--Choose--');
    const [input2Value, setInput2Value] = useState('--Choose--');
    const [input3Value, setInput3Value] = useState('--Choose--');
    const [thenBy, setThenBy] = useState(false);
    const [thennBy, setThennBy] = useState(false);

    function handleSort() {
        setSortBy(!sortBy);
        setThennBy(false);
        setThenBy(false);
    };
    function handleInputValue(value) {
        setInputValue(value);
        setSortBy(false);
    }
    function handleThen() {
        setThenBy(!thenBy);
        setSortBy(false);
        setThennBy(false);
    };
    function handleInput2Value(value) {
        setInput2Value(value);
        setThenBy(false);
    }
    function handleThenBy() {
        setThennBy(!thennBy);
        setThenBy(false);
        setSortBy(false);
    }
    function handleInput3Value(value) {
        setInput3Value(value)
        setThennBy(false);
    }

    return (
        <Modal
            id="modalCss"
            aria-labelledby="contained-modal-title-vcente"
            centered
            size="lg"
            show={props.showModal}
            contentClassName={classes.modalHeader}
        >
            <div>
                <Link href="#" onClick={(e) => e.preventDefault()} className="pull-right">
                    <CloseIcon style={{ fill: '#b5b5b5' }} onClick={props.closeModal} />
                </Link>
            </div>
            <Modal.Body className={classes.modalBody}>
                <div>
                    <form>
                        <table className={classes.table}>
                            <tbody>
                                <tr align='left'><label style={{ color: 'black', marginBottom: '2px' }}><b>Sort by</b></label></tr>
                                <tr style={{ whiteSpace: 'nowrap' }}>
                                    <td style={{ border: '1px solid #b5b5b5' }}>
                                        <span style={{ marginRight: '5px' }} onClick={handleSort}>{inputValue}</span>
                                        <MdOutlineArrowDropDown style={{ height: '25px', width: '21px' }} onClick={handleSort} />
                                    </td>
                                    <td>
                                        <input
                                            id="searchType1"
                                            type="radio"
                                            name="searchType"
                                            style={{ marginLeft: '25px' }}
                                            value="type1"
                                            defaultChecked
                                        />
                                        <label style={{ fontFamily: 'verdana, helvetica', fontSize: '12px', marginLeft: '5px', color: 'black' }} htmlFor="searchType1">Ascending</label>
                                    </td>
                                    <td>
                                        <input
                                            id="searchType2"
                                            type="radio"
                                            name="searchType"
                                            style={{ marginLeft: '13px' }}
                                            value="type2"
                                        />
                                        <label style={{ fontFamily: 'verdana, helvetica', fontSize: '12px', marginLeft: '5px', color: 'black' }} htmlFor="searchType2">Descending</label>

                                    </td>
                                    {sortBy &&
                                        <div className={classes.scrollableTable}>
                                            <table className={classes.tableStyle} style={{ zIndex: '1000', marginLeft: '-433px', marginTop: '28px', minHeight: '100px', maxHeight: '400px', overflowY: 'auto' }}>
                                                <tbody >
                                                    <tr className={classes.onRowHover} onClick={() => handleInputValue('--Choose--')}>
                                                        <td>--Choose--</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInputValue('Group Size')}>
                                                        <td>Group Size</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInputValue('Accession')}>
                                                        <td>Accession</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInputValue('Application number')}>
                                                        <td>Application number</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInputValue('Identifier')}>
                                                        <td>Identifier</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInputValue('Patent Id')}>
                                                        <td>Patent Id</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInputValue('Patent SEQ ID NO')}>
                                                        <td>Patent SEQ ID NO</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInputValue('Patent number')}>
                                                        <td>Patent number</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInputValue('Sequence version')}>
                                                        <td>Sequence version</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInputValue('Unique Family Sequence ID')}>
                                                        <td>Unique Family Sequence ID</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInputValue('Comments')}>
                                                        <td>Comments</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInputValue('Database cross-references')}>
                                                        <td>Database cross-references</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInputValue('Database name')}>
                                                        <td>Database name</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInputValue('Description')}>
                                                        <td>Description</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInputValue('Detailed legal status')}>
                                                        <td>Detailed legal status</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInputValue('Earliest priority number')}>
                                                        <td>Earliest priority number</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInputValue('Equivalents member type')}>
                                                        <td>Equivalents member type</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInputValue('Extended Legal Status')}>
                                                        <td>Extended Legal Status</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInputValue('Keywords')}>
                                                        <td>Keywords</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInputValue('Legal Status National Phase')}>
                                                        <td>Legal Status National Phase</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInputValue('Legal status')}>
                                                        <td>Legal status</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInputValue('Normalized Parent')}>
                                                        <td>Normalized Parent</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInputValue('Normalized Patent Assignee')}>
                                                        <td>Normalized Patent Assignee</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInputValue('Organism')}>
                                                        <td>Organism</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInputValue('Organism (non-normalized name)')}>
                                                        <td>Organism (non-normalized name)</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInputValue('Patent assignee')}>
                                                        <td>Patent assignee</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInputValue('Patent family')}>
                                                        <td>Patent family</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInputValue('Patent family ID')}>
                                                        <td>Patent family ID</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInputValue('Patent inventors')}>
                                                        <td>Patent inventors</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInputValue('Patent sequence location')}>
                                                        <td>Patent sequence location</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInputValue('Priority info')}>
                                                        <td>Priority info</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInputValue('Reference location')}>
                                                        <td>Reference location</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInputValue('Seq. listing equivalents')}>
                                                        <td>Seq. listing equivalents</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInputValue('Sequence Note')}>
                                                        <td>Sequence Note</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInputValue('Taxonomy ID')}>
                                                        <td>Taxonomy ID</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInputValue('Title')}>
                                                        <td>Title</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInputValue('US PAIR legal status')}>
                                                        <td>US PAIR legal status</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInputValue('Date of entry')}>
                                                        <td>Date of entry</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInputValue('Earliest priority date')}>
                                                        <td>Earliest priority date</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInputValue('Earliest pub. date')}>
                                                        <td>Earliest pub. date</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInputValue('PCT pub. date')}>
                                                        <td>PCT pub. date</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInputValue('Patent filing date')}>
                                                        <td>Patent filing date</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInputValue('Publication date')}>
                                                        <td>Publication date</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInputValue('Length')}>
                                                        <td>Length</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInputValue('Molecule type')}>
                                                        <td>Molecule type</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInputValue('Family size')}>
                                                        <td>Family size</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInputValue('Nb. claimed seqs')}>
                                                        <td>Nb. claimed seqs</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInputValue('Number of SEQ (nuc/prot)')}>
                                                        <td>Number of SEQ (nuc/prot)</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInputValue('Number of claims')}>
                                                        <td>Number of claims</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInputValue('Patent classification')}>
                                                        <td>Patent classification</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInputValue('Patent kind code')}>
                                                        <td>Patent kind code</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    }
                                </tr>
                                <tr align='left'><label style={{ color: 'black', marginTop: '12px', marginBottom: '2px' }}><b>Then by</b></label></tr>
                                <tr>
                                    <td style={{ border: '1px solid #b5b5b5', whiteSpace: 'nowrap' }}>
                                        <span style={{ marginRight: '5px' }} onClick={handleThen}>{input2Value}</span>
                                        <MdOutlineArrowDropDown style={{ height: '25px', width: '21px' }} onClick={handleThen} />
                                    </td>
                                    <td>
                                        <input
                                            id="searchType3"
                                            type="radio"
                                            name="searchType1"
                                            style={{ marginLeft: '25px' }}
                                            value="type1"
                                            defaultChecked
                                        />
                                        <label style={{ fontFamily: 'verdana, helvetica', fontSize: '12px', marginLeft: '5px', color: 'black' }} htmlFor="searchType3">Ascending</label>
                                    </td>
                                    <td>
                                        <input
                                            id="searchType4"
                                            type="radio"
                                            name="searchType1"
                                            style={{ marginLeft: '13px' }}
                                            value="type2"
                                        />
                                        <label style={{ fontFamily: 'verdana, helvetica', fontSize: '12px', marginLeft: '5px', color: 'black' }} htmlFor="searchType4">Descending</label>

                                    </td>
                                    {thenBy &&
                                        <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
                                            <table className={classes.tableStyle} style={{ zIndex: '1000', marginLeft: '-433px', marginTop: '28px' }}>
                                                <tbody >
                                                    <tr className={classes.onRowHover} onClick={() => handleInput2Value('--Choose--')}>
                                                        <td>--Choose--</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInput2Value('Accession')}>
                                                        <td>Accession</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInput2Value('Application number')}>
                                                        <td>Application number</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInput2Value('Identifier')}>
                                                        <td>Identifier</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInput2Value('Patent Id')}>
                                                        <td>Patent Id</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInput2Value('Patent SEQ ID NO')}>
                                                        <td>Patent SEQ ID NO</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInput2Value('Patent number')}>
                                                        <td>Patent number</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInput2Value('Sequence version')}>
                                                        <td>Sequence version</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInput2Value('Unique Family Sequence ID')}>
                                                        <td>Unique Family Sequence ID</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInput2Value('Comments')}>
                                                        <td>Comments</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInput2Value('Database cross-references')}>
                                                        <td>Database cross-references</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInput2Value('Database name')}>
                                                        <td>Database name</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInput2Value('Description')}>
                                                        <td>Description</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInput2Value('Detailed legal status')}>
                                                        <td>Detailed legal status</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInput2Value('Earliest priority number')}>
                                                        <td>Earliest priority number</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInput2Value('Equivalents member type')}>
                                                        <td>Equivalents member type</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInput2Value('Extended Legal Status')}>
                                                        <td>Extended Legal Status</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInput2Value('Keywords')}>
                                                        <td>Keywords</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInput2Value('Legal Status National Phase')}>
                                                        <td>Legal Status National Phase</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInput2Value('Legal status')}>
                                                        <td>Legal status</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInput2Value('Normalized Parent')}>
                                                        <td>Normalized Parent</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInput2Value('Normalized Patent Assignee')}>
                                                        <td>Normalized Patent Assignee</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInput2Value('Organism')}>
                                                        <td>Organism</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInput2Value('Organism (non-normalized name)')}>
                                                        <td>Organism (non-normalized name)</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInput2Value('Patent assignee')}>
                                                        <td>Patent assignee</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInput2Value('Patent family')}>
                                                        <td>Patent family</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInput2Value('Patent family ID')}>
                                                        <td>Patent family ID</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInput2Value('Patent inventors')}>
                                                        <td>Patent inventors</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInput2Value('Patent sequence location')}>
                                                        <td>Patent sequence location</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInput2Value('Priority info')}>
                                                        <td>Priority info</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInput2Value('Reference location')}>
                                                        <td>Reference location</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInput2Value('Seq. listing equivalents')}>
                                                        <td>Seq. listing equivalents</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInput2Value('Sequence Note')}>
                                                        <td>Sequence Note</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInput2Value('Taxonomy ID')}>
                                                        <td>Taxonomy ID</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInput2Value('Title')}>
                                                        <td>Title</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInput2Value('US PAIR legal status')}>
                                                        <td>US PAIR legal status</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInput2Value('Date of entry')}>
                                                        <td>Date of entry</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInput2Value('Earliest priority date')}>
                                                        <td>Earliest priority date</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInput2Value('Earliest pub. date')}>
                                                        <td>Earliest pub. date</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInput2Value('PCT pub. date')}>
                                                        <td>PCT pub. date</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInput2Value('Patent filing date')}>
                                                        <td>Patent filing date</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInput2Value('Publication date')}>
                                                        <td>Publication date</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInput2Value('Length')}>
                                                        <td>Length</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInput2Value('Molecule type')}>
                                                        <td>Molecule type</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInput2Value('Family size')}>
                                                        <td>Family size</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInput2Value('Nb. claimed seqs')}>
                                                        <td>Nb. claimed seqs</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInput2Value('Number of SEQ (nuc/prot)')}>
                                                        <td>Number of SEQ (nuc/prot)</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInput2Value('Number of claims')}>
                                                        <td>Number of claims</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInput2Value('Patent classification')}>
                                                        <td>Patent classification</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInput2Value('Patent kind code')}>
                                                        <td>Patent kind code</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    }
                                </tr>
                                <tr align='left'><label style={{ color: 'black', marginTop: '12px', marginBottom: '2px' }}><b>Then by</b></label></tr>
                                <tr>
                                    <td style={{ border: '1px solid #b5b5b5', whiteSpace: 'nowrap' }}>
                                        <span style={{ marginRight: '5px' }} onClick={handleThenBy}>{input3Value}</span>
                                        <MdOutlineArrowDropDown style={{ height: '25px', width: '21px' }} onClick={handleThenBy} />
                                    </td>
                                    <td>
                                        <input
                                            id="searchType5"
                                            type="radio"
                                            name="searchType2"
                                            style={{ marginLeft: '25px' }}
                                            value="type1"
                                            defaultChecked
                                        />
                                        <label style={{ fontFamily: 'verdana, helvetica', fontSize: '12px', marginLeft: '5px', color: 'black' }} htmlFor="searchType5">Ascending</label>
                                    </td>
                                    <td>
                                        <input
                                            id="searchType6"
                                            type="radio"
                                            name="searchType2"
                                            style={{ marginLeft: '13px' }}
                                            value="type2"
                                        />
                                        <label style={{ fontFamily: 'verdana, helvetica', fontSize: '12px', marginLeft: '5px', color: 'black' }} htmlFor="searchType6">Descending</label>

                                    </td>
                                    {thennBy &&
                                        <div >
                                            <table className={classes.tableStyle} style={{ zIndex: '1000', marginLeft: '-433px', marginTop: '28px' }}>
                                                <tbody style={{ maxHeight: '400px', overflowY: 'auto' }}>
                                                    <tr className={classes.onRowHover} onClick={() => handleInput3Value('--Choose--')}>
                                                        <td>--Choose--</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInput3Value('Accession')}>
                                                        <td>Accession</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInput3Value('Application number')}>
                                                        <td>Application number</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInput3Value('Identifier')}>
                                                        <td>Identifier</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInput3Value('Patent Id')}>
                                                        <td>Patent Id</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInput3Value('Patent SEQ ID NO')}>
                                                        <td>Patent SEQ ID NO</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInput3Value('Patent number')}>
                                                        <td>Patent number</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInput3Value('Sequence version')}>
                                                        <td>Sequence version</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInput3Value('Unique Family Sequence ID')}>
                                                        <td>Unique Family Sequence ID</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInput3Value('Comments')}>
                                                        <td>Comments</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInput3Value('Database cross-references')}>
                                                        <td>Database cross-references</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInput3Value('Database name')}>
                                                        <td>Database name</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInput3Value('Description')}>
                                                        <td>Description</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInput3Value('Detailed legal status')}>
                                                        <td>Detailed legal status</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInput3Value('Earliest priority number')}>
                                                        <td>Earliest priority number</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInput3Value('Equivalents member type')}>
                                                        <td>Equivalents member type</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInput3Value('Extended Legal Status')}>
                                                        <td>Extended Legal Status</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInput3Value('Keywords')}>
                                                        <td>Keywords</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInput3Value('Legal Status National Phase')}>
                                                        <td>Legal Status National Phase</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInput3Value('Legal status')}>
                                                        <td>Legal status</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInput3Value('Normalized Parent')}>
                                                        <td>Normalized Parent</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInput3Value('Normalized Patent Assignee')}>
                                                        <td>Normalized Patent Assignee</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInput3Value('Organism')}>
                                                        <td>Organism</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInput3Value('Organism (non-normalized name)')}>
                                                        <td>Organism (non-normalized name)</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInput3Value('Patent assignee')}>
                                                        <td>Patent assignee</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInput3Value('Patent family')}>
                                                        <td>Patent family</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInput3Value('Patent family ID')}>
                                                        <td>Patent family ID</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInput3Value('Patent inventors')}>
                                                        <td>Patent inventors</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInput3Value('Patent sequence location')}>
                                                        <td>Patent sequence location</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInput3Value('Priority info')}>
                                                        <td>Priority info</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInput3Value('Reference location')}>
                                                        <td>Reference location</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInput3Value('Seq. listing equivalents')}>
                                                        <td>Seq. listing equivalents</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInput3Value('Sequence Note')}>
                                                        <td>Sequence Note</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInput3Value('Taxonomy ID')}>
                                                        <td>Taxonomy ID</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInput3Value('Title')}>
                                                        <td>Title</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInput3Value('US PAIR legal status')}>
                                                        <td>US PAIR legal status</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInput3Value('Date of entry')}>
                                                        <td>Date of entry</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInput3Value('Earliest priority date')}>
                                                        <td>Earliest priority date</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInput3Value('Earliest pub. date')}>
                                                        <td>Earliest pub. date</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInput3Value('PCT pub. date')}>
                                                        <td>PCT pub. date</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInput3Value('Patent filing date')}>
                                                        <td>Patent filing date</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInput3Value('Publication date')}>
                                                        <td>Publication date</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInput3Value('Length')}>
                                                        <td>Length</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInput3Value('Molecule type')}>
                                                        <td>Molecule type</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInput3Value('Family size')}>
                                                        <td>Family size</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInput3Value('Nb. claimed seqs')}>
                                                        <td>Nb. claimed seqs</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInput3Value('Number of SEQ (nuc/prot)')}>
                                                        <td>Number of SEQ (nuc/prot)</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInput3Value('Number of claims')}>
                                                        <td>Number of claims</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInput3Value('Patent classification')}>
                                                        <td>Patent classification</td>
                                                    </tr>
                                                    <tr className={classes.onRowHover} onClick={() => handleInput3Value('Patent kind code')}>
                                                        <td>Patent kind code</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    }
                                </tr>
                            </tbody>
                        </table>
                    </form>
                </div>
            </Modal.Body>
        </Modal >
    );
};

export default PnSearchSortingModal;