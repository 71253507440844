import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { get } from '../../helpers/fetchServicesMethods';
import { url } from '../../reducers/url';
import PubSub from 'pubsub-js';

function showLoader() {
    PubSub.publish('msg', true);
}

function hideLoader() {
    PubSub.publish('msg', false);
}
const useStyles = makeStyles((theme) => ({
    ARModal: {
        minHeight: '353.091px',
        background: 'white',
        marginRight: '12px',
        marginLeft: '5px',
        borderLeft: '1px solid #dcd7c0',
        borderRight: '1px solid #dcd7c0',
        borderBottom: '1px solid #dcd7c0',
        // borderTop: '1px solid #dcd7c0',
        padding: '12px'
    },
    paraStyle: {
        fontSize: '12px',
        fontFamily: 'verdana, helvetica',
        color: 'black',
        padding: '5px'
    },
    tableHead: {
        fontFamily: 'verdana, helvetica',
        textAlign: 'center',
        padding: '4px',
        borderLeft: '1px solid white',
        width: '220px',
        verticalAlign: 'top',
        fontWeight: 'normal',
        fontSize: '12px'
    },
    fontDetails: {
        fontFamily: 'verdana, helvetica',
        fontSize: '12px',
        verticalAlign: 'top',
        padding: '4px 0px'
    },
    scrollable: {
        maxHeight: '353.091px',
        overflowY: 'auto',
        marginBottom: '12px',
    }
}));

async function getDirectShareables(id, history) {
    try {
        let getDirectShareables = url.getDirectShareables;
        getDirectShareables = getDirectShareables.replace('**', id);
        let urlParam = getDirectShareables;
        showLoader();
        return await get(urlParam, history)
            .then((response) => {
                hideLoader();
                return response;
            })
            .catch((error) => {
                console.log("error::", error);
                hideLoader();
            });
    } catch (error) {
        hideLoader();
        console.error(error);
    }
}

function AccessibleResources(props) {
    const classes = useStyles();
    const teamsDetails = props.teamsDetails;
    const [directShareableData, setDirectShareableData] = useState([]);
    const [readAccess, setReadAccess] = useState([]);
    const [writeAccess, setWriteAccess] = useState([]);
    const [seqDb, setSeqDb] = useState([]);
    const [readFolder, setReadFolder] = useState([]);
    const [feature, setFeature] = useState([]);
    const [writeFolder, setWriteFolder] = useState([]);

    useEffect(() => {
        async function fetchMyAPI() {
            try {
                const result = await getDirectShareables(teamsDetails.gq_team_id);
                const initialData = result.response_content;
                setDirectShareableData(initialData);
                setReadAccess(initialData.readables);
                setWriteAccess(initialData.writeables);
                const seqDbArray = Object.values(initialData.readables.seqdb || {});
                const folderR = Object.values(initialData.readables.folder || {});
                const feature = Object.values(initialData.readables.feature || {});                
                const folderW = Object.values(initialData.writeables.folder || {});

                setSeqDb(seqDbArray);
                setReadFolder(folderR);
                setFeature(feature);
                setWriteFolder(folderW);
                console.log('testing :', seqDbArray);
            } catch (error) {
                console.error(error);
            }
        }
        fetchMyAPI();
    }, [])

    console.log('seqdb', seqDb);

    return (
        <div className={classes.scrollable}>
            <div className={classes.ARModal}>
                <div className={classes.fontDetails} style={{ padding: '6px 0px 10px 0px' }}>
                    Resources directly accessible to {teamsDetails.text_label}:
                </div>

                <table style={{ width: '84%', border: '1px solid #dcd7c0' }}>
                    <thead>
                        <tr className='resulttable_head' style={{ padding: '12px' }}>
                            <th className={classes.tableHead}>Read Access </th>
                            <th className={classes.tableHead}>Write Access </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className={classes.fontDetails}>
                                {console.log('read:', readAccess.length)}
                                {readAccess.length !== 0 ? (
                                    <div>
                                        {seqDb && seqDb.length > 0 && (
                                            <li style={{marginLeft: '12px'}}><b className={classes.fontDetails}>SeqDb</b></li>
                                        )}
                                        {seqDb.map((seq, index) => (
                                            seq.text_label != '' &&
                                            <li style={{ marginLeft: '40px' }} key={index}>{seq.text_label}</li>
                                        ))}
                                        {readFolder && readFolder.length > 0 && (
                                            <li style={{marginLeft: '12px'}}><b className={classes.fontDetails}>Folder</b></li>
                                        )}
                                        {readFolder.map((folder, index) => (
                                            folder.text_label != '' &&
                                            <li style={{ marginLeft: '40px' }} key={index}>{folder.text_label}</li>
                                        ))}
                                        {feature && feature.length > 0 && (
                                            <li style={{marginLeft: '12px'}}><b className={classes.fontDetails}>Feature</b></li>
                                        )}
                                        {feature.map((feature, index) => (
                                            feature.text_label &&
                                            <li style={{ marginLeft: '40px' }} key={index}>{feature.text_label}</li>
                                        ))}
                                    </div>
                                ) : "No resources directly accessible."}
                            </td>
                            <td className={classes.fontDetails}>
                                {writeAccess.length !== 0 ?
                                    (
                                        <div>
                                            {writeFolder && writeFolder.length > 0 && (
                                               <li style={{marginLeft: '12px'}}><b className={classes.fontDetails}>Folder</b></li>
                                            )}
                                            {writeFolder.map((folder, index) => (
                                                <li style={{marginLeft: '40px'}} key={index}>{folder.text_label}</li>
                                            ))}
                                        </div>
                                    )
                                    : "No resources directly accessible."}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default AccessibleResources;