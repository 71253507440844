import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
import ManageTeamsMembers from './ManageTeamMembers';
import ManageTeamEdit from './ManageTeamEdit';
import AccessibleResources from './AccessibleResources';
import { get } from '../../helpers/fetchServicesMethods';
import PubSub from 'pubsub-js';
import { url } from '../../reducers/url';

function showLoader() {
    PubSub.publish('msg', true);
  }
  
  function hideLoader() {
    PubSub.publish('msg', false);
  }

  export async function getGqTeamInfo(id, history) {
    try {
      let gqTeamInfo = url.gqTeamInfo;
      gqTeamInfo = gqTeamInfo.replace('**', id)
      showLoader();
      return await get(gqTeamInfo, history)
        .then((response) => {
          hideLoader();
          return response;
        })
        .catch((error) => {
          console.log("error::", error);
          hideLoader();
        });
    } catch (error) {
      hideLoader();
      console.error(error);
    }
  }

  export async function getGqUpdatedInfo(id, history) {
    try {
      let gqUpdateTeamInfo = url.gqUpdateTeamInfo;
      gqUpdateTeamInfo = gqUpdateTeamInfo.replace('**', id)
      showLoader();
      return await get(gqUpdateTeamInfo, history)
        .then((response) => {
          hideLoader();
          return response;
        })
        .catch((error) => {
          console.log("error::", error);
          hideLoader();
        });
    } catch (error) {
      hideLoader();
      console.error(error);
    }
  }

function ManageTeamsInfo(props){

    const [state, setState] = useState("Members");
    const allowEdit = props.gqTeamInfoData[props.teamId]._is_writable;
    const gqTeamId = props.gqTeamInfoData[props.teamId].gq_team_id;
    const [teamsDetails, setTeamsDetails] = useState([]);

    useEffect(() => {
      async function fetchMyAPI() {
        try {
          let result;    
          if (!allowEdit) {
            result = await getGqTeamInfo(gqTeamId);
          } else if(allowEdit){
            result = await getGqUpdatedInfo(gqTeamId);
          }
          const initialData = result.response_content;
          setTeamsDetails(initialData);
          console.log("initialData: ", initialData);
        } catch (error) {
          console.error(error);
        }
      }
      fetchMyAPI();
    }, []);

    function switchPage(pageName){
        setState(pageName);
    }
    const afterEditTeam = (afterEditData) => {
        props.manageTeamsDataAfterEdit(afterEditData);
      }

    return(
        <div>
            <div>
                <button className={state === "Members" ? "inactiveManageTeams" : "accountTabs"} onClick={()=>switchPage("Members")}> Members</button>
                {allowEdit && <button className={state === "Edit" ? "inactiveManageTeams" : "accountTabs"}  onClick={()=>switchPage("Edit")}>Edit</button>}
                <button className={state === "AccessibleResources" ? "inactiveManageTeams" : "accountTabs"}  onClick={()=>switchPage("AccessibleResources")}>Accessible Resources</button>
            </div>

            {state == "Members" &&
            <div>
                <ManageTeamsMembers teamsDetails = {teamsDetails} allowEdit = {allowEdit}></ManageTeamsMembers>
            </div>
            }
            {state == "Edit" &&
            <div>
                <ManageTeamEdit teamsDetails = {teamsDetails} afterEditTeam = {afterEditTeam}></ManageTeamEdit>
            </div>
            }
            {state == "AccessibleResources" &&
            <div>
                <AccessibleResources teamsDetails={teamsDetails}></AccessibleResources>
            </div>
            }
        </div>
    );

}

export default ManageTeamsInfo;