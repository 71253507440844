import React, { useState, useEffect } from "react";
import CloseIcon from '@material-ui/icons/Close';



function OntoCompletePopup(props){
   
    //returns luceneQueryValue == Search
    const {synonymItems, hoveredTerm, setHoveredTerm, selectOntocomplete, closeSelf} = props;

    return(
        <div>
            <div className="ontoCompleteHeadDisaplay">
                <span className="ftCustomHeader">Tab:</span>
                <div className="ontoClose">
                    <span className="appTextcolor appTextFont">esc:&nbsp;</span>   
                    <CloseIcon onClick={closeSelf()}/>  
                </div>
            </div>
            <div className="ftScrolly ontoSuggestions">
                {synonymItems.length !=0 && synonymItems[0] != "" &&
				
						<ul style={{margin: 0, padding: "5px"}}>
							{synonymItems !=[] &&
								synonymItems.map((data, i) => {
									return(
                                        <p 
                                        style={{cursor: "pointer"}}
                                        onMouseOver={()=>{setHoveredTerm(i)}}
                                        onClick={(e)=>selectOntocomplete()}
                                        className={i == hoveredTerm? "activeOntoItem" : "notActiveOntoItem"}>
										    {data}
										</p>
									);
								})
							}
		   			    </ul>
                }
            </div>
        </div>
    );

}

export default OntoCompletePopup;
