import React, { useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import _ from "lodash";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { FaTimes, FaPlus, FaMinus } from "react-icons/fa";
import Dropdown from 'react-bootstrap/Dropdown';
import FieldConfig from '../../components/Discover/FieldConfig';
import DropdownMenu from 'react-bootstrap/esm/DropdownMenu';
import {FiChevronDown,FiChevronUp,FiChevronRight,FiChevronLeft} from "react-icons/fi";
import Tooltip from "@material-ui/core/Tooltip";
const toolTipStyles = {
    tooltip: {
        width: "auto",
        //height: "40px",
        fontSize: '12px',
        textAlign: 'justify',
        padding: "8px 14px 8px 14px",
        boxShadow: "0px 1px 7px #888",
        backgroundColor: '#777',
        color: '#FFF'
    }
};
const errorToolTipStyles = {
    tooltip: {
        width: "200px",
        //height: "40px",
        fontSize: '12px',
        textAlign: 'center',
        padding: "8px 14px 8px 14px",
        boxShadow: "0px 1px 7px #888",
        backgroundColor: 'lightgrey',
        color: 'red'
    }
};
const CustomTooltip = withStyles(toolTipStyles)(Tooltip);
const ErrorTooltip = withStyles(errorToolTipStyles)(Tooltip);
const useStyles = makeStyles((theme) => ({
    modalClassConDSI: {
        position: 'relative',
        width: '80%', 
        //padding: '35px 15px 0px',
        right: 'auto',
        bottom: 'auto',
        color: '#777'
    },
    modalDialogClass: {
        top: '-100px',
        left: '125px'
       
    },
    modalBoxContent: {
        maxHeight: '600px',
    },
    closeButton: {
        position: 'absolute',
        top: '10px',
        right: '10px'
    },
}));

const inputText = {
    width:'210px', 
    height:'25px', 
    border: 'none !important',
    color: '#777',
    fontSize: '10pt',
    display: 'inline-block',
    fontWeight: '400',
    textAlign: 'left',
    verticalAlign: 'middle',
    userSelect: 'none',
    backgroundSolor: 'transparent',
    //border: '1px solid transparent',
    //padding:'0.375rem 0.75rem',
    //fontSize: '1rem',
    lineHeight: '1.5',
    //borderRadius: '0.25rem',
    borderColor: '#EFEFEF !important',
    //border: 'solid 1px #CCC !important',
    //borderadius: '4px !important'
    //width: '100%',
    borderRadius: '4px',
    paddingLeft: '0px',
    paddingRight: '10px',

  };

const SortingWidgetModal = props => {
    const classes = useStyles();
    const [sortOpt, setSortOpt] = useState([]);
    const [seed, setSeed] = useState(1);
    const [sortName, setSortName] = useState('');
    const [sortNameErr, setSortNameErr] = useState(false);
    const [optionErr, setOptionErr] = useState({});
    const [TooltipSaveAsNew, setTooltipSaveAsNew] = useState(false);
    const [TooltipSaveAsNewApply, setTooltipSaveAsNewApply] = useState(false);
    const [isHover, setIsHover] = useState(false);
    const [isApplyHover, setApplyHover] = useState(false);
    const [selectedDispOptionEls, setSelectedDispOptionEls] = useState();
    const [availableDispOptionEls, setAvailableDispOptionEls] = useState();

    const [selectedDispOptionPsl, setSelectedDispOptionPsl] = useState();
    const [availableDispOptionPsl, setAvailableDispOptionPsl] = useState();

    const [selectedDispOptionPa, setSelectedDispOptionPa] = useState();
    const [availableDispOptionPa, setAvailableDispOptionPa] = useState();

    const [selectedDispOptionGname, setSelectedDispOptionGname] = useState();
    const [availableDispOptionGname, setAvailableDispOptionGname] = useState();
    
    let Sortmap = _.cloneDeep(FieldConfig.sortOptions);
    //console.log(Sortmap, "Sortmap..");
   

    const resetseed = () => {
        setSeed(Math.random());
    };

    const modalLoaded = () => {
        //this is called only when modal is loaded, used for cancel button later
        setSortOpt(Sortmap);
        initialErrorSetting();
        setSortName('');
        setSortNameErr(false);
        //FieldConfig.sortOptionsErrStatus = [];

    };

    const modalClose = () => {
        setSortNameErr(false);
        props.closeModal() ;
        setTooltipSaveAsNewApply(false);
        setTooltipSaveAsNew(false);
    }

    const initialErrorSetting = () => {
        let sortOptions = FieldConfig.sortOptions;
        let sortOptionsErrStatus = [];
        for (let i = 0; i < sortOptions.length; i++) {
            let atom = sortOptions[i];
            sortOptionsErrStatus[i] = {};
            if (atom.column === 'PFD_ELS' || atom.column === 'PFD_LS' || atom.column === 'PFD_PA'
                || atom.column === 'PFD_GNAME' || atom.column === 'PFD_PSL') {
                if (atom.customVals.length === 0) {
                    sortOptionsErrStatus[i].customVals = false;
                }
            }else {sortOptionsErrStatus[i].column = false;}
            let desc = '';
            if (atom.method && atom.method !== '') {
                desc = FieldConfig.getSortingOrderDesc(atom.column, atom.method);
            }
            if (!desc || desc === '') {
                sortOptionsErrStatus[i].method = false;
            }
        }
        setOptionErr(sortOptionsErrStatus);

    }

    const checkValidity = () => {
        let valid = true;
        let sortOptions = FieldConfig.sortOptions;
        let sortOptionsErrStatus = [];
        if(sortName === ''){
            setSortNameErr(true);
            valid = false;
        }
        for (let i = 0; i < sortOptions.length; i++) {
            let atom = sortOptions[i];
            sortOptionsErrStatus[i] = {};
            if (!atom.column || atom.column === '') {
                sortOptionsErrStatus[i].column = true;
               valid = false;
            } else if (atom.column === 'PFD_ELS' || atom.column === 'PFD_LS' || atom.column === 'PFD_PA'
                || atom.column === 'PFD_GNAME' || atom.column === 'PFD_PSL') {
                if (atom.customVals.length === 0) {
                    sortOptionsErrStatus[i].customVals = true;
                    valid = false;
                }
            }
            let desc = '';
            if (atom.method && atom.method !== '') {
                desc = FieldConfig.getSortingOrderDesc(atom.column, atom.method);
            }
            if (!desc || desc === '') {
                sortOptionsErrStatus[i].method = true;
                valid = false;
            }

        }
        setOptionErr(sortOptionsErrStatus);
        return valid;
        
    }

    const saveAsNew = () => {
        setSortNameErr(false);
        let v = checkValidity();
        if(v){
            FieldConfig.addSortOption(sortName, props.fieldConfig, props.filter);
            props.closeModal() ;
        }else{
            setTooltipSaveAsNew(true);
            setTooltipSaveAsNewApply(true);
        }
        //FieldConfig.addSortOption(sortName);
        //props.closeModal() ;

    }
    const deleteSort = () => {
        if(sortName !== ''){
        props.deleteSortOption(props.fieldConfig, props.filter);
        //resetseed();
        props.closeModal() ;}
    }

    const handleInputChange = (e) => {
        setSortName(e.target.value);
        //setSortOpt(FieldConfig.sortOptions);
        //resetseed();

    }
    const handleSortname = (e,key) => {
        setSortName(key.sortOptionName);
        FieldConfig.setSortOption(key);
        setSortOpt(FieldConfig.sortOptions);
        initialErrorSetting();
        resetseed();
    }

    const saveAsNewApply = () => {  
        setSortNameErr(false);
        let v = checkValidity();
        if(v){
            FieldConfig.addSortOption(sortName, props.fieldConfig, props.filter);
            props.errorsInSortOption(true,sortName);
            props.closeModal() ;
        }else{
            setTooltipSaveAsNewApply(true);
            setTooltipSaveAsNew(true);
        }  
       
    }

    const handleMenuItem = (e, idx, field, atom) => {
        FieldConfig.setSortingOption(idx, field);
        setSortOpt(FieldConfig.sortOptions);
        //resetseed();
        if(field ==='PFD_ELS'){
            if(idx === 0){FieldConfig.initCustomFields(FieldConfig.sortOptions[0], 'PFD_ELS');}
            else{FieldConfig.initCustomFields(atom, 'PFD_ELS');}
        }
        if(field === 'PFD_PSL'){
            if(idx === 0){FieldConfig.initCustomFields(FieldConfig.sortOptions[0], 'PFD_PSL');}
            else{FieldConfig.initCustomFields(atom, 'PFD_PSL');}
        }
        if(field === 'PFD_PA'){
            if(idx === 0){FieldConfig.initCustomFields(FieldConfig.sortOptions[0], 'PFD_PA');}
            else{FieldConfig.initCustomFields(atom, 'PFD_PA');}
           
        }
        if(field === 'PFD_GNAME'){
            if(idx === 0){FieldConfig.initCustomFields(FieldConfig.sortOptions[0], 'PFD_GNAME');}
            else{FieldConfig.initCustomFields(atom, 'PFD_GNAME');}
        }
        //console.log(field,atom,'field');
        initialErrorSetting();
        resetseed();
    }

    const handleValueMenuItem = (e,k,val) =>{
        FieldConfig.setSortingOptionMethod(k, val);
        setSortOpt(FieldConfig.sortOptions);
        initialErrorSetting();
        resetseed();
    }

    const handlePlus = (e, k) => {
        FieldConfig.addSortOptionAtom(k);
        setSortOpt(FieldConfig.sortOptions);
        initialErrorSetting();
        resetseed();
    }

    const handleMinus = (e, k) => {
        FieldConfig.deleteSortOptionAtom(k);
        setSortOpt(FieldConfig.sortOptions);
        initialErrorSetting();
        resetseed();
    }
    const handleDoubleClick = (e,atom,key,field) => {
        FieldConfig.addCustomFields(atom,key,field);
        resetseed();
    }
    const handleDoubleClickSelected = (e,atom,key,field) => {
        FieldConfig.removeCustomFields(atom,key,field);
        resetseed();
    }
    const handleAvlChange = (e,field) => {
        let value = Array.from(e.target.selectedOptions, option => option.value);
        if(field === 'PFD_ELS'){setAvailableDispOptionEls(value);}
        if(field === 'PFD_PSL'){setAvailableDispOptionPsl(value);}
        if(field === 'PFD_PA'){setAvailableDispOptionPa(value);}
        if(field === 'PFD_GNAME'){setAvailableDispOptionGname(value);}

      };

    const handleSelChange = (e,field) => {
        let value = Array.from(e.target.selectedOptions, option => option.value);
        if(field === 'PFD_ELS'){setSelectedDispOptionEls(value);}
        if(field === 'PFD_PSL'){setSelectedDispOptionPsl(value);}
        if(field === 'PFD_PA'){setSelectedDispOptionPa(value);}
        if(field === 'PFD_GNAME'){setSelectedDispOptionGname(value);}
        
      };

    const handleRight = (e,atom,selected,field) => {
        FieldConfig.addSortingField(atom, selected, field);
        resetseed();
    }
    const handleLeft = (e,atom,selected,field) => {
        FieldConfig.delSortingField(atom, selected, field);
        resetseed();
    }
    const handleUp = (e,selected,atom ) => {
        FieldConfig.promiseMoveSortingFieldsUp(selected,atom);
        resetseed();
    }
    const handleDown = (e,selected,atom ) => {
        FieldConfig.promiseMoveSortingFieldsDown(selected,atom);
        resetseed();
    }

    return (
        <Modal
            size="xl"
            aria-labelledby="contained-modal-title-vcente"
            centered
            show={props.showModal}
            contentClassName={classes.modalClassConDSI}
            dialogClassName={classes.modalDialogClass}
            onEntered={modalLoaded}
        >

            <div className="really-large-font" style={{ padding: '20px 20px 0 300px' }}>Manage Sort Options</div>
             <div className={classes.closeButton}>
            <FaTimes style={{ height: '2em' }} onClick={modalClose} />
            </div>
            <div className="big-message" ng-app="app">
                <div className="filter-widget-container" key={seed}>                   
                    <div className="rTable">
                        <div className="rTableRow">
                            <div className="filter-widget-field rTableCell colspan">
                                <Dropdown>
                                <div className="pull-right" uib-dropdown keyboard-nav>
                                <CustomTooltip arrow title="Specify a name for this sort option" placement="top"> 
                                    <Dropdown.Toggle style={{backgroundColor:'#EFEFEF', borderColor: '#EFEFEF',height: '35px'}} className={sortNameErr ? 'input-with-error': ''}>
                                    
                                    <input type="text" ng-model="fieldConfig.sortOptionName" style={inputText} 
                                    placeholder="Name or Select your Sort Option"
                                    onChange={handleInputChange} value={sortName}
                                    className='btn'></input>
                                   
                                    </Dropdown.Toggle>
                                </CustomTooltip>
                                    <DropdownMenu className="dropdown-menu dropdown-menu-dates">
                                        {FieldConfig.customSortConfig.map((key) => {
                                            return(
                                        <div onClick={(e) => handleSortname(e,key)}className="filter-widget-menu-item">{key.sortOptionName}</div>)
                                        })
                                        }
                                    </DropdownMenu>
                                </div>
                                
                                </Dropdown>

                            </div>
                        </div>
                        <div className="rTableRow">
                            <div className="rTableCell colspan"><hr /></div>
                        </div>
                        <div >
                            {sortOpt.map((atom, k) => {
                                return (
                                    <div key={atom.id}>
                                        {k == 0 && <div className="rTableCell" style={{ width: '60px', verticalAlign: 'middle' }}>Sort by</div>}
                                        {k > 0 && <div className="rTableCell" style={{ width: '60px', verticalAlign: 'middle' }} >Then by</div>}
                                        {/**********Column name *************/}
                                        <div className="rTableCell filter-widget-method" style={{ verticalAlign: 'middle' }}>
                                            <div className="control-spacer">

                                                <Dropdown className="btn-group" >
                                                    <Dropdown.Toggle className={'btn btn-dropdown btn-dropdown-methods '+ (optionErr[k].column ? 'input-with-error' : '')}

                                                        id={'methodmenu_' + k} drop='start'>
                                                        <div className="clearfix">
                                                            <div className="filter-widget-filter-method" dangerouslySetInnerHTML={{__html: FieldConfig.getFieldName(atom.column)}}></div>
                                                            <div style={{ display: 'inline-block', position: 'absolute', right: '12px' }}><span className="caret"></span></div>
                                                        </div>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu className='dropdown-menu dropdown-menu-dates'>
                                                        {
                                                            FieldConfig.sortingWidgetFields.map(({ group, fields }, key) => {
                                                                if ((group === 'CAS Biosequences™' && props.regAccess) || group  !== 'CAS Biosequences™')
                                                                    return (
                                                                        <div className='sortdiv'>
                                                                            <div className="filter-widget-group-item">{group}</div>
                                                                            {
                                                                                fields.map((field, key) => (
                                                                                    <Dropdown.ItemText key={key} className="filter-widget-menu-item"
                                                                                        onClick={e => handleMenuItem(e, k, field, atom)}
                                                                                        dangerouslySetInnerHTML={{__html: FieldConfig.getFieldName(field)}} >
                                                                                        {/*FieldConfig.getFieldName(field)*/}

                                                                                    </Dropdown.ItemText>
                                                                                ))
                                                                            }
                                                                        </div>)
                                                                return (null)

                                                            })
                                                        }
                                                    </Dropdown.Menu>
                                                </Dropdown>

                                            </div>
                                        </div>

                                        {/************VALUE************/}
                                        <div className="rTableCell filter-widget-options" style={{ verticalAlign: 'middle' }}>
                                            <div className="control-spacer">

                                                <Dropdown className="btn-group">
                                                    <Dropdown.Toggle id={'sortingmenu_' + k} className={'btn btn-dropdown btn-dropdown-methods '+ (optionErr[k].method ? 'input-with-error' : '')}> 
                                                        <div className="clearfix">
                                                            <div className="filter-widget-filter-method"> {atom.method !== null ? FieldConfig.getSortingOrderDesc(atom.column, atom.method) : ''}</div>
                                                            <div style={{ display: 'inline-block', position: 'absolute', right: '12px' }}><span className="caret"></span></div>
                                                        </div>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu className="dropdown-menu dropdown-menu-options">
                                                        {
                                                            Object.entries(FieldConfig.getSortingOrderList(atom.column)).map(([key, val], i) => (
                                                                <Dropdown.ItemText key={i} className="filter-widget-menu-item"  onClick={e => handleValueMenuItem(e,k,key)} > {val}
                                                                </Dropdown.ItemText>
                                                            ))
                                                        }
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        </div>
                                        {/********** PLUS MINUS BUTTONS */}
                                        <div className="rTableCell filter-widget-adddel" style={{ width: '30px' }}>
                                            {FieldConfig.getNbAtomsInSortingWidget() > 1 &&
                                                <CustomTooltip arrow title="Remove this Condition" placement="bottom">
                                                <div className="control-spacer plus-min-button"  tooltip-placement="bottom" onClick={e => handleMinus(e, k)}>
                                                    <FaMinus ></FaMinus>
                                                </div>
                                                </CustomTooltip>
                                            }
                                        </div>
                                        <div className="rTableCell filter-widget-adddel" style={{ width: '30px' }}>
                                            <CustomTooltip arrow title="Add Condition below" placement="bottom">
                                            <div className="control-spacer plus-min-button" tooltip-placement="bottom" onClick={e => handlePlus(e, k)} >
                                                <FaPlus ></FaPlus>
                                            </div>
                                            </CustomTooltip>
                                        </div>

                                        {/*****Custom val filters*********************** */}
                                        {atom.column === 'PFD_ELS' && 
                                        <div className="col-select-container">
                                            <table>
                                                <tbody>
                                                <tr>
                                                        <td>
                                                            <select multiple={true} className="els-container"
                                                            value={availableDispOptionEls}
                                                            onChange={ (e) => handleAvlChange(e,'PFD_ELS')}
                                                            >
                                                            {Object.entries(atom.customValList).map(([key,val],i) => (
                                                                <option key={key} className="field-option"
                                                                    onDoubleClick={ (e) => handleDoubleClick(e,atom,key,'PFD_ELS')}
                                                                    value={key}
                                                                >{val}
                                                                </option>
                                                            ))
                                                        }
                                                            </select>
                                                        </td>
                                                        <td className="els-controls">
                                                            <div>
                                                                <FiChevronRight onClick={(e) => handleRight(e,atom,availableDispOptionEls,'PFD_ELS')}></FiChevronRight>
                                                            </div>
                                                            <div>
                                                                <FiChevronLeft onClick={(e) => handleLeft(e,atom,selectedDispOptionEls,'PFD_ELS')}></FiChevronLeft>
                                                            </div>
                                                            <div>
                                                                <FiChevronUp  onMouseDown={e => handleUp(e,selectedDispOptionEls,atom)}
                                                                onMouseUp={(e) => {FieldConfig.refreshCustomFields(atom,'PFD_ELS');}} ></FiChevronUp>
                                                            </div>
                                                            <div>
                                                                <FiChevronDown onMouseDown={e => handleDown(e,selectedDispOptionEls,atom)}
                                                                onMouseUp={(e) => {FieldConfig.refreshCustomFields(atom,'PFD_ELS');}} ></FiChevronDown>
                                                            </div>

                                                        </td>
                                                        <td>
                                                            <select multiple={true} className={'els-container ' + (optionErr[k].customVals? 'input-with-error' : '')} id="dispColsPFD_ELS"
                                                                    value={selectedDispOptionEls}
                                                                    onChange={(e) => handleSelChange(e,'PFD_ELS')}>
                                                            {(atom.customVals).map((item,key) => (
                                                                <option className="field-option" key={key}
                                                                        onDoubleClick={(e) => handleDoubleClickSelected(e,atom,item,'PFD_ELS')}
                                                                        value={item}
                                                                        >{FieldConfig.getCustomOptionDesc('PFD_ELS',item)}
                                                                </option>
                                                            ))
                                                                }
                                                            </select>
                                                        </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        }
                                        
                                        {atom.column === 'PFD_PSL' && 
                                        <div className="col-select-container">
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <select multiple={true} className="els-container"
                                                            value={availableDispOptionPsl}
                                                            onChange={(e) => handleAvlChange(e,'PFD_PSL')}
                                                            >
                                                            {Object.entries(atom.customValList).map(([key,val],i) => (
                                                                <option key={key} className="field-option"
                                                                    onDoubleClick={ (e) => handleDoubleClick(e,atom,key,'PFD_PSL')}
                                                                    value={key}
                                                                >{val}</option>
                                                            ))
                                                            }
                                                            </select>
                                                        </td>
                                                        <td className="els-controls">
                                                            <div>
                                                                <FiChevronRight onClick={(e) => handleRight(e,atom,availableDispOptionPsl,'PFD_PSL')}></FiChevronRight>
                                                            </div>
                                                            <div>
                                                                <FiChevronLeft onClick={(e) => handleLeft(e,atom,selectedDispOptionPsl,'PFD_PSL')}></FiChevronLeft>
                                                            </div>
                                                            <div>
                                                                <FiChevronUp  onMouseDown={e => handleUp(e,selectedDispOptionPsl,atom)}
                                                                onMouseUp={(e) => {FieldConfig.refreshCustomFields(atom,'PFD_PSL');}} ></FiChevronUp>
                                                            </div>
                                                            <div>
                                                                <FiChevronDown onMouseDown={e => handleDown(e,selectedDispOptionPsl,atom)}
                                                                onMouseUp={(e) => {FieldConfig.refreshCustomFields(atom,'PFD_PSL');}} ></FiChevronDown>
                                                            </div>

                                                        </td>
                                                        <td>
                                                            <select multiple={true} className={'els-container ' + (optionErr[k].customVals? 'input-with-error' : '')} id="dispColsPFD_PSL"
                                                                    value={selectedDispOptionPsl}
                                                                    onChange={(e) => handleSelChange(e,'PFD_PSL')}>
                                                            {(atom.customVals).map((item,key) => (
                                                                <option className="field-option" key={key}
                                                                        onDoubleClick={(e) => handleDoubleClickSelected(e,atom,item,'PFD_PSL')}
                                                                        value={item}
                                                                        >{FieldConfig.getCustomOptionDesc('PFD_PSL',item)}
                                                                </option>
                                                            ))
                                                                }
                                                            </select>
                                                        </td>
                                                     </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        }

                                        
                                        {atom.column === 'PFD_GNAME' && 
                                        <div className="col-select-container">
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <select multiple={true} className="els-container"
                                                            value={availableDispOptionGname}
                                                            onChange={(e) => handleAvlChange(e,'PFD_GNAME')}
                                                            >
                                                            {Object.entries(atom.customValList).map(([key,val],i) => (
                                                                <option key={key} className="field-option"
                                                                    onDoubleClick={ (e) => handleDoubleClick(e,atom,key,'PFD_GNAME')}
                                                                    value={key}
                                                                >{val}</option>
                                                            ))
                                                            }
                                                            </select>
                                                        </td>
                                                        <td className="els-controls">
                                                            <div>
                                                                <FiChevronRight onClick={(e) => handleRight(e,atom,availableDispOptionGname,'PFD_GNAME')}></FiChevronRight>
                                                            </div>
                                                            <div>
                                                                <FiChevronLeft onClick={(e) => handleLeft(e,atom,selectedDispOptionGname,'PFD_GNAME')}></FiChevronLeft>
                                                            </div>
                                                            <div>
                                                                <FiChevronUp  onMouseDown={e => handleUp(e,selectedDispOptionGname,atom)}
                                                                onMouseUp={(e) => {FieldConfig.refreshCustomFields(atom,'PFD_GNAME');}} ></FiChevronUp>
                                                            </div>
                                                            <div>
                                                                <FiChevronDown onMouseDown={e => handleDown(e,selectedDispOptionGname,atom)}
                                                                onMouseUp={(e) => {FieldConfig.refreshCustomFields(atom,'PFD_GNAME');}} ></FiChevronDown>
                                                            </div>

                                                        </td>
                                                        <td>
                                                            <select multiple={true} className={'els-container ' + (optionErr[k].customVals? 'input-with-error' : '')} id="dispColsPFD_GNAME"
                                                                    value={selectedDispOptionGname}
                                                                    onChange={(e) => handleSelChange(e,'PFD_GNAME')}>
                                                            {(atom.customVals).map((item,key) => (
                                                                <option className="field-option" key={key}
                                                                        onDoubleClick={(e) => handleDoubleClickSelected(e,atom,item,'PFD_GNAME')}
                                                                        value={item}
                                                                        >{FieldConfig.getCustomOptionDesc('PFD_GNAME',item)}
                                                                </option>
                                                            ))
                                                                }
                                                            </select>
                                                        </td>
                                                     </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        }
                                        
                                        {atom.column === 'PFD_PA' && 
                                        <div className="col-select-container">
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <select multiple={true} className="els-container"
                                                            value={availableDispOptionPa}
                                                            onChange={(e) => handleAvlChange(e,'PFD_PA')}
                                                            >
                                                           {Object.entries(atom.customValList).map(([key,val],i) => (
                                                                <option key={key} className="field-option"
                                                                    onDoubleClick={ (e) => handleDoubleClick(e,atom,key,'PFD_PA')}
                                                                    value={key}
                                                                >{val}</option>
                                                            ))
                                                            }
                                                            </select>
                                                        </td>
                                                        <td className="els-controls">
                                                            <div>
                                                                <FiChevronRight onClick={(e) => handleRight(e,atom,availableDispOptionPa,'PFD_PA')}></FiChevronRight>
                                                            </div>
                                                            <div>
                                                                <FiChevronLeft onClick={(e) => handleLeft(e,atom,selectedDispOptionPa,'PFD_PA')}></FiChevronLeft>
                                                            </div>
                                                            <div>
                                                                <FiChevronUp  onMouseDown={e => handleUp(e,selectedDispOptionPa,atom)}
                                                                onMouseUp={(e) => {FieldConfig.refreshCustomFields(atom,'PFD_PA');}} ></FiChevronUp>
                                                            </div>
                                                            <div>
                                                                <FiChevronDown onMouseDown={e => handleDown(e,selectedDispOptionPa,atom)}
                                                                onMouseUp={(e) => {FieldConfig.refreshCustomFields(atom,'PFD_PA');}} ></FiChevronDown>
                                                            </div>

                                                        </td>
                                                        <td>
                                                            <select multiple={true} className={'els-container ' + (optionErr[k].customVals? 'input-with-error' : '')} id="dispColsPFD_PA"
                                                                    value={selectedDispOptionPa}
                                                                    onChange={(e) => handleSelChange(e,'PFD_PA')}>
                                                            {(atom.customVals).map((item,key) => (
                                                                <option className="field-option" key={key}
                                                                        onDoubleClick={(e) => handleDoubleClickSelected(e,atom,item,'PFD_PA')}
                                                                        value={item}
                                                                        >{FieldConfig.getCustomOptionDesc('PFD_PA',item)}
                                                                </option>
                                                            ))
                                                                }
                                                            </select>
                                                        </td>
                                                     </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        }
                                    
                                    </div>
                                )
                            }
                            )}
                        </div>
                        <div className="rTableRow">
                            <div className="rTableCell colspan"></div>
                        </div>


                        {/************Buttons**********/}
                        <div className="rTableRow">
                            <div className="rTableCell clearfix colspan">
                                <div className="clearfix filter-widget-bottom">
                                    <button className="pull-left normal-button grey" style={{ margin: '0px' }} onClick={deleteSort}>Delete Sort Option</button>
                                    <ErrorTooltip arrow title="Please fix above errors marked in Red" placement="top" open={TooltipSaveAsNewApply && isApplyHover }> 
                                    <button className={FieldConfig.sortOptionNameUsed(sortName)===- 1 ? 'gqblue pull-right normal-button' : 'gqblue-alert pull-right normal-button'}
                                        onClick={saveAsNewApply} onMouseOver={() => setApplyHover(true)} onMouseLeave={() => setApplyHover(false)}>
                                            {FieldConfig.sortOptionNameUsed(sortName) ===-1 ? 'Save as New' : 'Overwrite Existing'} and Apply</button></ErrorTooltip>
                                    <ErrorTooltip arrow title="Please fix above errors marked in Red" placement="top" open={TooltipSaveAsNew && isHover }> 
                                    <button className={FieldConfig.sortOptionNameUsed(sortName)===- 1 ? 'gqblue pull-right normal-button' : 'gqblue-alert pull-right normal-button'}
                                        onClick={saveAsNew} onMouseOver={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)}
                                        tooltip-popup-delay="250">{FieldConfig.sortOptionNameUsed(sortName) ===-1 ? 'Save as New' : 'Overwrite Existing'}</button></ErrorTooltip>
                                       
                                    <button className="pull-right normal-button grey" onClick={() => {props.closeModal(); setTooltipSaveAsNewApply(false);setTooltipSaveAsNew(false);}}>Cancel</button>
                                </div>

                            </div>

                        </div>
                    </div>

                </div>
            </div>


        </Modal>
    );
}

export default SortingWidgetModal;
