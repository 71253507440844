import { post, get } from '../helpers/fetchServicesMethods';
import { toast } from 'react-toastify';
import { url } from '../reducers/url';
import { id } from 'date-fns/locale';
import PubSub from 'pubsub-js';


function showLoader() {
    PubSub.publish('msg', true);
}

function hideLoader() {
    PubSub.publish('msg', false);
}

async function getFullHistory(history){
    try{
        let apiurl = url.fullTextQueryHistory;
        showLoader();
        return await get(apiurl, history)
            .then((response) => {
                hideLoader();
                return response;
            })
            .catch((error) => {
                hideLoader();
                //toast.error('A');
                console.log("error::", error);
            })
    } catch (error) {
        console.error(error);
    }
}

async function getFullTextSearchTerm(history, searchParam) {
    try {
        let apiurl = url.fullTextSearchTerm + searchParam;
        //showLoader();
        return await get(apiurl, history)
            .then((response) => {
                //hideLoader();
                return response;
            })
            .catch((error) => {
                hideLoader();
                //toast.error('A');
                console.log("error::", error);

                // return dispatch(loginError(error));
                // dispatch(showMessage({ message: error }));
            });
    } catch (error) {
        console.error(error);
    }

}

async function getFullTextSearchResult(history, searchParam, paramData) {
    try {
        let apiurl = url.fullTextSearchResult + searchParam;
        showLoader();
        return await post(apiurl, paramData, history)
            .then((response) => {
                hideLoader();
                if(response && response.response_status == 0 && response.response_content && response.response_content.status == 500){
                    toast.error('Something went wrong');
                }
                return response;
            })
            .catch((error) => {
                hideLoader();
                //toast.error('A');
                console.log("error::", error);

                // return dispatch(loginError(error));
                // dispatch(showMessage({ message: error }));
            });
    } catch (error) {
        console.error(error);
    }

}


async function getCountByTerm(history, grouping, postData) {
    try {
        let apiurl = 'do=gqft.count_by_terms&format=json';
            apiurl += '&grouping=Family' + grouping;
        //showLoader();
        return await post(apiurl, postData, history)
            .then((response) => {
                //hideLoader();
                return response;
            })
            .catch((error) => {
                hideLoader();
                toast.error('Could not get result count');
                console.log("error::", error);

                // return dispatch(loginError(error));
                // dispatch(showMessage({ message: error }));
            });
    } catch (error) {
        console.error(error);
    }

}


async function fullDocViewService(history, searchParam) {
    try {
        let apiurl = `${url.fullDocView}&patent_number=${searchParam}`;
        showLoader();
        return await get(apiurl, history)
            .then((response) => {
                hideLoader();
                return response;
            })
            .catch((error) => {
                // hideLoader();
                //toast.error('A');
                console.log("error::", error);

                // return dispatch(loginError(error));
                // dispatch(showMessage({ message: error }));
            });
    } catch (error) {
        console.error(error);
    }

}
async function getACSynonyms(history, selectedTermId) {
    try {
        let apiurl = url.autoqueryfindterm;
        apiurl = apiurl.replace('**', selectedTermId);
        //showLoader();
        return await get(apiurl, history)
            .then((response) => {
                //hideLoader();
                return response;
            })
            .catch((error) => {
                //hideLoader();
                //toast.error('A');
                console.log("error::", error);

                // return dispatch(loginError(error));
                // dispatch(showMessage({ message: error }));
            });
    } catch (error) {
        console.error(error);
    }

}

async function updateACSynonyms(postData, history) {
    try {
        let apiurl = "do=gqft.update_term&format=json"
        showLoader();
        return await post(apiurl, postData, history)
            .then((response) => {
                hideLoader();
                return response;
            })
            .catch((error) => {
                hideLoader();
                //toast.error('A');
                console.log("error::", error);

                // return dispatch(loginError(error));
                // dispatch(showMessage({ message: error }));
            });
    } catch (error) {
        console.error(error);
    }

}

async function deleteSynonyms(postData, history) {
    try {
        let apiurl = "do=gqft.delete_terms&format=json"
        showLoader();
        return await post(apiurl, postData, history)
            .then((response) => {
                hideLoader();
                return response;
            })
            .catch((error) => {
                hideLoader();
                //toast.error('A');
                console.log("error::", error);

                // return dispatch(loginError(error));
                // dispatch(showMessage({ message: error }));
            });
    } catch (error) {
        console.error(error);
    }

}

async function saveFTDocument(history, searchParam, paramData) {
    try {
        let apiurl = `${url.saveFTDocument}${searchParam}`;
        //showLoader();
        return await post(apiurl, paramData, history)
            .then((response) => {
                //hideLoader();
                return response;
            })
            .catch((error) => {
                // hideLoader();
                //toast.error('A');
                console.log("error::", error);

                // return dispatch(loginError(error));
                // dispatch(showMessage({ message: error }));
            });
    } catch (error) {
        console.error(error);
    }

}

async function deleteHistory(idString){
    try {

        let apiurl = url.fullTextDeleteHistory;
        let postData = {
            'id': idString
        };
        
        showLoader();
        return await post(apiurl, postData)
        .then((response) => {
            hideLoader();
            toast.success("History Items Deleted");
            return response;
        })
        .catch((error) => {
            hideLoader();
            toast.error('Could not Delete Items');
            console.log("error::", error);
            // return dispatch(loginError(error));
            // dispatch(showMessage({ message: error }));
        });
    } catch (error) {
        console.error(error);
    }
}

const levenshteinDistance = (str1 = '', str2 = '') =>{
    const track = Array(str2.length + 1).fill(null).map(() =>
    Array(str1.length + 1).fill(null));
    for (let i = 0; i <= str1.length; i += 1) {
       track[0][i] = i;
    }
    for (let j = 0; j <= str2.length; j += 1) {
       track[j][0] = j;
    }
    for (let j = 1; j <= str2.length; j += 1) {
       for (let i = 1; i <= str1.length; i += 1) {
          const indicator = str1[i - 1] === str2[j - 1] ? 0 : 1;
          track[j][i] = Math.min(
             track[j][i - 1] + 1, // deletion
             track[j - 1][i] + 1, // insertion
             track[j - 1][i - 1] + indicator, // substitution
          );
       }
    }
    return track[str2.length][str1.length];
 };


const fullTextService = {
    getCountByTerm,
    getFullTextSearchTerm,
    getFullTextSearchResult,
    fullDocViewService,
    getACSynonyms,
    updateACSynonyms,
    saveFTDocument,
    getFullHistory,
    deleteHistory,
    deleteSynonyms,
    levenshteinDistance
};

export default fullTextService;
