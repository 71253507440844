import React, {useState,useEffect} from 'react';
import styled from 'styled-components';
import ResultTableRecord from './ResultTableRecord';
import _ from "lodash";
import ResultTableRecDetail from "./ResultTableRecDetail";

const Styles = styled.div`
    .main-content {
        /*  width: 100%;*/
        padding-left: 10px;
    }
    .main-table {
        cursor: default;
    }
    .content-header {
        margin-bottom: 10px;
    }
    .main-row-sep {
        padding-top: 20px;
        border-top: solid 1px #CCC;
        margin-top: 20px;
    }
    .pull-left {
      float: left !important;
    }
    .table-header {
        font-weight: bold;
        padding-bottom: 10px;
    }
    .row-padding {
        padding: 4px 0 4px 0;
        display: inline-block;
    }
    .clearfix{*zoom:1;}.clearfix:before,.clearfix:after{display:table;content:"";line-height:0;}
    .clearfix() {
      &:before,
      &:after {
        content: " "; // 1
        display: table; // 2
      }
      &:after {
        clear: both;
      }
    }
  .gqcol {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-right: 8px;
    margin-left: 8px;
    min-height: 20px;
    min-width: 10px;
  }
  .gqcol: hover{
    cursor: ew-resize;
    border-right: 3px solid rgb(136,136,136);
  }
    .no-results {
        font-size: x-large;
        width: 500px;
        margin: auto;
    }
  .checkbox-div {
    padding-right: 3px;
    padding-left: 3px;
  }
  .color-select-div {
    padding-right: 3px;
    padding-left: 3px;
    cursor: pointer;
  }
  .indent {
    padding-left: 36px;
  }
  .tree {
    position: relative;
  }

  .tree:after {
    content: "";
    position: absolute;
    display: block;
    width: 0;
    height: 100%;
    top: 0;
    left: 9px;
    border-left: solid 1px #CCC;
  }

  .tree:before {
    content: "";
    position: absolute;
    display: block;
    width: 24px;
    height: 0;
    border-top: solid 1px #CCC;
    top: 15px;
    left: 10px;
  }

  .tree:last-child:before {
    height: auto;
    bottom: 0;
    top: 15px;
    left: 9px;
    background-color: #FFFFFF;
    z-index: 350;
  }
  tbody{font-family:"Helvetica Neue",Helvetica,Arial,sans-serif;font-size:14px;line-height:1.42857143;color:#333;background-color:#fff}
`

const highlightColors = [
    'FFEB3B', 'FFA726', 'EF9A9A', 'B39DDB', '80CBC4', 'F48FB1', '81D4FA',
    'A5D6A7', 'E6EE9C', 'FFF59D', 'FFE082', 'FFCC80', 'FFAB91',
    'FF7043', 'BCAAA4', 'B0BEC5'
];

const monthName = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
];

export default function ResultTable({ columns, data, displayMode, tableWidth, fieldConfig, filter, annotState,
                                        expandAllDetailPage, expandAllClaims, expandAllAlignments, openItemsView,
                                        fromItemsPage, groupValue1, groupValue2, saveAnnotation, parentAnnotState,
                                        refreshAnnotState, algorithm, isAbWorkflow, editable,
                                        setFtWindowsToOpen
                                    }) {
    const[tableState, setTableState] = useState(annotState);
    const [dragging, setDragging] = useState(()=>false);
    const [newWidth, setNewWidth] = useState(tableWidth);
    const [colConfig, setColConfig] = useState(_.cloneDeep(fieldConfig.fieldConfig));
    //const [lastPos, setLastPos] = useState(0);

    useEffect( async() => {
        setNewWidth(tableWidth);
    },[tableWidth]);

    const displayItemsView = (dbKey1, dbkey2, parentGroup) => {
        openItemsView(dbKey1, dbkey2, parentGroup, fieldConfig, filter);
    }
    const toggleFullRecordExpand = (key) => {
        annotState.fullRecordExpand[key] = !annotState.fullRecordExpand[key];
        setTableState({...annotState});
    }

    const expandAllClaim = (val) => {
        expandAllClaims(val);
        for (let idx in annotState.claimsCollapse) {
            annotState.claimsCollapse[idx] = val;
        }
        setTableState({...annotState});
    }

    const expandClaim = (key, val) => {
        annotState.claimsCollapse[key] = val;
        setTableState({...annotState});
    }

    const showLessClaim = (key, val) => {
        annotState.claimsCollapseLess[key] = val;
        setTableState({...annotState});
    }

    const expandAllAlignment = (val) => {
        expandAllAlignments(val);
        for (let idx in annotState.alignmentsCollapse) {
            annotState.alignmentsCollapse[idx] = val;
        }
        setTableState({...annotState});
    }

    const expandAlignment = (key, val) => {
        annotState.alignmentsCollapse[key] = val;
        setTableState({...annotState});
    }

    const expandAllDetailPages = (inType, val) => {
        let type = inType;
        if (inType === 'flat' || inType === 'sin') {
            type = 'ali';
        }
        expandAllDetailPage(displayMode, type, val);
        for (let idx in annotState.fullRecordExpand) {
            if (idx.startsWith(type)) {
                annotState.fullRecordExpand[idx] = val;
                /*
                if (val === true) {
                    annotState.fullRecordExpand[idx] = val;
                } else {
                    delete annotState.fullRecordExpand[idx];
                }*/
            }
        }
        setTableState({...annotState});
    }

    const highlight = (fieldConfig, filter, uiField, val, addPrefix) => {
        // field is always a real field that is being displayed
        // take care of combination fields like ALL_TEXT
        let fieldInfo, field, filterVals, highlightVals, combinedVals, i, j, z, entry, color, fieldsToProcess = [],
            prefix;

        if (val == null) {
            return '&nbsp;';
        } else if (val === '') {
            return '&nbsp;';
        }

        // fix display of geneseq Family IDs
        if (uiField === 'SUBJECT_PB' && _.endsWith(val, '-GENESEQ')) {
            val = '<span class="not-available">Unknown (GeneSeq)</span>';
        }

        fieldInfo = fieldConfig.getFieldInfo(uiField);
        if (fieldInfo.type == 'string') {
            // Only needs apply highlighting for string type fields, this check supposes to fast the UI
            fieldsToProcess.push(uiField);
            _.each(fieldConfig.combinedFields, function (members, combinationField) {
                if ((filter.state.smartTextFilter && filter.state.smartTextFilter[combinationField] !== undefined)
                    || (filter.state.smartHighlightFilter && filter.state.smartHighlightFilter[combinationField] !== undefined)) {
                    if (members[uiField] !== undefined) {
                        fieldsToProcess.push(combinationField);
                    }
                }
            });
        }

        // apply highlighting for all applicable fields
        for (z = 0; z < fieldsToProcess.length; z++) {
            field = fieldsToProcess[z];
            fieldInfo = fieldConfig.getFieldInfo(field);
            filterVals = filter.state.smartTextFilter ? filter.state.smartTextFilter[field] : undefined;
            highlightVals = filter.state.smartHighlightFilter ? filter.state.smartHighlightFilter[field] : undefined;
            if (filterVals !== undefined || highlightVals !== undefined) {
                filterVals = filterVals || [];
                highlightVals = highlightVals || [];
                combinedVals = filterVals.concat(highlightVals);
                // Sort by length and dictionary order desc
                combinedVals.sort(function(a, b) {return (b.text.length - a.text.length) || b.text.toString().localeCompare(a.text);});
                for (i = 0; i < combinedVals.length; i++) {
                    entry = combinedVals[i];
                    if (entry.value == true) {
                        // the checkbox is checked in the UI
                        color = highlightColors[entry.color];
                        //for (j = 0; j < entry.match.length; j++) {
                        if (val instanceof Array) {
                            let aval = Array();
                            for (var line in val) {
                                let lval = val[line];
                                let temp = new String(entry.text);
                                temp = temp.replace(/\*/g, "(\\w*)");
                                temp = temp.replace(/\?/g, "(\\w{1})");
                                temp = temp.replace(/\$/g, "");
                                // Ignore those words in tags <>
                                lval = lval.replace(new RegExp('(' + temp + ')(?![^<]*>)', 'gi'), '<span class="black-font highlight-' + color + '">$1</span>');
                                aval[line] = lval;
                            }
                            val = aval;
                        } else {
                            let temp = new String(entry.text);
                            temp = temp.replace(/\*/g, "(\\w*)");
                            temp = temp.replace(/\?/g, "(\\w{1})");
                            temp = temp.replace(/\$/g, "");
                            // Ignore those words in tags <>
                            val = val.replace(new RegExp('(' + temp + ')(?![^<]*>)', 'gi'), '<span class="black-font highlight-' + color + '">$1</span>');
                        }
                        //}
                    }
                }
            }
        }

        prefix = (addPrefix ? fieldInfo.prefix : '');
        if (val instanceof Array) {
            val = val.join("<br>");
        }
        val = String(val).replace(new RegExp('(SEQ[^A-Z]+ID[^A-Z]+NO)', 'gi'), '<span class="seq-id-no">$1</span>');
        return transformDisplayData(fieldConfig, val, fieldInfo.display, prefix);
    }

    const onMouseOver = (event, col) => {
        let element = document.getElementById(col);
        element.style.whiteSpace = 'normal'; // Enable text wrapping when hovering over the cell
      };
      
      const onMouseOut = (event, col) => {
        let element = document.getElementById(col);
        element.style.whiteSpace = 'nowrap'; // Disable text wrapping when mouse moves out
      };

    const onMouseMove = (event, col) => {
        let element = document.getElementById(col);
        let inDragTarget = (event.clientX >= (element.getClientRects()[0].right - 20));
        element.style.cursor = inDragTarget ? 'ew-resize' : 'default';
        element.style.borderRight = inDragTarget ? 'solid 3px #888' : 'none';
        //console.log("onMouseMove.");
    }

    const onMouseDown = (event, col) => {
        let element = document.getElementById(col);
        let inDragTarget = (event.clientX >= (element.getClientRects()[0].right - 20));
        if (inDragTarget && !dragging) {
            setDragging(true);
            //setLastPos(event.clientX);
            const firstPos = event.pageX;

            // this is what gets executed while the column size is being dragged
            const draggingNow = function (ev) {
                let oldWidth = parseInt(fieldConfig.fieldConfig[col].colwidth);
                if (oldWidth < 0 || oldWidth > 1000) {
                    oldWidth = 130;
                }
                //console.log("draggingNow: " + (element.getClientRects()[0].right - 20) + ", now:" + ev.target.getBoundingClientRect().right);
                let newWid = ev.pageX - firstPos + oldWidth;
                if (newWid !== undefined && newWid >= 30 && newWid < 1000) {
                    //setLastPos(ev.clientX);
                    element.style.width = newWid + 'px';
                    //console.log("new Width: " + newWid);
                }
            };

            // this is what gets executed when mouse cursor goes back up after dragging
            const stopDragging = function (ev) {
                let oldWidth = parseInt(fieldConfig.fieldConfig[col].colwidth);
                if (oldWidth < 0 || oldWidth > 1000) {
                    oldWidth = 130;
                }
                let width = ev.pageX - firstPos + oldWidth;
                //setLastPos(ev.clientX);
                console.log("Move Width: " + width + ",old:" + oldWidth);
                if (width >= 30 && width < 1000) {
                    fieldConfig.fieldConfig[col].colwidth = width;
                    resizeColCallback(col, width, width - oldWidth);
                }
                setDragging(false);
                document.body.removeEventListener("mousemove", draggingNow);
                document.body.removeEventListener("mouseup", stopDragging);
            };

            // dragging has started, attach events while dragging, and when dragging stops to body
            document.body.addEventListener("mousemove", draggingNow);
            document.body.addEventListener("mouseup", stopDragging);
        }
    }

    const onMouseLeave = (col, fieldConfig, filter) => {
        let element = document.getElementById(col);
        element.style.borderRight = 'none';
        element.style.cursor = 'default';
        //console.log("onMouseLeave");
        if (dragging && colConfig[col].colwidth !== fieldConfig.fieldConfig[col].colwidth) {
            // When the col width is changed, saves it
            //fieldConfig.saveConfig(fieldConfig, 'default', filter);
            setDragging(false);
        }
    }

    const resizeColCallback = (col, width, adjustedWidth) => {
        setNewWidth(tableWidth + adjustedWidth);
        fieldConfig.fieldConfig[col].colwidth = width;

        // When the col width is changed & not from the report links, saves it
        if (colConfig[col].colwidth !== width && editable) {
            colConfig[col].colwidth = width;
            fieldConfig.saveConfig(fieldConfig, 'default', filter);
        }
    }


    // formats dates into readable text and adds prefixes, etc.
    // how to format which piece of information is defined in fieldConfig.fieldConfig object
    const transformDisplayData = (fieldConfig, text, format, prefix) => {
        switch (format) {
            case 'YYYYMMDD': // dates
                if (text > 0) {
                    if (text.substr(4) == "0000") {
                        text = text.substr(0, 4);
                    } else if (text.length > 6) { // MM-DD-YYYY
                        text = monthName[parseInt(text.substr(4, 2)) - 1] + '-' + text.substr(6, 2) + '-' + text.substr(0, 4);
                    } else if (text.length > 4) { // MM-YYYY
                        text = monthName[parseInt(text.substr(4, 2)) - 1] + '-' + text.substr(0, 4);
                    } else { // YYYY
                        text = text.substr(0, 4);
                    }
                } else {
                    text = '<span class="not-available">Not Available</span>';
                }
                break;
            case 'percentage': // percentage, add % at the end
                text = text + '%';
                break;
            case 'pre': // for alignments put them between <pre> tags
                text = '<pre>' + text + '<br><br></pre>';
                break;
            case 'SUBJECT_GNAME':
                // convert dbId to description that can be displayed
                text = fieldConfig.getDatabaseName(text);
                break;
        }
        if (prefix != '') {
            text = prefix + ' ' + text;
        }
        if (text == '') {
            text = '&nbsp;';
        }
        return text;
    }

  // Render the UI for your table
  return (
    <Styles>
      <table>
        <tbody>
          <tr>
              <td id="maincontent" className="main-content">
                  <div id="maintable" className={'main-table' + ' ' + (displayMode === 'flat' ? 'main-row-sep' : '')} style={{ width: newWidth + 'px' }}>
                      {displayMode === 'flat' &&
                          <div className="clearfix row-padding" >
                              <div className="pull-left" style={{width: '135px'}}>&nbsp;</div>
                              {
                                  columns.map(
                                      col => (
                                          <div key={col} className="pull-left gqcol table-header" id={col}
                                               onMouseMove={(e) => onMouseMove(e, col)}
                                               onMouseDown={(e) => onMouseDown(e, col)}
                                               onMouseOut={(e) => onMouseOut(e,col)}
                                               onMouseLeave={() => onMouseLeave(col, fieldConfig, filter)}
                                               onMouseOver={(e) => onMouseOver(e, col)}
                                               style = {{width: fieldConfig.fieldConfig[col].colwidth + "px"}}>
                                              <div style={{color: '#777777'}} dangerouslySetInnerHTML={{__html: fieldConfig.getFieldName(col, algorithm, isAbWorkflow)}}></div> {/*hover-delay="gqcolhover"*/}
                                          </div>
                                      )
                                  )
                              }
                          </div>
                      }
                      {(data.data == undefined || Object.keys(data.data).length == 0) &&
                          <div className="no-results pull-left">
                          There are no results to display.
                          </div>
                      }
                      {data.data && Object.keys(data.data).length > 0 &&
                          Object.keys(data.data).map(
                              (item, idx) => (
                                  <div key={'div' + idx} className={displayMode === 'flat' ? '' : 'main-row-sep'}>
                                      {idx === 0 &&
                                          <span id="level1"></span>
                                      }
                                    <ResultTableRecord data={data} item={data.data[item]} idx={idx} fieldConfig={fieldConfig} annotState={annotState}
                                                       filter={filter} highlight={highlight} toggleFullRecordExpand={toggleFullRecordExpand}
                                                       columns={displayMode === 'flat' ? columns : fieldConfig.userTableTemplate[data.data[item].type]}
                                                       fromItemsPage={fromItemsPage} parentAnnotState={parentAnnotState} resizeColCallback={resizeColCallback}
                                                       groupValue1={groupValue1} groupValue2={groupValue2} saveAnnotation={saveAnnotation}/>
                                    {annotState.fullRecordExpand[data.data[item].dbkey] &&
                                      <ResultTableRecDetail data={data} item={data.data[item]} idx={idx} fieldConfig={fieldConfig} annotState={annotState} filter={filter}
                                                            highlight={highlight} toggleFullRecordExpand={toggleFullRecordExpand} expandAllDetailPage={expandAllDetailPages} refreshAnnotState={refreshAnnotState}
                                                            expandAllAlignments={expandAllAlignment} expandAllClaims={expandAllClaim} expandClaim={expandClaim} expandAlignment={expandAlignment} showLessClaim={showLessClaim}
                                                            fromItemsPage={fromItemsPage} parentAnnotState={parentAnnotState} groupValue1={groupValue1} groupValue2={groupValue2} saveAnnotation={saveAnnotation} isAbWorkflow={isAbWorkflow}
                                                            setFtWindowsToOpen ={(value)=>{setFtWindowsToOpen(value)}}
                                                            />
                                    }

                                      {data.data[item].nodes && Object.keys(data.data[item].nodes).length > 0 &&
                                          Object.keys(data.data[item].nodes).map(
                                              (level2Item, level2Idx) => (
                                                  <div key={'level2div' + level2Idx} className="indent tree">
                                                      {level2Idx === 0 &&
                                                          <span id="level2"></span>
                                                      }
                                                      {data.data[item].nodes[level2Item].maxed_out &&
                                                          <div className="row-padding"><a href="#" onClick={(e) => {displayItemsView(data.data[item].nodes[level2Item].maxed_key, '', data.data[item]);
                                                              e.preventDefault();}}>{data.data[item].nodes[level2Item].maxed_out}</a></div>
                                                      }
                                                      {!data.data[item].nodes[level2Item].maxed_out &&
                                                            <ResultTableRecord data={data} item={data.data[item].nodes[level2Item]} idx={level2Idx} fieldConfig={fieldConfig} annotState={annotState} filter={filter}
                                                                               highlight={highlight} toggleFullRecordExpand={toggleFullRecordExpand}
                                                                               columns={fieldConfig.userTableTemplate[data.data[item].nodes[level2Item].type]}
                                                                               fromItemsPage={fromItemsPage} parentAnnotState={parentAnnotState} resizeColCallback={resizeColCallback}
                                                                               groupValue1={groupValue1} groupValue2={groupValue2} saveAnnotation={saveAnnotation}/>
                                                      }
                                                      {annotState.fullRecordExpand[data.data[item].nodes[level2Item].dbkey] &&
                                                          <ResultTableRecDetail data={data} item={data.data[item].nodes[level2Item]} idx={level2Idx} fieldConfig={fieldConfig} annotState={annotState} filter={filter}
                                                                                highlight={highlight} toggleFullRecordExpand={toggleFullRecordExpand} expandAllDetailPage={expandAllDetailPages} refreshAnnotState={refreshAnnotState}
                                                                                expandAllAlignments={expandAllAlignment} expandAllClaims={expandAllClaim} expandClaim={expandClaim} expandAlignment={expandAlignment} showLessClaim={showLessClaim}
                                                                                fromItemsPage={fromItemsPage} parentAnnotState={parentAnnotState} groupValue1={groupValue1} groupValue2={groupValue2} saveAnnotation={saveAnnotation} isAbWorkflow={isAbWorkflow}
                                                                                setFtWindowsToOpen ={(value)=>{setFtWindowsToOpen(value)}}
                                                                                />
                                                      }

                                                      {data.data[item].nodes[level2Item].nodes && Object.keys(data.data[item].nodes[level2Item].nodes).length > 0 &&
                                                          Object.keys(data.data[item].nodes[level2Item].nodes).map(
                                                              (level3Item, level3Idx) => (
                                                                  <div key={'level3div' + level3Idx} className="indent tree">
                                                                      {level3Idx === 0 &&
                                                                          <span id="level3"></span>
                                                                      }
                                                                      {data.data[item].nodes[level2Item].nodes[level3Item].maxed_out &&
                                                                          <div className="row-padding"><a href="#" onClick={(e) => {
                                                                              displayItemsView(data.data[item].dbkey, data.data[item].nodes[level2Item].nodes[level3Item].maxed_key);
                                                                              e.preventDefault();}}>{data.data[item].nodes[level2Item].nodes[level3Item].maxed_out}</a></div>
                                                                      }
                                                                      {!data.data[item].nodes[level2Item].nodes[level3Item].maxed_out &&
                                                                        <ResultTableRecord data={data} item={data.data[item].nodes[level2Item].nodes[level3Item]} idx={level3Idx}
                                                                                           fieldConfig={fieldConfig} annotState={annotState} filter={filter}
                                                                                           highlight={highlight} toggleFullRecordExpand={toggleFullRecordExpand}
                                                                                           columns={fieldConfig.userTableTemplate[data.data[item].nodes[level2Item].nodes[level3Item].type]}
                                                                                           fromItemsPage={fromItemsPage} parentAnnotState={parentAnnotState} resizeColCallback={resizeColCallback}
                                                                                           groupValue1={groupValue1} groupValue2={groupValue2} saveAnnotation={saveAnnotation}/>
                                                                      }
                                                                      {annotState.fullRecordExpand[data.data[item].nodes[level2Item].nodes[level3Item].dbkey] &&
                                                                          <ResultTableRecDetail data={data} item={data.data[item].nodes[level2Item].nodes[level3Item]} idx={level3Idx} fieldConfig={fieldConfig} annotState={annotState} filter={filter}
                                                                                                highlight={highlight} toggleFullRecordExpand={toggleFullRecordExpand} expandAllDetailPage={expandAllDetailPages} refreshAnnotState={refreshAnnotState}
                                                                                                expandAllAlignments={expandAllAlignment} expandAllClaims={expandAllClaim} expandClaim={expandClaim} expandAlignment={expandAlignment} showLessClaim={showLessClaim}
                                                                                                fromItemsPage={fromItemsPage} parentAnnotState={parentAnnotState} groupValue1={groupValue1} groupValue2={groupValue2} saveAnnotation={saveAnnotation} isAbWorkflow={isAbWorkflow} 
                                                                                                setFtWindowsToOpen ={(value)=>{setFtWindowsToOpen(value)}}
                                                                                                />
                                                                      }

                                                                  </div>
                                                              )
                                                          )
                                                      }
                                                  </div>
                                              )
                                          )
                                      }
                                  </div>
                              )
                          )
                      }
                  </div>
              </td>
          </tr>
        </tbody>
      </table>
    </Styles>
  )
}
