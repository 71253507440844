//import Chart from "../assets/lib/Charts.min.js";
import _ from "lodash";
// TODO, AnguaJS version to be migrated to ReactJS version, https://github.com/reactchartjs/react-chartjs-2

let facet;
let data = {};
let chartObjects = {};
let min = 2100;
let max = 0;
const colors = ['#337ab7', '#98C747', '#db862c', '#c74848', '#ebd023', '#a843a8', '#64B5F6', '#26A69A', '#7E57C2', '#607D8B'];

// configure doughnut charts
const doughnutCharts = [
    {
        field: 'SUBJECT_PA',
        full: {ctx: 'AssigneeFacetChart', label: 'Assignees'},
        thumb: {ctx: 'AssigneeFacetChart_thumb', label: 'Assignees'}
    },
    {
        field: 'SUBJECT_PG',
        full: {ctx: 'statusFacetChart', label: 'Legal Status'},
        thumb: {ctx: 'statusFacetChart_thumb', label: 'Legal Status'}
    },
    {
        field: 'SUBJECT_PS',
        full: {ctx: 'PslFacetChart', label: 'Patent Sequence Location'},
        thumb: {ctx: 'PslFacetChart_thumb', label: 'Seq.Location'}
    },
    {
        field: 'SUBJECT_PN',
        full: {ctx: 'authFacetChart', label: 'Authorities'},
        thumb: {ctx: 'authFacetChart_thumb', label: 'Authorities'}
    }
];

function drawNow(facetData) {
    data = {};
    facet = facetData;
    min = 2100;
    max = 0;

    // prepare data for date trend chart
    formatXyScatterPlotData('SUBJECT_D1');
    formatXyScatterPlotData('SUBJECT_D3');
    formatXyScatterPlotData('SUBJECT_D4');

    drawDateTrendChart('dateTrendChart_thumb', 130, 100, 'Dates', 'thumb');
    drawDateTrendChart('dateTrendChart', 700, 460, 'Number of Documents over Time', 'full');

    // draw doughnut charts using config above
    for (let i = 0; i < doughnutCharts.length; i++) {
        formatDoughnutChartData(doughnutCharts[i].field);
        drawDoughnutChart(
            doughnutCharts[i].thumb.ctx,
            200, 150,
            data[doughnutCharts[i].field],
            doughnutCharts[i].thumb.label,
            'thumb'
        );
        drawDoughnutChart(
            doughnutCharts[i].full.ctx,
            700, 360,
            data[doughnutCharts[i].field],
            doughnutCharts[i].full.label,
            'full'
        );
    }
    //console.log(self);
}

function formatDoughnutChartData(field) {
    // console.log('formatDoughnutChartData('+field+')');
    let idx = 1, count = 0;
    data[field] = {values: [], labels: []};
    if (facet && facet[field]) {
        _.each(facet[field], function (val, key) {
            if (key) {
                if (idx >= 10) {
                    // more than 9 entries, count the remainder as "other"
                    count += parseInt(val.count);
                } else if (val.count > 0) {
                    data[field].values.push(val.count);
                    data[field].labels.push(val.desc.substring(0, 26));
                }
                idx++;
            }
        });
    }
    if (idx >= 10 && count > 0) {
        // add the "other" category
        data[field].values.push(count);
        data[field].labels.push('other');
    }
}

function drawDoughnutChart(ctx, width, height, data, label, size) {
    // console.log('drawDoughnutChart('+label+')');
    let chartsetup = {};
    // And for a doughnut chart
    /*if (chartObjects[ctx]) {
        //chartObjects[ctx].destroy();
    }

    let ctx_element = document.getElementById(ctx);
    if (null === ctx_element) {
        console.log('Element does not yet exist for chart.');
        return;
    }*/

    //resetCtxSize(ctx, width, height);

    //TODO
    //chartObjects[ctx] = new Chart(ctx_element, {
        chartsetup = {
        type: 'doughnut',
        options: {
            animation: {duration: 0},
            cutout: '46%',
            responsive: (size === 'thumb'),
            maintainAspectRatio: false,
            
            
            plugins:{
            legend: {
                display: (size === 'full'),
                position: 'right',
                labels: {
                    boxWidth: 25,
                    boxHeight: 15,
                    font:{ size: 14}
                }
            },
            title: {
                display: true,
                font: { weight: ((size === 'full') ? 'bold' : 'normal'),
                        size: (size === 'full') ? 18 : 14
                        },
                text: label,
                padding: ((size === 'full') ? 18 : 8)
            },
            tooltip: {enabled: (size === 'full')},
             }
        },
        data: {
            labels: data.labels,
            datasets: [{
                data: data.values,
                backgroundColor: colors,
                hoverBackgroundColor: colors,
                hoverBorderWidth: 2,
                borderWidth: 2,
                hoverBorderColor: '#FFF',
                borderColor: '#FFF'
            }]
        }
    };
    chartObjects[ctx] = [ctx,chartsetup];
    //});*/
}

function resetCtxSize(ctx_element, width, height) {
    let element = document.getElementById(ctx_element);
    element.setAttribute('width', width);
    element.setAttribute('height', height);
    //angular.element(ctx_element).attr('width', width).attr('height', height);
}

function drawDateTrendChart(ctx, width, height, label, size) {
    //console.log('drawDateTrendChart('+ctx +','+ width+','+ height+','+ label+','+ size+')');
    /*if (chartObjects[ctx]) {
        //chartObjects[ctx].destroy();
    }
    let ctx_element = document.getElementById(ctx);

    if (null === ctx_element) {
        console.log('Element does not yet exist for chart.');
        return;
    }*/

    // reset the width and height of the graph
    //resetCtxSize(ctx, width, height);
    // set up the chart
    let chartSetup = {
        type: 'line',
        data: {
            datasets: []
        },
        options: {
            plugins:{
            tooltip: {enabled: (size === 'full')},
            legend: {
                display: (size === 'full'),
                position: 'bottom',
                labels: {'boxWidth': 20}
            },
            title: {
                display: true,
                //fontSize: (size==='full' ? 18 : 14),
                //fontStyle: ((size==='full')?'bold':'normal'),
                font:{
                        weight: ((size === 'full') ? 'bold' : 'normal'),
                        size: (size === 'full') ? 18 : 14
                },
                text: label
            }
        },
            responsive: true, 
            animation: { duration: 0 },
            maintainAspectRatio: false,
            elements:{
                line:{tension: 0.4}
            },
            scales: {
                x: {
                    type: 'linear',
                    position: 'bottom',
                    grid: { display: false },
                    ticks: { display:(size==='full'), min: min, max: max, stepSize: 2 },
                    fontSize: 8
                },
                y: {
                    type: 'linear',
                    position: 'left',
                    ticks: { display:(size==='full') },
                    grid: { display: true },
                }
            }
        }
    };

    // insert the data
    chartSetup.data.datasets.push(
        getXyScatterPlotDataSet('SUBJECT_D3', 'Filing Date', colors[0], size)
    );
    chartSetup.data.datasets.push(
        getXyScatterPlotDataSet('SUBJECT_D4', 'Priority Date', colors[1], size)
    );
    chartSetup.data.datasets.push(
        getXyScatterPlotDataSet('SUBJECT_D1', 'Publication Date', colors[2], size)
    );
    chartObjects[ctx] = [ctx,chartSetup];
    // draw the chart, TODO
    //chartObjects[ctx] = new Chart(ctx_element, chartSetup);
}

// gets data from self.facet[field] variable (coming back from backend)
// and formats it for xyScatterPlot, and determines min and max value
// skips everything that is zero (like empty dates)
function formatXyScatterPlotData(field) {
    // console.log('formatXyScatterPlotData('+field+')');
    data[field] = [];
    if (facet && facet[field]) {
        _.each(facet[field], function (val, key) {
            key = parseInt(key);
            val = parseInt(val);
            if (key > 0) {
                data[field].push({x: key, y: val});
                if (key < min) {
                    min = key;
                }
                if (key > max) {
                    max = key;
                }
            }
        });
    }
}

function getXyScatterPlotDataSet(field, label, color, size) {
    // console.log('getXyScatterPlotDataSet('+field+')');
    return {
        label: label,
        backgroundColor: color,
        borderColor: color,
        pointBorderColor: color,
        pointBackgroundColor: color,
        pointStyle: 'circle',
        fill: false,
        borderWidth: (size==='full' ? 2 : 1),
        pointBorderWidth: 1,
        pointRadius: (size==='full' ? 3 : 1),
        pointHoverRadius: (size==='full' ? 3 : 1),
        data: data[field]
    };
}

const ChartService = {
    drawNow,
    chartObjects
};

export default ChartService;
