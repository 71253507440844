import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal'
import { FaPlus, FaMinus } from "react-icons/fa";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { BsCaretDownFill } from "react-icons/bs";
import { useHistory } from 'react-router-dom';
import _ from "lodash";
import CloseIcon from '@material-ui/icons/Close';
import Link from '@material-ui/core/Link';
import { BsFillCaretRightFill } from "react-icons/bs";


const useStyles = makeStyles((theme) => ({
    modalHeader: {
        borderBottom: 'none !important',
        paddingTop: '11px',
        paddingRight: '4px',
        marginTop: '-7px',
        width: '100%',
        fontFamily: 'verdana, helvetica',
        fontSize: '12px',
    },
    buttonStyle: {
        float: 'right',
        textTransform: 'none',
        margin: '4px',
        backgroundColor: '##DB862D !important',
        border: '1px solid ##DB862D !important',
        marginTop: '4px',

    },
    modalClassContent: {
        height: '30px',
        width: '200px',
        margin: '10px',
        fontSize: '14px',
        color: 'rgb(119, 119, 119)',
        fontFamily: 'verdana, helvetica',
        fontWeight: '400'
    },
    modalBody: {
        textAlign: 'center',
        marginBottom: '8px'
    },
    filterIcon:{
        position: 'absolute',
        marginLeft: '662px',
        backgroundColor: '#ffffff',
        border: '1px solid #b5b5b5'
    },
    onRowHover: {
        '&:hover': {
            border: '1px solid #b5b5b5',
            backgroundColor: '#eeece1',
            color: 'black',
        },
    }
}));

const toolTipStyles = {
    tooltip: {
        width: "auto",
        fontSize: '12px',
        textAlign: 'justify',
        padding: "8px 14px 8px 14px",
        boxShadow: "0px 1px 7px #888",
        backgroundColor: '#777',
        color: '#FFF'
    }
};

const CustomTooltip = withStyles(toolTipStyles)(Tooltip);

const PnSearchFilterModal = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const [openTextArea, setOpenTextArea] = useState(false);
    const [filterIcon, setFilterIcon] = useState(false);
    const [applyFilterIcon, setApplyFilterIcon] = useState(false);
    const [startingValue, setStartingValue] = useState([{ field: 'All fields', field_type: 'string', operator: 'CT', value: '' }]);
    const [applyFilterMarginTop, setApplyFilterMarginTop] = useState('-138px');
    const [filterMarginTop, setFilterMarginTop] = useState('-139px');

    function onCancel(props) {
        props.closeModal();
    }
    function handleInput() {
        setOpenTextArea(!openTextArea);
    }

    function openFilterTable(){
        setFilterIcon(!filterIcon);
    }
    function applyFilterButton(){
        setApplyFilterIcon(!applyFilterIcon);
    }

    const widgetDelAtom = (idx) => {
        startingValue.splice(idx, 1);
        setStartingValue([...startingValue]);
    };

    const widgetAddAtom = (idx) => {
        const newElement = { field: 'All fields', field_type: 'string', operator: 'CONTAINS', value: '' };
        startingValue.splice(idx + 1, 0, newElement);
        setStartingValue([...startingValue]);
    };

    return (
        <Modal
            id='modalCss'
            aria-labelledby="contained-modal-title-vcente"
            centered
            size='lg'
            show={props.showModal}
            contentClassName={classes.modalHeader}
        >
            <div>
                <Link href="#" onClick={(e) => e.preventDefault()} className="pull-right">
                    <CloseIcon style={{ fill: '#b5b5b5' }} onClick={() => onCancel(props)} />
                </Link>
            </div>
            <Modal.Body className={classes.modalBody}>
                <div >
                    <table>
                        <tbody>
                            <tr>
                                <td align='left' >
                                    <label style={{ padding: '12px' }}><strong>Show results that</strong></label>
                                    &nbsp;
                                    <select style={{height: '25px', fontSize: '12px', color: 'rgb(119, 119, 119)',fontFamily: 'verdana, helvetica'}} type="text">
                                        <option>meet all conditions</option>
                                        <option>meet any of the conditions</option>
                                        <option>Custom filter</option>
                                    </select>
                                </td>
                                <td >
                                    <span id='filterBtn' className={"pull-right normal-button grey"} style={{marginLeft: '-65px'}} onClick={openFilterTable} >Filter<BsCaretDownFill style={{ marginLeft: '6px' }} /></span>
                                </td>
                            </tr>
                            <tr>
                                {startingValue.map((atom, idx) => (
                                    <tr key={idx}>
                                        <div>
                                            <td>
                                                <div className="control-spacer">
                                                    <select style={{ height: '30px', width: '200px', fontFamily: 'verdana, helvetica !important', fontSize: '15px', color: '#777777', fontWeight: '400' }}
                                                        value={atom.field}>
                                                        <optgroup>
                                                            <option label='All fields'>All fields</option>
                                                        </optgroup>
                                                        <optgroup label='Identifiers'>
                                                            <option value="SEQUENCE_AC" label="Accession">Accession</option>
                                                            <option value="SEQUENCE_AN" label="Application number">Application number</option>
                                                            <option value="SEQUENCE_ID" label="Identifier">Identifier</option>
                                                            <option value="SEQUENCE_P4" label="PCT pub. no.">PCT pub. no.</option>
                                                            <option value="SEQUENCE_PV" label="Patent Id">Patent Id</option>
                                                            <option value="SEQUENCE_SI" label="Patent SEQ ID NO">Patent SEQ ID NO</option>
                                                            <option value="SEQUENCE_PN" label="Patent Number">Patent Number</option>
                                                            <option value="SEQUENCE_SV" label="Sequence version">Sequence version</option>
                                                            <option value="SEQUENCE_PJ" label="Unique Family Sequence ID">Unique Family Sequence ID</option>                                                    </optgroup>
                                                        <optgroup label='Text'>
                                                            <option value="SEQUENCE_P1" label="Abstract">Abstract</option>
                                                            <option value="SEQUENCE_CT" label="Claims">Claims</option>
                                                            <option value="SEQUENCE_CC" label="Comments">Comments</option>
                                                            <option value="SEQUENCE_DR" label="Database cross-references">Database cross-references</option>
                                                            <option value="SEQUENCE_GNAME" label="Database name">Database name</option>
                                                            <option value="SEQUENCE_DE" label="Description">Description</option>
                                                            <option value="SEQUENCE_L2" label="Detailed legal status">Detailed legal status</option>
                                                            <option value="SEQUENCE_PE" label="Earliest priority number">Earliest priority number</option>
                                                            <option value="SEQUENCE_EM" label="Equivalents member type">Equivalents member type</option>
                                                            <option value="SEQUENCE_PG" label="Extended Legal Status">Extended Legal Status</option>
                                                            <option value="SEQUENCE_KW" label="Keywords">Keywords</option>
                                                            <option value="SEQUENCE_PH" label="Legal Status National Phase">Legal Status National Phase</option>
                                                            <option value="SEQUENCE_LS" label="Legal status">Legal status</option>
                                                            <option value="SEQUENCE_PZ" label="Normalized Parent">Normalized Parent</option>
                                                            <option value="SEQUENCE_PU" label="Normalized Patent Assignee">Normalized Patent Assignee</option>
                                                            <option value="SEQUENCE_OS" label="Organism">Organism</option>
                                                            <option value="SEQUENCE_OO" label="Organism (non-normalized name)">Organism (non-normalized name)</option>
                                                            <option value="SEQUENCE_PA" label="Patent assignee">Patent assignee</option>
                                                            <option value="SEQUENCE_PM" label="Patent family">Patent family</option>
                                                            <option value="SEQUENCE_PB" label="Patent family ID">Patent family ID</option>
                                                            <option value="SEQUENCE_PI" label="Patent inventors">Patent inventors</option>
                                                            <option value="SEQUENCE_PS" label="Patent sequence location">Patent sequence location</option>
                                                            <option value="SEQUENCE_PR" label="Priority info">Priority info</option>
                                                            <option value="SEQUENCE_RA" label="Reference author">Reference author</option>
                                                            <option value="SEQUENCE_RL" label="Reference location">Reference location</option>
                                                            <option value="SEQUENCE_PQ" label="Seq. listing equivalents">Seq. listing equivalents</option>
                                                            <option value="SEQUENCE_PK" label="Sequence Note">Sequence Note</option>
                                                            <option value="SEQUENCE_OX" label="Taxonomy ID">Taxonomy ID</option>
                                                            <option value="SEQUENCE_PT" label="Title">Title</option>
                                                            <option value="SEQUENCE_L3" label="US PAIR legal status">US PAIR legal status</option>
                                                        </optgroup>
                                                        <optgroup label="Dates">
                                                            <option value="SEQUENCE_D2" label="Date of entry">Date of entry</option>
                                                            <option value="SEQUENCE_D4" label="Earliest priority date">Earliest priority date</option>
                                                            <option value="SEQUENCE_D5" label="Earliest pub. date">Earliest pub. date</option>
                                                            <option value="SEQUENCE_P5" label="PCT pub. date">PCT pub. date</option>
                                                            <option value="SEQUENCE_P8" label="PCT related dates">PCT related dates</option>
                                                            <option value="SEQUENCE_D3" label="Patent filing date">Patent filing date</option>
                                                            <option value="SEQUENCE_D1" label="Publication date">Publication date</option>
                                                        </optgroup>
                                                        <optgroup label="Sequence Properties">
                                                            <option value="SEQUENCE_L" label="Length">Length</option>
                                                            <option value="SEQUENCE_MT" label="Molecule type">Molecule type</option>
                                                        </optgroup>
                                                        <optgroup label="Other">
                                                            <option value="SEQUENCE_PO" label="Claimed SEQ ID">Claimed SEQ ID</option>
                                                            <option value="SEQUENCE_FM" label="Family size">Family size</option>
                                                            <option value="SEQUENCE_FH" label="Feature header">Feature header</option>
                                                            <option value="SEQUENCE_FT" label="Feature table">Feature table</option>
                                                            <option value="SEQUENCE_HL" label="Hyperlinks coding">Hyperlinks coding</option>
                                                            <option value="SEQUENCE_HO" label="Nb. claimed seqs">Nb. claimed seqs</option>
                                                            <option value="SEQUENCE_P9" label="Number of SEQ (nuc/prot)">Number of SEQ (nuc/prot)</option>
                                                            <option value="SEQUENCE_P7" label="Number of claims">Number of claims</option>
                                                            <option value="SEQUENCE_PC" label="Patent classification">Patent classification</option>
                                                            <option value="SEQUENCE_KC" label="Patent kind code">Patent kind code</option>
                                                            <option value="SEQUENCE_SQ" label="Sequence information">Sequence information</option>
                                                        </optgroup>
                                                    </select>
                                                </div>
                                            </td>

                                            <td><select className = {classes.modalClassContent}>
                                                <option>contains</option>
                                                <option>does not contain</option>
                                            </select></td>
                                            <td>
                                                <div>
                                                    <div>
                                                        {!openTextArea && <div><input style={{width: '200px', height: '30px'}}></input></div>}
                                                        {openTextArea && <div><textarea></textarea></div>}
                                                    </div>
                                                </div>
                                            </td>
                                            <td><a style={{ fontFamily: 'monospace', cursor: 'pointer', verticalAlign: 'top' }} onClick={handleInput}>[+]</a></td>
                                            <td className={"filter-widget-adddel "}>
                                                {startingValue.length > 1 &&
                                                    <CustomTooltip arrow title="Remove this Condition" placement="bottom">
                                                        <div className="control-spacer plus-min-button custom-filter-label"
                                                            onClick={() => widgetDelAtom(idx)}>
                                                            <FaMinus />
                                                        </div>
                                                    </CustomTooltip>
                                                }
                                            </td>
                                            <td className={"filter-widget-adddel "}>
                                                <CustomTooltip arrow title="Add Condition below" placement="bottom">
                                                    <div className="control-spacer plus-min-button custom-filter-label"
                                                        onClick={() => widgetAddAtom(idx)}>
                                                        <FaPlus />
                                                    </div>
                                                </CustomTooltip>
                                            </td>
                                        </div>
                                    </tr>
                                ))}
                            </tr>
                            <tr>
                                <td colSpan="6" className="clearfix">
                                    <div className="clearfix filter-widget-bottom">
                                        <button className={'pull-right normal-button gqblue'} >Apply</button>
                                        <button className={"pull-right normal-button grey"}>Reset</button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                {filterIcon &&
                    <table className={classes.filterIcon} style={{marginTop: filterMarginTop}}>
                        <tbody>
                            <tr className={classes.onRowHover}>
                                <td onClick={applyFilterButton} style={{ padding: '1px 8px 1px 7px'}}>Apply Filter <BsFillCaretRightFill/></td>
                            </tr>
                            <tr className={classes.onRowHover}>
                                <td>Save Filter</td>
                            </tr>
                            <tr className={classes.onRowHover}>
                                <td>Delete Filter <BsFillCaretRightFill/></td>
                            </tr>
                        </tbody>
                    </table>
                }
                {applyFilterIcon &&
                    <table id="applyFilterIcon" style={{marginTop: applyFilterMarginTop, position: 'absolute', marginLeft: '764px',whiteSpace: 'nowrap',backgroundColor: '#ffffff',border: '1px solid #b5b5b5'}}>
                        <tbody>
                            <tr className={classes.onRowHover} >
                                <td>Predefined criteria</td>
                            </tr>
                            <tr className={classes.onRowHover}>
                                <td>Claimed</td>
                            </tr>
                            <tr className={classes.onRowHover}>
                                <td>Granted</td>
                            </tr>
                            <tr className={classes.onRowHover}>
                                <td>Equivalent Representative</td>
                            </tr>
                            
                        </tbody>
                    </table>
                }
            </Modal.Body>
        </Modal>
    );
}

export default PnSearchFilterModal;;
