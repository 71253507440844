import React, { useState, useEffect} from "react";
import HtmlParser from "react-html-parser";
import { Link } from 'react-router-dom';
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
//import "../TableStyles.css"

function OverviewTable(props){
    
    const {resid, reportName} = useParams();

    //const data = useState(props.queryAliDocStats);
    const {alignmentDescriptions, queryAliDocStatsProp, collName} = props;
    const [hasDesc, setHasDesc] = useState(false);

    const [ queryAliDocStats, setQueryAliDocStats] = useState(null);
    const getSquare = (value) => {
        if(value != null){
            return value.charAt(0).toUpperCase() == 'L'? "lSquare" : "hSquare";
        }
    }

    useEffect(() =>{
        checkForDescriptions();
        if(Object.keys(queryAliDocStatsProp).length > 0){
            setQueryAliDocStats(queryAliDocStatsProp);
        }
    },[props.queryAliDocStatsProp, props.alignmentDescriptions])

    function redirectLink(key, isLastItem){
       
        if(key!==undefined){
            let groupBy = 'flat';
            if (isLastItem) {
                groupBy = 'document';
            }
            var qIds = [key.toUpperCase().replaceAll("-","_")];
        
            var abReportFilters = {
                    querySeqDbs: qIds,
                    subjSeqFrag: '',
                    groupBy: groupBy ? groupBy : 'flat',
                    queryNums: [],
                    reportName: decodeURIComponent(reportName) == "undefined"? "Unfiltered_Entire_Report": decodeURIComponent(reportName)
  
            };

            return encodeURIComponent(JSON.stringify(abReportFilters))
        }
    }

    function fixSeq(str){

        let newStr="";

        var tokens=str.match(new RegExp('.{1,' + 10 + '}', 'g'));

        for(var i = 0; i < tokens.length; i++){

            newStr = newStr.concat(tokens[i]);

            if(tokens.length>1)
            {newStr = newStr.concat(" ");}

            if(i==5){
                newStr = newStr.concat('\n');
            }

        }

        return newStr;
    }

    function doMaxColor(val){
        let str = "textFlatLeft mp0 pCenter font14noColor"

        if(val >= 300000){
            str += " red";
        }

        return str;
    }

    function checkForDescriptions(){
        if(alignmentDescriptions != undefined){
            if(Object.keys(alignmentDescriptions).length >0){
                for(var i = 0; i< Object.keys(alignmentDescriptions).length; i++){
                    if(alignmentDescriptions[Object.keys(alignmentDescriptions)[i]] != ""){
                        setHasDesc(true);
                        break;
                    }
                }
            }
        }
        return;
    }

    function getCDRName(CDR){
        if(queryAliDocStatsProp != undefined && alignmentDescriptions != undefined){
            if(Object.keys(queryAliDocStatsProp).length != 0){
                if(Object.keys(alignmentDescriptions).length != 0){
                    if(CDR!= null && CDR != "" && CDR != undefined){
                        var name = alignmentDescriptions[CDR]
                        if(name != "" && name != undefined && name != null){
                            return name;
                        }
                    }
                }
            }
        }
        return "";
    }

	return (
        <div class="tableFrame">

            <p class="textFlat pCenter paddingTitle"><strong>OVERVIEW NUMBER OF ALIGNMENTS AND DOCUMENTS</strong></p>

            <table class="tabler MarginBottom15" width={"95%"}>
                <thead>
                <th class="tablerb font13pt" width={"1%"}></th>
                <th class="tablerb textFlatLeft wsnw font13pt" width={"1%"}></th>
                {hasDesc &&
                    <th class="tablerb textFlatCenter padding15 font13pt" width={"8%"}>Description</th>
                }
                <th class="textFlatLeft padding15  wsnw font13pt" width={"40%"}>Sequence</th>
                <th class="tablerb textFlatCenter padding15 font13pt" width={"8%"}>Alignments</th>
                <th class="tablerb textFlatCenter padding15 font13pt" width={"1%"}>Documents</th>
                </thead>
                <tbody>
                {(queryAliDocStats!= null && Object.keys(queryAliDocStats).length > 0) && Object.entries(queryAliDocStats).map((value, index) => {
                    return(
                        <tr key={index}>
                            <td class="tablerb textFlatLeft padding15" width={"auto"}>
                                    <span class="mp0 hSquare" className={getSquare(value[0])}></span>                                
                            </td>
                            <td class="tablerb textFlatCenter padding15">
                                <strong class="font14 wsnw marginAll5">{value[0].toUpperCase()}</strong> 
                            </td>
                            {hasDesc &&
                            <td class="tablerb textFlatCenter padding15">
                                <strong class="textFlatCenter color777 LcLineStyle">{getCDRName(value[0])}</strong>
                            </td>
                            }
                            <td class="tablerb textFlatLeft padding15">
                                <span class="textFlatLeft monoFont mp0">{value[1].querySeq!= null? fixSeq(value[1].querySeq) : "NA"}</span>
                            </td>
                            <td class="tablerb padding15 textFlatCenter">
                                <Link 
                                    class="textFlatCenter mp0 font14noColor"                                    
                                    target="_blank"
                                    disabled={value[1].numOfAlis==0}
                                    to={{
                                        pathname: '/discover/wf:'+resid+".resdb/antibody/"+redirectLink(value[0], false) + "/" + collName,
                                    }}><span class="textFlatCenter mp0"><span className={doMaxColor(value[1].numOfAlis)}>{value[1].numOfAlis!= null? value[1].numOfAlis : "NA"}</span><span class="red" hidden={(value[1].numOfAlis < 300000)}> MAX</span></span>
                                </Link>
                            </td>
                               
                            <td class="tablerb padding15 textFlatCenter">
                                <Link 
                                    class="textFlatCenter mp0 font14noColor"
                                    target="_blank"
                                    disabled={value[1].numOfDocs==0} 
                                    to={{
                                        pathname: '/discover/wf:'+resid+".resdb/antibody/"+redirectLink(value[0], true) + "/" + collName,
                                    }}><span class="textFlatCenter mp0 font14noColor">{value[1].numOfDocs!= null? value[1].numOfDocs : "NA"}</span>

                                </Link>
                            </td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
        </div>
    );
}

export default OverviewTable;
