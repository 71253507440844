import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Checkbox from "@material-ui/core/Checkbox";
import { FaFilter } from "react-icons/fa";
import { BsCaretDownFill } from "react-icons/bs";
import { TbHierarchy2 } from "react-icons/tb";
import { FaSortAlphaDown } from "react-icons/fa";
import { BsFillCaretRightFill } from "react-icons/bs";
import ResultInformationModal from "../../shared/Modal/ResultInformationModal";
import PnSearchFilterModal from "../../shared/Modal/PnSearchFilterModal";
import PnSearchGroupModal from "../../shared/Modal/PnSearchGroupModal";
import PnSearchSortingModal from "../../shared/Modal/PnSearchSortingModal";
import SaveVirtualDatabase from "../../shared/Modal/SaveVirtualDatabase";
import PnSearchGeneiousModal from "../../shared/Modal/PnSearchGeneiousModal";
import PnSearchDocCurrentViewModal from '../../shared/Modal/PnSearchDocCurrentViewModal';
import PnSearchTableCurrentViewModal from '../../shared/Modal/PnSearchTableCurrentViewModal';
import PnSearchFastaModal from '../../shared/Modal/PnSearchFastaModal';
import PnSearchEmblLikeModal from '../../shared/Modal/PnSearchEmblLikeModal';
import PnSearchGenbankModal from '../../shared/Modal/PnSearchGenbankModal';
import PnSearchXMLExportModal from '../../shared/Modal/PnSearchXMLExportModal';
import SaveViewsModal from "../../shared/Modal/SaveViewsModal";
import ManageViewModal from "../../shared/Modal/ManageViewModal";
import DefineColumnsModal from "../../shared/Modal/DefineColumnsModal";

const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1,
        width: '96%',
    },
    tablehead: {
        borderLeft: '1px solid white',
        fontWeight: 'normal'
    },
    toolbar: {
        backgroundColor: '#eeece1',
        height: '30px',
        borderLeft: '1px solid #dcd7c0',
        borderBottom: '1px solid #dcd7c0',
        borderRight: '1px solid #dcd7c0',
        fontFamily: 'verdana, helvetica',
        padding : '5px',
    },
    drawer: {
        background: 'whitesmoke',
        border: '1px solid #CCCCCC',
        borderTop: 'none',
        fontFamily: 'verdana, helvetica',
        fontSize: '11px',
        height: '40px',
        padding: '8px 0px 7px 5px',
    },
    drawerButtons: {
        border: '1px solid #b5b5b5',
        padding: '6px 4px 10px 10px',
        borderRadius: '4px',
        backgroundColor: '#e6e6e6',
        cursor: 'pointer',
        fontSize: '13px',
        marginRight: '10px',
        boxShadow: '0 1px 1px rgba(0, 0, 0, 0.15)'
    },
    pagination: {
        padding: '0 0.3em',
        textAlign: 'center',
        color: 'gray',
        border: 'none',
        backgroundColor:'white'
    },
    toolbarBtns: {
        '&:hover': {
            border: '1px solid #b5b5b5',
        },
    },
    absolutePosition: {
        position: 'absolute',
        background: 'white',
        border: '1px solid #b5b5b5',
        fontFamily: 'verdana, helvetica',
        fontSize: '12px',
        padding: '4px',
    },
    onRowHover: {
        '&:hover': {
            border: '1px solid #b5b5b5',
            backgroundColor: '#eeece1',
            color: 'black',
        },
    }
}));

function PatentDatabases() {
    const classes = useStyles();
    const [resultBtnClicked, setResultBtnClicked] = useState(false);
    const [viewBtnClicked, setViewBtnClicked] = useState(false);
    const [exportBtnClicked, setExportBtnClicked] = useState(false);
    const [applicationBtnClicked, setApplicationClicked] = useState(false);
    const [resultBtnHovered, setResultBtnHovered] = useState(false);
    const [viewBtnHovered, setViewBtnHovered] = useState(false);
    const [exportBtnHovered, setExportBtnHovered] = useState(false);
    const [applicationBtnHovered, setApplicationBtnHovered] = useState(false);
    const [EMBOSSExtend,setEMBOSSExtend] = useState(false);
    const [showResult, setShowResult] = useState(false);
    const [expandedAll,setExpandedAll] = useState(false);
    const [nucleotide,setNucleotide] = useState(false);
    const [protein, setProtein] = useState(false);
    const [launchNewSearch, setLaunchNewSearch] = useState(false);
    const [expandedHovered,setExpandedHovered ] = useState(false);
    const [onFiltering, setOnFiltering] = useState(false);
    const [openGrouping, setOpenGrouping] = useState(false);
    const [openSorting, setOpenSorting] = useState(false);
    const [virtualDb, setvirtualDb] = useState(false);
    const [openGeneious,setOpenGeneious] = useState(false);
    const [openDocCurrentView,setOpenDocCurrentView] = useState(false);
    const [openTabCurrentView,setOpenTabCurrentView] = useState(false);
    const [openFasta, setOpenFasta] = useState(false);
    const [openEmblExport, setOpenEmblExport] = useState(false);
    const [openGenbankExport, setOpenGenbankExport] = useState(false);
    const [openXMLExport, setOpenXMLExport]= useState(false);
    const [saveView, setSaveView] = useState(false);
    const [manageView, setManageView] = useState(false);
    const [defineColumns, setDefineColumns] = useState(false);

    useEffect(() => {
    }, []);

    const handleResultBtnClick = () => {
        setResultBtnClicked(prevState => !prevState);
        closeAllExcept('result');
        setEMBOSSExtend(false);
    };

    const handleViewBtnClick = () => {
        setViewBtnClicked(prevState => !prevState);
        closeAllExcept('view');
        setEMBOSSExtend(false);
    };

    const handleExportBtnClick = () => {
        setExportBtnClicked(prevState => !prevState);
        closeAllExcept('export');
        setEMBOSSExtend(false);
    };

    const handleApplicationsBtnClick = () => {
        setApplicationClicked(prevState => !prevState);
        closeAllExcept('application');
        setEMBOSSExtend(false);
        setLaunchNewSearch(false);
    };

    const handleResultBtnHover= () =>{
        if (!resultBtnClicked) {
            setResultBtnHovered(true);
            setViewBtnHovered(false);
            setExportBtnHovered(false);
            setApplicationBtnHovered(false);
            closeAllExcept('result');
            setEMBOSSExtend(false);
            setNucleotide(false);
            setProtein(false);
            setLaunchNewSearch(false);
            setExpandedHovered(false);
            setExpandedAll(false);
        }
    }
    const handleViewBtnHover = () => {
        if (!viewBtnClicked) {
            setResultBtnHovered(false);
            setViewBtnHovered(true);
            setExportBtnHovered(false);
            setApplicationBtnHovered(false);
            closeAllExcept('view');
            setEMBOSSExtend(false);
            setNucleotide(false);
            setProtein(false);
            setLaunchNewSearch(false);
            setExpandedHovered(false);
        }
    };

    const handleExportBtnHover = () => {
        if (!exportBtnClicked) {
            setResultBtnHovered(false);
            setExportBtnHovered(true);
            setViewBtnHovered(false);
            setApplicationBtnHovered(false);
            closeAllExcept('export');
            setEMBOSSExtend(false);
            setNucleotide(false);
            setProtein(false);
            setLaunchNewSearch(false);
            setExpandedAll(false);
        }
    };

    const handleApplicationBtnHover = () => {
        if (!applicationBtnClicked) {
            setResultBtnHovered(false);
            setApplicationBtnHovered(true);
            setViewBtnHovered(false);
            setExportBtnHovered(false);
            closeAllExcept('application');
            setEMBOSSExtend(false);
            setLaunchNewSearch(false);
            setExpandedAll(false);
        }
    };

    const closeAllExcept = (except) => {
        if (except !== 'result') setResultBtnClicked(false);
        if (except !== 'view') setViewBtnClicked(false);
        if (except !== 'export') setExportBtnClicked(false);
        if (except !== 'application') setApplicationClicked(false);
    };

    const onExpandedDetails = () =>{
        setExpandedAll(prevState => !prevState);
    }
    const handleExpandedDetailsHover  = () =>{
        if(!expandedAll){
            setExpandedHovered(true);
        }
    }
    const handleEMBOSS = () =>{
        setEMBOSSExtend(prevState => !prevState);
        setNucleotide(false);
        setProtein(false);
        setLaunchNewSearch(false);
    }
    const handleCheckbox = () => {
        // Handle Checkbox Change
    };
    const showInformation = () => {
        setShowResult(true);
        setResultBtnClicked(false);
    }

    const virtualDatabase = () =>{
        setvirtualDb(true);
        setResultBtnClicked(false);
    }
    const handleNucleotide = () =>{
        setNucleotide(prevState => !prevState);
        setProtein(false);
    }
    const handleProtein = () =>{
        setProtein(prevState => !prevState);
        setNucleotide(false);
    }
    const handleNewSearch = () => {
        setLaunchNewSearch(prevState => !prevState);
        setEMBOSSExtend(false);
        setNucleotide(false);
        setProtein(false);
    }
    const closeModal =() =>{
        setShowResult(false);
        setvirtualDb(false);
        setSaveView(false);
        setManageView(false);
        setDefineColumns(false);
    }
    const handleFiltering = () =>{
        setOnFiltering(true);
    }
    const handleGrouping = () =>{
        setOpenGrouping(true);
    }
    const handleSorting = () =>{
        setOpenSorting(true);
    }
    const onGroupingClose = () =>{
        setOpenGrouping(false);
    }
    const onFilteringClose = () =>{
        setOnFiltering(false);
    }
    const onSortingClose = () =>{
        setOpenSorting(false);
    }
    const handleGeneious = () => {
        setOpenGeneious(true);
        setApplicationClicked(false);
    }
    const onGeneiousClose = () => {
        setOpenGeneious(false);
    }
    const handleDocCurrentView = () => {
        setOpenDocCurrentView(true);
        setExportBtnClicked(false);
    }
    const onDocCurrentViewClose = () => {
        setOpenDocCurrentView(false);
    }
    const handleTableCurrentView = () => {
        setOpenTabCurrentView(true);
        setExportBtnClicked(false);
    }
    const onTabCurrentViewClose = () => {
        setOpenTabCurrentView(false);
    }
    const handleFastaExport = () => {
        setOpenFasta(true);
        setExportBtnClicked(false);
    }
    const onFastaClose = () =>{
        setOpenFasta(false);
    }
    const handleEmblExport = () =>{
        setOpenEmblExport(true);
        setExportBtnClicked(false);
    }
    const onEmblExportClose = () =>{
        setOpenEmblExport(false);
    } 
    const handleGenbankExport = () =>{
        setOpenGenbankExport(true);
        setExportBtnClicked(false);
    }
    const onGenbankExportClose = () =>{
        setOpenGenbankExport(false);
    } 
    const handleXMLExport = () =>{
        setOpenXMLExport(true);
        setExportBtnClicked(false);
    }
    const onXMLExportClose = () =>{
        setOpenXMLExport(false);
    } 
    const showViewInformation = () =>{
        setSaveView(true);
        setResultBtnClicked(false);
    }
    const manageViewInformation = () =>{
        setManageView(true);
        setResultBtnClicked(false);
    }
    const defineColumnsInformation = () =>{
        setDefineColumns(true);
        setResultBtnClicked(false);
    }
    return (
        <div className={classes.grow}>
            <div id='tophalf'>
                <div className={classes.toolbar} style={{ fontSize: '12px' }}>
                    <span className={classes.toolbarBtns} style={{ padding: '7px' }} onClick={handleResultBtnClick}
                        onMouseEnter={handleResultBtnHover}
                        onMouseLeave={() => setResultBtnHovered(false)}>
                        Result
                    </span>
                    <span
                        className={classes.toolbarBtns}
                        style={{ padding: '7px' }}
                        onClick={handleViewBtnClick}
                        onMouseEnter={handleViewBtnHover}
                        onMouseLeave={() => setViewBtnHovered(false)}>
                        View
                    </span>
                    <span
                        className={classes.toolbarBtns}
                        style={{ padding: '7px' }}
                        onClick={handleExportBtnClick}
                        onMouseEnter={handleExportBtnHover}
                        onMouseLeave={() => setExportBtnHovered(false)}>
                        Export
                    </span>
                    <span
                        className={classes.toolbarBtns}
                        style={{ padding: '7px' }}
                        onClick={handleApplicationsBtnClick}
                        onMouseEnter={handleApplicationBtnHover}
                        onMouseLeave={() => setApplicationBtnHovered(false)}>
                        Applications
                    </span>
                    <span>
                        <div align='right'>
                            <a>
                                <span title="clear all selections">[x]</span>
                            </a>
                        </div>
                    </span>
                </div>
                {(resultBtnClicked || resultBtnHovered )&& (
                    <table>
                    <tbody className={classes.absolutePosition} style={{ marginLeft: '5px' }}>
                        <tr className={classes.onRowHover}  onClick={showInformation}>Result Information</tr>
                        <tr className={classes.onRowHover} onClick={virtualDatabase}>save as Virtual Database</tr>
                    </tbody>
                </table>
                )}
                {(viewBtnClicked || viewBtnHovered) && (
                    <table>
                        <tbody className={classes.absolutePosition} style={{ marginLeft: '56px' }}>
                            <tr className={classes.onRowHover}>My Views <BsFillCaretRightFill /></tr>
                            <tr className={classes.onRowHover} onClick={showViewInformation}>Save View</tr>
                            <tr className={classes.onRowHover} onClick={manageViewInformation}>Manage Views</tr>
                            <tr className={classes.onRowHover} style={{borderBottom: '1px solid #dcd7c0'}} onClick={defineColumnsInformation}>Define Columns</tr>
                            <tr className={classes.onRowHover} style={{borderBottom: '1px solid #dcd7c0'}}>
                                <td><Checkbox />Show Only Checked Results</td>
                            </tr>
                            <tr className={classes.onRowHover} onClick={onExpandedDetails} onMouseEnter={handleExpandedDetailsHover}
                                onMouseLeave={() => setExpandedHovered(false)}> Expand all Details <BsFillCaretRightFill /></tr>
                        </tbody>
                    </table>
                )}
                {(exportBtnClicked || exportBtnHovered) && (
                    <table>
                        <tbody className={classes.absolutePosition} style={{ marginLeft: '98px' }}>
                            <tr className={classes.onRowHover} onClick={handleDocCurrentView}>Document: Current view</tr>
                            <tr className={classes.onRowHover} style={{borderBottom: '1px solid #dcd7c0'}} onClick={handleTableCurrentView}>Table: Current View</tr>
                            <tr className={classes.onRowHover} onClick={handleFastaExport}>Fasta</tr>
                            <tr className={classes.onRowHover} onClick={handleEmblExport}>Embl-like</tr>
                            <tr className={classes.onRowHover} onClick={handleGenbankExport}>Genbank-like</tr>
                            <tr className={classes.onRowHover} onClick={handleXMLExport}>XML</tr>
                        </tbody>
                    </table>
                )}
                {(applicationBtnClicked || applicationBtnHovered) && (
                    <table>
                        <tbody className={classes.absolutePosition} style={{ marginLeft: '153px' }}>
                            <tr className={classes.onRowHover} onClick={handleEMBOSS}>EMBOSS <BsFillCaretRightFill /></tr>
                            <tr className={classes.onRowHover} onClick={handleGeneious}>Geneious</tr>
                            <tr className={classes.onRowHover} style={{color:'grey'}}>Clustalw</tr>
                            <tr className={classes.onRowHover} onClick={handleNewSearch}>Launch New Search <BsFillCaretRightFill /></tr>
                            <tr className={classes.onRowHover} style={{color:'grey'}}>Vector NTI</tr>
                            <tr className={classes.onRowHover} style={{color:'grey'}}>CLC Bio</tr>
                        </tbody>
                    </table>
                )}
                {(expandedAll || expandedHovered) && (
                    <table>
                        <tbody className={classes.absolutePosition} style={{ marginLeft: '277px', marginTop: '120px' }}>
                            <tr className={classes.onRowHover}>Annotation</tr>
                            <tr className={classes.onRowHover}>Sequence</tr>
                        </tbody>
                    </table>
                )}
                {(EMBOSSExtend )&& (
                    <table>
                        <tbody className={classes.absolutePosition} style={{ marginLeft: '297px' }}>
                            <tr className={classes.onRowHover} onClick={handleNucleotide}>Nucleotide analysis <BsFillCaretRightFill /></tr>
                            <tr className={classes.onRowHover} onClick={handleProtein}>Protein analysis<BsFillCaretRightFill /></tr>
                        </tbody>
                    </table>
                )}
                {(nucleotide) && (
                    <table>
                        <tbody className={classes.absolutePosition} style={{ marginLeft: '438px' }}>
                            <tr className={classes.onRowHover} style={{color:'grey'}}>GC Content (geecee)</tr>
                            <tr className={classes.onRowHover} style={{color:'grey'}}>ORF Finder (getorf)</tr>
                        </tbody>
                    </table>
                )}
                {(protein) && (
                    <table>
                        <tbody className={classes.absolutePosition} style={{ marginLeft: '438px', marginTop: '18px' }}>
                            <tr className={classes.onRowHover} style={{color:'grey'}}>Protein Statistics (pepstats)</tr>
                        </tbody>
                    </table>
                )}
                {(launchNewSearch) && (
                    <table>
                        <tbody className={classes.absolutePosition} style={{ marginLeft: '297px', marginTop: '54px' }}>
                            <tr className={classes.onRowHover} style={{color:'grey'}}>Sequence Search</tr>
                            <tr className={classes.onRowHover} style={{color:'grey'}}>IP Search</tr>
                        </tbody>
                    </table>
                )}
                {showResult &&
                    <ResultInformationModal showModal={showResult} closeModal={closeModal}></ResultInformationModal>
                }
                {virtualDb &&
                    <SaveVirtualDatabase showModal={virtualDb} closeModal = {closeModal}></SaveVirtualDatabase>
                }
                <div className={classes.drawer}>
                    <span className={classes.drawerButtons} onClick={handleFiltering}>
                        <span>
                            <FaFilter style={{ margin: '0px 9px 0px 3px', color: 'grey' }} />
                            Filtering
                        </span>
                        <span><BsCaretDownFill style={{ marginLeft: '6px' }} /></span>
                    </span>
                    <span className={classes.drawerButtons} onClick = {handleGrouping}>
                        <span>
                            <TbHierarchy2 style={{ margin: '0px 9px 0px 3px', color: 'grey' }} />
                            Grouping
                        </span>
                        <span><BsCaretDownFill style={{ marginLeft: '6px' }} /></span>
                    </span>
                    <span className={classes.drawerButtons} onClick = {handleSorting}>
                        <span>
                            <FaSortAlphaDown style={{ margin: '0px 9px 0px 3px', color: 'grey' }} />
                            Sorting
                        </span>
                        <span><BsCaretDownFill style={{ marginLeft: '6px' }} /></span>
                    </span>
                </div>
                {onFiltering &&
                    <PnSearchFilterModal showModal= {onFiltering} closeModal= {onFilteringClose}></PnSearchFilterModal>
                }
                {openGrouping &&
                    <PnSearchGroupModal showModal= {openGrouping} closeModal= {onGroupingClose}> </PnSearchGroupModal>
                }
                {openSorting &&
                    <PnSearchSortingModal showModal= {openSorting} closeModal= {onSortingClose}></PnSearchSortingModal>
                }
                {openGeneious &&
                    <PnSearchGeneiousModal showModal= {openGeneious} closeModal= {onGeneiousClose}></PnSearchGeneiousModal>
                }
                {openDocCurrentView &&
                    <PnSearchDocCurrentViewModal showModal= {openDocCurrentView} closeModal= {onDocCurrentViewClose}></PnSearchDocCurrentViewModal>
                }
                {openTabCurrentView &&
                    <PnSearchTableCurrentViewModal showModal= {openTabCurrentView} closeModal= {onTabCurrentViewClose}></PnSearchTableCurrentViewModal>
                }
                {openFasta &&
                    <PnSearchFastaModal showModal= {openFasta} closeModal= {onFastaClose}></PnSearchFastaModal>
                }
                {openEmblExport &&
                    <PnSearchEmblLikeModal showModal= {openEmblExport} closeModal= {onEmblExportClose}></PnSearchEmblLikeModal>
                }
                {openGenbankExport &&
                    <PnSearchGenbankModal showModal = {openGenbankExport} closeModal={onGenbankExportClose}></PnSearchGenbankModal>
                }
                {openXMLExport &&
                    <PnSearchXMLExportModal showModal={openXMLExport} closeModal={onXMLExportClose}></PnSearchXMLExportModal>
                }
                {saveView &&
                    <SaveViewsModal showModal={saveView} closeModal={closeModal}></SaveViewsModal>
                }
                {manageView &&
                    <ManageViewModal showModal={manageView} closeModal={closeModal}></ManageViewModal>
                }
                {defineColumns &&
                    <DefineColumnsModal showModal={defineColumns} closeModal={closeModal}></DefineColumnsModal>
                }
                <table style={{ width: '100%', marginTop: '15px', marginBottom: '15px' }}>
                    <tbody>
                        <tr>
                            <td style={{ width: '33%' }}></td>
                            <td style={{ width: '33%', fontSize: '12px', fontFamily: 'verdana, helvetica' }} align='center'>
                                <button className={classes.pagination}>
                                    &lt;&lt; First
                                </button>
                                <button className={classes.pagination}>
                                    &lt; Previous
                                </button>
                                <span className='colNoCSS'> Patents </span>
                                <button className={classes.pagination}>
                                    &gt; Next
                                </button>
                                <button className={classes.pagination}>
                                    &gt;&gt; Last
                                </button>
                            </td>
                            <td align="right" style={{ width: '33%', fontFamily: 'verdana, helvetica', fontSize: '11px' }}>
                                <a title="see the sequence listings">Sequences</a>
                                (
                                <a title="see the nucleotide sequence listings">nuc</a>
                                ,
                                <a title="see the protein sequence listings">pro</a>
                                )
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div style={{ marginTop: '12px' }}>
                <table style={{ width: '100%', fontFamily: 'verdana, helvetica', fontSize: '12px', fontWeight: 'normal' }}>
                    <thead className='resulttable_head'>
                        <tr>
                            <th style={{ width: '4%', fontFamily: 'verdana, helvetica' }}>
                                <Checkbox checked={false} onChange={handleCheckbox()} style={{ color: 'white', backgroundColor: "transparent" }} />
                            </th>
                            <th className={classes.tablehead} style={{ width: '10%' }}>Identifier</th>
                            <th className={classes.tablehead} style={{ width: '18%' }}>Patent Assignee</th>
                            <th className={classes.tablehead} style={{ width: '18%' }}>Title</th>
                            <th className={classes.tablehead} style={{ width: '10%' }}>Extended Legal Status</th>
                            <th className={classes.tablehead} style={{ width: '10%' }}>Publication Date</th>
                            <th className={classes.tablehead} style={{ width: '10%' }}>Earliest Priority Date</th>
                            <th className={classes.tablehead} style={{ width: '9%' }}>Patent Sequence Location</th>
                            <th className={classes.tablehead} style={{ width: '8%' }}>Link Out</th>
                            <th className={classes.tablehead} style={{ width: '4%' }}>PDF Link</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>

                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default PatentDatabases;
