import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";


const useStyles = makeStyles((theme) => ({
  textField: {
    // width: '194px',
    width: 'auto',
    minWidth: '250px',
    padding: '5px 0px',
    margin: '-8px 0 0 -5px'
  },
  dense: {
    marginTop: theme.spacing(2)
  },
  menu: {
    width: 'auto',
    top: '150px',
  },
  smallBox: {
    width: '14%',
    padding: '5px 0px',
    margin: '-8px 0 0 -5px',
    '& .MuiSelect-iconOutlined': {
      right: '0px'
    }
  },

}));

export default function DropDownBox(props) {
  const classes = useStyles();

  const items = props.items;
  return (
    <TextField
      id={props.id}
      name={props.name}
      select
      label={props.label ? props.label: ""}
      className={props.className + " " + (props.smallBox ? classes.smallBox : classes.textField)}
      value={props.value}
      InputLabelProps={{ shrink: true }}
      SelectProps={{
        MenuProps: {
          className: (props.smallBox ? "" : classes.menu),
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left"
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left"
          },
          getContentAnchorEl: null

        }
      }}
      margin={props.margin}
      variant={props.variant}
      onChange={props.onChange}
      disabled={props.disabled}
      onBlur={props.onBlur}
    >
      {items && items.length > 0 && items.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
    // </form>
  );
}
