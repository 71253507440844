import { useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import ArrowDownIcon from '../../../assets/image/sort-down-19g.png';

import SyntaxCard from './SyntaxCard';

function SyntaxExplainer() {

    const [currentCard, setCurrentCard] = useState(0);
    const numOfCards = useState(8);

    function moveLeft(){
        if(currentCard <= 0){
            setCurrentCard(8);
        }else{
            setCurrentCard(currentCard-1);
        }
    }

    function moveRight(){
        if(currentCard >= 8){
            setCurrentCard(0);
        }else{
            setCurrentCard(currentCard+1);
        }
    }

    return (
    <tr className='cardRow'>
        <td>
        <img src={ArrowDownIcon} className="ArrowLeft" onClick={(e) => moveLeft()}/> 
        </td>
        <td>
            <div className='syntaxCard'>
            {currentCard == 0 &&
            <SyntaxCard
                tlTitle = {ReactHtmlParser('<a href="https://docs.genomequestlive.com/#FT_QuerySyntax" target="_blank">Standard Logic Operators</a>')}
                tlList = {[
                    ReactHtmlParser('<li>dsRNA <em>AND</em> <em>(</em>HIV <em>OR</em> AIDS<em>)</em></li>'),
                    ReactHtmlParser('<li>myeloma <em>AND NOT</em> isothiocyanate</li>')
                ]}
                brTitle = {ReactHtmlParser('<a href="https://docs.genomequestlive.com/#FT_BGP" target="_blank">Exact Phrase Searching</a>')}
                brList={[
                    ReactHtmlParser('<li><em>"</em>multiple myeloma<em>"</em></li>')
                ]}
            />
            }
            {currentCard == 1 &&
            <SyntaxCard
                tlTitle = {ReactHtmlParser('<a href="https://docs.genomequestlive.com/#FT_WildcardOperators" target="_blank">Wildcard Characters</a>')}
                tlList = {[
                    ReactHtmlParser('<li><em>vaccine*</em> will match <em>vaccine</em>, <em>vaccines</em>, <em>vaccination</em>, ...</li>'),
                    ReactHtmlParser('<li><em>H1N?</em> will match <span style="color: rgb(255,0,0);">H1N1</span>, <em>H1N2</em>, ...</li>'),
                    ReactHtmlParser('<li><span style="color: rgb(255,0,0);">Covid_19 will match covid-19, covid 19, covid19, covid_19</span></li>')
                ]}
                brTitle = {ReactHtmlParser('<a href="https://docs.genomequestlive.com/#FT_WildcardOperators" target="_blank">Wildcard Combinations</a>')}
                brList={[
                    ReactHtmlParser('<li><em>fol<span style="color: rgb(255,0,0);">*</span>ox<span style="color: rgb(255,0,0);">?</span></em> matches <em>fol<span style="color: rgb(255,0,0);">f</span>ox</em>, <em>fol<span style="color: rgb(255,0,0);">f</span>ox<span style="color: rgb(255,0,0);">4</span></em>, <em>fol<span style="color: rgb(255,0,0);">firi</span>nox</em>, ...</li>')
                ]}
            />
            }
            {currentCard == 2 &&
            <SyntaxCard
                tlTitle = {ReactHtmlParser('<a href="https://docs.genomequestlive.com/#FT_BGP" target="_blank">Standard Logic Operators</a>')}
                tlList = {[
                    ReactHtmlParser('<li>dsRNA <em>AND</em> <em>(</em>HIV <em>OR</em> AIDS<em>)</em></li>'),
                    ReactHtmlParser('<li>myeloma <em>AND NOT</em> isothiocyanate</li>')
                ]}
                brTitle = {ReactHtmlParser('<a href="https://docs.genomequestlive.com/#FT_BGP" target="_blank">Exact Phrase Searching</a>')}
                brList={[
                    ReactHtmlParser('<li><em>"</em>multiple myeloma<em>"</em></li>')
                ]}
            />
            }
            {currentCard == 3 &&
            <SyntaxCard
                tlTitle = {ReactHtmlParser('<a href="https://docs.genomequestlive.com/#FT_SpecificFields" target="_blank">Search on Dates — YYYYMMDD</a>')}
                tlList = {[
                    ReactHtmlParser('<li>priordate:<em>19770403</em></li>')
                ]}
                brTitle = {ReactHtmlParser('<a href="https://docs.genomequestlive.com/#FT_SpecificFields" target="_blank">Search on Date Range []</a>')}
                brList={[
                    ReactHtmlParser('<li>pubdate:<em>[</em>20100218 <em>TO now]</em></li>'),
                    ReactHtmlParser('<li>appldate:<em>[* TO</em> 20100218<em>]</em></li>')
                ]}
            />
            }
            {currentCard == 4 &&
            <SyntaxCard
                tlTitle = {ReactHtmlParser('<a href="https://docs.genomequestlive.com/#FT_Ontologies" target="_blank">Use a Synonym List</a>')}
                tlList = {[
                    ReactHtmlParser('<li>Type <em>alcoholism</em> and select its <em>MSH</em> entry</li>'),
                    ReactHtmlParser('<li>This will search for many synonyms of <em>alcoholism</em> (click the term to view)</li>')
                ]}
                brTitle = {ReactHtmlParser('<a href="https://docs.genomequestlive.com/#FT_Ontologies" target="_blank">Create Your Own Synonym List</a>')}
                brList={[
                    ReactHtmlParser('<li>Click on the yellow box that reads <em>alcoholism</em></li>'),
                    ReactHtmlParser('<li>Press the <em>Edit</em> button, save changes</li>'),
                    ReactHtmlParser('<li>The <em>blue box</em> indicates a custom list</li>')
                ]}
            />
            }
            {currentCard == 5 &&
            <SyntaxCard
                tlTitle = {ReactHtmlParser('<a href="https://docs.genomequestlive.com/#FT_SpecificFields" target="_blank">Search Specific Fields</a>')}
                tlList = {[
                    ReactHtmlParser('<li><em>claim:(</em> dsRNA <em>AND (</em> HIV <em>OR</em> AIDS <em>) )</em></li>'),
                    ReactHtmlParser('<li>Type <em>?</em> to see the <a href="https://docs.genomequestlive.com/#FT_SpecificFields" target="_blank">available fields</a></li>')
                ]}
                brTitle = {ReactHtmlParser('<a href="https://docs.genomequestlive.com/#FT_SpecificFields" target="_blank">Search Aggregate Fields</a>')}
                brList={[
                    ReactHtmlParser('<li>Fields like <em>title:, abst: (Abstract), claim:</em> allow you to search across multiple fields</li>'),
                    ReactHtmlParser('<li>The <em>names:</em> field will search in Assignees, Inventors and Assignors</li>')
                ]}
            />
            }
            {currentCard == 6 &&
            <SyntaxCard
                tlTitle = {ReactHtmlParser('<a href="https://docs.genomequestlive.com/#FT_SpecificFields" target="_blank">Boost the Relevance of a Word</a>')}
                tlList = {[
                    ReactHtmlParser('<li>Make hyperoxia five times more relevant: hyperoxia^5 <em>AND</em> hypoxia</li>'),
                    ReactHtmlParser('<li>returns the same results as without the <em>^5</em> but documents with more occurrences of hyperoxia bubble to the top</li>')
                ]}
                brTitle = {ReactHtmlParser('<a href=https://docs.genomequestlive.com/#FT_WildcardOperators" target="_blank">Fuzzy Search</a>')}
                brList={[
                    ReactHtmlParser('<li><em>strawberry%</em> will search for strawbery, stravberry, starawberry, strawberri, mstrawberry, etc.</li>')                
                ]}
            />
            }
            {currentCard == 7 &&
            <SyntaxCard
                tlTitle = {ReactHtmlParser('<a href="https://docs.genomequestlive.com/#FT_SpecificFields" target="_blank">Search for Documents with Sequences</a>')}
                tlList = {[
                    ReactHtmlParser('<li><em>gq_doc:</em>yes</li>'),
                    ReactHtmlParser('<li><em>gq_doc:</em>no</li>')
                ]}
                brTitle = {ReactHtmlParser('<a href="https://docs.genomequestlive.com/#FT_SpecificFields" target="_blank">Numeric Ranges</a>')}
                brList={[
                    ReactHtmlParser('<li><em>gq_nucleos:[0 TO 500]</em></li>'),
                    ReactHtmlParser('<li><em>gq_proteins:[1 TO 20]</em></li>')             
                ]}
            />
            }
            {currentCard == 8 &&
            <SyntaxCard
                tlTitle = {ReactHtmlParser('<a href="https://docs.genomequestlive.com/#FT_SpecificFields" target="_blank">Proximity Search</a>')}
                tlList = {[
                    ReactHtmlParser('<li><em>&lt; glycine 3D sarcosine &gt;</em> will search for glycine before or after sarcosine, with at most three words in between</li>'),
                    ReactHtmlParser('<li><em>&lt; glycine 3W sarcosine &gt;</em> will search for glycine before sarcosine (and not after!), with at most three words in between</li>')
                ]}
                brTitle = {ReactHtmlParser('<a href="https://docs.genomequestlive.com/#FT_SpecificFields" target="_blank">Use Wildcards, Phrases and Synonym Lists</a>')}
                brList={[
                    ReactHtmlParser('<li><em>&lt;</em> "human immunodeficiency virus" HIV <em>10D</em> glycoprotein? <span class="synonym">Glycoproteins</span> <em>&gt;</em></li>'),
                    ReactHtmlParser('<li>Finds either <em>"human immunodeficiency virus"</em> or <em>HIV</em> within 10 words of anything matching <em>glycoprotein?</em> or any synonyms of <span class="synonym">Glycoproteins</span>\.</li>')             
                ]}
            />
            }
            </div>
        </td>
        <td>
        <img src={ArrowDownIcon} className="ArrowRight" onClick={(e) => moveRight()}/> 
        </td>
    </tr>
    )
}

export default SyntaxExplainer;
