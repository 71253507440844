import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from "@material-ui/core/Tooltip";
import Checkbox from "@material-ui/core/Checkbox";
import { url } from '../../reducers/url';
import PubSub from 'pubsub-js';
import ManageTeamsCreateModal from "../../shared/Modal/ManageTeamsCreateModal";
import ManageTeamsDeleteModal from "../../shared/Modal/ManageTeamsDeleteModal";
import { get } from '../../helpers/fetchServicesMethods';
import brush from "../../assets/image/brush.png";
import ManageTeamsInfo from "./ManageTeamsInfo";

function showLoader() {
  PubSub.publish('msg', true);
}

function hideLoader() {
  PubSub.publish('msg', false);
}

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
    width: '96%',
  },
  inputStyle: {
    marginLeft: '9px',
    border: '1px solid #b5b5b5',
    padding: '0px',
    fontSize: '12px',
    fontFamily: 'verdana, helvetica',
    outline: 'none',
  },
  h2Css: {
    fontSize: '13px',
    marginLeft: '1em',
    fontFamily: 'verdana, helvetica',
    fontWeight: 'bold',
    lineHeight: '1.286em',
    marginTop: '1em',
    marginBottom: '0px',
  }
}));

async function getManageTeamsTableData(extendUrl, history) {
  try {
    let manageTeams = url.manageTeams + extendUrl;
    showLoader();
    return await get(manageTeams, history)
      .then((response) => {
        hideLoader();
        return response;
      })
      .catch((error) => {
        console.log("error::", error);
        hideLoader();
      });
  } catch (error) {
    hideLoader();
    console.error(error);
  }
}
async function getManageTeamsSearch(searchItem, history) {
  try {
    let manageTeamsSearch = url.manageTeamsSearch;
    manageTeamsSearch = manageTeamsSearch.replace('**', searchItem)
    showLoader();
    return await get(manageTeamsSearch, history)
      .then((response) => {
        hideLoader();
        return response;
      })
      .catch((error) => {
        console.log("error::", error);
        hideLoader();
      });
  } catch (error) {
    hideLoader();
    console.error(error);
  }
}

function ManageTeams(props) {
  const [initialMTData, setInitialMTData] = useState([]);
  const [mTData, setMTData] = useState([]);
  const [mtTotalCount, setMtTotalCount] = useState(null);
  const [adjustedStop, setAdjustedStop] = useState();

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        const result = await getManageTeamsTableData('');
        const initialData = result.response_content.results;
        const totalCountAsNumber = parseInt(result.response_content.totalcount, 10);

        console.log('getManageTeamsTableData', result.response_content.results);
        setMTData(initialData);
        setInitialMTData(initialData);
        setAdjustedStop(initialData.length);
        setMtTotalCount(totalCountAsNumber);
      } catch (error) {
        console.error(error);
      }
    }
    fetchMyAPI();
  }, []);

  const classes = useStyles();
  const [openPopup, setOpenPopup] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);
  const [sortedColumn, setSortedColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc');
  const [termsDisable, setTermsDisable] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchTrue, setSearchTrue] = useState(false);
  const [expandedRows, setExpandedRows] = useState({});
  const [gqTeamInfoData, setGqTeamInfoData] = useState([]);
  const [start, setStart] = useState(1);

  const handleCreateButton = () => {
    setOpenPopup(true);
  };

  function handleDeleteButton() {
    setErrorMsg(true);
  }

  const handleCheckboxChange = (index) => (event) => {
    const updatedMTData = [...mTData];
    updatedMTData[index].checked = event.target.checked;
    let checkedCount = 0;

    updatedMTData.forEach((item) => {
      if (item.checked) {
        checkedCount++;
      }
    });

    setMTData(updatedMTData);

    if (checkedCount === 0) {
      setTermsDisable(false);
    } else if (checkedCount === mTData.length) {
      setTermsDisable(true);
    } else {
      setTermsDisable(false);
    }
  };

  const handleAllCheckboxChange = () => (event) => {
    const renderedItems = [...mTData];
    renderedItems.forEach((item, index) => {
      if (!termsDisable) {
        renderedItems[index].checked = true;
        setTermsDisable(true);
      }
      else {
        renderedItems[index].checked = false;
        setTermsDisable(false);
      }
    });
  };

  function sortData(column) {
    const newSortDirection = sortedColumn === column && sortDirection === 'asc' ? 'desc' : 'asc';
    setSortedColumn(column);
    setSortDirection(newSortDirection);

    const sortedCopy = [...mTData];
    sortedCopy.sort((a, b) => {
      const aValue = a[column];
      const bValue = b[column];

      if (aValue === null && bValue === null) {
        return 0;
      } else if (aValue === null) {
        return newSortDirection === 'asc' ? -1 : 1;
      } else if (bValue === null) {
        return newSortDirection === 'asc' ? 1 : -1;
      }

      if (typeof aValue === 'string' && typeof bValue === 'string') {
        return newSortDirection === 'asc'
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      } else {
        return newSortDirection === 'asc'
          ? aValue - bValue
          : bValue - aValue;
      }
    });
    setMTData(sortedCopy);
  }

  function handleCloseButton(n) {
    setOpenPopup(false);
  }
  function closeDeletePopup() {
    setErrorMsg(false);
  }

  function clearData() {
    const inputElement = document.getElementById('inputTag');
    inputElement.value = '';
    setSearchQuery('');
  }
  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSearchButtonClick = async() => {
    const TotalData = await getManageTeamsSearch(searchQuery);
    const searchedData = TotalData.response_content.results;

    setMTData(searchedData);
    setStart(1);
    setAdjustedStop(searchedData.length);
    setMtTotalCount(TotalData.response_content.totalcount);
    setSearchTrue(true);
  };
  const handleAfterPopupClose = async () => {
    try {
      const result = await getManageTeamsTableData('');
      const updatedData = result.response_content.results;
      const totalCountAsNumber = parseInt(result.response_content.totalcount, 10);

      setMTData(updatedData);
      setInitialMTData(updatedData);
      setMtTotalCount(totalCountAsNumber);

      setStart(1);
      setAdjustedStop(updatedData.length);
    } catch (error) {
      console.error(error);
    }
    setErrorMsg(false);
  };  

  const handleAfterCreateClose = async () => {
    try {
      const result = await getManageTeamsTableData('');
      const updatedData = result.response_content.results;
      const totalCountAsNumber = parseInt(result.response_content.totalcount, 10);

      setMTData(updatedData);
      setInitialMTData(updatedData);
      setMtTotalCount(totalCountAsNumber);

      setStart(1);
      setAdjustedStop(updatedData.length);

    } catch (error) {
      console.error(error);
    }
  };


  async function onClickTableData(team) {
    setExpandedRows((prevExpandedRows) => ({
      ...prevExpandedRows,
      [team.gq_team_id]: !prevExpandedRows[team.gq_team_id],
    }));
    setGqTeamInfoData(prevState => ({
      ...prevState,
      [team.gq_team_id]: team,
    }));
  }
  const manageTeamsDataAfterEdit = (afterEditData) => {
    setMTData((prevMTData) => {
      const updatedData = [...prevMTData];
      const index = updatedData.findIndex((item) => item.id === afterEditData.response_content.id);
      if (index !== -1) {
        updatedData[index].text_label = afterEditData.response_content.text_label;
        updatedData[index].description = afterEditData.response_content.description;
      }
      console.log('updatedData: ', updatedData);
      return updatedData;
    });
  };

  const handleRightShift = async (adjustedStop, totalMtDataCount) => {
    console.log("clicked on right shift");

    const itemsPerPage = 50;
    const newStart = adjustedStop + 1;
    const newStop = adjustedStop + itemsPerPage;

    const newAdjustedStop = Math.min(newStop, totalMtDataCount);
    const extendUrl = `&start=${newStart}&stop=${newAdjustedStop}`;
    const result = await getManageTeamsTableData(extendUrl);
    setMTData(result.response_content.results);

    setStart(newStart);
    setAdjustedStop(newAdjustedStop);
  };

  const handleLeftShift = async ( totalMtDataCount) => {
    console.log("clicked on left shift");

    const itemsPerPage = 50;
    let newStart = start - itemsPerPage;
    if (newStart < 1) {
      newStart = 1;
    }
    const newStop = newStart + itemsPerPage - 1;
    const newAdjustedStop = Math.min(newStop, totalMtDataCount);
    const extendUrl = `&start=${newStart}&stop=${newAdjustedStop}`;
    const result = await getManageTeamsTableData(extendUrl);
    setMTData(result.response_content.results);

    setStart(newStart);
    setAdjustedStop(newAdjustedStop);
  };

  const handleLeftDoubleShift = async ()=>{
    const extendUrl = `&start=${1}&stop=${50}`;
    const result = await getManageTeamsTableData(extendUrl);
    setMTData(result.response_content.results);

    setStart(1);
    setAdjustedStop(50);
  }

const handleRightDoubleShift = async (mtTotalCount) => {
  const itemsPerPage = 50;  
  let newStart = Math.max(Math.ceil(mtTotalCount / itemsPerPage) * itemsPerPage - itemsPerPage + 1, 1);
  const stop = mtTotalCount;
  const extendUrl = `&start=${newStart}&stop=${stop}`;
  const result = await getManageTeamsTableData(extendUrl);
  setMTData(result.response_content.results);

  setStart(newStart);
  setAdjustedStop(stop);
};

  return (
    <div className={classes.grow}>
      <div className='mt_layout'>
        <div className='mt_container'>
          <div className='toolbar mToolbar'>
            <table style={{ width: '100%' }}>
              <tbody>
                <tr>
                  <td style={{ width: '33%' }}>
                    <button className='mTeamsBtn' onClick={handleCreateButton}>Create</button>
                    {openPopup && <ManageTeamsCreateModal
                      showModal={openPopup}
                      handleCloseButton={handleCloseButton}
                      afterCloseModal={handleAfterCreateClose}></ManageTeamsCreateModal>}
                    <button className='mTeamsBtn' onClick={() => handleDeleteButton()}>Delete</button>
                    {errorMsg && <ManageTeamsDeleteModal
                      showModal={errorMsg}
                      mTData={mTData}
                      closeDeletePopup={closeDeletePopup}
                      afterCloseModal={handleAfterPopupClose}
                    ></ManageTeamsDeleteModal>}
                  </td>
                  {mTData.length > 0 && (
                    <td style={{ width: '33%', fontSize: '12px', fontFamily: 'verdana, helvetica' }} align='center'>
                      <button
                        className={`columnNo ${start === 1 ? 'disabled' : ''}`}
                        style={{ color: start === 1 ? 'gray' : 'black' }}
                        onClick={() => start !== 1 && handleLeftDoubleShift()}
                      >
                        &lt;&lt;
                      </button>
                      <button
                        className={`columnNo ${start === 1 ? 'disabled' : ''}`}
                        style={{ color: start === 1 ? 'gray' : 'black' }}
                        onClick={() => start !== 1 && handleLeftShift(mtTotalCount)}
                      >
                        &lt;
                      </button>
                      <span className='colNoCSS'> {start} - {adjustedStop} of {mtTotalCount}</span>
                      <button
                        className={`columnNo ${adjustedStop === mtTotalCount ? 'disabled' : ''}`}
                        style={{ color: adjustedStop === mtTotalCount ? 'gray' : 'black' }}
                        onClick={() => adjustedStop !== mtTotalCount && handleRightShift(adjustedStop, mtTotalCount)}
                      >
                        &gt;
                      </button>
                      <button
                        className={`columnNo ${adjustedStop === mtTotalCount ? 'disabled' : ''}`}
                        style={{ color: adjustedStop === mtTotalCount ? 'gray' : 'black' }}
                        onClick={() => handleRightDoubleShift(mtTotalCount)}
                      >
                        &gt;&gt;
                      </button>
                    </td>
                  )}

                  <td style={{ width: '33%' }} align='right'>
                    <form>
                      <div className="form-container">
                        <img align="right" src={brush} onClick={() => clearData()} />
                        <input
                          id="inputTag"
                          type="text"
                          value={searchQuery}
                          onChange={handleSearchInputChange}
                          className={classes.inputStyle}
                          autoComplete="off"
                        />
                        <div className="search-bar">
                          <button className="mTSearch" type="submit" onClick={handleSearchButtonClick}>Search</button>
                        </div>
                      </div>
                    </form>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          {mTData.length > 0 && <div className='tableCSS'>
            <div>
              <table style={{ width: '100%' }}>
                <thead className='resulttable_head'>
                  <tr>
                    <th style={{ width: '2%', fontFamily: 'verdana, helvetica' }}>
                      <Tooltip title="Select/Deselect all users in this table">
                        <Checkbox checked={termsDisable} onChange={handleAllCheckboxChange()} style={{ color: 'white' }} />
                      </Tooltip>
                    </th>
                    <th style={{ borderLeft: '1px solid white' }} >
                      <a className='columnLink' onClick={() => sortData('text_label')}>Name
                        {sortedColumn === 'text_label' && (
                          <span>{sortDirection === 'asc' ? '▲' : '▼'}</span>
                        )}
                      </a>
                    </th>

                    <th style={{ borderLeft: '1px solid white' }} >
                      <a className='columnLink' onClick={() => sortData('description')}>Description
                        {sortedColumn === 'description' && (
                          <span>{sortDirection === 'asc' ? '▲' : '▼'}</span>
                        )}
                      </a>
                    </th>
                    <th style={{ minWidth: '128px', borderLeft: '1px solid white' }} >
                      <a className='columnLink' onClick={() => sortData('_owner_full_name')}>Owner
                        {sortedColumn === '_owner_full_name' && (
                          <span>{sortDirection === 'asc' ? '▲' : '▼'}</span>
                        )}
                      </a>
                    </th>
                    <th style={{ minWidth: '145px', borderLeft: '1px solid white' }}>
                      <a className='columnLink' onClick={() => sortData('create_time')}>
                        Creation Date
                        {sortedColumn === 'create_time' && (
                          <span>{sortDirection === 'asc' ? '▲' : '▼'}</span>
                        )}
                      </a>
                    </th>
                  </tr>
                </thead>
                <tbody >
                  {mTData.map((item, index) => ( // Iterate through the 'mTData' array and use 'item' and 'index'
                    <React.Fragment key={index}>
                      <tr className={expandedRows[item.gq_team_id] ? 'selected-row' : 'table_body'}>
                        <td style={{ verticalAlign: 'top' }}><Checkbox
                          checked={item.checked || false}
                          onChange={handleCheckboxChange(index)}
                          style={{ color: 'black', verticalAlign: 'middle' }} /></td>
                        <td style={{ padding: '4px', verticalAlign: 'middle'}} onClick={() => onClickTableData(item)}>{item.text_label}</td>
                        <td style={{ padding: '4px', verticalAlign: 'middle' }} onClick={() => onClickTableData(item)}>{item.description}</td>
                        <td style={{ padding: '4px', verticalAlign: 'middle' }} onClick={() => onClickTableData(item)}>{item._owner_full_name}</td>
                        <td style={{ padding: '4px', verticalAlign: 'middle' }} onClick={() => onClickTableData(item)}>{item.create_time}</td>
                      </tr>
                      {expandedRows[item.gq_team_id] &&
                        <React.Fragment>
                          <tr>
                            <td colSpan='5' style={{ background: '#cbeaf5', padding: '5px' }}>
                              <ManageTeamsInfo gqTeamInfoData={gqTeamInfoData} teamId={item.gq_team_id} manageTeamsDataAfterEdit={manageTeamsDataAfterEdit}></ManageTeamsInfo>
                            </td>
                          </tr>
                        </React.Fragment>
                      }
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          </div>}
          {mTData.length === 0 &&
            <div className={classes.h2Css}>There are no items.</div>
          }
        </div>
      </div>
    </div>
  );
}

export default ManageTeams;
