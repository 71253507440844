import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";

function SyntaxCard(props) {
    const { t, i18n } = useTranslation("common");

    const tlTitle = useState(props.tlTitle);
    const tlList = useState(props.tlList);

    const brTitle = useState(props.brTitle);
    const brList = useState(props.brList);

    return (
    <div className="syntaxCardInner">
        <div className='cardTopContainer'>
        <h1 className='cardH1'>
            {tlTitle}
        </h1>
            <ul className="cardUl">
            {tlList && tlList.map((obj) => { 
                return(
                    obj
                );
            })}
            </ul>
        </div>
        <div className='cardBottomContainer'>
        <h1 className='cardH1'>
            {brTitle}
        </h1>
            <ul className="cardUl">
            {brList && brList.map((obj) => { 
                return(
                    obj
                );
            })}
            </ul>
        </div>
    </div>
    )
}

export default SyntaxCard;
