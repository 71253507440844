import React, { useState, useEffect } from "react";
//import "../TableStyles.css"

import doubleVenn from '../tableImages/doubleVenn.png';
import doubleVennNA from '../tableImages/doubleVennNA.png';
import { Link } from 'react-router-dom';
import { useParams } from "react-router-dom/cjs/react-router-dom.min";


function DocMatchOne(props){

    const {resid, reportName} = useParams();
    const {lcChain, hclcChain, hcChain, collName} = props

    useEffect(() =>{
    },[props])

    function applyMatchingPNs(totalQueries){
        for(var i=0; i< totalQueries.length; i++){
            totalQueries[i] = totalQueries[i].toUpperCase();
            totalQueries[i] = totalQueries[i].replaceAll('-','_');
        }
        var abReportFilters = {
            matchingDocuments: totalQueries,
            reportName: decodeURIComponent(reportName) == "undefined"? "Unfiltered_Entire_Report": decodeURIComponent(reportName),
            groupBy: 'document'
        };
        
        return encodeURIComponent(JSON.stringify(abReportFilters))
    }

	return (
        <div class="tableFrame">
            <p class="textFlat pCenter paddingTitle"><strong>DOCUMENTS</strong> matching at least one of the chains</p>
            <table class="tableMatchOne">
                <td>
                    <img id="imgMatchOne" class="imgMatchOne" src={hcChain > 0 ? doubleVenn : doubleVennNA}></img>
                    {lcChain > 0 &&
                     <Link 
                        className={lcChain == 0? "textL disabled":"textL"}                        
                        target="_blank"
                        disabled={lcChain == 0} 
                        to={{
                            pathname: '/discover/wf:'+resid+".resdb/antibody/"+applyMatchingPNs(['LC']) + "/" + collName
                        }}>{lcChain}
                     </Link>
                    }
                    {hclcChain > 0 &&
                    <Link 
                        className={hclcChain == 0? "textM disabled":"textM"}                         
                        target="_blank"
                        disabled={hclcChain == 0} 
                        to={{
                            pathname: '/discover/wf:'+resid+".resdb/antibody/"+applyMatchingPNs(['LC','HC']) + "/" + collName
                        }}>{hclcChain}
                    </Link>
                    }
                    {hcChain > 0 &&
                    <Link 
                        className={hcChain == 0? "textR disabled":"textR"}
                        target="_blank"
                        disabled={hcChain == 0} 
                        to={{
                            pathname: '/discover/wf:'+resid+".resdb/antibody/"+applyMatchingPNs(['HC']) + "/" + collName
                        }}>{hcChain}
                    </Link>
                    }
                </td>
            </table>

            <p class="bottomText pCenter">The number of patent documents matching chain sequences. Click the numbers to see the results.</p>
        </div>
    );
}

export default DocMatchOne;
