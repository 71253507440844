import React, { useState, useCallback, useEffect, Fragment, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from "react-i18next";
import { Link, useLocation } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import Button from '@material-ui/core/Button';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import seqSearchImg from '../../assets/image/seqSearch.png';
import notesImg from '../../assets/image/notes.png';
import pencilImg from '../../assets/image/pencil.png';
import TextInput from '../../shared/Fields/TextInput';
import searchResSequence from '../../services/searchResSequence';
import RenameContainer from '../../shared/components/RenameContainer'
import SharedWith from '../Sharing/SharedWith.js';
import { containerWidth } from '../../shared/constants';
import ftAccess from '../../services/ftAccess';
import ReactHtmlParser from 'react-html-parser';

const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1,
        width: '96%',
        margin: '4px auto 28px',
        minHeight: '260px',
        // borderBottom: '1px solid #cec7c7',
        padding: '23px 16px 14px',
        border: '1px solid #cec7c7',
        borderRadius: '3px',
        maxWidth: containerWidth
    },
    headerPipe: {
        margin: '0 10px'
    },
    listStyle: {
        listStyleType: 'none',
        padding: '0 10px'
    },
    failedTextColor: {
        color: '#e17a47 !important'
    },
    content: {
        fontSize: '14px !important',
        lineHeight: '25px',
        color: '#4a5050'
    },
    rowHeight: {
        height: '8%'
    },
    alertSelect: {
        width: '51%'
    },
    loginSubmitCancel: {
        backgroundColor: '#0182C5',
        borderColor: '#1F4E79',
        border: '1px solid #1F4E79',
        color: 'white',
        margin: '4px',
        textTransform: 'capitalize',
        '&:hover': {
            backgroundColor: '#0182C5',
            boxShadow: 'none',
        },
    },
    cancelButtonModal: {
        backgroundColor: '#EEEEEE',
        border: '1px solid #CCCCCC',
        float: 'left',
        textTransform: 'none',
        margin: '4px',
        marginTop: '4px',
        color: '#777777',
        boxShadow: 'none'
    },
    modalHeader: {
        borderBottom: 'none !important',
        paddingTop: '14px',
        paddingRight: '1px',
        marginTop: '-7px',
        display: "block !important"
    },
    footerDiv: {
        padding: '0 30px',
        marginTop: '-5px',
        marginRight: '-10px',
    },
    contentPadding: {
        padding: "45px !important"
    },
    modalBoxContent: {
        maxHeight: '675px',
    },
    modalClassContentDSI: {
        // position: 'absolute',
        width: '96%',
        height: '41%',
        // top: '30%',
        // left: '50%',
        right: 'auto',
        bottom: 'auto',
        padding: '20px 10px'
        // transform: 'translate(-50%, -50%)'
    },
    colorContainerDSI: {
        backgroundColor: '#EEEEEE',
        // marginTop: '-26px',
        // marginLeft: 0px;
        paddingTop: '28px',
        // paddingBottom: '75px',
        paddingBottom: '78px',
        // marginLeft: '10px',
        // marginRight: '10px',
        paddingRight: '10px',
        borderRadius: '5px',

    },
    modalClassContent: {
        position: 'absolute',
        width: '96%',
        // height: '42%',
        top: '30%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        transform: 'translate(-50%, -50%)'
    },
    colorContainer: {
        backgroundColor: '#EEEEEE',
        paddingTop: '28px',
        textAlign: 'left',
        paddingBottom: '53px',
        margin: '20px 10px',
        borderRadius: '5px',
        paddingLeft: '20px'
    },
    buttonStyle: {
        float: 'right',
        textTransform: 'none',
        margin: '4px',
        color: 'white',
        backgroundColor: '##DB862D !important',
        border: '1px solid ##DB862D !important',

    },
    bodyPadding: {
        padding: "15px 10px 0 !important"
    },
    modalHeaderDSI: {
        borderBottom: 'none !important',
        paddingTop: '20px',
        paddingRight: '1px',
        marginTop: '-16px',
        display: "block !important"
    },
    searchTitleText: {
        textWrap: 'break-word',
        inline_size:'150px',
        overflow: 'hidden',
    },
    highlight: {
        fontWeight: 'bold'
    },
    closeButton: {
        position: 'absolute',
        right: '10px',
        top: '10px'
    },
    mergeGroupTitle: {
        marginLeft: '15px',
        fontSize: '14px !important',
        fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
        fontWeight: '700'
    }
}));
const isIndeterminate = indeterminate => indeterminate;
function SearchResultSeqdb(props) {
    const { t, i18n } = useTranslation('common');
    const classes = useStyles();
    const history = useHistory();
    const [seqSummary, setSeqSummary] = useState();
    const [seqShare, setSeqShare] = useState();
    const { workflowId } = useParams();
    const [notes, setNotes] = useState();
    //using state
    const userInfo = useSelector(state => state.setUserInfo);

    // Result Sharing
    const [gqUserId, setGqUserId] = useState();

    // To Update Title
    const [updateTitle, setUpdateTitle] = useState(false);
    const titleNameRef = useRef('');
    const [titleNameErr, setTitleNameErr] = useState(false);

    // To disable shared user ids in add share popup
    const [resultWritable, setResultWritable] = useState(true);

    // To detect outside click
    const wrapperRef = useRef(null);

    const sharedWithMe = useRef('none');
    const getSharedWith = async (id) => {
        const results = await ftAccess.sharedWith(id);
        if (results && results.response_status == 0) {
            if (results.response_content && results.response_content !== 'none') {
                sharedWithMe.current = results.response_content;
                const sharedToNames = Object.keys(results.response_content).map((item) => {
                    return results.response_content[item].full_name
                })
                let sharedToNamesString = '';
                if (Array.isArray(sharedToNames) && (sharedToNames.length > 3)) {
                    sharedToNamesString = <span><b>{sharedToNames[0]}</b> et al.</span>
                } else {
                    for (let i = 0; i < sharedToNames.length; i++) {
                        if (i === 0) {
                            sharedToNamesString = <>{sharedToNamesString} <b>{sharedToNames[i]}</b></>
                        } else if (i !== (sharedToNames.length - 1)) {
                            sharedToNamesString = <>{sharedToNamesString}, <b>{sharedToNames[i]}</b></>
                        } else {
                            sharedToNamesString = <>{sharedToNamesString} <span>and</span> <b>{sharedToNames[i]}</b> </>
                        }
                    }
                    sharedToNamesString = <span>{sharedToNamesString}</span>
                }
                setSeqShare({ 'sharedNames': sharedToNamesString })
            } else {
                sharedWithMe.current = 'none';
                setSeqShare({ 'sharedNames': '' })
            }
        }
    }

    // reset login status
    useEffect(async () => {
        getSummaryResp();
        getSharedWith(workflowId);

        document.addEventListener("keydown", escFunction, false);
        document.title = 'GenomeQuest - Search Result Report' 

        return () => {
            document.removeEventListener("keydown", escFunction, false);
        };
    }, []);

    const escFunction = useCallback((event) => {
        if (updateTitle && event.keyCode === 27) {
            //Do whatever when esc is pressed
            setUpdateTitle(false);
        }
    }, []);

    const getSummaryResp = async () => {
        const getSummaryResponse = await searchResSequence.getDbSummary(workflowId);
        if (getSummaryResponse && getSummaryResponse.response_status == 0) {
            let diskUsage = getSummaryResponse.response_content.disk_usage;
            let usedSpace = 0;
            if (diskUsage) {
                usedSpace = (diskUsage / (1024 * 1024));
                usedSpace = usedSpace.toFixed(2);
            }
            titleNameRef.current = getSummaryResponse.response_content.text_label;
            setGqUserId(getSummaryResponse.response_content.gq_user_id);
            setResultWritable(getSummaryResponse.response_content._is_writable)
            // setSharedIds(getSummaryResponse.response_content.gq_user_id);
            getSummaryResponse.response_content['usedSpace'] = usedSpace;
            if (getSummaryResponse.response_content.description) {
                setNotes(getSummaryResponse.response_content.description);
            }
            setSeqSummary(getSummaryResponse.response_content);
        } else if (getSummaryResponse && getSummaryResponse.response_content && getSummaryResponse.response_content.type === 'GQEXCEPTION'
            && getSummaryResponse.response_content.message && getSummaryResponse.response_content.message.startsWith("Can't find the sequence database identified by")) {
            history.push('/searchResult');
        }

    }

    const handleScroll = (e, id) => {
        document.getElementById(id).scrollIntoView();
        e.preventDefault();
    }

    const updateNewTitle = async (name) => {
        // if (e.keyCode == 13) {
        if (titleNameErr) {
            return;
        }
        let updateParam = '&text_label=' + name;
        titleNameRef.current = name;
        updateSeqData(workflowId, updateParam);
        // }
    }
    const toastId = useRef(null)
    const updateNotes = async () => {
        if (notes) {
            let updateParam = '&description=' + encodeURIComponent(notes);
            updateSeqData(workflowId, updateParam);
        } else {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error('Please Enter Notes to Save Changes.')
            }
        }
    }
    const updateSeqData = async (workflowId, updateParam) => {
        const getnotesResponse = await searchResSequence.updateDbDesc(workflowId, updateParam);
        if (getnotesResponse && getnotesResponse.response_status == 0) {
            getSummaryResp();
            toast.success('Updated Successfully');

        } else {
            toast.error('Update in Error.');
        }
        setUpdateTitle(false);
    }
    const showUpdateTitleText = (e) => {
        e.preventDefault();
        titleNameRef.current = seqSummary.text_label;
        setUpdateTitle(true);
    }
    const updateTitleName = async (e) => {
        titleNameRef.current = e.target.value;
        if (e.target.value.length > 188) {
            setTitleNameErr(true);
        } else {
            setTitleNameErr(false);
        }
    }
    function searchResult() {
        history.push('/searchResult')
    }

    return (
        <div className={classes.grow}>
            <Row className="p-3">
                <Col lg="12" md="12" sm="12" className="mb-5">
                    <Typography className={classes.root + " float-right"}>
                        <span className={classes.pTagMargin}><a onClick={searchResult}>{t('allSearchResults')}</a></span>
                        <span className={classes.headerPipe + " appTextColor"}>|</span>
                        <span className={"appTextColor appLink"} title={t('auditTrial')}>
                            {t('auditTrial')}
                        </span>
                        <span className={classes.headerPipe + " appTextColor"}>|</span>
                        <Link href="#resultSharing" title={t('resSharing')} className={"appLink appLink"} onClick={(e) => handleScroll(e, 'resultSharing')}>
                            {t('resSharing')}
                        </Link>
                        <span className={classes.headerPipe + " appTextColor"}>|</span>
                        <Link href="#" title={t('notes')} className={"appLink appLink"} onClick={(e) => handleScroll(e, 'notesDiv')}>
                            {t('notes')}
                        </Link>
                    </Typography>
                </Col>
                <Col lg="12" md="12" sm="12">
                    <Row>
                        {resultWritable &&
                            <span><img className="float-left" src={pencilImg}/>&nbsp;</span>
                        }
                        {resultWritable && updateTitle ?
                            <RenameContainer applyNewName={updateNewTitle}
                                nameRef={titleNameRef}
                                placeHolderText={'Title'}
                                maxLength={188}
                                cancelButtonClass={classes.loginSubmitCancel} setRenameEnabled={setUpdateTitle} />
                            : <h6 className={"appTextColor loginTitle " + classes.searchTitleText} onClick={e => showUpdateTitleText(e)}>{titleNameRef.current}</h6>}
                    </Row>
                    <Row>
                        <Col lg="1" md="1" sm="12" className="pr-0 text-center">
                            <img src={seqSearchImg} alt={seqSummary && seqSummary.text_label} />
                        </Col>
                        <Col lg="10" md="10" sm="12" className="p-0 content" style={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography className={(seqSummary ? 'd-block' : 'd-none')}>
                                <RadioButtonUncheckedIcon style={{ fontSize: '11px' }} className="mr-2 mt-2 float-left appTextColor" /><span>{t('dbCreatedTitle')} {seqSummary && seqSummary.create_time ? format(new Date(seqSummary.create_time), 'dd-MMM-yyyy') : ''} {t('by')} <b>{seqSummary && seqSummary._owner_full_name}</b>.​</span></Typography>
                            <Typography className={(seqShare && seqShare.sharedNames ? 'd-block' : 'd-none')}>
                                <RadioButtonUncheckedIcon style={{ fontSize: '11px' }} className="mr-2 mt-2 float-left appTextColor" /> <span>{t('sharedWithTitle')} {seqShare && seqShare.sharedNames}
                                    <a href="#" onClick={(e) => handleScroll(e, 'resultSharing')}>({t('shareSettings')}).</a></span></Typography>
                        </Col>
                    </Row>
                    <hr />
                    <Row>
                        {seqSummary &&
                        <Col lg="12" md="12" sm="12" className="pr-0 content">
                            <h6 className={"appTextColor loginTitle mt-3"}>{t('dbDetails')}</h6>
                            <Col lg="12" md="12" className={"pr-0 content"}>
                                <Typography >
                                    <RadioButtonUncheckedIcon style={{ fontSize: '11px' }} className="mr-2 mt-2 float-left appTextColor" /> Sequence type: <span className="text-capitalize">{seqSummary && seqSummary.seq_type}.</span>​</Typography>
                            </Col>
                            <Col lg="12" md="12" className={"pr-0 content"}>
                                <Typography >
                                    <RadioButtonUncheckedIcon style={{ fontSize: '11px' }} className="mr-2 mt-2 float-left appTextColor" /> Number of sequences: <span className="text-capitalize">{seqSummary && seqSummary.nb_sequences}.</span>​</Typography>
                            </Col>
                            <Col lg="12" md="12" className={"pr-0 content"}>
                                <Typography >
                                    <RadioButtonUncheckedIcon style={{ fontSize: '11px' }} className="mr-2 mt-2 float-left appTextColor" /> Number of residues: <span className="text-capitalize">{seqSummary && seqSummary.nb_residues}.</span>​</Typography>
                            </Col>
                            {seqSummary && seqSummary.filter_readable_string &&
                                <Col lg="12" md="12" className={"pr-0 content"}>
                                    <Typography >
                                        <RadioButtonUncheckedIcon style={{ fontSize: '11px' }} className="mr-2 mt-2 float-left appTextColor" /> Filters: <span className="text-capitalize">{ReactHtmlParser(seqSummary.filter_readable_string)}.</span>​</Typography>
                                </Col>
                            }
                        </Col>}
                        {seqSummary && seqSummary.filter_readable_string &&
                            <Col lg="12" md="12" className="pr-0 content mb-2">
                                <br />
                                <h6 className={"appTextColor loginTitle mt-3"}>{t('dbComponents')}</h6>
                                {seqSummary.db_list && Object.keys(seqSummary.db_list).map((dbVal, i) => {

                                    // Return the element. Also pass key
                                    return (
                                        <div className={"ml-1 "} key={i}>
                                            <Col lg="6" md="6" className="pr-0 content float-left">
                                                <Typography>
                                                    <RadioButtonUncheckedIcon style={{ fontSize: '11px' }} className="mr-2 mt-2 float-left appTextColor" /> {seqSummary.db_list[dbVal].title}</Typography>
                                            </Col>
                                            <Col lg="2" md="2" className="pr-0 content float-left">
                                                <Typography>
                                                    {
                                                        seqSummary.db_list[dbVal].update_time ? format(new Date(seqSummary.db_list[dbVal].update_time), 'dd-MMM-yyyy') : ''
                                                    }
                                                </Typography>
                                            </Col>
                                            <Col lg="2" md="2" className={"pr-0 float-left " + (classes.content)}>

                                                {(seqSummary.db_list[dbVal].update_time == seqSummary.db_list[dbVal].current_update_time) &&
                                                    <Typography className="text-success">{t('currentVersion')}</Typography>
                                                }
                                                {(seqSummary.db_list[dbVal].update_time != seqSummary.db_list[dbVal].current_update_time) &&
                                                    <Typography className="failedTextColor">{t('updateAvailable')}</Typography>
                                                }

                                            </Col>
                                        </div>
                                    )
                                })
                                }
                            </Col>
                        }
                    </Row>
                    <br />
                    <hr />

                    {gqUserId != undefined && workflowId != undefined &&
                        <SharedWith
                            workflowId={workflowId}
                            gqUserId={gqUserId}
                            sharedWithMe={sharedWithMe.current}
                            getSharedWithMe={getSharedWith}
                        />
                    }
                    <hr />
                    <h6 className={"appTextColor loginTitle"} id="notesDiv">{t('notes')}</h6>
                    <Row>
                        <Col lg="10" md="9" sm="12" className="p-0 content">
                            <Row>
                                <Col lg="10" md="10" sm="10" xs='10' className="pr-0 content">
                                    <img className="float-left mx-3" src={notesImg} alt={t('notes')} />
                                    <Typography >
                                        You can leave notes in the box below. Press “Save Notes” to remember them. Notes are visible to and editable by people you share this result with.
                                    </Typography>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12" md="10" className="mx-4 mt-2">
                                    <div className="form-group">
                                        <TextInput
                                            rowsMax="8"
                                            rows="8"
                                            multiline={true}
                                            fullWidth
                                            id="notes"
                                            name="notes"
                                            label={t('notesComment')}
                                            variant="outlined"
                                            value={notes || ''}
                                            onChange={(e) => setNotes(e.target.value)}
                                        />
                                    </div>
                                </Col>
                                <Col md="2"></Col>
                            </Row>
                            <Row>
                                <Col lg="10" md="10" sm="10" className="pr-0 content float-right">
                                    <Button className="mr-2 accountInfo" type="submit" onClick={updateNotes}>{t('saveNotes')}</Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    )
}

export default SearchResultSeqdb;
