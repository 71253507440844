import React, { useState, useRef, useEffect } from "react";
import {useHistory, Link} from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import { useFormik } from 'formik';
import ProgressBar from "../../shared/ProgressBar/Progress";
import { url } from "../../reducers/url";
import { useTranslation } from "react-i18next";
 
let isCancelled = false;

const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1,
        width: '96%',
    },
    tableLayout: {
        margin: '-3px 67px 0px 0px',
        borderRight: '1px solid #dcd7c0',
        borderLeft: '1px solid #dcd7c0',
        borderBottom: '1px solid #dcd7c0',
    },
    textAreaCss: {
        marginLeft: '23px',
    },
    fontDetails: {
        fontFamily: 'verdana, helvetica',
        fontSize: '12px'
    },
}));
 
function PatentNumber() {
    const classes = useStyles();
    const history = useHistory();
    const { t } = useTranslation('common');
    const [searching,setSearching] = useState(false);
    const fileInputRef = useRef(null);
    const formik = useFormik({
        initialValues: {
            textareaId: '',
            searchType: "type1",
        },
    });
 
    useEffect(() => {
        const unlisten = history.listen(() => {
            fileInputRef.current.value = null;
        });

        return () => {
            unlisten();
        };
    }, [history]);
    
    function showfil() {
        console.log("showfil called");
        if (window.File && window.FileReader && window.FileList && window.Blob) {
            var preview = document.getElementById('textareaId');
            var file = document.querySelector('input[type=file]').files[0];
            var reader = new FileReader();

            var textFile = /text.*/;
            if (file.type.match(textFile)) {
                reader.onload = function (event) {
                    preview.value = event.target.result;
                    formik.setFieldValue("textareaId", event.target.result);
                };
            } else {
                preview.innerHTML =
                    "<span class='error'>It doesn't seem to be a valid patent number!</span>";
            }
            reader.readAsText(file);
        }
    }  
 
    function openWindowWithPost(url, data) {
        var form = document.createElement("form");
        form.method = "POST";
        form.action = url;
        form.style.display = "none";
    
        for (var key in data) {
            var input = document.createElement("input");
            input.type = "hidden";
            input.name = key;
            input.value = data[key];
            form.appendChild(input);
        }
    
        document.body.appendChild(form);
        form.submit();
    }
 
    const handleSearch = async () => {
        setSearching(true);

        if (!searching && formik.values.textareaId) {
            await new Promise((resolve) => setTimeout(resolve, 2000));
            const state = { 
                textareaId: formik.values.textareaId,
                searchType: formik.values.searchType
            };
            if (!isCancelled) {
                history.push({
                    pathname: "/patentnumbersearch/field_search",
                    state: state
                });
            }
            isCancelled = false;
        }
        else if (!formik.values.textareaId) {
            await new Promise((resolve) => setTimeout(resolve, 2000));
            if (!isCancelled) {
                if (formik.values.searchType === "type1")
                    openWindowWithPost(process.env.REACT_APP_API_URL + url.allPatentSearchResult, {
                        q: formik.values.textareaId,
                        field: 'PN',
                        include_pm: false,
                    });
                else if (formik.values.searchType === "type2") {
                    openWindowWithPost(process.env.REACT_APP_API_URL + url.allPatentSearchResult, {
                        q: formik.values.textareaId,
                        field: 'PN',
                        include_pm: true,
                    });
                }
            }
            isCancelled = false;
        }
    };

    async function onCancelSearch() {
        setSearching(false);
        isCancelled = true;
        history.push('/patentnumbersearch');
    }
 
    return (
        <div className="grow">
            <div className={classes.tableLayout}>
                <form>
                    <div>
                        <div style={{ padding: '5px' }}>
                            <div className={classes.fontDetails} >
                                <div align='right' > 
                                Browse:<a className={classes.fontDetails} href={process.env.REACT_APP_API_URL + url.allPatentsLink} target="_blank" rel="noreferrer" >{t('allPatents')}</a>
                                    <span className={classes.fontDetails} style={{ color: 'grey' }}>|</span>
                                    <a className={classes.fontDetails} href={process.env.REACT_APP_API_URL + url.allNucleotidePatentsLink} target="_blank" rel="noreferrer" >{t('allNucleotidePatents')}</a>
                                    <span className={classes.fontDetails} style={{ color: 'grey' }}>|</span>
                                    <a className={classes.fontDetails} href={process.env.REACT_APP_API_URL + url.allProteinPatentsLink} target="_blank" rel="noreferrer" >{t('allProteinPatents')}</a>
                                </div>
                            </div>
                            <input
                                id="searchType1"
                                type="radio"
                                name="searchType"
                                style={{marginLeft: '25px'}}
                                value="type1"
                                checked={formik.values.searchType === "type1"}
                                onChange={formik.handleChange}
                            />
                            <label style={{ fontFamily: 'verdana, helvetica', fontSize: '12px', marginLeft: '5px', color: 'black' }} htmlFor="searchType1">Only patent number will be searched</label>
                            <input
                                id="searchType2"
                                type="radio"
                                name="searchType"
                                style={{marginLeft: '13px'}}
                                value="type2"
                                checked={formik.values.searchType === "type2"}
                                onChange={formik.handleChange}
                            />
                            <label style={{ fontFamily: 'verdana, helvetica', fontSize: '12px', marginLeft: '5px', color: 'black' }} htmlFor="searchType2">Patent number and patent family fields will be searched</label>
                            <div className={classes.textAreaCss}>
                                <textarea
                                    id="textareaId"
                                    name="textareaId"
                                    style={{ border: '1px solid #dcd7c0', width: '98%', height: '150px', outline: 'none', fontFamily: 'verdana, helvetica', fontSize: '14px' }}
                                    value={formik.values.textareaId}
                                    onChange={formik.handleChange}
                                ></textarea>
                            </div>
                            <p style={{ color: 'black', fontFamily: 'verdana, helvetica', fontSize: '12px', marginLeft: '25px' }}>For example: WO2010151773
                                US20080038787,
                                EP1721609,
                                WO200209645,
                                AU9052001, EP1073751, US707495
                            </p>

                            <p style={{ display: 'table-cell', fontSize: '15px', padding: '0px 0px 6px 24px' }}> <b id="show-text">Upload .csv or .txt file</b></p>
                            <input
                                ref={fileInputRef}
                                style={{ padding: '0px 0px 27px 24px' }}
                                type="file"
                                accept=".txt"
                                onChange={showfil}
                            />
                            {searching && (
                                <React.Fragment>
                                    <ProgressBar getStatus={"default"} datas={""} />
                                </React.Fragment>
                            )}

                            <table>
                                <tbody>
                                    <tr>
                                        <td style={{ width: '50%' }}>
                                            <ul style={{ fontFamily: 'verdana, helvetica', fontSize: '12px' }}>
                                                <li>All patent databases will be searched</li>
                                                <li>Patent numbers can be separated by comma, semicolon, space or newline</li>
                                                {/*<li>Patent number and patent family fields will be searched</li>*/}
                                                <li>Patent numbers will automatically be reformatted to comply with GQ standards</li>
                                            </ul>
                                        </td>
                                        <td align="right" style={{ width: '50%' }}>
                                            <span>
                                                <span>
                                                    {searching && <button
                                                        className='DMTBtn'
                                                        style={{ padding: '5px 10px', fontSize: '18px' }}
                                                        onClick={onCancelSearch}
                                                    >
                                                        <b>Cancel</b>
                                                    </button>
                                                    }
                                                    <button
                                                        className='DMTBtn'
                                                        style={{ padding: '5px 10px', fontSize: '18px' }}
                                                        type="button"
                                                        onClick={handleSearch}
                                                    >
                                                        <b>Search</b>
                                                    </button>
                                                </span>
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </form>
            </div >
        </div >
    )
}
export default PatentNumber;
