import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import Link from '@material-ui/core/Link';
import Radio from '@material-ui/core/Radio';
import { MdOutlineArrowDropDown } from "react-icons/md";

const useStyles = makeStyles((theme) => ({
    table: {
        width: '100%',
    },
    modalHeader: {
        marginTop: '-7px',
        width: '100%',
        fontFamily: 'verdana, helvetica',
        fontSize: '12px',
    },
    modalBody: {
        textAlign: 'center',
        borderTop: '1px solid #b5b5b5',
        padding: '10px'
    },
    fieldset: {
        padding: '5px',
        marginBottom: '2ex',
        border: '1px solid #ccc',
        borderRadius: '6px',
        fontFamily: 'verdana, helvetica',
        fontSize: '11px',
        textAlign: 'left',
        width: '60%'
    },
    legend: {
        padding: '0 5px',
        fontWeight: 'bold',
        border: '1px solid #b5b5b5',
        borderRadius: '5px',
        background: '#eeece1',
        boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.4)',
        fontSize:'11pt',
        width: '70%',
        marginTop: '-19px'
    },
    para:{
        fontFamily: 'verdana, helvetica',
        color: 'black',
        fontSize: '11px'
    },
    onRowHover: {
        textAlign: 'left',
        '&:hover': {
            border: '1px solid #b5b5b5',
            backgroundColor: '#eeece1',
            color: 'black',
        },
    },
    tableStyle: {
        position: 'absolute',
        backgroundColor: '#ffffff',
        border: '1px solid #b5b5b5',
        width: '80px',
        margin: '-20px 0px 0px 20px'
    },
}));

const PnSearchTableCurrentViewModal = (props) => {
    const classes = useStyles();
    const [selection, setSelection] = useState(''); // Default to 'resultnumber'
    const [inputValue,setInputValue] = useState('DOC');
    const [openOptions, setOpenOptions] = useState(false)

    const handleOptions = () =>{
        setOpenOptions(!openOptions);
    }
    function handleInputValue(value) {
        setInputValue(value);
        setOpenOptions(false);
    }
    return (
        <Modal
            id="modalCss"
            aria-labelledby="contained-modal-title-vcente"
            centered
            size="sm"
            show={props.showModal}
            onHide={props.closeModal}
            contentClassName={classes.modalHeader}
        >
            <div>
                <div className="titlebar">
                    <span>Table: Current View</span>
                    <Link href="#" onClick={(e) => { e.preventDefault(); props.closeModal(); }} className="pull-right">
                        <CloseIcon style={{ fill: '#b5b5b5' }} />
                    </Link>
                </div>
            </div>
            <Modal.Body className={classes.modalBody}>
                <div>
                    <form>
                        <table className={classes.table}>
                            <tbody>
                                <tr>
                                    <td valign='top' style={{padding: '2px 0px 12px 0px'}}>
                                        <p className={classes.para}>You are about to export all {selection} results.</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td valign='top'>
                                        <div className={classes.fieldset}>
                                            <div className={classes.legend}>Selections</div>
                                            <Radio checked />
                                            <label className={classes.para}>All {selection} results</label>
                                        </div>
                                    </td>
                                </tr>
                                <br/>
                                <tr>
                                    <td valign='top'>
                                        <div className={classes.fieldset}>
                                            <div className={classes.legend}>Options</div>
                                            <br />
                                            <div style={{marginLeft: '13px'}}>
                                                <span>Format</span>
                                                <td style={{ border: '1px solid #b5b5b5' }}>
                                                    <span style={{ marginRight: '5px', padding: '0px 9px 0px 14px' }} onClick={handleOptions}>{inputValue}</span>
                                                    <MdOutlineArrowDropDown style={{ height: '25px', width: '21px' }} onClick={handleOptions} />
                                                </td>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                { openOptions &&
                                        <div >
                                        <table className={classes.tableStyle} >
                                            <tbody >
                                                <tr className={classes.onRowHover} onClick={() => handleInputValue('Excel')}>
                                                    <td>Excel</td>
                                                </tr>
                                                <tr className={classes.onRowHover} onClick={() => handleInputValue('CSV')}>
                                                    <td>CSV</td>
                                                </tr>
                                                <tr className={classes.onRowHover} onClick={() => handleInputValue('HTML')}>
                                                    <td>HTML</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                }
                            </tbody>
                        </table>
                    </form>
                    <div style={{ float: 'right' }}>
                        <button className='okButton DMTBtn' >Export</button>
                        <button className='DMTBtn' onClick={props.closeModal}>Cancel</button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default PnSearchTableCurrentViewModal;
