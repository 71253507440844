import {post} from "../../helpers/fetchServicesMethods";

async function record(dbId, action, param) {
    if (typeof param == 'undefined' || param == null) {
        param = '';
    } else if (typeof param === 'number') {
        param += "_";   //  This has to be a string on the backend, the PHP Param process gets all fussy.
    }

    try {
        const data = {
            do: 'gqresult2.log_usage',
            db: dbId,
            action: action,
            payload: param,
            format: 'json',
            error_detection: true
        }
        let apiUrl = 'do=gqresult2.log_usage&format=json';
        return await post(apiUrl, data)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                //toast.error('Something Wrong! Try Again.');
                console.log("error::", error);
            });
    } catch (error) {
        console.error(error);
    }
};

const Log = {
    record
};

export default Log;
