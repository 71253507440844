import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
import ConvertSequence from "./ConvertSequence";
import ConvertSequenceFasta from "./ConvertSequenceFasta";
import TabularFormConversion from "./TabularFormConversion"
import ThreeLetterCodeConversion from "./ThreeLetterCodeConversion";
import St25CodeConversionToFasta from "./St25CodeConversionToFasta";

function ConvertSequenceFormat(){

    const Tabs = ["ST.26", "FASTA", "Tabular", "EMBL", "ST.25", "Three Letter Code", "Single Letter Code"]
    const [state, setState] = useState("ST.26");
    
    let location = useParams();

    useEffect(() =>{
    },[])

    function switchPage(pageName){
        setState(pageName);
    }

    return(
        <div>
            <div style= {{ marginTop: '-33px', marginLeft: '-5px' }}>
                <button className={state === "ST.26" ? "inactiveTab" : "accountTabs"} onClick={()=>switchPage("ST.26")}> ST.26</button>
                <button className={state === "FASTA" ? "inactiveTab" : "accountTabs"} onClick={()=>switchPage("FASTA")}>FASTA</button>
                <button className={state === "Tabular" ? "inactiveTab" : "accountTabs"} onClick={()=>switchPage("Tabular")}>Tabular</button>
                {/* <button className={state === "EMBL" ? "inactiveTab" : "accountTabs"} onClick={()=>switchPage("EMBL")}>EMBL</button> */}
                <button className={state === "ST.25" ? "inactiveTab" : "accountTabs"} onClick={()=>switchPage("ST.25")}>ST.25</button>
                <button className={state === "Three Letter Code" ? "inactiveTab" : "accountTabs"} onClick={()=>switchPage("Three Letter Code")}>Three Letter Code</button>
                {/* <button className={state === "Single Letter Code" ? "inactiveTab" : "accountTabs"} onClick={()=>switchPage("Single Letter Code")}>Single Letter Code</button> */}

            </div>

            {state == "ST.26" &&
            <div>
                <ConvertSequence></ConvertSequence>
            </div>
            }
            {state == "FASTA" &&
            <div>
                <ConvertSequenceFasta></ConvertSequenceFasta>
            </div>
            }
            {state == "Tabular" &&
            <div>
                 <TabularFormConversion></TabularFormConversion>
            </div>
            }
            {state == "EMBL" &&
            <div>
                {/* <Alerts></Alerts> */}
            </div>
            }
            {state == "ST.25" &&
            <div>
                <St25CodeConversionToFasta></St25CodeConversionToFasta>
            </div>
            }
            {state == "Three Letter Code" &&
            <div>
                <ThreeLetterCodeConversion></ThreeLetterCodeConversion>
            </div>
            }
        </div>
    );

}

export default ConvertSequenceFormat;
