import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';

import { makeStyles } from '@material-ui/core/styles';
import {FaAngleUp,FaAngleDown,FaTimes} from "react-icons/fa";
import fieldConfig from "../../components/Discover/FieldConfig";

const useStyles = makeStyles((theme) => ({
	modalClassConDSI: {
		position: 'absolute',
		width: '75%',
		//padding: '35px 15px 0px',
		right: 'auto',
		bottom: 'auto',
		color: '#777'
	},
	modalBoxContent: {
		maxHeight: '600px',
	},
	modalText: {
		position: 'absolute',
		top: '10px',
		right: '10px'
	},
}));

const DedupPrefModal = props => {
	const classes = useStyles();
	const [selectedOption, setSelectedOption] = useState([]);
	const [seed, setSeed] = useState(1);

	const callback = () => {
		props.saveModal(props.filter, props.fieldConfig, props.prefsForUI);
	}

	const handleSelChange = (e) => {
        let value = Array.from(e.target.selectedOptions, option => option.value);
        setSelectedOption(value);  
      };

	  const handleUp = (e) => { 
		if (selectedOption != null) {
            for (var i = 0; i < selectedOption.length; i++) {
                var index = props.prefsForUI.indexOf(selectedOption[i]);
                if (index > i) {
                    // No need to move when index == i
                    props.prefsForUI.splice(index - 1, 0, props.prefsForUI.splice(index, 1)[0]);
                }
            }
        }
		setSeed(Math.random()); //to refresh the select comp after moveup
      };

	  const handleDown = (e) => { 
		if (selectedOption != null) {
            var len = props.prefsForUI.length - selectedOption.length;
            for (var i = selectedOption.length - 1; i >= 0; i--) {
                var index = props.prefsForUI.indexOf(selectedOption[i]);
                if (index < (len + i)) {
                    props.prefsForUI.splice(index + 1, 0, props.prefsForUI.splice(index, 1)[0]);
                }
            }
        }
		setSeed(Math.random()); //to refresh the select comp 
	  };

	return (
		<Modal
			size="lg"
			aria-labelledby="contained-modal-title-vcente"
			centered
			show={props.showModal}
			contentClassName={classes.modalClassConDSI}
		>
			
			<div className="col-select-container">
				<div className={classes.closeButton} href="#" onClick={(e) => e.preventDefault()}>
                    <FaTimes style={{height: '2em'}}  className={classes.modalText} onClick={props.closeModal} />
                </div>
				
				
                <table>
					<tbody>
                    <tr>
                        <td className="really-large-font field-optgroup">Deduplication Preferences
                        </td>
                    </tr>
                    <tr>
                        <td>
							<div className="large-font field-container-label">Patent Database Preference</div>
							<select multiple={true} value={selectedOption} className="dbsetting-container" onChange={handleSelChange} key={seed} >
							{props.prefsForUI && Object.keys(props.prefsForUI).map((i) => {
								return(
									<option
									key={props.prefsForUI[i]}
									className="field-option"
									value={props.prefsForUI[i]}>{props.fieldConfig.getPatentDatabaseLabel(props.prefsForUI[i])}
									</option>
								)
							}
							)}
								
							</select>

                        </td>
                        <td className="field-controls">
							<div><FaAngleUp onClick={handleUp}></FaAngleUp></div>
							<div><FaAngleDown onClick={handleDown}></FaAngleDown></div>
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <button className="pull-right normal-button gqblue" style={{margin: '10px 0 0 0'}} onClick={callback}>Save</button>
                            <button className="pull-right normal-button grey" style={{margin: '10px 10px 0 0'}} onClick={props.closeModal}>Cancel</button>
                        </td>
                    </tr>
					</tbody>
                </table>
				
			</div>
		</Modal>
	);
}

export default DedupPrefModal;
