import React, { useState, useEffect } from "react";
import {useHistory, Link} from "react-router-dom";
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import SortArrowManager from "./SortArrowManager";
import fullTextService from "../../../services/fulltextsearch";
import CloseIcon from '@material-ui/icons/Close';
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";



const useStyles = makeStyles((theme) => ({	
}));

function FullTextHistory(){

    const [fullHistory, setFullHistory] = useState([]);
    const [readyRender, setReadyRender] = useState(false);

    const [hoverQuery, setHoverQuery] = useState("");
    const [hoverPns, setHoverPns] = useState("");

    const [queryPopupY, setQueryPopupY] = useState(0);
    const [queryPopupX, setQueryPopupX] = useState(0);

    const [selectionList, setSelectionList] = useState([]);

    const [showClearHistoryPopup, setShowClearHistoryPopup] = useState(false);

    const userInfo = useSelector((state) => state.setUserInfo);    

    const [sortFactor , setSortFactor] = React.useState();
    const [sortAsc, setSortAsc] = useState(true);

	const { t, i18n } = useTranslation("common");
	const history = useHistory();

    const [allBool, setAllbool] = useState(false);
	
	useEffect(async() => {

       // let test = Test.testHistory.response_content;
       // setFullHistory(Test.testHistory.response_content);
       document.title = 'GenomeQuest - Full-Text Search History'
		if (userInfo && userInfo.ftAccess == false) {
			history.push('/home');
		}else if (userInfo) {
            let ftsh = await fullTextService.getFullHistory(history);
            //let ftsh = Test.testhistoryasdfas;
            let historyHolder = ftsh.response_content.results
            
            if(historyHolder){
                if(historyHolder.length > 0){
                    for(let i=0; i < historyHolder.length; i++){
                        historyHolder[i].checked = false;
                    }
                }
            }
            setFullHistory(historyHolder);

		}

		return () => {};
	}, [userInfo]);

    function fixDate(dateTime){
        return dateTime;
    }

    async function showAll(){
        let ftsh = await fullTextService.getFullHistory(history);

        let historyHolder = ftsh.response_content.results;


        for(let i = 0; i< historyHolder.length; i++){
            historyHolder[i].checked = false;
        }


        let list = selectionList;
        if(list.length > 0 && historyHolder.length>0){
            for(let i = 0; i< historyHolder.length; i++){
                if(list.includes(historyHolder[i].id)){
                    historyHolder[i].checked = true;
                }
            }
        }

        setFullHistory(historyHolder);
    }

    function showSelected(){
        let thisHistory = fullHistory;
        thisHistory = thisHistory.filter(value => value.checked == true);
        setFullHistory(thisHistory);

        setReadyRender(!readyRender);
    }

    async function deleteItems(){
    
        let idString = "";
        let thisHistory = fullHistory;

        for( let i=0; i<thisHistory.length; i++){
            if((!allBool && thisHistory[i].checked) || allBool){
                idString+= thisHistory[i].id;
                idString+=',';
            }
        }

        setSelectionList([]);
        idString = idString.substring(0, idString.length-1)
        setAllbool(false);
        
        if(idString != ""){
            let response = await fullTextService.deleteHistory(idString);
        }

        showAll();
    }

    
    async function deleteAll(){
        let thisHistory = fullHistory;
        let idString = "";
        for(let i =0; i<thisHistory.length; i++){
            idString += thisHistory[i].id;
            idString += ',';
        }
        if(idString != ""){
            let response = await fullTextService.deleteHistory(idString);
        }
        showAll();
    }

    function sortByFactor(factor){

        var thisAscBool = true;
        if(factor === sortFactor){
            setSortAsc(!sortAsc);
            thisAscBool = !sortAsc;

        }else{
            setSortAsc(true);
            thisAscBool = true;
        }
        setSortFactor(factor);

        let historyHolder = [];

        switch(factor){
            case "nbResults":
                historyHolder = fullHistory.sort(nbResultsCompare);
            break; 

            case "executeTime":
                historyHolder = fullHistory.sort(executeTimeCompare);
            break;

            case "grouping":
                historyHolder = fullHistory.sort(groupingCompare);
            break; 

            case "label":
                historyHolder = fullHistory.sort(queryCompare);
            break; 
        }

        if(!thisAscBool){
            historyHolder = historyHolder.reverse();
        }

        setFullHistory(historyHolder);
    }

    function queryCompare(a, b){
        if ( a.query < b.query ){
            return -1;
          }
          if ( a.query > b.query ){
            return 1;
          }
          return 0;
    }

    function groupingCompare(a, b){
        if ( a.grouping < b.grouping ){
            return -1;
          }
          if ( a.grouping > b.grouping ){
            return 1;
          }
          return 0;
    }

    function executeTimeCompare(a, b){
        if ( a.create_time < b.create_time ){
            return -1;
          }
          if ( a.create_time > b.create_time ){
            return 1;
          }
          return 0;
    }

    function nbResultsCompare(a, b){

        if(a.nb_results == null){
            a.nb_results = 0;
        }
        if(b.nb_results == null){
            b.nb_results = 0;
        }


        if ( parseInt(a.nb_results) < parseInt(b.nb_results) ){
            return -1;
        }
        if ( parseInt(a.nb_results) > parseInt(b.nb_results) ){
            return 1;
        }
        return 0;
    }

    const openClearHistoryPopup = (thisAllbool) => {
        setAllbool(thisAllbool);
        setShowClearHistoryPopup(true);
    }

    const closeClearHistoryPopup = () => {
        setShowClearHistoryPopup(false);
        setAllbool(false);
    }

    const checkValue = (event) =>{
       let index = event.target.id.substring(12, event.target.id.length);
       let historyHolder = fullHistory;
       historyHolder[index].checked = event.target.checked;

        if(historyHolder[index].checked){
            let list = selectionList;
            list.push(historyHolder[index].id);
            setSelectionList(list);
        }else{
            let list = selectionList;
            list = arrayRemove(list, historyHolder[index].id);
            setSelectionList(list);
        }
        
       setFullHistory(historyHolder);
       setReadyRender(!readyRender);

    }

    function arrayRemove(arr, value) { 
    
        return arr.filter(function(ele){ 
            return ele != value; 
        });
    }

    function fixQuery(string){
        if(string && string.length > 70){
            string = string.replace(/(\r\n|\n|\r)/gm, "").trim().substring(0, 70)+"...";
        }
        return string;
    }

    const showQuery = (event, query, pns) =>{
		setQueryPopupX(event.clientX);
		setQueryPopupY(event.clientY+10);
        if(query){
            if(query.length > 70){
                setHoverQuery(query);
            }else{
                setHoverQuery("");
            } 
        }else{
            setHoverQuery("");
        } 
        if(pns){
            if(pns.length > 0){
                setHoverPns(pns)
            }else{
                setHoverPns("");
            } 
        }else{
            setHoverPns("");
        } 
	}

	return (
		<div className="HistoryWidth">
			<div className="">
                <table className="">
                    <tr>
                        <td className="historyPopupHeader HistoryPad15">Your Past Searches</td>
                        <td className="HistoryTopper">
                         <Link to={{
                                pathname: '/fulltextsearch',
                                }}>Full-Text Search</Link>
                        </td>
                        <td className="HistoryTopper" onClick={() => showAll()}>Show all</td>
                        <td className={selectionList.length != 0 ? "HistoryTopper": "disabledHistory"} onClick={() => showSelected()}>Show Selected</td>
                        <td className="HistoryTopper" onClick={() => openClearHistoryPopup(true)}>Delete All</td>
                        <td className={selectionList.length != 0 ? "HistoryTopper": "disabledHistory"} onClick={() => openClearHistoryPopup(false)}>Delete Selected</td>
                        {/* <td className="HistoryTopper">Show Searches with Alerts</td>
                        <td className="HistoryTopper">Show Searches with Active Alerts</td> */}
                    </tr>
                </table>
            </div>
            <br></br>
            <div>
                <table className="HistoryTable">
                    <tr className="HistoryHeaderRow">
                        <th className="HistoryBorderBottom HistoryBorderRight">
                        </th>
                        <th onClick={() => {sortByFactor("nbResults")}} className="HistoryHeaderFont HistoryItems HistoryBorderBottom HistoryBorderRight">
                            <div className="fixItems">
                            {sortFactor === "nbResults" && <SortArrowManager asc={sortAsc}></SortArrowManager>}
                            {sortFactor !== "nbResults" && <span className="fixItemWidth"></span>}
                            <span>Items</span>
                            </div>
                        </th>
                        <th onClick={() => {sortByFactor("executeTime")}} className="HistoryHeaderFont HistoryBorderBottom HistoryBorderRight">
                            <div className="fixDate">
                            <span>Last Search Date</span>
                            {sortFactor === "executeTime" && <SortArrowManager asc={sortAsc}></SortArrowManager>}
                            </div>
                        </th>
                        {/* <th className="HistoryHeaderFont HistoryBorderBottom HistoryBorderRight">Alerts</th>
                        <th className="HistoryHeaderFont HistoryBorderBottom HistoryBorderRight"></th> */}
                        <th className="HistoryHeaderFont HistoryBorderBottom HistoryBorderRight groupingFix"> Grouping </th>
                        <th onClick={()=> sortByFactor("label")} className="HistoryHeaderFont HistoryBorderBottom">Query
                            {sortFactor === "label" && <SortArrowManager asc={sortAsc}></SortArrowManager>}
                        </th>
                    </tr>
                    {fullHistory != undefined && fullHistory.map((value, index) => {

        				return (
                            <tr className="HistoryTableRows">
                                <td className={index < fullHistory.length-1 ? "HistoryBorderBottom HistoryBorderRight historyWidthNone" : "HistoryNoBorder HistoryBorderRight historyWidthNone"}>
                                    <Checkbox
                                        color="primary"
                                        onChange={checkValue}
                                        id = {"histCheckbox"+index}
                                        checked = {fullHistory[index].checked}
                                    />
                                </td>
                                <td className={index < fullHistory.length-1 ? "HistoryItems HistoryBorderBottom HistoryBorderRight HistoryText" : "HistoryItems HistoryNoBorder HistoryBorderRight HistoryText" + "historyWidthNone"}>
                                    {value.nb_results}
                                </td>
                                <td className={index < fullHistory.length-1 ? "HistoryDate HistoryBorderBottom HistoryBorderRight HistoryText" : "HistoryDate HistoryNoBorder HistoryBorderRight HistoryText"}>{fixDate(value.create_time)}</td>
                                {/* <td className={index < fullHistory.length-1 ? "HistoryAlerts HistoryBorderBottom HistoryBorderRight" : "HistoryAlerts HistoryNoBorder HistoryBorderRight"}>{fixAlertValue(value.alertSearch)}</td>
                                <td className={index < fullHistory.length-1 ? "HistoryAlertsSpacer HistoryBorderBottom HistoryBorderRight" : "HistoryAlertsSpacer HistoryNoBorder HistoryBorderRight"}><span onClick={(e) => setAlert(value.id)}>{getAlert(value.searchAlert)}</span></td> */}
                                <td className={index < fullHistory.length-1 ? "HistoryBorderBottom HistoryBorderRight HistoryText" : "HistoryBorderRight HistoryNoBorder HistoryText"}>{value.grouping}</td>
                                <td className={index < fullHistory.length-1 ? "HistoryLabel HistoryBorderBottom" : "HistoryLabel HistoryNoBorder"}>
                                    {value.query != "" &&
                                        <div className="fixQuery" onMouseOver={(e)=>showQuery(e, value.query, value.pns)} onMouseLeave={(e)=>{showQuery(e, "", "")}}>
                                            <Link to={{
                                                pathname: '/fulltextsearch',
                                                state: {detail: value}
                                            }}>{fixQuery(value.query)}</Link>
                                        </div>
                                    }
                                    {value.query == "" &&
                                        <div className="fixQuery" onMouseOver={(e)=>showQuery(e, value.query, value.pns)} onMouseLeave={(e)=>{showQuery(e, "", "")}}>
                                            <Link to={{
                                                pathname: '/fulltextsearch',
                                                state: {detail: value}
                                            }}>{fixQuery(value.pns)}</Link>
                                        </div>
                                    }
                                </td>             
                            </tr>
                        );
                        })
                    }
                </table>
            </div>
            {showClearHistoryPopup &&    
            <div>
                <div className="historyPopup">
                    <div>
                        <Link href="#" className={"p-2 float-right appTextColor"}><CloseIcon onClick={() => closeClearHistoryPopup()} /></Link>
                    </div>
                    <br></br>
                    <div>
                        <span className="historyPopupHeader">Confirm Delete </span>
                        {allBool &&
                        <span className="marginLeftFix historyPopupHeader">All</span>}
                        {!allBool &&
                        <span className="marginLeftFix historyPopupHeader">Selected</span>}
                    </div>
                    <div className="historyPopupBuffer">
						<Button className={"cancelButtonClass "} onClick={() => closeClearHistoryPopup()}>{t('Cancel')}</Button>
                        <span className="ml-1"></span>
                        <Button className={"submitButtonClass"} onClick={() => {deleteItems(); closeClearHistoryPopup();}}>{t('Confirm')}</Button>
                    </div>
                </div>
            </div>
            }
            {(hoverQuery.length > 10 || hoverPns.length > 0) &&
			<div 
            className="queryPopup"
				style={{ top: queryPopupY, left: queryPopupX }}
			>
                {hoverQuery !== "" &&
                <div>
                    <div className="historyPopupHeaderHover">
                        <span>Query</span>
                    </div>
                    <p className="queryPopupQuery">
                        {hoverQuery}
                    </p>
                </div>
                }
                {hoverPns !== "" &&
                <div>
                    <div className="historyPopupHeaderHover">
                        <span >Patent List</span>
                    </div>
                    <p className="queryPopupQuery">
                        <br></br>
                        {hoverPns}
                    </p>
                </div>
                }
			</div>	
            }
		</div>	
	);
}

export default FullTextHistory;
