import React, { useState, useEffect } from "react";
import OverviewTable from "./Tables/OverviewTable";
import DocMatchAll from "./Tables/DocMatchAll";
import DocMatchOne from "./Tables/DocMatchOne";
//import DocMatchOneCdr from "./Tables/DocMatchOneCDR";
import DocSeqMatchOneCdr from "./Tables/DocSeqMatchOneCDR";
import CdrMatches from "./Tables/CdrMatchesTable";
//import LightChainVariants from "./Tables/LightChainVariants";
import LightHeavyChainVariants from "./Tables/LightHeavyChainVariants";
import AuditTrail from "./Tables/AuditTrail";
//import "./TableStyles.css"
import ReactHtmlParser from 'react-html-parser';


import CloseIcon from '@material-ui/icons/Close';
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";

import {useHistory, Link, useParams} from 'react-router-dom';
import DiscoverService from "../../services/discoverService";

import { toast } from "react-toastify";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
    loaderHide: {
        display: 'none'
    },

    loader :{
        position: 'fixed',
        top: '0',
        left: '0',
        width: '100%',
        height:' 100%',
        zIndex: '999999',
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
        display: 'block',

    },
    loaderPosition:{
        marginLeft: '602px',
        marginTop: '300px',
    }
}));

function AntiBodyReport(props){

    const { resid, reportName} = useParams();

    let [abReportNames, setAbReportNames] = useState([]);

    const [abReportName, setAbReportName] = useState("Unfiltered_Entire_Report");
    const [constUserDir, setConstUserDir] = useState('');

    const [discoverUrl, setDiscoverUrl]= useState("#/discover/wf:" + resid.match(/\d+/)[0] +".resdb")

    //save and print
    const [isSaveEnabled, setIsSaveEnabled] = useState(false);
    const [openSaveUI, setOpenSaveUI] = useState(false);
    const [saveIsSubmited, setSaveIsSubmited] = useState(false);
    const [userNamedReport, setUserNamedReport] = useState("");
    const [saveFilters, setSaveFilters] = useState({})
    const [saveAuth, setSaveAuth] = useState({});
    const [collName, setCollName] = useState("");


    //for individual tables
    const [alignmentDescriptions, setAlignmentDescriptions] = useState({});
    const [queryAliDocStats, setQueryAliDocStats] = useState({});
    const [matchingDocumentsAll, setMatchingDocumentsAll] = useState(0);
    const [lcChain, setLcChain] = useState(0);
    const [hclcChain, setHcLcChain] = useState(0);
    const [hcChain, setHcChain] = useState(0);
    const [matchingDocumentsHC, setMatchingDocumentsHC] = useState({});
    const [matchingDocumentsLC, setMatchingDocumentsLC] = useState({});
    const [matchingSequencesHC, setMatchingSequencesHC] = useState({});
    const [matchingSequencesLC, setMatchingSequencesLC] = useState({});
    const [cdrCombinationsLC, setCdrCombinationsLC] = useState([]);
    const [cdrCombinationsHC, setCdrCombinationsHC] = useState([]);
    const [cdrCombinationsVariants, setCdrCombinationsVariants] = useState({});
    const [auditTrail, setAuditTrail] = useState({});
    const [strategyParams, setStrategyParams] = useState({});
    const [databases, setDatabases] = useState({});
    const [auditFilters, setAuditFilters] = useState("");


    const [misMap, setMisMap] = useState(["PERFECT","ONE","TWO","THREE"]);
    const [ABData, setABData] = useState({});

    const [wfData, setWfData] = useState(null);

    let [userdir, setUserdir] = useState('');
    const [showLoader, setShowLoader] = useState(false);
    const classes = useStyles();

    useEffect( async() => {
        document.title = 'GenomeQuest - Antibody Report Overview';
        setShowLoader(true);
        var residVar = resid;

        let filters = null;
        let queryObj = "";
		if(!_.isEmpty(window.reportFiltersAb) && (reportName == null || reportName == undefined)){
            if(!_.isEmpty(window.reportFiltersAb.data)){
                filters = window.reportFiltersAb.data;
                setSaveFilters(filters);
            }
		}

        const auth = await DiscoverService.getAuthInfoAB(residVar);
        
        if(auth != null){

            setSaveAuth(auth.response_content.gqApiKey);
            getABReports(auth.response_content.gqApiKey)

            userdir = auth.response_content.userDir;
            setConstUserDir(userdir);

            let data = {}
            
            if(reportName != undefined && reportName !== null){
                data.abReportName = decodeURIComponent(reportName);
            }else{
                data.abReportName = "Unfiltered_Entire_Report"
            }

            data.userId = userdir
            data.workflowId = resid;

            const results = await DiscoverService.loadAbReport(data, auth.response_content.gqApiKey)

            let queryAliKeys=[];
            if(results != null){
                if (results.filters && results.filters.collName) {
                    setCollName(results.filters.collName);
                }

                if(results.matchingDocumentsChains){
                    if(results.matchingDocumentsChains.queryMatchingDocs !=null){
                        setLcChain(results.matchingDocumentsChains.queryMatchingDocs.LC);
                        setHcLcChain(results.matchingDocumentsChains.queryMatchingDocs.LCHC);
                        setHcChain(results.matchingDocumentsChains.queryMatchingDocs.HC);
                    }
                }

                if(results.aliDocsStats){
                    if(results.aliDocsStats.queryAliDocStats!= null){
                        queryAliKeys = Object.keys(results.aliDocsStats.queryAliDocStats);

                        for(let i = 0; i< queryAliKeys.length; i++){
                            queryAliKeys[i] = queryAliKeys[i].toUpperCase();
                        }
                    }
                }
            
                getAliDesc(filters, auth.response_content.gqApiKey);
                setABData(results);
            }
            if(filters!=null){

                setIsSaveEnabled(true);

                makeOverviewCall(filters, auth.response_content.gqApiKey);
                makeCDRVariationsCall(filters, auth.response_content.gqApiKey);

                makeCDRCombinationsCall(filters, auth.response_content.gqApiKey, true);
                makeCDRCombinationsCall(filters, auth.response_content.gqApiKey, false);

                matchingDocumentsAllApi(filters, auth.response_content.gqApiKey, queryAliKeys);
                matchingDocumentsChainsApi(filters, auth.response_content.gqApiKey, queryAliKeys);

                matchingDocumentsApi(filters, auth.response_content.gqApiKey, true);
                matchingDocumentsApi(filters, auth.response_content.gqApiKey, false);

                matchingSequencesApi(filters, auth.response_content.gqApiKey, true);
                matchingSequencesApi(filters, auth.response_content.gqApiKey, false);
               
            } else {

                if(results.aliDocsStats){
                    if(results.aliDocsStats.queryAliDocStats!= null){

                        setQueryAliDocStats(results.aliDocsStats.queryAliDocStats);
                        if(results.aliDocsStats.filterDesc != null){
                            setAuditFilters(results.aliDocsStats.filterDesc);
                        }
                    }
                }

                if(results.matchingDocumentsAll){
                    if(results.matchingDocumentsAll.matchingRecords!= null){
                        setMatchingDocumentsAll(results.matchingDocumentsAll.matchingRecords);
                    }
                }

                if(results.matchingDocumentsHC){
                    if(results.matchingDocumentsHC.queryMatchingDocs !=null){
                        setMatchingDocumentsHC(results.matchingDocumentsHC.queryMatchingDocs);
                    }
                }

                if(results.matchingDocumentsLC){
                    if(results.matchingDocumentsLC.queryMatchingDocs !=null){
                        setMatchingDocumentsLC(results.matchingDocumentsLC.queryMatchingDocs);
                    }
                }

                if(results.matchingSequencesHC){
                    if(results.matchingSequencesHC.queryMatchingSeqs !=null){
                        setMatchingSequencesHC(results.matchingSequencesHC.queryMatchingSeqs);
                    }
                }

                if(results.matchingSequencesLC){
                    if(results.matchingSequencesLC.queryMatchingSeqs !=null){
                        setMatchingSequencesLC(results.matchingSequencesLC.queryMatchingSeqs);
                    }
                }

                //all CDR MATCHESMISMATCHES tables
                if(results.cdrCombinationsLC){
                    if(results.cdrCombinationsLC.cdrCombinations != null){
                        sortCombinations('lc', results.cdrCombinationsLC.cdrCombinations);
                    }
                }
                if(results.cdrCombinationsHC){
                    if(results.cdrCombinationsHC.cdrCombinations != null){
                        sortCombinations('hc', results.cdrCombinationsHC.cdrCombinations);
                    }
                }

                if(results.cdrVariationStats != undefined && results.cdrVariationStats != null){
                    if(results.cdrVariationStats.cdrVariationStats != undefined && results.cdrVariationStats.cdrVariationStats != null){
                        setCdrCombinationsVariants(results.cdrVariationStats.cdrVariationStats);
                    }
                }

                if(results.aliDocsStats.auditTrail != null){
                    setAuditTrail(results.aliDocsStats.auditTrail);
                };

                if(results.aliDocsStats.strategyParams != null){
                    setStrategyParams(results.aliDocsStats.strategyParams);
                };

                if(results.aliDocsStats.databases != null){
                    setDatabases(results.aliDocsStats.databases);
                };
            }

            setShowLoader(false);
        }

    },[]);
    

    function Print(divName) {
        const printContents = document.getElementById(divName).innerHTML;
        if (navigator.userAgent.toLowerCase().indexOf('chrome') > -1) {
            const popupWin = window.open('', '_blank', 'width=600,height=600,scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no');
            popupWin.window.focus();
            popupWin.document.write('<!DOCTYPE html><html><body onload="window.print()"><div style="width: 900px">' + printContents + '</div></body></html>');
            popupWin.document.close();
            popupWin.onbeforeunload = function (event) {
                popupWin.close();
                return '.\n';
            };
            popupWin.onabort = function (event) {
                //popupWin.document.close();
                popupWin.close();
            }
        } else {
            const popupWin = window.open('', '_blank', 'width=800,height=600');
            popupWin.document.open();
            popupWin.document.write('<html><body onload="window.print()" style="width: 900px">' + printContents + '</body></html>');
            popupWin.document.close();
        }
    }

    async function getABReports(apikey) {
        let data = {
            workflowId: resid.match(/\d+/)[0],
            userId: constUserDir
        }
        let response = await DiscoverService.getABReports(data, apikey);
        if (response) {
            abReportNames = response.abReportNames;
        }
    }

    async function saveABReport() {


        if(userNamedReport == ""){

            toast.error("Please name Report");

        }else{
            setAbReportName(userNamedReport)
            let data = saveFilters!= undefined? _.cloneDeep(saveFilters) : {};
            data["abReportName"] = userNamedReport;
            data["workflowId"] = resid.match(/\d+/)[0];
            data["userId"] = constUserDir;
            
            let response = await DiscoverService.saveABReport(data, saveAuth);
            setSaveIsSubmited(true);
            setIsSaveEnabled(false);

            
        }
    }

    async function makeOverviewCall(filters, apikey) {
            let data = {};
            if(filters != null){
                data = _.cloneDeep(filters);
            }

            data["workflowId"] = resid.match(/\d+/)[0];
            data["userId"] = userdir;
        
        let response = await DiscoverService.makeOverviewCall(data, apikey);
        if (response) {

            setCollName(response.collName);

            if(response.filterDesc != null){
                setAuditFilters(response.filterDesc);
            }

            if(response.queryAliDocStats!= null){
                setQueryAliDocStats(response.queryAliDocStats);
            }

            if(response.auditTrail != null){
                setAuditTrail(response.auditTrail);
            };

            if(response.strategyParams != null){
                setStrategyParams(response.strategyParams);
            };

            if(response.databases != null){
                setDatabases(response.databases);
            };
            
        }
    }

    async function getAliDesc(filters, apikey) {
        let data = {};
        if(filters != null){
            data = _.cloneDeep(filters);
        }

        data["workflowId"] = resid.match(/\d+/)[0];
        data["userId"] = userdir;
    
    let response = await DiscoverService.getAlignmentDescriptions(data, apikey);
    if (response) {
        if(Object.keys(response.queryAliDesc).length != 0){
            setAlignmentDescriptions(response.queryAliDesc);
        }

    }
}

    async function makeCDRVariationsCall(filters, apikey) {
        let data = {};
            if(filters != null){
                data = _.cloneDeep(filters);
            }
        data["workflowId"] = resid.match(/\d+/)[0];
        data["userId"] = userdir;
        

        let response = await DiscoverService.makeCDRVariationsCall(data, apikey);
        if (response) {
            if(response.cdrVariationStats != null){
                setCdrCombinationsVariants(response.cdrVariationStats );
            };
        }
    }

    async function makeCDRCombinationsCall(filters, apikey, hclc) {

        var cdrCombos = ["lc_cdr1", "lc_cdr2", "lc_cdr3"]
        if(!hclc){
            cdrCombos = ["hc_cdr1", "hc_cdr2", "hc_cdr3"]
        }

        let data = {};
            if(filters != null){
                data = _.cloneDeep(filters);
            }
        data["cdrCombinations"]  = cdrCombos;
        data["workflowId"] = resid.match(/\d+/)[0];
        data["userId"] = userdir;
        
        let response = await DiscoverService.makeCDRCombinationsCall(data, apikey);
        if (response) {
            if(!hclc){
                if(response.cdrCombinations != null){
                    sortCombinations('hc', response.cdrCombinations);
                }
            }else{
                if(response.cdrCombinations != null){
                    sortCombinations('lc', response.cdrCombinations);
                }
            }
        }
    }

    async function matchingDocumentsChainsApi(filters, apikey, queryAliKeys) {
            let data = {};
            if(filters != null){
                data = _.cloneDeep(filters);
            }
            let chainsList=[];
            if (_.includes(queryAliKeys,"LC")) {
                chainsList.push("LC");
            }
            if (_.includes(queryAliKeys,"HC")) {
                chainsList.push("HC");
            }
            data["matchingDocuments"] = chainsList;
            data["workflowId"] = resid.match(/\d+/)[0];
            data["userId"] = userdir;

            let response = await DiscoverService.matchingDocuments(data, apikey);
            if (response && response.queryMatchingDocs) {
                setLcChain(response.queryMatchingDocs.LC);
                setHcLcChain(response.queryMatchingDocs.LCHC);
                setHcChain(response.queryMatchingDocs.HC);
            }
    }

    async function matchingDocumentsAllApi(filters, apikey, queryAliKeys) {
        let data = {};
            if(filters != null){
                data = _.cloneDeep(filters);
            }
        // TODO, incorrect parameters which leads to wrong result for filtered results
        //       it calls /jnrb/getQueries to get the queries in old AB report
        data["matchingDocuments"] = queryAliKeys;
        data["workflowId"] = resid.match(/\d+/)[0];
        data["userId"] = userdir;
        
        let response = await DiscoverService.matchingDocumentsAll(data, apikey);
        if (response) {
            if(response.matchingRecords!= null){
                setMatchingDocumentsAll(response.matchingRecords);
            }

        }
    }

    async function matchingDocumentsApi(filters, apikey, hclc) {
        let matchDoc = ["HC_CDR1", "HC_CDR2", "HC_CDR3"];
        if(!hclc){
            matchDoc = ["LC_CDR1", "LC_CDR2", "LC_CDR3"];
        }
        let data = {};
            if(filters != null){
                data = _.cloneDeep(filters);
            }
        data["matchingDocuments"] = matchDoc;
        data["workflowId"] = resid.match(/\d+/)[0];
        data["datauserId"] = userdir;
        
        let response = await DiscoverService.matchingDocuments(data, apikey);
        if (response) {
            
            if(hclc){
                if(response.queryMatchingDocs !=null){
                    setMatchingDocumentsHC(response.queryMatchingDocs);
                }
            }else{
                if(response.queryMatchingDocs !=null){
                    setMatchingDocumentsLC(response.queryMatchingDocs);
                }
            }

        }
    }

    async function matchingSequencesApi(filters, apikey, hclc) {
        let matchDoc = ["HC_CDR1", "HC_CDR2", "HC_CDR3"];
        if(!hclc){
            matchDoc = ["LC_CDR1", "LC_CDR2", "LC_CDR3"];
        }
        let data = {};
            if(filters != null){
                data = _.cloneDeep(filters);
            }
        data["matchingSequences"] = matchDoc;
        data["workflowId"] = resid.match(/\d+/)[0];
        data["datauserId"] = userdir;

        let response = await DiscoverService.matchingSequences(data, apikey);
        if (response) {
            
            if(hclc){
                if(response.queryMatchingSeqs !=null){
                    setMatchingSequencesHC(response.queryMatchingSeqs);
                }
            }else{
                if(response.queryMatchingSeqs !=null){
                    setMatchingSequencesLC(response.queryMatchingSeqs);
                }
            }

        }
    }

    function sortCombinations( HL, cdrCombinations){

        let cdrArray = [{},{},{},{}];

        for (const [key, value] of Object.entries(cdrCombinations)) {
            
            let count = 0;
            if(key.charAt(0).toLowerCase() == "x"){count++;}
            if(key.charAt(1).toLowerCase() == "x"){count++;}
            if(key.charAt(2).toLowerCase() == "x"){count++;}

            cdrArray[count][key] = value;
        }

        if(HL.toLowerCase() == "lc"){
            setCdrCombinationsLC(cdrArray);
        }else{
            setCdrCombinationsHC(cdrArray);

        }
    }

    const changeOpenSaveState = () =>{
        setOpenSaveUI(!openSaveUI);
    }

    function saveReportName(event){

        if(event != null){
            if(event["target"]["value"] != null || event["target"]["value"] == ""){
                setUserNamedReport(event["target"]["value"])
            }
        }

    }

    async function ExportExcel(LightHeavy, c1, c2, c3) {


        var state={};
        var data={};

        if(c1==undefined || JSON.stringify(c1) == '{}')
        {c1={"":""};}
        if(c2==undefined || JSON.stringify(c2) == '{}')
        {c2={"":""};}
        if(c3==undefined || JSON.stringify(c3) == '{}')
        {c3={"":""};}

        if(LightHeavy==='HEAVY'){
            data.data= {
                'HC_CDR1': c1[Object.keys(c1)[0]],
                'HC_CDR2': c2[Object.keys(c2)[0]],
                'HC_CDR3': c3[Object.keys(c3)[0]]
            }
        }else{
            data.data = {
                'LC_CDR1': c1[Object.keys(c1)[0]],
                'LC_CDR2': c2[Object.keys(c2)[0]],
                'LC_CDR3': c3[Object.keys(c3)[0]]
            }

        }

        if(JSON.stringify(data) === '{}')
        {
            toast.error('Cannot export empty result set');
            return;
        }

        var alertMessage = "";
        if (c1.length>process.env.REACT_APP_EXPORT_LIMIT
            || c2.length>process.env.REACT_APP_EXPORT_LIMIT
            || c3.length>process.env.REACT_APP_EXPORT_LIMIT) {
            alertMessage = "Maximum data export limit reached. 20,000 sequences will be downloaded.";
        }

        let format ='EXCEL'
        let fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        let fileExtension = '.xlsx';

        var thisData = {
            workflowId: resid.match(/\d+/)[0], // database ID ("GQ-classic" compatible)
            userId: constUserDir,
            groupCriteria: "variant",
            pnsPerPage: '',
            alignmentsPerPage: '',
            sortCriterias: [],
            pageNum: 1,
            title: auditTrail.Title,
            vmExport: false,
            algorithm: strategyParams.algo === 'kerr'? "GenePAST": "BLAST",
            exportType: "EXCEL",
            filters: encodeURIComponent(JSON.stringify(saveFilters)).replaceAll('%','~'),
            workflowUrl: window.location.href,
            columnsToDisplay: Object.keys(data.data),
            groupedColumns: Object.keys(data.data),
            detailedColumns: [],
            gqAlignments: {},
            gqAlignmentSummaries: {},
            variants: data.data,
            newLink: true
        }

        let response = await DiscoverService.exportABVariants(thisData, saveAuth)
    
        var fileName = document.title + fileExtension;
        var blob = new Blob([response], {type: fileType});
       
        var fileURL = URL.createObjectURL(blob);
        var link = document.createElement("a");

        link.setAttribute("href", fileURL);
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
    

	return (
		<div className="center">
            {showLoader &&
                <div className={classes.loader}>
                    <div className={classes.loaderPosition}>
                        <div className="spinner-container">
                            <div className="spinner-socket">
                                <div className="spinner-hex spinner-tile-1">
                                    <div className="spinner-brick rotate-0"></div>
                                    <div className="spinner-brick rotate-1"></div>
                                    <div className="spinner-brick rotate-2"></div>
                                </div>
                                <div className="spinner-hex spinner-tile-2">
                                    <div className="spinner-brick rotate-0"></div>
                                    <div className="spinner-brick rotate-1"></div>
                                    <div className="spinner-brick rotate-2"></div>
                                </div>
                                <div className="spinner-hex spinner-tile-3 spinner-row-2">
                                    <div className="spinner-brick rotate-0"></div>
                                    <div className="spinner-brick rotate-1"></div>
                                    <div className="spinner-brick rotate-2"></div>
                                </div>
                                <div className="spinner-hex spinner-tile-4 spinner-row-2">
                                    <div className="spinner-brick rotate-0"></div>
                                    <div className="spinner-brick rotate-1"></div>
                                    <div className="spinner-brick rotate-2"></div>
                                </div>
                                <div className="spinner-hex spinner-tile-5 spinner-row-2">
                                    <div className="spinner-brick rotate-0"></div>
                                    <div className="spinner-brick rotate-1"></div>
                                    <div className="spinner-brick rotate-2"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            
            <div class="clearfix tableCaption large-font">
                <div class="pull-right padding15">
                <a class="large-font" onClick={()=>{Print('printable')}}>Print</a><span> </span>
                {isSaveEnabled == true && 
                    <a class="large-font" onClick={changeOpenSaveState}>Save</a>
                }
                </div>
            </div>

            <div id="printable">

            <style>
        {`
        .grey{
            color: grey !important;
        }

        .red {
            color: red !important
        }

        .tableMatchAll{
            position: relative;
            margin-left: auto;
            margin-right: auto;
            width: 80%;
            height: 80%;
        }

        .imgMatchAllContainer{
            position: relative;
            display: block;
            margin-left: auto;
            margin-right: auto;
            width: 160px;
            height: 160px;
        }

        .imgMatchAll0{
            position: absolute;
            top: 60px;
            left: 0px;
            transform: translate(-50%, -50%);
            transform: rotate(0deg);
            width: 80px;
            height: 40px;
            z-index: 49;
        }

        .imgMatchAll45{
            position: absolute;
            top:30px;
            left: 10px;
            transform: translate(-50%, -50%);
            transform: rotate(45deg);
            width: 80px;
            height: 40px;
        }

        .imgMatchAll90{
            position: absolute;
            top: 20px;
            left: 40px;
            transform: translate(-50%, -50%);
            transform: rotate(90deg);
            width: 80px;
            height: 40px;
            z-index: 50;
        }

        .imgMatchAll135{
            position: absolute;
            top: 30px;
            left: 70px;
            transform: translate(-50%, -50%);
            transform: rotate(135deg);
            width: 80px;
            height: 40px;
        }

        .imgMatchAll180{
            position: absolute;
            top: 62px;
            left: 80px;
            transform: translate(-50%, -50%);
            transform: rotate(180deg);
            width: 80px;
            height: 40px;
            z-index: 49;
        }

        .imgMatchAll225{
            position: absolute;
            top: 90px;
            left: 70px;
            transform: translate(-50%, -50%);
            transform: rotate(225deg);
            width: 80px;
            height: 40px;
        }

        .imgMatchAll270{
            position: absolute;
            top: 100px;
            left: 40px;
            transform: translate(-50%, -50%);
            transform: rotate(270deg);
            width: 80px;
            height: 40px;
            z-index: 50;
        }

        .imgMatchAll315{
            position: absolute;
            top: 55%;
            left: 10px;
            transform: translate(-50%, -50%);
            transform: rotate(315deg);
            width: 80px;
            height: 40px;
        }

        .centerCircle{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 50px;
            height: 50px;
            z-index: 60;
        }

        .grey{
            -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
            filter: grayscale(100%);
        }

        .font13pt{
            font-size: 13pt !important;
            font-weight: bold;
            color: #777777;
        }

        .bolding{
            font-weight: bold;
        }

        .f15pt{
        font-size: 15pt !important;
        }

        .variantFix{
            padding: 5px;
        }

        .tableMatchOneCdr{
            position: relative;
            margin-left: auto;
            margin-right: auto;
            width: 100%;
            height: auto;

        }

        .imgMatchOneCdr{
            margin-left: 3%;
            margin-right: 3%;
            width: 94%;
            height: auto;
        }

        .AbTriple0{
            position: absolute;
            top: 20%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        .AbTriple1{
            position: absolute;
            top: 45%;
            left: 30%;
            transform: translate(-50%, -50%);
        }

        .AbTriple2{
            position: absolute;
            top: 54%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        .AbTriple3{
            position: absolute;
            top: 45%;
            left: 70%;
            transform: translate(-50%, -50%);
        }

        .AbTriple4{
            position: absolute;
            top: 70%;
            left: 21%;
            transform: translate(-50%, -50%);
        }

        .AbTriple5{
            position: absolute;
            top: 72%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        .AbTriple6{
            position: absolute;
            top: 70%;
            left: 78%;
            transform: translate(-50%, -50%);
        }

        .tableMatchOne{
            position: relative;
            margin-left: auto;
            margin-right: auto;
            width: 80%;
            height: 80%;

        }

        .imgMatchOne{
            position: relative;
            display: block;
            margin-left: auto;
            margin-right: auto;
            height: 160px;
        }

        .textL{
            position: absolute;
            top: 48%;
            left: 32%;
            transform: translate(-50%, -50%);
        }

        .textM{
            position: absolute;
            top: 48%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        .textR{
            position: absolute;
            top: 48%;
            left: 66%;
            transform: translate(-50%, -50%);
        }

        .hcSquare1{
            position: absolute;
            top: 55%;
            left: 50%;
            border-radius: 3px;
            background: #D9EBFC;
            border: 1px solid #9BC0E7;
            padding: 0px;
            width: 50px;
            height: 20px;
            transform: translate(-50%, -50%);
        }

        .hcSquare2{
            position: absolute;
            top: 55%;
            left: 50%;
            border-radius: 3px;
            background: #9BC0E7;
            border: 1px solid #9BC0E7;
            padding: 0px;
            width: 50px;
            height: 20px;
            transform: translate(-50%, -50%);
        }

        .matchContainer{
            position: relative;
        }

        .line {
            border: 1px solid grey;
            position: absolute;
            width:200%;
            margin-top: auto;
            margin-bottom: auto;
            left: 50%;
            top: 50%;
            z-index: -100;
            transform: translate(-50%, -50%);
        }

        .lcSquare0{

        }

        .x{
            position: absolute;
            text-align: center;
            margin-top: auto;
            margin-bottom: auto;
            transform: translate(0%, 0%);
            width: 100%;
        }

        .lcSquare1{
            position: absolute;
            top: 50%;
            left: 50%;
            border-radius: 3px;
            background: #FBDDC9;
            border: 1px solid #F4B17E;
            padding: 0px;
            width: 50px;
            height: 20px;
            transform: translate(-50%, -50%);
        }

        .lcSquare2{
            position: absolute;
            top: 50%;
            left: 50%;
            border-radius: 3px;
            background: #F4B17E;
            border: 1px solid #F4B17E;
            padding: 0px;
            width: 50px;
            height: 20px;
            transform: translate(-50%, -50%);
        }

        .borderR{
            border-right: 1px solid lightgrey;
        }

        .spacing{
            padding-top: 5px;
            padding-right: 20%;
        }

        .colorBlack{
            color:black
        }

        .dark{
            color: black;
            font-weight: bold;
            font-family: "Courier New", monospace !important;
        }

        .light{
            color: grey;
            font-family: "Courier New", monospace !important;
        }

        .grey{
            color: grey !important;
        }

        .red{
            color: red;
        }

        a{
            cursor: pointer;
        }

        .monoFont{
            font-family: "Courier New", monospace !important;
            
        }

        .widthFix{
            max-width: 500px;
            width: 500px;
        }

        .disabled {
            cursor: not-allowed;
            pointer-events: none;
            color: grey;
        }

        .tdMid{
            vertical-align: middle;
        }

        .hSquare{
            float: left;
            border-radius: 5px;
            background: #D9EBFC;
            border: 1px solid #9BC0E7;
            width: 20px;
            height: 15px;
        }

        .lSquare{
            float: left;
            border-radius: 5px;
            background: #FBDDC9;
            border: 1px solid #F4B17E;
            width: 20px;
            height: 15px;
        }

        .textFlat{
            font-size: 13pt;
            white-space: nowrap;
            overflow-wrap: break-word;
            word-break: break-word;
        }

        .overlaps{
            font-size: 13pt !important;
            font-weight: bold;
        }

        .textFlatCenter{
            font-size: 14px;
            white-space: nowrap;
            overflow-wrap: break-word;
            word-break: break-word;
            text-align: center;
        }

        .textFlatLeft{
            font-size: 14px;
            overflow-wrap: break-word;
            word-break: break-word;
            text-align: left;
        }

        .wsnw{
            white-space: nowrap;

        }


        .width990{
            width: 990px;
        }
        .tableFrame{
            border-radius: 10px;
            border: 2px solid #D3D3D3;
            padding: 5px 5px 5px 5px !important;
            margin: 5px 5px 5px 5px !important;
        }

        .tableItem{
            vertical-align: center;
            width: auto;
            height: auto;
            margin: 1px 1px 1px 1px;
            padding: 5px 5px 5px 5px;

        }

        .marginAll5{
            margin: 5px 5px 5px 5px;
        }


        .img2{
            margin-left: 5%;
            margin-right: 5%;
            width: 90%;
        }

        .pCenter{
            text-align: center;
        }

        .tableMarginWidth{
            margin-left: 5%;
            margin-right: 5%;
            width: 90%;

        }

        .tableCaption{
            padding-bottom: 15px;
        }

        .bottomText{
            font-size: 8px;
            padding-top: 10px;
            padding-bottom: 10px;
            width: 60%;
            margin-left: auto;
            margin-right: auto;
        }

        .tabler {
            border-collapse: collapse;
            border-style: hidden;
            margin-right: auto;
            margin-left: auto;

        }

        .tablerb{
            border: 1px solid lightgrey;
            padding: 1px 3px 1px 3px;
        }
        .tablerbAudit{
            border: 1px solid lightgrey;
            text-align: left;
            padding: 1px 3px 1px 3px;
        }
        .overtableWidth{
            width: 1024px;
        }

        .center{
            width: 1024px;
            margin-left: auto;
            margin-right: auto;
            
        }

        .centerWording{
            text-align: center;
        }

        .padding15{
            padding-left: 15px;
            padding-right: 15px;
        }

        .paddingBottom15{
            padding-bottom: 15;
        }

        .paddingTitle{
            margin-top: 13px;
            margin-bottom: 28px;
        }


        .font14{
            font-size: 14px;
            color: #777777;
        }

        .color777{
            color: #777777;
        }

        .font14noColor{
            font-size: 14px;
        }

        .tableMatchAll{
            position: relative;
            margin-left: auto;
            margin-right: auto;
            width: 80%;
            height: 80%;
        }

        .imgMatchAllContainer{
            position: relative;
            display: block;
            margin-left: auto;
            margin-right: auto;
            width: 160px;
            height: 160px;
        }

        .imgMatchAll0{
            position: absolute;
            top: 60px;
            left: 0px;
            transform: translate(-50%, -50%);
            transform: rotate(0deg);
            width: 80px;
            height: 40px;
            z-index: 49;
        }

        .imgMatchAll45{
            position: absolute;
            top:30px;
            left: 10px;
            transform: translate(-50%, -50%);
            transform: rotate(45deg);
            width: 80px;
            height: 40px;
        }

        .imgMatchAll90{
            position: absolute;
            top: 20px;
            left: 40px;
            transform: translate(-50%, -50%);
            transform: rotate(90deg);
            width: 80px;
            height: 40px;
            z-index: 50;
        }

        .imgMatchAll135{
            position: absolute;
            top: 30px;
            left: 70px;
            transform: translate(-50%, -50%);
            transform: rotate(135deg);
            width: 80px;
            height: 40px;
        }

        .imgMatchAll180{
            position: absolute;
            top: 62px;
            left: 80px;
            transform: translate(-50%, -50%);
            transform: rotate(180deg);
            width: 80px;
            height: 40px;
            z-index: 49;
        }

        .imgMatchAll225{
            position: absolute;
            top: 90px;
            left: 70px;
            transform: translate(-50%, -50%);
            transform: rotate(225deg);
            width: 80px;
            height: 40px;
        }

        .imgMatchAll270{
            position: absolute;
            top: 100px;
            left: 40px;
            transform: translate(-50%, -50%);
            transform: rotate(270deg);
            width: 80px;
            height: 40px;
            z-index: 50;
        }

        .imgMatchAll315{
            position: absolute;
            top: 55%;
            left: 10px;
            transform: translate(-50%, -50%);
            transform: rotate(315deg);
            width: 80px;
            height: 40px;
        }

        .centerCircle{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 50px;
            height: 50px;
            z-index: 60;
        }
        .centerText{
            position: absolute;
            font-size: 14pt;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 70;
        }

        .grey{
            -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
            filter: grayscale(100%);
        }

        .LcCdrUnderStyle{
            border-bottom: 1px solid lightgrey; 
            text-align: center; 
            padding: 1px 3px 1px 3px; 
            width: "auto"
        }

        .LcWordSytle{
            width: 90%; 
            margin-left: 5%; 
            margin-right: 5%; 
            font-size: 12px;
        }

        .LcLineStyle{
            font-size: 12px; 
            line-height: .5;
        }

        .LcBorder{
            border-bottom: 1px solid lightgrey; 
            width: 20%; 
            height: 30px
        }

        .LcBoder2{
            border-bottom: 1px solid lightgrey;
        }

        .MarginBottom15{
            margin-bottom: 15px
        }

        .Margin0{
            margin: 0; 
        }

        .ChainWidth{
            width: 30%; 
            vertical-align: top;
        }

        .ChainPadding{
            padding-bottom: 5px; padding-left: 5px; vertical-align: top; width: 60%
        }

        .ChainInline{
            width: 100%; 
            text-align: Left; 
            display: inline
        }

        .ChainMargin{
            padding-right: 2px; 
            height:18px; 
            margin-top: 4px; 
            margin-bottom: 0px
        }

        .VirtAlign{
            vertical-align: top;
        }

        .ChainPadding{
            padding-left: 7px; padding-bottom:5px; width: 50%; vertical-align: top
        }

        .ChainMarginLeft{
            margin-left: 40%; margin-top: 0%; margin-bottom: auto;
        }

        .ChainWH30{
            width: 30%; height: 99%;
        }

        .auditWM{
            width: 95%; margin: 15px
        }

        .textAlignLeft{
            text-align: left
        }
        .margin15{
            margin: 0px 15px 0px 15px;
        }

        .padding5{
            padding-left: 5px;
            padding-right: 5px;
        }

        .margL5{
            margin-left: 5px;
        }

        .margB0{
            margin-bottom: 0;
        }

        .mp0{
            margin: 0;
            padding: 0;
        }
        `}
        </style>
            
                <div class="tableItem">
                    <OverviewTable alignmentDescriptions={alignmentDescriptions} queryAliDocStatsProp={queryAliDocStats} collName={collName}></OverviewTable>
                </div>
                <table width={"100%"}>
                    
                    <tr>
                        <td class="tableItem">
                            <DocMatchAll queryAliDocStats={queryAliDocStats} matchingDocumentsAll={matchingDocumentsAll} data={wfData} collName={collName}></DocMatchAll>
                        </td>
                        <td class="tableItem">
                            <DocMatchOne
                                lcChain = {lcChain}
                                hclcChain = {hclcChain}
                                hcChain = {hcChain}
                                collName={collName}
                            ></DocMatchOne>
                        </td>
                    </tr>
                    <tr>
                        <td class="tableItem">
                            <DocSeqMatchOneCdr
                                LcCDR={matchingDocumentsLC}
                                HcCDR={matchingDocumentsHC}
                                Switch={true}  collName={collName}
                            ></DocSeqMatchOneCdr>
                        </td>
                        <td class="tableItem">
                            <DocSeqMatchOneCdr
                                LcCDR={matchingSequencesLC}
                                HcCDR={matchingSequencesHC}
                                Switch={false}  collName={collName}
                            ></DocSeqMatchOneCdr>
                        </td>

                    </tr>
                    {misMap.map((value, index) => {
                        return(
                            <tr key={index}>
                                <td class="tableItem"><CdrMatches Switch={true} CdrIndex={index} CdrCombos={cdrCombinationsLC[index]} collName={collName}></CdrMatches></td>
                                <td class="tableItem"><CdrMatches Switch={false} CdrIndex={index} CdrCombos={cdrCombinationsHC[index]} collName={collName}></CdrMatches></td>
                            </tr>
                        );
                    })
                }
                    
                </table>
                

                <div width={"100%"}>
                    <div className="variantFix">
                        <LightHeavyChainVariants cdr1={cdrCombinationsVariants.lc_cdr1} cdr2={cdrCombinationsVariants.lc_cdr2} cdr3={cdrCombinationsVariants.lc_cdr3} ExportExcel={(LightHeavy, cdr1, cdr2, cdr3)=> ExportExcel(LightHeavy, cdr1, cdr2, cdr3)} collName={collName}></LightHeavyChainVariants>
                    </div>
                    <div className="variantFix">
                        <LightHeavyChainVariants  Switch={true} cdr1={cdrCombinationsVariants.hc_cdr1} cdr2={cdrCombinationsVariants.hc_cdr2} cdr3={cdrCombinationsVariants.hc_cdr3} ExportExcel={(LightHeavy, cdr1, cdr2, cdr3)=> ExportExcel(LightHeavy, cdr1, cdr2, cdr3)} collName={collName}></LightHeavyChainVariants>
                    </div>
                    <div className="variantFix">
                        <AuditTrail   AdTrail={auditTrail} strategyParams={strategyParams} queryAliDocStats={queryAliDocStats} databases={databases} filters={auditFilters}></AuditTrail>
                    </div>
                </div>

            <h3 class="textFlatLeft"></h3>


            {openSaveUI == true &&
            <div>
                <div className="popupBackdrop" onClick={changeOpenSaveState}> 
                </div>
                <div className="saveAbPopup">
                    <div>
                        <Link href="#" className={"p-2 appTextColor"}><CloseIcon onClick={changeOpenSaveState} /></Link>
                    </div>
                    {!saveIsSubmited &&
                    <div className="ABSavejumbotron">
                        <br></br>
                            <span className="center historyPopupHeader tableCaption">Antibody Report</span>
                        <br></br>
                        <input
                            id="abreportname"
                            onChange={(event)=>saveReportName(event)}
                            class="abInput"
                            type="text"
                            width={"100%"} 
                            placeholder="Name your Report"
                            autoComplete="off" spellCheck="false"
                        >
                        </input>

                        <div className="ABSaveBuffer">
                            <Button className={"cancelButtonClass "} onClick={()=>{changeOpenSaveState();}}>Cancel</Button>
                            <span className="ml-1"></span>
                            <Button className={"submitButtonClass"} onClick={()=>{saveABReport();}}>Save</Button>
                        </div>
                    </div>
                    }
                    {saveIsSubmited &&
                    <div className="ABSaveSubmittedJumbotron">
                        <br></br>
                            <span className="center historyPopupHeader tableCaption">Your Report has been saved...</span>
                        <br></br>
                        <br></br>
                        <div className="ABSaveBuffer">
                            <Button className={"cancelButtonClass"} onClick={changeOpenSaveState}>Ok</Button>
                        </div>
                    </div>
                    }
                </div>
            </div>	
            }
            </div>
        </div>
    );
}

export default AntiBodyReport;
