import { useEffect } from 'react';
import { useTranslation } from "react-i18next";
import moment from 'moment';
import ReactHtmlParser from 'react-html-parser';
import { Link } from 'react-router-dom';


function FullTextResultsV2(props) {

    const { data, keywords } = props;
    const { t } = useTranslation("common");

    function showString(str) {
        let getLength = str.length;
        if (getLength > 500) {
            str = str.substring(0, 500) + ' ...';
        }
        return str;

    }

    function openLink(pubid){
        let newWindow = window.open("#/fulldocview/" + encodeURIComponent(pubid))
        newWindow.keywords = keywords;
    }

    return (
        <div className="fixWidth2">
            {
                data && data.docs && data.docs.length > 0 && <div>

                    {data.docs.map((item, index) => {
                        return (
                            <div key={index}>
                                <p className={"subHeading"}>
                                    <span onClick={()=>{openLink(item.pubid)}} className={"appLink"} target="_blank">{ ReactHtmlParser(showString(item["patentid-hl"]? item["patentid-hl"]: item.pubid))}</span>
                                    <span> - </span><span>{ReactHtmlParser(showString(item["ttl_en_nostem-hl"]? item["ttl_en_nostem-hl"][0] : item.title))}</span>
                                </p>
                                <p className={"bodyTextFTResult"}>
                                    <span className={"heavyFont"}>{t('publicationData')}</span>
                                    <span>{moment(item.pubdate).format('DD-MMM-YYYY')}</span>
                                </p>
                                {item['desc_en_nostem-hl'] && item['desc_en_nostem-hl'].length > 0 && <p className={"bodyTextFTResult"}>
                                    <span className={"heavyFont"}>{t("description")} </span>
                                    <span>{ReactHtmlParser(showString(item['desc_en_nostem-hl'][0]))}</span>
                                </p>}
                                {item['clm_en_nostem-hl'] && item['clm_en_nostem-hl'].length > 0 && <p className={"bodyTextFTResult"}>
                                    <span className={"heavyFont"}>{t("claims")}</span>
                                    <span>{ReactHtmlParser(showString(item['clm_en_nostem-hl'][0]))}</span>
                                </p>}
                                {item['abst_en_nostem-hl'] && item['abst_en_nostem-hl'].length > 0 && <p className={"bodyTextFTResult"}>
                                    <span className={"heavyFont"}>{t("abstract")}</span>
                                    <span>{ReactHtmlParser(showString(item['abst_en_nostem-hl'][0]))}</span>
                                </p>}
                                <hr />
                            </div>
                        );
                    })
                    }
                </div>
            }

        </div>


    )
}

export default FullTextResultsV2;
