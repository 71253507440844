import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import Link from '@material-ui/core/Link';
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
	modalClassConDSI: {
		position: 'absolute',
		width: '96%',
		padding: '35px 15px 0px',
		right: 'auto',
		bottom: 'auto',
	},
	modalBoxContent: {
		maxHeight: '600px',
	},
	modalText: {
		position: 'absolute',
		top: '10px',
		right: '10px'
	},
}));

const DiscoverSaveResultsSucModal = props => {
	const { t, i18n } = useTranslation("common");
	const classes = useStyles();

	return (
		<Modal
			size="lg"
			aria-labelledby="contained-modal-title-vcente"
			centered
			show={props.showModal}
			contentClassName={classes.modalClassConDSI}
		>
			<Link href="#" onClick={(e) => e.preventDefault()} className={classes.modalText}>
				<CloseIcon onClick={props.closeModal} />
			</Link>
			<div className="jumbotron appTextColor">
				<h2>{props.downloadType === 'FullTextV2' ? t("exportToFTSucc") : t("saveResultAsSucc")}</h2>
				<span>
					{props.downloadType === 'FullTextV2' ? t("exportToFTSuccBody") : t("saveResultAsSuccBody")}<a href={props.saveFiltedResultMsg} target='_blank' rel="noreferrer">here</a>
				</span>
				<br></br>
				<div>
					<button onClick={props.closeModal} className="pull-right large-button gqblue">OK</button>
				</div>
			</div>
		</Modal>
	);
}

export default DiscoverSaveResultsSucModal;
