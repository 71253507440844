import React, { useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles((theme) => ({
    modalHeader: {
        fontFamily: 'verdana, helvetica',
        fontSize: '12px',
        width: '73%'
    }
}));



const ResultInformationModal = (props) => {

    const classes = useStyles();

    useEffect(() => {
    }, []);

    function onCancel(props) {
        props.closeModal();
    }
    function clickOnOk(props) {
        props.closeModal();
    }

    return (
        <Modal
            id='modalCss'
            aria-labelledby="contained-modal-title-vcente"
            centered
            size='lg'
            show={props.showModal}
            contentClassName={classes.modalHeader}
        >
            <div>
                <div className="titlebar">
                    <span>Result Information</span>
                    <Link href="#" onClick={(e) => e.preventDefault()} className="pull-right">
                        <CloseIcon style={{ fill: '#b5b5b5' }} onClick={() => onCancel(props)} />
                    </Link>
                </div>
                <div style={{ borderTop: '1px solid #b5b5b5', padding:'12px' }}>
                    <br />
                    <p style={{color: 'black'}}>4 Databases Searched:</p>
                    <table>
                        <tbody>
                            <tr>
                                <th width='332px'>Database</th>
                                <th width='70px'>Version</th>
                                <th>Release Date</th>
                            </tr>
                            <tr>
                                <td >
                                    <li>GQ Pat GoldPlus Nucleotide - Patent sequences</li>
                                </td>
                                <td >20170405</td>
                                <td >2017-04-05 10:05:58</td>
                            </tr>
                            <tr>
                                <td >
                                    <li>Thomson-Reuters Geneseq - Nucleotide sequences</li>
                                </td>
                                <td >20170518</td>
                                <td >2017-05-08 06:32:00</td>
                            </tr>
                            <tr>
                                <td >
                                    <li>GQ Pat GoldPlus Protein - Patent sequences</li>
                                </td>
                                <td >20170405</td>
                                <td >2017-04-05 11:20:58</td>
                            </tr>
                            <tr>
                                <td >
                                    <li>Thomson-Reuters Geneseq - Protein sequences</li>
                                </td>
                                <td >20170518</td>
                                <td >2017-05-08 06:32:01</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div align='right'>
                    <button className='okButton DMTBtn' onClick={() => clickOnOk(props)}>Ok</button>
                </div>
            </div>
        </Modal>
    );
}

export default ResultInformationModal;