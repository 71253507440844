import './App.css';
import React, { useState, useEffect } from 'react';

import Routes from './routes/index';
import 'bootstrap/dist/css/bootstrap.min.css';
import PubSub from 'pubsub-js';
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from 'react-redux';
import { getUserServerInfo } from './reducers/slice/userServerDataSlice';
import 'reactjs-popup/dist/index.css';


const useStyles = makeStyles((theme) => ({
 
loaderHide: {
  display: 'none'
},

loader :{
  position: 'fixed',
  top: '0',
  left: '0',
  width: '100%',
  height:' 100%',
  zIndex: '999999',
  backgroundColor: 'rgba(0, 0, 0, 0.6)',
  display: 'block',
  
  },
  loaderPosition:{
  marginLeft: '602px',
    marginTop: '300px',
  }

}));

function App() {
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    const isUserLogin = localStorage.getItem('isLoggedIn');
    //if(isUserLogin) {
    dispatch(getUserServerInfo());
    //dispatch(getPageCount());
    //}
    async function fetchLoader() {
      const loaderResponse = await PubSub.subscribe('msg', (msg, data) => {
        setLoading(data)
      });
     
      
    }
    fetchLoader()
  }, [])
  return (
    <div> 
     <Routes />
    {loading ?
      <div className={classes.loader}>
      <div className={classes.loaderPosition}>
  <div className="spinner-container">
    <div className="spinner-socket">
        <div className="spinner-hex spinner-tile-1">
            <div className="spinner-brick rotate-0"></div>
            <div className="spinner-brick rotate-1"></div>
            <div className="spinner-brick rotate-2"></div>
        </div>
        <div className="spinner-hex spinner-tile-2">
            <div className="spinner-brick rotate-0"></div>
            <div className="spinner-brick rotate-1"></div>
            <div className="spinner-brick rotate-2"></div>
        </div>
        <div className="spinner-hex spinner-tile-3 spinner-row-2">
            <div className="spinner-brick rotate-0"></div>
            <div className="spinner-brick rotate-1"></div>
            <div className="spinner-brick rotate-2"></div>
        </div>
        <div className="spinner-hex spinner-tile-4 spinner-row-2">
            <div className="spinner-brick rotate-0"></div>
            <div className="spinner-brick rotate-1"></div>
            <div className="spinner-brick rotate-2"></div>
        </div>
        <div className="spinner-hex spinner-tile-5 spinner-row-2">
            <div className="spinner-brick rotate-0"></div>
            <div className="spinner-brick rotate-1"></div>
            <div className="spinner-brick rotate-2"></div>
        </div>
    </div>
  </div>
      </div>
      
      </div>
      :
      <div className={classes.loaderHide}>
      <div className={classes.loaderPosition}>
      <div className="spinner-container">
    <div className="spinner-socket">
        <div className="spinner-hex spinner-tile-1">
            <div className="spinner-brick rotate-0"></div>
            <div className="spinner-brick rotate-1"></div>
            <div className="spinner-brick rotate-2"></div>
        </div>
        <div className="spinner-hex spinner-tile-2">
            <div className="spinner-brick rotate-0"></div>
            <div className="spinner-brick rotate-1"></div>
            <div className="spinner-brick rotate-2"></div>
        </div>
        <div className="spinner-hex spinner-tile-3 spinner-row-2">
            <div className="spinner-brick rotate-0"></div>
            <div className="spinner-brick rotate-1"></div>
            <div className="spinner-brick rotate-2"></div>
        </div>
        <div className="spinner-hex spinner-tile-4 spinner-row-2">
            <div className="spinner-brick rotate-0"></div>
            <div className="spinner-brick rotate-1"></div>
            <div className="spinner-brick rotate-2"></div>
        </div>
        <div className="spinner-hex spinner-tile-5 spinner-row-2">
            <div className="spinner-brick rotate-0"></div>
            <div className="spinner-brick rotate-1"></div>
            <div className="spinner-brick rotate-2"></div>
        </div>
    </div>
  </div>
      </div>
      </div>
    }
    </div>
   
  );
}

export default App;
