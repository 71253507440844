import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import CloseIcon from '@material-ui/icons/Close';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import { url } from '../../reducers/url';
import PubSub from 'pubsub-js';
import { get } from  "../../helpers/fetchServicesMethods";

const useStyles = makeStyles((theme) => ({
    modalHeader:{
        width:'68% !important',
    }
}));

function showLoader() {
    PubSub.publish('msg', true);
}

function hideLoader() {
    PubSub.publish('msg', false);
}


async function deleteAlert(ids, props, history) {
    try {
        let deleteAlerts = url.deleteAlerts
        deleteAlerts = deleteAlerts.replace('**', ids);
        let urlParam = deleteAlerts;
        showLoader();
        return await get(urlParam, history)
            .then((response) => {
                hideLoader();
                return response;
            })
            .catch((error) => {
                console.log("error::", error);
            });
    } catch (error) {
        hideLoader();
        console.error(error);
    }
}


const AlertsDeleteModal = (props )=> {

    const classes = useStyles();
    const [deleteMsg, setDeleteMsg] = useState([
        "You have not selected any alerts to delete."
    ]);
    const [deleteMsg2, setDeleteMsg2] = useState([
        "Please make your selection and try again."
    ]);
    const [userData, setUserData] = useState(props.alertResult);
    const [cancelBtn, setCancelBtn] = useState(false);


    useEffect(() => {
        const alertsData = userData;
        textToDisplay(alertsData);
    }, [userData]);

    function textToDisplay(alertsData) {
        const selectedItems = alertsData.filter((item) => item.checked);
        const deletableItems =selectedItems.filter((item) => item._is_writable);
        if((selectedItems.length===0) && (deletableItems.length===0)){
            setDeleteMsg("You have not selected any alerts to delete.");
            setDeleteMsg2("Please make your selection and try again.");
        }
        else if ((selectedItems.length===1) && (deletableItems.length > 0)) {
            setDeleteMsg2('');
            setDeleteMsg('Are you sure you want to delete this alert?');
            setCancelBtn(true);
        }
        else if((selectedItems.length >1)&& (deletableItems.length > 0) ){
            setDeleteMsg2('');
            setDeleteMsg('Are you sure you want to delete the selected alerts?');
            setCancelBtn(true);
        }
    }

    function onOkButton(props) {
        const selectedItems = props.alertResult.filter((item) => item.checked);
        const unselectedItems = props.alertResult.filter((item) => !item.checked);
        const deletableItems = selectedItems.filter((item) => item._is_writable);    
        if (deletableItems.length > 0) {
            if(deletableItems.length === 1){
                deleteAlert(deletableItems[0].id, props);
            }
            else{
                deletableItems.forEach((item,index) => {
                    deleteAlert(item.id,props)
                  });
            }
            props.afterCloseModal(unselectedItems);
            console.log('Deleted selected items');
            setUserData(unselectedItems);
        } else {
            return props.closeDeletePopup(false);
        }
    }

    function onCancel(props){
        props.closeDeletePopup(false);
    }
    
    return (
        <Modal
            id = 'modalCss'
            aria-labelledby="contained-modal-title-vcente"
            centered
            show={props.showModal}
            contentClassName= {classes.modalHeader}
        >
            <div>
                <div className="titlebar">
                    <span>Delete</span>
                    <Link href="#" onClick={(e) => e.preventDefault()} className="pull-right">
                        <CloseIcon style = {{ fill : '#b5b5b5'}} onClick={() => onCancel(props)} />
                    </Link>
                </div>
                <div style= {{ borderTop: '1px solid #b5b5b5'}}>
                    <p className='deletedialogtextteam' >{deleteMsg}
                        <br/>
                        {deleteMsg2}
                    </p>
                </div>
                <div style={{float: 'right'}}>
                    <button className='okButton DMTBtn' onClick={() => onOkButton(props)} >OK</button>
                    {cancelBtn && <button className='DMTBtn' onClick={() => onCancel(props)}>Cancel</button>}
                </div>
            </div>
        </Modal>
    );
}

export default AlertsDeleteModal;