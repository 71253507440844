import _ from "lodash";
import ChartService from "./ChartService";
import { get, post } from "../../helpers/fetchServicesMethods";
import Log from "./UILog";

//export default class FieldConfig {
// takes care of everything to do with display configuration
let pref_context = 'result_rb2';
let alertMsg = '';
let showAlert = false;
let singleAlignment;
let forAb;
let forAlert;
let forVm;

// database translation that should connect to config file GQ instead
const databases = {
    GQPAT_PRT: 'GQ-Pat Gold+ Protein',
    GQPAT_NUC: 'GQ-Pat Gold+ Nucleotide',
    GQPAT_PREMIUM_PRT: 'GQ-Pat Platinum Protein',
    GQPAT_PREMIUM_NUC: 'GQ-Pat Platinum Nucleotide',
    GEAA: 'GENESEQ Protein',
    GENA: 'GENESEQ Nucleotide',
    REGPAT_NUC: 'CAS Registry Nucleotide - Patent sequences',
    REGPAT_PRT: 'CAS Registry Protein - Patent sequences',
    REGNP_NUC: 'CAS Registry Nucleotide - Non Patent sequences',
    REGNP_PRT: 'CAS Registry Protein - Non Patent sequences',
    CASPAT_NUC: 'CAS Biosequences™ - Nucleotides from Patents',
    CASPAT_PRT: 'CAS Biosequences™ - Proteins from Patents',
    CASNP_NUC: 'CAS Biosequences™ - Nucleotides from Journals and Proceedings',
    CASNP_PRT: 'CAS Biosequences™ - Proteins from Journals and Proceedings'
};

const databasesBriefName = {
    REGPAT_NUC: 'CAS Registry Nuc Pat',
    REGPAT_PRT: 'CAS Registry Prot Pat',
    REGNP_NUC: 'CAS Registry Nuc Jrnl',
    REGNP_PRT: 'CAS Registry Prot Jrnl',
    CASPAT_NUC: 'CAS Biosequences™ Nuc Pat',
    CASPAT_PRT: 'CAS Biosequences™ Prot Pat',
    CASNP_NUC: 'CAS Biosequences™ Nuc Jrnl',
    CASNP_PRT: 'CAS Biosequences™ Prot Jrnl'
}

const patentDatabases = {
    GQPAT_PRT: 'GQPat',
    GQPAT_NUC: 'GQPat',
    GQPAT_PREMIUM_PRT: 'GQPat',
    GQPAT_PREMIUM_NUC: 'GQPat',
    GEAA: 'Geneseq',
    GENA: 'Geneseq',
    REGPAT_NUC: 'CAS',
    REGPAT_PRT: 'CAS',
    CASPAT_NUC: 'CAS',
    CASPAT_PRT: 'CAS'
};

const patentDatabasePrefs = {
    GQPat: 'GQ-Pat',
    Geneseq: 'GENESEQ',
    CAS: 'CAS Biosequences™'
};

function getPatentDatabaseLabel(id) {
    let obj = patentDatabasePrefs[id];
    if (obj) {
        return obj;
    } else {
        return id;
    }
}

function isPatentDatabase(SUBJECT_GNAME) {
    let dbId = _.split(SUBJECT_GNAME, ':')[0],
        dbDesc = patentDatabases[dbId];
    if (dbDesc === undefined) {
        return false;
    }
    return true;
}

// the one function used to convert dbId (like GQPAT_NUC) into a database description
function getDatabaseName(SUBJECT_GNAME) {
    let dbId = _.split(SUBJECT_GNAME, ':')[0],
        dbDesc = databases[dbId];
    if (dbDesc === undefined) {
        dbDesc = dbId; // in case database is unknown return the dbId
    }
    return dbDesc;
};

function getDatabaseBriefName(SUBJECT_GNAME) {
    let dbId = _.split(SUBJECT_GNAME, ':')[0],
        dbDesc = databasesBriefName[dbId];
    if (dbDesc === undefined) {
        dbDesc = databases[dbId];
        if (dbDesc === undefined) {
            dbDesc = dbId; // in case database is unknown return the dbId
        }
    }
    return dbDesc;
};

function getDbBriefNameForDocDetail(SUBJECT_GNAME) {
    let dbId = _.split(SUBJECT_GNAME, ':')[0];
    if (dbId.indexOf("GQPAT") == 0) {
        return "GQ-Pat";
    }
    return getDatabaseBriefName(SUBJECT_GNAME);
};

// ------------ end of database name related functionality


// which of the operators are default in the filtering widget
const operatorsDefault = {
    string: 'CONTAINS',
    string_frame: 'EQUALS',
    date: 'MORE',
    int: 'MORE',
    float: 'MORE',
    percentage: 'MORE'
};

// operators available in the filtering widget according to type
const operators = {
    string: {
        CONTAINS: 'contains',
        EQUALS: 'equals',
        NOTCONTAINS: 'does not contain',
        NOTEQUALS: 'does not equal'
    },
    string_frame: {
        EQUALS: 'is',
        NOTEQUALS: 'is not'
    },
    date: {
        EQUALS: 'equals',
        LESS: 'before or on',
        LESS_IE: 'before or is empty',
        MORE: 'after or on',
        MORE_IE: 'after or is empty',
        BETWEEN: 'between or on'
    },
    int: {
        EQUALS: 'equals',
        LESS_NE: 'less than',
        LESS: 'less than or equal',
        MORE_NE: 'more than',
        MORE: 'more than or equal',
        BETWEEN: 'between or equal'
    },
    float: {
        EQUALS: 'equals',
        LESS_NE: 'less than',
        LESS: 'less than or equal',
        MORE_NE: 'more than',
        MORE: 'more than or equal',
        BETWEEN: 'between or equal'
    },
    percentage: {
        EQUALS: 'equals',
        LESS_NE: 'less than',
        LESS: 'less than or equal',
        MORE_NE: 'more than',
        MORE: 'more than or equal',
        BETWEEN: 'between or equal'
    }
};

// get the operators for a specific field type in the filtering widget
const getOperators = function (field) {
    let type = fieldConfig[field].type;
    return operators[type];
};

// get a copy of the result filter service running here, TODO, resultFilter
let resultFilter = {};

// very naive setup of links used the app
const linkPrepSetup = {
    espacenet: {
        url: 'https://v3.espacenet.com/results?sf=a&FIRST=1&CY=ep&LG=en&DB=EPODOC&PN={SUBJECT_PN}'
    },
    USPTO: {
        url: 'https://patft.uspto.gov/netacgi/nph-Parser?patentnumber={SUBJECT_PN}'
    },
    USPTO2: {
        url: 'https://appft1.uspto.gov/netacgi/nph-Parser?s1={SUBJECT_PN}.PGNR.&d=PG01&p=1&u=/netahtml/PTO/srchnum.html&r=1&f=G&l=50'
    },
    USPTO_NEW: {
        url: 'https://ppubs.uspto.gov/pubwebapp/external.html?q={SUBJECT_PN}.did.&db=all'
    },
    FPO: {
        url: 'https://www.freepatentsonline.com/{SUBJECT_PN}.html'
    },
    patbase: {
        url: 'http://www.patbase.com/express/default.asp?saction=P-{SUBJECT_PN}'
    },
    thomson_innovation: {
        url: 'https://www.derwentinnovation.com/tip-innovation/patentRecordView.do?pn={SUBJECT_PN}'
    },
    gql_PDF: {
        url: 'https://www.genomequestlive.com/query?do=gqfetch.get_patent_pdf&pn={SUBJECT_PN}&kc={SUBJECT_KC}'
    },
    lifequest: {
        url: 'https://www.genomequestlive.com/query?do=gqredirect.lq&lq_ca=main/&pns={SUBJECT_PN}'
    },
    WIPO: {
        url: 'https://www.wipo.int/patentscope/search/en/{SUBJECT_PN}'
    },
    // NCBI & SRH for GenBank databases
    NCBI: {
        url: "https://www.ncbi.nlm.nih.gov/entrez/query.fcgi?cmdtarget'=Search&db=Nucleotide&term={SUBJECT_ID}&doptcmdl=GenBank'"
    },
    SRH: {
        url: "https://www.ncbi.nlm.nih.gov/entrez/sutils/girevhist.cgi?val={SUBJECT_ID}"
    },
    // ENSP, ENSM
    Ensembl: {
        url: "https://www.ensembl.org/Multi/Search/Results?q={SUBJECT_ID}"
    },
    RSM_NCBI: {
        url: "http://www.ncbi.nlm.nih.gov/entrez/query.fcgi?cmd=Search&db=nuccore&term={SUBJECT_ID}&doptcmdl=GenBank"
    },
    RSP_NCBI: {
        url: "http://www.ncbi.nlm.nih.gov/entrez/query.fcgi?cmd=Search&db=Protein&term={SUBJECT_ID}&doptcmdl=GenPept"
    },
    PDB_PRT: {
        url: "http://www.ncbi.nlm.nih.gov/entrez/viewer.fcgi?db=protein&val={SUBJECT_ID}"
    },
    PDB_NUC: {
        url: "http://www.ncbi.nlm.nih.gov/entrez/viewer.fcgi?db=nucleotide&val={SUBJECT_ID}"
    },
    PDB: {
        url: "http://www.rcsb.org/pdb/cgi/explore.cgi?pdbId={SUBJECT_ID}"
    },
    SP: {
        url: "http://www.uniprot.org/uniprot/{SUBJECT_ID}"
    },
    GQ_FT: {
        url: "https://www.genomequestlive.com/fulltext/index.html#/fulldocview/{SUBJECT_PN}{SUBJECT_KC}"
    }
};

// Called from doc-detail.html, returns the Links HTML
function prepLinks(item) {
    let resultHtml = '';
    if (!item) {
        return '';
    }
    let dbId = _.split(item['SUBJECT_GNAME'], ':')[0];
    if (item.SUBJECT_PN) {
        // When patent number exists
        let pn = item.SUBJECT_PN;
        let kc = item.SUBJECT_KC === undefined ? "" : item.SUBJECT_KC;
        let authority = pn.substring(0, 2);
        let pnWithoutAu = pn.substring(2);

        resultHtml += '<div>';
        // GQ LifeSciences PDF
        resultHtml += prepOutsideLink('gql_PDF', 'GQ PDF', pn, kc);
        // GQ Full Text link
        //resultHtml += '<div class="pull-left pad-menu-item sep-menu-item">' + item['VT_FT'] + '</div>';
        // LifeQuest
        //resultHtml += prepOutsideLink('lifequest', 'LifeQuest', pn);
        // Esp@cenet
        resultHtml += prepOutsideLink('espacenet', 'Esp@cenet', pn);

        if (authority == 'US') {
            // Free Patent Online
            resultHtml += prepOutsideLink('FPO', 'Free Patent Online', pnWithoutAu);

            // USPTO
            /*
            if (pn.length > 9) {
                // Use the link of USPTO2
                resultHtml += prepOutsideLink('USPTO2', 'USPTO', pnWithoutAu);
            } else {
                // Use the link of USPTO
                resultHtml += prepOutsideLink('USPTO', 'USPTO', pnWithoutAu);
            }*/
            resultHtml += prepOutsideLink('USPTO_NEW', 'USPTO', pnWithoutAu);
        } else if (authority == 'WO') {
            let pnFpo = pn;
            if (pn.substring(2, 3) == '8' || pn.substring(2, 3) == '9') {
                pnFpo = pnFpo.replace('WO', 'WO19');
            } else if (pn.substring(2, 3) == '0') {
                pnFpo = pnFpo.replace('WO', 'WO20');
            }
            if (pnFpo.length == 11) { // WO199912345 => WO1999012345
                pnFpo = pnFpo.substring(0, 5) + '0' + pnFpo.substring(5);
            }

            // Free Patent Online
            resultHtml += prepOutsideLink('FPO', 'Free Patent Online', pnFpo);
            // WIPO
            resultHtml += prepOutsideLink('WIPO', 'WIPO', pnFpo);
        } else {
            // Free Patent Online
            resultHtml += prepOutsideLink('FPO', 'Free Patent Online', pn);
        }

        if (dbId && dbId.substring(0, 3) !== 'REG' && dbId.substring(0, 3) !== 'CAS') {
            // Patbase
            resultHtml += prepOutsideLink('patbase', 'Patbase', pn);
            // Derwent Innovation
            resultHtml += prepOutsideLink('thomson_innovation', 'Derwent Innovation', pn);
        }

        resultHtml += '</div>';
    } else if (dbId && dbId.substring(0, 2) == 'GB') {
        resultHtml += '<>';
        resultHtml += prepOutsideLink('NCBI', 'NCBI', '', '', item.SUBJECT_ID, true);
        resultHtml += prepOutsideLink('SRH', 'Sequence Revision History', '', '', item.SUBJECT_ID);
        resultHtml += '</>';
    } else if (dbId && dbId.substring(0, 2) == 'GP') {
        resultHtml += '<>';
        resultHtml += prepOutsideLink('RSP_NCBI', 'NCBI', '', '', item.SUBJECT_ID, true); // Same as the RSP link
        resultHtml += '</>';
    } else if (dbId && dbId.substring(0, 2) == 'SP') {
        resultHtml += '<>';
        resultHtml += prepOutsideLink('SP', 'SwissProt', '', '', item.SUBJECT_ID, true);
        resultHtml += '</>';
    } else if (dbId && dbId.substring(0, 3) == 'ENS') {
        resultHtml += '<>';
        resultHtml += prepOutsideLink('Ensembl', 'Ensembl', '', '', item.SUBJECT_ID, true);
        resultHtml += '</>';
    } else if (dbId && dbId.substring(0, 3) == 'RSM') {
        resultHtml += '<>';
        resultHtml += prepOutsideLink('RSM_NCBI', 'NCBI', '', '', item.SUBJECT_ID, true);
        resultHtml += '</>';
    } else if (dbId && dbId.substring(0, 3) == 'RSP') {
        resultHtml += '<>';
        resultHtml += prepOutsideLink('RSP_NCBI', 'NCBI', '', '', item.SUBJECT_ID, true);
        resultHtml += '</>';
    } else if (dbId && dbId.substring(0, 7) == 'PDB_PRT') {
        resultHtml += '<>';
        resultHtml += prepOutsideLink('PDB', 'PDB', '', '', item.SUBJECT_ID, true);
        resultHtml += prepOutsideLink('PDB_PRT', 'NCBI', '', '', item.SUBJECT_ID);
        resultHtml += '</>';
    } else if (dbId && dbId.substring(0, 7) == 'PDB_NUC') {
        resultHtml += '<>';
        resultHtml += prepOutsideLink('PDB', 'PDB', '', '', item.SUBJECT_ID, true);
        resultHtml += prepOutsideLink('PDB_NUC', 'NCBI', '', '', item.SUBJECT_ID);
        resultHtml += '</>';
    }
    return resultHtml;
}

// called from detail view, returns a correct link according to the link types defined above
function prepOutsideLink(link_id, linkDesc, SUBJECT_PN, SUBJECT_KC, SUBJECT_ID, isFirst) {
    let result;
    if (link_id === 'gql_PDF' || isFirst) {
        // First link
        result = '<div class="pull-left"><a target="_blank" href="';
    } else {
        result = '<div class="pull-left pad-menu-item sep-menu-item"><a target="_blank" href="';
    }
    result += linkPrepSetup[link_id].url.replace('{SUBJECT_PN}', SUBJECT_PN).replace('{SUBJECT_KC}', SUBJECT_KC).replace('{SUBJECT_ID}', SUBJECT_ID);
    result += '">' + linkDesc + '</a></div>';

    return result;
};

/* ================ all fields ================ */

/*
    fieldConfig contains an entry for each information field

    FIELD_ID: {
        id:                 string              // repeat of the FIELD_ID
        title:              string              // how the field is described to the user (and table header in Excel export)
        prefix:             string              // text displayed before
        type:               string              // type of the field (string|date|int|float|percentage), required when filter is set to true
        colwidth:           integer             // the width in pixels of the table column for this field
        display:            string              // transformation of the field value according to recipe (for example YYYYMMDD for dates)
        fieldCombinations:  array of strings    // if a field is made up of several other fields they are listed here
    }
*/

// WARNING: changing the default colwidth values will not work if you don't remove the user default file first
let fieldConfig = {

    ALL_TEXT: {
        id: 'ALL_TEXT',
        title: 'Any Text Field',
        prefix: '',
        colwidth: 0,
        display: '',
        type: 'string',
        // WARNING: when changing the fieldCombinations property, you also need to make the same changes on the backend (data-setup.php)
        fieldCombinations: [
            'SUBJECT_PB', 'SUBJECT_PE', 'SUBJECT_PM', 'SUBJECT_XF', 'SUBJECT_AN', 'SUBJECT_CC', 'SUBJECT_CT', 'SUBJECT_EM',
            'SUBJECT_GNAME', 'SUBJECT_P1', 'SUBJECT_P4', 'SUBJECT_PA', 'SUBJECT_PC',//'SUBJECT_LS', 'SUBJECT_L2',
            'SUBJECT_PG', 'SUBJECT_PH', 'SUBJECT_PI', 'SUBJECT_PN', 'SUBJECT_PQ', 'SUBJECT_PR', 'SUBJECT_PT',
            'SUBJECT_PU', 'SUBJECT_PZ', 'SUBJECT_AC', 'SUBJECT_FT', 'SUBJECT_MT', 'SUBJECT_OO', 'SUBJECT_OS',
            'SUBJECT_OX', 'SUBJECT_PK', 'SUBJECT_PS', 'SUBJECT_RA', 'SUBJECT_RL', 'SUBJECT_OC',
            'SUBJECT_SI', 'QUERY_ID', 'SUBJECT_L3', 'SUBJECT_ID', 'SUBJECT_GX', 'SUBJECT_GI', 'SUBJECT_DE',
            // CAS Resigtry fields
            'SUBJECT_BM', 'SUBJECT_C6', 'SUBJECT_C7', 'SUBJECT_C8', 'SUBJECT_C9', 'SUBJECT_GY', 'SUBJECT_CD', 'SUBJECT_CJ', 'SUBJECT_CK', 'SUBJECT_CL', 'SUBJECT_CM', 'SUBJECT_CQ', 'SUBJECT_CU',
            'SUBJECT_DS', 'SUBJECT_MP', 'SUBJECT_NT', 'SUBJECT_RD', 'SUBJECT_RE', 'SUBJECT_RG', 'SUBJECT_S9', 'SUBJECT_SX', 'SUBJECT_SY'
        ]
    },
    SUBJECT_PAINV: {
        id: 'SUBJECT_PAINV',
        title: 'Any Assignee/Inventor Field',
        prefix: '',
        colwidth: 0,
        display: '',
        type: 'string',
        fieldCombinations: ['SUBJECT_PA', 'SUBJECT_PU', 'SUBJECT_PZ', 'SUBJECT_PI']
    },
    SUBJECT_STATUS: {
        id: 'SUBJECT_STATUS',
        title: 'Any Legal Status Field',
        prefix: '',
        colwidth: 0,
        display: '',
        type: 'string',
        fieldCombinations: ['SUBJECT_PG', 'SUBJECT_PH', 'SUBJECT_L3'] //'SUBJECT_LS',
    },
    SUBJECT_SEQIDNOSUMM: {
        id: 'SUBJECT_SEQIDNOSUMM',
        title: 'SEQ ID NOs in your current data',
        prefix: '',
        type: 'string',
        colwidth: 300,
        display: 'SEQIDNOSUMM'
    },
    SUBJECT_PB: {
        id: 'SUBJECT_PB',
        title: 'Patent Family ID',
        prefix: 'Patent Family',
        type: 'string',
        colwidth: 200,
        display: ''
    },
    NBFAMMEMBERS: {
        id: 'NBFAMMEMBERS',
        title: 'Family Member Count',
        prefix: 'Nb.Members:',
        type: 'int',
        colwidth: 130,
        display: ''
    },
    SUBJECT_PM: {
        id: 'SUBJECT_PM',
        title: 'Family Member List',
        prefix: '',
        type: 'string',
        colwidth: 300,
        display: ''
    },
    SUBJECT_XF: {
        id: 'SUBJECT_XF',
        title: 'Extended Family Member List',
        prefix: '',
        type: 'string',
        colwidth: 300,
        display: ''
    },
    SUBJECT_PE: {
        id: 'SUBJECT_PE',
        title: 'Priority Number',
        prefix: '',
        type: 'string',
        colwidth: 200,
        display: ''
    },
    SUBJECT_PN: {
        id: 'SUBJECT_PN',
        title: 'Patent Number',
        prefix: '',
        type: 'string',
        colwidth: 130,
        display: ''
    },
    SUBJECT_AN: {
        id: 'SUBJECT_AN',
        title: 'Application Number',
        prefix: '',
        type: 'string',
        colwidth: 130,
        display: ''
    },
    SUBJECT_KC: {
        id: 'SUBJECT_KC',
        title: 'Kind Code',
        prefix: '',
        type: 'string',
        colwidth: 20,
        display: ''
    },
    SUBJECT_D1: {
        id: 'SUBJECT_D1',
        title: 'Publication Date',
        prefix: 'Publ:',
        type: 'date',
        colwidth: 130,
        display: 'YYYYMMDD'
    },
    SUBJECT_D3: {
        id: 'SUBJECT_D3',
        title: 'Filing Date',
        prefix: 'Filing:',
        type: 'date',
        colwidth: 130,
        display: 'YYYYMMDD'
    },
    SUBJECT_D4: {
        id: 'SUBJECT_D4',
        title: 'Priority Date',
        prefix: 'Prior:',
        type: 'date',
        colwidth: 130,
        display: 'YYYYMMDD'
    },
    SUBJECT_D5: {
        id: 'SUBJECT_D5',
        title: 'Earliest Publication Date',
        prefix: '',
        type: 'date',
        colwidth: 130,
        display: 'YYYYMMDD'
    },
    SUBJECT_D7: {
        id: 'SUBJECT_D7',
        title: 'Submission Date',
        prefix: '',
        type: 'date',
        colwidth: 130,
        display: 'YYYYMMDD'
    },
    SUBJECT_DE: {
        id: 'SUBJECT_DE',
        title: 'Subject Description/Title',
        prefix: '',
        type: 'string',
        colwidth: 200,
        display: ''
    },
    SUBJECT_PA: {
        id: 'SUBJECT_PA',
        title: 'Patent Assignee',
        prefix: '',
        type: 'string',
        colwidth: 300,
        display: ''
    },
    SUBJECT_PU: {
        id: 'SUBJECT_PU',
        title: 'Normalized Patent Assignee',
        prefix: '',
        type: 'string',
        colwidth: 300,
        display: ''
    },
    SUBJECT_PZ: {
        id: 'SUBJECT_PZ',
        title: 'Normalized Parent',
        prefix: '',
        type: 'string',
        colwidth: 300,
        display: ''
    },
    SUBJECT_PI: {
        id: 'SUBJECT_PI',
        title: 'Patent Inventors',
        prefix: '',
        type: 'string',
        colwidth: 300,
        display: ''
    },
    SUBJECT_PT: {
        id: 'SUBJECT_PT',
        title: 'Title',
        prefix: '',
        type: 'string',
        colwidth: 300,
        display: ''
    },
    SUBJECT_PR: {
        id: 'SUBJECT_PR',
        title: 'Priority Info',
        prefix: '',
        type: 'string',
        colwidth: 300,
        display: ''
    },
    SUBJECT_PG: {
        id: 'SUBJECT_PG',
        title: 'Extended Legal Status',
        prefix: '',
        type: 'string',
        colwidth: 100,
        display: ''
    },
    SUBJECT_PH: {
        id: 'SUBJECT_PH',
        title: 'Legal Status National Phase',
        prefix: '',
        type: 'string',
        colwidth: 300,
        display: ''
    },
    SUBJECT_PO: {
        id: 'SUBJECT_PO',
        title: 'Claimed SEQ ID Nos',
        prefix: '',
        type: 'string',
        colwidth: 300,
        display: ''
    },
    SUBJECT_CC: {
        id: 'SUBJECT_CC',
        title: 'Comments',
        prefix: '',
        type: 'string',
        colwidth: 600,
        display: ''
    },
    SUBJECT_CT: {
        id: 'SUBJECT_CT',
        title: 'Claims',
        prefix: '',
        type: 'string',
        colwidth: 600,
        display: ''
    },

    SUBJECT_EM: {
        id: 'SUBJECT_EM',
        title: 'Equivalent Member Type',
        prefix: '',
        colwidth: 200,
        type: 'string',
        display: ''
    },
    SUBJECT_GNAME: {
        id: 'SUBJECT_GNAME',
        title: 'Database Name',
        prefix: '',
        type: 'string',
        colwidth: 200,
        display: 'SUBJECT_GNAME'
    },
    SUBJECT_P1: {
        id: 'SUBJECT_P1',
        title: 'Abstract',
        prefix: '',
        type: 'string',
        colwidth: 600,
        display: ''
    },
    SUBJECT_P4: {
        id: 'SUBJECT_P4',
        title: 'PCT Publication Number',
        prefix: '',
        type: 'string',
        colwidth: 130,
        display: ''
    },
    SUBJECT_P5: {
        id: 'SUBJECT_P5',
        title: 'PCT Publication Date',
        prefix: '',
        type: 'date',
        colwidth: 130,
        display: 'YYYYMMDD'
    },
    SUBJECT_P8: {
        id: 'SUBJECT_P8',
        title: 'PCT Related Dates',
        prefix: '',
        colwidth: 130,
        type: 'string',
        display: ''
    },
    SUBJECT_P9: {
        id: 'SUBJECT_P9',
        title: 'Number of Sequences',
        prefix: '',
        type: 'int',
        colwidth: 130,
        display: ''
    },
    SUBJECT_PC: {
        id: 'SUBJECT_PC',
        title: 'Patent Classification',
        prefix: '',
        type: 'string',
        colwidth: 200,
        display: ''
    },
    SUBJECT_PQ: {
        id: 'SUBJECT_PQ',
        title: 'Sequence Listing Equivalents',
        prefix: '',
        type: 'string',
        colwidth: 200,
        display: ''
    },
    SUBJECT_PV: {
        id: 'SUBJECT_PV',
        title: 'Patent ID',
        prefix: '',
        colwidth: 200,
        type: 'string',
        display: ''
    },
    SUBJECT_D2: {
        id: 'SUBJECT_D2',
        title: 'Date of Entry',
        prefix: '',
        colwidth: 130,
        type: 'date',
        display: 'YYYYMMDD'
    },
    SUBJECT_P7: {
        id: 'SUBJECT_P7',
        title: 'Number of Claims',
        prefix: '',
        colwidth: 80,
        type: 'int',
        display: ''
    },
    SUBJECT_SI: {
        id: 'SUBJECT_SI',
        title: 'Patent SEQ ID NO',
        prefix: 'SEQ ID NO',
        type: 'int',
        colwidth: 130,
        display: ''
    },
    SUBJECT_L: {
        id: 'SUBJECT_L',
        title: 'Subject Sequence Length',
        prefix: 'Len:',
        type: 'int',
        colwidth: 60,
        display: ''
    },
    SUBJECT_OO: {
        id: 'SUBJECT_OO',
        title: 'Organism',
        prefix: '',
        type: 'string',
        colwidth: 200,
        display: ''
    },
    SUBJECT_OS: {
        id: 'SUBJECT_OS',
        title: 'Normalized Organism',
        prefix: '',
        type: 'string',
        colwidth: 200,
        display: ''
    },
    SUBJECT_OX: {
        id: 'SUBJECT_OX',
        title: 'Organism Taxonomy ID',
        prefix: '',
        type: 'string',
        colwidth: 130,
        display: ''
    },
    SUBJECT_OC: {
        id: 'SUBJECT_OC',
        title: 'Species Taxonomy',
        prefix: '',
        type: 'string',
        colwidth: 130,
        display: ''
    },
    SUBJECT_AC: {
        id: 'SUBJECT_AC',
        title: 'Accession Number',
        prefix: '',
        type: 'string',
        colwidth: 130,
        display: ''
    },
    SUBJECT_GX: {
        id: 'SUBJECT_GX',
        title: 'GQ Identifier',
        prefix: '',
        type: 'string',
        colwidth: 130,
        display: ''
    },
    SUBJECT_GI: {
        id: 'SUBJECT_GI',
        title: 'Genbank Index',
        prefix: '',
        type: 'string',
        colwidth: 130,
        display: ''
    },
    SUBJECT_FT: {
        id: 'SUBJECT_FT',
        title: 'Feature Table',
        prefix: '',
        type: 'string',
        colwidth: 200,
        display: ''
    },
    SUBJECT_MT: {
        id: 'SUBJECT_MT',
        title: 'Molecule Type',
        prefix: '',
        type: 'string',
        colwidth: 130,
        display: ''
    },
    SUBJECT_ID: {
        id: 'SUBJECT_ID',
        title: 'Subject Sequence ID',
        prefix: '',
        type: 'string',
        colwidth: 130,
        display: ''
    },
    SUBJECT_L3: {
        id: 'SUBJECT_L3',
        title: 'US-PAIR Legal Status',
        prefix: '',
        type: 'string',
        colwidth: 200,
        display: ''
    },
    SUBJECT_N: {
        id: 'SUBJECT_N',
        title: 'Biofacet Subject lspid',
        prefix: '',
        colwidth: 130,
        display: ''
    },
    SUBJECT_PK: {
        id: 'SUBJECT_PK',
        title: 'Sequence Notes',
        prefix: '',
        type: 'string',
        colwidth: 300,
        display: ''
    },
    SUBJECT_USER_COMMENT: {
        id: 'SUBJECT_USER_COMMENT',
        title: 'Comments',
        prefix: '',
        type: 'string',
        colwidth: 300,
        display: ''
    },
    SUBJECT_PS: {
        id: 'SUBJECT_PS',
        title: 'Patent Sequence Location',
        prefix: '',
        type: 'string',
        colwidth: 200,
        display: ''
    },
    SUBJECT_RA: {
        id: 'SUBJECT_RA',
        title: 'Reference Author',
        prefix: '',
        type: 'string',
        colwidth: 200,
        display: ''
    },
    SUBJECT_RL: {
        id: 'SUBJECT_RL',
        title: 'Reference Location',
        prefix: '',
        type: 'string',
        colwidth: 200,
        display: ''
    },
    SUBJECT_SV: {
        id: 'SUBJECT_SV',
        title: 'Sequence Version',
        prefix: '',
        type: 'string',
        colwidth: 130,
        display: ''
    },
    RESULT_RIQ: {
        id: 'RESULT_RIQ',
        title: '% Identity Query',
        prefix: 'Query',
        colwidth: 110,
        type: 'percentage',
        display: 'percentage'
    },
    RESULT_RID: {
        id: 'RESULT_RID',
        title: '% Identity Subject',
        prefix: 'Sbjct',
        colwidth: 110,
        type: 'percentage',
        display: 'percentage'
    },
    RESULT_RI: {
        id: 'RESULT_RI',
        title: '% Identity Alignment',
        prefix: 'Align',
        type: 'percentage',
        colwidth: 110,
        display: 'percentage'
    },
    RESULT_OOBQ: {
        id: 'RESULT_OOBQ',
        title: 'Query Start Position',
        prefix: '',
        type: 'int',
        colwidth: 130,
        display: ''
    },
    RESULT_OOEQ: {
        id: 'RESULT_OOEQ',
        title: 'Query Stop Position',
        prefix: '',
        type: 'int',
        colwidth: 130,
        display: ''
    },
    RESULT_OOBD: {
        id: 'RESULT_OOBD',
        title: 'Subject Start Position',
        prefix: '',
        type: 'int',
        colwidth: 130,
        display: ''
    },
    RESULT_OOED: {
        id: 'RESULT_OOED',
        title: 'Subject Stop Position',
        prefix: '',
        type: 'int',
        colwidth: 130,
        display: ''
    },
    RESULT_RE: {
        id: 'RESULT_RE',
        title: 'Expect Value',
        prefix: '',
        type: 'float',
        colwidth: 130,
        display: ''
    },
    RESULT_RL: {
        id: 'RESULT_RL',
        title: 'Alignment Length',
        prefix: 'Align.Len:',
        type: 'int',
        colwidth: 100,
        display: ''
    },
    RESULT_RS: {
        id: 'RESULT_RS',
        title: 'Alignment Score',
        prefix: '',
        type: 'int',
        colwidth: 130,
        display: ''
    },
    RESULT_ID: {
        id: 'RESULT_ID',
        title: 'Result Index',
        prefix: '',
        colwidth: 130,
        display: ''
    },
    RESULT_COV: {
        id: 'RESULT_COV',
        title: 'Query % HSP Coverage',
        prefix: '',
        colwidth: 130,
        type: 'percentage',
        display: 'percentage'
    },
    RESULT_GAPD: {
        id: 'RESULT_GAPD',
        title: 'Number of Gaps in Subject',
        prefix: '',
        type: 'int',
        colwidth: 130,
        display: ''
    },
    RESULT_GAPQ: {
        id: 'RESULT_GAPQ',
        title: 'Number of Gaps in Query',
        prefix: '',
        type: 'int',
        colwidth: 130,
        display: ''
    },
    RESULT_NFD: {
        id: 'RESULT_NFD',
        title: 'Subj. frame / transl.',
        prefix: '',
        type: 'string_frame',
        colwidth: 130,
        display: ''
    },
    RESULT_FQ: {
        id: 'RESULT_FQ',
        title: 'Query frame / transl.',
        prefix: '',
        type: 'string_frame',
        colwidth: 130,
        display: ''
    },
    RESULT_NFQ: {
        id: 'RESULT_NFQ',
        title: 'Query frame / transl.',
        prefix: '',
        type: 'string_frame',
        colwidth: 130,
        display: ''
    },
    RESULT_NFALI_FORMATTED: {
        id: 'RESULT_NFALI_FORMATTED',
        title: 'NCBI-style Alignment',
        prefix: '',
        colwidth: 600,
        display: 'pre'
    },
    RESULT_FALI_FORMATTED: {
        id: 'RESULT_FALI_FORMATTED',
        title: 'GenomeQuest Alignment',
        prefix: '',
        colwidth: 600,
        display: 'pre'
    },
    RESULT_ALI_DIR: {
        id: 'RESULT_ALI_DIR',
        title: 'Align Direction',
        prefix: '',
        colwidth: 40
    },
    RESULT_RF: {
        id: 'RESULT_RF',
        title: 'min_len(query, subj)/len(align)',
        prefix: '',
        colwidth: 130,
        type: 'float',
        display: ''
    },
    RESULT_RIG: {
        id: 'RESULT_RIG',
        title: '% Identity over min_len(query,subj)',
        prefix: '',
        colwidth: 130,
        type: 'percentage',
        display: 'percentage'
    },
    RESULT_RM: {
        id: 'RESULT_RM',
        title: '% Positives over len(align)',
        prefix: '',
        colwidth: 130,
        type: 'percentage',
        display: 'percentage'
    },
    RESULT_RP: {
        id: 'RESULT_RP',
        title: 'result field3 (P-value, ...)',
        prefix: '',
        colwidth: 130,
        display: ''
    },
    RESULT_CLMATCH: {
        id: 'RESULT_CLMATCH',
        title: 'Alignment Match Type',
        prefix: '',
        colwidth: 200,
        display: ''
    },
    RESULT_RCD: {
        id: 'RESULT_RCD',
        title: '% Coverage over Subject Length',
        prefix: '',
        colwidth: 130,
        type: 'percentage',
        display: 'percentage'
    },
    RESULT_RCQ: {
        id: 'RESULT_RCQ',
        title: '% Coverage over Query Length',
        prefix: '',
        colwidth: 130,
        type: 'percentage',
        display: 'percentage'
    },
    RESULT_RZ: {
        id: 'RESULT_RZ',
        title: 'len(align)/min_len(query,subject)',
        prefix: '',
        colwidth: 130,
        type: 'float',
        display: ''
    },
    RESULT_OSFRAGD: {
        id: 'RESULT_OSFRAGD',
        title: 'Untranslated Matching Fragment Subject Sequence',
        prefix: '',
        colwidth: 300,
        display: ''
    },
    RESULT_OSFRAGQ: {
        id: 'RESULT_OSFRAGQ',
        title: 'Untranslated Matching Fragment Query Sequence',
        prefix: '',
        colwidth: 300,
        display: ''
    },
    QUERY_ID: {
        id: 'QUERY_ID',
        title: 'Query Sequence ID',
        prefix: '',
        colwidth: 60,
        display: '',
        type: 'string'
    },
    QUERY_DE: {
        id: 'QUERY_DE',
        title: 'Query Description',
        prefix: '',
        colwidth: 200,
        type: 'string',
        display: ''
    },
    QUERY_L: {
        id: 'QUERY_L',
        title: 'Query Sequence Length',
        prefix: '',
        colwidth: 130,
        type: 'int',
        display: ''
    },
    QUERY_N: {
        id: 'QUERY_N',
        title: 'Biofacet Query lspid',
        prefix: '',
        colwidth: 130,
        display: ''
    },
    QUERY_O: {
        id: 'QUERY_O',
        title: 'Query Sequence',
        prefix: '',
        colwidth: 300,
        display: ''
    },
    UFS_DISPLAY: {
        id: 'UFS_DISPLAY',
        title: 'Simple Unique Family Sequence ID',
        prefix: 'Simple Unique Family Sequence ID',
        colwidth: 300,
        type: 'string',
        display: ''
    },
    SUBJECT_PJ: {
        id: 'SUBJECT_PJ',
        title: 'Unique Family Sequence ID',
        prefix: 'UFS',
        colwidth: 300,
        type: 'string',
        display: ''
    },
    SUBJECT_FAMINFO: {
        id: 'SUBJECT_FAMINFO',
        title: 'Patent Family Info',
        prefix: '',
        colwidth: 200,
        display: ''
    },
    FAMMEMBERS_IN_CURRENT: {
        id: 'patentsInCurrentResults',
        title: 'Found and in current results',
        prefix: '',
        type: 'string',
        colwidth: 300,
        display: ''
    },
    FAMMEMBERS_OUT_CURRENT: {
        id: 'patentsFilteredOut',
        title: 'Found but filtered out',
        prefix: '',
        type: 'string',
        colwidth: 300,
        display: ''
    },
    FAMMEMBERS_OUT_GQ: {
        id: 'patentsNotInGQ',
        title: 'Not in GQ Databases',
        prefix: '',
        type: 'string',
        colwidth: 300,
        display: ''
    },
    // these fields have been added for the detail view
    // they are combination fields that display all related information
    ORGANISM: {
        id: 'ORGANISM',
        title: 'Organism',
        prefix: '',
        colwidth: 0,
        display: ''
    },
    ASSIGNEES: {
        id: 'ASSIGNEES',
        title: 'Assignees and Inventors',
        prefix: '',
        colwidth: 0,
        display: ''
    },
    PATENT_DATES: {
        id: 'PATENT_DATES',
        title: 'Patent Dates',
        prefix: '',
        colwidth: 0,
        display: ''
    },
    LEGAL_STATUS: {
        id: 'LEGAL_STATUS',
        title: 'Legal Status',
        prefix: '',
        colwidth: 0,
        display: ''
    },
    DOCHEADER: {
        id: 'DOCHEADER',
        title: 'Document Header',
        prefix: '',
        colwidth: 0,
        display: ''
    },
    DOCLINKS: {
        id: 'DOCLINKS',
        title: 'Document Links',
        prefix: '',
        colwidth: 0,
        display: ''
    },

    ALI_INFO: { // new
        id: 'ALI_INFO',
        title: 'Alignment Information',
        prefix: '',
        colwidth: 0,
        display: ''
    },
    QUERY_ALI_INFO: { // new
        id: 'QUERY_ALI_INFO',
        title: 'Query Information',
        prefix: '',
        colwidth: 0,
        display: ''
    },
    SUBJECT_ALI_INFO: { // new
        id: 'SUBJECT_ALI_INFO',
        title: 'Subject Information',
        prefix: '',
        colwidth: 0,
        display: ''
    },
    // CAS registry fields
    SUBJECT_BM: {
        id: 'SUBJECT_BM',
        title: 'Biosequence Modifications',
        prefix: '',
        type: 'string',
        colwidth: 130,
        display: ''
    },
    SUBJECT_C6: {
        id: 'SUBJECT_C6',
        title: 'Language',
        prefix: '',
        type: 'string',
        colwidth: 60,
        display: ''
    },
    SUBJECT_C7: {
        id: 'SUBJECT_C7',
        title: 'Volume',
        prefix: '',
        type: 'string',
        colwidth: 130,
        display: ''
    },
    SUBJECT_C8: {
        id: 'SUBJECT_C8',
        title: 'Issue',
        prefix: '',
        type: 'string',
        colwidth: 130,
        display: ''
    },
    SUBJECT_C9: {
        id: 'SUBJECT_C9',
        title: 'Pages',
        prefix: '',
        type: 'string',
        colwidth: 130,
        display: ''
    },
    SUBJECT_GY: {
        id: 'SUBJECT_GY',
        title: 'Company/Organization',
        prefix: '',
        type: 'string',
        colwidth: 130,
        display: ''
    },
    SUBJECT_CD: {
        id: 'SUBJECT_CD',
        title: 'CAS Document ID',
        prefix: '',
        type: 'string',
        colwidth: 130,
        display: ''
    },
    SUBJECT_CJ: {
        id: 'SUBJECT_CJ',
        title: 'DOI link',
        prefix: '',
        type: 'string',
        colwidth: 130,
        display: ''
    },
    SUBJECT_CK: {
        id: 'SUBJECT_CK',
        title: 'ISSN',
        prefix: '',
        type: 'string',
        colwidth: 130,
        display: ''
    },
    SUBJECT_CL: {
        id: 'SUBJECT_CL',
        title: 'Coden',
        prefix: '',
        type: 'string',
        colwidth: 130,
        display: ''
    },
    SUBJECT_CM: {
        id: 'SUBJECT_CM',
        title: 'Structure Data',
        prefix: '',
        type: 'string',
        colwidth: 130,
        display: ''
    },
    SUBJECT_CQ: {
        id: 'SUBJECT_CQ',
        title: 'Publisher',
        prefix: '',
        type: 'string',
        colwidth: 130,
        display: ''
    },
    SUBJECT_CU: {
        id: 'SUBJECT_CU',
        title: 'Source Title',
        prefix: '',
        type: 'string',
        colwidth: 130,
        display: ''
    },
    SUBJECT_DC: {
        id: 'SUBJECT_DC',
        title: 'Publication year',
        prefix: '',
        type: 'string', // Just treat it as string instead of Date type
        colwidth: 130,
        display: '' // YYYYMMDD
    },
    SUBJECT_DS: {
        id: 'SUBJECT_DS',
        title: 'CAS Name',
        prefix: '',
        type: 'string',
        colwidth: 200,
        display: ''
    },
    SUBJECT_MP: {
        id: 'SUBJECT_MP',
        title: 'Morphology',
        prefix: '',
        type: 'string',
        colwidth: 130,
        display: ''
    },
    SUBJECT_NT: {
        id: 'SUBJECT_NT',
        title: 'CAS Note',
        prefix: '',
        type: 'string',
        colwidth: 130,
        display: ''
    },
    SUBJECT_RB: {
        id: 'SUBJECT_RB',
        title: 'CAS RN<sup>®</sup> Entry Date',
        prefix: '',
        type: 'date',
        colwidth: 130,
        display: 'YYYYMMDD'
    },
    SUBJECT_RC: {
        id: 'SUBJECT_RC',
        title: 'CAS RN<sup>®</sup> Last Update Date',
        prefix: '',
        type: 'date',
        colwidth: 130,
        display: 'YYYYMMDD'
    },
    SUBJECT_RD: {
        id: 'SUBJECT_RD',
        title: 'Role in Document',
        prefix: '',
        type: 'string',
        colwidth: 130,
        display: ''
    },
    SUBJECT_RE: {
        id: 'SUBJECT_RE',
        title: 'Related Sequences',
        prefix: '',
        type: 'string',
        colwidth: 130,
        display: ''
    },
    SUBJECT_RG: {
        id: 'SUBJECT_RG',
        title: 'CAS Registry Number<sup>®</sup>',
        prefix: '',
        type: 'string',
        colwidth: 130,
        display: ''
    },
    SUBJECT_S8: {
        id: 'SUBJECT_S8',
        title: 'Strand Number',
        prefix: '',
        type: 'int',
        colwidth: 130,
        display: ''
    },
    SUBJECT_S9: {
        id: 'SUBJECT_S9',
        title: 'Topology',
        prefix: '',
        type: 'string',
        colwidth: 130,
        display: ''
    },
    SUBJECT_SX: {
        id: 'SUBJECT_SX',
        title: 'Sequence cross-reference',
        prefix: '',
        type: 'string',
        colwidth: 130,
        display: ''
    },
    SUBJECT_SY: {
        id: 'SUBJECT_SY',
        title: 'Also known as',
        prefix: '',
        type: 'string',
        colwidth: 130,
        display: ''
    },
    SUBJECT_CB: {
        id: 'SUBJECT_CB',
        title: 'Citation',
        prefix: '',
        type: 'string',
        colwidth: 130,
        display: ''
    },
    SUBJECT_DR: {
        id: 'SUBJECT_DR',
        title: 'Database cross-references',
        prefix: '',
        type: 'string',
        colwidth: 130,
        display: ''
    },
    SUBJECT_KW: {
        id: 'SUBJECT_KW',
        title: 'Keywords',
        prefix: '',
        type: 'string',
        colwidth: 130,
        display: ''
    },
    VT_PDF: {
        id: 'VT_PDF',
        title: 'PDF Link',
        prefix: '',
        type: 'string',
        colwidth: 130,
        display: ''
    },
    ALI_DESC: {
        id: 'ALI_DESC',
        title: 'Patent Member View Count',
        prefix: '',
        type: 'string',
        colwidth: 130,
        display: ''
    },
    SS_DESC: {
        id: 'SS_DESC',
        title: 'Subject Sequence View Count',
        prefix: '',
        type: 'string',
        colwidth: 130,
        display: ''
    },
    PB_DESC: {
        id: 'PB_DESC',
        title: 'Family Member View Count',
        prefix: '',
        type: 'string',
        colwidth: 130,
        display: ''
    },
    PFD_PA: {
        id: 'PFD_PA',
        title: 'Preferred Patent Authority',
        prefix: '',
        type: 'string',
        colwidth: 130,
        display: ''
    },
    PFD_ELS: {
        id: 'PFD_ELS',
        title: 'Preferred Extended Legal Status',
        prefix: '',
        type: 'string',
        colwidth: 130,
        display: ''
    },
    PFD_LS: {
        id: 'PFD_LS',
        title: 'Preferred Legal Status',
        prefix: '',
        type: 'string',
        colwidth: 130,
        display: ''
    },
    PFD_PSL: {
        id: 'PFD_PSL',
        title: 'Preferred Patent Sequence Location',
        prefix: '',
        type: 'string',
        colwidth: 130,
        display: ''
    },
    PFD_GNAME: {
        id: 'PFD_GNAME',
        title: 'Preferred Database Name',
        prefix: '',
        type: 'string',
        colwidth: 130,
        display: ''
    },
    VT_FT: {
        id: 'VT_FT',
        title: 'Full Text Link',
        prefix: '',
        type: 'string',
        colwidth: 130,
        display: ''
    },
    SUBJECT_LS: {
        id: 'SUBJECT_LS',
        title: 'Legal Status',
        prefix: '',
        type: 'string',
        colwidth: 200,
        display: ''
    },
    SUBJECT_FTS: {
        id: 'SUBJECT_FTS',
        title: 'Full Text',
        prefix: '',
        type: 'string',
        colwidth: 350,
        display: ''
    },
    RESULT_VMH: {
        id: 'RESULT_VMH',
        title: 'Variation Hits',
        prefix: '',
        type: 'string',
        colwidth: 350,
        display: ''
    },
};

// TOOD
let defaultFieldConfig = _.cloneDeep(fieldConfig);


let combinedFields = {};
// prep information on the combined fields
function prepCombinedFields() {
    let i;
    let combinedFields = {};
    _.each(fieldConfig, function (val, key) {
        if (val.fieldCombinations !== undefined) {
            combinedFields[key] = {};
            for (i = 0; i < val.fieldCombinations.length; i++) {
                combinedFields[key][val.fieldCombinations[i]] = 1;
            }
        }
    });
    return combinedFields;
};
//prepCombinedFields();

// here we define what sort options are available
// SQL definition has been moved to the backend ($sortSqlConfig)
const sortConfig = {
    high_percid_query: {
        // sql: 'RESULT_RIQ DESC, SUBJECT_D1 DESC',
        shorttitle: 'Highest %Id. Query',
        title: 'Highest Percentage Identity Query'
    },
    low_percid_query: {
        // sql: 'RESULT_RIQ ASC, SUBJECT_D1 DESC',
        shorttitle: 'Lowest %Id. Query',
        title: 'Lowest Percentage Identity Query'
    },
    high_percid_subject: {
        // sql: 'RESULT_RID DESC, SUBJECT_D1 DESC',
        shorttitle: 'Highest %Id. Subject',
        title: 'Highest Percentage Identity Subject'
    },
    low_percid_subject: {
        // sql: 'RESULT_RID ASC, SUBJECT_D1 DESC',
        shorttitle: 'Lowest %Id. Subject',
        title: 'Lowest Percentage Identity Subject'
    },
    high_percid_alignment: {
        // sql: 'RESULT_RI DESC, SUBJECT_D1 DESC',
        shorttitle: 'Highest %Id. Alignment',
        title: 'Highest Percentage Identity Alignment'
    },
    low_percid_alignment: {
        // sql: 'RESULT_RI ASC, SUBJECT_D1 DESC',
        shorttitle: 'Lowest %Id. Alignment',
        title: 'Lowest Percentage Identity Alignment'
    },
    earliest_prior_date: {
        // sql: 'SUBJECT_D4 ASC, RESULT_RIQ DESC',
        shorttitle: 'Earliest Priority Date',
        title: 'Earliest Priority Date'
    },
    earliest_pub_date: {
        // sql: 'SUBJECT_D1 ASC, RESULT_RIQ DESC',
        shorttitle: 'Earliest Publ.Date',
        title: 'Earliest Publication Date'
    },
};

let customSortConfig = [];

const groupConfigNew = {
    ufs: [
        {
            group: 'Unique Family Sequence',
            fields: ['UFS_DISPLAY', 'SUBJECT_PJ']
        }
    ],
    fam: [
        {
            group: 'Patent Family',
            fields: ['SUBJECT_PB', 'PB_DESC', 'SUBJECT_PM', 'SUBJECT_XF', 'NBFAMMEMBERS', 'FAMMEMBERS_IN_CURRENT', 'FAMMEMBERS_OUT_CURRENT', 'FAMMEMBERS_OUT_GQ']
        }
    ],
    doc: [
        {
            group: 'Patent Information',
            fields: ['SUBJECT_PN', 'ALI_DESC', 'SUBJECT_KC', 'SUBJECT_PV', 'SUBJECT_PT', 'SUBJECT_P1', 'SUBJECT_CT', 'SUBJECT_P7', 'SUBJECT_PC', 'SUBJECT_KW', 'VT_PDF', 'VT_FT']
        },
        {
            group: 'Legal Statuses',
            fields: ['SUBJECT_PG', 'SUBJECT_PH', 'SUBJECT_L3']
        },
        {
            group: 'Inventors & Assignees',
            fields: ['SUBJECT_PI', 'SUBJECT_PA', 'SUBJECT_PU', 'SUBJECT_PZ']
        },
        {
            group: 'Dates & Documents',
            fields: ['SUBJECT_PR', 'SUBJECT_PE', 'SUBJECT_D4', 'SUBJECT_D3', 'SUBJECT_AN', 'SUBJECT_D5', 'SUBJECT_D7', 'SUBJECT_D1',
                'SUBJECT_P4', 'SUBJECT_P5', 'SUBJECT_P8', 'SUBJECT_EM']
        },
        {
            group: 'Patent Family',
            fields: ['SUBJECT_PB', 'SUBJECT_PM', 'SUBJECT_XF', 'NBFAMMEMBERS'] // , 'FAMMEMBERS_IN_CURRENT', 'FAMMEMBERS_OUT_CURRENT', 'FAMMEMBERS_OUT_GQ'
        },
        {
            group: 'GQ-related',
            fields: ['SUBJECT_PQ', 'SUBJECT_PO', 'SUBJECT_P9', 'SUBJECT_D2', 'SUBJECT_SEQIDNOSUMM'] // , 'SUBJECT_GNAME'
        }
    ],
    cas: [
        {
            group: 'CAS Registry Information',
            fields: ['SUBJECT_RG', 'SUBJECT_RB', 'SUBJECT_RC', 'SUBJECT_DS', 'SUBJECT_SY', 'SUBJECT_NT', 'SUBJECT_SX', 'SUBJECT_CM', 'SUBJECT_RE']
        }
    ],
    ssv: [
        {
            group: 'Subject Sequence Information',
            fields: ["SUBJECT_ID", "SUBJECT_L", "SUBJECT_PN", "SUBJECT_D1", "SUBJECT_PG", "SUBJECT_PA", 'SUBJECT_PU', 'SUBJECT_PZ', "SUBJECT_PT",
                "SS_DESC", "SUBJECT_GX", "SUBJECT_GI", "SUBJECT_P1", "SUBJECT_PS", "SUBJECT_PI", "SUBJECT_SI",
                "SUBJECT_D3", "SUBJECT_D4", "SUBJECT_D5", "SUBJECT_D7", "SUBJECT_P5",
                "SUBJECT_PB", "SUBJECT_PM", "SUBJECT_XF", "NBFAMMEMBERS", "SUBJECT_PJ", "UFS_DISPLAY",
                "SUBJECT_OS", "SUBJECT_OO", "SUBJECT_OX", "SUBJECT_GNAME", "SUBJECT_CC"]
        }
    ],
    qs: [
        {
            group: 'Query Sequence Information',
            fields: ["QUERY_ID", "QUERY_L", "QUERY_DE"]
        }
    ],
    hsp: [
        {
            group: 'Blast HSPs',
            fields: ["QUERY_ID", "SUBJECT_ID", "RESULT_FQ", "RESULT_NFD", "QUERY_L", "QUERY_DE"]
        }
    ],
    docForFam: [
        {
            group: 'Patent Information',
            fields: ['SUBJECT_PN', 'ALI_DESC', 'SUBJECT_KC', 'SUBJECT_PV', 'SUBJECT_PT', 'SUBJECT_P1', 'SUBJECT_CT', 'SUBJECT_P7', 'SUBJECT_PC', 'SUBJECT_KW', 'VT_PDF', 'VT_FT']
        },
        {
            group: 'Legal Statuses',
            fields: ['SUBJECT_PG', 'SUBJECT_PH', 'SUBJECT_L3']
        },
        {
            group: 'Inventors & Assignees',
            fields: ['SUBJECT_PI', 'SUBJECT_PA', 'SUBJECT_PU', 'SUBJECT_PZ']
        },
        {
            group: 'Dates & Documents',
            fields: ['SUBJECT_PR', 'SUBJECT_PE', 'SUBJECT_D4', 'SUBJECT_D3', 'SUBJECT_AN', 'SUBJECT_D5', 'SUBJECT_D7', 'SUBJECT_D1',
                'SUBJECT_P4', 'SUBJECT_P5', 'SUBJECT_P8', 'SUBJECT_EM']
        },
        {
            group: 'GQ-related',
            fields: ['SUBJECT_PQ', 'SUBJECT_PO', 'SUBJECT_P9', 'SUBJECT_D2', 'SUBJECT_SEQIDNOSUMM'] //, 'SUBJECT_GNAME'
        }
    ],

    sin: [
        {
            group: 'Identifiers',
            fields: ['QUERY_ID', 'SUBJECT_ID', 'SUBJECT_GX', 'SUBJECT_GI', 'SUBJECT_AC', 'SUBJECT_DR']
        },
        {
            group: 'Alignment Quality',
            fields: ['RESULT_RCD', 'RESULT_RCQ', 'RESULT_RI', 'RESULT_RIQ', 'RESULT_RID', 'RESULT_FQ', 'RESULT_NFD', 'RESULT_RL', 'RESULT_RS', 'RESULT_ID',
                'RESULT_RE', 'RESULT_FALI_FORMATTED', 'RESULT_GAPQ', 'RESULT_GAPD', 'RESULT_COV', 'RESULT_VMH']
        },
        {
            group: 'Alignment Position',
            fields: ['RESULT_OOBQ', 'RESULT_OOEQ', 'RESULT_OOBD', 'RESULT_OOED'] // 'RESULT_OBQ', 'RESULT_OEQ', 'RESULT_OBD', 'RESULT_OED'
        },
        {
            group: 'Patent Info',
            fields: ['SUBJECT_SI', 'SUBJECT_PS', 'SUBJECT_FT']
        },
        {
            group: 'Sequence Attributes',
            fields: ['QUERY_L', 'SUBJECT_L', 'SUBJECT_MT', 'SUBJECT_PK', 'SUBJECT_CC', 'QUERY_DE']
        },
        {
            group: 'GenePast Parameters',
            fields: ['RESULT_GAPD', 'RESULT_GAPQ']
        },
        {
            group: 'Organism',
            fields: ['SUBJECT_OO', 'SUBJECT_OS', 'SUBJECT_OX', 'SUBJECT_OC']
        },
        {
            group: 'Non-Patent Records',
            fields: ['SUBJECT_SV', 'SUBJECT_RA', 'SUBJECT_RL', 'SUBJECT_DE']
        },
        {
            group: 'Complex Alignment Quality',
            fields: ['RESULT_RF', 'RESULT_RIG', 'RESULT_RM', 'RESULT_RZ']
        },
        {
            group: 'GQ-related',
            fields: ['UFS_DISPLAY', 'SUBJECT_PJ', 'SUBJECT_GNAME']
        },
        {
            group: 'CAS Biosequences™',
            fields: ['SUBJECT_SY', 'SUBJECT_BM', 'SUBJECT_CD', 'SUBJECT_RG', 'SUBJECT_RB', 'SUBJECT_RC', 'SUBJECT_DS', 'SUBJECT_NT', 'SUBJECT_CB', 'SUBJECT_CL', 'SUBJECT_GY', 'SUBJECT_CJ', 'SUBJECT_CK',
                'SUBJECT_C8', 'SUBJECT_C6', 'SUBJECT_MP', 'SUBJECT_C9', 'SUBJECT_DC', 'SUBJECT_CQ', 'SUBJECT_RE', 'SUBJECT_RD', 'SUBJECT_SX', 'SUBJECT_CU', 'SUBJECT_S8', 'SUBJECT_CM', 'SUBJECT_S9', 'SUBJECT_C7']
        },
        {
            group: 'CAS Biosequences™ ',
            fields: ['SUBJECT_SY', 'SUBJECT_BM', 'SUBJECT_CD', 'SUBJECT_RG', 'SUBJECT_RB', 'SUBJECT_RC', 'SUBJECT_DS', 'SUBJECT_NT', 'SUBJECT_MP', 'SUBJECT_RE', 'SUBJECT_RD', 'SUBJECT_SX', 'SUBJECT_S8', 'SUBJECT_CM', 'SUBJECT_S9']
        }
    ],
    flat: [
        {
            group: 'Patent Information',
            fields: ['SUBJECT_PN', 'SUBJECT_KC', 'SUBJECT_PV', 'SUBJECT_PT', 'SUBJECT_P1', 'SUBJECT_CT', 'SUBJECT_P7', 'SUBJECT_PC', 'SUBJECT_KW', 'VT_PDF', 'VT_FT']
        },
        {
            group: 'Legal Statuses',
            fields: ['SUBJECT_PG', 'SUBJECT_PH', 'SUBJECT_L3']
        },
        {
            group: 'Inventors & Assignees',
            fields: ['SUBJECT_PI', 'SUBJECT_PA', 'SUBJECT_PU', 'SUBJECT_PZ']
        },
        {
            group: 'Dates & Documents',
            fields: ['SUBJECT_PR', 'SUBJECT_PE', 'SUBJECT_D4', 'SUBJECT_D3', 'SUBJECT_AN', 'SUBJECT_D5', 'SUBJECT_D7', 'SUBJECT_D1',
                'SUBJECT_P4', 'SUBJECT_P5', 'SUBJECT_P8', 'SUBJECT_EM']
        },
        {
            group: 'Patent Family',
            fields: ['SUBJECT_PB', 'SUBJECT_PM', 'SUBJECT_XF', 'NBFAMMEMBERS', 'FAMMEMBERS_IN_CURRENT', 'FAMMEMBERS_OUT_CURRENT', 'FAMMEMBERS_OUT_GQ']
        },
        {
            group: 'GQ-related',
            fields: ['UFS_DISPLAY', 'SUBJECT_PJ', 'SUBJECT_PQ', 'SUBJECT_PO', 'SUBJECT_P9', 'SUBJECT_D2', 'SUBJECT_GNAME', 'SUBJECT_SEQIDNOSUMM']
        },
        {
            group: 'Identifiers',
            fields: ['QUERY_ID', 'SUBJECT_ID', 'SUBJECT_GX', 'SUBJECT_GI', 'SUBJECT_AC', 'SUBJECT_DR']
        },
        {
            group: 'Alignment Quality',
            fields: ['RESULT_RCD', 'RESULT_RCQ', 'RESULT_RI', 'RESULT_RIQ', 'RESULT_RID', 'RESULT_RL', 'RESULT_RS', 'RESULT_ID', 'RESULT_RE', 'RESULT_FALI_FORMATTED',
                'RESULT_GAPQ', 'RESULT_GAPD', 'RESULT_FQ', 'RESULT_NFD', 'RESULT_COV', 'RESULT_VMH']
        },
        {
            group: 'Alignment Position',
            fields: ['RESULT_OOBQ', 'RESULT_OOEQ', 'RESULT_OOBD', 'RESULT_OOED'] //'RESULT_OBQ', 'RESULT_OEQ', 'RESULT_OBD', 'RESULT_OED'
        },
        {
            group: 'Patent Info',
            fields: ['SUBJECT_SI', 'SUBJECT_PS', 'SUBJECT_FT']
        },
        {
            group: 'Sequence Attributes',
            fields: ['QUERY_L', 'SUBJECT_L', 'SUBJECT_MT', 'SUBJECT_PK', 'SUBJECT_CC', 'QUERY_DE']
        },
        {
            group: 'GenePast Parameters',
            fields: ['RESULT_GAPD', 'RESULT_GAPQ']
        },
        {
            group: 'Organism',
            fields: ['SUBJECT_OO', 'SUBJECT_OS', 'SUBJECT_OX', 'SUBJECT_OC']
        },
        {
            group: 'Non-Patent Records',
            fields: ['SUBJECT_SV', 'SUBJECT_RA', 'SUBJECT_RL', 'SUBJECT_DE']
        },
        {
            group: 'Complex Alignment Quality',
            fields: ['RESULT_RF', 'RESULT_RIG', 'RESULT_RM', 'RESULT_RZ']
        },
        {
            group: 'CAS Biosequences™',
            fields: ['SUBJECT_SY', 'SUBJECT_BM', 'SUBJECT_CD', 'SUBJECT_RG', 'SUBJECT_RB', 'SUBJECT_RC', 'SUBJECT_DS', 'SUBJECT_NT', 'SUBJECT_CB', 'SUBJECT_CL', 'SUBJECT_GY', 'SUBJECT_CJ', 'SUBJECT_CK',
                'SUBJECT_C8', 'SUBJECT_C6', 'SUBJECT_MP', 'SUBJECT_C9', 'SUBJECT_DC', 'SUBJECT_CQ', 'SUBJECT_RE', 'SUBJECT_RD', 'SUBJECT_SX', 'SUBJECT_CU', 'SUBJECT_S8', 'SUBJECT_CM', 'SUBJECT_S9', 'SUBJECT_C7']
        },
        {
            group: 'CAS Biosequences™ ',
            fields: ['SUBJECT_SY', 'SUBJECT_BM', 'SUBJECT_CD', 'SUBJECT_RG', 'SUBJECT_RB', 'SUBJECT_RC', 'SUBJECT_DS', 'SUBJECT_NT', 'SUBJECT_MP', 'SUBJECT_RE', 'SUBJECT_RD', 'SUBJECT_SX', 'SUBJECT_S8', 'SUBJECT_CM', 'SUBJECT_S9']
        }
    ]
};

// this creates a data structure with information for each field (coming from fieldConfig)
// this structure is used for display in the column chooser UI
let basicFieldConfig = {};
function createBasicFieldConfig() {
    _.forEach(groupConfigNew, function (val, type) {
        basicFieldConfig[type] = groupConfigNew[type];
    });
};
createBasicFieldConfig();

const typeDesc = {
    fam: 'Patent Family',
    doc: 'Patent Document',
    sub: 'Patent Sequence',
    ali: 'Alignment Properties',
    qry: 'Query Sequence',
    ufs: 'Unique Family Sequence',
    sin: 'Alignment / Subject / Query',
    flat: 'All fields',
    cas: 'CAS Registry',
    ssv: 'Subject Sequence',
    qs: 'Query Sequence',
    hsp: 'Blast HSPs'
};

//let expandedTableTemplate = {};    //  Unused?

// these fields are actually displayed in the table UI
// WARNING: this gets overwritten by what user has saved as default, so when changing things here
// make sure to remove the file that stores the user default values (see saveConfig / loadConfig below)
let userTableTemplate = {
    ufs: ["UFS_DISPLAY"],
    cas: ["SUBJECT_RG", 'SUBJECT_RB', 'SUBJECT_RC', 'SUBJECT_DS'],
    fam: ["SUBJECT_PB", "PB_DESC", "NBFAMMEMBERS", 'SUBJECT_PM', 'SUBJECT_XF'], // , 'FAMMEMBERS_IN_CURRENT', 'FAMMEMBERS_OUT_CURRENT', 'FAMMEMBERS_OUT_GQ'
    doc: ["SUBJECT_PN", "ALI_DESC", "SUBJECT_PG", "SUBJECT_D1", "SUBJECT_PZ"],
    ssv: ["SUBJECT_ID", "SUBJECT_L", "SUBJECT_PN", "SUBJECT_D1", "SUBJECT_PG", "SUBJECT_PA", "SUBJECT_PT"],
    qs: ["QUERY_ID", "QUERY_L"],
    hsp: ["QUERY_ID", "SUBJECT_ID", "QUERY_L"],
    sin: ["SUBJECT_SI", "QUERY_ID", "RESULT_RL", "RESULT_RIQ", "SUBJECT_PS"],
    flat: ["SUBJECT_PN", "QUERY_ID", "SUBJECT_ID", "SUBJECT_PA", "SUBJECT_PT", "SUBJECT_D1",
        "SUBJECT_PG", "SUBJECT_PS", "RESULT_RIQ", "RESULT_RID", "VT_PDF"],
};

const defaultUserTableTemplate = {
    ufs: ["UFS_DISPLAY"],
    cas: ["SUBJECT_RG", 'SUBJECT_RB', 'SUBJECT_RC', 'SUBJECT_DS'],
    fam: ["SUBJECT_PB", 'PB_DESC', "NBFAMMEMBERS", 'SUBJECT_PM', 'SUBJECT_XF'], // , 'FAMMEMBERS_IN_CURRENT', 'FAMMEMBERS_OUT_CURRENT', 'FAMMEMBERS_OUT_GQ'
    doc: ["SUBJECT_PN", "ALI_DESC", "SUBJECT_PG", "SUBJECT_D1", "SUBJECT_PZ"],
    ssv: ["SUBJECT_ID", "SUBJECT_L", "SUBJECT_PN", "SUBJECT_D1", "SUBJECT_PG", "SUBJECT_PA", "SUBJECT_PT"],
    qs: ["QUERY_ID", "QUERY_L"],
    hsp: ["QUERY_ID", "SUBJECT_ID", "QUERY_L"],
    sin: ["SUBJECT_SI", "QUERY_ID", "RESULT_RL", "RESULT_RIQ", "SUBJECT_PS"],
    flat: ["SUBJECT_PN", "QUERY_ID", "SUBJECT_ID", "SUBJECT_PA", "SUBJECT_PT", "SUBJECT_D1",
        "SUBJECT_PG", "SUBJECT_PS", "RESULT_RIQ", "RESULT_RID", "VT_PDF"],
};

let views = {};
let viewKeys = [];
let viewsHover = [];
let currentView;
let newViewName;

// To save the ids defined in patentDatabasePrefs in below,
// to seperate the id & display label, so that we could change the display label easily when needed
let databasePrefs = [];


function selectView(fieldConfig, viewName, resultFilter, editable) {
    if (viewName === "default") {
        // console.log(userTableTemplate);
        fieldConfig.userTableTemplate = {
            ufs: ["UFS_DISPLAY"],
            cas: ["SUBJECT_RG", 'SUBJECT_RB', 'SUBJECT_RC', 'SUBJECT_DS'],
            fam: ["SUBJECT_PB", "PB_DESC", "NBFAMMEMBERS", 'SUBJECT_PM', 'SUBJECT_XF'], // , 'FAMMEMBERS_IN_CURRENT', 'FAMMEMBERS_OUT_CURRENT', 'FAMMEMBERS_OUT_GQ'
            doc: ["SUBJECT_PN", "ALI_DESC", "SUBJECT_PG", "SUBJECT_D1", "SUBJECT_PZ"],
            ssv: ["SUBJECT_ID", "SUBJECT_L", "SUBJECT_PN", "SUBJECT_D1", "SUBJECT_PG", "SUBJECT_PA", "SUBJECT_PT"],
            qs: ["QUERY_ID", "QUERY_L"],
            hsp: ["QUERY_ID", "SUBJECT_ID", "QUERY_L"],
            sin: ["SUBJECT_SI", "QUERY_ID", "RESULT_RL", "RESULT_RIQ", "SUBJECT_PS"],
            flat: ["SUBJECT_PN", "QUERY_ID", "SUBJECT_ID", "SUBJECT_PA", "SUBJECT_PT", "SUBJECT_D1",
                "SUBJECT_PG", "SUBJECT_PS", "RESULT_RIQ", "RESULT_RID", "VT_PDF"],
        };
    } else if (viewName === "defaultAli") {
        fieldConfig.userTableTemplate = {
            ufs: ["UFS_DISPLAY"],
            cas: ["SUBJECT_RG", 'SUBJECT_RB', 'SUBJECT_RC', 'SUBJECT_DS'],
            fam: ["SUBJECT_PB", "PB_DESC", "NBFAMMEMBERS", 'SUBJECT_PM', 'SUBJECT_XF'], //, 'FAMMEMBERS_IN_CURRENT', 'FAMMEMBERS_OUT_CURRENT', 'FAMMEMBERS_OUT_GQ'
            doc: ["SUBJECT_PN", "ALI_DESC", "SUBJECT_PG", "SUBJECT_D1", "SUBJECT_PZ"],
            ssv: ["SUBJECT_ID", "SUBJECT_L", "SUBJECT_PN", "SUBJECT_D1", "SUBJECT_PG", "SUBJECT_PA", "SUBJECT_PT"],
            qs: ["QUERY_ID", "QUERY_L"],
            hsp: ["QUERY_ID", "SUBJECT_ID", "QUERY_L"],
            sin: ["SUBJECT_SI", "QUERY_ID", "RESULT_RL", "RESULT_RIQ", "SUBJECT_PS"],
            flat: ["SUBJECT_PN", "QUERY_ID", "SUBJECT_ID", "RESULT_FALI_FORMATTED", "SUBJECT_PA", "SUBJECT_PT", "SUBJECT_CT",
                "SUBJECT_D1", "SUBJECT_PG", "SUBJECT_PS", "RESULT_RIQ", "RESULT_RID"],
        };
    } else if (viewName === "defaultFam") {
        fieldConfig.userTableTemplate = {
            ufs: ["UFS_DISPLAY"],
            cas: ["SUBJECT_RG", 'SUBJECT_RB', 'SUBJECT_RC', 'SUBJECT_DS'],
            fam: ["SUBJECT_PB", "SUBJECT_PM", "SUBJECT_XF"],
            doc: ["SUBJECT_PN", "SUBJECT_PG", "SUBJECT_D1", "SUBJECT_D4", "SUBJECT_PA", "SUBJECT_PT"],
            ssv: ["SUBJECT_ID", "SUBJECT_L", "SUBJECT_PN", "SUBJECT_D1", "SUBJECT_PG", "SUBJECT_PA", "SUBJECT_PT"],
            qs: ["QUERY_ID", "QUERY_L"],
            hsp: ["QUERY_ID", "SUBJECT_ID", "QUERY_L"],
            sin: ["SUBJECT_ID", "QUERY_ID", "RESULT_RIQ", "RESULT_RID", "SUBJECT_PS"],
            flat: ["SUBJECT_PN", "SUBJECT_D1", "SUBJECT_PG", "QUERY_ID", "SUBJECT_SI", "RESULT_RL", "RESULT_RIQ"],
        };
    } else if (viewName === "defaultSsv") {
        fieldConfig.userTableTemplate = {
            ufs: ["UFS_DISPLAY"],
            cas: ["SUBJECT_RG", 'SUBJECT_RB', 'SUBJECT_RC', 'SUBJECT_DS'],
            fam: ["SUBJECT_PB", "SUBJECT_PM", "SUBJECT_XF"],
            doc: ["SUBJECT_PN", "SUBJECT_PG", "SUBJECT_D1", "SUBJECT_D4", "SUBJECT_PA", "SUBJECT_PT"],
            ssv: ["SUBJECT_ID", "SUBJECT_L", "SUBJECT_PN", "SUBJECT_D1", "SUBJECT_PG", "SUBJECT_PA", "SUBJECT_PT"],
            qs: ["QUERY_ID", "QUERY_L"],
            hsp: ["QUERY_ID", "SUBJECT_ID", "QUERY_L"],
            sin: ["QUERY_ID", "RESULT_RIQ", "RESULT_RID", "RESULT_RL", "RESULT_RI"],
            flat: ["QUERY_ID", "RESULT_RIQ", "RESULT_RID", "RESULT_RL", "RESULT_RI"],
        };
    } else if (viewName === "defaultFt") {
        fieldConfig.userTableTemplate = {
            ufs: ["UFS_DISPLAY"],
            cas: ["SUBJECT_RG", 'SUBJECT_RB', 'SUBJECT_RC', 'SUBJECT_DS'],
            fam: ["SUBJECT_PB", "SUBJECT_PM", "SUBJECT_XF"],
            doc: ["SUBJECT_PN", "SUBJECT_PT", "SUBJECT_PU", "SUBJECT_D1", "SUBJECT_PG", "VT_FT"],
            ssv: ["SUBJECT_ID", "SUBJECT_L", "SUBJECT_PN", "SUBJECT_D1", "SUBJECT_PG", "SUBJECT_PA", "SUBJECT_PT"],
            qs: ["QUERY_ID", "QUERY_L"],
            hsp: ["QUERY_ID", "SUBJECT_ID", "QUERY_L"],
            sin: [],
            flat: ["SUBJECT_PN", "SUBJECT_AN", "SUBJECT_PT", "SUBJECT_D1", "SUBJECT_D4", "SUBJECT_PU",
                "SUBJECT_P1", "SUBJECT_PE", "SUBJECT_PG"],
        };
    } else {
        if (!fieldConfig.views[viewName]) { // To filter the delete view case
            return;
        }
        // Reset selected fields
        fieldConfig.userTableTemplate = fieldConfig.views[viewName];

        // Log
        //Log.record('COLUMN_DISPLAY', 'Switch to ' + viewName);
    }
    // Re-calculate the table width
    estimateTableWidth(fieldConfig.userTableTemplate, fieldConfig.tableDisplayMode);
    fieldConfig.currentView = viewName;
    if (editable === true) {
        saveConfig(fieldConfig, 'default', resultFilter);
    }
}

function saveView(fieldConfig, newViewName, resultFilter) {
    let currentTemplate = _.cloneDeep(fieldConfig.userTableTemplate);
    fieldConfig.views[newViewName] = currentTemplate;
    // Save the grouping info as well
    fieldConfig.views[newViewName].tableDisplayMode = fieldConfig.tableDisplayModeFromView;
    if (fieldConfig.viewKeys.indexOf(newViewName) < 0) {
        fieldConfig.viewKeys.push(newViewName);
    }

    // Sort by name
    fieldConfig.viewKeys.sort();

    estimateTableWidth(fieldConfig.userTableTemplate, fieldConfig.tableDisplayMode);
    saveConfig(fieldConfig, 'default', resultFilter);
};

function checkViewNameUsed() {
    let retVal = -1;
    if (this.views !== undefined) {
        let keys = Object.keys(this.views);
        for (let i = 0; i < keys.length; i++) {
            if (keys[i] == this.newViewName) {
                retVal = i;
                break;
            }
        }

        if (this.newViewName && this.newViewName.trim().length > 0) {
            buttonHighlighted = true;
        } else {
            buttonHighlighted = false;
        }
    }
    return retVal;
}

function deleteView(fieldConfig, viewName, resultFilter) {
    delete fieldConfig.views[viewName];
    if (fieldConfig.viewKeys.indexOf(viewName) >= 0) {
        fieldConfig.viewKeys.splice(fieldConfig.viewKeys.indexOf(viewName), 1);
    }
    if (viewName === fieldConfig.currentView) {
        fieldConfig.currentView = 'default';
    }
    saveConfig(fieldConfig, 'default', resultFilter);
}

// return flat array of all fields currently displayed
function getCurrentDisplayModeFieldInfo(fieldConfig) {
    let fieldDef, rv, template, fullTemplate, config = {};
    fieldDef = getDisplayModeDef(fieldConfig);
    rv = [];            //  All fields displayed, regardless of grouping
    fullTemplate = {};  //  Fields to display for each group
    for (let i = 0; i < fieldDef.length; i++) {
        template = fieldConfig.userTableTemplate[fieldDef[i]];
        fullTemplate[fieldDef[i]] = template;
        for (let j = 0; j < template.length; j++) {
            rv.push(template[j]);
            config[template[j]] = fieldConfig.fieldConfig[template[j]];    //  Detailed field info
        }
    }
    // returns three things
    //      [0] flat array with all fields from all grouping levels combined
    //      [1] object with grouping levels as keys and array of fields in that group as value
    //      [2] object with fieldId as key and full field configuration as value
    return [rv, fullTemplate, config];
};

function getColumnsInfoForExport(fieldConfig) {
    let groupedColumns = [];
    let detailedColumns = [];
    let fieldDef = getDisplayModeDef(fieldConfig);
    let template, i, j;
    for (i = 0; i < fieldDef.length; i++) {
        template = fieldConfig.userTableTemplate[fieldDef[i]];
        for (j = 0; j < template.length; j++) {
            if (i == 0) {
                groupedColumns.push(template[j]);
            } else {
                detailedColumns.push(template[j]);
            }
        }
    }
    return [groupedColumns, detailedColumns];
}

// get information for a specific field (used in UI)
function getFieldInfo(field) {
    return fieldConfig[field];
};

function displayField(item, field) {
    if (item && fieldConfig[field] && item[field]) {
        if (fieldConfig[field].display === 'YYYYMMDD') {
            return item[field] > 0;
        } else if (field == 'SUBJECT_FAMINFO' || field == 'SUBJECT_SEQIDNOSUMM' || field == 'NBFAMMEMBERS' || field == 'SUBJECT_CT'
            || field == 'FAMMEMBERS_IN_CURRENT' || field == 'FAMMEMBERS_OUT_CURRENT' || field == 'FAMMEMBERS_OUT_GQ' || field == 'PB_DESC') {
            // Check if Patent databases or Doc Search results
            if (item['VT_FT'] && item['VT_FT'] !== '' && (field == 'SUBJECT_CT' || field == 'SUBJECT_FAMINFO')) {
                return true;
            }
            return isPatentDatabase(item['SUBJECT_GNAME']);
        } else if (Array.isArray(item[field])) {
            return item[field].length > 0;
        } else if (field == 'UFS_DISPLAY' && item['SUBJECT_GNAME']) {
            let dbId = _.split(item['SUBJECT_GNAME'], ':')[0];
            if (dbId == 'CASNP_NUC' || dbId == 'CASNP_PRT' || dbId == 'REGNP_NUC' || dbId == 'REGNP_PRT') {
                return false;
            }
            return true;
        } else {
            return true;
        }
    }
    return false;
};

function getFieldName(fieldId, algorithm, isABWorkflow) {
    if (fieldId === 'RESULT_RS') {
        if (algorithm && algorithm.toLowerCase() === 'fragment_search') {
            return 'Number of Matches';
        } else if (algorithm && (algorithm.toLowerCase() === 'genepast' || isABWorkflow)) {
            return 'Diff. Count';
        } else {
            return 'Align. Score';
        }
    }
    return fieldConfig[fieldId] === undefined ? "" : fieldConfig[fieldId].title;
}

// default table display mode
let tableDisplayMode = 'flat';
let tableDisplayModeFromView = 'flat';
let famViewOption = '3';
let famViewAliOption = '10';
let pnViewOption = '10';
let ssvOption = '10';
let ufsOption = '10';

// definition of available display modes and what is at each grouping level
const displayModes = {
    family: [
        'fam', 'doc', 'sin'
    ],
    document: [
        'doc', 'sin'
    ],
    cas: [
        'cas', 'flat'
    ],
    flat: [
        'flat'
    ],
    ufs: [
        'ufs', 'fam', 'flat'
    ],
    ssv: [
        'ssv', 'sin'
    ],
    qs: [
        'qs', 'sin'
    ],
    hsp: [
        'hsp', 'sin'
    ]
};

// more information about display modes
const displayDesc = {
    family: {
        desc: 'Patent Family',
        objectsPerPage: 5
    },
    document: {
        desc: 'Patent Document',
        objectsPerPage: 10
    },
    cas: {
        desc: 'CAS Registry',
        objectsPerPage: 10
    },
    ssv: {
        desc: 'Subject Sequence',
        objectsPerPage: 10
    },
    flat: {
        desc: 'Alignment',
        objectsPerPage: 50
    },
    ufs: {
        desc: 'Unique Sequence within Family',
        objectsPerPage: 5
    },
    qs: {
        desc: 'Query Sequence',
        objectsPerPage: 10
    },
    hsp: {
        desc: 'Blast HSPs',
        objectsPerPage: 10
    },
};

// number of objects per page leties with display mode
function getObjectsPerPage() {
    return displayDesc[tableDisplayMode].objectsPerPage;
};

// get a textual description of the display mode
function getDisplayModeDescription() {
    return displayDesc[this.tableDisplayModeFromView].desc;
};

// get a definition (grouping types like fam, doc, sin, famdoc, flat, ufs, sub, ali, qry) for a display mode
function getDisplayModeDef(fieldConfig) {
    return displayModes[fieldConfig.tableDisplayMode];
};

// change the display mode
function setDisplayMode(displayMode) {
    tableDisplayMode = displayMode;
    //Log.record('GROUPING', displayMode);
};

// see if grouping type (fam, doc, sin, famdoc, flat, ufs, sub, ali, qry) is in current display mode
function typeIsInDisplayMode(type) {
    return (_.indexOf(displayModes[this.tableDisplayModeFromView], type) !== -1);
};

// estimate the width of the table before it is displayed using column definition
let tableWidth = 900;
function estimateTableWidth(userTableTemplate, tableDisplayMode) {
    let i, width, maxWidth, typesInDisplayMode, thisTypeIsDisplayed, allFields, field, fieldInfo;
    maxWidth = 0;
    _.forEach(userTableTemplate, function (table, type) {
        typesInDisplayMode = displayModes[tableDisplayMode];
        thisTypeIsDisplayed = (_.indexOf(typesInDisplayMode, type) !== -1);
        if (thisTypeIsDisplayed) {
            // loop through all fields in type
            width = 330; // guess for width of annotation, 310 -> 330, for user notes
            allFields = userTableTemplate[type];
            for (i = 0; i < allFields.length; i++) {
                field = allFields[i];
                fieldInfo = fieldConfig[field];
                width += parseInt(fieldInfo === undefined ? 0 : fieldInfo.colwidth) + 20; // Seems more correct to be 20 instead of 10, before & after gaps
            }
            if (width > maxWidth) {
                maxWidth = width;
            }
        }
    });
    tableWidth = maxWidth < 900 ? 900 : maxWidth; // Set a minWidth as 900
    return tableWidth;
};

function refreshCols() {
    let arr = _.cloneDeep(userTableTemplate);
    userTableTemplate = {};
    userTableTemplate = _.cloneDeep(arr);
    document.getElementById("dispCols").focus();
}


// Reset the displayed columns
function resetColsDisplay(fieldConfig, filter) {
    // Reset the field length
    fieldConfig.fieldConfig = _.cloneDeep(defaultFieldConfig);
    // Reset selected fields
    fieldConfig.userTableTemplate = _.cloneDeep(defaultUserTableTemplate);
    if (fieldConfig.tableDisplayMode !== 'ssv') {
        // Change back to the default display column list
        fieldConfig.userTableTemplate["sin"] = ["SUBJECT_SI", "QUERY_ID", "RESULT_RL", "RESULT_RIQ", "SUBJECT_PS"];
    } else {
        fieldConfig.userTableTemplate["sin"] = ["QUERY_ID", "RESULT_RIQ", "RESULT_RID", "RESULT_RL", "RESULT_RI"];
    }
    // Reset unselected fields
    //this.userTableTemplate = userTableTemplate;
    basicFieldConfig = {};
    createBasicFieldConfig();

    // Log
    //Log.record('COLUMN_DISPLAY', 'Reset to default columns');
    // Re-calculate the table width
    estimateTableWidth(fieldConfig.userTableTemplate, fieldConfig.tableDisplayMode);  //TODO

    saveConfig(fieldConfig,'default',filter);
}

// Reset the displayed columns

// take selected fields from shuttle and move them into current template
function addFieldsToTemplate(multipleFields, userTableTemplate) {
    let type, field;
    _.forEach(multipleFields, function (fieldComb, key) {
        type = fieldComb.split('|')[0];
        field = fieldComb.split('|')[1];
        addFieldToTemplate(type, field, userTableTemplate);
    });
    //estimateTableWidth();
};

// take selected fields from shuttle and remove them from the current template
function delFieldsFromTemplate(multipleFields, userTableTemplate) {
    let type, field;
    _.forEach(multipleFields, function (fieldComb, key) {
        type = fieldComb.split('|')[0];
        field = fieldComb.split('|')[1];
        removeFieldFromTemplate(type, field, userTableTemplate);
    });
    //estimateTableWidth();
    // saveConfig('default');
    //selected = null;
};

// add a single field to the template
function addFieldToTemplate(type, field, userTableTemplate) {
    // copy the new configuration back to configService
    if (userTableTemplate[type].indexOf(field) < 0) { // Avoid adding duplicate columns
        userTableTemplate[type].push(field);
        //Log.record('COLUMN_DISPLAY', type + ' ' + field);
        estimateTableWidth();
    }
};

// remove a single field from the template
function removeFieldFromTemplate(type, field, userTableTemplate) {
    let idx = _.findIndex(userTableTemplate[type], function (obj) {
        return obj == field;
    });
    _.pullAt(userTableTemplate[type], idx);
    //Log.record('COLUMN_REMOVE', type + ' ' + field);
    estimateTableWidth();
};

function promiseMoveFieldsUp(multipleFields, userTableTemplate) {
    //let promise= moveFieldsUp(multipleFields);
    let promise = new Promise((resolve, reject) => {
        let p = moveFieldsUp(multipleFields, userTableTemplate);
        if (p === "worked") {
            resolve("Promise resolved successfully");
        }
        else {
            reject(Error("Promise rejected"));
        }
    }
    );

    promise.then(function () {
        estimateTableWidth();
    }, function () {
        alert('error reorganizing up');
    });
};

function moveFieldsUp(multipleFields, userTableTemplate) {

    let deferred = ""; //$q.defer(); TODO
    try {
        if (multipleFields !== null) {

            let viewType = new Set();

            for (let i = 0; i < multipleFields.length; i++) {
                viewType.add(multipleFields[i].split('|')[0]);
            }

            viewType.forEach(function (value) {
                let curMultipleFields = [];
                multipleFields.forEach(function (element) {
                    if (element.indexOf(value) == 0) {
                        curMultipleFields.push(element);
                    }
                });
                let field, pos;
                let arr = userTableTemplate[value];
                let firstField = curMultipleFields[0].split('|')[1];
                let startPos = _.indexOf(arr, firstField),
                    prevPos = _.indexOf(arr, firstField);
                let indexArr = [];
                let firstObj = {};
                firstObj[startPos] = firstField;
                indexArr.push([firstObj]);

                for (let i = 1; i < curMultipleFields.length; i++) {
                    field = curMultipleFields[i].split('|')[1];
                    pos = _.indexOf(arr, field);
                    let fieldObj = {};
                    fieldObj[pos] = field;
                    if (pos - prevPos > 1) {
                        indexArr.push([fieldObj]);
                    } else {
                        indexArr[indexArr.length - 1].push(fieldObj);
                    }
                    prevPos = pos;
                }
                _.forEach(indexArr, function (index) {
                    pos = parseInt(Object.keys(index[0])[0]);
                    if (pos === 0) {
                        return;
                    }
                    arr.splice(pos, index.length);
                    _.forEach(index, function (s) {
                        let tarPos = pos - 1;
                        if (tarPos < 0) {
                            tarPos = 0;
                        }
                        arr.splice(tarPos, 0, Object.values(s)[0]);
                        pos++;
                    });
                });
                userTableTemplate[value] = _.cloneDeep(arr);
                //setUserTableTemplate(arr, value);
            });
        }

        deferred = 'worked';

    } catch (error) {
        deferred = 'bad news';
    }

    return deferred;
};


function promiseMoveFieldsDown(multipleFields, userTableTemplate) {
    //let promise= moveFieldsDown(multipleFields);
    let promise = new Promise((resolve, reject) => {
        let p = moveFieldsDown(multipleFields, userTableTemplate);
        if (p === "worked") {
            resolve("Promise resolved successfully");
        }
        else {
            reject(Error("Promise rejected"));
        }
    }
    );
    promise.then(result => {
        estimateTableWidth();
    }, function () {
        alert('error reorganizing down');
    });

};

function moveFieldsDown(multipleFields, userTableTemplate) {

    let deferred = ""; // $q.defer();TODO

    try {

        if (multipleFields !== null) {
            let viewType = new Set();
            for (let i = 0; i < multipleFields.length; i++) {
                viewType.add(multipleFields[i].split('|')[0]);
            }
            viewType.forEach(function (value) {
                let curMultipleFields = [];
                multipleFields.forEach(function (element) {
                    if (element.indexOf(value) == 0) {
                        curMultipleFields.push(element);
                    }
                });
                let field, pos;
                let arr = userTableTemplate[value];
                let firstField = curMultipleFields[0].split('|')[1];
                let startPos = _.indexOf(arr, firstField),
                    prevPos = _.indexOf(arr, firstField);
                let indexArr = [];
                let firstObj = {};
                firstObj[startPos] = firstField;
                indexArr.push([firstObj]);

                for (let i = 1; i < curMultipleFields.length; i++) {
                    field = curMultipleFields[i].split('|')[1];
                    pos = _.indexOf(arr, field);
                    let fieldObj = {};
                    fieldObj[pos] = field;
                    if (pos - prevPos > 1) {
                        indexArr.push([fieldObj]);
                    } else {
                        indexArr[indexArr.length - 1].push(fieldObj);
                    }
                    prevPos = pos;
                }
                _.forEach(indexArr, function (index) {
                    pos = parseInt(Object.keys(index[0])[0]);
                    arr.splice(pos, index.length);
                    _.forEach(index, function (s) {
                        arr.splice(pos + 1, 0, Object.values(s)[0]);
                        pos++;
                    });
                });
                userTableTemplate[value] = _.cloneDeep(arr);
                //setUserTableTemplate(arr, value);

            });

        }

        deferred = 'worked';

    } catch (error) {
        deferred = 'bad news';
    }

    return deferred;

};

function getUserTableTemplate() {
    return this.userTableTemplate;
};

function setUserTableTemplate(arr, val) {
    userTableTemplate[val] = _.cloneDeep(arr);
};


// verifies whether a field is present in the current template
function fieldIsInTableTemplate(type, field) {
    return (_.indexOf(this.userTableTemplate[type], field) !== -1);
};

// returns a textual description of a field type (fam,doc,sub,...)
function getTypeDescription(type) {
    return typeDesc[type];
};


/* ================ fullRecord Expand ================ */

// This is the setup for the detail view
// WARNING: this data structure gets overwritten by what ever is saved in the user preferences
// make sure to remove the user default file before changing anything here (maybe we need to version them)
const detailViewConfig = {
    ufs: [
        'UFS_DISPLAY', 'SUBJECT_PJ'
    ],
    cas: [
        'SUBJECT_RG', 'SUBJECT_RB', 'SUBJECT_RC', 'SUBJECT_DS', 'SUBJECT_SY', 'SUBJECT_NT', 'SUBJECT_SX', 'SUBJECT_CM', 'SUBJECT_RE'
    ],
    qs: ["QUERY_ID", "QUERY_L", "QUERY_DE"],
    hsp: ["QUERY_ID", "SUBJECT_ID", "RESULT_FQ", "RESULT_NFD", "QUERY_L", "QUERY_DE"],
    ssv: [
        'DOCHEADER', 'DOCLINKS',
        "SUBJECT_ID", "SUBJECT_L", "SUBJECT_PN", "SUBJECT_D1",  //"SUBJECT_PG","SUBJECT_PA", "SUBJECT_PT", "SUBJECT_PI",
        'ASSIGNEES', "PATENT_DATES", 'LEGAL_STATUS', "SUBJECT_FAMINFO",
        "SUBJECT_P1", "SUBJECT_PS", "SUBJECT_SI",
        //"SUBJECT_D3", "SUBJECT_D4", "SUBJECT_D5", "SUBJECT_P5",
        //"SUBJECT_PB", "SUBJECT_PM", "NBFAMMEMBERS", "SUBJECT_PJ", "UFS_DISPLAY",
        "SUBJECT_OS", "SUBJECT_OO", "SUBJECT_OX", "SUBJECT_GX", "SUBJECT_GI",
        "SUBJECT_GNAME", "SUBJECT_CC"],
    fam: [
        'SUBJECT_PB', 'SUBJECT_PM', 'SUBJECT_XF', 'NBFAMMEMBERS', 'FAMMEMBERS_IN_CURRENT', 'FAMMEMBERS_OUT_CURRENT', 'FAMMEMBERS_OUT_GQ'
    ],
    doc: [
        'DOCHEADER', 'DOCLINKS', 'SUBJECT_P1',
        'SUBJECT_CT',
        'ASSIGNEES', 'PATENT_DATES', 'SUBJECT_PC', 'SUBJECT_SEQIDNOSUMM', 'LEGAL_STATUS',
        'SUBJECT_FAMINFO',
        'SUBJECT_PE',
        'SUBJECT_EM', 'SUBJECT_PQ',
        // 'PCT_GROUP' for 'SUBJECT_P4', 'SUBJECT_P5', 'SUBJECT_P8',
        'PCT_GROUP',
        'SUBJECT_PO', 'SUBJECT_CC',
        //'SUBJECT_GNAME',
    ],
    ali: [
        'QUERY_ALI_INFO', 'SUBJECT_ALI_INFO', 'ALI_INFO'
    ],
    qry: [
        'QUERY_ID', 'QUERY_L', 'QUERY_O', 'QUERY_DE'
    ],
    sin: [
        'QUERY_ALI_INFO', 'SUBJECT_ALI_INFO',
        'SUBJECT_PS', 'ALI_INFO', 'SUBJECT_P1',
        // CAS registry fields
        'SUBJECT_CJ', 'SUBJECT_RD', 'SUBJECT_KW', 'SUBJECT_RA', 'SUBJECT_GY', 'SUBJECT_CU', // D1
        'SUBJECT_C7', 'SUBJECT_C8', 'SUBJECT_C9', 'SUBJECT_D1', 'SUBJECT_DC', 'SUBJECT_C6', 'SUBJECT_CQ', 'SUBJECT_CB', 'SUBJECT_DS', 'SUBJECT_SY', 'SUBJECT_DE',
        // 'SUBJECT_RG', 'SUBJECT_RB', 'SUBJECT_RC' into 'CAS_RGGROUP'
        'CAS_RGGROUP', 'SUBJECT_NT', 'SUBJECT_BM', 'SUBJECT_S9', 'SUBJECT_MP',
        'SUBJECT_S8', 'SUBJECT_SX', 'SUBJECT_RE', 'SUBJECT_CM',
        // Other fields
        'SUBJECT_FT', 'SUBJECT_MT', //'SUBJECT_AC' moved into 'SUBJECT_ALI_INFO'
        'ORGANISM',
        'SUBJECT_PK', 'SUBJECT_RL', 'SUBJECT_SV',
        // CAS registry fields, 'SUBJECT_CD', 'SUBJECT_CK', 'SUBJECT_CL',
        'CAS_CDGROUP',
        // Other fields, 'SUBJECT_GX', 'UFS_DISPLAY', 'SUBJECT_PJ',
        'GXGROUP',
        // GeneSeq fields
        'SUBJECT_DR',
        'SUBJECT_GNAME', 'SUBJECT_CC'
    ],
    sin4ssv: [
        'QUERY_ALI_INFO', 'SUBJECT_ALI_INFO', 'ALI_INFO',
    ],

    flat: [
        'DOCHEADER', 'DOCLINKS',
        'QUERY_ALI_INFO', 'SUBJECT_ALI_INFO',
        'SUBJECT_PS', 'ALI_INFO', 'SUBJECT_P1',
        'SUBJECT_CT',
        'ASSIGNEES', 'PATENT_DATES', 'SUBJECT_PC', 'SUBJECT_SEQIDNOSUMM', 'LEGAL_STATUS',
        'SUBJECT_FAMINFO',
        // CAS registry fields
        'SUBJECT_CJ', 'SUBJECT_RD', 'SUBJECT_KW', 'SUBJECT_RA', 'SUBJECT_GY', 'SUBJECT_CU', // D1
        'SUBJECT_C7', 'SUBJECT_C8', 'SUBJECT_C9', 'SUBJECT_D1', 'SUBJECT_DC', 'SUBJECT_C6', 'SUBJECT_CQ', 'SUBJECT_CB', 'SUBJECT_DS', 'SUBJECT_SY', 'SUBJECT_DE',
        // 'SUBJECT_RG', 'SUBJECT_RB', 'SUBJECT_RC' into 'CAS_RGGROUP'
        'CAS_RGGROUP', 'SUBJECT_NT', 'SUBJECT_BM', 'SUBJECT_S9', 'SUBJECT_MP',
        'SUBJECT_S8', 'SUBJECT_SX', 'SUBJECT_RE', 'SUBJECT_CM',
        // Other fields
        'SUBJECT_EM', 'SUBJECT_PQ',
        // 'PCT_GROUP' for 'SUBJECT_P4', 'SUBJECT_P5', 'SUBJECT_P8',
        'PCT_GROUP',
        'SUBJECT_FT', 'SUBJECT_MT', //'SUBJECT_AC' moved into 'SUBJECT_ALI_INFO'
        'ORGANISM',
        'SUBJECT_PK', 'SUBJECT_RL', 'SUBJECT_SV',
        // CAS registry fields, 'SUBJECT_CD', 'SUBJECT_CK', 'SUBJECT_CL',
        'CAS_CDGROUP',
        // Other fields, 'SUBJECT_GX', 'UFS_DISPLAY', 'SUBJECT_PJ',
        'GXGROUP',
        'SUBJECT_PO',
        // GeneSeq fields
        'SUBJECT_DR',
        'SUBJECT_GNAME', 'SUBJECT_CC'
    ]

};

// which information to show in the detailed view
function getFullRecordFields(type, tableDisplayMode) {
    if (tableDisplayMode === 'ssv' && type === 'sin') {
        return detailViewConfig['sin4ssv'];
    }
    return detailViewConfig[type];
};


async function loadConfig(pref_context, fieldConfig, resultFilter, workflowId) {
    // console.log('loading config');
    let apiUrl = 'do=gqpref.get&format=json&prefname=all&context=' + pref_context
        + '&workflow=' + workflowId + '&report=' + fieldConfig.reportId;
    try {
        return await get(apiUrl)
            .then((response) => {
                if (response.response_status == 0 && response.response_content) {
                    if (typeof response.response_content === 'string') {
                        response.response_content = JSON.parse(response.response_content);
                    }

                    // just copy the colwidth property which is the only thing the user can customize
                    if (response.response_content.fieldConfig) {
                        let fc = response.response_content.fieldConfig;
                        _.each(fc, function (val, key) {
                            if (key in fieldConfig.fieldConfig) {
                                fieldConfig.fieldConfig[key].colwidth = val.colwidth;
                            }
                        });
                    }
                    if (response.response_content.views) {
                        fieldConfig.views = response.response_content.views;
                        fieldConfig.viewKeys = [];
                        _.each(fieldConfig.views, function (val, key) {
                            fieldConfig.viewKeys.push(key);
                        });
                        fieldConfig.viewKeys.sort();
                    } else {
                        fieldConfig.views = {};
                        fieldConfig.viewKeys = [];
                        fieldConfig.viewsHover = [];
                    }
                    if (response.response_content.databasePrefs) {
                        fieldConfig.databasePrefs = response.response_content.databasePrefs;
                    }
                    if (response.response_content.widgetState) {
                        // restore all the custom advanced filters created by the user
                        resultFilter.widgetState = response.response_content.widgetState;
                        if (!resultFilter.widgetState.globalLvaFilters) {
                            resultFilter.widgetState.globalLvaFilters = [];
                        }
                        if (!resultFilter.widgetState.globalLvaFiltersHover) {
                            resultFilter.widgetState.globalLvaFiltersHover = [];
                        }

                        for (let key in resultFilter.widgetState.customFilters) {
                            let filter = resultFilter.widgetState.customFilters[key];
                            if (!filter.uuid) {
                                filter.uuid = resultFilter.generateUUID();
                            }
                        }
                    }
                    if (response.response_content.customSortConfig) {
                        fieldConfig.customSortConfig = response.response_content.customSortConfig;
                    }
                }
                return fieldConfig.viewKeys;
            })
            .catch((error) => {
                //toast.error('Something Wrong! Try Again.');
                console.log("error::", error);
            });
    } catch (error) {
        console.error(error);
    }
};
//loadConfig('default');

// save the configuration that is loaded in loadConfig()
// this is called from the UI when the user rearranges columns in the table, or when a colum width is resized
async function saveConfig(fieldConfig, pref_context, resultFilter) {
    //if (fieldConfig.singleAlignment !== true && fieldConfig.forAb !== true
        //&& fieldConfig.forAlert !== true && fieldConfig.forVm !== true) {
        let changedFieldConfig = {};
        // extract only the colwidth to be saved
        _.each(fieldConfig.fieldConfig, function (val, key) {
            if (key in fieldConfig.fieldConfig && "colwidth" in fieldConfig.fieldConfig[key]) {
                changedFieldConfig[key] = { colwidth: fieldConfig.fieldConfig[key].colwidth };
            }
        });

        let data = {
            // field configuration (column width)
            fieldConfig: changedFieldConfig,
            // which columns are displayed in the table
            // userTableTemplate: userTableTemplate,
            // save all the custom advanced filters created by the user
            widgetState: resultFilter.widgetState,
            views: fieldConfig.views,
            // Database preference
            databasePrefs: fieldConfig.databasePrefs,
            // Sort Options
            customSortConfig: fieldConfig.customSortConfig
        };

        // Check the data if it is a valid JSON string, and its length < 65535
        /*if (JSON.stringify(data).length > 65000) { // 65535 length limitation in preference_value table
            fieldConfig.alertMsg = 'Maximum preference value limit reached. Please reduce your views or advanced filters to proceed.';
            fieldConfig.showAlert = true;
            return;
        }*/

        const postData = {
            'do': "gqpref.set",
            'context': 'result_rb2',
            'prefname': 'all',
            'pref': data
        }
        let apiUrl = 'do=gqpref.set&format=json';
        try {
            return await post(apiUrl, postData)
                .then((response) => {
                    return response;
                })
                .catch((error) => {
                    //toast.error('Something Wrong! Try Again.');
                    console.log("error::", error);
                });
        } catch (error) {
            console.error(error);
        }
    //}
}

function dismissInfoMsg() {
    alertMsg = '';
    showAlert = false;
}

let buttonHighlighted = false;
function viewNameEntered(viewName) {
    if (viewName && viewName.trim().length > 0) {
        buttonHighlighted = true;
    } else {
        buttonHighlighted = false;
    }
};

const javaConvert = {
    family: {
        familyMembers: "SUBJECT_PM",
        extendedPatentFamily: "SUBJECT_XF",
        familyID: "SUBJECT_PB",
        familyMemberCount: "NBFAMMEMBERS",
        pnDesc: "PB_DESC",
        //aliDesc: "PB_DESC",
        familyMemberInfo: "SUBJECT_FAMINFO",
        patentsInCurrentResults: "FAMMEMBERS_IN_CURRENT",
        patentsFilteredOut: "FAMMEMBERS_OUT_CURRENT",
        patentsNotInGQ: "FAMMEMBERS_OUT_GQ"
    },
    patent: {
        patentNumber: "SUBJECT_PN",
        kindCode: "SUBJECT_KC",
        patentAbstract: "SUBJECT_P1",
        applicationNumber: "SUBJECT_AN",
        claimedSeqIdNos: "SUBJECT_PO",
        claims: "SUBJECT_CT",
        classification: "SUBJECT_PC",
        databaseName: "SUBJECT_GNAME",
        dateOfEntry: "SUBJECT_D2",
        earliestPublicationDate: "SUBJECT_D5",
        submissionDate: "SUBJECT_D7",
        equivalentMemberType: "SUBJECT_EM",
        extendedLegalStatus: "SUBJECT_PG",
        filingDate: "SUBJECT_D3",
        inventors: "SUBJECT_PI",
        legalStatusNationalPhase: "SUBJECT_PH",
        normalizedParent: "SUBJECT_PZ",
        normalizedPatentAssignee: "SUBJECT_PU",
        numberOfClaims: "SUBJECT_P7",
        numberOfSequences: "SUBJECT_P9",
        patentAssignee: "SUBJECT_PA",
        patentId: "SUBJECT_PV",
        pctPublicationDate: "SUBJECT_P5",
        pctPublicationNumber: "SUBJECT_P4",
        pctRelatedDates: "SUBJECT_P8",
        priorityDate: "SUBJECT_D4",
        priorityInfo: "SUBJECT_PR",
        priorityNumber: "SUBJECT_PE",
        publicationDate: "SUBJECT_D1",
        seqListingEquivalents: "SUBJECT_PQ",
        title: "SUBJECT_PT",
        uspairLegalStatus: "SUBJECT_L3",
        comment: "SUBJECT_CC",
        // patentsInCurrentResults: "FAMMEMBERS_IN_CURRENT",
        // patentsFilteredOut: "FAMMEMBERS_OUT_CURRENT",
        // patentsNotInGQ: "FAMMEMBERS_OUT_GQ"
    },
    alignment: {
        queryId: "QUERY_ID",
        subjectId: "SUBJECT_ID",
        resultId: "RESULT_ID",
        accessionNumber: "SUBJECT_AC",
        alignmentLength: "RESULT_RL",
        alignmentScore: "RESULT_RS",
        comment: "SUBJECT_CC",
        expectValue: "RESULT_RE",
        featureTable: "SUBJECT_FT",
        genomeQuestAlignment: "RESULT_FALI_FORMATTED",
        lenAlignMinLen: "RESULT_RZ",
        minLen: "RESULT_RF",
        moleculeType: "SUBJECT_MT",
        normalizedOrganism: "SUBJECT_OS",
        organism: "SUBJECT_OO",
        organismTaxonomyID: "SUBJECT_OX",
        speciesTaxonomy: "SUBJECT_OC",
        percCoverageOverQueryLength: "RESULT_RCQ",
        percCoverageOverSubjectLength: "RESULT_RCD",
        percIdentityAlignment: "RESULT_RI",
        percIdentityQuery: "RESULT_RIQ",
        percIdentitySubject: "RESULT_RID",
        percPositivesOverLen: "RESULT_RM",
        percIdentityOverMinLen: "RESULT_RIG",
        queryLength: "QUERY_L",
        queryDesc: "QUERY_DE",
        //dbtype: "SUBJECT_T",
        resultNfq: "RESULT_NFQ",
        queryPercHSPCoverage: "RESULT_COV",
        qframe: "RESULT_FQ",
        sframe: "RESULT_NFD",
        queryStartPosition: "RESULT_OOBQ",
        queryStopPosition: "RESULT_OOEQ",
        // rcQueryStartPosition: "RESULT_OBQ",
        // rcQueryStopPosition: "RESULT_OEQ",
        // rcSubjectStartPosition: "RESULT_OBD",
        // rcSubjectStopPosition: "RESULT_OED",
        referenceAuthor: "SUBJECT_RA",
        referenceLocation: "SUBJECT_RL",
        seqIDNO: "SUBJECT_SI",
        sequenceLocation: "SUBJECT_PS",
        sequenceNotes: "SUBJECT_PK",
        sequenceVersion: "SUBJECT_SV",
        subjectDescription: "SUBJECT_DE",
        simpleUniqueFamilySeqId: "UFS_DISPLAY",
        subjectLength: "SUBJECT_L",
        subjectStartPosition: "RESULT_OOBD",
        subjectStopPosition: "RESULT_OOED",
        uniqueFamilySeqId: "SUBJECT_PJ",
        gapsInSubject: "RESULT_GAPD",
        gapsInQuery: "RESULT_GAPQ",
        // CAS
        casRegistryNumber: "SUBJECT_RG",
        casEntryDate: "SUBJECT_RB",
        casLastUpdateDate: "SUBJECT_RC",
        casName: "SUBJECT_DS",
        alsoKnownAs: "SUBJECT_SY",
        topology: "SUBJECT_S9",
        morphology: "SUBJECT_MP",
        biosequenceModifications: "SUBJECT_BM",
        strandNumber: "SUBJECT_S8",
        casNote: "SUBJECT_NT",
        roleInDocument: "SUBJECT_RD",
        structureData: "SUBJECT_CM",
        sequenceCrossReference: "SUBJECT_SX",
        keywords: "SUBJECT_KW",
        casDocumentId: "SUBJECT_CD",
        publicationYear: "SUBJECT_DC",
        databaseCrossReferences: "SUBJECT_DR",
        databaseName: "SUBJECT_GNAME",
        gqIdentifier: "SUBJECT_GX",
        genbankIndex: "SUBJECT_GI",
        issn: "SUBJECT_CK",
        coden: "SUBJECT_CL",
        doi: "SUBJECT_CJ",
        language: "SUBJECT_C6",
        volume: "SUBJECT_C7",
        issue: "SUBJECT_C8",
        pages: "SUBJECT_C9",
        publisher: "SUBJECT_CQ",
        publicationTitle: "SUBJECT_CU",
        companyOrganization: "SUBJECT_GY",
        citation: "SUBJECT_CB",
        relatedSequences: "SUBJECT_RE",
    },
    ufs: {
        simpleUniqueFamilySeqId: "UFS_DISPLAY",
        uniqueFamilySeqId: "SUBJECT_PJ",
        // patentFamily: "SUBJECT_PB"
    },
    cas: {
        casRegistryNumber: "SUBJECT_RG",
        casEntryDate: "SUBJECT_RB",
        casLastUpdateDate: "SUBJECT_RC",
        casName: "SUBJECT_DS",
        alsoKnownAs: "SUBJECT_SY",
        casNote: "SUBJECT_NT",
        sequenceCrossReference: "SUBJECT_SX",
        structureData: "SUBJECT_CM",
        relatedSequences: "SUBJECT_RE"
    },
    ssv: {
        subjectId: "SUBJECT_ID",
        seqIDNO: "SUBJECT_SI",
        sequenceLocation: "SUBJECT_PS",
        gqIdentifier: "SUBJECT_GX",
        genbankIndex: "SUBJECT_GI",
        //accessionNumber: "SUBJECT_AC",
        subjectLength: "SUBJECT_L",
        normalizedOrganism: "SUBJECT_OS",
        organism: "SUBJECT_OO",
        organismTaxonomyID: "SUBJECT_OX",
        uniqueFamilySeqId: "SUBJECT_PJ",
        databaseName: "SUBJECT_GNAME",
        patentNumber: "SUBJECT_PN",
        extendedLegalStatus: "SUBJECT_PG",
        patentAssignee: "SUBJECT_PA",
        normalizedParent: "SUBJECT_PZ",
        normalizedPatentAssignee: "SUBJECT_PU",
        title: "SUBJECT_PT",
        patentAbstract: "SUBJECT_P1",
        comment: "SUBJECT_CC",
        inventors: "SUBJECT_PI",
        simpleUniqueFamilySeqId: "UFS_DISPLAY",
        earliestPublicationDate: "SUBJECT_D5",
        submissionDate: "SUBJECT_D7",
        publicationDate: "SUBJECT_D1",
        priorityDate: "SUBJECT_D4",
        pctPublicationDate: "SUBJECT_P5",
        filingDate: "SUBJECT_D3",
        applicationNumber: "SUBJECT_AN",
        priorityInfo: "SUBJECT_PR",
        kindCode: "SUBJECT_KC",
        dateOfEntry: "SUBJECT_D2",
    },
    qs: {
        queryId: "QUERY_ID",
        queryLength: "QUERY_L",
        queryDesc: "QUERY_DE"
    },
    hsp: {
        queryId: "QUERY_ID",
        queryLength: "QUERY_L",
        queryDesc: "QUERY_DE",
        subjectId: "SUBJECT_ID",
        qframe: "RESULT_FQ",
        sframe: "RESULT_NFD",
    },
};

function javaObjectConverter(responseData, groupType, seqDb, fieldConfig) {
    let data;
    switch (groupType) {
        case 'fam':
            data = responseData.patentFamilies;
            break;
        case 'patent':
            data = responseData.patents;
            break;
        case 'alignment':
            data = responseData.alignments;
            break;
        case 'ufs':
            data = responseData.ufs;
            break;
        case 'cas':
            data = responseData.casRegs;
            break;
        case 'ssv':
            data = responseData.ssv;
            break;
        case 'qs':
            data = responseData.groupedItems;
            break;
        case 'hsp':
            data = responseData.groupedItems;
            break;
    }

    let seqIdsSummary = responseData.seqIdsSummary;
    let firstIndex = 0;
    let secondIndex = 0;
    let thirdIndex = 0;
    let allIndex = 0;
    let newObject = {};
    let objectsPerPage = 10;
    let objectsPerSecPage = 30;
    if (tableDisplayMode === 'family') {
        objectsPerPage = famViewOption;
        objectsPerSecPage = famViewAliOption;
    } else if (tableDisplayMode === 'document') {
        objectsPerPage = pnViewOption;
    } else if (tableDisplayMode === 'ssv') {
        objectsPerPage = ssvOption;
    } else if (tableDisplayMode === 'ufs') {
        objectsPerSecPage = ufsOption;
    }
    let objectsInPage = 0;
    let objectsInSecPage = 0;
    if (groupType === 'fam') {
        for (let index in data) {
            firstIndex = allIndex++;
            let familyData = data[index];
            let newFamilyData = javaObjectConverterHelper(familyData, 'fam', seqDb);
            newFamilyData["nodes"] = {};
            let patentList = familyData.patentList;
            objectsInPage = 0;
            for (let subIndex in patentList) {
                secondIndex = allIndex++;
                let patentData = patentList[subIndex];
                let newPatentData = {};
                let databasePatentDataPair = patentData.patentInfo;
                newPatentData["databases"] = {};
                let firstData = true;
                // Correct the default database logic
                let firstDatabase = getDefaultDatabaseId(databasePatentDataPair, fieldConfig);
                /*if (patentData.alignmentList !== undefined && patentData.alignmentList[0] !== undefined && patentData.alignmentList[0] !== null
                    && patentData.alignmentList[0].databaseName !== undefined && patentData.alignmentList[0].databaseName !== null) {
                    firstDatabase = patentData.alignmentList[0].databaseName.split(":")[0];
                }*/
                for (let db in databasePatentDataPair) {
                    let newPatentDataVal = javaObjectConverterHelper(databasePatentDataPair[db], 'patent', seqDb);
                    newPatentDataVal["SUBJECT_PN"] = patentData["patentNumber"];
                    if (seqIdsSummary && seqIdsSummary[patentData["patentNumber"]]) {
                        newPatentDataVal["SUBJECT_SEQIDNOSUMM"] = seqIdsSummary[patentData["patentNumber"]];
                    } else {
                        newPatentDataVal["SUBJECT_SEQIDNOSUMM"] = patentData["seqSummary"];
                    }
                    newPatentDataVal["NBFAMMEMBERS"] = newFamilyData["NBFAMMEMBERS"];
                    newPatentDataVal["SUBJECT_PB"] = newFamilyData["SUBJECT_PB"];
                    newPatentDataVal["FAMMEMBERS_IN_CURRENT"] = newFamilyData["FAMMEMBERS_IN_CURRENT"];
                    newPatentDataVal["FAMMEMBERS_OUT_CURRENT"] = newFamilyData["FAMMEMBERS_OUT_CURRENT"];
                    newPatentDataVal["FAMMEMBERS_OUT_GQ"] = newFamilyData["FAMMEMBERS_OUT_GQ"];
                    newPatentDataVal["PB_DESC"] = newFamilyData["PB_DESC"];
                    newPatentDataVal["SUBJECT_FAMINFO"] = {};
                    newPatentDataVal["dbkey"] = "doc_" + patentData["patentNumber"];
                    newPatentData["databases"][db] = newPatentDataVal;
                    if (firstData && db === firstDatabase) {
                        newPatentData["VT_PDF"] = structurePDFLink2(databasePatentDataPair[db]["pdfLink"]);
                        newPatentData["VT_FT"] = structureFtLink(databasePatentDataPair[db]["ftLink"]);
                        for (let key in newPatentDataVal) {
                            newPatentData[key] = newPatentDataVal[key];
                        }
                        firstData = false;
                    }
                }
                if (seqIdsSummary && seqIdsSummary[patentData["patentNumber"]]) {
                    newPatentData["SUBJECT_SEQIDNOSUMM"] = seqIdsSummary[patentData["patentNumber"]];
                } else {
                    newPatentData["SUBJECT_SEQIDNOSUMM"] = patentData["seqSummary"];
                }
                newPatentData["NBFAMMEMBERS"] = newFamilyData["NBFAMMEMBERS"];
                newPatentData["FAMMEMBERS_IN_CURRENT"] = newFamilyData["FAMMEMBERS_IN_CURRENT"];
                newPatentData["FAMMEMBERS_OUT_CURRENT"] = newFamilyData["FAMMEMBERS_OUT_CURRENT"];
                newPatentData["FAMMEMBERS_OUT_GQ"] = newFamilyData["FAMMEMBERS_OUT_GQ"];
                newPatentData["SUBJECT_PB"] = newFamilyData["SUBJECT_PB"];
                newPatentData["PB_DESC"] = newFamilyData["PB_DESC"];
                newPatentData["SUBJECT_FAMINFO"] = {};
                newPatentData["SUBJECT_PN"] = patentData["patentNumber"];
                newPatentData["ALI_DESC"] = patentData["aliDesc"];
                newPatentData["type"] = "doc";
                newPatentData["dbkey"] = "doc_" + newPatentData["SUBJECT_PN"];
                newPatentData[javaConvert.family.familyID] = newFamilyData[javaConvert.family.familyID];
                newPatentData[javaConvert.family.familyMembers] = newFamilyData[javaConvert.family.familyMembers];
                newPatentData[javaConvert.family.extendedPatentFamily] = newFamilyData[javaConvert.family.extendedPatentFamily];
                newFamilyData["nodes"][secondIndex] = newPatentData;
                newPatentData["nodes"] = {};
                if (newFamilyData[javaConvert.patent.priorityNumber] === undefined)
                    newFamilyData[javaConvert.patent.priorityNumber] = newPatentData[javaConvert.patent.priorityNumber];
                if (newFamilyData[javaConvert.patent.databaseName] === undefined && newFamilyData["SUBJECT_FAMINFO"] !== undefined && Object.keys(newFamilyData["SUBJECT_FAMINFO"]).length > 0)
                    newFamilyData[javaConvert.patent.databaseName] = Object.keys(newFamilyData["SUBJECT_FAMINFO"])[0];
                let alignmentList = patentData.alignmentList;
                objectsInSecPage = 0;
                for (let subSubIndex in alignmentList) {
                    thirdIndex = allIndex++;
                    let alignmentData = alignmentList[subSubIndex];
                    let newAlignmentData = javaObjectConverterHelper(alignmentData, 'alignment', seqDb);
                    newAlignmentData["type"] = "sin";
                    newFamilyData["nodes"][secondIndex]["nodes"][thirdIndex] = newAlignmentData;

                    // For "xxx more items"
                    objectsInSecPage++;
                    if (patentData['maxedOut'] &&
                        (objectsInSecPage == objectsPerSecPage || objectsInSecPage == alignmentList.length)) {
                        let maxedOut = {};
                        maxedOut['maxed_out'] = patentData['maxedOut'];
                        maxedOut['maxed_key'] = patentData['maxedKey'];
                        newFamilyData["nodes"][secondIndex]["nodes"][thirdIndex + 1] = maxedOut;
                    }
                }

                // For "xxx more items"
                objectsInPage++;
                if (familyData['maxedOut'] &&
                    (objectsInPage == objectsPerPage || objectsInPage == patentList.length)) {
                    let maxedOut = {};
                    maxedOut['maxed_out'] = familyData['maxedOut'];
                    maxedOut['maxed_key'] = familyData['maxedKey'];
                    newFamilyData["nodes"][secondIndex + 1] = maxedOut;
                }
            }
            newObject[firstIndex] = newFamilyData;
        }
    } else if (groupType === 'patent') {
        for (let index in data) {
            firstIndex = allIndex++;
            let patentData = data[index];
            // let newPatentData = javaObjectConverterHelper(patentData, 'patent');
            let newPatentData = {};
            let databasePatentDataPair = patentData.patentInfo;
            newPatentData["databases"] = {};
            let firstData = true;
            // Correct the default database logic
            let firstDatabase = getDefaultDatabaseId(databasePatentDataPair, fieldConfig);
            /*if (patentData.alignmentList !== undefined && patentData.alignmentList[0] !== undefined && patentData.alignmentList[0] !== null
                && patentData.alignmentList[0].databaseName !== undefined && patentData.alignmentList[0].databaseName !== null) {
                firstDatabase = patentData.alignmentList[0].databaseName.split(":")[0];
            }*/
            for (let db in databasePatentDataPair) {
                let newPatentDataVal = javaObjectConverterHelper(databasePatentDataPair[db], 'patent', seqDb);
                newPatentDataVal["SUBJECT_PN"] = patentData["patentNumber"];
                if (seqIdsSummary && seqIdsSummary[patentData["patentNumber"]]) {
                    newPatentDataVal["SUBJECT_SEQIDNOSUMM"] = seqIdsSummary[patentData["patentNumber"]];
                } else {
                    newPatentDataVal["SUBJECT_SEQIDNOSUMM"] = patentData["seqSummary"];
                }
                newPatentDataVal["dbkey"] = "doc_" + newPatentData["SUBJECT_PN"];
                if (patentData["familyInfo"]) {
                    newPatentDataVal["SUBJECT_PB"] = patentData["familyInfo"]["familyID"];
                    newPatentDataVal["SUBJECT_PM"] = patentData["familyInfo"]["familyMembers"];
                    newPatentDataVal["SUBJECT_XF"] = patentData["familyInfo"]["extendedPatentFamily"];
                    newPatentDataVal["NBFAMMEMBERS"] = patentData["familyInfo"]["familyMemberCount"];
                    newPatentDataVal["FAMMEMBERS_IN_CURRENT"] = patentData["familyInfo"]["patentsInCurrentResults"];
                    newPatentDataVal["FAMMEMBERS_OUT_CURRENT"] = patentData["familyInfo"]["patentsFilteredOut"];
                    newPatentDataVal["FAMMEMBERS_OUT_GQ"] = patentData["familyInfo"]["patentsNotInGQ"];
                    newPatentDataVal["SUBJECT_FAMINFO"] = {};
                }
                newPatentData["databases"][db] = newPatentDataVal;
                if (firstData && db === firstDatabase) {
                    newPatentData["VT_PDF"] = structurePDFLink2(databasePatentDataPair[db]["pdfLink"]);
                    newPatentData["VT_FT"] = structureFtLink(databasePatentDataPair[db]["ftLink"]);
                    for (let key in newPatentDataVal) {
                        newPatentData[key] = newPatentDataVal[key];
                    }
                    firstData = false;
                }
            }
            newPatentData["nodes"] = {};
            if (seqIdsSummary && seqIdsSummary[patentData["patentNumber"]]) {
                newPatentData["SUBJECT_SEQIDNOSUMM"] = seqIdsSummary[patentData["patentNumber"]];
            } else {
                newPatentData["SUBJECT_SEQIDNOSUMM"] = patentData["seqSummary"];
            }
            newPatentData["SUBJECT_PN"] = patentData["patentNumber"];
            if (patentData["familyInfo"]) {
                newPatentData["SUBJECT_PB"] = patentData["familyInfo"]["familyID"];
                newPatentData["SUBJECT_PM"] = patentData["familyInfo"]["familyMembers"];
                newPatentData["SUBJECT_XF"] = patentData["familyInfo"]["extendedPatentFamily"];
                newPatentData["NBFAMMEMBERS"] = patentData["familyInfo"]["familyMemberCount"];
                newPatentData["FAMMEMBERS_IN_CURRENT"] = patentData["familyInfo"]["patentsInCurrentResults"];
                newPatentData["FAMMEMBERS_OUT_CURRENT"] = patentData["familyInfo"]["patentsFilteredOut"];
                newPatentData["FAMMEMBERS_OUT_GQ"] = patentData["familyInfo"]["patentsNotInGQ"];
                newPatentData["SUBJECT_FAMINFO"] = {};
            }
            newPatentData["type"] = "doc";
            newPatentData["dbkey"] = "doc_" + newPatentData["SUBJECT_PN"];
            newPatentData["ALI_DESC"] = patentData["aliDesc"];
            objectsInPage = 0;
            let alignmentList = patentData.alignmentList;
            for (let subIndex in alignmentList) {
                objectsInPage++;
                secondIndex = allIndex++;
                let alignmentData = alignmentList[subIndex];
                let newAlignmentData = javaObjectConverterHelper(alignmentData, 'alignment', seqDb);
                newAlignmentData["type"] = "sin";
                newAlignmentData["SUBJECT_SEQIDNOSUMM"] = patentData["seqSummary"];
                //newAlignmentData["NBFAMMEMBERS"] = newFamilyData["NBFAMMEMBERS"];
                //newAlignmentData["SUBJECT_PB"] = newFamilyData["SUBJECT_PB"];
                newPatentData["nodes"][secondIndex] = newAlignmentData;
                if (patentData['maxedOut'] &&
                    (objectsInPage == objectsPerPage || objectsInPage == alignmentList.length)) {
                    let maxedOut = {};
                    maxedOut['maxed_out'] = patentData['maxedOut'];
                    maxedOut['maxed_key'] = patentData['maxedKey'];
                    newPatentData["nodes"][secondIndex + 1] = maxedOut;
                }
            }
            newObject[firstIndex] = newPatentData;
        }
    } else if (groupType === 'alignment') {
        for (let index in data) {
            firstIndex = allIndex++;
            let alignmentData = data[index];
            let newAlignmentData = javaObjectConverterHelper(alignmentData, 'alignment', seqDb);
            if (data[index].patentInfo !== undefined && data[index].patentInfo.patentInfo !== undefined) {
                newAlignmentData["SUBJECT_PN"] = data[index].patentInfo["patentNumber"];
                let patentInformation = Object.values(data[index].patentInfo.patentInfo)[0];
                newAlignmentData["VT_PDF"] = structurePDFLink2(alignmentData['pdfLink']);
                newAlignmentData["VT_FT"] = structureFtLink(alignmentData['ftLink']);
                if (seqIdsSummary && seqIdsSummary[newAlignmentData["SUBJECT_PN"]]) {
                    newAlignmentData["SUBJECT_SEQIDNOSUMM"] = seqIdsSummary[newAlignmentData["SUBJECT_PN"]];
                } else {
                    newAlignmentData["SUBJECT_SEQIDNOSUMM"] = data[index].patentInfo["seqSummary"];
                }
                for (let field in javaConvert.patent) {
                    if (patentInformation[field] !== undefined) {
                        newAlignmentData[javaConvert.patent[field]] = patentInformation[field];
                        if (field === "comment") {
                            newAlignmentData[javaConvert.patent[field]] = patentInformation[field].replace(/\n/g, '<br>');
                        }
                    }
                }
            }
            if (data[index].familyInfo !== undefined) {
                for (let field in javaConvert.family) {
                    if (data[index].familyInfo[field] !== undefined) {
                        newAlignmentData[javaConvert.family[field]] = data[index].familyInfo[field];
                    }
                }
            }
            newObject[firstIndex] = newAlignmentData;
        }
    } else if (groupType === 'ufs') {
        for (let index in data) {
            firstIndex = allIndex++;
            secondIndex = allIndex++;
            let ufsData = data[index];
            let newUfsData = javaObjectConverterHelper(ufsData, 'ufs', seqDb);
            newUfsData["nodes"] = {};
            let familyData = ufsData.patentFamily;
            let newFamilyData = javaObjectConverterHelper(familyData, 'fam', seqDb);
            newFamilyData["PB_DESC"] = familyData.aliDesc;
            newFamilyData["nodes"] = {};
            if (newFamilyData[javaConvert.patent.databaseName] === undefined && newFamilyData["SUBJECT_FAMINFO"] !== undefined && Object.keys(newFamilyData["SUBJECT_FAMINFO"]).length > 0)
                newFamilyData[javaConvert.patent.databaseName] = Object.keys(newFamilyData["SUBJECT_FAMINFO"])[0];
            newUfsData["nodes"][secondIndex] = newFamilyData;
            let alignmentList = familyData.alignmentList;
            objectsInSecPage = 0;
            for (let subSubIndex in alignmentList) {
                thirdIndex = allIndex++;
                let alignmentData = alignmentList[subSubIndex];
                let newAlignmentData = javaObjectConverterHelper(alignmentData, 'alignment', seqDb);
                newAlignmentData["SUBJECT_PB"] = newFamilyData["SUBJECT_PB"];
                newAlignmentData["SUBJECT_PM"] = newFamilyData["SUBJECT_PM"];
                newAlignmentData["SUBJECT_XF"] = newFamilyData["SUBJECT_XF"];
                newAlignmentData["NBFAMMEMBERS"] = newFamilyData["NBFAMMEMBERS"];
                newAlignmentData["FAMMEMBERS_IN_CURRENT"] = newFamilyData["FAMMEMBERS_IN_CURRENT"];
                newAlignmentData["FAMMEMBERS_OUT_CURRENT"] = newFamilyData["FAMMEMBERS_OUT_CURRENT"];
                newAlignmentData["FAMMEMBERS_OUT_GQ"] = newFamilyData["FAMMEMBERS_OUT_GQ"];
                newAlignmentData["SUBJECT_FAMINFO"] = {};
                if (alignmentData.patentInfo) {
                    if (seqIdsSummary && seqIdsSummary[alignmentList[subSubIndex].patentInfo["patentNumber"]]) {
                        newAlignmentData["SUBJECT_SEQIDNOSUMM"] = seqIdsSummary[alignmentList[subSubIndex].patentInfo["patentNumber"]];
                    } else {
                        newAlignmentData["SUBJECT_SEQIDNOSUMM"] = alignmentData.patentInfo["seqSummary"];
                    }
                }
                if (alignmentList[subSubIndex].patentInfo !== undefined &&
                    alignmentList[subSubIndex].patentInfo.patentInfo !== undefined) {
                    newAlignmentData["SUBJECT_PN"] = alignmentList[subSubIndex].patentInfo["patentNumber"];
                    let patentInformation = Object.values(alignmentList[subSubIndex].patentInfo.patentInfo)[0];
                    newAlignmentData["VT_PDF"] = structurePDFLink2(alignmentData['pdfLink']);
                    newAlignmentData["VT_FT"] = structureFtLink(alignmentData['ftLink']);
                    for (let field in javaConvert.patent) {
                        if (patentInformation[field] !== undefined) {
                            newAlignmentData[javaConvert.patent[field]] = patentInformation[field];
                            if (field === "comment") {
                                newAlignmentData[javaConvert.patent[field]] = patentInformation[field].replace(/\n/g, '<br>');
                            }
                        }
                    }
                    objectsInSecPage++;
                    // For "xxx more items"
                    if (familyData['maxedOut']
                        && (objectsInSecPage == objectsPerSecPage || objectsInSecPage == alignmentList.length)) {
                        let maxedOut = {};
                        maxedOut['maxed_out'] = familyData['maxedOut'];
                        maxedOut['maxed_key'] = familyData['maxedKey'];
                        newUfsData["nodes"][secondIndex]["nodes"][thirdIndex + 1] = maxedOut;
                    }
                }
                newUfsData["nodes"][secondIndex]["nodes"][thirdIndex] = newAlignmentData;
            }
            newObject[firstIndex] = newUfsData;
        }
    } else if (groupType === 'cas') {
        for (let index in data) {
            firstIndex = allIndex++;
            let casData = data[index];
            let newCasData = javaObjectConverterHelper(casData, 'cas', seqDb);
            newCasData["nodes"] = {};
            objectsInPage = 0;
            let alignmentList = casData.alignmentList;
            for (let subIndex in alignmentList) {
                objectsInPage++;
                secondIndex = allIndex++;
                let alignmentData = alignmentList[subIndex];
                let newAlignmentData = javaObjectConverterHelper(alignmentData, 'alignment', seqDb);
                if (alignmentData.patentInfo !== undefined && alignmentData.patentInfo.patentInfo !== undefined) {
                    newAlignmentData["SUBJECT_PN"] = alignmentData.patentInfo["patentNumber"];
                    let patentInformation = Object.values(alignmentData.patentInfo.patentInfo)[0];
                    newAlignmentData["VT_PDF"] = structurePDFLink2(alignmentData['pdfLink']);
                    newAlignmentData["VT_FT"] = structureFtLink(alignmentData['ftLink']);
                    if (seqIdsSummary && seqIdsSummary[newAlignmentData["SUBJECT_PN"]]) {
                        newAlignmentData["SUBJECT_SEQIDNOSUMM"] = seqIdsSummary[newAlignmentData["SUBJECT_PN"]];
                    } else {
                        newAlignmentData["SUBJECT_SEQIDNOSUMM"] = alignmentData.patentInfo["seqSummary"];
                    }
                    if (alignmentData.familyInfo !== undefined) {
                        newAlignmentData["SUBJECT_PB"] = alignmentData["familyInfo"]["familyID"];
                        newAlignmentData["SUBJECT_PM"] = alignmentData["familyInfo"]["familyMembers"];
                        newAlignmentData["SUBJECT_XF"] = alignmentData["familyInfo"]["extendedPatentFamily"];
                        newAlignmentData["NBFAMMEMBERS"] = alignmentData["familyInfo"]["familyMemberCount"];
                        newAlignmentData["FAMMEMBERS_IN_CURRENT"] = alignmentData["familyInfo"]["patentsInCurrentResults"];
                        newAlignmentData["FAMMEMBERS_OUT_CURRENT"] = alignmentData["familyInfo"]["patentsFilteredOut"];
                        newAlignmentData["FAMMEMBERS_OUT_GQ"] = alignmentData["familyInfo"]["patentsNotInGQ"];
                        newAlignmentData["SUBJECT_FAMINFO"] = {};
                    }
                    newAlignmentData["SUBJECT_FAMINFO"] = {};
                    for (let field in javaConvert.patent) {
                        if (patentInformation[field] !== undefined) {
                            newAlignmentData[javaConvert.patent[field]] = patentInformation[field];
                            if (field === "comment") {
                                newAlignmentData[javaConvert.patent[field]] = patentInformation[field].replace(/\n/g, '<br>');
                            }
                        }
                    }
                }
                if (alignmentData.familyInfo !== undefined) {
                    for (let field in javaConvert.family) {
                        if (alignmentData.familyInfo[field] !== undefined) {
                            newAlignmentData[javaConvert.family[field]] = alignmentData.familyInfo[field];
                        }
                    }
                }
                newAlignmentData["type"] = "flat";
                newCasData["nodes"][secondIndex] = newAlignmentData;
                if (objectsInPage == objectsPerPage && casData['maxedOut']) {
                    let maxedOut = {};
                    maxedOut['maxed_out'] = casData['maxedOut'];
                    maxedOut['maxed_key'] = casData['maxedKey'];
                    newCasData["nodes"][secondIndex + 1] = maxedOut;
                }
            }
            if (newCasData["SUBJECT_RG"] !== undefined) {
                newCasData["SUBJECT_RG"] = newCasData["nodes"][secondIndex]["SUBJECT_RG"];
                newCasData["SUBJECT_RE"] = newCasData["nodes"][secondIndex]["SUBJECT_RE"];
            }
            newObject[firstIndex] = newCasData;
        }
    } else if (groupType === 'ssv') {
        for (let index in data) {
            firstIndex = allIndex++;
            let casData = data[index];
            let newCasData = javaObjectConverterHelper(casData, 'ssv', seqDb);
            newCasData["nodes"] = {};
            newCasData["SS_DESC"] = casData["aliDesc"];
            if (casData["familyInfo"]) {
                newCasData["SUBJECT_PB"] = casData["familyInfo"]["familyID"];
                newCasData["SUBJECT_PM"] = casData["familyInfo"]["familyMembers"];
                newCasData["SUBJECT_XF"] = casData["familyInfo"]["extendedPatentFamily"];
                newCasData["NBFAMMEMBERS"] = casData["familyInfo"]["familyMemberCount"];
                newCasData["FAMMEMBERS_IN_CURRENT"] = casData["familyInfo"]["patentsInCurrentResults"];
                newCasData["FAMMEMBERS_OUT_CURRENT"] = casData["familyInfo"]["patentsFilteredOut"];
                newCasData["FAMMEMBERS_OUT_GQ"] = casData["familyInfo"]["patentsNotInGQ"];
                newCasData["SUBJECT_FAMINFO"] = {};
            }
            objectsInPage = 0;
            let alignmentList = casData.alignmentList;
            for (let subIndex in alignmentList) {
                objectsInPage++;
                secondIndex = allIndex++;
                let alignmentData = alignmentList[subIndex];
                let newAlignmentData = javaObjectConverterHelper(alignmentData, 'alignment', seqDb);
                if (alignmentData.patentInfo !== undefined && alignmentData.patentInfo.patentInfo !== undefined) {
                    newAlignmentData["SUBJECT_PN"] = alignmentData.patentInfo["patentNumber"];
                    let patentInformation = Object.values(alignmentData.patentInfo.patentInfo)[0];
                    newAlignmentData["VT_PDF"] = structurePDFLink2(alignmentData['pdfLink']);
                    newAlignmentData["VT_FT"] = structureFtLink(alignmentData['ftLink']);
                    if (seqIdsSummary && seqIdsSummary[newAlignmentData["SUBJECT_PN"]]) {
                        newAlignmentData["SUBJECT_SEQIDNOSUMM"] = seqIdsSummary[newAlignmentData["SUBJECT_PN"]];
                    } else {
                        newAlignmentData["SUBJECT_SEQIDNOSUMM"] = alignmentData.patentInfo["seqSummary"];
                    }
                    if (alignmentData.familyInfo !== undefined) {
                        newAlignmentData["SUBJECT_PB"] = alignmentData["familyInfo"]["familyID"];
                        newAlignmentData["SUBJECT_PM"] = alignmentData["familyInfo"]["familyMembers"];
                        newAlignmentData["SUBJECT_XF"] = alignmentData["familyInfo"]["extendedPatentFamily"];
                        newAlignmentData["NBFAMMEMBERS"] = alignmentData["familyInfo"]["familyMemberCount"];
                        newAlignmentData["FAMMEMBERS_IN_CURRENT"] = alignmentData["familyInfo"]["patentsInCurrentResults"];
                        newAlignmentData["FAMMEMBERS_OUT_CURRENT"] = alignmentData["familyInfo"]["patentsFilteredOut"];
                        newAlignmentData["FAMMEMBERS_OUT_GQ"] = alignmentData["familyInfo"]["patentsNotInGQ"];
                        newAlignmentData["SUBJECT_FAMINFO"] = {};
                    }
                    newAlignmentData["SUBJECT_FAMINFO"] = {};
                    for (let field in javaConvert.patent) {
                        if (patentInformation[field] !== undefined) {
                            newAlignmentData[javaConvert.patent[field]] = patentInformation[field];
                            if (field === "comment") {
                                newAlignmentData[javaConvert.patent[field]] = patentInformation[field].replace(/\n/g, '<br>');
                            }
                        }
                    }
                }
                if (alignmentData.familyInfo !== undefined) {
                    for (let field in javaConvert.family) {
                        if (alignmentData.familyInfo[field] !== undefined) {
                            newAlignmentData[javaConvert.family[field]] = alignmentData.familyInfo[field];
                        }
                    }
                }
                newAlignmentData["type"] = "sin";
                newCasData["nodes"][secondIndex] = newAlignmentData;
                if (casData['maxedOut'] &&
                    (objectsInPage == objectsPerPage || objectsInPage == alignmentList.length)) {
                    let maxedOut = {};
                    maxedOut['maxed_out'] = casData['maxedOut'];
                    maxedOut['maxed_key'] = casData['maxedKey'];
                    newCasData["nodes"][secondIndex + 1] = maxedOut;
                }
            }
            newObject[firstIndex] = newCasData;
        }
    } else if (groupType === 'qs' || groupType === 'hsp') {
        for (let index in data) {
            firstIndex = allIndex++;
            let casData = data[index];
            let newCasData = javaObjectConverterHelper(casData, groupType, seqDb);
            newCasData["nodes"] = {};
            newCasData["ALI_DESC"] = casData["aliDesc"];
            if (casData["familyInfo"]) {
                newCasData["SUBJECT_PB"] = casData["familyInfo"]["familyID"];
                newCasData["SUBJECT_PM"] = casData["familyInfo"]["familyMembers"];
                newCasData["SUBJECT_XF"] = casData["familyInfo"]["extendedPatentFamily"];
                newCasData["NBFAMMEMBERS"] = casData["familyInfo"]["familyMemberCount"];
                newCasData["FAMMEMBERS_IN_CURRENT"] = casData["familyInfo"]["patentsInCurrentResults"];
                newCasData["FAMMEMBERS_OUT_CURRENT"] = casData["familyInfo"]["patentsFilteredOut"];
                newCasData["FAMMEMBERS_OUT_GQ"] = casData["familyInfo"]["patentsNotInGQ"];
                newCasData["SUBJECT_FAMINFO"] = {};
            }
            objectsInPage = 0;
            let alignmentList = casData.alignmentList;
            for (let subIndex in alignmentList) {
                objectsInPage++;
                secondIndex = allIndex++;
                let alignmentData = alignmentList[subIndex];
                let newAlignmentData = javaObjectConverterHelper(alignmentData, 'alignment', seqDb);
                if (alignmentData.patentInfo !== undefined && alignmentData.patentInfo.patentInfo !== undefined) {
                    newAlignmentData["SUBJECT_PN"] = alignmentData.patentInfo["patentNumber"];
                    let patentInformation = Object.values(alignmentData.patentInfo.patentInfo)[0];
                    newAlignmentData["VT_PDF"] = structurePDFLink2(alignmentData['pdfLink']);
                    newAlignmentData["VT_FT"] = structureFtLink(alignmentData['ftLink']);
                    if (seqIdsSummary && seqIdsSummary[newAlignmentData["SUBJECT_PN"]]) {
                        newAlignmentData["SUBJECT_SEQIDNOSUMM"] = seqIdsSummary[newAlignmentData["SUBJECT_PN"]];
                    } else {
                        newAlignmentData["SUBJECT_SEQIDNOSUMM"] = alignmentData.patentInfo["seqSummary"];
                    }
                    if (alignmentData.familyInfo !== undefined) {
                        newAlignmentData["SUBJECT_PB"] = alignmentData["familyInfo"]["familyID"];
                        newAlignmentData["SUBJECT_PM"] = alignmentData["familyInfo"]["familyMembers"];
                        newAlignmentData["SUBJECT_XF"] = alignmentData["familyInfo"]["extendedPatentFamily"];
                        newAlignmentData["NBFAMMEMBERS"] = alignmentData["familyInfo"]["familyMemberCount"];
                        newAlignmentData["FAMMEMBERS_IN_CURRENT"] = alignmentData["familyInfo"]["patentsInCurrentResults"];
                        newAlignmentData["FAMMEMBERS_OUT_CURRENT"] = alignmentData["familyInfo"]["patentsFilteredOut"];
                        newAlignmentData["FAMMEMBERS_OUT_GQ"] = alignmentData["familyInfo"]["patentsNotInGQ"];
                        newAlignmentData["SUBJECT_FAMINFO"] = {};
                    }
                    newAlignmentData["SUBJECT_FAMINFO"] = {};
                    for (let field in javaConvert.patent) {
                        if (patentInformation[field] !== undefined) {
                            newAlignmentData[javaConvert.patent[field]] = patentInformation[field];
                            if (field === "comment") {
                                newAlignmentData[javaConvert.patent[field]] = patentInformation[field].replace(/\n/g, '<br>');
                            }
                        }
                    }
                }
                if (alignmentData.familyInfo !== undefined) {
                    for (let field in javaConvert.family) {
                        if (alignmentData.familyInfo[field] !== undefined) {
                            newAlignmentData[javaConvert.family[field]] = alignmentData.familyInfo[field];
                        }
                    }
                }
                newAlignmentData["type"] = "sin";
                newCasData["nodes"][secondIndex] = newAlignmentData;
                if (casData['maxedOut'] &&
                    (objectsInPage == objectsPerPage || objectsInPage == alignmentList.length)) {
                    let maxedOut = {};
                    maxedOut['maxed_out'] = casData['maxedOut'];
                    maxedOut['maxed_key'] = casData['maxedKey'];
                    newCasData["nodes"][secondIndex + 1] = maxedOut;
                }
            }
            newObject[firstIndex] = newCasData;
        }
    }
    let returnedObject = {};
    returnedObject["data"] = newObject;
    returnedObject["stats"] = {};
    returnedObject["stats"]["nav"] = {};
    returnedObject["stats"]["nav"]['current_page'] = responseData['currentPage'];
    returnedObject["stats"]["nav"]['first_obj'] = responseData['firstRow'];
    returnedObject["stats"]["nav"]['last_obj'] = responseData['lastRow'];
    returnedObject["stats"]["nav"]['objects_per_page'] = responseData['objectsPerPage'];
    returnedObject["stats"]["nav"]['prev_page_available'] = responseData['hasPrvPage'];
    returnedObject["stats"]["nav"]['next_page_available'] = responseData['hasNextPage'];
    returnedObject["stats"]["nav"]['last_available_page'] = responseData['lastPage'];
    returnedObject["stats"]["nav"]['total_count'] = responseData['totalCount'];

    // Annotation info
    if (responseData.annotationInfo) {
        if (responseData.annotationInfo.checkboxInfo) {
            returnedObject['checkboxes'] = {};
            for (let idx in responseData.annotationInfo.checkboxInfo) {
                returnedObject['checkboxes'][idx] = responseData.annotationInfo.checkboxInfo[idx] == 1 ? true : false;
            }
            //returnedObject['checkboxes'] = responseData.annotationInfo.checkboxInfo;
        }
        if (responseData.annotationInfo.starInfo) {
            returnedObject['stars'] = responseData.annotationInfo.starInfo;
        }
        if (responseData.annotationInfo.colorInfo) {
            returnedObject['colors'] = responseData.annotationInfo.colorInfo;
        }
        if (responseData.annotationInfo.commentInfo) {
            returnedObject['notes'] = responseData.annotationInfo.commentInfo;
        }
    }

    //console.log(returnedObject);
    return returnedObject;
};

function javaObjectConverterHelper(data, type, seqDb) {
    let fields, dbkeyPrefix, dbkeyField, dataType;
    switch (type) {
        case 'fam':
            fields = javaConvert.family;
            dbkeyPrefix = "fam_";
            dbkeyField = "familyID";
            dataType = "fam";
            break;
        case 'patent':
            fields = javaConvert.patent;
            dbkeyPrefix = "doc_";
            dbkeyField = "patentNumber";
            dataType = "doc";
            break;
        case 'alignment':
            fields = javaConvert.alignment;
            dbkeyPrefix = "ali_";
            dbkeyField = "resultId"
            dataType = "flat";
            break;
        case 'ufs':
            fields = javaConvert.ufs;
            dbkeyPrefix = "ufs_";
            dbkeyField = "uniqueFamilySeqId";
            dataType = "ufs";
            break;
        case 'cas':
            fields = javaConvert.cas;
            dbkeyPrefix = "cas_";
            dbkeyField = "casRegistryNumber";
            dataType = "cas";
            break;
        case 'ssv':
            fields = javaConvert.ssv;
            dbkeyPrefix = "ssv_";
            dbkeyField = "subjectId";
            dataType = "ssv";
            break;
        case 'qs':
            fields = javaConvert.qs;
            dbkeyPrefix = "qs_";
            dbkeyField = "queryId";
            dataType = "qs";
            break;
        case 'hsp': // TODO
            fields = javaConvert.hsp;
            dbkeyPrefix = "hsp_";
            dbkeyField = "queryId";
            dataType = "hsp";
            break;
    }
    let newData = {};
    for (let field in fields) {
        if (data[field] !== undefined) {
            newData[fields[field]] = data[field];
            if (field === "comment") {
                newData[fields[field]] = data[field].replace(/\n/g, '<br>');
            }
            if (field === "subjectStartPosition") {
                if (data["resultNfq"] >= 3 && data["resultNfq"] <= 6) {
                    newData[fields[field]] = data["subjectStopPosition"];
                } else {
                    newData[fields[field]] = data["subjectStartPosition"];
                }
            }
            if (field === "subjectStopPosition") {
                if (data["resultNfq"] >= 3 && data["resultNfq"] <= 6) {
                    newData[fields[field]] = data["subjectStartPosition"];
                } else {
                    newData[fields[field]] = data["subjectStopPosition"];
                }
            }
        }
    }
    newData["dbkey"] = dbkeyPrefix + data[dbkeyField];
    newData["type"] = dataType;
    if (type === "alignment") {
        resultGraphicHelper(newData);
        for (let idx in seqDb) {
            if (seqDb[idx] === newData["QUERY_ID"]) {
                newData["QUERY_N"] = idx;
            }
        }
        if (data.rgLink !== undefined && data.rgLink !== "") {
            newData["SUBJECT_RG"] = structureRgLink(data.rgLink, data.casRegistryNumber);
        }
        if (data.reLink !== undefined && data.reLink !== "") {
            newData["SUBJECT_RE"] = structureReLink(data.reLink, data.relatedSequences);
        }
        if (data.doiLink !== undefined && data.doiLink !== "") {
            newData["SUBJECT_CJ"] = structureDoiLink(data.doiLink, data.doi);
        }
        if (data.sxLink !== undefined && data.sxLink !== "") {
            newData["SUBJECT_SX"] = structureSxLink(data.sxLink, data.sequenceCrossReference);
        }
    } else if (type === "patent") {
        if (data.ftLink !== undefined && data.ftLink !== "") {
            newData["VT_FT"] = structureFtLink(data.ftLink);
        }
    }
    return newData;
}

function getDefaultDatabaseId(databases, fieldConfig) {
    let dbs = Object.keys(databases);
    if (fieldConfig.databasePrefs && fieldConfig.databasePrefs.length > 0) {
        for (let i = 0; i < fieldConfig.databasePrefs.length; i++) {
            for (let j = 0; j < dbs.length; j++) {
                if (patentDatabases[dbs[j]] === fieldConfig.databasePrefs[i]
                        || dbs[j] === fieldConfig.databasePrefs[i]) {
                    return dbs[j];
                }
            }
        }
    } else {
        // No preference settings, default as GQ-Pat
        let names = ['GQPAT_NUC', 'GQPAT_PRT', 'GQPAT_PREMIUM_PRT', 'GQPAT_PREMIUM_NUC']
        for (let i = 0; i < names.length; i++) {
            if (databases[names[i]]) {
                return names[i];
            }
        }
    }

    return dbs[0];
}

function resultGraphicHelper(alignment) {
    let resultGraphic = {};
    resultGraphic["QUERY_L"] = alignment["QUERY_L"];
    resultGraphic["RESULT_OOBD"] = alignment["RESULT_OOBD"];
    resultGraphic["RESULT_OOBQ"] = alignment["RESULT_OOBQ"];
    resultGraphic["RESULT_OOED"] = alignment["RESULT_OOED"];
    resultGraphic["RESULT_OOEQ"] = alignment["RESULT_OOEQ"];
    resultGraphic["SUBJECT_L"] = alignment["SUBJECT_L"];
    alignment["RESULT_GRAPHIC"] = resultGraphic;
}

function emptyFamInfo(famInfo) {
    let empty = true;
    for (let db in famInfo) {
        if (famInfo[db].length > 0) {
            empty = false;
            break;
        }
    }
    return empty;
}

function resultChartsConverter(data) {
    let res = {};
    if (data.publicationDateCount !== undefined && data.publicationDateCount !== null && data.publicationDateCount.length > 0) {
        let pubCount = {};
        Object.values(data.publicationDateCount).forEach(function (v) {
            pubCount[v.year] = v.count;
        });
        res["SUBJECT_D1"] = pubCount;
    }
    if (data.fillingDateCount !== undefined && data.fillingDateCount !== null && data.fillingDateCount.length > 0) {
        let fillingCount = {};
        Object.values(data.fillingDateCount).forEach(function (v) {
            fillingCount[v.year] = v.count;
        });
        res["SUBJECT_D3"] = fillingCount;
    }
    if (data.priorityDateCount !== undefined && data.priorityDateCount !== null && data.priorityDateCount.length > 0) {
        let priorityCount = {};
        Object.values(data.priorityDateCount).forEach(function (v) {
            priorityCount[v.year] = v.count;
        });
        res["SUBJECT_D4"] = priorityCount;
    }
    if (data.assigneesCount !== undefined && data.assigneesCount !== null && data.assigneesCount.length > 0) {
        let count = {};
        Object.values(data.assigneesCount).forEach(function (v) {
            count[v.assignee] = {};
            count[v.assignee]["count"] = v.count;
            count[v.assignee]["desc"] = v.assignee;
        });
        res["SUBJECT_PA"] = count;
    }
    // console.log(res);
    return res;
}

function facetConverter(data) {
    let res = {};
    if (data.authorities !== undefined && data.authorities !== null && data.authorities.length > 0) {
        let authCount = {};
        Object.values(data.authorities).forEach(function (v) {
            authCount[v.country] = {};
            authCount[v.country]["count"] = v.count;
            authCount[v.country]["desc"] = v.country;
        });
        res["SUBJECT_PN"] = authCount;
    }
    if (data.legalStatuses !== undefined && data.legalStatuses !== null && data.legalStatuses.length > 0) {
        let lsCount = {};
        Object.values(data.legalStatuses).forEach(function (v) {
            lsCount[v.status] = {};
            lsCount[v.status]["count"] = v.count;
            lsCount[v.status]["desc"] = v.status;
        });
        res["SUBJECT_PG"] = lsCount;
    }
    if (data.seqlocations !== undefined && data.seqlocations !== null && data.seqlocations.length > 0) {
        let seqLocationCount = {};
        Object.values(data.seqlocations).forEach(function (v) {
            seqLocationCount[v.code] = {};
            seqLocationCount[v.code]["count"] = v.count;
            seqLocationCount[v.code]["desc"] = v.code;
        });
        res["SUBJECT_PS"] = seqLocationCount;
    }
    // console.log(res);
    return res;
}

function structureReLink(link, val) {
    let list = val.split("; ");
    let linkList = link.split("; ");
    let res = "";
    for (let i = 0; i < list.length; i++) {
        res = res + '<a href="' + linkList[i] + '" target="_blank" title="click to view the details">' + list[i] + '</a>, ';
    }
    res = res.substring(0, res.length - 2);
    return res;
}

function structureSxLink(link, val) {
    let list = val.split(",");
    let linkList = link.split(", ");
    let res = "";
    for (let i = 0; i < list.length; i++) {
        if (i == 0) {
            let tmpList = list[i].split(":");
            let tmpLink = '<a href="' + linkList[0] + '" target="_blank" title="click to view the details">' + list.length + '</a>';
            res = tmpList[0].replace("" + list.length, tmpLink) + ': ';
            let otherValues = "";
            for (let j = 1; j < tmpList.length; j++) {
                otherValues += tmpList[j];
            }
            res = res + '<a href="' + linkList[i + 1] + '" target="_blank" title="click to view the details">' + otherValues + '</a>, ';
        } else {
            res = res + '<a href="' + linkList[i + 1] + '" target="_blank" title="click to view the details">' + list[i] + '</a>, ';
        }
    }
    res = res.substring(0, res.length - 2);
    return res;
}

function structureRgLink(link, val) {
    return '<a href="' + link + '" target="_blank" title="click to view the details">' + val + '</a>';
}

function structureDoiLink(link, val) {
    return '<a href="' + link + '" target="_blank" title="click to view the details">' + val + '</a>';
}

function structurePDFLink2(link) {
    // let link = "https://www.genomequestlive.com/query?do=gqfetch.get_patent_pdf&pn=" + pn + "&kc=" + kc;
    let result = "";
    if (link !== undefined && link !== "") {
        result = '<a href="' + link + '" target="_blank" title="click to view the details">PDF</a>';
    }
    return result;
}

function structureFtLink(link) {
    // let link = "https://stage.genomequestlive.com/fulltext/index.html#/fulldocview/JPWO2015133652A1"
    let result = "";
    if (link !== undefined && link !== "") {
        result = '<a href="' + link + '" target="_blank">GQ Full Text</a>';
    }
    return result;
}

// Task 143497: Add controlled vocab to ELS and PSL fields in jNRB
const pulldownFields = {
    SUBJECT_PG: {
        Expired: 'Expired',
        Granted: 'Granted',
        Lapsed: 'Lapsed',
        Pending: 'Pending',
        Revoked: 'Revoked',
        Unknown: 'Unknown',
        Other: 'Other'
    },
    SUBJECT_PS: {
        Claim: 'sequence in claims',
        Disclosure: 'sequence in disclosure',
        TBD: 'to be determined, English claims not available'
    },
    RESULT_NFD: {
        0: 'frame +1',
        1: 'frame +2',
        2: 'frame +3',
        3: 'frame -1',
        4: 'frame -2',
        5: 'frame -3',
        6: 'reverse',
        7: 'forward'
    },
    RESULT_FQ: {
        0: 'frame +1',
        1: 'frame +2',
        2: 'frame +3',
        3: 'frame -1',
        4: 'frame -2',
        5: 'frame -3',
        6: 'reverse',
        7: 'forward'
    },
};

// get the pulldownFields for a specific field type in the filtering widget
function getPulldownFields(field) {
    return pulldownFields[field];
};

/* ====== Functions to manage ESF sorting options ======================================= */
// how fields are displayed in the filter widget
const sortingWidgetFields = [
    { group: '', fields: ['PFD_PA', 'PFD_ELS', 'RESULT_RI', 'RESULT_RIQ', 'RESULT_RID', 'RESULT_RS', 'RESULT_RL', 'PFD_PSL', 'PFD_GNAME'] }, //'PFD_LS',
    { group: 'Important Dates', fields: ['SUBJECT_D4', 'SUBJECT_D3', 'SUBJECT_D5', 'SUBJECT_D7', 'SUBJECT_D1'] },
    { group: 'Status', fields: ['SUBJECT_PG', 'SUBJECT_LS'] },
    { group: 'Publication IDs', fields: ['SUBJECT_PN', 'SUBJECT_AN', 'SUBJECT_AC'] },
    { group: 'Patent Family Information', fields: ['SUBJECT_PB'] },
    { group: 'Patent Information', fields: ['SUBJECT_PT', 'SUBJECT_PC', 'SUBJECT_P9', 'SUBJECT_PE'] },
    {
        group: 'Subject Sequence', fields: ['SUBJECT_SI', 'SUBJECT_PS', 'SUBJECT_ID', 'SUBJECT_GX', 'SUBJECT_GI', 'SUBJECT_AC', 'SUBJECT_L',
            'SUBJECT_OO', 'SUBJECT_OS', 'SUBJECT_PJ', 'SUBJECT_GNAME'] // SUBJECT_PJ or UFS_DISPLAY
    },
    { group: 'Query Sequence', fields: ['QUERY_ID', 'QUERY_L', 'QUERY_DE'] },
    { group: 'Assignees and Inventors', fields: ['SUBJECT_PA', 'SUBJECT_PU', 'SUBJECT_PZ'] },
    { group: 'Public Database Information', fields: ['SUBJECT_AC'] },
    {
        group: 'Alignment Quality', fields: [
            'RESULT_RI', 'RESULT_RIQ', 'RESULT_COV', 'RESULT_RID', 'QUERY_L', 'SUBJECT_L', 'RESULT_RL', 'RESULT_RS', // RS -> Number of Matches/Diff. Count/Align. Score
            'RESULT_GAPQ', 'RESULT_GAPD', 'RESULT_RCD', 'RESULT_RCQ',]
    },
    { group: 'Alignment Position', fields: ['RESULT_OOBD', 'RESULT_OOED', 'RESULT_OOBQ', 'RESULT_OOEQ'] },
    { group: 'CAS Biosequences™', fields: ['SUBJECT_RG', 'SUBJECT_MP', 'SUBJECT_RD', 'SUBJECT_S9'] }
];

const customSortingFields = {
    PFD_ELS: {
        Expired: 'Expired',
        Granted: 'Granted',
        Lapsed: 'Lapsed',
        Pending: 'Pending',
        Revoked: 'Revoked',
        Unknown: 'Unknown',
        Other: 'Other'
    },
    PFD_LS: {
        Expired: 'Expired',
        Granted: 'Granted',
        Lapsed: 'Lapsed',
        Pending: 'Pending',
        Revoked: 'Revoked',
        Unknown: 'Unknown',
        Other: 'Other'
    },
    PFD_PSL: {
        Claim: 'Sequence in claims',
        Disclosure: 'Sequence in disclosure',
        TBD: 'To be determined, English claims not available'
    },
    PFD_PA: {
        US: 'US - United States',
        WO: 'WO - World IP Oraganization (WIPO)',
        EP: 'EP - European Patent Office (EPO)',
        //Other: 'All other Authorities'
    },
    PFD_GNAME: {
        GQPat: 'GQ-Pat',
        CAS: 'CAS Biosequences™',
        Geneseq: 'GENESEQ'
    }
};

let availableEls = [];
let availableLs = [];
let availablePsl = [];
let availablePa = [];
let availableGname = [];
function initCustomFields(atom, field) {
    atom.customValList = _.cloneDeep(customSortingFields[field]);
    for (let val in atom.customVals) {
        let idx = -1;
        for (let cv in atom.customValList) {
            if (cv == atom.customVals[val]) {
                idx = cv;
                break;
            }
        }
        _.pullAt(atom.customValList, idx);
    }
};

function refreshCustomFields(atom, field) {
    initCustomFields(atom, field);
    document.getElementById("dispCols" + field).focus();
}

function getCustomOptionDesc(col, key) {
    return customSortingFields[col][key];
};

function addSortingField(atom, multipleFields, field) {
    _.forEach(multipleFields, function (fieldComb, key) {
        if (atom.customVals.indexOf(fieldComb) < 0) {
            atom.customVals.push(fieldComb);
        }
    });
    initCustomFields(atom, field);
};

function delSortingField(atom, multipleFields, field) {
    _.forEach(multipleFields, function (fieldComb, key) {
        let idx = _.findIndex(atom.customVals, function (obj) {
            return obj == fieldComb;
        });
        _.pullAt(atom.customVals, idx);
    });
    initCustomFields(atom, field);
    /* TODO
    if (field == 'PFD_ELS') {
        selectedEls = null;
    } else if (field == 'PFD_ELS') {
        selectedLs = null;
    } else if (field == 'PFD_PA') {
        selectedPa = null;
    } else if (field == 'PFD_GNAME') {
        selectedGname = null;
    } else if (field == 'PFD_PSL') {
        selectedPsl = null;
    }*/
};

function addCustomFields(atom, key, field) {
    if (atom.customVals.indexOf(key) < 0) {
        atom.customVals.push(key);
    }
    initCustomFields(atom, field);
}

function removeCustomFields(atom, key, field) {
    let idx = _.findIndex(atom.customVals, function (obj) {
        return obj == key;
    });
    _.pullAt(atom.customVals, idx);
    initCustomFields(atom, field);
};

function promiseMoveSortingFieldsUp(multipleFields, atom) {

    //let promise = moveCustomFieldsUp(multipleFields, atom);
    let promise = new Promise((resolve, reject) => {
        let p = moveCustomFieldsUp(multipleFields, atom);
        if (p === "worked") {
            resolve("Promise resolved successfully");
        }
        else {
            reject(Error("Promise rejected"));
        }
    }
    );

    promise.then(function () {
        console.log('Move sorting fields up');
    }, function () {
        console.log('Error moving sorting fields up');
    });
};

function promiseMoveSortingFieldsDown(multipleFields, atom) {

    //let promise = moveCustomFieldsDown(multipleFields, atom);
    let promise = new Promise((resolve, reject) => {
        let p = moveCustomFieldsDown(multipleFields, atom);
        if (p === "worked") {
            resolve("Promise resolved successfully");
        }
        else {
            reject(Error("Promise rejected"));
        }
    }
    );

    promise.then(function () {
        console.log('Move sorting fields down');
    }, function () {
        console.log('Error moving sorting fields down');
    });
};


function moveCustomFieldsDown(multipleFields, atom) {
    let deferred = ""; //$q.defer();
    try{
    if (multipleFields != null) {
        let len = atom.customVals.length - multipleFields.length;
        for (let i = multipleFields.length - 1; i >= 0; i--) {
            let index = atom.customVals.indexOf(multipleFields[i]);
            if (index < (len + i)) {
                atom.customVals.splice(index + 1, 0, atom.customVals.splice(index, 1)[0]);
            }
        }
    }
        deferred = 'worked';
    }catch(error){
        deferred = 'bad news';
    }
    return deferred;
};

function moveCustomFieldsUp(multipleFields, atom) {
    let deferred = ""; //$q.defer();
    try{
    if (multipleFields != null) {
        for (let i = 0; i < multipleFields.length; i++) {
            let index = atom.customVals.indexOf(multipleFields[i]);
            if (index > i) {
                // No need to move when index == i
                atom.customVals.splice(index - 1, 0, atom.customVals.splice(index, 1)[0]);
            }
        }
    }
        deferred = 'worked';
    }catch(error){
        deferred = 'bad news';
    }
    return deferred;
};

const sortingOrder = {
    string: { 'ASC': 'From A to Z', 'DESC': 'From Z to A' },
    number: { 'ASC': 'Lowest Numbers First', 'DESC': 'Highest Numbers First' },
    percentage: { 'ASC': 'Lowest Numbers First', 'DESC': 'Highest Numbers First' },
    float: { 'ASC': 'Lowest Numbers First', 'DESC': 'Highest Numbers First' },
    int: { 'ASC': 'Lowest Numbers First', 'DESC': 'Highest Numbers First' },
    date: { 'ASC': 'Earliest Dates First', 'DESC': 'Latest Dates First' },
    custom: { 'custom': 'Custom Sort Order' }
};

function getSortingOrderDesc(field, method) {
    if (field == 'PFD_GNAME' || field == 'PFD_ELS' || field == 'PFD_LS' || field == 'PFD_PA' || field == 'PFD_PSL') {
        return sortingOrder['custom'][method];
    }
    let selection = fieldConfig[field] === undefined ? null : fieldConfig[field];
    if (selection != null) {
        return sortingOrder[selection.type][method];
    }
    return sortingOrder['string'][method];
};

function getSortingOrderList(field) {
    if (field == 'PFD_GNAME' || field == 'PFD_ELS' || field == 'PFD_LS' || field == 'PFD_PA' || field == 'PFD_PSL') {
        return sortingOrder['custom'];
    }
    let selection = fieldConfig[field] === undefined ? null : fieldConfig[field];
    if (selection != null) {
        return sortingOrder[selection.type];
    }
    return sortingOrder['string'];
};

function getSortOptionDesc(key) {
    if (sortConfig[key]) {
        return sortConfig[key].shorttitle;
    } else {
        return key;
    }
};

function populateSortCriterias(key) {
    let sortCriterias = [];
    if (!sortConfig[key]) {
        for (let i = 0; i < this.customSortConfig.length; i++) {
            if (this.customSortConfig[i].sortOptionName == key) {
                for (let atom in this.customSortConfig[i].sortOptions) {
                    let criteria = {};
                    criteria['colName'] = this.customSortConfig[i].sortOptions[atom].column;
                    criteria['sortOrder'] = this.customSortConfig[i].sortOptions[atom].method;
                    criteria['customValues'] = this.customSortConfig[i].sortOptions[atom].customVals;
                    sortCriterias.push(criteria);
                }
                break;
            }
        }
    }
    return sortCriterias;
}

let sortOptionName = '';
let sortOptionNameErr = '';
let sortOptionsErrStatus = [];
let sortOptionErr = '';

function errorsInSortOption() {
    let valid = true;
    sortOptionErr = '';
    sortOptionNameErr = '';
    sortOptionsErrStatus = [];
    if (sortOptionName == '') {
        valid = false;
        sortOptionNameErr = 'Please name or select your Sort Option';
    }
    for (let i = 0; i < sortOptions.length; i++) {
        let atom = sortOptions[i];
        sortOptionsErrStatus[i] = {};
        if (!atom.column || atom.column === '') {
            sortOptionsErrStatus[i].column = true;
            valid = false;
        } else if (atom.column == 'PFD_ELS' || atom.column == 'PFD_LS' || atom.column == 'PFD_PA'
            || atom.column == 'PFD_GNAME' || atom.column == 'PFD_PSL') {
            if (atom.customVals.length == 0) {
                sortOptionsErrStatus[i].customVals = true;
                valid = false;
            }
        }

        let desc = '';
        if (atom.method && atom.method !== '') {
            desc = getSortingOrderDesc(atom.column, atom.method);
        }
        if (!desc || desc == '') {
            sortOptionsErrStatus[i].method = true;
            valid = false;
        }
    }
    if (!valid) {
        sortOptionErr = 'Please fix above errors marked in Red';
    }
    return valid;
};

function sortOptionNameUsed(sortOptionName) {
    let retval = -1;
    for (let i = 0; i < FieldConfig.customSortConfig.length; i++) {
        if (FieldConfig.customSortConfig[i].sortOptionName === sortOptionName) {
            retval = i;
            break;
        }
    }
    return retval;
};

function addSortOption(sortOptionName, fieldConfig, resultFilter) {
    let newOption = {
        sortOptionName: sortOptionName,
        sortOptions: _.cloneDeep(this.sortOptions)
    };
    for (let atom in newOption.sortOptions) {
        // Clear customValList to avoid saving those unneeded info
        newOption.sortOptions[atom].customValList = null;
    }
    let existingOptionIndex = sortOptionNameUsed(sortOptionName);
    if (existingOptionIndex === -1) {
        this.customSortConfig.push(newOption);
    } else {
        this.customSortConfig.splice(existingOptionIndex, 1, newOption);
    }
    // Sort by name
    this.customSortConfig.sort(
        function (a, b) {
            return a.sortOptionName.localeCompare(b.sortOptionName);
        }
    );
    fieldConfig.customSortConfig = this.customSortConfig;
    saveConfig(fieldConfig,'default',resultFilter);
};

function setSortOption(option) {
    this.sortOptionName = option.sortOptionName;
    this.sortOptions = _.cloneDeep(option.sortOptions);
    for (let atom in this.sortOptions) {
        // Clear customValList to avoid saving those unneeded info 
        let colName = this.sortOptions[atom].column;
        if (colName == 'PFD_GNAME' || colName == 'PFD_ELS'
            || colName == 'PFD_LS' || colName == 'PFD_PA' || colName == 'PFD_PSL') {
            initCustomFields(this.sortOptions[atom], colName);
        }
    }
};

function deleteSortOption(fieldConfig, resultFilter) {
    for (let i = 0; i < this.customSortConfig.length; i++) {
        if (this.customSortConfig[i].sortOptionName === this.sortOptionName) {
            this.customSortConfig.splice(i, 1);
            break;
        }
    }
    this.sortOptionName = '';
    this.sortOptions = [];
    this.sortOptions[0] = newSortingAtom();
    fieldConfig.customSortConfig = this.customSortConfig;
    saveConfig(fieldConfig,'default',resultFilter);
};

function deleteSortOptionAtom(idx) {
    this.sortOptions.splice(idx, 1);
};

function addSortOptionAtom(idx) {
    //console.log("addSortOptionAtom......called");
    this.sortOptions.splice((idx + 1), 0, newSortingAtom());
};

let defaultSortingAtom = {
    id: 0,
    column: '',
    method: '',
    customVals: [],
    customValList: []
};

function newSortingAtom() {
    let atom = _.cloneDeep(defaultSortingAtom);
    atom.id = Date.now();
    return atom;
};

let sortOptions = [];
sortOptions[0] = newSortingAtom();

function setSortingOption(index, colName) {
    this.sortOptions[index].column = colName;
    // Clear the custom values when the sorting column is changed
    this.sortOptions[index].customVals = [];
    this.sortOptions[index].method = '';
    if (colName == 'PFD_GNAME' || colName == 'PFD_ELS'
        || colName == 'PFD_LS' || colName == 'PFD_PA' || colName == 'PFD_PSL') {
        this.sortOptions[index].method = 'custom';
    }
};

function setSortingOptionMethod(index, method) {
    this.sortOptions[index].method = method;
};

function getNbAtomsInSortingWidget() {
    return _.size(this.sortOptions);
};
//}

const FieldConfig = {
    getFieldName,
    fieldConfig,
    userTableTemplate,
    views,
    viewKeys,
    viewsHover,
    currentView,
    newViewName,
    tableDisplayMode,
    tableDisplayModeFromView,
    famViewOption,
    famViewAliOption,
    pnViewOption,
    ssvOption,
    ufsOption,
    loadConfig,
    saveConfig,
    tableWidth,
    estimateTableWidth,
    selectView,
    deleteView,
    singleAlignment,
    forAb,
    forAlert,
    forVm,
    sortConfig,
    customSortConfig,
    customSortingFields,
    sortOptions,
    sortingWidgetFields,
    sortingOrder,
    initCustomFields,
    addCustomFields,
    removeCustomFields,
    addSortingField,
    delSortingField,
    promiseMoveSortingFieldsUp,
    promiseMoveSortingFieldsDown,
    refreshCustomFields,
    getCustomOptionDesc,
    setSortOption,
    addSortOption,
    addSortOptionAtom,
    deleteSortOption,
    deleteSortOptionAtom,
    getSortingOrderList,
    getSortingOrderDesc,
    getSortOptionDesc,
    sortOptionNameUsed,
    errorsInSortOption,
    newSortingAtom,
    setSortingOption,
    setSortingOptionMethod,
    getNbAtomsInSortingWidget,
    populateSortCriterias,
    javaObjectConverter,
    databasePrefs,
    patentDatabases,
    getDatabaseBriefName,
    isPatentDatabase,
    getPatentDatabaseLabel,
    facetConverter,
    resultChartsConverter,
    getFieldInfo,
    getDatabaseName,
    prepLinks,
    getFullRecordFields,
    displayField,
    getDbBriefNameForDocDetail,
    checkViewNameUsed,
    getDisplayModeDescription,
    getTypeDescription,
    getUserTableTemplate,
    addFieldToTemplate,
    typeIsInDisplayMode,
    removeFieldFromTemplate,
    fieldIsInTableTemplate,
    refreshCols,
    promiseMoveFieldsDown,
    promiseMoveFieldsUp,
    delFieldsFromTemplate,
    resetColsDisplay,
    basicFieldConfig,
    prepCombinedFields,
    addFieldsToTemplate,
    saveView,
    getColumnsInfoForExport,
    getCurrentDisplayModeFieldInfo,
    sortOptionNameErr,
}
export default FieldConfig;
