import React, {useState} from 'react';
import Modal from 'react-bootstrap/Modal';

import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles((theme) => ({
	modalClassConDSI: {
		position: 'absolute',
		width: '105%',
		padding: '35px 15px 0px',
		right: 'auto',
		bottom: 'auto',
	},
	modalBoxContent: {
		maxHeight: '600px',
	},
	modalText: {
		position: 'absolute',
		top: '10px',
		right: '10px'
	},
}));

const DiscoverExportChoicesModal = props => {
	const [choice, setChoice] = useState('all');
	const classes = useStyles();
	const callback = () => {
		props.actionModal(props.fieldConfig, props.filter, props.resInfo, props.downloadType, choice);
	}

	const changeSelection = (e) => {
		setChoice(e.target.value);
	}

	return (
		<Modal
			size="lg"
			aria-labelledby="contained-modal-title-vcente"
			centered
			show={props.showModal}
			contentClassName={classes.modalClassConDSI}
		>
			<Link href="#" onClick={(e) => e.preventDefault()} className={classes.modalText}>
				<CloseIcon  style = {{ fill : '#777777'}} onClick={props.closeModal} />
			</Link>
			<br></br>
			<div className="jumbotron appTextColor" style={{backgroundColor: '#eee', marginBottom: '20px'}}>
				<h2 >You are about to export alignments to {props.downloadTypeDesc}</h2>
				<div className="filter-widget-textarea">
					<label style={{padding: '0px'}}>Selections:</label>
					{props.selectedAlignmentsCount > 0 &&
						<div>
							<label style={{padding: '0px'}}>
								<input id="selected" type="radio" value="selected" name="exportGroup" defaultChecked={choice === 'selected'} onChange={changeSelection}/>
								<span htmlFor="selected"> {Number(props.selectedAlignmentsCount).toLocaleString()} selected alignment(s)</span>
							</label>
						</div>
					}
					{props.displayCustomSelection &&
						<div>
							<label style={{padding: '0px'}}>
								<input id="custom" type="radio" value="custom" name="exportGroup" defaultChecked={choice === 'custom'} onChange={changeSelection}/>
								<span htmlFor="custom"> {props.customSelectionLabel}</span>
							</label>
						</div>
					}
					<div>
						<label style={{padding: '0px'}}>
							<input id="all" type="radio" value="all" name="exportGroup" defaultChecked={choice === 'all'} onChange={changeSelection}/>
							<span htmlFor="all"> All {Number(props.totalAlignmentNumber).toLocaleString()} alignment(s)</span>
						</label>
					</div>
				</div>
				<br></br>
				{choice === 'selected' && props.selectedAlignmentsCount > process.env.REACT_APP_EXPORT_LIMIT &&
					<b style={{color: 'red'}}>
						Maximum data export limit reached. 20,000 of {Number(props.selectedAlignmentsCount).toLocaleString()} alignments will be exported.
					</b>
				}
				{choice === 'all' && props.totalAlignmentNumber > process.env.REACT_APP_EXPORT_LIMIT &&
					<b style={{color: 'red'}}>
						Maximum data export limit reached. 20,000 of {Number(props.totalAlignmentNumber).toLocaleString()} records will be exported.
					</b>
				}
				{choice === 'custom' && props.customTtlCount > process.env.REACT_APP_EXPORT_LIMIT &&
					<b style={{color: 'red'}}>
						Maximum data export limit reached. 20,000 of {Number(props.customTtlCount).toLocaleString()} results will be exported.
					</b>
				}
				<br></br>
				<div style={{padding: '10px'}}>
					{props.totalAlignmentNumber > 0 &&
					<button onClick={callback} className="pull-right large-button gqblue">Start Export</button>
					}
					<button onClick={props.closeModal} className="pull-right large-button grey">Cancel</button>
				</div>
			</div>
		</Modal>
	);
}

export default DiscoverExportChoicesModal;
