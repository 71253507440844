
import React, { useState, useEffect, useCallback } from "react";
import CheckBox from '../../shared/Fields/CheckBox';
import { useSelector } from "react-redux";
import {useHistory} from "react-router-dom";

import Add from '@material-ui/icons/Add';
import Undo from '@material-ui/icons/Undo';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Save from '@material-ui/icons/Save';
import _ from "lodash";
import Tooltip from "@material-ui/core/Tooltip";

import { getInitialKeywordValue, getInitialSeqIdValue } from "../../services/FTDocService";
import CloseIcon from '@material-ui/icons/Close';
import SettingsIcon from '@material-ui/icons/Settings';

import FtOntologyModal from "../../shared/Modal/FtOntologyModal";

import fullTextService from "../../services/fulltextsearch"

const KeywordsInContext = (props) =>{

	const history = useHistory();
    const userInfo = useSelector((state) => state.setUserInfo);
    const {docsSelected, applyKeywords, applySeqIds} = props

    const [defaultOntologyState, setDefaultOntologyState] = useState({
		CHEBI:    ['Chemical Entities of Biological Interest (CHEBI)', false],
        DOID:     ['Human Disease Ontology (DOID)', false],
        GO:       ['Gene Ontology (GO)', false],
        HP:       ['Human Phenotype Ontology (HP)', false],
        IMR:      ['INOH Protein name/family name ontology (IMR)', false],
        MOD: 	  ['Protein Modification (MOD)', false],
        MSH: 	  ['Medical Subject Headings (MSH)', true],
        NCBI: 	  ['NCBI organismal classification (NCBI)', true],
        PO: 	  ['Plant Ontology (PO)', false],
        PR: 	  ['Protein Ontology (PR)', false],
		// Remove below item for now due to the license restriction
        // SNOMEDCT: ['Systematized Nomenclature of Medicine - Clinical Terms (SNOMEDCT)', false],
        TO: 	  ['Plant Trait Ontology (TO)', false],
        USER: 	  ['Your word lists (USER)', true],
	});
	//const [checkedOntologyState, setCheckedOntologyState] = useState(Object.assign({},defaultOntologyState));
	const [savedOntologyState, setSavedOntologyState] = useState(Object.assign({},defaultOntologyState));
	


    const [keywordsState, setKeywordsState] = useState(getInitialKeywordValue);
    const [keywords, setKeywords] = useState([]);

    const [seqIds, setSeqIds] = useState("");

    const [anykeywordChange, setAnykeywordChange] = useState(false);
    const [anyKeywordChecked, setAnyKeywordChecked] = useState(false);

    const [keywordsSelectedByAll, setKeywordsSelectedByAll] = useState([]);

    const [ontologyPanel, setOntologyPanel] =useState(false);

    const [ontologyOptionsPopup, setOntologyOptionsPopup] = useState(false)

    const keywordAtom = {
        type: "keyword",
        value: "",
        editableValue: "",
        selected: false,
    }

    const ontologyAtom = {
        type: "ontology",
        term: "",
        items: []
    }

    const ontologyItemAtom = {
        value: "",
        selected: false
    }

    useEffect(() => {

        let kws = [];
        for(let i = 0; i < keywordsState.length; i++){
            if(keywordsState[i].key !== ""){
                let ontAtom = _.cloneDeep(ontologyAtom)
                ontAtom.term = keywordsState[i].key;

                if(Array.isArray(keywordsState[i].value)){
                    for(let j = 0; j < keywordsState[i].value.length; j++ ){
                        let ontItemAtom  = _.cloneDeep(ontologyItemAtom);

                        ontItemAtom.value = keywordsState[i].value[j];

                        let selected = keywordsSelectedByAll.includes(keywordsState[i].value[j])
                        ontItemAtom.selected = selected;

                        if(!anyKeywordChecked && selected){
                            setAnyKeywordChecked(true)
                        }

                        ontAtom.items.push(ontItemAtom)
                    }
                }

                kws.push(ontAtom)
            }else{
                 
                let keyAtom = _.cloneDeep(keywordAtom)

                keyAtom['value'] = keywordsState[i].value
                keyAtom['editableValue'] = keywordsState[i].value

                let selected = keywordsSelectedByAll.includes(keywordsState[i].value);
                keyAtom['selected'] = selected;

                if(!anyKeywordChecked && selected){
                    setAnyKeywordChecked(true)
                }
                kws.push(keyAtom)
            }

        }
        
        setKeywords([...kws])
    }, [keywordsState, keywordsSelectedByAll]); // Only run the effect if 'value' changes
    

    useEffect(()=>{
        if(keywords.length >0){
            setAnyKeywordChecked(false);
            for(let i = 0; i < keywords.length; i++){

                if(keywords[i].type === "ontology"){

                    if(Array.isArray(keywords[i].items)){
                        for(let j = 0; j<keywords[i].items.length; j++){
                            if(keywordsSelectedByAll.includes(keywords[i].items[j].value)){
                                keywords[i].items[j].selected = true;
                                setAnyKeywordChecked(true)
                            }else{
                                keywords[i].items[j].selected = false
                            }
                        }
                    }
                
                }else{
                    if(keywordsSelectedByAll.includes(keywords[i].value)){
                        keywords[i].selected = true;
                        setAnyKeywordChecked(true)
                    }else{
                        keywords[i].selected = false
                    }
                }
            }
            setKeywords([...keywords])
        }

    },[keywordsSelectedByAll])

    useEffect(() => {
        const handleStorageChange = (event) => {
          if (event.key === 'keywords') {
            setKeywordsState(event.newValue ? JSON.parse(event.newValue) : []);
          }
        };
      
        window.addEventListener('storage', handleStorageChange);
      
        // Cleanup event listener on component unmount
        return () => {
          window.removeEventListener('storage', handleStorageChange);
        };
    },[]);


    function findCommonElements(array1, array2) {
        const set1 = new Set(array1);
        const commonElements = array2.filter(element => set1.has(element));
      
        return commonElements;
    }

    useEffect(()=>{

        let selectedKeys = [];
        let selectedSeqs = "";
        if(Array.isArray(docsSelected)){
            for(let i = 0; i< docsSelected.length; i++){
                let docString = localStorage.getItem(docsSelected[i]);
                if(docString){
                    let docObj = JSON.parse(docString);

                    if(i === 0){
                        selectedKeys = selectedKeys.concat(docObj.keywords)
                        selectedSeqs = docObj.seqIds
                    }else{
                        selectedKeys = findCommonElements(selectedKeys, docObj.keywords)

                        if(docObj.seqIds != selectedSeqs){
                            selectedSeqs = "";
                        }
                    }
                }

            }
        }

        selectedKeys = selectedKeys.filter((value, index, array) => array.indexOf(value) === index)
        setKeywordsSelectedByAll(selectedKeys)
        setSeqIds(selectedSeqs)

    },[docsSelected])

    const handleSeqId = (e) => {
        const filteredValue = e.target.value.replace(/[^0-9,\s\n\-]/g, '');
        setSeqIds(filteredValue);
    };

    const addKeyword = (e) =>{
        
        for (var i = 0; i < keywords.length; i++) {
            if (keywords[i].value === "" && keywords[i].editableValue === "") {
                keywords.splice(i, 1);
                i--;
            }
        }

        setAnyKeywordChecked(true)

        let newItem = _.cloneDeep(keywordAtom);
        newItem.selected = true;

        keywords.push(newItem);
        setKeywords([...keywords]);
    }

    const openOntologyPanel = (e) =>{
        setOntologyPanel(true);
        // let newItem = _.cloneDeep(OntologyAtom);

        // keywords.push(newItem);
        // setKeywords([...keywords]);
    }

    const closeOntologyPanel = (e) =>{
        setOntologyPanel(false);
     
    }

    function getOntologyString(){

		let ontologyString = "";
		let keys = Object.keys(savedOntologyState);

		for(var i =0; i<keys.length; i++){
			if(savedOntologyState[keys[i]][1]){
				ontologyString+=';'+keys[i];
			}
		}

		return ontologyString;
	}

    const [ontologyTextList, setOntologyTextList] =useState();
    const [searchedTerm, setSearchedTerm] = useState("")

    const [typingTimeout, setTypingTimeout] = useState(null);
    const findOntology = (event) => {

        // Clear the previous timeout if the user is still typing
        if (typingTimeout) {
          clearTimeout(typingTimeout);
        }
    
        // Set a new timeout to trigger the function after 1 second of no typing
        const newTimeout = setTimeout(() => {
            searchOntologies(event.target.value);
        }, 1000);
    
        setTypingTimeout(newTimeout);

    }

    const searchOntologies = async (value) => {
        console.log('User stopped typing for 1 second:', value);

        let userId = userInfo && userInfo.current_user.gq_user_id;        	
		//toast.success('start');

        if(fullTextService.levenshteinDistance(value, searchedTerm) > 2){

            let searchParam = `&json_query=${encodeURIComponent(value)}&rows=25&ontologies=${getOntologyString()}&user_id=${userId}`;
            const searchRes = await fullTextService.getFullTextSearchTerm(
                history,
                searchParam
            );

            //const searchRes = Test.test2;
            setSearchedTerm(value)
            if (searchRes && searchRes.response_status == 0) {
                if(searchRes.response_content && searchRes.response_content.results){
                    if (searchRes.response_content.results.length > 0) {
                        if(searchRes.response_content.results){
                            setOntologyTextList(searchRes.response_content.results);
                            return true;
                        }
                    }
                }	
            }
        }

    };

    //synonym popup
    const [synonymPopupX, setSynonymPopupX] = useState(0);
    const [synonymPopupY, setSynonymPopupY] = useState(0);
    const [synonymItems, setSynonymItems] = useState([]);
 
    const showSynonyms = (event, synonymList) =>{
        setSynonymPopupX(event.clientX);
        setSynonymPopupY(event.clientY);
        if(synonymList != null && synonymList != undefined){
            setSynonymItems(synonymList);
        }else{
            setSynonymItems([]);
        }
    }

    const hideSynonyms = () =>{
        setSynonymItems([]);
    }

    const removeKeyword = (index) => {
        keywords.splice(index, 1);
        setKeywords([...keywords])
        setAnykeywordChange(true)
    }

    const resetWord = (index) =>{
        keywords[index].editableValue = keywords[index].value
        setKeywords([...keywords]);
    }

    function getValuesFromItems(ontologyAtom) {
        return ontologyAtom.items.map(item => item.value);
    }

    const saveTerms = () =>{

        let allTerms =[]
        for(let i = 0 ; i < keywords.length; i++){

            if(keywords[i]["selected"]){
                keywords[i].value = keywords[i].editableValue
            }
            
            if(keywords[i].value!=="" && !allTerms.includes(keywords[i].value)){

                let kwStorageItem = {key: "", value: ""};
                if(keywords[i].type == "ontology"){
                    kwStorageItem.key = keywords[i].term
                    kwStorageItem.value = getValuesFromItems(keywords[i])
                }else{
                    kwStorageItem.value = keywords[i].value
                }

                allTerms.push(kwStorageItem)
            }
        }

        setKeywords([...keywords])
        setAnykeywordChange(false);

        let keystring = JSON.stringify(allTerms);
        localStorage.setItem("keywords", keystring)
        
    }

    const handleKeywordEdit = (event, index) => {
        let editedValue = event.target.value;

        keywords[index].editableValue = editedValue;
        setKeywords([...keywords])
        checkIfAnyChanged(keywords);
    }

    const checkIfAnyChanged = (keywords) => {
        for(let i = 0; i< keywords.length; i++){
            if(keywords[i].value !== keywords[i].editableValue){
                setAnykeywordChange(true)
                return
            }
        }
        setAnykeywordChange(false);
    }

    const checkIfAnyChecked = (keywords) => {
        for(let i = 0; i< keywords.length; i++){
            if(keywords[i].selected){
                setAnyKeywordChecked(true)
                return
            }
        }
        setAnyKeywordChecked(false);
    }

    const getKeywordsSelected = () =>{
        let selection = []

        for(let i = 0; i< keywords.length; i++){
            if(keywords[i].type === "keyword"){
                if(keywords[i].selected && keywords[i].value !== ""){
                    if(!selection.includes(keywords[i].value)){
                        selection.push(keywords[i].value);
                    }
                }
            }else if(keywords[i].type === "ontology"){
                for(let j = 0; j < keywords[i].items.length; j++){
                    if(keywords[i].items[j].selected && keywords[i].items[j].value !== ""){
                        if(!selection.includes(keywords[i].items[j].value)){
                            selection.push(keywords[i].items[j].value);
                        }
                    }
                }
            }

        }
        return selection
    }

    const handleCheck = (e, index) =>{
      
        let checked = e.target.checked
        keywords[index].selected = checked;
        setKeywords([...keywords])
        checkIfAnyChecked(keywords)
    }
    
    const handleCheckOnt = (e, termindex, ontIndex) =>{
      
        let checked = e.target.checked
        keywords[termindex].items[ontIndex].selected = checked;
        setKeywords([...keywords])
        checkIfAnyChecked(keywords)
    }
    const addOntologyTerm = (data) =>{
        let ontAtom = _.cloneDeep(ontologyAtom);
        ontAtom.term = data.taxonomy+": "+data.term;
        
        let valueList = [];
        if(Array.isArray(data.synonyms)){
            for( let i = 0; i< data.synonyms.length; i++){
                let ontItemAtom = _.cloneDeep(ontologyItemAtom);
                ontItemAtom.value = data.synonyms[i];
                ontItemAtom.selected = true;
                valueList.push(ontItemAtom)
            }
        }
        ontAtom.items = valueList

        keywords.push(ontAtom);
        setKeywords([...keywords]);
        setAnykeywordChange(true);
        closeOntologyPanel();
        setSynonymItems([]);

    }

    return(
        <div className="docManagerColumn" style={{width: "320px"}}>
            <span className="subHeading">Keywords</span>
            <div 
            className="docKey"
            style={{padding: "5px 5px 0 0", overflowX: "scroll"}}
            >
                {keywords.length > 0 && keywords.map((data, index1)=> {
                    
                    if(data.type == "keyword"){
                        return(
                        <div key={index1} style={{ margin: "0 3px", display: "flex", flexDirection: "row"}}>
                            <div  style={{marginTop: "5px"}}>
                                <CheckBox
                                    checked = {data.selected}
                                    onChange= {(event)=>{handleCheck(event, index1)}}
                                    style={{width: "15px", color: "#0182C5" }}
                                />
                            </div>
                            <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                                <div style={{borderBottom: "1px solid lightgray", textWrap: "nowrap", minHeight: "20px", width: "230px"}}>
                                    <input onChange={(event)=>handleKeywordEdit(event, index1)} disabled={!data.selected} style={{width: "100%", outline: "none", border: "none"}} type="text" className="appTextFont appTextColor" value={data.editableValue}></input>
                                </div>
                                <div style={{display: "flex",flexWrap: "nowrap", padding: "0 2px"}}>
                                    <Tooltip title="Delete">
                                        <DeleteForeverIcon
                                            onClick={()=> removeKeyword(index1)}
                                            className="trashbutton"
                                            style={{marginTop: "5px", fontSize: 19, color: "#db862c", cursor: "pointer" }}
                                        />
                                    </Tooltip>
                                    <Tooltip title="Undo">
                                        <Undo
                                            onClick={()=>resetWord(index1)}
                                            className={(keywords[index1].editableValue !== keywords[index1].value) && keywords[index1].selected? "kwResetButton" : "kwResetButtonDisabled"}
                                            style={{fontSize: 19}}
                                        />
                                    </Tooltip>
                                </div>
                            </div>

                        </div>
                        )
                    }else{
                        return(
                        <div style={{ margin: "0 3px 5px 5px", border: "1px solid darkgray", borderRadius: "4px"}}>
                            <div>
                                <div style={{ margin: "0 5px 0 5px", display: "flex", flexDirection: "row"}}>
                                    <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                                        <span key={index1} style={{width: "260px", padding: "3px 3px 0 3px", textWrap: "none", overflow: "clip"}}>
                                            <span className={data.term.split(":")[0] == "USER"? "autoqueryUser":"autoquery"}>{data.term.split(":")[0]}</span> {data.term.split(":")[1]}
                                        </span>
                                        <div style={{display: "flex",flexWrap: "nowrap", padding: "0px 2px 4px 2px"}}>
                                        <Tooltip title="Delete">
                                            <DeleteForeverIcon
                                                onClick={()=> removeKeyword(index1)}
                                                className="trashbutton"
                                                style={{marginTop: "5px", fontSize: 19, color: "#db862c", cursor: "pointer" }}
                                            />
                                        </Tooltip>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div style={{width:"100%", display: "flex", alignContent: "center"}}>
                                <div style={{display:"flex", height:"80px", margin: "5px", border: "1px solid lightgray", borderRadius: "4px", width: "100%", overflowY: "scroll", flexDirection: "column", lineBreak: "anywhere"}}>
                                {data.items.length > 0 && data.items.map((synonyms, index2) =>{
                                    return(
                                        <div key={index2} style={{ margin: "0 3px", display: "flex", flexDirection: "row"}}>
                                        <div  style={{marginTop: "5px"}}>
                                            <CheckBox
                                                checked = {synonyms.selected}
                                                onChange= {(event)=>{handleCheckOnt(event, index1, index2)}}
                                                style={{width: "15px", color: "#0182C5" }}
                                            />
                                        </div>
                                        <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                                            <div style={{borderBottom: "1px solid lightgray", textWrap: "nowrap", minHeight: "20px", width: "230px"}}>
                                                <span style={{width: "100%", outline: "none", border: "none"}}className="appTextFont appTextColor">{synonyms.value}</span>
                                            </div>
                                        </div>
            
                                    </div>
                                    )
                                })}
                                </div>
                            </div>
                        </div>
                        )
                    }
                })}

                {ontologyPanel &&
                <div style={{ margin: "0 3px 5px 5px", border: "1px solid darkgray", borderRadius: "4px"}}>
                    <div>
                       <div style={{ margin: "0 5px 0 5px", display: "flex", flexDirection: "row"}}>

                            <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                                <div style={{borderBottom: "1px solid lightgray", textWrap: "nowrap", minHeight: "20px", width: "245px"}}>
                                    <input onChange={(event)=>findOntology(event)} style={{width: "100%", outline: "none", border: "none"}} placeholder="Search for ontology" type="text" className="appTextFont appTextColor"></input>
                                </div>
                                <div style={{display: "flex",flexWrap: "nowrap", padding: "0 2px"}}>
                                    <Tooltip title="Ontology Selection">
                                        <SettingsIcon
                                            onClick={()=> setOntologyOptionsPopup(!ontologyOptionsPopup)}
                                            className="trashbutton"
                                            style={{marginTop: "5px", fontSize: 19, color: "#0182C5", cursor: "pointer" }}
                                        />
                                    </Tooltip>
                                    <Tooltip title="Close">
                                        <CloseIcon
                                            onClick={()=> closeOntologyPanel()}
                                            className="trashbutton"
                                            style={{marginTop: "5px", fontSize: 19, color: "#db862c", cursor: "pointer" }}
                                        />
                                    </Tooltip>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div style={{width:"100%", display: "flex", alignContent: "center"}}>
                        <div style={{display:"flex", height:"80px", margin: "5px", border: "1px solid lightgray", borderRadius: "4px", width: "100%", overflowY: "scroll", flexDirection: "column", lineBreak: "anywhere"}}>
                        {ontologyTextList && ontologyTextList.length > 0 && ontologyTextList.map((data, index) =>{
                            return(
                                <span onClick={()=>{addOntologyTerm(data)}} key={index} style={{padding: "3px", textWrap: "pretty"}} onMouseOver={(event) => {showSynonyms(event, data.synonyms)}} onMouseOut={()=>{hideSynonyms()}}>
                                    <span className={data.taxonomy == "USER"? "autoqueryUser":"autoquery"}>{data.taxonomy}</span> {data.term}
                                </span>
                            )
                        })
                        }
                        </div>
                    </div>
                </div>
                }
                <div style={{width: "100%", display: "flex"}}>
                    <button onClick={()=> addKeyword()} className="docManagerAddKeywordButton appTextFont appTextColor">
                    <Add/>
                        <span>New Keyword</span>
                    </button>
                    {userInfo.ftAccess &&  false &&
                    <button onClick={()=> openOntologyPanel()} className="docManagerAddOntologyButton appTextFont appTextColor">
                        <span>Ontology</span>
                    </button>
                    }
                </div>
                </div>
                <div className="buttonContainer">
                    <div>
                        <Tooltip title="Save">
                            <Save
                                onClick={()=>{if(anyKeywordChecked && anykeywordChange){saveTerms()}}}
                                style={{margin: "0 5px",fontSize: 30}}
                                className={anyKeywordChecked && anykeywordChange? "saveIconDocManager" : "saveIconDocManagerDisabled"}
                            />
                        </Tooltip>
                    </div>
                    <button className={docsSelected.length > 0? "docManagerButton" : "docManagerButtonDisabled"} onClick={()=>{if(docsSelected.length > 0){saveTerms(); applyKeywords(getKeywordsSelected())}}}>Apply KeyWords</button>
                </div>
                <span className="subHeading">Sequence ID's</span>
                <div style={{height: "100px"}}>
                    <textarea
                        className="docSeq appTextFont appTextColor"
                        placeholder={"231, 4-765, 34\n675309, 76"}
                        type="text"
                        value={seqIds}
                        onChange={handleSeqId}
                    />
                </div>
                <div className="buttonContainer">
                    <button className={docsSelected.length > 0 ? "docManagerButton" : "docManagerButtonDisabled"} onClick={()=>{applySeqIds(seqIds)}}>Apply ID's</button>
                </div>

            <div>
                <FtOntologyModal
					show={ontologyOptionsPopup}
					closeModal={() => setOntologyOptionsPopup(false)}
					savOntState = {savedOntologyState}
				/>
			</div>
            <div>
            {synonymItems.length > 0 &&
				<div 
					className={"ftSynonymPopup"}
					style={{ top: synonymPopupY, left: synonymPopupX}}
				>
					<table>
						<th>
							<tr>
								<span style={{display: "flex", textWrap: "nowrap", padding: "8px 8px 0 8px", marginBottom: "5px !important"}} className="ontologySymTitle">This Ontology includes:</span>
							</tr>
						</th>
						<tbody>
						<ul className="col-md-12 ftOntologyItem paddingletf15">
							{synonymItems !=[] &&
								synonymItems.map((data, i) => {
									return(
											<p className="ftNoLineBreak" style={{marginBottom: "5px"}}>
												{data}
											</p>
									);
								})
							}
		   			    </ul>
						</tbody>
					</table>
				</div>
            }
			</div>
        </div>
    )};

export default KeywordsInContext