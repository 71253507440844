import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import Button from '@material-ui/core/Button';
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';

const ExportExcel = ({excelData, fileName, onClose, setFieldValue, resetFileInput}) => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const exportToExcel = async () => {
        const ws = XLSX.utils.aoa_to_sheet(excelData);
        const wb = { Sheets: { 'data': ws}, SheetNames: ['data'] };
        const excelBuffer =XLSX.write(wb, { bookType: 'xlsx' , type: 'array'});
        const data = new Blob([excelBuffer],{type: fileType });
        const filename = fileName(); // Call fileName function to get the filename
        let split = filename.split('.')
        let finalName;
        if (split.length === 1 ) {
            // Handle the case where filename has no extension
            finalName = filename;
        }else{
            split.pop();
            finalName = split.join(".");
        }
        FileSaver.saveAs(data, finalName + fileExtension);
        onClose && onClose();
        setFieldValue('st26input', '');
        resetFileInput();
    }

    return(
        <div>
            <Button variant='contained' onClick={exportToExcel}> Excel Export </Button>
        </div>
    )
}

export default ExportExcel;
