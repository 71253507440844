import React, { useState, useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Modal from 'react-bootstrap/Modal'
import { useTranslation } from "react-i18next";
import {useHistory, Link, useParams} from "react-router-dom";
import Button from "@material-ui/core/Button";
import CloseIcon from '@material-ui/icons/Close';
import CheckBox from '../../shared/Fields/CheckBox';


const useStyles = makeStyles((theme) => ({
	modalHeader: {
		borderBottom: 'none !important',
		padding: '4px 4px 3px 0px',
		display: "block !important"
	},
	modalBody: {
		margin: "0 20px 20px 20px",
		backgroundColor: "#EEEEEE",
		padding: "38px"
	},
	titleFont: {
		fontSize: "20px !important"
	},
	submitDisabled: {
		border: '1px solid #e0e0e0 !important',
		textTransform: 'none',
	},
	checkboxAdjustments: {
		paddingLeft: '5px'
	}
}));

function FtOntologyModal(props) {

    const { t, i18n } = useTranslation("common");

    const classes = useStyles();
    const {savOntState} = props;

    const [modalUpdater, setModalUpdater] = useState(false);
    const [checkedOntologyState, setCheckedOntologyState] = useState(savOntState);
    const [savedOntologyState, setSavedOntologyState] = useState(savOntState);

    useEffect(async() => {}, [checkedOntologyState, savOntState]);

    const changeOntology = (key) => { //handles change on checkboxes
		var thisOnt = checkedOntologyState;
		var oldOntVal = thisOnt[key] //swaping in the new value
		oldOntVal = [oldOntVal[0], !oldOntVal[1]];
		thisOnt[key] = oldOntVal;

		//localStorage.setItem("FTOntologies", thisOnt);
		setCheckedOntologyState(thisOnt);
		setModalUpdater(!modalUpdater);
	}

	const quickSaveOntologies = () => {
		setSavedOntologyState(checkedOntologyState);
		let test = checkedOntologyState;
		if(test){
			localStorage.setItem("FTOntologiesQ", JSON.stringify(test));
		}
	}

	const loadquickSaveOntologies = () => {
		let ontFromMem = JSON.parse(localStorage.getItem('FTOntologiesQ'));
		if (ontFromMem != null) {
			setCheckedOntologyState(ontFromMem);
			setSavedOntologyState(ontFromMem);
		}
		//setModalupdater(!modalUpdater);
	}

	const applyOntologyState = () => {
		setSavedOntologyState(checkedOntologyState);
		props.closeModal();
	}

	const cancelOntologies = () =>{
		setCheckedOntologyState(savedOntologyState);
		props.closeModal();
	}


	return (
	    <Modal
			size="lg"
            show={props.show}
            onHide={props.closeModal}
			aria-labelledby="example-modal-sizes-title-lg"
		>
			<Modal.Header className={classes.modalHeader}> 
				<Link to="#" onClick={(e) => e.preventDefault()} className={"float-right  appTextColor"}><CloseIcon onClick={() => cancelOntologies()} /></Link>
			</Modal.Header>
			<Modal.Body className={classes.modalBody}>
					<h4 className={"subHeading mb-4 " + classes.titleFont}>{t('OntusedAuto')}</h4>
					 
					{Object.entries(checkedOntologyState).map(([key,value]) => {
    					return (
							<div style={{paddingLeft: '10px'}} key={key+""}>
    					      <table className="toppings-list-item">
    					        <tr className="left-section">
								 <td>
    					          <CheckBox
								    color="primary"
								    className={"float-left ml-2 mt-1"}
									id={key+'-id'}
									name={key+'-id'}
									checked={checkedOntologyState[key][1]}
    					            onChange={()=> changeOntology(key)}
    					          />
								  </td>
								  <td className={classes.checkboxAdjustments}>
    					            <label onClick={(e) => changeOntology(key)} className="makeStyles-checkBoxContent-88 bodyText ml-0 cursorPointer ontologyFix">{value[0]}</label>
								  </td>
								</tr>
    					      </table>
                              <span hidden>{modalUpdater}</span>
							  </div>
    					  );
    					})}
						{/* <span hidden>{modalUpdater}</span> */}

					<div className="clear">
						<Button className={"submitButtonClass float-right ml-2"} id="mergeSubmit" onClick={() => applyOntologyState()}>{t('apply')}</Button>
						<Button className={"cancelButtonClass float-right ml-2"} id="mergeCancel" onClick={() => cancelOntologies()}>{t('cancel')}</Button>
						<Button className={"submitButtonClass float-right ml-2"} onClick={() => loadquickSaveOntologies()}>{t('Load Quick Save')}</Button>
						<Button className={"submitButtonClass float-right ml-2"} onClick={() => quickSaveOntologies()}>{t('Quick Save')}</Button>							
					</div>
			</Modal.Body>
		</Modal>
	);
}

export default FtOntologyModal;