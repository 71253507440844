import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from "react-i18next";
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { useFormik } from 'formik';
import utilsService, { TOAST_TYPE } from '../../helpers/utils'
import _, { values } from "lodash";


import TextInput from '../../shared/Fields/TextInput';
import Constant from '../../helpers/constant';
import st26service from '../../services/st26service';
import ProteinSingle from './ProteinSingle';
import Switch from '@mui/material/Switch';
//import SingleLetterCodeConversion from './SingleLetterCodeConversion';
import * as FileSaver from 'file-saver';


const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1,
        width: '96%',
        margin: '0 auto 28px',
        minHeight: '260px',
        // borderBottom: '1px solid #cec7c7',
        padding: '23px 16px 14px',
    },
    headerPipe: {
        margin: '0 10px'
    },
    searchInput: {
        width: '30%'
    },
    buttonStyleCancel: {
        float: 'right',
        textTransform: 'none',
        margin: '4px',
        color: 'white',
        backgroundColor: '#008EC5 !important',
        border: '1px solid #1F4E79 !important',
        boxShadow: 'none',
        '&:hover': {
            boxShadow: 'none',
        }
    },

    antibodyNumInput: {
        width: '8%'
    }
}));

function St25CodeConversionToFasta() {
    const { t, i18n } = useTranslation('common');

    const classes = useStyles();
    const history = useHistory();
    const [authInfo, setAuthInfo] = useState();
    const [formdata, setFormData] = useState({});
    const { workflowId } = useParams();
    const toastRef = useRef(null)
    const [getResponse, setGetResponse] = useState(null);
    const [conversionCompleted, setConversionCompleted] = useState(false);
    const [uploadedFileName, setUploadedFileName] = useState("");

    function showfil(event) {
        const file = event.target.files[0];
        if (file) {
            setUploadedFileName(file.name);
        } else if (!formik.values.st26input) {
            setUploadedFileName(""); // Clear the file name when no file is selected and input is empty
        } else {
            setUploadedFileName(""); // Clear the file name when input is present but no file is selected
        }
        const reader = new FileReader();
        const preview = document.getElementById('st26input');
        const textFile = /text.*/;
        if (file.type.match(textFile)) {
            reader.onload = function (event) {
                preview.value = event.target.result;
                formik.setFieldValue("st26input", event.target.result);
            };
        } else {
            preview.innerHTML = "<span class='error'>It doesn't seem to be a text file!</span>";
        }
        reader.readAsText(file);
    }
     
    function downloadTextFile() {
       if (getResponse != null) {
           const blob = new Blob([getResponse], {
               type: "text/plain;charset=utf-8;"
           });
           FileSaver.saveAs(blob, uploadedFileName +".txt");
           window.location.reload();
       }
   }

    const resetFileName = () => {
        var fileInput = document.querySelector('input[type=file]');
        fileInput.value = '';
        setUploadedFileName('');
    };

    const ConvertToFasta = async () => {
        formik.setFieldValue("st26input", "");
        const postData = {
            st26input: formik.values.st26input
        };
        try {
            const response = await st26service.convertingST25ToFasta(postData, history, t);
            if(response === 'parsererror'){
                utilsService.showToast(TOAST_TYPE.ERROR, "The data is not correctly structured.\nPlease correct the .txt and try again.", toastRef);
            }else if(response === 'empty'){
                utilsService.showToast(TOAST_TYPE.ERROR, 'The data has no sequence to be parsed.', toastRef)
            }
            else if( response === 'missing'){
                utilsService.showToast(TOAST_TYPE.ERROR, 'The ST25 data is not correctly structured.\nEither the moltype or Sequence data  is missing.', toastRef);
            }else{
                setGetResponse(response);
                formik.setFieldValue("st26input", response);
                setConversionCompleted(true);
            }
        } catch (error){
            console.error("Error during conversion:", error);
        }
    };


    const formik = useFormik({
        initialValues: {
            st26input: formdata.st26input

        },
        enableReinitialize: true,
        onSubmit: async (values) => {
            let { st26input } = values;
            let postData = {
                st26input
            }
            var getResponse = await st26service.convertingST25ToFasta(postData, history, t);
            setGetResponse(getResponse)
        },
    });

    // useEffect(() => {
    //     // Reset uploaded file name when input field is emptied
    //     if (!formik.values.st26input) {
    //         setUploadedFileName(null);
    //     }
    // }, [formik.values.st26input]);

    function cncl() {
        window.location.reload();
    }

    return (
        <div className={classes.grow}>
            <form name="antibodySearchForm" onSubmit={formik.handleSubmit} className={classes.loginDiv}>
                <Row>
                    <Col lg="12" md="12" className={"mb-2 " + (authInfo && !authInfo.syscontrol_search_submit ? 'd-block' : 'd-none')}>
                        <Typography className="text-danger">
                            {t('ABsearchDisableText')}
                            {authInfo && authInfo.syscontrol_search_submit_txt}
                            {t('patienceThanksText')}
                        </Typography>
                    </Col>
                    <Col lg="12" md="12" className="mb-2">
                        <h6 className={"appTextColor loginTitle"}>ST.25 Code Conversion</h6>
                        <Row className="mb-3">
                            <Col lg="12" md="12" className="p-0 content float-left">
                                <div className="form-group px-3 ">
                                    <TextInput
                                        spellCheck={false}
                                        rows="20"
                                        multiline={true}
                                        fullWidth
                                        id="st26input"
                                        name="st26input"
                                        label="Copy and Paste your .txt sequences here"
                                        variant="outlined"
                                        value={formik.values.st26input}
                                        onChange={(event) => {
                                            formik.handleChange(event);
                                            if (!event.target.value) {
                                                resetFileName(); // Clear file input when text input is cleared
                                            }
                                        }}
                                        error={formik.touched.st26input && Boolean(formik.errors.st26input)}
                                        helperText={formik.errors.st26input}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <hr />
                    </Col>
                    <Col lg="12" md="12" className="float-right mb-3">
                        <p style={{ display: 'table-cell', fontSize: '15px' }}>
                            <b id="show-text">Upload .txt file</b>
                        </p>
                            <input type="file" accept=".txt" onChange={showfil} />
                        {!formik.values.st26input ?
                            <Button className='cancelButtonDisable' color="default" disableRipple={true} variant="contained" >
                                Convert to Fasta
                            </Button>
                            :
                            <Button
                                className='accountInfo'
                                color="default"
                                disableRipple={true}
                                variant="contained"
                                onClick={() => {
                                    if (conversionCompleted) {
                                        downloadTextFile();
                                    } else {
                                        ConvertToFasta();
                                    }
                                }}
                            >
                                {conversionCompleted ? 'Download' : 'Convert to Fasta'}
                            </Button>
                        }
                        <Button variant="contained" disableRipple={true} color="default" className={classes.buttonStyleCancel} onClick={cncl}>
                            {t('cancel')}
                        </Button>
                    </Col>
                </Row>
            </form>
        </div>
    );

}

export default St25CodeConversionToFasta;