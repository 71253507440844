import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1,
        width: '96%',
      },
    tooltipStyle: {
        top: '-10px',
        left: '5px',
        margin: '10px',
        padding: '0 !important',
        width: '300px',
        position: 'absolute',
        background: '#337ab7',
        zIndex: '1000',
    },
    textStyle: {
        background: '#337ab7 !important',
        color: '#FFF!important',
        font: '14px',
        text: "center",
        fontSize: '19px',

    },
    butGroup: {
        padding: '9px 14px',
        borderRadius: '6px',
        marginLeft: '-1px',
        float: 'left',
        borderColor: '#ccc'
    }
}));


export default function Tour({
    handleOnClick,
    tourStart,
    tourFlag,
    handleTourFlag
}) {
    const tourText = useState(["Is this your first time here? Please take a minute to see the most important features.",
        "You can always restart the tour here.",
        "Select Displayed columns and save views here.",
        "Highlight text by typing here.",
        "Filter results by typing text here. For example type the word claim.",
        "Filter results by selecting checkboxes/stars/colors here.",
        "Custom result filters.",
        "Variation Filters for subscribing users.",
        "Variation Landscape Reports for subscribing users.",
        "Always press the Apply button after setting up a filter.",
        "Export Results Here.",
        // "Select what to globally highlight, then select annotation.",
        "Sort results.",
        "Group results by Patent Family, Publication Number, etc."]);

    const classes = useStyles();
    const [tooltipPosition, setTooltipPosition] = useState(null);
    const [state, setState] = useState(2);
    const [marginTop, setMarginTop] = useState(0);
    const [marginBottom, setMarginBottom] = useState('auto');
    const [elePosition, setElePosition] = useState('absolute');
    const [arrowPosition, setArrowPosition] = useState('absolute');
    const [arrowBottom, setArrowBottom] = useState('auto');
    const [marginLeft, setMarginLeft] = useState(0);
    const [marginRight, setMarginRight] = useState(0);
    const [arrowMarginTop, setArrowMarginTop] = useState(0);
    const [arrowMarginLeft, setArrowMarginLeft] = useState(0);

    function getPosition(ex) {
        // const element = document.getElementById("second");
        // console.log(element.offsetTop);
        if (ex == '0') {
            setMarginTop('125px');
            setMarginBottom('auto');
            setMarginLeft('285px');
            setMarginRight('-312px');
            setArrowMarginTop('100px');
            setArrowMarginLeft('515px');
            document.getElementById('sideBarScroll2').scrollIntoView();
        }
        else if (ex == '1') {
            const ele = document.getElementById("tourTooltip");
            let pos2 = ele.offsetLeft;
            setMarginBottom('auto');
            setMarginTop('-54px');
            setMarginLeft(pos2+41);
            setMarginRight('-763px');
            setArrowMarginTop('-45px');
            setArrowMarginLeft(pos2+41);
            document.getElementById('sideBarScroll2').scrollIntoView();
        }
        else if (ex == '2') {
            setMarginBottom('auto');
            setMarginTop('-9px');
            setMarginLeft('0px');
            setMarginRight('0px');
            setArrowMarginTop('-13px');
            setArrowMarginLeft('0px');
            document.getElementById('tour-start').scrollIntoView();
        }
        else if (ex == '3') {
            const ele = document.getElementById("HighlightText");
            let pos1 = ele.offsetTop;
            setMarginTop(pos1-10);
            setMarginBottom('auto');
            setMarginLeft('-17px');
            setMarginRight('53px');
            setArrowMarginTop(pos1 - 20);
            setArrowMarginLeft('-17px');
            document.getElementById('tour-start').scrollIntoView();
        }
        else if (ex == '4') {
            const ele = document.getElementById("FilterText");
            let pos1 = ele.offsetTop;
            setMarginTop(pos1 - 32);
            setMarginBottom('auto');
            setMarginLeft('-17px');
            setMarginRight('8px');
            setArrowMarginTop(pos1-28);
            setArrowMarginLeft('-17px');
            document.getElementById('tour-highlight').scrollIntoView();
        }
        else if (ex == '5') {
            const ele = document.getElementById("annotationFilter");
            let pos1 = ele.offsetTop;
            setMarginTop(pos1 -50);
            setMarginBottom('auto');
            setMarginLeft('-17px');
            setMarginRight('8px');
            setArrowMarginTop(pos1-50);
            setArrowMarginLeft('-17px');
            document.getElementById('FilterText').scrollIntoView();
        }
        else if (ex == '6') {
            const ele = document.getElementById("advFilter");
            let pos1 = ele.offsetTop;
            setMarginTop(pos1 - 44);
            setMarginBottom('auto');
            setMarginLeft('-17px');
            setMarginRight('70px');
            setArrowMarginTop(pos1 -55 );
            setArrowMarginLeft('-17px');
            document.getElementById('advFilterScroll').scrollIntoView();    
        }
        else if (ex == '7') {
            const ele = document.getElementById("variationTooltip");
            const ele2= document.getElementById("variationLandscape");
            if (ele) {
                let pos1 = ele.offsetTop;
                setMarginBottom('auto');
                setMarginTop(pos1 - 25);
                setMarginLeft('-43px');
                setMarginRight('46px');
                setArrowMarginTop(pos1 - 25);
                setArrowMarginLeft('-43px');
                document.getElementById('ExportResultsScroll').scrollIntoView();
            }
            else if(ele2){
                let pos1 = ele2.offsetTop;
                setMarginBottom('auto');
                setMarginTop(pos1 - 18);
                setMarginLeft('-43px');
                setMarginRight('46px');
                setArrowMarginTop(pos1 - 18);
                setArrowMarginLeft('-43px');
                document.getElementById('ExportResultsScroll').scrollIntoView();
            }
            else {
                setMarginTop('320px');
                setMarginLeft('-289px');
                setMarginRight('267px');
                setArrowMarginTop('326px')
                setArrowMarginLeft('-289px');
                document.getElementById('tour-highlight').scrollIntoView();
            }
        }
        else if (ex == '8') {
            const ele = document.getElementById("variationTooltip");
            const ele2= document.getElementById("variationLandscape");
            if (ele) {
                let pos1 = ele.offsetTop;
                setElePosition('absolute');
                setArrowPosition('absolute');
                setMarginBottom('auto');
                setArrowBottom('auto');
                setMarginTop(pos1 + 5);
                setMarginLeft('-44px');
                setMarginRight('62px');
                setArrowMarginTop(pos1)
                setArrowMarginLeft('-44px');
                document.getElementById('ExportResultsScroll').scrollIntoView();
            }
            else if(ele2){
                let pos1 = ele2.offsetTop;
                setElePosition('absolute');
                setArrowPosition('absolute');
                setMarginBottom('auto');
                setArrowBottom('auto');
                setMarginTop(pos1 + 68);
                setMarginLeft('-44px');
                setMarginRight('62px');
                setArrowMarginTop(pos1 + 74)
                setArrowMarginLeft('-44px');
                document.getElementById('ExportResultsScroll').scrollIntoView();
            }
            else {
                setElePosition('absolute');
                setArrowPosition('absolute');
                setMarginBottom('auto');
                setArrowBottom('auto');
                setMarginTop('46px');
                setMarginLeft('-288px');
                setMarginRight('267px');
                setArrowMarginTop('51px')
                setArrowMarginLeft('-288px');
                document.getElementById('tour-start').scrollIntoView();
            }
        }
        else if (ex == '9') {
            // const ele = document.getElementById("floatApply");
            // let pos1 = ele.offsetTop;
            let pos1 = 315;
            setElePosition('fixed');
            setArrowPosition('fixed');
            setMarginTop('auto');
            setMarginBottom('3px');
            setArrowBottom('50px');
            setMarginLeft('315px');
            setMarginRight('716px');
            setArrowMarginTop('auto')
            setArrowMarginLeft('315px');
            document.getElementById('FilterText').scrollIntoView();
        }
        else if (ex == '10') {
            const ele = document.getElementById("ExportResults");
            let pos1 = ele.offsetTop;
            setElePosition('absolute');
            setArrowPosition('absolute');
            setMarginBottom('auto');
            setArrowBottom('auto');
            setMarginTop(pos1 - 46);
            setMarginLeft('-46px');
            setMarginRight('99px');
            setArrowMarginTop(pos1 - 55)
            setArrowMarginLeft('-46px');
            document.getElementById('ExportResultsScroll').scrollIntoView();
        }
        // this css for "Select what to globally highlight, then select annotation.",
        // else if(ex == '11'){
        //     setMarginTop('-51px');
        //     setMarginLeft('488px');
        //     setMarginRight('-553px'); 
        //     setArrowMarginTop('-49px')
        //     setArrowMarginLeft('488px');
        //     document.getElementById('tour-start').scrollIntoView();
        // }
        else if (ex == '11') {
            const ele = document.getElementById("viewTooltip");
            let pos2 = ele.offsetLeft;
            setMarginTop('-38px');
            setMarginBottom('auto');
            setMarginLeft(pos2);
            setMarginRight(-pos2);
            setArrowMarginTop('-45px')
            setArrowMarginLeft(pos2);
            document.getElementById('sidebarScroll').scrollIntoView();
        }
        else if (ex == '12') {
            const ele = document.getElementById("viewTooltip");
            let pos2 = ele.offsetLeft;
            setMarginBottom('auto');
            setMarginTop('-42px');
            setMarginLeft(pos2+207);
            setMarginRight('-741px');
            setArrowMarginTop('-44px');
            setArrowMarginLeft(pos2+207);
            document.getElementById('sideBarScroll2').scrollIntoView();
        }
    }
    useEffect(async () => {
        if(tourStart && tourFlag){ 
            setState(0);  
            getPosition(0); 
            handleFlag();
         }
    }, []);

    function handleOnClickPrev(e) {
        const pos = state - 1;
        if (state > 0) {
            setState(state - 1);
            setTooltipPosition(pos);
        }
        getPosition(pos)
    };

    function handleOnClickNext(e) {
        const pos = state + 1;
        if (state >= 0) {
            setState(state + 1);
            setTooltipPosition(state + 1);
        }
        getPosition(pos)
    };

    function handleOnClickPauseAndEndTour(e) {
        handleOnClick(e.target.value);
    }

    function handleFlag() {
        handleTourFlag();
    }

    return (
        <div className={classes.grow} >
            <div className={classes.tooltipStyle}>
                <div className='tour-arrow' style={{ top: arrowMarginTop, left: arrowMarginLeft, bottom: arrowBottom, position: arrowPosition }}></div>
                <div className='element' style={{ top: marginTop, left: marginLeft, right: marginRight, bottom: marginBottom, position: elePosition}}>
                    <div className={classes.butGroup}>
                        <div className={classes.textStyle}>{tourText[0][state]}</div>
                        <div className='tour-step-navigation'>
                            <div className='btn-group'>
                                {state > 0 &&
                                    <button className="prevBtn" style={{
                                        'border-top-right-radius': 0,
                                        'border-bottom-right-radius': 0,
                                        'border-right':'ridge'
                                    }} onClick={handleOnClickPrev} > « Prev</button>
                                }
                                {state < 12 &&
                                    <button className={(tooltipPosition == 0) ? 'prevBtnFor1' : 'nextBtn'} style={{
                                        'border-top-right-radius': 0,
                                        'border-bottom-right-radius': 0
                                    }} onClick={handleOnClickNext}>Next »</button>
                                }
                                <button className={(tooltipPosition == 12) ? 'pauseBtnFor12' : 'pauseBtn'} style={{
                                    'border-top-left-radius': 0,
                                    'border-bottom-left-radius': 0,
                                }} onClick={handleOnClickPauseAndEndTour}>Pause</button>
                            </div>
                            <button className="endTour" onClick={handleOnClickPauseAndEndTour}>End Tour</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
