import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import CloseIcon from '@material-ui/icons/Close';
import { toast } from 'react-toastify';
import CheckBox from '../../../shared/Fields/CheckBox';
import SelectBox from "../../../shared/Fields/SelectBox";
import AuthoritiesData from '../../../helpers/authorities';
import Constant from "../../../helpers/constant";
import _ from "lodash";


function FullTextResultInfoModal(props) {

	const {openOrClosed, resInfo, cancel, save} = props
    const authorities = AuthoritiesData.getAuthorities(true)
    const [tempInfo, setTempInfo] = useState(resInfo)

    const [readyRender, setReadyRender] = useState(false);

    const canelThis = ()=>{
        setTempInfo(resInfo);
        cancel()
    }

    useEffect(() => {
    },[tempInfo]);

    
    return (
          <div onClick={()=>{setReadyRender(!readyRender)}}>
			{openOrClosed &&
				<div>
					<div className="popupBackdrop" onClick={() => canelThis()}> 
					</div>
                    
                    <div className="resInfoPopup">
						<div>
							<Link href="#" className={"p-2 float-right appTextColor"}><CloseIcon onClick={() => canelThis()} /></Link>
						</div>
						<br></br>
                        <h4 className={"subHeading margin15 titleFont"}>Representative Document in Patent Families</h4>
                        <div className='margin15' >
                            <CheckBox
                                onChange={(e) => {tempInfo.isAuthoritySorting = !tempInfo.isAuthoritySorting; setTempInfo(tempInfo)}}
                                checked={tempInfo.isAuthoritySorting}
                            />
                            <label className={"checkBoxContent bodyText cursorPointer ml-0 mr-3"}>Apply this preferred authority order</label>
                        </div>
                        <div className='margin15'>
                        
                            <SelectBox
                                value={tempInfo.configure1}
                                items={authorities}
                                className={"mr-2 ml-2"}
                                onChange={(e) => {tempInfo.configure1 = e.target.value; setTempInfo(tempInfo)}}
                                disabled={!tempInfo.isAuthoritySorting}
                                smallSelectBox={true}
                            />
                            <SelectBox
                                value={tempInfo.configure2}
                                items={authorities}
                                onChange={(e) => {tempInfo.configure2 = e.target.value; setTempInfo(tempInfo)}}
                                className={"mr-2 ml-2"}
                                disabled={!tempInfo.isAuthoritySorting}
                                smallSelectBox={true}
                            />
                            <SelectBox
                                value={tempInfo.configure3}
                                items={authorities}
                                onChange={(e) => {tempInfo.configure3 = e.target.value; setTempInfo(tempInfo)}}
                                className={"mr-2 ml-2"}  
                                disabled={!tempInfo.isAuthoritySorting}
                                smallSelectBox={true}
                            />
                            <SelectBox
                                value={tempInfo.configure4}
                                items={authorities}
                                onChange={(e) => {tempInfo.configure4 = e.target.value; setTempInfo(tempInfo)}}
                                className={"mr-2 ml-2"}
                                disabled={!tempInfo.isAuthoritySorting}
                                smallSelectBox={true}
                            />
                            <SelectBox
                                value={tempInfo.configure5}
                                items={authorities}
                                onChange={(e) => {tempInfo.configure5 = e.target.value; setTempInfo(tempInfo)}}
                                className={"mr-2 ml-2"}
                                disabled={!tempInfo.isAuthoritySorting}
                                smallSelectBox={true}
                            />
                        </div>
                        <div className='mb-1'></div>
                        <div className='margin15'>
                            <CheckBox
                                onChange={() => {tempInfo.isDateSorting = !tempInfo.isDateSorting; setTempInfo(tempInfo)}}
                                checked={tempInfo.isDateSorting}
                            />
                            <label className={"bodyText ml-0 mr-3"}>Select documents with</label>
                        </div>
                        <div className='margin15'>

                            <SelectBox
                                value={tempInfo.dateSortingDirection}
                                items={Constant.dateSortingDirection}
                                onChange={(e) => {tempInfo.dateSortingDirection = e.target.value; setTempInfo(tempInfo)}}
                                className={"mr-2 ml-2"}
                                disabled={!tempInfo.isDateSorting}
                            />
                            <SelectBox
                                value={tempInfo.dateSortingField}
                                items={Constant.dateSortingField}
                                onChange={(e) => {tempInfo.dateSortingField = e.target.value; setTempInfo(tempInfo)}}
                                className={"mr-2 ml-2"}
                                disabled={!tempInfo.isDateSorting}
                            />
                        </div>
                        <div className="margin15">
                            <Button className={"submitButtonClass float-right ml-2"} id="mergeSubmit" onClick={() => {save(tempInfo)}}>Apply</Button>
                            <Button className={"cancelButtonClass float-right"} id="mergeCancel" onClick={() => canelThis()}>Cancel</Button>
                        </div>
            	    </div>
                    <div display="none">{readyRender}</div>
                </div>
			}
        </div>
    );
}

export default FullTextResultInfoModal;