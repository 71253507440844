import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";


const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    width: '270px',
    //padding: "6px"
    padding: '10px 0px',
    margin: '-8px 0 0 -5px'
  },
  dense: {
    marginTop: theme.spacing(2)
  },
  smallBox: {
    width: '180px',
    //padding: '10px 0px',
    margin: '0px',
    backgroundColor: '#EFEFEF'
  },
  middleBox: {
    width: '200px',
    //padding: '10px 0px',
    margin: '0px',
    backgroundColor: '#EFEFEF'
  },
  input: {
    margin: theme.spacing(0),
    height: 36,
  },
}));

export default function DiscoverSelectBox(props) {
  const classes = useStyles();

  const items = props.items;
  return (
    // <form className={classes.container} noValidate autoComplete="off">
    <TextField
      id={props.id}
      name={props.name}
      select
      // label={gender=== "" ? "Gender": ""}
      className={props.className + " " + (props.smallSelectBox ? classes.smallBox : (props.midSelectBox ? classes.middleBox : classes.textField))}
      value={props.value}
      InputLabelProps={{ shrink: false }}
      InputProps={{ className: classes.input }}
      SelectProps={{
        MenuProps: {
          className: (props.smallSelectBox ? "" : classes.textField),
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left"
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left"
          },
          getContentAnchorEl: null

        }
      }}
      margin={props.margin}
      variant={props.variant}
      onChange={props.onChange}
      disabled={props.disabled}
      onBlur={props.onBlur}
    >
      {items && items.length > 0 && items.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
    // </form>
  );
}
