import React, {useEffect, useState} from 'react';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import Modal from 'react-bootstrap/Modal';
import CloseIcon from '@material-ui/icons/Close';
import Link from '@material-ui/core/Link';
import {FaCaretDown, FaPlus, FaMinus} from "react-icons/fa";
import ReactHtmlParser from "react-html-parser";
import Tooltip from "@material-ui/core/Tooltip";
import UtilsService from "../../helpers/utils";
import TextInput from "../Fields/TextInput";
import DatePicker from "../Fields/DatePicker";
import _ from "lodash";
import Dropdown from "react-bootstrap/Dropdown";
import DiscoverSelectBox from "../Fields/DiscoverSelectBox";
import FullTextSearchBox from '../components/FullTextSearchBox/FullTextSearchBox';

const useStyles = makeStyles((theme) => ({
    modalClassConDSI: {
        position: 'absolute',
        padding: '15px 15px 10px',
        minHeight: '250px',
        width: '100%',
        maxHeight: '100vh',
        //overflowY: 'auto',
        //bottom: 'auto',
        //right: 'auto',
    },
    closeBtn: {
        position: 'fixed',
        right: '10px',
        bottom: '10px'
    },
    input: {
        margin: theme.spacing(0),
        height: 36,
    },
    dataInput: {
        borderRadius: "7px",
    }
}));

// operators available in the filtering widget according to type
const operatorSelections = {
    string: [
        {value: "CONTAINS", label: 'contains', id: 'CONTAINS'},
        {value: "EQUALS", label: 'equals', id: 'EQUALS'},
        {value: "NOTCONTAINS", label: 'does not contain', id: 'NOTCONTAINS'},
        {value: "NOTEQUALS", label: 'does not equal', id: 'NOTEQUALS'}
    ],
    string_frame: [
        {value: "EQUALS", label: 'is', id: 'EQUALS'},
        {value: "NOTEQUALS", label: 'is not', id: 'NOTEQUALS'}
    ],
    date: [
        {value: "EQUALS", label: 'equals', id: 'EQUALS'},
        {value: "LESS", label: 'before or on', id: 'LESS'},
        {value: "LESS_IE", label: 'before or is empty', id: 'LESS_IE'},
        {value: "MORE", label: 'after or on', id: 'MORE'},
        {value: "MORE_IE", label: 'after or is empty', id: 'MORE_IE'},
        {value: "BETWEEN", label: 'between or on', id: 'BETWEEN'}
    ],
    int: [
        {value: "EQUALS", label: 'equals', id: 'EQUALS'},
        {value: "LESS_NE", label: 'less than', id: 'LESS_NE'},
        {value: "LESS", label: 'less than or equal', id: 'LESS'},
        {value: "MORE_NE", label: 'more than', id: 'MORE_NE'},
        {value: "MORE", label: 'more than or equal', id: 'MORE'},
        {value: "BETWEEN", label: 'between or equal', id: 'BETWEEN'}
    ],
    float: [
        {value: "EQUALS", label: 'equals', id: 'EQUALS'},
        {value: "LESS_NE", label: 'less than', id: 'LESS_NE'},
        {value: "LESS", label: 'less than or equal', id: 'LESS'},
        {value: "MORE_NE", label: 'more than', id: 'MORE_NE'},
        {value: "MORE", label: 'more than or equal', id: 'MORE'},
        {value: "BETWEEN", label: 'between or equal', id: 'BETWEEN'}
    ],
    percentage: [
        {value: "EQUALS", label: 'equals', id: 'EQUALS'},
        {value: "LESS_NE", label: 'less than', id: 'LESS_NE'},
        {value: "LESS", label: 'less than or equal', id: 'LESS'},
        {value: "MORE_NE", label: 'more than', id: 'MORE_NE'},
        {value: "MORE", label: 'more than or equal', id: 'MORE'},
        {value: "BETWEEN", label: 'between or equal', id: 'BETWEEN'}
    ]
};

const pulldownSelections = {
    SUBJECT_PG: [
        {value: "Expired", label: 'Expired', id: 'Expired'},
        {value: "Granted", label: 'Granted', id: 'Granted'},
        {value: "Lapsed", label: 'Lapsed', id: 'Lapsed'},
        {value: "Pending", label: 'Pending', id: 'Pending'},
        {value: "Revoked", label: 'Revoked', id: 'Revoked'},
        {value: "Unknown", label: 'Unknown', id: 'Unknown'},
        {value: "Other", label: 'Other', id: 'Other'}
    ],
    SUBJECT_PS: [
        {value: "Claim", label: 'sequence in claims', id: 'Claim'},
        {value: "Disclosure", label: 'sequence in disclosure', id: 'Disclosure'},
        {value: "TBD", label: 'to be determined, English claims not available', id: 'TBD'}
    ],
    RESULT_NFD: [
        {value: "0", label: 'frame +1', id: '0'},
        {value: "1", label: 'frame +2', id: '1'},
        {value: "2", label: 'frame +3', id: '2'},
        {value: "3", label: 'frame -1', id: '3'},
        {value: "4", label: 'frame -2', id: '4'},
        {value: "5", label: 'frame -3', id: '5'},
        {value: "6", label: 'reverse', id: '6'},
        {value: "7", label: 'forward', id: '7'}
    ],
    RESULT_FQ: [
        {value: "0", label: 'frame +1', id: '0'},
        {value: "1", label: 'frame +2', id: '1'},
        {value: "2", label: 'frame +3', id: '2'},
        {value: "3", label: 'frame -1', id: '3'},
        {value: "4", label: 'frame -2', id: '4'},
        {value: "5", label: 'frame -3', id: '5'},
        {value: "6", label: 'reverse', id: '6'},
        {value: "7", label: 'forward', id: '7'}
    ]
};

// how fields are displayed in the filter widget
const filterWidgetFields = [
    { group: '', fields: ['ALL_TEXT', 'SUBJECT_PN', 'QUERY_ID', 'SUBJECT_ID', 'SUBJECT_FTS'] },
    { group: 'Important Dates', fields: ['SUBJECT_D4', 'SUBJECT_D3', 'SUBJECT_D5', 'SUBJECT_D7', 'SUBJECT_D1', 'SUBJECT_D2'] },
    { group: 'Status', fields: ['SUBJECT_STATUS', 'SUBJECT_PG', 'SUBJECT_PH', 'SUBJECT_L3'] }, //'SUBJECT_LS',  'SUBJECT_L2'
    {
        group: 'Publication IDs',
        fields: ['SUBJECT_PN', 'SUBJECT_KC', 'SUBJECT_PV', 'SUBJECT_AN', 'SUBJECT_AC']
    },
    { group: 'Patent Family', fields: ['SUBJECT_PB', 'SUBJECT_PM', 'SUBJECT_XF'] },
    {
        group: 'Patent Information', fields: [
            'SUBJECT_PT', 'SUBJECT_P1', 'SUBJECT_CT', 'SUBJECT_P7', 'SUBJECT_PC', 'SUBJECT_KW', 'SUBJECT_CC', 'SUBJECT_P9',
            'SUBJECT_PO', 'SUBJECT_PE', 'SUBJECT_PR', 'SUBJECT_EM', 'SUBJECT_PQ', 'SUBJECT_P4', 'SUBJECT_P5', 'SUBJECT_P8'
        ]
    },
    {
        group: 'Subject Sequence',
        fields: ['SUBJECT_SI', 'SUBJECT_PS', 'SUBJECT_ID', 'SUBJECT_GX', 'SUBJECT_GI', 'SUBJECT_AC', 'SUBJECT_L', 'SUBJECT_OO', 'SUBJECT_OS', 'SUBJECT_OX', 'UFS_DISPLAY', 'SUBJECT_PJ', 'SUBJECT_DR', 'SUBJECT_OC']
    },
    { group: 'Query Sequence', fields: ['QUERY_ID', 'QUERY_L', 'QUERY_DE'] },
    {
        group: 'Assignees and Inventors',
        fields: ['SUBJECT_PAINV', 'SUBJECT_PA', 'SUBJECT_PU', 'SUBJECT_PZ', 'SUBJECT_PI']
    },
    {
        group: 'Public Information',
        fields: ['SUBJECT_AC', 'SUBJECT_FT', 'SUBJECT_MT', 'SUBJECT_PK', 'SUBJECT_RA', 'SUBJECT_RL', 'SUBJECT_SV', 'SUBJECT_DE']
    },
    {
        group: 'Alignment Quality', fields: [
            'RESULT_RI', 'RESULT_RIQ', 'RESULT_RID', 'QUERY_L', 'SUBJECT_L', 'RESULT_RE', 'RESULT_RL', 'RESULT_RS', 'RESULT_FQ', 'RESULT_NFD',
            'RESULT_GAPQ', 'RESULT_GAPD', 'RESULT_RF', 'RESULT_RM', 'RESULT_RIG', 'RESULT_RCD', 'RESULT_RCQ', 'RESULT_COV', 'RESULT_RZ'
        ]
    },
    {
        group: 'Alignment Position',
        fields: ['RESULT_OOBQ', 'RESULT_OOEQ', 'RESULT_OOBD', 'RESULT_OOED'] // 'RESULT_OBQ', 'RESULT_OEQ', 'RESULT_OBD', 'RESULT_OED'
    },
    {
        group: 'CAS Biosequences™',
        fields: ['SUBJECT_SY', 'SUBJECT_BM', 'SUBJECT_CD', 'SUBJECT_RG', 'SUBJECT_RB', 'SUBJECT_RC', 'SUBJECT_DS', 'SUBJECT_NT', 'SUBJECT_CB', 'SUBJECT_CL', 'SUBJECT_GY', 'SUBJECT_CJ', 'SUBJECT_CK',
            'SUBJECT_C8', 'SUBJECT_C6', 'SUBJECT_MP', 'SUBJECT_C9', 'SUBJECT_DC', 'SUBJECT_CQ', 'SUBJECT_RE', 'SUBJECT_RD', 'SUBJECT_SX', 'SUBJECT_CU', 'SUBJECT_S8', 'SUBJECT_CM', 'SUBJECT_S9', 'SUBJECT_C7']
    },
    {
        group: 'CAS Biosequences™ ',
        fields: ['SUBJECT_SY', 'SUBJECT_BM', 'SUBJECT_CD', 'SUBJECT_RG', 'SUBJECT_RB', 'SUBJECT_RC', 'SUBJECT_DS', 'SUBJECT_NT', 'SUBJECT_MP', 'SUBJECT_RE', 'SUBJECT_RD', 'SUBJECT_SX', 'SUBJECT_S8', 'SUBJECT_CM', 'SUBJECT_S9']
    }
];

const filterWidgetFieldsWOReg = [
    { group: '', fields: ['ALL_TEXT', 'SUBJECT_PN', 'QUERY_ID', 'SUBJECT_ID', 'SUBJECT_FTS'] },
    { group: 'Important Dates', fields: ['SUBJECT_D4', 'SUBJECT_D3', 'SUBJECT_D5', 'SUBJECT_D7', 'SUBJECT_D1', 'SUBJECT_D2'] },
    { group: 'Status', fields: ['SUBJECT_STATUS', 'SUBJECT_PG', 'SUBJECT_PH', 'SUBJECT_L3'] }, //'SUBJECT_LS',  'SUBJECT_L2'
    {
        group: 'Publication IDs',
        fields: ['SUBJECT_PN', 'SUBJECT_KC', 'SUBJECT_PV', 'SUBJECT_AN', 'SUBJECT_AC']
    },
    { group: 'Patent Family', fields: ['SUBJECT_PB', 'SUBJECT_PM', 'SUBJECT_XF'] },
    {
        group: 'Patent Information', fields: [
            'SUBJECT_PT', 'SUBJECT_P1', 'SUBJECT_CT', 'SUBJECT_P7', 'SUBJECT_PC', 'SUBJECT_KW', 'SUBJECT_CC', 'SUBJECT_P9',
            'SUBJECT_PO', 'SUBJECT_PE', 'SUBJECT_PR', 'SUBJECT_EM', 'SUBJECT_PQ', 'SUBJECT_P4', 'SUBJECT_P5', 'SUBJECT_P8'
        ]
    },
    {
        group: 'Subject Sequence',
        fields: ['SUBJECT_SI', 'SUBJECT_PS', 'SUBJECT_ID', 'SUBJECT_GX', 'SUBJECT_GI', 'SUBJECT_AC', 'SUBJECT_L', 'SUBJECT_OO', 'SUBJECT_OS', 'SUBJECT_OX', 'UFS_DISPLAY', 'SUBJECT_PJ', 'SUBJECT_DR', 'SUBJECT_OC']
    },
    { group: 'Query Sequence', fields: ['QUERY_ID', 'QUERY_L', 'QUERY_DE'] },
    {
        group: 'Assignees and Inventors',
        fields: ['SUBJECT_PAINV', 'SUBJECT_PA', 'SUBJECT_PU', 'SUBJECT_PZ', 'SUBJECT_PI']
    },
    {
        group: 'Public Information',
        fields: ['SUBJECT_AC', 'SUBJECT_FT', 'SUBJECT_MT', 'SUBJECT_PK', 'SUBJECT_RA', 'SUBJECT_RL', 'SUBJECT_SV', 'SUBJECT_DE']
    },
    {
        group: 'Alignment Quality', fields: [
            'RESULT_RI', 'RESULT_RIQ', 'RESULT_RID', 'RESULT_RE', 'RESULT_RL', 'RESULT_RS', 'RESULT_FQ', 'RESULT_NFD', 'RESULT_GAPQ', 'RESULT_GAPD',
            'RESULT_RF', 'RESULT_RM', 'RESULT_RIG', 'RESULT_RCD', 'RESULT_RCQ', 'RESULT_COV', 'RESULT_RZ' //check
        ]
    },
    {
        group: 'Alignment Position',
        fields: ['RESULT_OOBQ', 'RESULT_OOEQ', 'RESULT_OOBD', 'RESULT_OOED'] // 'RESULT_OBQ', 'RESULT_OEQ', 'RESULT_OBD', 'RESULT_OED'
    }
];

const toolTipStyles = {
    tooltip: {
        width: "auto",
        //height: "40px",
        fontSize: '12px',
        textAlign: 'justify',
        padding: "8px 14px 8px 14px",
        boxShadow: "0px 1px 7px #888",
        backgroundColor: '#777',
        color: '#FFF'
    }
};
const CustomTooltip = withStyles(toolTipStyles)(Tooltip);
const defaultAtom = {field: 'ALL_TEXT', field_type: 'string', operator: 'CONTAINS', value: '', second_value: ''};

const DiscoverFilterModal = props => {
    const classes = useStyles();
    const [widgetState, setWidgetState] = useState(props.widgetState);
    const [filterNameUsed, setFilterNameUsed] = useState(-1);
    const [customFilterNameErr, setCustomFilterNameErr] = useState(false);
    const [customQueryMessage, setCustomQueryMessage] = useState('');
    const [widgetAtomErrStatus, setWidgetAtomErrStatus] = useState([]);
    const [filterNameErr, setFilterNameErr] = useState('');
    let algo = props.algorithm;
    let isAB = props.isAbWorkflow;

    useEffect(() => {
        let state = {...widgetState};
        state = _.cloneDeep(props.widgetState);
        setWidgetState(state);
        checkCustomFilterName(props.widgetState.customFilterName);
        //errorsInFilterWidget(state);
    }, [props.widgetState]);

    const changeSelection = (e) => {
        //setChoice(e.target.value);
        widgetState.widgetMode = e.target.value;
        setWidgetState({...widgetState});
    }

    const setFilterWidgetField = (field, idx) => {
        let previousType = getFieldType(idx);
        let previousField = widgetState.widgetContent[idx].field;
        widgetState.widgetContent[idx].field = field;
        let currentType = props.fieldConfig.fieldConfig[field].type;

        if (currentType !== previousType) {
            widgetState.widgetContent[idx].value = '';
            widgetState.widgetContent[idx].second_value = '';
            if (currentType === 'string') {
                widgetState.widgetContent[idx].operator = 'CONTAINS';
            } else {
                widgetState.widgetContent[idx].operator = 'MORE'
                if (currentType === 'date') {
                    widgetState.widgetContent[idx].operator = 'MORE';
                    widgetState.widgetContent[idx].value = new Date();
                }
                else if(currentType === 'string_frame'){
                    widgetState.widgetContent[idx].operator = 'IS';
                }
            }
        } else if (previousField === 'SUBJECT_PG' || previousField === 'SUBJECT_PS') {
            widgetState.widgetContent[idx].value = '';
        }

        // Default drop down value
        if (field === 'SUBJECT_PG') {
            widgetState.widgetContent[idx].value = 'Expired';
        } else if (field === 'SUBJECT_PS') {
            widgetState.widgetContent[idx].value = 'Claim';
        } else if (field === 'RESULT_NFD' || field === 'RESULT_FQ') {
            widgetState.widgetContent[idx].value = '0';
        }

        if (currentType === 'CUSTOM') {
            widgetState.widgetCustomAtomUsed = {};
        }

        widgetState.widgetContent[idx].field_type = currentType;
        setWidgetState({...widgetState});
    }

    const setFilterWidgetOperator = (e, idx) => {
        widgetState.widgetContent[idx].operator = e.target.value;
        if (e.target.value === 'BETWEEN') {
            if (getFieldType(idx) === 'date') {
                widgetState.widgetContent[idx].second_value = new Date();
            }
        } else {
            //widgetState.widgetContent[idx].second_value = '';
        }
        setWidgetState({...widgetState});
    }

    const setFilterWidgetValue = (e, idx) => {
        widgetState.widgetContent[idx].value = e.target.value;
        setWidgetState({...widgetState});
    }

    const setFTString = (endString, idx) =>{
        widgetState.widgetContent[idx].tempFieldDisplayValue = endString;
        setWidgetState({...widgetState})
    }

    function setEndStringsFT(){
        for (let i = 0; i < widgetState.widgetContent.length; i++) {
            if(widgetState.widgetContent[i].tempFieldDisplayValue){
                widgetState.widgetContent[i].fieldDisplayValue = widgetState.widgetContent[i].tempFieldDisplayValue;
            }
        }
        setWidgetState({...widgetState})
    }

    const setFilterWidgetSecondValue = (e, idx) => {
        widgetState.widgetContent[idx].second_value = e.target.value;
        setWidgetState({...widgetState});
    }

    const widgetDelAtom = (idx) => {
        widgetState.widgetContent.splice(idx, 1);
        setWidgetState({...widgetState});
    }

    const widgetAddAtom = (idx) => {
        widgetState.widgetContent.splice((idx + 1), 0, _.cloneDeep(defaultAtom));
        setWidgetState({...widgetState});
    }

    function getFieldType(idx) {
        let field = widgetState.widgetContent[idx].field,
            type = props.fieldConfig.fieldConfig[field].type;
        return type;
    }

    function getFieldId(idx) {
        return widgetState.widgetContent[idx].field;
    }

    function widgetGetTextAreaNbRows(idx) {
        // count number of return characters and allow up to 4 rows for the textarea
        let value = String(widgetState.widgetContent[idx].value),
            count = 1 + (value.match(/\r\n|\r|\n/g) || []).length;
        return (count > 4) ? 4 : count;
    }

    const clearFilterWidget = () => {
        setWidgetAtomErrStatus([]);
        widgetState.widgetContent = [_.cloneDeep(defaultAtom)];
        widgetState.widgetMode = 'AND';
        widgetState.widgetCustomQuery = '';
        widgetState.customFilterName = '';
        setFilterNameUsed(-1);
        setWidgetState({...widgetState});
    }

    const setCustomFilterName = (e) => {
        widgetState.customFilterName = e.target.value;
        setWidgetState({...widgetState});
        checkCustomFilterName(e.target.value);
    }

    const checkCustomFilterName = (val) => {
        // see if a filter name has been used before and return the index number (or === false)
        let retVal = -1;
        for (let i = 0; i < props.filter.widgetState.customFilters.length; i++) {
            if (props.filter.widgetState.customFilters[i].customFilterName === val) {
                retVal = i;
                break;
            }
        }
        setFilterNameUsed(retVal);
        return retVal;
    }

    const save = () => {
        if (!errorsInFilterWidget(widgetState)) {
            setEndStringsFT();
            extractKeyWords(_.cloneDeep(widgetState.widgetContent))
            props.filter.widgetState.filterNameUsed = checkCustomFilterName(widgetState.customFilterName);
            props.filter.widgetState.widgetMode = widgetState.widgetMode;
            props.filter.widgetState.customFilterName = widgetState.customFilterName;
            props.filter.widgetState.widgetCustomQuery = widgetState.widgetCustomQuery;
            props.filter.widgetState.widgetContent = _.cloneDeep(widgetState.widgetContent);
            props.callback(props.filter, props.fieldConfig, props.sideMenuDetail);
        }
    }

    function extractKeyWords(widgetContent){

        let retRay = []

        for(let i = 0; i < widgetContent.length; i++){
            if(widgetContent[i].keyWords!= undefined){
                if(widgetContent[i].keyWords.length > 0){
                    for(let k = 0; k <= widgetContent[i].keyWords.length; k++){
                        if(!retRay.includes(widgetContent[i].keyWords[k])){
                            retRay.push(widgetContent[i].keyWords[k]);
                        }
                    }
                    
                }
            }
        }

        props.keyWordsInContext(retRay)
    }

    // verifies if everything is OK with the filter (returns true when it is)
    function errorsInFilterWidget(widgetState) {
        let filterCorrect = true;
        setWidgetAtomErrStatus([]);
        widgetState.widgetCustomAtomUsed = {};
        setCustomFilterNameErr(false);
        setFilterNameErr('');
        if (widgetState.widgetMode === 'CUSTOM') {
            // if custom, the query syntax must be good
            filterCorrect = verifyCustomQuerySyntax(widgetState);
        }
        if (filterCorrect) {
            // the filter must have a name
            filterCorrect = (widgetState.customFilterName ? true : false);
            setCustomFilterNameErr(!filterCorrect); // set error when filter name is empty
        }
        if (filterCorrect) {
            // every atom must have a value
            filterCorrect = verifyFilterWidgetAtoms(widgetState);
        }
        return !filterCorrect;
    }

    function verifyFilterWidgetAtoms(widgetState) {
        let i = 0, operator, value, second_value, nbErrors = 0, status, type;
        for (i = 0; i < widgetState.widgetContent.length; i++) {
            // field, operator, value, second_value
            operator = widgetState.widgetContent[i].operator;
            type = getFieldType(i);
            value = (widgetState.widgetContent[i].value != null && widgetState.widgetContent[i].value !== "") ? true : false;
            second_value = widgetState.widgetContent[i].second_value ? true : false;
            if (operator === 'BETWEEN') {
                status = !(value && second_value);
                // Compare the values with the specific type
                if (!status) {
                    if (type === 'int'
                        && parseInt(widgetState.widgetContent[i].value) > parseInt(widgetState.widgetContent[i].second_value)) {
                        status = true;
                    } else if ((type === 'percentage' || type === 'float')
                        && parseFloat(widgetState.widgetContent[i].value) > parseFloat(widgetState.widgetContent[i].second_value)) {
                        status = true;
                    } else if (type === 'date'
                        && Date.parse(widgetState.widgetContent[i].value) > Date.parse(widgetState.widgetContent[i].second_value)) {
                        status = true;
                    }
                }
            } else {
                status = !value;
            }
            // Check if less than 400 PNs
            if (value && filterNameErr === '' && widgetState.widgetContent[i].field === 'SUBJECT_PN') {
                let tmpValues = widgetState.widgetContent[i].value.replace(',', "\n").replace(';', "\n");
                let values = tmpValues.split('\n');
                if (values.length > 400) {
                    setFilterNameErr('Exceeded 400 patent numbers. Please use Patent Numbers box above, not Advanced Filter.');
                    status = true;
                }
            }
            widgetAtomErrStatus[i] = status;
            if (status) {
                nbErrors++;
            }
        }
        setWidgetAtomErrStatus([...widgetAtomErrStatus]);
        return (nbErrors === 0);
    }

    function verifyCustomQuerySyntax(widgetState, value) {
        setCustomQueryMessage('');
        let val = value ? value : (widgetState.widgetCustomQuery ? widgetState.widgetCustomQuery : ''),
            msg = '',
            tmp_val = val.toUpperCase(),
            words = [],
            nbParenthesesLeft = 0,
            nbParenthesesRight = 0,
            i = 0,
            nbErrors = 0,
            totNbAtoms = _.size(widgetState.widgetContent),
            atomNb = 0,
            lastSeenAtom = 0;


        if (val === '' || !val) {
            nbErrors++;
            setCustomQueryMessage('Please type your custom query');
            return (nbErrors === 0);
        }

        // reset which atoms have been used in the custom filter
        widgetState.widgetCustomAtomUsed = {};

        // check if all words are either and, or, F[0-9]+
        // Unnecessary escape character: \( \)
        tmp_val = tmp_val
            .replace(/[ ()]/g, ' ')
            .replace(/^ +| +$/g, '')
            .replace(/ +/g, ' ');
        words = (_.split(tmp_val, ' ') || []);
        for (i = 0; i < words.length; i++) {
            switch (words[i]) {
                case 'AND':
                case 'OR':
                    // last word can't be AND / OR
                    if (i === words.length - 1) {
                        msg += 'Syntax error (last ' + words[i] + '). ';
                        nbErrors++;
                    }
                    break;
                default:
                    if (!(words[i].match(/^F[0-9]+$/) || []).length) {
                        // console.log('unknown word: ' + words[i])
                        if (words[i]) {
                            msg += 'Syntax error "' + words[i] + '" not recognized. ';
                            nbErrors++;
                        }
                    } else {
                        // keep track of which atoms are used
                        atomNb = parseInt(words[i].replace('F', ''));
                        if (atomNb <= totNbAtoms) {
                            // set the atom (F1 ... FN) as used in case of custom filters
                            widgetState.widgetCustomAtomUsed[words[i]] = true;
                            // make sure atoms are connected by AND / OR
                            if ((i - lastSeenAtom) !== 0 && (i - lastSeenAtom) !== 2) {
                                // error forget and / or somewhere
                                msg += 'Syntax error (AND/OR expected). ';
                                nbErrors++;
                            }
                            lastSeenAtom = i;
                        } else {
                            // non-existing atom number
                            msg += 'Syntax error ("' + words[i] + '" does not exist). ';
                            nbErrors++;
                        }
                    }
                    break;
            }
        }

        // check if number of opening parenthesis equals closing ones
        tmp_val = val;
        nbParenthesesLeft = (tmp_val.match(/\(/g) || []).length;
        nbParenthesesRight = (tmp_val.match(/\)/g) || []).length;
        if (nbParenthesesLeft !== nbParenthesesRight) {
            msg += 'Syntax error (parentheses). ';
            nbErrors++;
        }
        setCustomQueryMessage(msg);
        setWidgetState({...widgetState});
        return (nbErrors === 0);
    }

    const checkCustomQuerySyntax = (e) => {
        widgetState.widgetCustomQuery = e.target.value;
        setWidgetState({...widgetState});
        return verifyCustomQuerySyntax(widgetState, e.target.value);
    }

    function deepCopy(value){
        let newAddress = value+"";
        return newAddress;
    }

    return (
        <Modal
            size="lg"
            aria-labelledby="contained-modal-title-vcente"
            centered
            show={props.showModal}
            //contentClassName={classes.modalClassConDSI}
        >        
            <div className="large-font">
                <Link href="#" onClick={(e) => e.preventDefault()} className="pull-right">
                    <CloseIcon onClick={props.closeModal}/>
                </Link>
            </div>
            <Modal.Body>
            <div style={{padding: '10px 10px 20px 10px'}}> {/*className="filter-widget-container"*/}
                <table>
                    <tbody>
                    {/*all, any, random */}
                    <tr>
                        <td colSpan="6" className="filter-widget-top clearfix">
                            <div className="pull-left filter-widget-field-middle custom-filter-label">
                                Results must meet&nbsp;&nbsp;&nbsp;
                                <input id="AND" type="radio" value="AND" name="radioGroup"
                                       style={{verticalAlign: 'middle'}}
                                       checked={widgetState.widgetMode === 'AND'}
                                       onChange={changeSelection}/>
                                <span htmlFor="AND">&nbsp;all conditions</span>
                                &nbsp;&nbsp;&nbsp;
                                <input id="OR" type="radio" value="OR" name="radioGroup"
                                       style={{verticalAlign: 'middle'}}
                                       checked={widgetState.widgetMode === 'OR'}
                                       onChange={changeSelection}/>
                                <span htmlFor="OR">&nbsp;at least one condition</span>
                                &nbsp;&nbsp;&nbsp;
                                <input id="CUSTOM" type="radio" value="CUSTOM" name="radioGroup"
                                       style={{verticalAlign: 'middle'}}
                                       checked={widgetState.widgetMode === 'CUSTOM'}
                                       onChange={changeSelection}/>
                                <span htmlFor="CUSTOM">&nbsp;custom</span>
                            </div>
                            <div className="pull-right">
                                <input type="text" autoComplete="off" spellCheck="false"
                                       className={'filter-widget-input-textarea ' + (customFilterNameErr ? 'input-with-error' : '')}
                                       style={{width: '210px'}} placeholder="Name your Filter"
                                       value={widgetState.customFilterName}
                                       onChange={(e) => {setCustomFilterName(e)}}/>
                            </div>
                        </td>
                    </tr>
                    {widgetState.widgetMode === 'CUSTOM' &&
                        <tr>
                            <td colSpan="6" className="clearfix filter-widget-top">
                                <textarea className="filter-widget-input-textarea"
                                          placeholder="Type your custom query here using the F-numbers below. For example: ((F1 and F3) or F2)"
                                          rows="3"
                                          value={widgetState.widgetCustomQuery}
                                          onChange={checkCustomQuerySyntax}></textarea>
                                <div className="custom-query-error">{customQueryMessage}</div>
                            </td>
                        </tr>
                    }
                    {widgetState?.widgetContent && Object.keys(widgetState.widgetContent).length > 0 &&
                        Object.keys(widgetState.widgetContent).map((atom, idx) => (
                            <tr key={'filter_' + idx} className={""+(getFieldId(idx) !== "SUBJECT_FTS"? "":"ftFilterModalRow")}>
                                {/* F designation for the custom filter */}
                                {widgetState.widgetMode === 'CUSTOM' &&
                                    <td className={' filter-widget-atom-index ' + (widgetState.widgetCustomAtomUsed['F'+(1+idx)]?'green-font':'red-font')}>
                                        F{(1 + idx)}
                                    </td>
                                }

                                {/* field */}
                                <td className="filter-widget-field" style={{width: '175px'}}>
                                    <div className="control-spacer">
                                        <Dropdown className="btn-group">
                                            <Dropdown.Toggle className="btn btn-dropdown btn-dropdown-dates">
                                                <div className="clearfix">
                                                    <div className="pull-left custom-filter-label">{ReactHtmlParser(props.fieldConfig.getFieldName(widgetState.widgetContent[atom].field, algo, isAB))}</div>
                                                    <div style={{ display: 'inline-block', position: 'absolute', right: '2px' }}><FaCaretDown/></div>
                                                </div>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className="dropdown-menu dropdown-menu-dates custom-filter-label">
                                            {props.displayRegFields && filterWidgetFields.map(({group, fields}, key) =>
                                                ((group !== 'CAS Biosequences™' && group !== 'CAS Biosequences™ ')
                                                        || (group === 'CAS Biosequences™' && props.hasRegNP)
                                                        || (group === 'CAS Biosequences™ ' && !props.hasRegNP)) &&
                                                <div key={'dropdown_' + key}>
                                                    <div className="filter-widget-group-item">{group}</div>
                                                    {fields.map((field, subIdx) => (
                                                        <Dropdown.Item className="filter-widget-menu-item" style={{color: '#db862c'}} key={'option_' + subIdx} value={field}
                                                                onClick={(e) => setFilterWidgetField(field, idx)}>
                                                            {ReactHtmlParser(props.fieldConfig.getFieldName(field, algo, isAB))}
                                                        </Dropdown.Item>
                                                    ))}
                                                </div>
                                            )}
                                            {!props.displayRegFields && filterWidgetFieldsWOReg.map(({ group, fields }, key) => (
                                                <div key={'dropdown_' + key}>
                                                    <div className="filter-widget-group-item">{group}</div>
                                                    {fields.map((field, subIdx) => (
                                                        <Dropdown.Item className="filter-widget-menu-item" style={{color: '#db862c'}} key={'option_' + subIdx}
                                                                value={field} onClick={(e) => setFilterWidgetField(field, idx)}>
                                                            {ReactHtmlParser(props.fieldConfig.getFieldName(field, algo, isAB))}
                                                        </Dropdown.Item>
                                                    ))}
                                                </div>
                                            ))
                                            }
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </td>

                                
                                {/* operator */}
                                {getFieldId(idx) !== 'SUBJECT_FTS' &&
                                <>
                                <td className={"filter-widget-operator "+(getFieldId(idx) !== "SUBJECT_FTS"? "":"ftStrangeSpacer")}>
                                    <div className="control-spacer">
                                        <DiscoverSelectBox style={{ba: '#EFEFEF'}}
                                            margin="normal" variant="outlined"
                                            name="opSel" id="opSel" smallSelectBox={true}
                                            value={widgetState.widgetContent[idx].operator}
                                            onChange={(e) => {setFilterWidgetOperator(e, idx)}}
                                            items={operatorSelections[props.fieldConfig.fieldConfig[widgetState.widgetContent[idx].field].type]}
                                        />
                                    </div>
                                </td>
                                
                                {/* value */}
                                <td className="filter-widget-value">
                                    <div className="control-spacer">
                                        {/* string textarea */}
                                        {getFieldType(idx) === 'string' && getFieldId(idx) !== 'SUBJECT_PG' && getFieldId(idx) !== 'SUBJECT_PS' && getFieldId(idx) !== 'SUBJECT_FTS' &&
                                            <CustomTooltip arrow title="Query multiple items by putting each of them on a new line" placement="top">
                                                <textarea rows={widgetGetTextAreaNbRows(idx)} style={{width: '200px'}}
                                                          className={'filter-widget-input-textarea ' + (widgetAtomErrStatus[idx]?'input-with-error':'')}
                                                          placeholder="Text" onChange={(e) => {setFilterWidgetValue(e, idx)}}
                                                          value={widgetState.widgetContent[idx].value}></textarea>
                                            </CustomTooltip>
                                        }
                                        {/* SUBJECT_PG or SUBJECT_PS, dropdown choices */}
                                        {getFieldType(idx) === 'string' && (getFieldId(idx) === 'SUBJECT_PG' || getFieldId(idx) === 'SUBJECT_PS') &&
                                            <DiscoverSelectBox
                                                margin="normal" variant="outlined"
                                                name="stringSel" id="stringSel" midSelectBox={true}
                                                value={widgetState.widgetContent[idx].value}
                                                onChange={(e) => {setFilterWidgetValue(e, idx)}}
                                                items={pulldownSelections[getFieldId(idx)]}
                                            />
                                        }
                                        {/* RESULT_NFD or RESULT_FQ, dropdown choices */}
                                        {getFieldType(idx) === 'string_frame' && (getFieldId(idx) === 'RESULT_NFD' || getFieldId(idx) === 'RESULT_FQ') &&
                                            <DiscoverSelectBox
                                                margin="normal" variant="outlined"
                                                name="frameSel" id="frameSel" midSelectBox={true}
                                                value={widgetState.widgetContent[idx].value}
                                                onChange={(e) => {setFilterWidgetValue(e, idx)}}
                                                items={pulldownSelections[getFieldId(idx)]}
                                            />
                                        }
                                        {/* Date values */}
                                        {getFieldType(idx) === 'date' && widgetState.widgetContent[idx].operator !== 'BETWEEN' &&
                                            <div className={'clearfix'}>
                                                <DatePicker
                                                    customize={true}
                                                    margin="normal"
                                                    id="dateVal" name="dateVal"
                                                    dateFormat="MMMM-dd-yyyy"
                                                    label="Date picker inline"
                                                    value={new Date(widgetState.widgetContent[idx].value)}
                                                    inputVariant="outlined"
                                                    className={"patent-date-picker " + (widgetAtomErrStatus[idx]?'input-with-error':'')}
                                                    onChange={val => {widgetState.widgetContent[idx].value = val;setWidgetState({...widgetState});}}
                                                />
                                            </div>
                                        }
                                        {getFieldType(idx) === 'date' && widgetState.widgetContent[idx].operator === 'BETWEEN' &&
                                            <div className="clearfix">
                                                <div className={'pull-left width-40'}>
                                                    <DatePicker
                                                        customizedSmall={true}
                                                        margin="normal"
                                                        id="dateVal"
                                                        name="dateVal"
                                                        dateFormat="MMM-dd-yyyy"
                                                        label="Date picker inline"
                                                        value={new Date(widgetState.widgetContent[idx].value)}
                                                        inputVariant="outlined"
                                                        className={"small-patent-date-picker " + (widgetAtomErrStatus[idx]?'input-with-error':'')}
                                                        onChange={val => {widgetState.widgetContent[idx].value = val;setWidgetState({...widgetState});}}
                                                    />
                                                </div>
                                                <div className={'pull-left width-20'}>
                                                    &nbsp;and&nbsp;
                                                </div>
                                                <div className={'pull-left width-40'}>
                                                    <DatePicker
                                                        customizedSmall={true}
                                                        margin="normal"
                                                        id="dateVal"
                                                        name="dateVal"
                                                        dateFormat="MMM-dd-yyyy"
                                                        label="Date picker inline"
                                                        value={new Date(widgetState.widgetContent[idx].second_value)}
                                                        inputVariant="outlined"
                                                        className={"small-patent-date-picker " + (widgetAtomErrStatus[idx]?'input-with-error':'')}
                                                        onChange={val => {widgetState.widgetContent[idx].second_value = val;setWidgetState({...widgetState});}}
                                                    />
                                                </div>
                                            </div>
                                        }
                                        {/* Integer values */}
                                        {getFieldType(idx) === 'int' && widgetState.widgetContent[idx].operator !== 'BETWEEN' &&
                                            <div>
                                                <TextInput fullWidth={false} error={widgetAtomErrStatus[idx]}
                                                    id="intVal" name="intVal" placeholder="Number"
                                                    variant="outlined" type="number"
                                                    InputProps={{ inputProps: { min: 0 }, className: classes.input }}
                                                    className={'filter-widget-input'}
                                                    value={widgetState.widgetContent[idx].value}
                                                    onKeyDown={UtilsService.restrictCharacter}
                                                    onChange={(e) => setFilterWidgetValue(e, idx)}
                                                />
                                            </div>
                                        }
                                        {getFieldType(idx) === 'int' && widgetState.widgetContent[idx].operator === 'BETWEEN' &&
                                            <div>
                                                <div className={'pull-left width-40'}>
                                                    <TextInput fullWidth={false} placeholder="Number"
                                                               id="intVal1" name="intVal1"
                                                               variant="outlined" type="number" error={widgetAtomErrStatus[idx]}
                                                               InputProps={{ inputProps: { min: 0 }, className: classes.input }}
                                                               className={'filter-widget-input'}
                                                               value={widgetState.widgetContent[idx].value}
                                                               onKeyDown={UtilsService.restrictCharacter}
                                                               onChange={(e) => setFilterWidgetValue(e, idx)}
                                                    />
                                                </div>
                                                <div className={'pull-left width-20'}>
                                                    &nbsp;and&nbsp;
                                                </div>
                                                <div className={'pull-left width-40'}>
                                                    <TextInput fullWidth={false} placeholder="Number"
                                                               id="intVal2" name="intVal2"
                                                               variant="outlined" type="number" error={widgetAtomErrStatus[idx]}
                                                               InputProps={{ inputProps: { min: 0 }, className: classes.input }}
                                                               className={'filter-widget-input'}
                                                               value={widgetState.widgetContent[idx].second_value}
                                                               onKeyDown={UtilsService.restrictCharacter}
                                                               onChange={(e) => setFilterWidgetSecondValue(e, idx)}
                                                    />
                                                </div>
                                            </div>
                                        }
                                        {/* Float values */}
                                        {/* Percentage (float) values */}
                                        {(getFieldType(idx) === 'float' || getFieldType(idx) === 'percentage')
                                            && widgetState.widgetContent[idx].operator !== 'BETWEEN' &&
                                            <div>
                                                <TextInput fullWidth={false} style={{width: '200px'}}
                                                           id="floatVal" name="floatVal" placeholder="Number" type="number"
                                                           variant="outlined" InputProps={{ inputProps: { min: 0 }, className: classes.input }}
                                                           className={'filter-widget-input'}
                                                           value={widgetState.widgetContent[idx].value} error={widgetAtomErrStatus[idx]}
                                                           onChange={(e) => setFilterWidgetValue(e, idx)}
                                                />
                                            </div>
                                        }
                                        {(getFieldType(idx) === 'float' || getFieldType(idx) === 'percentage')
                                            && widgetState.widgetContent[idx].operator === 'BETWEEN' &&
                                            <div>
                                                <div className={'pull-left width-40'}>
                                                    <TextInput fullWidth={false} placeholder="Number" type="number"
                                                               id="floatVal1" name="floatVal1" error={widgetAtomErrStatus[idx]}
                                                               variant="outlined" InputProps={{ inputProps: { min: 0 }, className: classes.input }}
                                                               className={'filter-widget-input'}
                                                               value={widgetState.widgetContent[idx].value}
                                                               onChange={(e) => setFilterWidgetValue(e, idx)}
                                                    />
                                                </div>
                                                <div className={'pull-left width-20'}>
                                                    &nbsp;and&nbsp;
                                                </div>
                                                <div className={'pull-left width-40'}>
                                                    <TextInput fullWidth={false} placeholder="Number" type="number"
                                                               id="floatVal2" name="floatVal2" error={widgetAtomErrStatus[idx]}
                                                               variant="outlined" InputProps={{ inputProps: { min: 0 }, className: classes.input }}
                                                               className={'filter-widget-input'}
                                                               value={widgetState.widgetContent[idx].second_value}
                                                               onChange={(e) => setFilterWidgetSecondValue(e, idx)}
                                                    />
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </td>
                                </>
                                }
                                {getFieldId(idx) == 'SUBJECT_FTS' &&
                                <td width={"90%"} 
                                colSpan="2">
                                    <FullTextSearchBox
                                    	setSearchLock={(bool) =>{}}
                                        starterString={deepCopy(widgetState.widgetContent[idx].fieldDisplayValue)}
                                        setLuceneQueryValue={(luceneObj)=>{return widgetState.widgetContent[idx].value = luceneObj}}
                                        endString={(endString) =>{setFTString(endString, idx)}}
                                        keyWordsInContext = {(retRay) =>{return widgetState.widgetContent[idx].keyWords = retRay}}
                                        setNodeId = {(stringholder)=>{return("")}}

                                    >
                                    </FullTextSearchBox>
                                </td>
                                }

                                {/* plus / minus buttons */}
                                <td className={"filter-widget-adddel "}>
                                {Object.keys(widgetState.widgetContent).length > 1 &&
                                    <CustomTooltip arrow title="Remove this Condition" placement="bottom">
                                        <div className="control-spacer plus-min-button custom-filter-label"
                                             onClick={() => widgetDelAtom(idx)}>
                                            <FaMinus/>
                                        </div>
                                    </CustomTooltip>
                                }
                                </td>
                                <td className={"filter-widget-adddel "}>
                                    <CustomTooltip arrow title="Add Condition below" placement="bottom">
                                        <div className="control-spacer plus-min-button custom-filter-label"
                                             onClick={() => widgetAddAtom(idx)}>
                                            <FaPlus/>
                                        </div>
                                    </CustomTooltip>
                                </td>
                            </tr>
                        ))
                    }


                    {/* buttons */}
                    <tr>
                        <td colSpan="6" className="clearfix">
                            <div className="clearfix filter-widget-bottom">
                                <button className="pull-left normal-button grey" style={{margin: '0px'}}
                                        onClick={clearFilterWidget}>Clear All
                                </button>
                                <CustomTooltip arrow title={filterNameErr} placement="top">
                                <button className={'pull-right normal-button ' + (filterNameUsed === -1 ? 'gqblue' : 'gqblue-alert')}
                                        onClick={save}>
                                    {(filterNameUsed===-1 ? 'Save as New ' : 'Overwrite Existing My')} Filter
                                </button>
                                </CustomTooltip>
                                <button className="pull-right normal-button grey" onClick={props.closeModal}>Cancel</button>
                            </div>
                        </td>
                    </tr>

                    </tbody>
                </table>
            </div>
            </Modal.Body>
        </Modal>
    );
}

export default DiscoverFilterModal;
