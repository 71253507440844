import React, { useState, useEffect } from "react";
import {useHistory, Link} from "react-router-dom";
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import CheckBox from '../../shared/Fields/CheckBox';
import fullTextService from "../../services/fulltextsearch";
import { useRef } from 'react';
import { useFormik } from 'formik';
import CloseIcon from '@material-ui/icons/Close';
import Button from "@material-ui/core/Button";
import CaretPositioning from "../../services/EditCaretPositioning"
import Tooltip from "@material-ui/core/Tooltip";
import OntoCompletePopup from "./OntoCompletePopup";
import { read, utils, writeFile } from 'xlsx';
import _ from "lodash";
import { toast } from "react-toastify";




function Ontologies(){

    const { t, i18n } = useTranslation("common");
	const history = useHistory();

    const userInfo = useSelector((state) => state.setUserInfo);
    const synonymEditor = React.createRef();
    const createLabelRef = React.createRef();
    
    const leftRef = useRef(null);

    const [dragging, setDragging] = useState(false);
    const [leftWidth, setLeftWidth] = useState(null)
    //const rightRef = useRef(null);

    const [editMode, setEditMode] = useState(false);
    const [searchTerms, setSearchTerms] = useState([]);
    const [activeIndex, setActiveIndex] = useState(-1);

    const [doneLoading, setDoneLoading] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState("Loading...")

    const [termHasSynonyms, setTermHasSynonyms] = useState(false)

    const termAtom = {
        "id": "Unsaved Term",
        "taxonomy": "USER",
        "term": "New Term",
        "synonyms": [],
        "description": null,
        "parents": null,
        "supernodes": null,
        "userId": null,
        "userName": "",
        "isSelected": false
    };

    const [anySelected, setAnySelected] = useState(false);
    const [deleteAllPopup, setDeleteAllPopup] = useState(false);
    const [deleteSelectedPopup, setDeleteSelectedPopup] = useState(false);
    const [importPopup, setImportPopup] = useState(false);
    const [exportPopup, setExportPopup] = useState(false);

    const [createTermNameError, setCreateTermNameError] = useState(false);
    const [searchTermEmptyError, setSearchTermEmptyError] = useState(false);

    const [leftPosition, setLeftPosition] = useState(0)
    const [topPosition, setTopPosition] = useState(0)
    const [ontoCompletePopup, setOntoCompletePopup] = useState(false);
    const [pressedEscape, setPressedEscape] = useState(false);
    const [searchedTerm, setSearchedTerm] = useState("");
    const [hoveredTerm, setHoveredTerm] = useState(-1);
    const [insertOntoNode, setInsertOntoNode] = useState(null);

    const [synonymItems, setSynonymItems] = useState([]);

    const [uploadNotImportError, setUploadNotImportError] = useState(false);
    const [noExportNameError, setNoExportNameError] = useState(true);


	
	useEffect(async() => {

        if(userInfo !== undefined && userInfo !== null){
            document.title = "User created Ontologies";
            getUsersTerms();
        }

	},[userInfo]);

    function detectTab(event){   
        if(event.keyCode === 9){
			event.preventDefault();
            let listLength = synonymItems.length
            if(hoveredTerm < listLength){
                setHoveredTerm(hoveredTerm+1)
            }else{
                setHoveredTerm(0);
            }
        }

    }

    function detectEnter(event){
        if(event.keyCode === 13){
            setPressedEscape(false);
            if(ontoCompletePopup && hoveredTerm !==-1){
                if(hoveredTerm!=-1){
                    event.preventDefault();
                    insertOntoComplete();
                }
            }
        }
    }

    function detectEscape(event){
        if(event.key === 'Escape'){
            setOntoCompletePopup(false);
            setPressedEscape(true);
            setHoveredTerm(-1);
        }
    }

    function closeOntocomplete(){
        setOntoCompletePopup(false);
    }



    function handlePaste(e){
        e.preventDefault();
        //var info = e.clipboardData.getData('text/plain')
    }

    async function getUsersTerms(){
		
		let searchParam = `&rows=${"50"}&json_query=${"*"}&ontologies=${"USER"}&user_id=${userInfo.current_user.gq_user_id}`;
		const searchRes = await fullTextService.getFullTextSearchTerm(
		 	history,
		 	searchParam
		);

        if(searchRes != undefined){
            if(searchRes.response_content){
                if(searchRes.response_content.results && searchRes.response_content.results.length > 0){

                    let terms = searchRes.response_content.results;

                    for(let i = 0; i<terms.length; i++){
                        terms[i].isSelected = false;
                    }

                    setSearchTerms(terms)
                    setDoneLoading(true);
                    return
                }
            }
        }
        //return above makes this else{}
        setLoadingMessage("Add New Terms")
        console.log("no results "+ JSON.stringify(searchRes.response_content))

    }

    function resizeTable(e){
        if(e.clientX > 0){
            let newWidth = e.clientX - leftRef.current.offsetLeft
            if(newWidth < 150){
                newWidth = 150;
            }
            if(newWidth > 500){
                newWidth = 500;
            }
            console.log(newWidth)
            setLeftWidth(newWidth)
        }
    }

    function selectTermForEdit(index){

        synonymEditor.current.innerHTML = formatEditorText([""]);
        setActiveIndex(index)

        if(searchTerms[index].editedSynonyms != undefined){ //if the term has been edited
            setEditMode(true);

            if(searchTerms[index].editedSynonyms.length > 0){
                setTermHasSynonyms(true);
                synonymEditor.current.innerHTML = formatEditorText(searchTerms[index].editedSynonyms)
            }
        }else{ //original term
            
            if(searchTerms[index].synonyms.length > 0){
                setEditMode(false);
                setTermHasSynonyms(true);
                synonymEditor.current.innerHTML = formatEditorText(searchTerms[index].synonyms)
            }else{
                setEditMode(true);
                setTermHasSynonyms(false);
            }
        }
    }

    let caretLocation = Number.NaN;
    function handleInput(event){
        
		caretLocation = CaretPositioning.saveSelection(event.currentTarget).start;
        if(event.nativeEvent.inputType == "insertParagraph"){
            caretLocation+=1;
        }
        
        let wordRay = []
        if(synonymEditor.current){
            let children = synonymEditor.current.children;

            for(let i=0; i< children.length; i++){
                if(children[i].localName ==="p"){
                    wordRay[i] = children[i].textContent;
                }
            }
        }

        if(wordRay.length>0 && JSON.stringify(wordRay) !== JSON.stringify([""])){
            setTermHasSynonyms(true);

            if(JSON.stringify(searchTerms[activeIndex].synonyms) !== JSON.stringify(wordRay)){
                searchTerms[activeIndex].editedSynonyms = wordRay;
            }else{
                searchTerms[activeIndex].editedSynonyms = undefined;

            }

        }else{
            searchTerms[activeIndex].editedSynonyms = undefined;
            setTermHasSynonyms(false);
        }

        setSearchTerms([...searchTerms])
        
        let formattedList = formatEditorText(wordRay);
        synonymEditor.current.innerHTML = formattedList

        CaretPositioning.setCurrentCursorPosition(synonymEditor, caretLocation);
    }

    function formatEditorText(wordRay){
        
        let formattedList = ""

        if(wordRay.length == 0){
            formattedList = 
            '<p class="appTextColor synonymEditorTerm" key="0">'+
            ""
            +'</p>'
        }else{

            for(let i=0; i<wordRay.length; i++){

                let newString = wordRay[i].replace(/[\[\]\<\>\"\"\n]/ig, '')
                
                formattedList += 
                    '<p class="appTextColor synonymEditorTerm" key="'+i+'">'+
                        newString
                    +'</p>'
                
                if(i< wordRay.length){
                    formattedList+='\n';
                }
            }
        }

        return formattedList
    }

    async function getOntoSuggestions(){

        let selection = window.getSelection();

        let focusedNode = selection.focusNode;

        movePopup(focusedNode)
        setOntoCompletePopup(false)

        let searchTerm = focusedNode.textContent;

        if(userInfo != undefined && userInfo != null && editMode){            
            if(fullTextService.levenshteinDistance(searchTerm, searchedTerm) > 2){

                let userId = userInfo && userInfo.current_user.gq_user_id;
                let searchParam = `&json_query=${encodeURIComponent(searchTerm)}&rows=25&ontologies=CHEBI;DOID;GO;HP;IMR;MOD;MSH;NCBI;PO;PR;TO&user_id=${userId}`;
                
                const searchRes = await fullTextService.getFullTextSearchTerm(
                    history,
                    searchParam
                );

                //const searchRes = Test.test2;
                if(searchRes.response_content){
                    if(searchRes.response_content.results){
                        if (searchRes.response_content.results.length > 0) {
                            if(searchRes.response_content.results){

                            let synonymList =[];
                            let counter = 0;
                            let results = searchRes.response_content.results;
                            
                            for(let i=0; i< results.length; i++){

                                if(results[i].term != undefined){
                                    synonymList[counter] = results[i].term;
                                    counter++;
                                }

                                if(results[i].synonyms !=undefined){
                                    if(results[i].synonyms.length>0){

                                        for(let k =0; k<=results[i].synonyms.length; k++){
                                            if(results[i].synonyms[k] !="" && results[i].synonyms[k] != undefined){
                                                synonymList[counter]= results[i].synonyms[k]
                                                counter++;
                                            }
                                        }

                                    }
                                }
                            }

                            setSearchedTerm(searchTerm)
                            console.log("Searching "+ searchTerm) 

                            setSynonymItems(synonymList);
                            setHoveredTerm(-1);
                            setOntoCompletePopup(true)
                                
                            }
                        }
                    }	
                }
            }
        }
    }

    function movePopup(eventTarget){

		let focusedElement = eventTarget;
        let ontoSpacer = document.getElementById("ontoPopupSpacer")
        setInsertOntoNode(focusedElement.parentElement);
        
        if(focusedElement != undefined && ontoSpacer != undefined){
            let focusedElementBoundes = focusedElement.parentElement.getBoundingClientRect();
            let ontoPsacerBounds = ontoSpacer.getBoundingClientRect();
            setLeftPosition(focusedElementBoundes.right+5 - ontoPsacerBounds.right);
            setTopPosition(focusedElementBoundes.top+10 - ontoPsacerBounds.top);
        }
        
	}

    function createNew(){

        setActiveIndex(-1);
        setEditMode(false);
        synonymEditor.current.innerHTML = ""
        let terms  = searchTerms;
        terms.unshift(termAtom);

        setSearchTerms([...terms]);
    }

    function fixLocation(offset){
        let element = document.getElementById("synonymEditor");
       
        if(element){
            let height = element.clientHeight/2
            let total = height + offset;
            return -total+"px"
        }
    }

    function insertOntoComplete(){
		
        insertOntoNode.textContent = synonymItems[hoveredTerm];

        let wordRay = []
        if(synonymEditor.current){
            let children = synonymEditor.current.children;

            for(let i=0; i< children.length; i++){
                if(children[i].localName ==="p"){
                    wordRay[i] = children[i].textContent;
                }
            }
        }

        if(wordRay.length>0 && JSON.stringify(wordRay) !== JSON.stringify([""])){
            setTermHasSynonyms(true);

            if(JSON.stringify(searchTerms[activeIndex].synonyms) !== JSON.stringify(wordRay)){
                searchTerms[activeIndex].editedSynonyms = wordRay;
            }else{
                searchTerms[activeIndex].editedSynonyms = undefined;

            }

        }else{
            searchTerms[activeIndex].editedSynonyms = undefined;
            setTermHasSynonyms(false);
        }

        let formattedList = formatEditorText(wordRay);
        synonymEditor.current.innerHTML = formattedList
        CaretPositioning.setCurrentCursorPosition(synonymEditor, caretLocation);
        
        setOntoCompletePopup(false)

    }

    async function saveSearchTerm(){

        if(activeIndex !=-1){
        if(searchTerms[activeIndex].editedSynonyms !== undefined){

            let userId = userInfo.current_user.gq_user_id;
            let wordRay = searchTerms[activeIndex].editedSynonyms;

            let synonymString = "";
            if(wordRay.length > 0 && userId !== null && userId !== undefined){

                wordRay = wordRay.filter((word) => word != "");

                for(let i=0; i<wordRay.length; i++){
                    if(wordRay[i]!== ""){
                        synonymString+=wordRay[i];
                        if(i<wordRay.length-1){
                            synonymString+="\n";
                        }
                    }
                }

                if(synonymString!==""){

                    let postData={
                        user_id: userId,
                        word_list: synonymString
                    }

                    if(searchTerms[activeIndex].id !== "Unsaved Term"){
                        postData.term_id = searchTerms[activeIndex].id;
                    }

                    //synonymEditor.current.innerHTML = ""
                    let response = await fullTextService.updateACSynonyms(postData, history);
                    
                    if(response.response_content!== undefined){
                        searchTerms[activeIndex] = response.response_content
                        setSearchTerms([...searchTerms]);
                    }

                }
            }

            setOntoCompletePopup(false);
            setSearchedTerm("");
        }else{
            toast.error("Cannot Save Empty List")
            return
        }
    
    }
        cancelEdit();
        
    }

    async function deleteTerms(all){

        let postData ={
            ids: []
        }
       
        let terms = searchTerms
        for(let i=0; i<terms.length; i++){
            if(all || terms[i].isSelected == true){
                terms[i].isSelected = false
                postData.ids.push(terms[i].id);
                terms.splice(i, 1);
                if(i == activeIndex){
                    setActiveIndex(-1);
                    let formattedList = formatEditorText([]);
                    synonymEditor.current.innerHTML = formattedList
                }
                i=i-1;
            }
        }

        setAnySelected(false);
        setSearchTerms([...terms])
        await fullTextService.deleteSynonyms(postData, history);
    }

    function checkTerm(index){

        let terms = searchTerms;

        terms[index].isSelected = (!terms[index].isSelected);
        setAnySelected(ifTermsSelected());
        setSearchTerms([...terms]);
    }

    function ifTermsSelected(){
        if(Array.isArray(searchTerms)){
            for(let i = 0; i < searchTerms.length; i++){
                if(searchTerms[i].isSelected){
                    return true;
                }
            }
        }
        return false;
    } 
    
    function widthFunction(){
        if(leftWidth == null){
            return "fit-content"
        }else{
            return leftWidth+"px"
        }
    }

    function cancelEdit(){
        
        if(activeIndex!== -1){
            if(searchTerms[activeIndex].editedSynonyms !== undefined){
                searchTerms[activeIndex].editedSynonyms = undefined
                setSearchTerms([...searchTerms]);
            }
        }
        setOntoCompletePopup(false)
        setHoveredTerm(-1);
        setSearchedTerm("")
        setInsertOntoNode(null)
        setEditMode(false)

        synonymEditor.current.innerHTML = formatEditorText(searchTerms[activeIndex].synonyms)
    }
    

    const [fileName, setFileName] = useState(null);
    function exportFile(){
        let jsonObj = [];

        for(let i = 0; i<searchTerms.length; i++){
            if(searchTerms[i].isSelected){
                let itemObj = {
                    "Search Terms": searchTerms[i].term
                }

                for( let k = 1; k< searchTerms[i].synonyms.length; k++){
                  
                    let key = "Synonym "+k;
                    itemObj[key] = searchTerms[i].synonyms[k];
                }
                jsonObj.push(itemObj);
            }
        }

        /* generate worksheet from state */
        const ws = utils.json_to_sheet(jsonObj);
        /* create workbook and append worksheet */
        const wb = utils.book_new();
        utils.book_append_sheet(wb, ws, "Data");
        /* export to XLSX */
        writeFile(wb, fileName+".xlsx");
        setFileName(null)
    }

    const [file, setFile] = useState(null);
    const [fileJson, setFileJson] = useState(null);

    const ImportFile = () => {
        if (fileJson) {
            for(let i = 1; i < fileJson.length; i++){

                let newTerm = _.cloneDeep(termAtom);

                newTerm["term"] = fileJson[i][0];
                newTerm["editedSynonyms"] = fileJson[i];

                searchTerms.push(newTerm);
            }
            setSearchTerms([...searchTerms])
        }
    };


    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onload = (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = read(data, { type: 'array' });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            const jsonData = utils.sheet_to_json(sheet, { header: 1 });

            if(Array.isArray(jsonData)){
                let headers = jsonData[0];
                if(Array.isArray(headers)){ //check header row to see if its an export

                    if(headers[0] !== "Search Terms"){
                        setUploadNotImportError(true)
                        setFileJson(null);
                        return
                    }

                    for(let i = 1; i<headers.length; i++){
                        let compareString = "Synonym "+i;
                        if(headers[i] !== compareString){
                            setUploadNotImportError(true)
                            setFileJson(null);
                            return
                        }
                    }
                }else{
                    setUploadNotImportError(true)
                    setFileJson(null);
                    return
                }

                for(let i = 1; i<jsonData.length;i++){
                    for(let k = 0; k < jsonData[i].length; k++){
                        let test = jsonData[i][k];
                        if(test == undefined){
                            setUploadNotImportError(true)
                            setFileJson(null);
                            return
                        }
                    }
                }

            }

            setUploadNotImportError(false)
            setFileJson([...jsonData]);
        };

        if(file != null){
            reader.readAsArrayBuffer(file);
        }else{
            setFileJson(null);
            return
        }
    };

    const dateAsString = () =>{

        const currentDate = new Date();

        let year = currentDate.getFullYear();
        let month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Month is zero-indexed
        let day = String(currentDate.getDate()).padStart(2, '0');
        const dateString = `${year}-${month}-${day}`;

        return dateString
    }

    function handleFileName(value){
        if(value !=undefined && value !== null ){
            if (typeof value === 'string'){
                if(value !== "" && value.length >0){
                    setFileName(value)
                    setNoExportNameError(false)
                    return
                }
            }
        }
        setFileName(null)
        setNoExportNameError(true);
    }

	function goToFullText(){
		history.push('/fulltextsearch');

	}

	return (
		<div className="fullWidthCenterAlign" onKeyDown={(e)=>{detectTab(e); detectEnter(e); detectEscape(e)}}>
            <div className="CenterOntologies">
                <div className="ontologyBar">
                        <span className="HistoryTopper" onClick={()=>createNew()}>Create New Term</span>
                        <span className={anySelected === true? "HistoryTopper":"disabledHistory disableFixer"} onClick={()=>setDeleteSelectedPopup(true)}>Delete Selected</span>
                        <span className="HistoryTopper" onClick={()=>setDeleteAllPopup(true)}>Delete All</span>
                        <span className={anySelected === true? "HistoryTopper":"disabledHistory disableFixer"} onClick={()=>{setExportPopup(true)}}>Export Selected</span>
                        <span className="HistoryTopper" onClick={()=>{setImportPopup(true)}}>Import</span>
                        <span className="HistoryTopper" onClick={()=>goToFullText()}>GQ Full Text</span>

                </div>
                <div>
                    <div className="ontologyTable">

                        <div style={{width: widthFunction()}}  className="ontologyLeftFormat OntologyBoxBorder" ref={leftRef}>
                            <div className="borderGrey leftTableInnerContainer">
                       
                            {searchTerms.map((value, index) => {
                            return (
                                <div className="ontologyItem borderBlue" style={{height:"50px"}} key={index+"ont"}>
                                    <span style={{display: 'flex', paddingLeft: "3px", paddingRight: '2px' }}>
                                        <CheckBox checked={searchTerms[index].isSelected} style={{position:"absolute", marginRight: "-20px", backgroundColor: "white !important"}} onChange={()=>{checkTerm(index)}}></CheckBox>
                                    </span>
                                    <div className={index == activeIndex? "activeOntology": "notActiveOntology"} onClick={()=>{selectTermForEdit(index)}}>
                                        <div className="Ontologyformat">                                            
                                            {value.editedSynonyms === undefined &&
                                            <>
                                                <strong className={index == activeIndex? "appTextFont activeOntology": "appTextColor appTextFont notActiveOntology"}>{value.term}</strong>
                                                <span className={index == activeIndex? "appTextFont activeOntology": " appTextColor appTextFont notActiveOntology"}>ID: {value.id.replace("USER:", "")}</span>
                                            </>
                                            }
                                            {value.editedSynonyms !== undefined &&
                                            <>
                                                <strong className={index == activeIndex? "appTextFont activeOntology": "appTextColor appTextFont notActiveOntology"}>{value.editedSynonyms[0]}</strong>
                                                <span className={index == activeIndex? "appTextFont activeOntology": " appTextColor appTextFont notActiveOntology"}>ID: Not Saved</span>
                                            </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                        
                        {searchTerms.length == 0 && !doneLoading &&
                        <div
                         className="loadingContainer"
                        >
                           <span 
                            style={{padding: "10px"}}
                            className="appTextColor"
                           >
                            {loadingMessage}
                           </span>
                        </div>
                        }
                        </div>
                        </div>

                        <div className={dragging? "dragBoxDragging" :"dragBox"} onDrag={(e)=>{resizeTable(e)}}
                            onDragStart={()=>setDragging(true)} 
                            onDragEnd={()=>setDragging(false)}
                        >
                        </div>

                        <div className="OntologyBoxBorder" style={{width: "100%"}} >
                            <div id="ontoPopupSpacer" style={{width: 0, height: 0, position: "relative"}}>
                                <div
                                    id="ontoPopup"
                                    className={(topPosition>=0 && leftPosition>=0 && synonymItems.length != 0 && ontoCompletePopup && !pressedEscape && editMode && activeIndex!==-1)? "ontoCompleteContainer": "ftDisplayNone"}
                                    style={{ top: topPosition, left: leftPosition }}
                                    // hidden={synonymItems.length == 0}
                                >
                                    <div className="tinyBlueLeftArrow"></div>
                                    <div className={"ontoPopupBackground"}>
                                        <OntoCompletePopup
                                            closeSelf = {()=>{closeOntocomplete()}}
                                            hoveredTerm ={hoveredTerm}
                                            setHoveredTerm={(i)=> setHoveredTerm(i)}
                                            synonymItems = {synonymItems}
                                            selectOntocomplete = {()=>insertOntoComplete()}

                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="borderBlue ontologyTextArea">
                                <div style={{
                                    position: "relative",
                                    width: "100%",
                                    height: "100%",
                                    outline: "none",
                                    zIndex: "200"
                                }}
                                id="synonymEditor"
                                ref={synonymEditor}
                                contentEditable={ activeIndex!= -1 && editMode}
                                onInput={(e)=>{handleInput(e); getOntoSuggestions();}}
                                onClick={()=>{getOntoSuggestions(); setPressedEscape(false)}}
                                onDrop={(e)=>{e.preventDefault()}}>

                                </div>
                                {activeIndex !== -1 && !termHasSynonyms &&
                                    <div className="centerEditortext"
                                        style={{marginTop: fixLocation(15)}}

                                    >
                                        <span className="font18pt appTextColor">Add Terms Here</span>
                                    </div>
                                }
                                {activeIndex == -1 &&
                                    <div className="centerEditortext"
                                        style={{marginTop: fixLocation(15)}}
                                    >
                                        <span className="font18pt appTextColor">Select a search term from the left</span>
                                    </div>
                                }
                                
                            </div>
                            <div className="ftOptionsButtons">
                                {!editMode && activeIndex == -1 &&
                                <div width={'100%'}>
                                    <button className={"submitButtonClassDisabled"} onClick={()=>{}}>Edit</button>
                                </div>
                                }
                                {!editMode && activeIndex !== -1 &&
                                <div width={'100%'}>
                                    <button className={"submitButtonClass"} onClick={()=>{setEditMode(true)}}>Edit</button>
                                </div>
                                }
                                {editMode &&
                                <div>
                                    <button className={"submitButtonClass"} onClick={()=>{cancelEdit()}}>Cancel</button>
                                    <button className={"ml-1 submitButtonClass"} onClick={()=>saveSearchTerm()}>Save</button>
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>

            {deleteAllPopup &&
			    <div>
                    <div className="popupBackdrop" onClick={()=>setDeleteAllPopup(false)}> 
                </div>
                <div className="fixedFullScreen">
                    <div className="OntologyPopup">
                        <div className="cancelX">
                            <Link href="#" className={"p-2 appTextColor"}><CloseIcon onClick={()=>setDeleteAllPopup(false)} /></Link>
                        </div>
                        <div className="ABSavejumbotron">
                            <br></br>
                                <span style={{marginLeft: "5%"}} className="center historyPopupHeader tableCaption">Delete <string>ALL</string> created search terms?</span>
                            <br></br>
                        
                            <div className="buttonBuffer" style={{marginRight: "5%", paddingTop: "45px"}}>
                                <Button className={"cancelButtonClass "} onClick={()=>setDeleteAllPopup(false)}>Cancel</Button>
                                <span className="ml-1"></span>
                                <Button className={"submitButtonClass"} onClick={()=>{deleteTerms(true); setDeleteAllPopup(false)}}>Delete</Button>
                            </div>
                        </div>
                    </div>
                </div>
             </div>		
			}
 
            {deleteSelectedPopup && 
            <div>
                <div className="popupBackdrop" onClick={()=>setDeleteSelectedPopup(false)}> 
                </div>
                <div className="fixedFullScreen">
                    <div className="OntologyPopup">
                        <div className="cancelX">
                            <Link href="#" className={"p-2 appTextColor"}><CloseIcon onClick={()=>setDeleteSelectedPopup(false)} /></Link>
                        </div>
                        <div className="ABSavejumbotron">
                            <br></br>
                                <span style={{marginLeft: "5%"}} className="center historyPopupHeader tableCaption">Delete <string>SELECTED</string> search terms?</span>
                            <br></br>
                        
                            <div className="buttonBuffer" style={{marginRight: "5%", paddingTop: "45px"}}>
                                <Button className={"cancelButtonClass "} onClick={()=>setDeleteSelectedPopup(false)}>Cancel</Button>
                                <span className="ml-1"></span>
                                <Button className={"submitButtonClass"} onClick={()=>{deleteTerms(false); setDeleteSelectedPopup(false)} }>Delete</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            }  

        {importPopup && 
            <div>
                <div className="popupBackdrop" onClick={()=>setImportPopup(false)}> 
                </div>
                <div className="fixedFullScreen">
                    <div className="OntologyPopup">
                        <div className="cancelX">
                            <Link href="#" className={"p-2 appTextColor"}><CloseIcon onClick={()=>setImportPopup(false)} /></Link>
                        </div>
                        <div className="ABSavejumbotron">
                            <div className="importdiv">
                                <input
                                    type="file"
                                    accept=".xls,.xlsx"
                                    onChange={handleFileUpload}
                                />
                                {uploadNotImportError &&
                                <p className="importError">
                                    This file is not in GQ format
                                </p>
                                }
                            </div>
                        
                            <div className="buttonBuffer" style={{marginRight: "5%"}}>
                                <Button className={"cancelButtonClass "} onClick={()=>setImportPopup(false)}>Cancel</Button>
                                <span className="ml-1"></span>
                                <Button className={(uploadNotImportError || fileJson == undefined || fileJson == null)? "disableButtonClass":"submitButtonClass"} disabled={uploadNotImportError || fileJson == undefined || fileJson == null} onClick={()=>{ImportFile(); setImportPopup(false)} }>Import</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            }  
            {exportPopup && 
            <div>
                <div className="popupBackdrop" onClick={()=>setExportPopup(false)}> 
                </div>
                <div className="fixedFullScreen">
                    <div className="OntologyPopup">
                        <div className="cancelX">
                            <Link href="#" className={"p-2 appTextColor"}><CloseIcon onClick={()=>setExportPopup(false)} /></Link>
                        </div>
                        <div className="ABSavejumbotron">
                            <div className="exportDiv">
                                <p className="historyPopupHeader" style={{marginLeft:"0px", marginBottom:"5px"}}>Please provide file name:</p>
                                <input
                                    placeholder={"Name"}
                                    style={{width: "100%"}}
                                    type="text"
                                    onChange={(e) => handleFileName(e.target.value)}
                                />
                                {noExportNameError &&
                                <p className="importError">
                                    Please provide Name
                                </p>
                                }
                            </div>
                        
                            <div className="buttonBuffer" style={{marginRight: "5%", paddingTop: "35px"}}>
                                <Button className={"cancelButtonClass "} onClick={()=>setExportPopup(false)}>Cancel</Button>
                                <span className="ml-1"></span>
                                <Button className={noExportNameError? "disableButtonClass":"submitButtonClass"} onClick={()=>{if(fileName!== "" && fileName !== null && fileName !== undefined){exportFile(); setExportPopup(false)}}}>Export</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            }  
        </div>
        </div>
	);
}

export default Ontologies;
