import React, { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';
import Link from '@material-ui/core/Link';
import {FaStar} from "react-icons/fa";

const useStyles = makeStyles((theme) => ({
	modalClassConDSI: {
		padding: '35px 15px 0px',
	},
	modalText: {
		position: 'absolute',
		top: '10px',
		right: '10px'
	},
}));

const DiscoverAnnotationModal = props => {
	const classes = useStyles();
	const [value, setValue] = useState(props.value);
	const [selection, setSelection] = useState('single');
	const [annotated, setAnnotated] = useState(false);

	const callback = () => {
		props.actionModal(props.type, selection, !annotated, props.dbkey, value, props.annotState,
			props.filter, props.fieldConfig, props.fromItemsPage, props.groupValue1, props.groupValue2, props.parentAnnotState);
	}

	const clearall = () => {
		if(value != null){
			setValue('');
		}
	}

	const close = () => {
		props.closeModal();
	}

	const changeSelection = (e) => {
		setSelection(e.target.value);
	}

	const updateCheckbox = () => {
		setAnnotated(!annotated);
	}

	return (
		<Modal
			size="lg"
			aria-labelledby="contained-modal-title-vcente"
			centered
			show={props.showModal}
			contentClassName={classes.modalClassConDSI}
		>
			<Link href="#" onClick={(e) => e.preventDefault()} className={classes.modalText}>
				<CloseIcon onClick={close} />
			</Link>
			<div className="" style={{padding: '10px 10px 20px 10px'}}>
			{props.type === 'checkbox' &&
				<div>
					<label>Apply Checkbox:&nbsp;&nbsp;</label>
					<input type="checkbox" defaultChecked={value <= 0}
						   style={{verticalAlign: 'middle'}}
						   onChange={(e) => setValue(e.target.checked ? 0 : 1)}/>
				</div>
			}
			{props.type === 'star' &&
				<div className="stars-div">
					<label>Apply Stars:&nbsp;&nbsp;</label>
					<FaStar className="fa fa-star" color={value>0 ? '#999' : '#DDD'} onClick={() => setValue(value === 1 ? 0: 1)}></FaStar>
					<FaStar className="fa fa-star" color={value>1 ? '#999' : '#DDD'} onClick={() => setValue(value === 2 ? 0: 2)}></FaStar>
					<FaStar className="fa fa-star" color={value>2 ? '#999' : '#DDD'} onClick={() => setValue(value === 3 ? 0: 3)}></FaStar>
				</div>
			}
			{props.type === 'color' &&
				<div className="clearfix color-select-div">
					<label className='pull-left'>Apply this Color:&nbsp;&nbsp;</label>
					<div className={'pull-left color-select color-' + value}>
						<div tabIndex="0" className="clearfix color-box-select">
							<div onClick={()=>setValue(0)} className="color-0 color-box pull-left"></div>
							<div onClick={()=>setValue(1)} className="color-1 color-box pull-left"></div>
							<div onClick={()=>setValue(2)} className="color-2 color-box pull-left"></div>
							<div onClick={()=>setValue(3)} className="color-3 color-box pull-left"></div>
							<div onClick={()=>setValue(4)} className="color-4 color-box pull-left"></div>
							<div onClick={()=>setValue(5)} className="color-5 color-box pull-left"></div>
							<div onClick={()=>setValue(6)} className="color-6 color-box pull-left"></div>
						</div>
					</div>
				</div>
			}
			{props.type === 'notes' &&
				<div>
					<label>Apply Notes:&nbsp;&nbsp;</label>
					<TextField id="notes" name="notes" variant="outlined" onChange={(e) => setValue(e.target.value)}
							   inputProps={{maxLength: 256}} defaultValue={value} placeholder={"Input your notes here"}
							   style={{width: '600px', verticalAlign: 'middle'}}
							   fullWidth={false} autoFocus={true} spellCheck="false" value={value} />
				</div>
			}
				{props.type === 'notes' &&
					<div>
						<label>Notes appear under hit details twist arrow</label>
					</div>
				}

				<div>
					<input type="radio" value="single" name="radioGroup"
						   id="single" style={{verticalAlign: 'middle'}}
						   checked={selection === 'single'}
						   onChange={changeSelection}/>
					<label htmlFor="single">&nbsp;This alignment only</label>
				</div>
				<div>
					<input type="radio" value="thisPage" name="radioGroup"
						   id="thisPage" style={{verticalAlign: 'middle'}}
						   checked={selection === 'thisPage'}
						   onChange={changeSelection}/>
					<label htmlFor="thisPage">&nbsp;All alignments on this page</label>
				</div>
				<div>
					<input type="radio" value="all" name="radioGroup"
						   id="all" style={{verticalAlign: 'middle'}}
						   checked={selection === 'all'}
						   onChange={changeSelection}/>
					<label htmlFor="all">&nbsp;All alignments in this resultset</label>
				</div>

				<br></br>
				<div>
					<input type="checkbox"  style={{verticalAlign: 'middle'}}
						   checked={annotated} id="annotated"
						   onChange={() => updateCheckbox()}/>
					<label htmlFor="annotated">&nbsp;Skip alignments that already have&nbsp;</label>
					{props.type === 'checkbox' &&
						<label htmlFor="annotated">checked</label>
					}
					{props.type === 'star' &&
						<label htmlFor="annotated">stars</label>
					}
					{props.type === 'color' &&
						<label htmlFor="annotated">a color</label>
					}
					{props.type === 'notes' &&
						<label htmlFor="annotated">notes</label>
					}
				</div>

				<div style={{padding: '20px'}}>
					<button onClick={callback} className="pull-right large-button gqblue">Apply</button>
					<button onClick={close} className="pull-right large-button grey">Cancel</button>
					<button onClick={clearall} className="pull-right large-button grey">Clear All</button>
				</div>

			</div>
		</Modal>
	);
}

export default DiscoverAnnotationModal;
