import { useState, useEffect, useRef} from 'react';
import React from 'react';
import { useTranslation } from "react-i18next";
import { Row, Col } from 'react-bootstrap';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { useParams, useHistory } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import _ from "lodash";

import UpRoundedIcon from '@material-ui/icons/ArrowDropUpRounded';
import DownRoundedIcon from '@material-ui/icons/ArrowDropDownRounded';

import Search from '@material-ui/icons/Search';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import Footer from '../../../shared/footer';

import { usBaseUrl, ipcBaseUrl, ipcrBaseUrl, cpcBaseUrl } from '../../../config';
import '../../../assets/css/full-doc-view.css'
//import test from './testdoc';
import KeywordsInContext from '../../FullDocManager/KeywordsInContext';
import fullTextService from "../../../services/fulltextsearch";
import { documentState, getInitialDocValue, getInitialManagerValue } from '../../../services/FTDocService';
import { Tooltip } from '@material-ui/core';

const Accordion = withStyles({
    root: {
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        minHeight: 30,
        width: '102%',
        margin: 0,
        '&$expanded': {
            minHeight: 30,
            margin: 0,
        },
    },
    content: {
        margin: '0',
        '&$expanded': {
            margin: '0',
        },
    },
    expanded: {},
    '@media (max-width: 780px)': {
        root: {
            width: '100%',
        }
    }
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: '0 20px',
        margin: '12px 0',
        display: 'block'
    },
}))(MuiAccordionDetails);

const useStyles = makeStyles((theme) => ({
    arrowIcon: {
        fontSize: '2.5rem',
        marginTop: '-2px'
    },
    arrowIconTitle: {
        marginLeft: '-8px'
    },
    fixBottom: {
        borderTop: "1px solid darkgrey",
        paddingTop: "10px",
        marginLeft: "-30px",
        position: "fixed",
        bottom: "0",
        margin: "0 auto",
        background: "#fff",
        zIndex: "100",
        width: "100%",
        textAlign: "center"
    },
    paddingBottom: {
        paddingBottom: "60px !important"
    },
    parentDiv: {
        height: "100%",
        width: "100%",
        overflow: "hidden"
    },
    childDiv: {
        width: "100%",
        paddingRight: "15px",
        boxSizing: "content-box"
    },
    centerFix: {
        marginBottom: "-40px !important",
        height: "30px",
        margin: "auto",
        width: "80%",
        fontSize: "14px"
    },
    bottomContent: {
        fontSize: "13px",
        fontFamily: "Arial, Helvetica, sans-serif, Helvetica Neue",
        color: "#337AB7",
        fontWeight: "400",
        textDecoration: "none",
        cursor: "pointer"
    },

}));

const FullDocumentView = (props) => {

    const { t, i18n } = useTranslation('common');
    const classes = useStyles();
    const { patentId } = useParams();
    const history = useHistory();

    const [thisDocument, setThisDocument] = useState(getInitialDocValue(patentId));
    const [keywords, setKeywords] = useState([]);
    const [seqIds, setSeqIds] = useState("");

    const managerKey = "ftDocManager"
    const [managerState, setManagerState] = useState(getInitialManagerValue(managerKey));

    useEffect(() => {

        let state = localStorage.getItem(managerKey);
        if(state == null || state == undefined || state ==""){
            localStorage.setItem(managerKey, JSON.stringify(managerState))
        }
        
        const storageListener = (event) => {
            if (event.key === patentId) {
                if(event.newValue !== event.oldValue){
                    let docInfo =  JSON.parse(event.newValue)
                    setThisDocument(docInfo)
                    setKeywords(docInfo.keywords)
                    setSeqIds(docInfo.seqIds)
                }
            }
        };

        const beforeUnload = (event) => {
            
            localStorage.removeItem(patentId)

            let managerStateStr = localStorage.getItem("ftDocManager");
            let managerStateObj = JSON.parse(managerStateStr)

            delete managerStateObj.documents[patentId];

            let managerStateString = JSON.stringify(managerStateObj)
            localStorage.setItem("ftDocManager", managerStateString)
        };
    
        // Add the storage event listener when the component mounts
        window.addEventListener('beforeunload', beforeUnload);
        window.addEventListener('storage', storageListener);
        return () => {
          window.removeEventListener('storage', storageListener);
          window.removeEventListener('beforeunload', beforeUnload);
        };
    }, []);


    //state
    const [isAbstractOpen, setIsAbstractOpen] = useState(true);
    const [isClaimsOpen, setIsClaimsOpen] = useState(true);
    const [isDescriptionOpen, setIsDescriptionOpen] = useState(true);
    const [isClassificaitonOpen, setIsClassificationOpen] = useState(true);
    const [isLegalEventsOpen, setIsLegalEventsOpen] = useState(true);
    const [isUsPairOpen, setUsPairOpen] = useState(true);
    const [isCitationsOpen, setIsCitationsOpen] = useState(true);
    const [docContent, setDocContent] = useState({});
    const [classificationData, setClassificationData] = useState([]);
    const [summaryParts, setSummaryParts] = useState([]);
    const [detailedDesc, setDetailedDesc] = useState([]);
    const [descDrawings, setDescDrawings] = useState([]);
    const [textParts, setTextParts] = useState([]);
    const [title, setTitle] = useState(null);

    //usPair
    const [isbilboDataOpen, setIsBilboDataOpen] = useState(false);
    const [transactHistory, setTransactHistory] = useState([]);
    const [istransactionHistoryOpen, setIsTrasactionHistoryOpen] = useState(false);
    const [isPTAOpen, setIsPTAOpen] = useState(false); //Patent Term Adjustments
    const [PTHData, setPTHData] = useState([]);
    const [parContData, setParContData] = useState([]);
    const [chilContData, setChilContData] = useState([]);
    const [iscontinuityDataOpen, setIsContinuityDataOpen] = useState(false);
    const [PTEData, setPTEData] = useState([]);
    const [isPTEOpen, setIsPTEOPen] = useState(false); //patent Term Extensions

    //citations
    const [patCitData, setPatCitData] = useState([]);
    const [isPatentCitationOpen, setIsPatentCitationOpen] = useState(false);
    const [nonPatCitData, setNonPatCitData] = useState([]);
    const [isNonPatentCitationOpen, setIsNonPatentCitationOpen] = useState(false);
    const [ forCitData, setForCitData] = useState([]);
    const [isforwardCitationsOpen, setIsforwardCitationsOpen] = useState(false);

    //summary
    const [legalStatus, setLegalStatus] = useState();
    const [pubDate, setPubDate] = useState();
    const [filingDate, setFilingDate] = useState();
    const [appNum, setAppNum] = useState();
    const [appAssignees, setAppAssignees] = useState([]);
    const [inventors, setInventors] = useState([]);
    const [latestLegal, setLatestLegal] = useState([]);
    const [priorities, setPriorities] = useState([]);
    const [simFamMembers, setSimFamMembers] = useState([]);
    const [extenFamMembers, setExtenFamMembers] = useState([]);

    //legal
    const [legalEvents, setLegalEvents] = useState([]);

    //fthighlights

    const documentRef = useRef(null);

    const [keywordIndex, setKeywordIndex] = useState(-1);
    const [keywordFocused, setKeywordFocused] = useState("Keywords");
    const [KeywordNodes, setKeywordNodes] = useState([])

    const [seqIdIndex, setSeqIdndex] = useState(-1);
    const [seqIdFocused, setSeqIdFocused] = useState("SeqIDs");
    const [seqIdNodes, setSeqIdNodes] = useState([])
    
    useEffect(() => {
        if (documentRef.current) {
            removeKeywordsHighlights(documentRef.current);
          if(keywords.length>0){
            highlightKeywords(documentRef.current, keywords);
          }
        }
        getKwsHighlightedNodes(documentRef.current)
    }, [keywords]);
    
    const removeKeywordsHighlights = (node) => {
        if (node.nodeType === Node.ELEMENT_NODE) {
          const highlights = node.querySelectorAll('.keywordHighlight');
          highlights.forEach(span => {
            const parent = span.parentNode;
            parent.replaceChild(document.createTextNode(span.textContent), span);
            parent.normalize(); // Combine adjacent text nodes
          });
        }
    };

    const highlightKeywords = (node, keywords) => {
        if (node.nodeType === Node.TEXT_NODE) {
          const regex = new RegExp(`(${keywords.join('|')})`, 'gi');
          const matches = node.textContent.match(regex);
    
          if (matches) {
            const spanWrapper = document.createElement('span');
            spanWrapper.innerHTML = node.textContent.replace(regex, match => `<span class="keywordHighlight">${match}</span>`);
            
            while (spanWrapper.firstChild) {
              node.parentNode.insertBefore(spanWrapper.firstChild, node);
            }
            node.parentNode.removeChild(node);
          }
        } else if (node.nodeType === Node.ELEMENT_NODE) {
          for (let i = 0; i < node.childNodes.length; i++) {
            if(node.className !== "keywordHighlight"){
                highlightKeywords(node.childNodes[i], keywords);
            }
          }
        }
    };

    const getKwsHighlightedNodes = (node) => {
        const nodes = [];
        if (node.nodeType === Node.ELEMENT_NODE) {
          const highlights = node.querySelectorAll('.keywordHighlight');
          highlights.forEach(span => {
            nodes.push(span);
          });
        }
        setKeywordNodes(nodes);
    };


    //scrolling
    function scrollToNextKeyword() {
        let index = keywordIndex + 1
        if(index >=KeywordNodes.length){
            index = 0;
        }

        KeywordNodes[index].scrollIntoView({ behavior: 'smooth', block: 'center' });
        setKeywordFocused(KeywordNodes[index].textContent)
        setKeywordIndex(index)
    }
    
    function scrollToPrevKeyword() {
        let index = keywordIndex - 1
        if(index < 0){
            index = KeywordNodes.length -1
        }

        KeywordNodes[index].scrollIntoView({ behavior: 'smooth', block: 'center' });
        setKeywordFocused(KeywordNodes[index].textContent)
        setKeywordIndex(index)
    } 

    useEffect(() => {
        if (documentRef.current) {
            removeSeqIdHighlights(documentRef.current);
          if(seqIds !== ""){
            let numRay = extractUniqueSortedNumbers(seqIds)
            highlightSeqIds(documentRef.current, numRay);
          }
        }
        getseqIdHighlightedNodes(documentRef.current)
    }, [seqIds]);
    
    const removeSeqIdHighlights = (node) => {
        if (node.nodeType === Node.ELEMENT_NODE) {
          const highlights = node.querySelectorAll('.seqidHighlight');
          highlights.forEach(span => {
            const parent = span.parentNode;
            parent.replaceChild(document.createTextNode(span.textContent), span);
            parent.normalize(); // Combine adjacent text nodes
          });
        }
    };

    function highlightSeqIds(node, numRay) {
        if (node.nodeType === Node.TEXT_NODE) {
            const regex = /SEQ ID NO:\s*[0-9]+\s*-?\s*[0-9]*/g;
            const matches = node.textContent.match(regex);
      
            if (matches) {
                const spanWrapper = document.createElement('span');
                spanWrapper.innerHTML = node.textContent.replace(regex, (match) => {
                if(containsNumbers(extractNumbers(match), numRay)){
                        return `<span class="seqidHighlight">${match}</span>`
                    }else{
                        return `${match}`
                    }
                });
                
                while (spanWrapper.firstChild) {
                    node.parentNode.insertBefore(spanWrapper.firstChild, node);
                }
                node.parentNode.removeChild(node);
            }
          } else if (node.nodeType === Node.ELEMENT_NODE) {
            for (let i = 0; i < node.childNodes.length; i++) {
              if(node.className !== "seqidHighlight"){
                
                highlightSeqIds(node.childNodes[i], numRay);
              }
            }
          }
      
   
    }

    function extractUniqueSortedNumbers(inputString) {
        // Function to generate an array of numbers within a range
        const range = (start, end) => {
          const result = [];
          for (let i = start; i <= end; i++) {
            result.push(i);
          }
          return result;
        };
      
        // Split the input string by commas, hyphens, or new lines, and process each part
        const parts = inputString.split(/[\s,]+/);
        let numbers = [];
      
        parts.forEach(part => {
          if (part.includes('-')) {
            // Handle range
            const [start, end] = part.split('-').map(Number);
            numbers = numbers.concat(range(start, end));
          } else {
            // Handle single number
            numbers.push(Number(part));
          }
        });
      
        const uniqueNumbers = Array.from(new Set(numbers)).sort((a, b) => a - b);
        return uniqueNumbers;
    }

    function extractNumbers(str) {
        // Match one or more digits at the end of the string
        const regex = /(\d+)(?:-(\d+))?/;
        const match = str.match(regex);
    
        if (match) {
            if (match[2]) {
                // If it's a range, return the range string
                return match[0];
            } else {
                // If it's a single number, return it
                return match[1];
            }
        }
    
        // If no match found, return an empty string
        return '';
    }

    function containsNumbers(s, array) {
        // Split the string by commas and spaces
        let parts = s.split(',');
        let numbersOrRanges = parts.map(part => part.trim());
    
        for (let item of numbersOrRanges) {
            if (item.includes('-')) {
                // It's a range, split it and get the start and end
                let [start, end] = item.split('-').map(Number);
                for (let num of array) {
                    if (start <= num && num <= end) {
                        return true;
                    }
                }
            } else {
                // It's a single number
                let number = Number(item);
                if (array.includes(number)) {
                    return true;
                }
            }
        }
    }

    const getseqIdHighlightedNodes = (node) => {
        const nodes = [];
        if (node.nodeType === Node.ELEMENT_NODE) {
          const highlights = node.querySelectorAll('.seqidHighlight');
          highlights.forEach(span => {
            nodes.push(span);
          });
        }
        setSeqIdNodes(nodes);
    };

    function scrollToNextSeqId() {
        let index = seqIdIndex + 1
        if(index >=seqIdNodes.length){
            index = 0;
        }

        seqIdNodes[index].scrollIntoView({ behavior: 'smooth', block: 'center' });
        setSeqIdFocused(seqIdNodes[index].textContent)
        setSeqIdndex(index)
    }
    
    function scrollToPrevSeqId() {
        let index = seqIdIndex - 1
        if(index < 0){
            index = seqIdNodes.length -1
        }

        seqIdNodes[index].scrollIntoView({ behavior: 'smooth', block: 'center' });
        setSeqIdFocused(seqIdNodes[index].textContent)
        setSeqIdndex(index)
    }

    useEffect(async () => { 
               
        let tempTitle = "Loading..."
        thisDocument.title = tempTitle
       
        let getResponse = null;
        if (patentId) {
            getResponse = await fullTextService.fullDocViewService(history, patentId);
            if (!getResponse || getResponse.response_status !== 0 || getResponse.response_content === null || getResponse.response_content === undefined) {
                // e.g. when A5 not found, get xxxA info
                let newPatentId = patentId.substring(0, patentId.length-1);
                getResponse = await fullTextService.fullDocViewService(history, newPatentId);
            }
        }

        if(getResponse === null){
            //getResponse = test.secondDoc;
        }

        if (getResponse && getResponse.response_status == 0 && getResponse.response_content !== null) {
            
            setDocContent(getResponse.response_content);

            
            if(getResponse.response_content.inventionTitles && getResponse.response_content.inventionTitles.en
                && getResponse.response_content.inventionTitles.en.titleText){
                    
                tempTitle = getResponse.response_content.inventionTitles.en.titleText
            } else {
                tempTitle = "Title not Available"
            }
            setTitle(tempTitle)
            thisDocument.title = tempTitle


            setLegalEvents(formatLegal(getResponse.response_content.legalEvents.reverse()));
            setTransactHistory(getResponse.response_content.usPairTransactionHistory);
            setPTHData(getResponse.response_content.adjustmentHistory);
            setParContData(getResponse.response_content.parentContinuity);
            setChilContData(getResponse.response_content.childContinuity);
            setPTEData(getResponse.response_content.extensionHistory);
            setPatCitData(getResponse.response_content.patentCitations);
            setNonPatCitData(getResponse.response_content.nonPatentCitations);
            setForCitData(getResponse.response_content.forwardCitations);
            //setSummaryBlock(getResponse.response_content);
            setLegalStatus(getResponse.response_content.publicationType ? getResponse.response_content.publicationType : 'Not Available');
            setPubDate(getResponse.response_content.publicationDate ? formatDate(getResponse.response_content.publicationDate) : 'Not Available');
            setFilingDate(getResponse.response_content.applicationDate ? formatDate(getResponse.response_content.applicationDate) : 'Not Available');
            setAppNum(getResponse.response_content.applicationDocNum ? getResponse.response_content.applicationCountry + "" + getResponse.response_content.applicationDocNum : 'Not Available');
            setAppAssignees(getResponse.response_content.applicants && getResponse.response_content.applicants.length > 0 ? formatAssignees(getResponse.response_content.applicants)[0] : ['Not Available']);
            setInventors(getResponse.response_content.inventors && getResponse.response_content.inventors.length > 0 ? formatInventors(getResponse.response_content.inventors) : ['Not Available']);
            setLatestLegal(getResponse.response_content.legalEvents && getResponse.response_content.legalEvents.length > 0  ? formatLatestLgal(getResponse.response_content.legalEvents) : 'Not Available');
            //setLinkouts(getResponse.response_content.legalStatus ? getResponse.response_content.legalStatus : 'Not Available');
            setPriorities(getResponse.response_content.priorityClaims ? getResponse.response_content.priorityClaims : null);
            setSimFamMembers(getResponse.response_content.patentFamily && getResponse.response_content.patentFamily.simpleFamilyMbrs ? getResponse.response_content.patentFamily.simpleFamilyMbrs : null);   
            setExtenFamMembers(getResponse.response_content.patentFamily && getResponse.response_content.patentFamily.completeFamilyMbrs ? getResponse.response_content.patentFamily.completeFamilyMbrs : null);

            const { internationalClasses, cooperativeClasses, nationalMainClass, nationalFurtherClasses, ipcMainClass, ipcFurtherClasses, nationalClassCountry, descriptions } = getResponse.response_content;
            setClassification(internationalClasses, cooperativeClasses, nationalMainClass, nationalFurtherClasses, ipcMainClass, ipcFurtherClasses, nationalClassCountry);

            const descriptionData = descriptions && descriptions.length > 0 ? descriptions.find(data => data.langCode == "en") : {};
            setSummaryParts(descriptionData && descriptionData.summaryParts ? descriptionData.summaryParts : []);
            setDetailedDesc( descriptionData && descriptionData.detailedDesc ? descriptionData.detailedDesc : []);
            setDescDrawings(descriptionData && descriptionData.descDrawings ? descriptionData.descDrawings : []);
            setTextParts(descriptionData && descriptionData.textParts ? descriptionData.textParts : []);
        }

      


        if(window.starterSeqId !== undefined && window.starterSeqId !== null ){
            thisDocument.seqIds = window.starterSeqId
            setSeqIds(window.starterSeqId)
        }

        if(window.kws !== undefined && window.kws !== null ){
            thisDocument.keywords = window.kws
            setKeywords(window.kws)
        }

        localStorage.setItem(patentId, JSON.stringify(thisDocument));

        let managerStateString = localStorage.getItem("ftDocManager");
        let managerStateObj =  JSON.parse(managerStateString);

        if(managerStateObj){
            managerStateObj.documents[patentId] = {
                title: tempTitle,
                patentId: patentId
            }

            managerStateString = JSON.stringify(managerStateObj)
            localStorage.setItem("ftDocManager", managerStateString)
        }

    },[]);

    function formatDocPath(patentid){
        let str=""+patentid;
        return str;
    }

    function formatDate(dateString) {
        let options = { year: 'numeric', month: 'long', day: 'numeric' };
        let date = new Date(dateString);
        let strDate = date.toLocaleDateString("en-US", options) + "";

        return strDate;
    }

    function formatAssignees(assigneesObject) {
        let assignees = [];
        assigneesObject.forEach((object) => {
            object.addressBooks.forEach((names) => {
                assignees.push(names.nameOrgName);
            });
        });

        return assignees;
    }

    function formatInventors(inventorsObject) {
        let inventors = [];
        inventorsObject.forEach((object) => {
            if (object.format == null) {
                object.addressBooks.forEach((names) => {
                    if ((names.nameFirstName != null || names.nameLastName)) {

                        let fName = names.nameFirstName ? names.nameFirstName : "";
                        let mName = names.nameMiddleName ? names.nameMiddleName : "";
                        let lName = names.nameLastName ? names.nameLastName : "";

                        inventors.push(fName + " " + mName + " " + lName + ", ");
                    } else {
                        if (names.name) {
                            inventors.push(names.name + ", ");
                        }
                    }
                });
            }
        });

        return inventors;
    }

    function formatLegal(eventsObject) {
        let legalEvents = [];
        eventsObject.forEach((object) => {
            let data = {};
            data['code'] = object.eventCode1;
            data['date'] = formatDate(object.pubDate);
            data['desc'] = object.legalDesc;
            legalEvents.push(data);
        });

        return legalEvents;
    }

    function formatLatestLgal(theseLegalEvents) {
        return formatDate(theseLegalEvents.at(-1).pubDate) + " " + theseLegalEvents.at(-1).legalDesc;
    }

    function setClassification(ipcr, cpc, usMain, usFurther, ipcMain, ipcFurther, nationalClassCountry) {
        let classificationArray = [], ipcrLinkout = "", cpcLinkout = "", usMainLinkout = "", usFurtherLinkout = "", usMainObj = {}, ipcMainLinkout="", ipcFurtherLinkout = "", ipcMainObj = {};

        // setClassificationData(classificationArray);

        //US
        if (usMain && usMain.classCode && usMain.subClassCode) {
            // uspc435/defs435.htm#C435S320100
            let formatCode = usMain.text.split(/[.\/]/);
            let formatCode1 = `00${formatCode[1]}`.substr(-3, 3);
            let formatCode2 = formatCode && formatCode.length > 2 ? `${formatCode[2]}000`.slice(0,3) : '000';
            let usMainLink = `${usBaseUrl}uspc${formatCode[0]}/defs${formatCode[0]}.htm#C${formatCode[0]}S${formatCode1}${formatCode2}`;
            let usMainLabel = `${usMain.text}`;
            usMainLinkout += nationalClassCountry && nationalClassCountry == 'US' ? `<a class="mr-2" href=${usMainLink} target="_blank" rel="noreferrer">${usMainLabel}</a>` : `${usMain.classCode}/${usMain.subClassCode} `;
            // usMainObj = {
            //     label: "US",
            //     link: ReactHtmlParser(usMainLinkout)
            // }
        }

        // setClassificationData(classificationArray);

        //usFurther
        usFurther && usFurther.length > 0 && usFurther.map((item, index) => {
            // uspc435/defs435.htm#C435S320100
            let formatCode = item.text.split(/[.\/]/);
            let formatCode1 = `00${formatCode[1]}`.substr(-3, 3);
            let formatCode2 = formatCode && formatCode.length > 2 ? `${formatCode[2]}000`.slice(0,3) : '000';
            let usFurtherLink = `${usBaseUrl}uspc${formatCode[0]}/defs${formatCode[0]}.htm#C${formatCode[0]}S${formatCode1}${formatCode2}`;
            let usFurtherLabel = `${item.text}`;
            usFurtherLinkout += nationalClassCountry && nationalClassCountry == 'US' ? `<a class="mr-2" href=${usFurtherLink} target="_blank" rel="noreferrer">${usFurtherLabel}</a>` : `${item.classCode}/${item.subClassCode} `;
        });
        let usFurtherObj = {
            label: `${nationalClassCountry} Classifications`,
            link: usMainLinkout + usFurtherLinkout
        }
        console.log('usFurtherObj', usFurtherObj)
        if(_.size(usMain) > 0 || _.size(usFurther) > 0){
        classificationArray.push(usFurtherObj);
        }

        //ipc
        if (ipcMain && ipcMain.classCode && ipcMain.subClassCode) {
            let mainGroupZero = ipcMain.mainGroup ?  `0000${ipcMain.mainGroup}`.substr(-4, 4) : '';
            let subGroupZero = ipcMain.subGroup  ? `${ipcMain.subGroup}000000`.slice(0, 6) : '';
            let mGroup = ipcMain.mainGroup ? ipcMain.mainGroup : '';
            let sGroup = ipcMain.subGroup  ? ipcMain.subGroup : '';
            let ipcMainLink = `${ipcBaseUrl}${ipcMain.section}${ipcMain.classCode}${ipcMain.subClassCode}${mainGroupZero}${subGroupZero}`;
            let ipcMainLabel = `${ipcMain.section}${ipcMain.classCode}${ipcMain.subClassCode}${mGroup}/${sGroup}`;
            ipcMainLinkout += `<a class="mr-2" href=${ipcMainLink} target="_blank" rel="noreferrer">${ipcMainLabel}</a>`
            // ipcMainObj = {
            //     label: "IPC",
            //     link: ReactHtmlParser(ipcMainLinkout)
            // }
        }

        //ipcFurther
        ipcFurther && ipcFurther.length > 0 && ipcFurther.map((item, index) => {
            // uspc435/defs435.htm#C435S320100
            let mainGroupZero = item.mainGroup ?  `0000${item.mainGroup}`.substr(-4, 4) : '';
            let subGroupZero = item.subGroup  ? `${item.subGroup}000000`.slice(0, 6) : '';
            let mGroup = item.mainGroup ? item.mainGroup : '';
            let sGroup = item.subGroup  ? item.subGroup : '';
            let ipcFurtherLink = `${ipcBaseUrl}${item.section}${item.classCode}${item.subClassCode}${mainGroupZero}${subGroupZero}`;
            let ipcFurtherLabel = `${item.section}${item.classCode}${item.subClassCode}${mGroup
            }/${sGroup}`;
            ipcFurtherLinkout += `<a class="mr-2" href=${ipcFurtherLink} target="_blank" rel="noreferrer">${ipcFurtherLabel}</a>`
        });
        let ipcFurtherObj = {
            label: "IPC",
            link: ipcMainLinkout + ipcFurtherLinkout
        }
        if(_.size(ipcMain) > 0 || _.size(ipcFurther) > 0 ){
        classificationArray.push(ipcFurtherObj);
        }
        //ipcr
        ipcr && ipcr.length > 0 && ipcr.map((item, index) => {
            let mainGroupZero = item.mainGroup ?  `0000${item.mainGroup}`.substr(-4, 4) : '';
            let subGroupZero = item.subGroup  ? `${item.subGroup}000000`.slice(0, 6) : '';
            let mGroup = item.mainGroup ? item.mainGroup : '';
            let sGroup = item.subGroup  ? item.subGroup : '';
            let ipcrLink = `${ipcrBaseUrl}${item.section}${item.classCode}${item.subClassCode}${mainGroupZero}${subGroupZero}`;
            let ipcrLabel = `${item.section}${item.classCode}${item.subClassCode}${mGroup}/${sGroup}`;
            ipcrLinkout += `<a class="mr-2" href=${ipcrLink} target="_blank" rel="noreferrer">${ipcrLabel}</a>`
        });
        let ipcrObj = {
            label: "IPCR",
            link: ipcrLinkout
        }
        classificationArray.push(ipcrObj);

        //cpc
        cpc && cpc.length > 0 && cpc.map((item, index) => {
            let mGroup = item.mainGroup ? item.mainGroup : '';
            let sGroup = item.subGroup  ? item.subGroup : '';
            let cpcLink = `${cpcBaseUrl}${item.section}${item.classCode}${item.subClassCode}${mGroup}/${sGroup}`;
            let cpcLabel = `${item.section}${item.classCode}${item.subClassCode}${mGroup}/${sGroup}`;
            cpcLinkout += `<a class="mr-2" href=${cpcLink} target="_blank" rel="noreferrer">${cpcLabel}</a>`
        });
        let cpcObj = {
            label: "CPC",
            link: cpcLinkout
        }
        classificationArray.push(cpcObj);
        setClassificationData(classificationArray);
    }

    const handleScroll = (e, id) => {
        document.getElementById(id).scrollIntoView({ behavior: "smooth", inline: "nearest" });
    }
    
    const applyKeywords = (keywords) => {

        let docString = localStorage.getItem(patentId);
        let docObj = JSON.parse(docString);
        
        docObj.keywords = keywords;
        setKeywords(keywords)

        setKeywordFocused("Keywords")
        setKeywordIndex(-1)

        docString = JSON.stringify(docObj);
        localStorage.setItem(patentId, docString);
    }


    const applySeqIds = (seqIds) => {

        let docString = localStorage.getItem(patentId);
        let docObj = JSON.parse(docString);
        
        docObj.seqIds = seqIds;
        setSeqIds(seqIds)

        setSeqIdFocused("SeqIDs")
        setSeqIdndex(-1)
    
        docString = JSON.stringify(docObj);
        localStorage.setItem(patentId, docString);
    }

    const { abstracts, claims, descriptions } = docContent;

    const showString = (claim, isHTml) => {
        if(claim !== null && claim !==undefined){
        
            let str = claim.trim();
            if(isHTml){
                str = str.replaceAll('<claim-ref>', '<b>').replaceAll('</claim-ref>', '</b>')
                    .replaceAll('<claim-text>', ' ').replaceAll('</claim-text>', ' ').trim()

            
                var regex = /<ul>\s*<li>\s*<ul>\s*<li>/gi;
                var regex2 = /<\/li>\s*<\/ul>\s*<\/li>\s*<\/ul>/gi;
                var regex3 = /<chemistry>\s*<img>\s*<\/img>\s*<\/chemistry>/gi;
        
                str = str.replace(regex, "<ul><li>")
                    .replace(regex2, "</li></ul>")
                    .replace(regex3, "");
            }   
            
            // Regex pattern to match entry number and "claim [0-9]+"
            let entryNumberRegex = /(^\d+\s*-\s*\d+\s*\.)|(^\d+\s*\.)/gi;
            let claimRegex = /(\bclaim \d+\s*-\s*\d+\b)|(\bclaim\s*\d+\b)/g;

            // Replace matched patterns with bold tags
            str = str.replace(entryNumberRegex, '<b>$&</b>')
            .replace(claimRegex, '<b>$&</b>');

            return str;
        }
    return ""
    }

    const [kwicOffset, setKwicOffset] = useState("-345px")
    const openKwic = ()=>{
        if(kwicOffset === "-345px"){
            setKwicOffset("-5px")
        }else{
            setKwicOffset("-345px")
        }
    }


    return (
        <div>
            <div style={{display: "flex"}}>
                <div className="docContainer">
                {
                    <div ref={documentRef} style={{maxWidth: "800px"}}>
                    <div style={{width:"100%"}}>
                        <div className='docHeaderPadding'>
                            {title &&
                                <p className="headingFix" id="title">{title}</p>
                            }
                            {!title &&
                                <p className="headingFix" id="title">{t('fullDocViewTitle')}</p>
                            }
                        </div>
                    </div>
                        <Accordion square expanded={isAbstractOpen} onChange={() => setIsAbstractOpen(prevState => !prevState)} id="abstract">
                            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" className="subHeading p-0">
                                <p className="subHeading m-0">
                                    {isAbstractOpen && <ArrowDropDownIcon className={classes.arrowIcon} />}
                                    {!isAbstractOpen && <ArrowRightIcon className={classes.arrowIcon} />}
                                    <span className={classes.arrowIconTitle}>{t("abstract")}</span>
                                </p>
                            </AccordionSummary>
                            <AccordionDetails className="appTextColor">
                                {/* <p>Sample abstract</p> */}
                                {abstracts && abstracts.length > 0 && abstracts.map((item, index) => {
                                    if (item.langCode == "en" && item.abstractText && item.abstractText.length > 0) {
                                        return (
                                            item.abstractText.map((data, subIndex) => {
                                                return (
                                                    <p key={subIndex}>{ReactHtmlParser(data.html)}</p>
                                                )
                                            })
                                        )
                                    }
                                })}
                            </AccordionDetails>
                        </Accordion>
                        <Accordion square expanded={isClaimsOpen} onChange={() => setIsClaimsOpen(prevState => !prevState)} id="claims">
                            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" className="subHeading p-0">
                                <p className="subHeading m-0">
                                    {isClaimsOpen && <ArrowDropDownIcon className={classes.arrowIcon} />}
                                    {!isClaimsOpen && <ArrowRightIcon className={classes.arrowIcon} />}
                                    <span className={classes.arrowIconTitle}>{t("claims")}</span>
                                </p>
                            </AccordionSummary>
                                <AccordionDetails className="appTextColor">
                                {claims && claims.length > 0 && claims.map((claimObject, subIndex) => {
                                    return(
                                        (claimObject.langCode=='en' || claimObject.langCode== null) && claimObject.claims != undefined && claimObject.claims.length>0 && claimObject.claims.map((claimTextsObj)=>{
                                        return(
                                            Object.keys(claimTextsObj).length > 0 &&
                                            <div className='appTextFont appTextColor'>
                                            {claimTextsObj.claimTexts != undefined && claimTextsObj.claimTexts.length>0 && claimTextsObj.claimTexts.map((claimItem)=>{
                                                return (
                                                    <>    
                                                        {claimItem.html &&
                                                            <p>{ReactHtmlParser(showString(claimItem.html, true))}</p>
                                                        }
                                                        {!claimItem.html && claimItem.text && claimItem.text.trim()!=="" &&
                                                            <p>{ReactHtmlParser(showString(claimItem.text, false))}</p>
                                                        }
                                                    </>
                                                )
                                            })}
                                            </div>
                                        )})
                                        
                                    )})
                                }
                                </AccordionDetails>
                            </Accordion>
                                <Accordion square expanded={isDescriptionOpen} onChange={() => setIsDescriptionOpen(prevState => !prevState)} id="description">
                                    <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" className="subHeading p-0">
                                        <p className="subHeading m-0">
                                            {isDescriptionOpen && <ArrowDropDownIcon className={classes.arrowIcon} />}
                                            {!isDescriptionOpen && <ArrowRightIcon className={classes.arrowIcon} />}
                                            <span className={classes.arrowIconTitle}>{t("description")}</span>
                                        </p>
                                    </AccordionSummary>
                                    <AccordionDetails className="appTextColor">
                                        {summaryParts && summaryParts.length > 0 && summaryParts[0].textParts && summaryParts[0].textParts.length > 0 && summaryParts[0].textParts.map((data, summaryIndex) => {
                                                    return (
                                                        <p key={summaryIndex}>{ReactHtmlParser(showString(data.html, true))}</p>
                                                    )
                                                })
                                        } 
                                        {detailedDesc && detailedDesc.length > 0 && detailedDesc[0] && detailedDesc[0].textParts && detailedDesc[0].textParts.length > 0 && detailedDesc[0].textParts.map((detailedItem, detailedDescIndex) => {
                                                    return (
                                                        <p key={detailedDescIndex}>{ReactHtmlParser(showString(detailedItem.html, true))}</p>
                                                    )
                                                })
                                        }
                                            {descDrawings && descDrawings.length > 0 && descDrawings[0] && descDrawings[0].textParts && descDrawings[0].textParts.length > 0 &&descDrawings[0].textParts.map((descDrawingsItem, descDrawingsIndex) => {
                                                    return (
                                                        <p key={descDrawingsIndex}>{ReactHtmlParser(showString(descDrawingsItem.html, true))}</p>
                                                    )
                                                })
                                        } 
                                        {textParts && textParts.length > 0 && textParts.map((data, subIndex) => {
                                                        return (
                                                            <p key={subIndex}>{ReactHtmlParser(showString(data.html, true))}</p>
                                                        )
                                                    })
                                            }
                            </AccordionDetails>
                        </Accordion>
                        <Accordion square expanded={isClassificaitonOpen} onChange={() => setIsClassificationOpen(prevState => !prevState)} id="classifications">
                                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" className="subHeading p-0">
                                            <p className="subHeading m-0">
                                                {isClassificaitonOpen && <ArrowDropDownIcon className={classes.arrowIcon} />}
                                                {!isClassificaitonOpen && <ArrowRightIcon className={classes.arrowIcon} />}
                                                <span className={classes.arrowIconTitle}>{t("classifications")}</span>
                                            </p>
                                        </AccordionSummary>
                                        <AccordionDetails className="appTextColor">
                                        {Object.entries(classificationData).length != 0?
                                            <table rules="all" className="tables" style={{tableLayout: "fixed"}}>
                                                    {classificationData.map((events) => (
                                                        <tr style={{width: "500px"}}>
                                                            <td>{events.label}</td>
                                                            <td className='classTableLink'>{ReactHtmlParser(events.link)}</td>
                                                        </tr>
                                                    ))}  
                                            </table>
                                            : <div><p>Not Available</p></div>}

                                        </AccordionDetails>
                        </Accordion>
                        <Accordion square expanded={isLegalEventsOpen} onChange={() => setIsLegalEventsOpen(prevState => !prevState)} id="legalEvents">
                                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" className="subHeading p-0">
                                            <p className="subHeading m-0">
                                                {isLegalEventsOpen && <ArrowDropDownIcon className={classes.arrowIcon} />}
                                                {!isLegalEventsOpen && <ArrowRightIcon className={classes.arrowIcon} />}
                                                <span className={classes.arrowIconTitle}>{t("legalEvents")}</span>
                                            </p>
                                        </AccordionSummary>
                                        <AccordionDetails className="appTextColor">
                                            {legalEvents.length != 0?
                                            <table rules="all" className="tables">
                                    
                                                    <tr className="tableCustom">
                                                        <th>
                                                            Code
                                                        </th>
                                                        <th>
                                                            Date
                                                        </th>
                                                        <th>
                                                            Description
                                                        </th>
                                                    </tr>
                                        
                                            
                                                    {legalEvents.map((events) => (
                                                        <tr>
                                                            <td>{events.code}</td>
                                                            <td>{events.date}</td>
                                                            <td>{events.desc}</td>
                                                        </tr>
                                                    ))}
                                            
                                            </table>
                                            : <div><p>Not Available</p></div>}
                                        </AccordionDetails>
                        </Accordion>
                        <Accordion square expanded={isUsPairOpen} onChange={() => setUsPairOpen(prevState => !prevState)}>
                                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" className="subHeading p-0">
                                            <p className="subHeading m-0">
                                                {isUsPairOpen && <ArrowDropDownIcon className={classes.arrowIcon} />}
                                                {!isUsPairOpen && <ArrowRightIcon className={classes.arrowIcon} />}
                                                <span className={classes.arrowIconTitle}>{t("usPair")}</span>
                                            </p>
                                        </AccordionSummary>
                                        <AccordionDetails className="appTextColor">
                                            {/* ------------------------------------------------------------------------------------------------------------------------------visual seporator comment*/}
                                            <Accordion square expanded={isbilboDataOpen} onChange={() => setIsBilboDataOpen(prevState => !prevState)} id="classifications">
                                                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" className="subHeading p-0">
                                                    <p className="subHeading m-0">
                                                        {isbilboDataOpen && <ArrowDropDownIcon className={classes.arrowIcon} />}
                                                        {!isbilboDataOpen && <ArrowRightIcon className={classes.arrowIcon} />}
                                                        <span className={classes.arrowIconTitle}>{t("biblioGraphicData")}</span>
                                                    </p>
                                                </AccordionSummary>
                                                <AccordionDetails className="appTextColor">
                                                <table rules="all" className="tables">
                                                            <tr>
                                                                <td>Application Number:</td>
                                                                <td>{docContent.applicationDocNum? docContent.applicationDocNum: ""}</td>
                                                                <td>Status:</td>
                                                                <td>{docContent.applStatus? docContent.applStatus : "" }</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Filing or 371 (c) Date:</td>
                                                                <td>{docContent.applicationDate? formatDate(docContent.applicationDate): ""}</td>
                                                                <td>Status Date:</td>
                                                                <td>{docContent.applStatusDate? formatDate(docContent.applStatusDate) : ""}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Application Type:</td>
                                                                <td>{docContent.applicationType? docContent.applicationType: ""}</td>
                                                                <td>Earliest Publication No:</td>
                                                                <td>{docContent.appRePublicationNum? docContent.appRePublicationNum : "Not Available​" /* incorrect? */}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Entity Status:</td>
                                                                <td>{docContent.entityStatus? docContent.entityStatus : ""}</td>
                                                                <td>Earliest Publication Date:</td>
                                                                <td>{docContent.modifiedFirstDatePub? formatDate(docContent.modifiedFirstDatePub) : "" /* incorrect? */}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>AIA (First Inventor to File):</td>
                                                                <td>{inventors[0]? inventors[0]: ""}</td>
                                                                <td>Patent Number:</td>
                                                                <td>{docContent.publicationDocNum? docContent.publicationDocNum : ""}</td>
                                                            </tr>
                                                            <tr>
                                                                <td></td>
                                                                <td></td>
                                                                <td>Issue Date of Patent: </td>
                                                                <td>{docContent.publicationDate? formatDate(docContent.publicationDate) : ""}</td>
                                                            </tr>
                                                    </table>
                                                </AccordionDetails>
                                            </Accordion>


                                            <Accordion square expanded={istransactionHistoryOpen} onChange={() => setIsTrasactionHistoryOpen(prevState => !prevState)} id="classifications">
                                                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" className="subHeading p-0">
                                                    <p className="subHeading m-0">
                                                        {istransactionHistoryOpen && <ArrowDropDownIcon className={classes.arrowIcon} />}
                                                        {!istransactionHistoryOpen && <ArrowRightIcon className={classes.arrowIcon} />}
                                                        <span className={classes.arrowIconTitle}>{t("Transaction History​")}</span>
                                                    </p>
                                                </AccordionSummary>
                                                <AccordionDetails className="appTextColor">
                                                    { transactHistory.length != 0 ?
                                                    <table rules="all" className="tables">
                                                        {transactHistory.map((events) => (
                                                            <tr>
                                                                <td>{formatDate(events.tranDate)}</td>
                                                                <td>{events.tranText}</td>
                                                            </tr>
                                                        ))}
                                                    </table>
                                                    : <div><p>Not Available</p></div> }
                                                </AccordionDetails>
                                            </Accordion>

                                            <Accordion square expanded={isPTAOpen} onChange={() => setIsPTAOpen(prevState => !prevState)} id="classifications">
                                                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" className="subHeading p-0">
                                                    <p className="subHeading m-0">
                                                        {isPTAOpen && <ArrowDropDownIcon className={classes.arrowIcon} />}
                                                        {!isPTAOpen && <ArrowRightIcon className={classes.arrowIcon} />}
                                                        <span className={classes.arrowIconTitle}>{t("Patent Term Adjustments​")}</span>
                                                    </p>
                                                </AccordionSummary>
                                                <AccordionDetails className="appTextColor">
                                                    <div>
                                                    <p className="subHeading m-0" style={{paddingTop: 0+"px", paddingBottom: 10+"px"}}>Patent Term Adjustment</p>
                                                    <table rules="all" className="tables">
                                                    <tr>
                                                        <td>Filing or 371(c) Date:​</td>
                                                        <td>{docContent.fileOr371cDate? formatDate(docContent.fileOr371cDate): "Not Available"}</td>
                                                        <td style={{width: 336+"px"}}>Overlapping Days Between &#123;A and B&#125; or &#123;A and C&#125;:​</td>
                                                        <td>{docContent.overlappingDays? docContent.overlappingDays: ""}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Issue Date of Patent:​</td>
                                                        <td>{docContent.patentIssueDate? formatDate(docContent.patentIssueDate): "Not Available"}</td>
                                                        <td>Non-Overlapping USPTO Delays:​</td>
                                                        <td>{docContent.nonOverlying? docContent.nonOverlying: ""}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>A Delays:​</td>
                                                        <td>{docContent.aDelays? docContent.aDelays: ""}</td>
                                                        <td>PTO Manual Adjustments:​</td>
                                                        <td>{docContent.ptoManualAdjs? docContent.ptoManualAdjs: ""}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>B Delays:</td>
                                                        <td>{docContent.bDelays? docContent.bDelays: ""}</td>
                                                        <td>Applicant Delays:​</td>
                                                        <td>{docContent.applicantDelays? docContent.applicantDelays: ""}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>C Delays:</td>
                                                        <td>{docContent.cDelays? docContent.cDelays: ""}</td>
                                                        <td>Total PTA Adjustments:​</td>
                                                        <td>{docContent.totalPtoAdj? docContent.totalPtoAdj: ""}</td>
                                                    </tr>
                                                    <tr>
                                                            <td><a href="https://www.uspto.gov/patents-application-process/checking-application-status/pair-announcements/explanation-patent-term">Explanation Of Calculations​</a></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                        </tr>
                                                </table>

                                            </div>
                                            
                                            <div>
                                            <p className="subHeading m-0" style={{paddingTop: 0+"px", paddingBottom: 10+"px"}}>Patent Term Adjustment History</p>
                                            { PTHData.length != 0?
                                            <table rules="all" className="tables">
                                            
                                                    <tr>
                                                        <th>
                                                            Number
                                                        </th>
                                                        <th>
                                                            Date
                                                        </th>
                                                        <th>
                                                            Contents Description
                                                        </th>
                                                        <th>
                                                            PTO (Days)
                                                        </th>
                                                        <th>
                                                            APPL (days)
                                                        </th>
                                                        <th>
                                                            Start
                                                        </th>
                                                    </tr>
                                                
                                                    {PTHData.map((events) => (
                                                        <tr>
                                                            <td>{events.num ? events.num : ""}</td>
                                                            <td>{events.date ? formatDate(events.date) : ""}</td>
                                                            <td>{events.text ? events.text : ""}</td>
                                                            <td>{events.ptoDays ? events.ptoDays : ""}</td>
                                                            <td>{events.applDays ? events.applDays : ""}</td>
                                                            <td>{events.start ? events.start : ""}</td>
                                                        </tr>
                                                    ))}
                                                
                                            </table>
                                            : <div><p>Not Available</p></div> }
                                            </div>
                                            </AccordionDetails>
                                            </Accordion>

                                            <Accordion square expanded={iscontinuityDataOpen} onChange={() => setIsContinuityDataOpen(prevState => !prevState)} id="classifications">
                                                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" className="subHeading p-0">
                                                    <p className="subHeading m-0">
                                                        {iscontinuityDataOpen && <ArrowDropDownIcon className={classes.arrowIcon} />}
                                                        {!iscontinuityDataOpen && <ArrowRightIcon className={classes.arrowIcon} />}
                                                        <span className={classes.arrowIconTitle}>{t("Continuity Data")}</span>
                                                    </p>
                                                </AccordionSummary>
                                                <AccordionDetails className="appTextColor">
                                                    <p className="subHeading m-0" style={{paddingTop: 0+"px", paddingBottom: 10+"px"}}>Parent Continuity Data​</p>
                                                    {parContData.length != 0?
                                                    <div>
                                                    <table rules="all" className="tables">
                                                        
                                                            <tr>
                                                                <th>
                                                                    Description
                                                                </th>
                                                                <th>
                                                                    Parent Number
                                                                </th>
                                                                <th>
                                                                    Parent Filing or 371(c) Date
                                                                </th>
                                                                <th>
                                                                    AIA(First Inventor to File)
                                                                </th>
                                                                <th>
                                                                    Parent Status
                                                                </th>
                                                                <th>
                                                                    Patent Number
                                                                </th>
                                                            </tr>
                                                        
                                                            {parContData.map((events) => ( /* incorrect? no test data */
                                                                <tr>
                                                                    <td>{events.text ? events.text : ""}</td>
                                                                    <td>{events.document.docNumber ? events.document.docNumber : ""}</td>
                                                                    <td>{events.document.date ? formatDate(events.document.date) : ""}</td>
                                                                    <td>{events.name ? events.name : ""}</td>
                                                                    <td>{events.status ? events.status : ""}</td>
                                                                    <td>{events.num ? events.num : ""}</td>
                                                                </tr>
                                                            ))}
                                                    </table>
                                                    </div>
                                                    : <div><p>Not Avaiable</p></div>} 

                                                    <p className="subHeading m-0" style={{paddingTop: 0+"px", paddingBottom: 10+"px"}}>Child Continuity Data​</p>
                                                    {chilContData.length != 0?
                                                    <div>
                                                    <table rules="all" className="tables">
                                                        {chilContData.map((events) => (
                                                            <tr>
                                                                <td>{events.document.docNumber} filed on {formatDate(events.document.date)} which is {events.status} claims the benefit of this document.</td>
                                                            </tr>
                                                        ))}
                                                    </table>
                                                    </div>
                                                    : <div><p>Not Avaiable</p></div>}
                                                </AccordionDetails>
                                            </Accordion>

                                            <Accordion square expanded={isPTEOpen} onChange={() => setIsPTEOPen(prevState => !prevState)} id="classifications">
                                                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" className="subHeading p-0">
                                                    <p className="subHeading m-0">
                                                        {isPTEOpen && <ArrowDropDownIcon className={classes.arrowIcon} />}
                                                        {!isPTEOpen && <ArrowRightIcon className={classes.arrowIcon} />}
                                                        <span className={classes.arrowIconTitle}>{t("Patent Term Extensions")}</span>
                                                    </p>
                                                </AccordionSummary>
                                                <AccordionDetails className="appTextColor">
                                                    <p className="subHeading m-0" style={{paddingTop: 0+"px", paddingBottom: 10+"px"}}>Patent Term Extension</p>
                                                    <table rules="all" className="tables">
                                                        <tr>
                                                            <td>Filing or 371(c) Date:​</td>
                                                            <td>{docContent.extFiling371cDate ? docContent.extFiling371cDate : "Not Available"}</td>
                                                            <td>USPTO Delay (PTO) Delay (days):​</td>
                                                            <td>{docContent.usptoDelayPto ? docContent.usptoDelayPto : ""}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>USPTO Adjustment (days):​</td>
                                                            <td>{docContent.usptoAdjustment ? docContent.usptoAdjustment : ""}</td>
                                                            <td>Corrections (APPL) Delay (days):​</td>
                                                            <td>{docContent.correctionsDelay ? docContent.correctionsDelay : ""}</td>
                                                        </tr>
                                                        <tr>
                                                            <td><a href="https://www.uspto.gov/ebc/pair/pte_calc_explanation.html">Explanation Of Calculations​</a></td>
                                                            <td></td>
                                                            <td>Total Patent Term Extension (days):​</td>
                                                            <td>{docContent.totalPteExtension ? docContent.totalPteExtension : ""}</td>
                                                        </tr>
                                                    </table>

                                                    <p className="subHeading m-0" style={{paddingTop: 0+"px", paddingBottom: 10+"px"}}>Patent Term Extension History</p>
                                                    {PTEData.length != 0 ?
                                                    <table rules="all" className="tables">
                                                        <thead>
                                                            <tr>
                                                                <th>
                                                                    Date
                                                                </th>
                                                                <th>
                                                                    Contents Description
                                                                </th>
                                                                <th>
                                                                    PTO (Days)
                                                                </th>
                                                                <th>
                                                                    APPL (days)
                                                                </th>
                                                                <th>
                                                                    Start
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {PTEData.map((events) => ( /* incorrect? no test data */
                                                                <tr>
                                                                    <td>{events.number ? events.number : ""}</td>
                                                                    <td>{events.date ? events.date : ""}</td>
                                                                    <td>{events.desc ? events.desc : ""}</td>
                                                                    <td>{events.pto ? events.pto : ""}</td>
                                                                    <td>{events.appl ? events.appl : ""}</td>
                                                                    <td>{events.start ? events.start : ""}</td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                : <p>Not Available</p>}   
                                                </AccordionDetails>
                                            </Accordion>

                                            {/* ------------------------------------------------------------------------------------------------------------------------------visual seporator comment*/}
                                        </AccordionDetails>
                        </Accordion>
                        <Accordion square expanded={isCitationsOpen} onChange={() => setIsCitationsOpen(prevState => !prevState)} id="citations">
                                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" className="subHeading p-0">
                                            <p className="subHeading m-0">
                                                {isCitationsOpen && <ArrowDropDownIcon className={classes.arrowIcon} />}
                                                {!isCitationsOpen && <ArrowRightIcon className={classes.arrowIcon} />}
                                                <span className={classes.arrowIconTitle}>{t("citations")}</span>
                                            </p>
                                        </AccordionSummary>
                                        <AccordionDetails className="appTextColor">
                                            {/* ------------------------------------------------------------------------------------------------------------------------------visual seporator comment*/}

                                            <Accordion square expanded={isPatentCitationOpen} onChange={() => setIsPatentCitationOpen(prevState => !prevState)} id="citations">
                                                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" className="subHeading p-0">
                                                    <p className="subHeading m-0">
                                                        {isPatentCitationOpen && <ArrowDropDownIcon className={classes.arrowIcon} />}
                                                        {!isPatentCitationOpen && <ArrowRightIcon className={classes.arrowIcon} />}
                                                        <span className={classes.arrowIconTitle}>{t("Patent Citations​")}</span>
                                                    </p>
                                                </AccordionSummary>
                                                <AccordionDetails className="appTextColor">
                                                    {patCitData.length != 0 ?
                                                    <table rules="all" className="tables">
                                                        {patCitData.map((events) => (
                                                            <tr>
                                                                <td><a onClick={()=>{history.push(formatDocPath(events.docRef.country+""+events.docRef.docNumber+""+events.docRef.kind)); history.go(0);}}>{events.docRef.country+events.docRef.docNumber+events.docRef.kind}</a></td>
                                                                <td>{events.docRef.name ? events.docRef.name : ""}</td>
                                                                <td>{events.docRef.date ? formatDate(events.docRef.date) : ""}</td>
                                                            </tr>
                                                        ))}
                                                    </table>
                                                    : <p> Not Available</p>}
                                                </AccordionDetails>
                                            </Accordion>

                                            <Accordion square expanded={isNonPatentCitationOpen} onChange={() => setIsNonPatentCitationOpen(prevState => !prevState)} id="citations">
                                                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" className="subHeading p-0">
                                                    <p className="subHeading m-0">
                                                        {isNonPatentCitationOpen && <ArrowDropDownIcon className={classes.arrowIcon} />}
                                                        {!isNonPatentCitationOpen && <ArrowRightIcon className={classes.arrowIcon} />}
                                                        <span className={classes.arrowIconTitle}>{t("Non-Patent Citations​")}</span>
                                                    </p>
                                                </AccordionSummary>
                                                <AccordionDetails className="appTextColor">
                                                    {nonPatCitData.length != 0?
                                                    <table rules="all" className="tables">
                                                        {nonPatCitData.map((events) => (
                                                            <tr>
                                                                <td>{events.text ? events.text : ""}</td>
                                                            </tr>
                                                        ))}
                                                    </table>
                                                    : <div><p> Not Available</p></div>}
                                                </AccordionDetails>
                                            </Accordion>

                                            <Accordion square expanded={isforwardCitationsOpen} onChange={() => setIsforwardCitationsOpen(prevState => !prevState)} id="citations">
                                                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" className="subHeading p-0">
                                                    <p className="subHeading m-0">
                                                        {isforwardCitationsOpen && <ArrowDropDownIcon className={classes.arrowIcon} />}
                                                        {!isforwardCitationsOpen && <ArrowRightIcon className={classes.arrowIcon} />}
                                                        <span className={classes.arrowIconTitle}>{t("Forward Citations​")}</span>
                                                    </p>
                                                </AccordionSummary>
                                                <AccordionDetails className="appTextColor">
                                                    {forCitData.length != 0 ?
                                                    <table rules="all" className="tables">
                                                        {forCitData.map((events) => (
                                                            <tr>
                                                                <td><a href={formatDocPath(events.docRef.country+""+events.docRef.docNumber+""+events.docRef.kind)}>stuff{events.docRef.country+""+events.docRef.docNumber+""+events.docRef.kind}</a></td>
                                                                <td>{events.docRef.date ? formatDate(events.docRef.date) : ""}</td>
                                                            </tr>
                                                        ))}
                                                    </table>
                                                    : <div><p> Not Available</p></div>}
                                                </AccordionDetails>
                                            </Accordion>

                                            {/* ------------------------------------------------------------------------------------------------------------------------------visual seporator comment*/}

                                        </AccordionDetails>
                        </Accordion>
                        <div className="fixHeight">
                        </div>
                    </div>
                }
                </div>
                <div className='ftdocsidebar'> 
                    <div style={{width: 320+'px'}}>
                        <Accordion expanded ={true}>  
    
                            <AccordionSummary>
                                <p className="subHeading">{t("fullDocSummary")}</p>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div className="summaryTables">
                                <table>
                                    <tr><td className="subHeading">Legal Status</td><td className="appTextColor">{legalStatus}</td></tr>
                                    <tr><td className="subHeading">Pub. Date​</td><td className="appTextColor">{formatDate(pubDate)}</td></tr>
                                    <tr><td className="subHeading">Filing Date​</td><td className="appTextColor">{formatDate(filingDate)}</td></tr>
                                    <tr><td className="subHeading">Application Number​</td><td className="appTextColor">{appNum}</td></tr>
                                    <tr><td className="subHeading">Applicants and Assignees​</td><td className="appTextColor">{appAssignees}</td></tr>
                                    <tr><td className="subHeading">Inventors​</td><td className="appTextColor">{inventors && inventors.map((inven) => (<tr>{inven ? inven : ""}</tr>))}</td></tr>
                                    <tr><td className="subHeading">Latest Legal Event​</td><td className="appTextColor">{latestLegal}</td></tr>
                                    <tr><td className="subHeading">Linkouts</td><td className="appTextColor">
                                        <tr><a href={process.env.REACT_APP_BASE_URL+"query?do=gqfetch.get_patent_pdf&pn="+docContent.publicationCountry+""+docContent.publicationDocNum+"&kc="+docContent.publicationKind} target="_blank">PDF</a></tr>
                                        <tr><a href={process.env.REACT_APP_BASE_URL+"query?do=gqfetch&db=cat:ALL_PATENT&filter=(filters:!((O:CT,P:SEQUENCE_PN,V:"+docContent.publicationCountry+docContent.publicationDocNum+")),matchtype:AND,ver:2)"} target="_blank">Sequences</a></tr>
                                        {docContent.publicationCountry == 'US'?
                                        <div>
                                        {patentId.length - 2 > 9 ?
                                        <tr><a href={'http://appft1.uspto.gov/netacgi/nph-Parser?Sect1=PTO1&Sect2=HITOFF&d=PG01&p=1&u=%2Fnetahtml%2FPTO%2Fsrchnum.html&r=1&f=G&l=50&s1='+docContent.publicationDocNum}>USPTO</a></tr> :
                                        <tr><a href={'http://patft.uspto.gov/netacgi/nph-Parser?patentnumber='+docContent.publicationDocNum}>USPTO</a></tr>
                                        }
                                        </div>
                                        : null}
                                        {docContent.publicationCountry == 'WO'?
                                        <tr><a href={'https://www.wipo.int/patentscope/search/en/'+docContent.publicationCountry+docContent.publicationDocNum}>WIPO</a></tr>
                                        : null }
                                        <tr><a href={"https://v3.espacenet.com/results?sf=a&FIRST=1&CY=ep&LG=en&DB=EPODOC&PN="+docContent.publicationCountry+docContent.publicationDocNum}>Espacenet</a></tr>
                                    </td></tr>
                                </table>
                                </div>
                                <div className="familyTables">
                                <p className="subHeading">Priorities</p>
                                <table>
                                    <tbody>
                                    <tr><td className="appTextColor">{priorities == null ? priorities[priorities.length - 1].docNum : "Not Available"}</td><td className="appTextColor">{priorities == null ? formatDate(docContent.priorityClaims[docContent.priorityClaims.length - 1].date): "Not Available"}</td></tr>
                                    </tbody>
                                </table>
                                </div>
                                <div className="familyTables">
                                <p className="subHeading">Simple Family Members ({simFamMembers? simFamMembers.length + 1 : 0 })​</p>
                                <table>      
                                    <tbody>
                                    <tr><td><a onClick={()=>{history.push(formatDocPath(patentId));  history.go(0);}}>{docContent.publicationCountry && docContent.publicationDocNum && docContent.publicationKind? docContent.publicationCountry+" "+docContent.publicationDocNum+" "+docContent.publicationKind: "Not Available"}</a></td><td className="appTextColor">{formatDate(docContent.publicationDate)}</td></tr>
                                    {simFamMembers? simFamMembers.map((sfm) => (<tr><td>
                                        <a onClick={()=>{history.push(formatDocPath(sfm.docRefs[0].country+""+sfm.docRefs[0].docNumber+""+sfm.docRefs[0].kind)); history.go(0);}}>
                                        {sfm.docRefs[0].country+" "+sfm.docRefs[0].docNumber+" "+sfm.docRefs[0].kind}
                                        </a>
                                        </td><td className="appTextColor">{formatDate(sfm.docRefs[0].date)}</td></tr>)) : null}
                                    </tbody>
                                </table>
                                </div>
                                <div className="familyTables">
                                <p className="subHeading">Extended Family Members ({extenFamMembers? extenFamMembers.length : 0})​</p>
                                <table>
                                    <tbody>
                                    {extenFamMembers? extenFamMembers.map((xfm) => (<tr><td>
                                        <a onClick={()=>{history.push(formatDocPath(xfm.docRefs[0].country+""+xfm.docRefs[0].docNumber+""+xfm.docRefs[0].kind)); history.go(0);}}>
                                        {xfm.docRefs[0].country+" "+xfm.docRefs[0].docNumber+" "+xfm.docRefs[0].kind}
                                        </a>
                                        </td><td className="appTextColor">{formatDate(xfm.docRefs[0].date)}</td></tr>)) : null}
                                    </tbody>
                                </table>
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                </div>
            </div>
            <div 
                className="floatingKwicContainer"
                style={{
                    height: window.innerHeight-70+"px",
                    right: kwicOffset

                }}>
                <div>
                    <div className='openKwicButton'>
                        <Tooltip title="Document Highlights">
                            <Search
                            onClick={()=>openKwic()}/>
                        </Tooltip>
                    </div>
                    {KeywordNodes.length >0 &&
                        <div className='openKwicButton' style={{display: "flex", flexDirection: "column"}}>
                            <UpRoundedIcon onClick={()=>{scrollToPrevKeyword()}}/>
                            {keywordFocused !== "" &&
                            <div className='fthighlightDiv'><span className='keywordHighlight absolutePosition'>{keywordFocused}</span></div>
                            }
                            <DownRoundedIcon onClick={()=>{scrollToNextKeyword()}}/> 
                        </div>
                    }
                    {seqIdNodes.length >0 &&
                    <div className='openKwicButton' style={{display: "flex", flexDirection: "column"}}>
                        <UpRoundedIcon onClick={()=>{scrollToPrevSeqId()}}/>
                        {seqIdFocused !=="" &&
                        <div className='fthighlightDiv'><span className='seqidHighlight absolutePosition'>{seqIdFocused}</span></div>
                        }
                        <DownRoundedIcon onClick={()=>{scrollToNextSeqId()}}/> 
                    </div>
                    }
                </div>
                <div className="floatingkwic">
                <KeywordsInContext
                    docsSelected = {[patentId]}
                    applyKeywords={(keywords)=>{applyKeywords(keywords)}}
                    applySeqIds ={(seqids)=>{applySeqIds(seqids)}}
                >
                </KeywordsInContext>
                </div>
            </div>
            <div className={classes.fixBottom}>
                <p className={classes.centerFix}>
                    <span className={"m-1 " + classes.bottomContent} onClick={(e) => handleScroll(e, 'title')}>{t('titleFooter')}</span>
                    <span className="m-1">|</span>
                    <span className={"m-1 " + classes.bottomContent} onClick={(e) => handleScroll(e, 'abstract')}>{t('abstractFooter')}</span>
                    <span className="m-1">|</span>
                    <span className={"m-1 " + classes.bottomContent} onClick={(e) => handleScroll(e, 'claims')}>{t('claimsFooter')}</span>
                    <span className="m-1">|</span>
                    <span className={"m-1 " + classes.bottomContent} onClick={(e) => handleScroll(e, 'description')}>{t('descriptionFooter')}</span>
                    <span className="m-1">|</span>
                    <span className={"m-1 " + classes.bottomContent} onClick={(e) => handleScroll(e, 'classifications')}>{t('classificationsFooter')}</span>
                    <span className="m-1">|</span>
                    <span className={"m-1 " + classes.bottomContent} onClick={(e) => handleScroll(e, 'legalEvents')}>{t('legalEventsFooter')}</span>
                    <span className="m-1">|</span>
                    <span className={"m-1 " + classes.bottomContent} onClick={(e) => handleScroll(e, 'usPair')}>{t('usPair')}</span>
                    <span className="m-1">|</span>
                    <span className={"m-1 " + classes.bottomContent} onClick={(e) => handleScroll(e, 'citations')}>{t('citationsFooter')}</span>
                </p>
                <div style={{marginTop: "45px"}}>
                    <Footer/>
                </div>
            </div>
        </div>
    )
}

export default FullDocumentView;
