import React, { useState, useEffect } from "react";
import PatentNumber from './PatentNumber';
import GlobalSearch from "./GlobalSearch";

function PatentNumberSearch(){

    const Tabs = ["patentNumber", "global"]
    const [state, setState] = useState("patentNumber");
    
    useEffect(() =>{
    },[])

    function switchPage(pageName){
        setState(pageName);
    }

    return(
        <div>
            <div style= {{ marginTop: '-33px', marginLeft: '-5px' }}>
                <button className={state === "patentNumber" ? "inactiveTab" : "accountTabs"} onClick={()=>switchPage("patentNumber")}>Patent Number</button>
                <button className={state === "global" ? "inactiveTab" : "accountTabs"}  onClick={()=>switchPage("global")}>Global Search</button>
            </div>

            {state == "patentNumber" &&
            <div>
                <PatentNumber></PatentNumber>
            </div>
            }
            {state == "global" &&
            <div>
                <GlobalSearch></GlobalSearch>
            </div>
            }
        </div>
    );
}

export default PatentNumberSearch;
