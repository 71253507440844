import React, { useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import TextInput from '../Fields/TextInput';
import SelectBox from '../Fields/SelectBox';
import { Dropdown } from 'react-bootstrap';
import { BorderAll } from '@material-ui/icons';
import DropdownToggle from 'react-bootstrap/esm/DropdownToggle';
import DropdownItem from 'react-bootstrap/esm/DropdownItem';
import { FaCaretDown, FaPlus, FaMinus } from "react-icons/fa";
import DropdownMenu from 'react-bootstrap/esm/DropdownMenu';
 
const useStyles = makeStyles((theme) => ({
    modalHeader: {
        fontFamily: 'verdana, helvetica',
        fontSize: '12px',
        width: '73%'
    }
}));
 
 
 
const ManageViewModal = (props) => {
 
    const classes = useStyles();
 
    useEffect(() => {
    }, []);
 
    function onCancel(props) {
        props.closeModal();
    }
    function clickOnOk(props) {
        props.closeModal();
    }
 
    return (
        <Modal
            id='modalCss'
            aria-labelledby="contained-modal-title-vcente"
            centered
            size='lg'
            show={props.showModal}
            contentClassName={classes.modalHeader}
        >
            <div>
                <div className="titlebar">
                    <span>Manage Preferences</span>
                    <Link href="#" onClick={(e) => e.preventDefault()} className="pull-right">
                        <CloseIcon style={{ fill: '#b5b5b5' }} onClick={() => onCancel(props)} />
                    </Link>
                </div>
                <div style={{ borderTop: '1px solid #b5b5b5', padding:'12px' }}>
                    <br />
                    <form style={{border:'1px solid rgb(204,204,204)', background:'whitesmoke'}}>
                        <table >
                            <tr>
                                <td>
                                    <label>Set a preference as your default &nbsp;&nbsp;&nbsp;</label>
                                </td>
                                
                                <td>
                                    <div className="pull-left filter-widget-field-middle custom-filter-label">
                                        <Dropdown className="btn-group">
                                            <DropdownToggle className="btn btn-dropdown btn-dropdown-dates">
                                                <div className="clearfix">
                                                    <div className="pull-left custom-filter-label">
                                                        Choose...
                                                    </div>
                                                    <div style={{ position: 'absolute', right: '2px' }}>
                                                        <FaCaretDown />
                                                    </div>
                                                </div>
                                            </DropdownToggle>
                                            <DropdownMenu className="dropdown-menu dropdown-menu-dates custom-filter-label">
                                                <DropdownItem className="filter-widget-menu-item" style={{ color: '#db862c' }}>
                                                    Choose...
                                                    <br>
                                                    </br>
                                                    System Default
                                                </DropdownItem>
                                            </DropdownMenu>
                                        </Dropdown>
                                    </div>
                                </td>
                                
                                <td>
                                    <button>Set default</button>
                                </td>
                            </tr>
                        </table>
                    </form>
                    <br/>
                    <form style={{border:'1px solid rgb(204,204,204)', background:'whitesmoke'}}>
                        <table>
                            <tr>
                                <td>
                                    <label>Delete a preference </label>
                                </td>
                                <td>
                                    <div className="pull-left filter-widget-field-middle custom-filter-label">
                                        <Dropdown className="btn-group">
                                            <DropdownToggle className="btn btn-dropdown btn-dropdown-dates" aria-required>
                                                <div className="clearfix">
                                                    <div className="pull-left custom-filter-label" >
                                                        Choose...
                                                    </div>
                                                    <div style={{ position: 'absolute', right: '2px' }}>
                                                        <FaCaretDown />
                                                    </div>
                                                </div>
                                            </DropdownToggle>
                                            <DropdownMenu className="dropdown-menu dropdown-menu-dates custom-filter-label">
                                                <DropdownItem className="filter-widget-menu-item" style={{ color: '#db862c' }}>
                                                    Choose...
                                                    <br>
                                                    </br>
                                                    System Default
                                                </DropdownItem>
                                            </DropdownMenu>
                                        </Dropdown>
                                    </div>
                                </td>
                                <td>
                                    <button>Delete</button>
                                </td>
                            </tr>
                        </table>
                    </form>
                </div>
                <div align='right'>
                    <button className='okButton DMTBtn' onClick={() => onCancel(props)}>Cancel</button>
                    <button className='okButton DMTBtn' onClick={() => clickOnOk(props)}>Save</button>
                </div>
            </div>
        </Modal>
    );
}
 
export default ManageViewModal;