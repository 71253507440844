import React, { useState, useEffect } from "react";
import {useParams, Link} from 'react-router-dom';
import { toast } from 'react-toastify';
import _ from "lodash";
import {FaInfoCircle, FaRegPlusSquare} from "react-icons/fa";
import Tooltip from "@material-ui/core/Tooltip";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import Modal from 'react-bootstrap/Modal';

import {IoClose} from "react-icons/io5";
import Button from "@material-ui/core/Button";
import DiscoverService from "../../../services/discoverService";

import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';

import VmFilterModal from "../../../shared/Modal/VmFilterModal";
import {Col} from "react-bootstrap";
import { BorderColor } from "@material-ui/icons";

function SequenceVariationDiscover(props){

     //widgetState contains just this filter items
     const [defaultLvaWidgetState, setDefaultLWidgetState] = useState({
        lvaQuery: null, //  To be saved with a filter
        lvaQueryLocked: false,
        widgetContent: [],

        savedWidgetContentQueries: {},  //  The query
        savedWidgetContents: {},  
 
        lvaFilterName: '',  //  To be saved with a filter

        lvaMethods: {},

        lvaPositionEvents: {},

        // holds all the custom filters available to the user
        lvaFilters: [], //  To be saved in app_state for this result set

        // set to true when user hovers over a filter (to show edit|del menu on hover, no restore)
        lvaFiltersHover: [],

        // holds whether a filter has been deleted (true means deleted)
        lvaFilterDeleted: [],

        lvaReportsHover: [],
        selectedColorIdx: 0
    });

    const [lvaWidgetState, setLvaWidgetState] = useState(defaultLvaWidgetState);

    const {resid} = useParams();
    const [showDex, setShowDex] = useState('');

    //del
    const [openDelUI, setOpenDelUI] = useState(false);
    const [oldName, setOldName] = useState(false);
    const [deleteReportName, setDeleteReportName] = useState("");

    //create
    const [openCreateUI, setOpenCreateUI] = useState(false);
    const [reportQuerySeqID, setReportQuerySeqID] = useState(null);
    //const [reportQuerySeqCount, setReportQuerySeqCount] = useState(null);

    //filtermodal
    const [addFilterPopup, setAddFilterPopup] = useState(false);

    const [lvaFiltersHover, setLvaFiltersHover] = React.useState([]);
    const [globalLvaFiltersHover, setGlobalLvaFiltersHover] = React.useState([]);

    const closeVmFilterModal = () =>{
        setAddFilterPopup(false);
    }
    const openVmFilterModal = () =>{
        setAddFilterPopup(true);
    }


    const [vmReportNames , setVmReportNames] = useState([]);
    const [vmReportName, setVmReportName] = useState("");

    const {
        applyFilterForJava,
        apiKey,
        userDir,
        vmIds,
        saveResultState,
        filter,
        fieldConfig,
        lvaLoadingReady,
        reportId,
        editable
    } = props;



    const Accordion = withStyles({
        root: {
            boxShadow: 'none',
            '&:not(:last-child)': {
                borderBottom: 0,
            },
            '&:before': {
                display: 'none',
            },
            '&$expanded': {
                margin: 'auto',
            },
        },
        expanded: {},
    })(MuiAccordion);
    
    const AccordionSummary = withStyles({
        root: {
            minHeight: 3,
            width: '102%',
            margin: 0,
            '&$expanded': {
                minHeight: 3,
                margin: 0,
            },
        },
        content: {
            margin: '0',
            padding: '0',
            '&$expanded': {
                margin: '0',
            },
        },
        expanded: {},
        // '@media (max-width: 780px)': {
        //     root: {
        //         width: '100%',
        //     }
        // }
    })(MuiAccordionSummary);
    
    const AccordionDetails = withStyles((theme) => ({
        root: {
            padding: '0 0 0 5px',
            margin: '0',
            display: 'block'
        },
    }))(MuiAccordionDetails);

    const accordionProps = {
        sx: {
            pointerEvents: "none"
        },
        expandIcon: (
            <ExpandMoreIcon
                sx={{
                    pointerEvents: "auto"
                }}
            />
        )
    };

    const useStyles = makeStyles((theme) => ({
        modalClass: {
            position: 'absolute',
            width: '81%',
            padding: '2px 15px 12px',
            right: '68px',
            bottom: 'auto',
        }
    }));
    let [sideMenuDetail, setSideMenuDetail] = useState({});

    const [lvaTitle, setLvaTitle]= useState("Loading Sequence Variation Discovery");
    const [lvaID,setLvaID] = useState("");

    const classes = useStyles();
    const toolTipStyles = {
        tooltip: {
            width: "auto",
            //height: "40px",
            fontSize: '12px',
            textAlign: 'justify',
            padding: "8px 14px 8px 14px",
            boxShadow: "0px 1px 7px #888",
            backgroundColor: '#777',
            color: '#FFF'
        }
    };
    const CustomTooltip = withStyles(toolTipStyles)(Tooltip);

    const parseSeqId = (seqId) => {
        return seqId.replace(new RegExp('/[^\-_a-zA-Z0-9\.]+/', 'g'), '_')
                           .replace(/\//g, '_').replace(/&/g, '_').replace(/#/g, '_')
                           .replace(/%/g, '_').replace(/=/g, '_').replace(/\+/g, '_');
    }

    async function variationLandscapeReport(reportQuerySeqID, vmReportNames) {

        let now = new Date();
        let month = now.getMonth() + 1;
        if (month < 10) {
            month = "0" + month;
        }
        let days = now.getDate();
        if (days < 10) {
            days = "0" + days;
        }
        let hours = now.getHours();
        if (hours < 10) {
            hours = "0" + hours;
        }
        let mins = now.getMinutes();
        if (mins < 10) {
            mins = "0" + mins;
        }
        let secs = now.getSeconds();
        if (secs < 10) {
            secs = "0" + secs;
        }
        let nextSecs = now.getSeconds() + 1;
        if (nextSecs < 10) {
            nextSecs = "0" + nextSecs;
        }
        // TODO, there should be a bug when reportQuerySeqID !== seqId, how to handle the seqId key in vmReportNames array
        // reportQuerySeqID may not be valid for a doc name
        let seqId = parseSeqId(reportQuerySeqID);
        let fileName = 'Report_' + seqId + "_" + now.getFullYear() + month + days + "_" + hours + mins + secs;
        
        if (vmReportNames && vmReportNames[reportQuerySeqID] && vmReportNames[reportQuerySeqID][fileName] !== undefined) {
            // Check if duplicate report name exists
            fileName = 'Report_' + seqId + "_" + now.getFullYear() + month + days + "_" + hours + mins + nextSecs;
        }

        let reports = vmReportNames;
        let obj = {'reportName': fileName, 'ready': false};
        if (!reports[seqId]) {
            reports[seqId] = {};
        }
        reports[seqId][fileName] = obj;
        setVmReportNames(reports);
    
        let data = {};
        let groupCriteria = fieldConfig.tableDisplayMode;

        switch (fieldConfig.tableDisplayMode) {
            case 'family':
                groupCriteria = 'fam';
                break;
            case 'document':
                groupCriteria = 'patent';
                break;
            case 'flat':
                groupCriteria = 'alignment';
                break;
        }
        data["workflowId"] = resid.match(/\d+/)[0];
        data["userId"] = userDir;
        data["groupCriteria"] = groupCriteria;
        data["queryId"] = reportQuerySeqID;
        data["vmFileName"] = fileName;
        data["newLink"] = true;

        let javaCall = {
            responseType: 'arraybuffer',
            data: data,
            headers: {
                'Authorization': 'Bearer ' + apiKey
                // 'Access-Control-Allow-Headers': '*'
            },
            format: 'json',
            error_detection: true,
            parm_location: 'outer'
        };

        applyFilterForJava(javaCall, true);

        let response = await DiscoverService.loadVariationReport(javaCall.data, apiKey);
        if (response) {
            //let vmWindow = window.open('../fulltext/index.html#/vmreport/' + resid.match(/\d+/)[0] + '/' + fileName,'_blank');
            //vmWindow.vmReport = response;
            setVmReportName(fileName);
            let blob =  new Blob([(response)]);
            let decodedString = await blob.text();
            if (decodedString) {
                // Set in localStorage to be passed across different tabs
                localStorage.setItem('vmReportString_' + fileName, decodedString.replace("<html>", "").replace("</html>", "").replace("<head>", "").replace("</head>", ""));
                document.getElementById("vmReport").click();
            }

            setOpenCreateUI(false);
            reports[seqId][fileName]['ready'] = true;
            setVmReportNames(reports);
            // TODO, force a refresh for now
            //variationAnalysisOps('list', '')
        }
    }

    async function variationAnalysisOps(type, fileName){
        let data={
            dbId: resid.match(/\d+/)[0],
            type: type,
            fileName: fileName,
            format: 'json',  //  Do this so that any error content is returned as JSON instead of HTML
            error_detection: true,
            parm_location: 'inner'
        };

        let response = await DiscoverService.variationAnalysisOps(data)

        if (response) {
            if (type === 'list') {
                let seqReports = [];
                for (let idx in response) {
                    let name = response[idx];

                    // Be noted that, data.data contains names like '.' and '..'
                    if ('.' === name || '..' === name) {
                        continue;
                    }

                    // Use regex to get the seq name between Report & date, starts with 'Report_'
                    //let seq = _.replace(name, 'Report_', '');
                    let seq = name.substring(7);
                    // Ends with '_{8 digits}_{6 digits}.doc'
                    seq = parseSeqId(seq.replace(/(_\d{8}_\d{6}\.doc)$/g, ''));

                    if (!seqReports[seq]) {
                        seqReports[seq] = {};
                    }
                    name = name.substring(0, name.length - 4); // remove '.doc'
                    seqReports[seq][name] = {'reportName': name, 'ready': true};
                }
                setVmReportNames(seqReports);
            } else if (type === 'download') {
                let docName = fileName + '.doc';
                let blob = new Blob([(response)], {type: 'application/msword'});
                let fileURL = URL.createObjectURL(blob);
                let link = document.createElement("a");

                link.setAttribute("href", fileURL);
                link.setAttribute("download", docName);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } else {
                // Refresh the list for deletion case
                variationAnalysisOps('list', '')
            }

            return response;
        }

    }

    async function delvmReport(){


        let response = await variationAnalysisOps("delete", deleteReportName.replace(".doc", ""))
        if(response != null && response != undefined){
            if(response.includes("file removed?")){
                if(response.match(/\d+/)[0] > 0){
                    setOpenDelUI(!openDelUI)
                    toast.success("File Deleted");
                    return
                }
            }
        }
        toast.error("Could not Delete File");
    }

    const saveFilter = (filter, fieldConfig, sideMenuDetail) => {
        sideMenuDetail['lvaModules'] = true;
        sideMenuDetail['lvaFilters'] = true;
        if (lvaWidgetState.saveAsGlobal && !sideMenuDetail['globalLvaFilters']) {
            sideMenuDetail['globalLvaFilters'] = true;
        }
        saveResultState(filter, fieldConfig, sideMenuDetail);
        filter.lvaWidgetState = {...filter.lvaWidgetState};
        filter.widgetState = {...filter.widgetState}; // When saved as global
        filter.state = {...filter.state};
        closeVmFilterModal();
        setLvaWidgetState(filter.lvaWidgetState);
        setSideMenuDetail({...sideMenuDetail});
    }

    useEffect( async() => {
        if (Object.keys(vmIds).length > 0){
            variationAnalysisOps('list', '')
            setLvaTitle("Sequence Variation Discovery");
            if (props.filter.lvaWidgetState) {
                setLvaWidgetState(props.filter.lvaWidgetState);
            }
            setSideMenuDetail(props.menuDetail);
        }

    },[props.vmIds]);
    

    const toggleSideMenuDetail = (sideMenuItem) => {
        if (sideMenuDetail) {// Wait for the initialization
            let val = (sideMenuDetail[sideMenuItem] ? false : true);
            sideMenuDetail[sideMenuItem] = val;
            setSideMenuDetail({...sideMenuDetail});
            if(sideMenuDetail.lvaModules ){//to fix tooltip position for VM
                if(sideMenuDetail.lvaFilterMenu && sideMenuItem === 'lvaFilterMenu'){
                    setLvaID('variationLandscape');
                }
                else{
                    setLvaID('variationTooltip');
                }
            }
            else{
                setLvaID('exampleTooltip');
            }
        }
    }

    const loadGlobalLvaFilterFromList = (idx) => {
        lvaWidgetState.widgetContent = _.cloneDeep(filter.widgetState.globalLvaFilters[idx].widgetContent);
        lvaWidgetState.lvaFilterName = filter.widgetState.globalLvaFilters[idx].lvaFilterName;
        lvaWidgetState.selectedColor = filter.widgetState.globalLvaFilters[idx].selectedColor ? filter.widgetState.globalLvaFilters[idx].selectedColor : "blue";
        lvaWidgetState.selectedColorIdx = filter.widgetState.globalLvaFilters[idx].selectedColorIdx ? filter.widgetState.globalLvaFilters[idx].selectedColorIdx : "5";
        lvaWidgetState.lvaQuery = filter.widgetState.globalLvaFilters[idx].lvaQuery;
        if (filter.defaultLvaQuery) {
            // Set the default query
            //self.lvaWidgetState.lvaQuery.QUERY_ID = self.defaultLvaQuery.QUERY_ID;
            lvaWidgetState.lvaQuery = _.cloneDeep(filter.defaultLvaQuery);
            lvaWidgetState.lvaQuery.copyFromOther = true;
        }
        lvaWidgetState.lvaQueryLocked = true;
        //self.lvaWidgetState.copyFromOther = true;
        lvaWidgetState.saveAsGlobal = false;
    };

    const updateLvaFiltersHover = (idx, val) => {
        lvaFiltersHover[idx] = val;
        setLvaFiltersHover([...lvaFiltersHover]);
    }

    const updateGlobalLvaFiltersHover = (idx, val) => {
        globalLvaFiltersHover[idx] = val;
        setGlobalLvaFiltersHover([...globalLvaFiltersHover]);
    }

    const removeGlobalLvaFilterFromList = (filter, fieldConfig, idx) => {
        filter.widgetState.globalLvaFilters.splice(idx, 1);
        filter.widgetState = {...filter.widgetState};

        // save the config after the filters have been updated
        fieldConfig.saveConfig(fieldConfig, 'default', filter);
    }

    const startFilteringWidget = (filter, type, idx) => {
        if (editable !== true) {
            return;
        }
        if (type === 'global') {
            // View/Edit global filter
            setLvaWidgetState(_.cloneDeep(filter.widgetState.globalLvaFilters[idx]));
            lvaWidgetState.widgetContent = _.cloneDeep(filter.widgetState.globalLvaFilters[idx].widgetContent);
            if (filter.widgetState.globalLvaFilters[idx].widgetMode) {
                lvaWidgetState.widgetMode = filter.widgetState.globalLvaFilters[idx].widgetMode;
            } else {
                lvaWidgetState.widgetMode = 'AND';
            }
            lvaWidgetState.widgetCustomQuery = filter.widgetState.globalLvaFilters[idx].widgetCustomQuery;
            lvaWidgetState.lvaFilterName = filter.widgetState.globalLvaFilters[idx].lvaFilterName;
            lvaWidgetState.lvaQuery = _.cloneDeep(filter.widgetState.globalLvaFilters[idx].lvaQuery);
            lvaWidgetState.selectedColor = filter.widgetState.globalLvaFilters[idx].selectedColor;
            lvaWidgetState.selectedColorIdx = filter.widgetState.globalLvaFilters[idx].selectedColorIdx;
        } else if (type === 'custom') {
            // View/Edit custom filter
            setLvaWidgetState(_.cloneDeep(filter.lvaWidgetState.lvaFilters[idx]));
            lvaWidgetState.widgetContent = _.cloneDeep(filter.lvaWidgetState.lvaFilters[idx].widgetContent);
            if (filter.lvaWidgetState.lvaFilters[idx].widgetMode) {
                lvaWidgetState.widgetMode = filter.lvaWidgetState.lvaFilters[idx].widgetMode;
            } else {
                lvaWidgetState.widgetMode = 'AND';
            }
            lvaWidgetState.widgetCustomQuery = filter.lvaWidgetState.lvaFilters[idx].widgetCustomQuery;
            lvaWidgetState.lvaFilterName = filter.lvaWidgetState.lvaFilters[idx].lvaFilterName;
            lvaWidgetState.lvaQuery = _.cloneDeep(filter.lvaWidgetState.lvaFilters[idx].lvaQuery);
            lvaWidgetState.selectedColor = filter.lvaWidgetState.lvaFilters[idx].selectedColor;
            lvaWidgetState.selectedColorIdx = filter.lvaWidgetState.lvaFilters[idx].selectedColorIdx;
        } else {
            // Create new filter
            //lvaWidgetState.widgetContent = [{field: 'ALL_TEXT', field_type: 'string', operator: 'CONTAINS', value: '', second_value: ''}];
            lvaWidgetState.widgetMode = 'AND';
            lvaWidgetState.lvaFilterName = '';
            lvaWidgetState.selectedColor = 'blue';
            lvaWidgetState.selectedColorIdx = '5';
            lvaWidgetState.widgetContent = [];
            lvaWidgetState.lvaQuery = {};
        }
        if (lvaWidgetState.widgetMode !== 'CUSTOM') {
            lvaWidgetState.widgetCustomQuery = '';
        }
        lvaWidgetState.lvaFilters = _.cloneDeep(filter.lvaWidgetState.lvaFilters);
        setLvaWidgetState({...lvaWidgetState});
        setAddFilterPopup(true);
    }

	return (

        <div>
            <span id={lvaID}></span>
            <Accordion square expanded={sideMenuDetail['lvaModules']}>
                <AccordionSummary aria-controls="panel-lvaModules" disabled={lvaLoadingReady !== true} className="subMenuHeading p-0">
                    <p className="subMenuHeading m-0" onClick={() => toggleSideMenuDetail('lvaModules')}>
                        {sideMenuDetail['lvaModules']==true && <ArrowDropDownIcon className={classes.arrowIcon} />}
                        {!sideMenuDetail['lvaModules']==true && <ArrowRightIcon className={classes.arrowIcon} />}
                        {lvaTitle}&nbsp;
                        <span className="glyphicon glyphicon-info-sign"></span>
                    </p>
                    <CustomTooltip arrow title={"Click to open the guide"} placement="top">
                        <span>
                            <a href="https://docs.genomequestlive.com/sections/variation_discovery/"
                                target="_blank" className="appTextFont appLink float-right" rel="noreferrer"><FaInfoCircle/></a>
                        </span>
                    </CustomTooltip>
                </AccordionSummary>
                <AccordionDetails className="appTextColor" disabled={lvaLoadingReady !== true}>
                    <Col style={{paddingLeft: '5px'}}>
                    <Accordion square expanded={sideMenuDetail['lvaFilterMenu']} onChange={() => toggleSideMenuDetail('lvaFilterMenu')}>
                        <AccordionSummary aria-controls="panel-lva" id="panel-lva" className="subMenuHeading p-0">
                        <div className="side-menu-item sidebar-tour-width">
                            {sideMenuDetail['lvaFilterMenu']==true && <ArrowDropDownIcon className={classes.arrowIcon} />}
                            {!sideMenuDetail['lvaFilterMenu']==true && <ArrowRightIcon className={classes.arrowIcon} />}
                            <span>Variation Filters</span>
                        </div>
                        </AccordionSummary>
                        <AccordionDetails className="appTextColor">
                            <Col style={{paddingLeft: '10px'}}>
                            <Accordion square expanded={true}>
                                <AccordionSummary aria-controls='panel-content-samcustomfilter' id='paneld-header-samcustomfilter' className="subMenuHeading p-0">
                                    <p className="subMenuHeading m-0" onClick={() => {startFilteringWidget(filter)}} style={{paddingLeft: '5px'}}>
                                        <FaRegPlusSquare style={{marginRight: '5px', verticalAlign: 'middle'}} className="fa fa-plus-square"></FaRegPlusSquare>
                                        <span>Create New Filter</span>
                                    </p>
                                </AccordionSummary>
                            </Accordion>

                            <Accordion square expanded={sideMenuDetail['globalLvaFilters']}
                                       onChange={() => {toggleSideMenuDetail('globalLvaFilters')}}>
                                <AccordionSummary aria-controls='panel-content-globalLvaFilters' id='paneld-header-globalLvaFilters' className="subMenuHeading p-0">
                                    <p className="subMenuHeading m-0">
                                        {sideMenuDetail['globalLvaFilters'] && <ArrowDropDownIcon className={classes.arrowIcon} />}
                                        {!sideMenuDetail['globalLvaFilters'] && <ArrowRightIcon className={classes.arrowIcon} />}
                                        <span className="mt-4">Global Filters</span>
                                    </p>
                                </AccordionSummary>
                                <AccordionDetails className="appTextColor" style={{paddingLeft: '0px'}}>
                                    {filter.widgetState && filter.widgetState.globalLvaFilters && Object.keys(filter.widgetState.globalLvaFilters).length > 0 &&
                                        filter.widgetState.globalLvaFilters.map(
                                            (lvaFilter, idx) => (
                                                <Col key={'lvaFilter_' + idx} style={{paddingLeft: '10px'}}>
                                                    <div onMouseEnter={(e) => updateGlobalLvaFiltersHover(idx, true)}
                                                         onMouseLeave={(e) => updateGlobalLvaFiltersHover(idx, false)}
                                                         className={'custom-filter-no-wrap'}
                                                    >
                                                        <span className={'custom-filter-label'}>&nbsp;{filter.widgetState.globalLvaFilters[idx].lvaFilterName}&nbsp;&nbsp;</span>
                                                        {globalLvaFiltersHover[idx] && editable &&
                                                            <div style={{display: 'inline-block'}}>
                                                                <a href="#" onClick={(e) => {startFilteringWidget(filter, 'global', idx);e.preventDefault();}}>edit</a>&nbsp;|&nbsp;
                                                                <a href="#" onClick={(e) => {removeGlobalLvaFilterFromList(filter, fieldConfig, idx);e.preventDefault();}}>del</a>
                                                            </div>
                                                        }
                                                    </div>
                                                </Col>
                                            )
                                        )
                                    }
                                </AccordionDetails>
                            </Accordion>
                            <Accordion square expanded={sideMenuDetail['lvaFilters']} onChange={() => {toggleSideMenuDetail('lvaFilters')}}>
                                <AccordionSummary aria-controls='panel-content-lvaFilters' id='paneld-header-lvaFilters' className="subMenuHeading p-0">
                                    <p className="subMenuHeading m-0">
                                        {sideMenuDetail['lvaFilters'] === true && <ArrowDropDownIcon className={classes.arrowIcon} />}
                                        {sideMenuDetail['lvaFilters'] !== true && <ArrowRightIcon className={classes.arrowIcon} />}
                                        <span className="mt-4">Result-specific Filters</span>
                                    </p>
                                </AccordionSummary>
                                <AccordionDetails className="appTextColor">
                                    {lvaWidgetState && lvaWidgetState.lvaFilters && Object.keys(lvaWidgetState.lvaFilters).length > 0 &&
                                        lvaWidgetState.lvaFilters.map(
                                            (lvaFilter, idx) => (
                                                <Col key={'lvaFilter_' + idx} style={{paddingLeft: '10px'}}>
                                                    <div onMouseEnter={(e) => updateLvaFiltersHover(idx, true)}
                                                         onMouseLeave={(e) => updateLvaFiltersHover(idx, false)}
                                                         className={'custom-filter-no-wrap'}
                                                    >
                                                        <input type="checkbox" id={'cb_lvaFilter_' + idx} style={{verticalAlign: 'middle'}}
                                                               checked={filter.state?.lvaFilterCheckboxes[idx] || false}
                                                               onChange={(e)=> {props.setLvaFilterCheckbox(filter, idx)}}
                                                        />
                                                        <span htmlFor={'cb_lvaFilter_' + idx} className={'custom-filter-label'}>
                                                                        &nbsp;{lvaWidgetState.lvaFilters[idx].lvaFilterName}&nbsp;&nbsp;</span>
                                                        {lvaFiltersHover[idx] && editable &&
                                                            <div style={{display: 'inline-block'}}>
                                                                <a href="#" onClick={(e) => {startFilteringWidget(filter, 'custom', idx);e.preventDefault();}}>edit</a>&nbsp;|&nbsp;
                                                                <a href="#" onClick={(e) => {props.removeLvaFilterFromList(filter, fieldConfig, idx);e.preventDefault();}}>del</a>
                                                            </div>
                                                        }
                                                    </div>
                                                </Col>
                                            )
                                        )
                                    }
                                </AccordionDetails>
                            </Accordion>
                            </Col>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion square expanded={sideMenuDetail['lvaReport']} onChange={() => toggleSideMenuDetail('lvaReport')}>
                        <AccordionSummary aria-controls="panel-lvaReport" className="subMenuHeading p-0">
                        <div className="side-menu-item sidebar-tour-width">
                            {sideMenuDetail['lvaReport']===true && <ArrowDropDownIcon className={classes.arrowIcon} />}
                            {sideMenuDetail['lvaReport']!==true && <ArrowRightIcon className={classes.arrowIcon} />}
                            <span>Variation Landscape</span>
                        </div>
                        </AccordionSummary>
                        <AccordionDetails className="appTextColor">
                            <Col style={{paddingLeft: '20px'}}>

                            {Object.keys(vmIds).length > 0 && Object.keys(vmIds).map((key, index)=>{return(
                            <Accordion square expanded={sideMenuDetail[index+"vm"]} key={"create"+index}>
                                <AccordionSummary aria-controls={'panelvm-'+index} className="subMenuHeading p-0"
                                                  disabled={!vmIds[key].RESULTS || vmIds[key].RESULTS === 0 || vmIds[key].RESULTS === '0'}>
                                    <div className="side-menu-item sidebar-tour-width" onClick={() => toggleSideMenuDetail(index+"vm")}>
                                        <p className="subMenuHeading m-0">
                                        {sideMenuDetail[index+"vm"]===true && <ArrowDropDownIcon className={classes.arrowIcon} />}
                                        {sideMenuDetail[index+"vm"]!==true && <ArrowRightIcon className={classes.arrowIcon} />}
                                        {vmIds[key].QUERY_ID} ({vmIds[key].RESULTS})
                                        &nbsp;
                                            {vmIds[key].RESULTS && vmIds[key].RESULTS > 0 && reportId === undefined &&
                                            <a onClick={(e)=>{e.stopPropagation();setReportQuerySeqID(vmIds[key].QUERY_ID);setOpenCreateUI(!openCreateUI);}}
                                            >Create</a>
                                            }
                                        </p>
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails className="appTextColor">
                                    {vmReportNames && vmReportNames[parseSeqId(vmIds[key].QUERY_ID)] &&
                                        Object.keys(vmReportNames[parseSeqId(vmIds[key].QUERY_ID)]).map((reportName, index) =>{
                                        return(
                                            <Col key={reportName+"-"+index} style={{paddingLeft: '0px'}}>
                                            <div className="side-menu-item filter-no-wrap"
                                            onMouseOver={() => {setShowDex(reportName)}}
                                            onMouseLeave={() => {setShowDex('')}}
                                            >
                                                <span className={'custom-filter-label'}>{reportName}&nbsp;&nbsp;</span>
                                                {showDex === reportName && vmReportNames[parseSeqId(vmIds[key].QUERY_ID)][reportName].ready &&
                                                <span>
                                                    <Link 
                                                    target="_blank"
                                                    to={{ 
                                                        pathname: "/vmreport/"+resid.match(/\d+/)[0]+"/"+reportName,
                                                        state: {}
                                                    }}>view</Link>
                                                    <span>
                                                    <span> | </span>
                                                    <a onClick={(e)=>{variationAnalysisOps('download', reportName); e.preventDefault();}}>download</a>
                                                    <span> | </span>
                                                    <a onClick={(e)=>{setDeleteReportName(reportName); setOpenDelUI(!openDelUI);e.preventDefault();}}>del</a>
                                                    </span>
                                                </span>
                                                }
                                            </div>
                                            </Col>
                                        )
                                     }
                                    )}
                                </AccordionDetails>
                            </Accordion>
                            );})}
                        </Col>

                        </AccordionDetails>
                    </Accordion>
                    </Col>
                </AccordionDetails>
            </Accordion>

            {openDelUI == true &&
            <div>
                <div className= "popupBackdrop" onClick={()=>{setOpenDelUI(!openDelUI)}}> 
                </div>
                <div className="saveAbPopup">
                    <div>
                        <Link href="#" className={"p-2 appTextColor"}><IoClose onClick={()=>{setOpenDelUI(!openDelUI)}} /></Link>
                    </div>
                    <div className="ABjumbotron alignCenter App abHeight140" width={"400px"}>
                        <br></br>
                            <span className="abDelPopupHeader" width={"100%"}>Are you sure?</span>
                        <br></br>
                        <div className="paddingTop15pxFix">
                            <Button className={"cancelButtonClass "} onClick={()=>{setOpenDelUI(!openDelUI)}}>Cancel</Button>
                            <span className="ml-1"></span>
                            <Button className={"submitButtonClass"} onClick={()=>{delvmReport()}}>Yes</Button>
                        </div>
                    </div>
                </div>
            </div>	
            }

            {openCreateUI === true &&
                <div>
                    <div className="popupBackdrop" style = {{opacity : '-0.5'}} onClick={() => setOpenCreateUI(!openCreateUI)}>
                    </div>
                    <Modal  contentClassName={classes.modalClass} show={openCreateUI} onHide={() => { setOpenCreateUI(!openCreateUI) }}  size="xl" centered>
                        <div style={{ marginTop: '12px', marginBottom: '20px' }} >
                            <Link href="#" className="appTextColor cross">
                                <CloseIcon onClick={() => { setOpenCreateUI(!openCreateUI) }} />
                            </Link>
                        </div>
                        <div className="ABjumbotron alignCenter App" style={{ height: '85%' }}>
                            <br></br>
                            <br></br>
                            <span className="abDelPopupHeader" width={"100%"}><h1 className="tagSize">Variation Landscape Report</h1></span>
                            <p className="variationClass">
                                <span style={{ fontFamily: 'Helvetica, Helvetica neue,arial,sans-seri', fontWeight: '200', marginBottom: '15px', wordSpacing: '-2px' }}>Your Variation Landscape Report for {reportQuerySeqID} is generated in the background. You can continue to work on your results. As soon as your download is ready, we will let you know.</span>
                            </p>
                            <div className="paddingTop15pxFix" style = {{ paddingTop: '20px', paddingBottom: '20px'}}>
                                <Button className="cancelBtnClass" style={{ padding: '10px', backgroundColor: '#EEE !important', color: '#777 !important', border: 'solid 1px #CCC', borderRadius: 'inherit', marginLeft: '514px' }} onClick={() => { setOpenCreateUI(!openCreateUI) }}>Cancel</Button>
                                <Button className="createReportClass" style={{ backgroundColor: '#337ab7', border: 'solid 1px #2269a6', float: 'right', borderRadius: 'inherit', color: 'white', padding: '10px' }} onClick={() => { variationLandscapeReport(reportQuerySeqID, vmReportNames) }}>Create Report</Button>
                                <Link id="vmReport" style={{ display: 'none' }} target="_blank"
                                      to={{pathname: '/vmreport/' + resid.match(/\d+/)[0] + '/' + vmReportName}}></Link>
                                {/* <span className="ml-1"></span> */}
                            </div>
                        </div>
                    </Modal>
                </div>
            }
            
            <VmFilterModal
                key="vmfiltermodal"
                showModal={addFilterPopup} 
                closeModal={closeVmFilterModal} 
                callback={saveFilter}
                fieldConfig={fieldConfig}
                lvaWidgetState={lvaWidgetState}
                vmIds={vmIds}
                filter={filter}
                sideMenuDetail={sideMenuDetail}
            ></VmFilterModal>

        </div>
    );
}

export default SequenceVariationDiscover;
