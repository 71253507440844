import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal'
import { useTranslation } from "react-i18next";
import Dropdown from "react-bootstrap/Dropdown";
import { FaCaretDown, FaPlus, FaMinus } from "react-icons/fa";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import ReactHtmlParser from "react-html-parser";
import { get } from '../../helpers/fetchServicesMethods';
import DropdownToggle from 'react-bootstrap/esm/DropdownToggle';
import DropdownItem from 'react-bootstrap/esm/DropdownItem';
import DatePicker from "../Fields/DatePicker";
//import DatePicker from "react-datepicker";
import DropdownMenu from 'react-bootstrap/esm/DropdownMenu';
import { url } from '../../reducers/url';
import { useHistory } from 'react-router-dom';
import _ from "lodash";
//import {getWelcomePage} from '../../services/home'
import CloseIcon from '@material-ui/icons/Close';
import Link from '@material-ui/core/Link';


const useStyles = makeStyles((theme) => ({
    modalHeader: {
        borderBottom: 'none !important',
        paddingTop: '11px',
        paddingRight: '4px',
        marginTop: '-7px',

    },
    footerDiv: {
        padding: '0 30px',
        marginTop: '-20px',
        marginRight: '-31px',
    },
    buttonStyle: {
        float: 'right',
        textTransform: 'none',
        margin: '4px',
        backgroundColor: '##DB862D !important',
        border: '1px solid ##DB862D !important',
        marginTop: '4px',

    },
    buttonStyleCancel: {
        float: 'right',
        textTransform: 'none',
        margin: '4px',
        color: 'white',
        backgroundColor: '#008EC5 !important',
        border: '1px solid #1F4E79 !important',
        borderColor: '#1F4E79',


    },
    modalBoxContent: {
        maxHeight: '675px',
    },
    modalClassContent: {
        position: 'absolute',
        width: '96%',
        height: 'auto',
        top: '30%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        transform: 'translate(-50%, -50%)'
    },
    colorContainer: {
        backgroundColor: '#EEEEEE',
        marginTop: '-38px',
        // marginLeft: 0px;
        paddingTop: '28px',
        paddingBottom: '65px',
        marginLeft: '10px',
        marginRight: '10px',
        paddingRight: '10px',
        borderRadius: '5px',

    },
    modalBody: {
        textAlign: 'center',
        marginBottom: '8px'
    }
}));

const operatorSelection = {
    string: [
        { value: "CT", label: 'contains', id: 'CT' },
        { value: "EQ", label: 'equals', id: 'EQ' },
        { value: "NCT", label: 'does not contain', id: 'NCT' },
        { value: "START_WITH", label: 'starts with', id: 'START_WITH' },
        { value: "NEQ", label: 'does not equal', id: 'NEQ' },
        { value: "NOT_START_WITH", label: 'not starts with', id: 'NOT_START_WITH' },
        { value: "EQ_OR", label: 'equal to one of', id: 'EQ_OR' },
    ],
    string_frame: [
        { value: "MATCH", label: 'is', id: 'MATCH' },
        { value: "NMATCH", label: 'is not', id: 'NMATCH' }
    ],
    date: [
        { value: "BEF", label: 'before ', id: 'BEF' },
        { value: "OBEF", label: 'on or before', id: 'OBEF' },
        { value: "ON", label: 'on', id: 'ON' },
        { value: "NON", label: 'not on', id: 'NON' },
        { value: "OAFT", label: 'on or after', id: 'OAFT' },
        { value: "AFT", label: 'after', id: 'AFT' }
    ],
    int: [
        { value: "LTE", label: 'less than or equal', id: 'LTE' },
        { value: "LT", label: 'less than', id: 'LT' },
        { value: "EQ", label: 'equals', id: 'EQ' },
        { Value: "NEQ", label: 'does not equal', id: 'NEQ' },
        { value: "EQ_OR", label: 'equal to one of', id: 'EQ_OR' },
        { value: "GT", label: 'greater than', id: 'GT' },
        { value: "GTE", label: 'greater than or equal', id: 'GTE' },
    ],
    float: [
        { value: "IS_TRUE", label: 'yes', id: 'IS_TRUE' },
        { value: "IS_FALSE", label: 'no', id: 'IS_FALSE' },
    ],
};



const toolTipStyles = {
    tooltip: {
        width: "auto",
        //height: "40px",
        fontSize: '12px',
        textAlign: 'justify',
        padding: "8px 14px 8px 14px",
        boxShadow: "0px 1px 7px #888",
        backgroundColor: '#777',
        color: '#FFF'
    }
};



const CustomTooltip = withStyles(toolTipStyles)(Tooltip);

const AdvancedFilterModal = (props) => {
    const classes = useStyles();
    const { t, i18n } = useTranslation('common');
    const [checkedValueSeqdb, setCheckedValueSeqdb] = useState(false);
    const [checkedValueWorkflow, setCheckedValueWorkflow] = useState(false);
    const [checkedValueFolder, setCheckedValueFolder] = useState(false);
    const history = useHistory();
    const [selectedFieldTypes, setSelectedFieldTypes] = useState(['string']);
    const [selectedType, setSelectedType] = useState('string');
    const [error, setError] = useState('');
    const [isApplyClicked, setIsApplyClicked] = useState(false);
    const [startingValue, setStartingValue] = useState([{ field: 'DlShareable/text_label', field_type: 'string', operator: 'CT', value: '' }]);
    const [currentDate, setCurrentDate] = useState(new Date());
    const {
        setAdvFilterModalShowed,
    } = props;

    function foldercheckboxselected(e) {
        setCheckedValueFolder(e.target.checked);
    }
    function seqdbcheckboxselected(e) {
        setCheckedValueSeqdb(e.target.checked);
    }
    function workFlowcheckboxselected(e) {
        setCheckedValueWorkflow(e.target.checked);
    }

    const onApply = async () => {
        setIsApplyClicked(true); // Button is clicked
        if (!checkedValueSeqdb && !checkedValueWorkflow && !checkedValueFolder) {
            setError('You must choose at least one type of object to search.');
            return;
        }
        let scope;
        let ver;
        //create the filter object and then calling of api is done in parent Searchmanagement.js
        const filterArray = startingValue.map((filter) => {
            return {
                field: filter.field,
                field_type: filter.field_type,
                operator: filter.operator,
                value: filter.value,
            };
        });
        const objClass = [];
        if (checkedValueSeqdb) {
            objClass.push('DlSeqdb');
        }
        if (checkedValueWorkflow) {
            objClass.push('DlWorkflow');
        }
        if (checkedValueFolder) {
            objClass.push('DlFolder');
        }
        const filterJSON = buildFilterString(filterArray, objClass);
        setError('');
        props.advfilterapply(filterJSON);
        //props.advfilterapply("('filters':!(('O':'CT','P':'DlShareable/text_label','V':'www','id':'f1')),'obj_class':!('DlWorkflow'),'scope':1,'ver':2)");
        props.onHide();
    }

    const buildFilterString = (filters, objClass) => {
        const filterArray = filters.map((filter, index) => {
            if (filter.field === 'DlSeqdb/seq_type' || filter.field === 'DlWorkflow/status') {
                return `('O':'${filter.operator}','P':'${filter.field}','V':!(${filter.value}),'id':'f${index + 1}')`;
            } else if(filter.field === 'DlWorkflow/type') {
                return `('O':'${filter.operator}','P':'${filter.field}','V':!(${filter.value}),'id':'f${index + 1}')`;
            } else {
                // Construct the filter objects for each filter in the filters array
                return `('O':'${filter.operator}','P':'${filter.field}','V':'${filter.value}','id':'f${index + 1}')`;
            }
        });
        const objClassString = objClass.map((cls) => `'${cls}'`).join(',');
        const filterString = `('filters':!(${filterArray.join(',')}),'obj_class':!(${objClassString}),'scope':1,'ver':2)`;
        return filterString;
    };

    const initialStartingValue = [
        { field: 'DlShareable/text_label', field_type: 'string', operator: 'CT', value: '' }
    ];
    const initialCheckedValues = {
        seqdb: false,
        workflow: false,
        folder: false
    };

    const resetState = () => {
        setCheckedValueSeqdb(initialCheckedValues.seqdb);
        setCheckedValueWorkflow(initialCheckedValues.workflow);
        setCheckedValueFolder(initialCheckedValues.folder);
        setSelectedFieldTypes(['string']);
        setSelectedType('string');
        setError('');
        setIsApplyClicked(false);
        setStartingValue([...initialStartingValue]);
    };

    const closeModal = () => {
        setAdvFilterModalShowed(false);
    }
    const onChangeReset = async () => {
        resetState();
        setAdvFilterModalShowed(false);
        window.location.reload();
    };

    function getFieldType(idx) {
        if (startingValue && startingValue[idx] && startingValue[idx].field_type) {
            return startingValue[idx].field_type;
        }
        return '';
    }

    const handlePropertyChange = (value, idx, dataType) => {
        setStartingValue((prevStartingValue) => {
            const updatedStartingValue = [...prevStartingValue];
            updatedStartingValue[idx].field = value;
            updatedStartingValue[idx].field_type = dataType;

            setSelectedFieldTypes((prevSelectedFieldTypes) => {
                const updatedSelectedFieldTypes = [...prevSelectedFieldTypes];
                updatedSelectedFieldTypes[idx] = dataType;
                return updatedSelectedFieldTypes;
            });
            if (dataType !== 'date') {
                updatedStartingValue[idx].value = '';
            }

            if (dataType === 'float') {
                updatedStartingValue[idx].operator = 'IS_TRUE';
                updatedStartingValue[idx].value = '';
            } else if (dataType === 'int') {
                updatedStartingValue[idx].operator = 'LTE';
            } else if (dataType === 'date') {
                updatedStartingValue[idx].operator = 'BEF';
                updatedStartingValue[idx].value = new Date().toISOString().split('T')[0];
            } else if (dataType === 'string_frame') {
                updatedStartingValue[idx].operator = 'MATCH';
            } else if (dataType === 'string') {
                updatedStartingValue[idx].operator = 'CT';
            }

            return updatedStartingValue;
        });
        setSelectedType(dataType);
    };


    const handleOperatorChange = (e, idx) => {
        const newOperator = e.target.value;
        setStartingValue((prevStartingValue) => {
            const updatedStartingValue = [...prevStartingValue];
            updatedStartingValue[idx] = {
                ...updatedStartingValue[idx],
                operator: newOperator,
            };
            if (newOperator === 'date') {
                updatedStartingValue[idx].value = new Date();
            } else {
                updatedStartingValue[idx].value = '';
            }

            return updatedStartingValue;
        });
    };

    const handleValueChange = (value, idx) => {
        setStartingValue((prevStartingValue) => {
            const updatedStartingValue = [...prevStartingValue];
            if (!updatedStartingValue[idx]) {
                updatedStartingValue[idx] = {};
            }
            updatedStartingValue[idx] = {
                ...updatedStartingValue[idx],
                value: value,
            };
            return updatedStartingValue;
        });
    };

    const handleDatePicker = (date, idx) => {
        setStartingValue((prevStartingValue) => {
            const updatedStartingValue = [...prevStartingValue];
            const fieldType = getFieldType(idx);

            if (fieldType === 'date') {
                updatedStartingValue[idx] = {
                    ...updatedStartingValue[idx],
                    value: date.toISOString().split('T')[0],
                };
                setCurrentDate(date);
            }
            return updatedStartingValue;
        });
    };

    const widgetDelAtom = (idx) => {
        startingValue.splice(idx, 1);
        setStartingValue([...startingValue]);
    };

    const widgetAddAtom = (idx) => {
        const newElement = { field: 'DlShareable/text_label', field_type: 'string', operator: 'CONTAINS', value: '' };
        startingValue.splice(idx + 1, 0, newElement);
        setStartingValue([...startingValue]);
    };

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcente"
            centered
        // contentClassName={classes.modalClassContent}
        // className={classes.modalBoxContent}
        >
            <div className="large-font">
                <Link href="#" onClick={(e) => e.preventDefault()} className="pull-right">
                    <CloseIcon onClick={closeModal}/>
                </Link>
            </div>
            <Modal.Body className={classes.modalBody}>
                <div >
                    <table>
                        <tbody>
                            <tr>
                                <td colSpan="6" className="filter-widget-top clearfix">
                                    <div className="pull-left filter-widget-field-middle custom-filter-label">
                                        <b> Show only: </b> &nbsp;&nbsp;&nbsp;
                                        <input id="seqdb" type="Checkbox" checked={checkedValueSeqdb || false}
                                            style={{ verticalAlign: 'middle' }}
                                            onChange={seqdbcheckboxselected} />
                                        <span htmlFor="seqdb">&nbsp;Seqdb</span>
                                        &nbsp;&nbsp;&nbsp;
                                        <input id="WorkFlow" type="Checkbox" checked={checkedValueWorkflow || false}
                                            style={{ verticalAlign: 'middle' }}
                                            onChange={workFlowcheckboxselected} />
                                        <span htmlFor="WorkFlow">&nbsp;WorkFlow</span>
                                        &nbsp;&nbsp;&nbsp;
                                        <input id="folder" type="Checkbox" checked={checkedValueFolder || false}
                                            style={{ verticalAlign: 'middle' }}
                                            onChange={foldercheckboxselected} />
                                        <span htmlFor="WorkFlow">&nbsp;Folder</span>
                                    </div>
                                </td>
                            </tr>

                            {startingValue.map((atom, idx) => (
                                <tr key={idx}>
                                    <div>
                                        <td>
                                            <div className="control-spacer">
                                                <select onChange={(e) => handlePropertyChange(e.target.value, idx, e.target.options[e.target.selectedIndex].getAttribute('data-type'))} style={{ height: '30px', width: '200px', fontFamily: 'Helvetica Neue", Helvetica, Arial, sans-serif', fontSize: '15px', color: '#777777', fontWeight: '400'}}
                                                    value={atom.field}>
                                                    <optgroup label='common' >
                                                        <option value='DlShareable/text_label' label='name' data-type='string'>name</option>
                                                        <option value='DlShareable/description' label='description' data-type='string'>description</option>
                                                        <option value='DlShareable/absolute_path' label='location' data-type="string">location</option>
                                                        <option value='DlShareable/_is_owned_by_me' label='owned by me?' data-type="float">owned by me?</option>
                                                        <option value="DlShareable/first_name" label="owner's first name" data-type='string'>owner's first name</option>
                                                        <option value='DlShareable/last_name' label="owner's last name" data-type='string'>owner's last name</option>
                                                        <option value='DlShareable/email' label="owner's email" data-type='string'>owner's email</option>
                                                        <option value='DlShareable/login_name' label="owner's login id" data-type='string'>owner's login id</option>
                                                        <option value='DlShareable/create_time' label="create time" data-type='date'>create time</option>
                                                    </optgroup>
                                                    <optgroup label='seqdb'>
                                                        <option value='DlSeqdb/def_id' label='def_id' data-type='string'>def_id</option>
                                                        <option value='DlSeqdb/seq_type' label='sequence type' data-type='string_frame'>sequence type</option>
                                                        <option value='DlSeqdb/nb_sequences' label='number of sequences' data-type='int'>number of sequences</option>
                                                        <option value='DlSeqdb/annotation_fields' label='annotation fields' data-type='string'>annotation fields</option>
                                                        <option value='DlSeqdb/max_seq_len' label='maximum sequence length' data-type='int'>maximum sequence length</option>
                                                        <option value='DlSeqdb/min_seq_len' label='minimum sequence length' data-type='int'>minimum sequence length</option>
                                                        <option value='DlSeqdb/avg_seq_len' label='average sequence length' data-type='int'>average sequence length</option>
                                                    </optgroup>
                                                    <optgroup label='workflow'>
                                                        <option value='DlWorkflow/workflow_id' label='id' data-type='int'>id</option>
                                                        <option value='DlWorkflow/type' label='workflow type' data-type='string_frame'>workflow type</option>
                                                        <option value='DlWorkflow/total_nb_results' label='number of results' data-type='int'>number of results</option>
                                                        <option value='DlWorkflow/submit_time' label='launch time' data-type='date'>launch time</option>
                                                        <option value='DlWorkflow/finish_time' label='finish time' data-type='date'>finish time</option>
                                                        <option value='DlWorkflow/status' label='run status' data-type='string_frame'>run status</option>
                                                    </optgroup>
                                                </select>
                                            </div>
                                        </td>
                                        <td className='filter-widget-operator '>
                                        <div className="control-spacer">
                                            <select style={{ height: '30px', width: '200px', margin: '10px', fontFamily: 'Helvetica Neue", Helvetica, Arial, sans-serif', fontSize: '14px', color: '#777777', fontWeight: '400'}} 
                                                className='modalClassContent'
                                                onChange={(e) => handleOperatorChange(e, idx)}
                                                value={startingValue[idx].operator}
                                            >
                                                {operatorSelection[getFieldType(idx)].map((option) => (
                                                    <option
                                                        key={option.value}
                                                        value={option.value}
                                                        label={option.label}
                                                    >
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </select>
                                            </div>
                                        </td>
                                        <td className="filter-widget-value">
                                            <div className="control-spacer" >
                                                {(getFieldType(idx) === 'string' || getFieldType(idx) === 'int') &&
                                                    <CustomTooltip arrow title="Query multiple items by putting each of them on a new line" placement="top">
                                                        <textarea style={{width: '200px', height: '10px', borderColor: 'grey', position:'static', fontFamily: 'Helvetica Neue", Helvetica, Arial, sans-serif', fontSize: '14px', color: '#777777', fontWeight: '1000'}}
                                                                 className={'filter-widget-input-textarea '}
                                                                  placeholder="Text" onChange={(e) => handleValueChange(e.target.value, idx)}
                                                                  value={startingValue[idx].value}></textarea>
                                                    </CustomTooltip>
                                                }
                                                {getFieldType(idx) === 'date' &&
                                                    <div>
                                                        <DatePicker
                                                        customize={true}
                                                        margin="normal"
                                                        id="dateVal"
                                                        name="dateVal"
                                                        label="Date picker inline"
                                                        dateFormat="yyyy-MM-dd"
                                                        value={getFieldType(idx) === 'date' ? currentDate : new Date(currentDate.toISOString().split('T')[0])}
                                                        inputVariant="outlined"  
                                                        className={'patent-date-picker1'}
                                                        onChange={(date) => handleDatePicker(date, idx)}
                                                        />
                                                    </div>
                                                }
                                                {getFieldType(idx) === 'string_frame' && startingValue[idx].field === 'DlWorkflow/status' && (
                                                    <td>
                                                        <select onChange={(e) => handleValueChange([...e.target.options].filter(option => option.selected).map(option => option.value), idx)} multiple='multiple' style={{ height: '70px', width: '190px',fontFamily: 'Helvetica Neue", Helvetica, Arial, sans-serif', fontSize: '14px', color: '#777777', fontWeight: '400'}} value={startingValue[idx].value}>
                                                            <option value="ARCHIVED" label='ARCHIVED'>ARCHIVED</option>
                                                            <option value="CANCELLED" label='CANCELLED'>CANCELLED</option>
                                                            <option value="FAILED" label='FAILED'>FAILED</option>
                                                            <option value="FINISHED" label=''>FINISHED</option>
                                                            <option value="MISSING" label=''>MISSING</option>
                                                            <option value="STILL_RUNNING" label=''>STILL_RUNNING</option>
                                                            <option value="UNKNOWN" label=''>UNKNOWN</option>
                                                            <option value="WAITING" label=''>WAITING</option>
                                                        </select>
                                                    </td>
                                                )}
                                                {getFieldType(idx) === 'string_frame' && startingValue[idx].field === 'DlSeqdb/seq_type' && (
                                                    <td>
                                                        <select onChange={(e) => handleValueChange([...e.target.options].filter(option => option.selected).map(option => option.value), idx)} multiple='multiple' style={{ height: '70px', width: '200px',fontFamily: 'Helvetica Neue", Helvetica, Arial, sans-serif', fontSize: '14px', color: '#777777', fontWeight: '400'}} value={startingValue[idx].value}>
                                                            <option value="PROTEIN" label='amino acid'>amino acid</option>
                                                            <option value="NUCCS" label='color space'>color space</option>
                                                            <option value="MIX" label='mixed'>mixed</option>
                                                            <option value="NUCLEOTIDE" label='nucleotide'>nucleotide</option>
                                                            <option value="NUCPAT" label='nucleotide pattern'>nucleotide pattern</option>
                                                            <option value="PROPAT" label='protein pattern'>protein pattern</option>
                                                            <option value="UNK" label='unknown'>unknown</option>
                                                        </select>
                                                    </td>
                                                )}
                                                {getFieldType(idx) === 'string_frame' && startingValue[idx].field === 'DlWorkflow/type' && (
                                                    <td>
                                                        <select onChange={(e) => handleValueChange([...e.target.options].filter(option => option.selected).map(option => option.value), idx)} multiple='multiple' style={{ height: '70px', width: '190px',fontFamily: 'Helvetica Neue", Helvetica, Arial, sans-serif', fontSize: '14px', color: '#777777', fontWeight: '400'}} value={startingValue[idx].value}>
                                                            <option value="GqWfIpSearch" label='IP Search'>'GqWfIpSearch' : IP</option>
                                                            <option value="GqWfIpSearchVM" label='Variation'>'GqWfIpSearchVM' : Variation</option>
                                                            <option value="GqWfABIpSearch" label='Antibody'>'GqWfABIpSearch' : Antibody</option>
                                                            <option value="GqWfAlndbCopy" label='Save Filtered Result'>'GqWfAlndbCopy' : Save Filtered Result</option>
                                                            <option value="GqWfMerge" label='Merge Result'>'GqWfMerge' : Merge Result</option>
                                                            <option value="GqWfSearchFT" label='Document'>'GqWfSearchFT' : Document</option>
                                                        </select>
                                                    </td>
                                                )}
                                            </div>
                                        </td>
                                        <td className={"filter-widget-adddel "}>
                                            {startingValue.length > 1 &&
                                                <CustomTooltip arrow title="Remove this Condition" placement="bottom">
                                                    <div className="control-spacer plus-min-button custom-filter-label"
                                                        onClick={() => widgetDelAtom(idx)}>
                                                        <FaMinus />
                                                    </div>
                                                </CustomTooltip>
                                            }
                                        </td>
                                        <td className={"filter-widget-adddel "}>
                                            <CustomTooltip arrow title="Add Condition below" placement="bottom">
                                                <div className="control-spacer plus-min-button custom-filter-label"
                                                    onClick={() => widgetAddAtom(idx)}>
                                                    <FaPlus />
                                                </div>
                                            </CustomTooltip>
                                        </td>
                                    </div>
                                </tr>
                            ))}
                            <tr>
                                <td>
                                    <div className="pull-left filter-widget-field-middle custom-filter-label">
                                        <b> Limit the search area to: </b> &nbsp;&nbsp;&nbsp;
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div className="pull-left filter-widget-field-middle custom-filter-label">
                                        <Dropdown className="btn-group">
                                            <DropdownToggle className="btn btn-dropdown btn-dropdown-dates">
                                                <div className="clearfix">
                                                    <div className="pull-left custom-filter-label">
                                                        All Data
                                                    </div>
                                                    <div style={{ position: 'absolute', right: '2px' }}>
                                                        <FaCaretDown />
                                                    </div>
                                                </div>
                                            </DropdownToggle>
                                            <DropdownMenu className="dropdown-menu dropdown-menu-dates custom-filter-label">
                                                <DropdownItem className="filter-widget-menu-item" style={{ color: '#db862c' }}>
                                                    All Data
                                                </DropdownItem>
                                            </DropdownMenu>
                                        </Dropdown>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="6" className="clearfix">
                                    <div className="clearfix filter-widget-bottom">
                                        <button className={'pull-right normal-button gqblue'} onClick={(e) => onApply()}>Apply</button>
                                        <button className={"pull-right normal-button grey"} onClick={(e) => onChangeReset()}>Reset</button>
                                    </div>
                                </td>
                            </tr>
                            <div>
                                {isApplyClicked && error && <div style={{ color: 'red' }}>{error}</div>}
                            </div>
                        </tbody>
                    </table>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default AdvancedFilterModal;
