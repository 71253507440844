import { post, get } from '../helpers/fetchServicesMethods';
import { toast } from 'react-toastify';
import { url } from '../reducers/url';
import PubSub from 'pubsub-js';

function showLoader() {
    PubSub.publish('msg', true);
}

function hideLoader() {
    PubSub.publish('msg', false);
}

export const convertXml = async (data, history, t) => {
    // dispatch(setUser({ GQUSERID: data.GQUSERID, isLoggedIn: true }));
    // const history = useHistory();
    var domParser = new DOMParser();

    var dom = domParser.parseFromString(data.st26input, 'text/xml');
    var x = dom.getElementsByTagName('parsererror').length ? (new XMLSerializer()).serializeToString(dom) : 'all good';
    if (dom.getElementsByTagName('parsererror').length > 0) {
        toast.error(dom.getElementsByTagName('parsererror')[0].getElementsByTagName('div')[0].innerHTML);
      }

    if (dom.getElementsByTagName('parsererror').length) {
        return 'parsererror';
    }
    else {
        var sd = dom.getElementsByTagName('SequenceData');
        var prt = '';
        var dna = '';
        var prtCount = 0;
        var dnaCount = 0;
        if (sd.length == 0) {
            return 'empty';
        }
        for (var i = 0; i < sd.length; i++) {
            var nNode = sd.item(i);
            if (nNode.nodeType == Node.ELEMENT_NODE) {
                var mol = nNode.getElementsByTagName('INSDSeq_moltype');
                var seqId = nNode.getAttribute('sequenceIDNumber');
                var seqTag = nNode.getElementsByTagName('INSDSeq_sequence');
                if (mol.length == 0 || seqId == null || seqTag.length == 0 || seqId.length == 0) {
                    return 'missing';
                }
                else {
                    var moltype = mol.item(0).textContent;
                    var seq = seqTag.item(0).textContent;
                    if (moltype == 'AA' || moltype == 'PRT') {
                        prt += '>SEQ_NO_' + seqId + '\n' + seq + '\n';
                        prtCount++;
                    }
                    if (moltype == 'DNA' || moltype == 'RNA') {
                        dna += '>SEQ_NO_' + seqId + '\n' + seq + '\n';
                        dnaCount++;
                    }
                }

            }
        }

        var x = [prt, dna, prtCount, dnaCount];
        return x;
    }

};

export const convertText = async (data, history, t) => {
    if(!data.st26input.startsWith(">")){
        return 'parsererror';
    }
    else if(data.st26input === ''){
        return 'empty'
    }
    else {
        const big = [];
        var lines = (data.st26input).split('\n');
        let ind = -1;
        for (var line = 0; line < lines.length; line++) {
            if (lines[line].startsWith(">")){
                let [first, ...rest] = lines[line].slice(1).split(' '); // Remove '>' character by slicing the line
                rest = rest.join(' ');
                const arr = [];
                arr.push(first, rest);
                big.push(arr);
                ind++;
            }
            else {
                big[ind].push(lines[line]);
            }
        }
        
        const small = [];
        for (var i = 0; i < big.length; i++){
            const l = big[i].slice(2).join("");
            const nee = big[i].slice(0, 2).concat(l);
            small[i] = nee;
        }      
        return small;
    }
};
async function getAuthInfoSt26(workflowId, history) {

    try {
        let apiurl = url.authInfoSt26;
        if (workflowId) {
            // Comes from the route URL '/antibody/parent_id={parentId}'
            //let db = 'db=wf:' + workflowId + '.resdb&parentId=' + workflowId;
            //apiurl = apiurl.replace('db=', db);
        }
        showLoader();
        return await get(apiurl, history)
            .then((response) => {
                hideLoader();
                return response;
            })
            .catch((error) => {
                hideLoader();
                // toast.error('Failed to change password');
                console.log("error::", error);

            });
    } catch (error) {
        toast.error(error.response_content.message);
        console.error(error, "errors");
    }

}
 
export const convertingThreeToOne = async (data, history, t) => {
    const a = {
        Ala: 'A', Arg: 'R', Asn: 'N', Asp: 'D', Cys: 'C', Gln: 'Q', Glu: 'E', Gly: 'G', His: 'H',
        Ile: 'I', Leu: 'L', Lys: 'K', Met: 'M', Phe: 'F', Pro: 'P', Ser: 'S', Thr: 'T', Trp: 'W',
        Tyr: 'Y', Val: 'V', Xaa: 'X', Glx: 'Z', Asx: 'B'
    };

    if (data.st26input.startsWith(">")) {
        return 'parsererror';
    } else if (data.st26input === '') {
        return 'empty';
    }

    let new_file = "";
    let lines = data.st26input.trim().split('\n');
    let isInSequence = false;
    let sequence = '';

    for (let line of lines) {
        line = line.trim();

        if (line.startsWith('<')) {
            if (isInSequence && sequence.length > 0) {
                for (let i = 0; i < sequence.length; i += 50) {
                    new_file += sequence.slice(i, i + 50) + '\n';
                }
                sequence = '';
                isInSequence = false;
            }
            new_file += line + '\n';
            isInSequence = line.startsWith('<400>');
        } else if (/^\d+$/.test(line)) {
            continue;
        } else if (isInSequence || /^[A-Za-z\s\*]+$/g.test(line)) {
            let cleanedLine = line.replace(/\d+/g, '').trim();
            let chunks = cleanedLine.match(/[A-Z][a-z]*|\*{1,3}/g);
            
            if (chunks) {
                for (let chunk of chunks) {
                    if (chunk === '***') {
                        sequence += '*';
                    } else if (chunk.startsWith('*')) {
                        sequence += '*';
                    } else if (a.hasOwnProperty(chunk)) {
                        sequence += a[chunk];
                    } else {
                        sequence += chunk;
                    }
                }
                isInSequence = true;
            }
        } else {
            new_file += line + '\n';
        }
    }

    if (isInSequence && sequence.length > 0) {
        for (let i = 0; i < sequence.length; i += 50) {
            new_file += sequence.slice(i, i + 50) + '\n';
        }
    }

    return new_file.trim();
}

export const convertingSingleToThree = async (data, history, t) => {
    const a = {
        A: 'Ala', R: 'Arg', N: 'Asn', D: 'Asp', C: 'Cys', Q: 'Gln', E: 'Glu', G: 'Gly', H: 'His',
        I: 'Ile', L: 'Leu', K: 'Lys', M: 'Met', F: 'Phe', P: 'Pro', S: 'Ser', T: 'Thr', W: 'Trp',
        Y: 'Tyr', V: 'Val', X: 'Xaa',
    };
 
    if (data.st26input.startsWith(">")) {
        return 'parsererror';
    } else if (data.st26input === '') {
        return 'empty';
    }
 
    let new_file = '';
    const lines = data.st26input.split('\n');
    let shouldConvert = false;
 
    for (let i = 0; i < lines.length; i++) {
        let line = lines[i];
 
        if (line.startsWith('<400>')) {
            shouldConvert = true;
            new_file += line + '\n';
            continue;
        }
 
        if (shouldConvert && /^[A-Z\s]+$/.test(line.trim())) {
            let convertedLine = '';
            for (let j = 0; j < line.length; j++) {
                const codon = line[j];
                if (a[codon]) {
                    convertedLine += a[codon] + ' ';
                } else {
                    convertedLine += codon;
                }
            }
            new_file += convertedLine.trim() + '\n';
        } else {
            new_file += line + '\n';
        }
    }
 
    return new_file;
};

export const convertingST25ToFasta = async (data, history, t) => {
    const sequences = {
        Ala: 'A', Arg: 'R', Asn: 'N', Asp: 'D', Cys: 'C', Gln: 'Q', Glu: 'E', Gly: 'G', His: 'H',
        Ile: 'I', Leu: 'L', Lys: 'K', Met: 'M', Phe: 'F', Pro: 'P', Ser: 'S', Thr: 'T', Trp: 'W',
        Tyr: 'Y', Val: 'V', Xaa: 'X'
    };

    if (data.st26input.startsWith(">")) {
        return 'parsererror';
    } else if (data.st26input === '\n') {
        return 'empty';
    }else if(!data.st26input.includes('<400>')){
        return 'missing';
    }

    // Ensure that data.st26input is always treated as an array of lines
    let lines = [];
    if (typeof data.st26input === 'string') {
        lines = data.st26input.split(/\r?\n/);
    } else if (Array.isArray(data.st26input)) {
        lines = data.st26input;
    }

    let newFile = '';
    let currentSequenceNumber = '';
    let currentSequence = '';
    let currentDescription = '';
    let printedSequences = {};

    for (let line of lines) {
        line = line.trim();

        if (line.startsWith('<210>')) {
            if (currentSequenceNumber !== '') {
                if (!(currentSequenceNumber in printedSequences)) {
                    newFile += '>' + currentSequenceNumber + ' ' + currentDescription + '\n' + wrapSequence(currentSequence) + '\n';
                    printedSequences[currentSequenceNumber] = true;
                }
                currentSequence = ''; // Reset the current sequence after printing
            }
            currentSequenceNumber = line.substring('<210>'.length).trim();
        } else if (line.startsWith('<213>') || line.startsWith('<211>') || line.startsWith('<212>')) {
            continue;
        } 
        // else if (line.startsWith('<120>')) {
        //     currentDescription += line.substring('<120>'.length).trim() + ' '; // Concatenate description until next tag
        //     let nextLineIndex = lines.indexOf(line) + 1;
        //     let nextLine = nextLineIndex < lines.length ? lines[nextLineIndex] : null;
        //     while (nextLine && !nextLine.startsWith('<')) {
        //         currentDescription += nextLine.trim() + ' ';
        //         nextLineIndex++;
        //         nextLine = nextLineIndex < lines.length ? lines[nextLineIndex] : null;
        //     }
        // } Description for the sequence
        else if (line.startsWith('<')) {
            continue;
        } else {
            let chunks = line.split(/\s+/);
            for (let chunk of chunks) {
                if (sequences.hasOwnProperty(chunk)) {
                    currentSequence += sequences[chunk];
                }
            }
        }
    }

    if (currentSequenceNumber !== '') {
        if (!(currentSequenceNumber in printedSequences)) {
            newFile += '>' + currentSequenceNumber + ' ' + currentDescription + '\n' + wrapSequence(currentSequence) + '\n';
            printedSequences[currentSequenceNumber] = true;
        }
    }

    // console.log(newFile);

    return newFile;
};

function wrapSequence(sequence) {
    let wrappedSequence = '';
    while (sequence.length > 0) {
        wrappedSequence += sequence.substring(0, 80) + '\n';
        sequence = sequence.substring(80);
    }
    return wrappedSequence;
}




const st26service = {
    convertXml,
    getAuthInfoSt26,
    convertText,
    convertingThreeToOne,
    convertingSingleToThree,
    convertingST25ToFasta
};

export default st26service;
