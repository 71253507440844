export const documentState = {
    title: "",
    keywords: [],
    seqIds: "",
}

export const ftDocManagerState = {
    open: false,
    documents: {}
}

export const getInitialManagerValue = (managerKey) => {
    const savedValue = localStorage.getItem(managerKey);
    return savedValue !== null ? JSON.parse(savedValue) : ftDocManagerState; // Default value if not present in localStorage
};

export const getInitialDocValue = (patentId) => {
    const savedValue = localStorage.getItem(patentId);
    return savedValue !== null ? JSON.parse(savedValue) : documentState; // Default value if not present in localStorage
};

export const getInitialSeqIdValue = () => {
    const savedValue = localStorage.getItem("seqIds");
    return savedValue !== null ? JSON.parse(savedValue) : []; // Default value if not present in localStorage
};

export const getInitialKeywordValue = () => {
    const savedValue = localStorage.getItem("keywords");
    return savedValue !== null ? JSON.parse(savedValue) : []; // Default value if not present in localStorage
};