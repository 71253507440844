import React, { useState, useEffect } from "react";
import FindInPageIcon from '@material-ui/icons/FindInPage';

import { makeStyles } from "@material-ui/core/styles";
import FullDocManager from "./FullDocManager";


function DocManagerLink(props){;

    const [docMangerIsOpen, setDocMangerIsOpen] = useState(false)

    const {ftWindowsToOpen, setFtWindowsToOpen} = props

    // useEffect(()=>{
    //     if(ftWindowsToOpen !== null && ftWindowsToOpen !== undefined && setFtWindowsToOpen !== null && setFtWindowsToOpen !== undefined){
    //         if(myWindow !== null){
    //             if(!myWindow.closed){
    //                 myWindow.postMessage(ftWindowsToOpen, window.location.href);
    //                 setFtWindowsToOpen(null)
    //                 return;
    //             }
    //         }
            
    //         let popup = openManager()
    //         popup.onload = () =>{
    //             popup.postMessage(ftWindowsToOpen, window.location.href);
    //             setFtWindowsToOpen(null)
    //         }
            
            
    //     }

    // },[ftWindowsToOpen])

    const openManager = () => {
        setDocMangerIsOpen(true)
    }

    const closeManager = () => {
        setDocMangerIsOpen(false)
    }

    return (
        <>
    
        <div onClick={()=>openManager()} style={{cursor: "pointer"}}>
            <FindInPageIcon style={{width: "20px", margin: "0 5px", paddingBottom: "4px"}}/><span>Text Document Manager</span>
        </div>
        <div hidden={!docMangerIsOpen} className="popupContainer">
            <div className="popupBackdrop">
            </div>
           
            <div>

                <FullDocManager
                    closeManager={()=>closeManager()}
                    ftWindowsToOpen={ftWindowsToOpen}
                    setFtWindowsToOpen ={(value)=>{setFtWindowsToOpen(value)}}
                >
                </FullDocManager>
            </div>
        </div>
      
        </> 
    );
}

export default DocManagerLink;