import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { useFormik } from 'formik';
import { get } from '../../helpers/fetchServicesMethods';
import { url } from "../../reducers/url";
import PubSub from 'pubsub-js';

function showLoader() {
    PubSub.publish('msg', true);
}

function hideLoader() {
    PubSub.publish('msg', false);
}
async function patentDbInfo(history) {
    try {
        let patentDbInfor = url.nucProPatentDbList;
        showLoader();
        return await get(patentDbInfor, history)
            .then((response) => {
                hideLoader();
                return response;
            })
            .catch((error) => {
                console.log("error::", error);
                hideLoader();
            });
    } catch (error) {
        hideLoader();
        console.error(error);
    }
}

const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1,
        width: '96%',
    },
    tableLayout: {
        margin: '-3px 67px 0px 0px',
        borderRight: '1px solid #dcd7c0',
        borderLeft: '1px solid #dcd7c0',
        borderBottom: '1px solid #dcd7c0',
    },
    textAreaCss: {
        marginLeft: '23px',
    },
    fontDetails: {
        fontFamily: 'verdana, helvetica',
        fontSize: '12px',
        backgroundColor: 'white',
        height: '165px',
        padding: '5px',
    },
    scrollable: {
        border: '1px solid #dcd7c0',
        maxHeight: '200.091px',
        overflowY: 'auto',
        overflowX: 'hidden',
        background: '#ffffff',
    },
    selectedContainer: {
        // border: '1px solid #dcd7c0',
        borderRadius: '0 4px 4px 4px',
        height: '200.091px',
        padding: '1em',
        backgroundColor: '#f2f2f2',
        marginLeft: '5px',
        marginRight: '27px',
    },
    activatedTab: {
        backgroundColor: '#f2f2f2',
        fontFamily: 'Verdana, Helvetica',
        fontSize: '12px',
        marginLeft: '5px',
        border: '1px solid #f2f2f2',
        borderTop: '2px solid #bab3ab',
        borderRadius: '3px 3px 0px 0px',
    },
    inactivatedTab: {
        borderColor: 'white',
        backgroundColor: 'white',
        zIndex: '100',
        fontFamily: 'Verdana, Helvetica',
        fontSize: '12px',
        border: 'white'
    },
    aTag:{
        fontWeight: 'bold',
        color: 'black !important',
    }

}));

function GlobalSearch() {
    const classes = useStyles();
    const [state, setState] = useState("Patent");
    const [patentDbList, setPatentDbList] = useState();
    const [nucDbList, setNucDbList] = useState();
    const [proDbList, setProDbList] = useState();
    const [checkedPatentDbList, setCheckedPatentDbList] = useState([]);
    const [checkedNucDbList, setCheckedNucDbList] = useState([]);
    const [checkedProDbList, setCheckedProDbList] = useState([]);

    function switchPage(pageName, event) {
        event.preventDefault();
        setState(pageName);
    }

    useEffect(() => {
        async function fetchMyAPI() {
            try {
                const result = await patentDbInfo();
                const initialData = result.response_content;
                const nucPatentdbList = initialData.nuc_patentdb_list ? Object.values(initialData.nuc_patentdb_list) : [];
                const proPatentdbList = initialData.pro_patentdb_list ? Object.values(initialData.pro_patentdb_list) : [];
                setPatentDbList({ nucPatentdbList: nucPatentdbList, proPatentdbList: proPatentdbList });
                const nucRefdbList = initialData.nuc_refdb_list ? Object.values(initialData.nuc_refdb_list) : [];
                const nucMydbList = initialData.nuc_mydb_list ? Object.values(initialData.nuc_mydb_list) : [];
                setNucDbList({ nucRefdbList: nucRefdbList, nucMydbList: nucMydbList });
                const proRefdbList = initialData.pro_refdb_list ? Object.values(initialData.pro_refdb_list) : [];
                const proMydbList = initialData.pro_mydb_list ? Object.values(initialData.pro_mydb_list) : [];
                setProDbList({ proRefdbList: proRefdbList, proMydbList: proMydbList });
                console.log(result.response_content);
            } catch (error) {
                console.error(error);
            }
        }
        fetchMyAPI();
    }, []);

    function handleCheckboxChange(event, db, type) {
        const isChecked = event.target.checked;
        if (isChecked) {
            switch (type) {
                case "Patent":
                    setCheckedPatentDbList((prevChecked) => [...prevChecked, db]);
                    break;
                case "Nuc":
                    setCheckedNucDbList((prevChecked) => [...prevChecked, db]);
                    break;
                case "Pro":
                    setCheckedProDbList((prevChecked) => [...prevChecked, db]);
                    break;
                default:
                    break;
            }
        } else {
            switch (type) {
                case "Patent":
                    setCheckedPatentDbList((prevChecked) =>
                        prevChecked.filter((item) => item.id !== db.id)
                    );
                    break;
                case "Nuc":
                    setCheckedNucDbList((prevChecked) =>
                        prevChecked.filter((item) => item.id !== db.id)
                    );
                    break;
                case "Pro":
                    setCheckedProDbList((prevChecked) =>
                        prevChecked.filter((item) => item.id !== db.id)
                    );
                    break;
                default:
                    break;
            }
        }
    }

    const formik = useFormik({
        initialValues: {
            textareaId: '',
        },
    });
 
    const handleSearch = async () => {
        const checkedPatentDbIds = checkedPatentDbList.map((item) => item.def_id);
        const checkedNucDbIds = checkedNucDbList.map((item) => item.def_id);
        const checkedProDbIds = checkedProDbList.map((item) => item.def_id);
        const allCheckedIds = [
            ...checkedPatentDbIds,
            ...checkedNucDbIds,
            ...checkedProDbIds,
        ];
    
        let filterParam = "";
        if (formik.values.textareaId.trim() !== "") {
            filterParam =
                "&filter=(filters:!((O:CT,P:SEQUENCE_ALL_ANNOTS,V:" +
                encodeURIComponent(formik.values.textareaId) +
                ")),matchtype:AND,ver:2)";
        }
    
        const baseUrl = process.env.REACT_APP_BASE_URL + "query?do=gqfetch&db=";
    
        window.location.href = baseUrl + allCheckedIds + filterParam;
    };
    

    return (
        <div className="grow">
            <div className={classes.tableLayout}>
                <form>
                    <div>
                        <label style={{ fontFamily: 'verdana, helvetica', fontSize: '12px', marginLeft: '15px', marginTop: '12px', color: 'black' }}>Paste in Keywords</label>
                    </div>
                    <div className={classes.textAreaCss}>
                        <textarea
                            id="textareaId"
                            name="textareaId"
                            style={{ border: '1px solid #dcd7c0', width: '98%', height: '150px', outline: 'none', fontFamily: 'verdana, helvetica', fontSize: '14px' }}
                            value={formik.values.textareaId}
                            onChange={formik.handleChange}
                        ></textarea>
                    </div>

                    <div>
                        <div >
                            <h6 style={{ fontSize: 'medium', color: 'black', marginLeft: '15px' }}>Databases</h6>
                        </div>
                        <div>
                            <div style={{ marginLeft: '13px' }}>
                                <div >
                                    <button className={state === "Patent" ? classes.activatedTab : classes.inactivatedTab} onClick={(e) => switchPage("Patent", e)}><a className={state === "Patent" ? classes.aTag : ''}>Patent</a></button>
                                    <button className={state === "Nuc" ? classes.activatedTab : classes.inactivatedTab} onClick={(e) => switchPage("Nuc", e)}><a className={state === 'Nuc' ? classes.aTag : ''}>Nucleotide</a></button>
                                    <button className={state === "Pro" ? classes.activatedTab : classes.inactivatedTab} onClick={(e) => switchPage("Pro", e)}><a className={state === 'Pro' ? classes.aTag : ''}>Protein</a></button>
                                </div>
                                {state === "Patent" && patentDbList && (
                                    <div className={classes.selectedContainer}>
                                        <div className={classes.scrollable}>
                                            <div className={classes.fontDetails}>
                                                {patentDbList.nucPatentdbList.map((db, index) => {
                                                    return (
                                                        <div className='membersStyle' key={index}>
                                                            <label style={{ fontFamily: 'verdana, helvetica', fontSize: '13px', display: 'contents', color: 'black' }}>
                                                                <input
                                                                    type="checkbox"
                                                                    onChange={(e) =>
                                                                        handleCheckboxChange(e, db, "Patent")
                                                                    }
                                                                    checked={checkedPatentDbList.some(
                                                                        (item) => item.id === db.id
                                                                    )}
                                                                />
                                                                <div style={{marginLeft: '2px'}}>
                                                                    {db.text_label}
                                                                </div>
                                                            </label>
                                                        </div>
                                                    );
                                                })}
                                                {patentDbList.proPatentdbList.map((db, index) => {
                                                    return (
                                                        <div className='membersStyle' key={index}>
                                                            <label style={{ fontFamily: 'verdana, helvetica', fontSize: '13px',display: 'contents', color: 'black' }}>
                                                                <input
                                                                    type="checkbox"
                                                                    onChange={(e) =>
                                                                        handleCheckboxChange(e, db, "Patent")
                                                                    }
                                                                    checked={checkedPatentDbList.some(
                                                                        (item) => item.id === db.id
                                                                    )}
                                                                />
                                                                <div style={{ marginLeft: '2px' }}>
                                                                    {db.text_label}
                                                                </div>
                                                            </label>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {state === "Nuc" && nucDbList && (
                                    <div className={classes.selectedContainer}>
                                        <div className={classes.scrollable}>

                                            <div className={classes.fontDetails}>
                                                {nucDbList.nucRefdbList.map((db, index) => {
                                                    return (
                                                        <div className='membersStyle' key={index}>
                                                            <label style={{ fontFamily: 'verdana, helvetica', fontSize: '13px',display: 'contents', color: 'black' }}>
                                                                <input
                                                                    type="checkbox"
                                                                    onChange={(e) =>
                                                                        handleCheckboxChange(e, db, "Nuc")
                                                                    }
                                                                    checked={checkedNucDbList.some(
                                                                        (item) => item.id === db.id
                                                                    )}
                                                                />
                                                                <div style={{marginLeft: '2px'}}>
                                                                    {db.text_label}
                                                                </div>
                                                            </label>
                                                        </div>
                                                    );
                                                })}
                                                {nucDbList.nucMydbList.map((db, index) => {
                                                    return (
                                                        <div className='membersStyle' key={index}>
                                                            <label style={{fontFamily: 'verdana, helvetica', fontSize: '13px', display: 'contents', color: 'black' }}>
                                                                <input
                                                                    type="checkbox"
                                                                    onChange={(e) =>
                                                                        handleCheckboxChange(e, db, "Nuc")
                                                                    }
                                                                    checked={checkedNucDbList.some(
                                                                        (item) => item.id === db.id
                                                                    )}
                                                                />
                                                                <div style={{marginLeft: '2px'}}>
                                                                    {db.text_label}
                                                                </div>
                                                            </label>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {state === "Pro" && proDbList && (
                                    <div className={classes.selectedContainer}>
                                        <div className={classes.scrollable}>
                                            <div className={classes.fontDetails}>
                                                {proDbList.proRefdbList.map((db, index) => {
                                                    return (
                                                        <div className='membersStyle' key={index}>
                                                            <label style={{ fontFamily: 'verdana, helvetica', fontSize: '13px',display: 'contents', color: 'black' }}>
                                                                <input
                                                                    type="checkbox"
                                                                    onChange={(e) =>
                                                                        handleCheckboxChange(e, db, "Pro")
                                                                    }
                                                                    checked={checkedProDbList.some(
                                                                        (item) => item.id === db.id
                                                                    )}
                                                                />
                                                                <div style={{marginLeft: '2px'}}>
                                                                    {db.text_label}
                                                                </div>
                                                            </label>
                                                        </div>
                                                    );
                                                })}
                                                {proDbList.proMydbList.map((db, index) => {
                                                    return (
                                                        <div className='membersStyle' key={index}>
                                                            <label style={{ fontFamily: 'verdana, helvetica', fontSize: '13px',display: 'contents', color: 'black' }}>
                                                                <input
                                                                    type="checkbox"
                                                                    onChange={(e) =>
                                                                        handleCheckboxChange(e, db, "Pro")
                                                                    }
                                                                    checked={checkedProDbList.some(
                                                                        (item) => item.id === db.id
                                                                    )}
                                                                />
                                                                <div style={{marginLeft: '2px'}}>
                                                                    {db.text_label}
                                                                </div>
                                                            </label>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                        <div align='right'>
                            <button
                                className='DMTBtn'
                                style={{ padding: '5px 10px', fontSize: '18px', marginTop: '19px' }}
                                type="button"
                                onClick={handleSearch}
                            >
                                <b>Search</b>
                            </button>
                        </div>
                </form>
            </div >
        </div >
    )
}
export default GlobalSearch;
