import React, { useState, useEffect, useRef } from "react";
import CheckBox from '../../shared/Fields/CheckBox';
import CloseIcon from '@material-ui/icons/Close';

import KeywordsInContext from "./KeywordsInContext";
import _, { set } from "lodash";

import { ftDocManagerState, getInitialManagerValue } from "../../services/FTDocService";

function FullDocManager(props){
    

    const {ftWindowsToOpen, setFtWindowsToOpen, closeManager } = props

    const managerKey = "ftDocManager"

    const [managerState, setManagerState] = useState(getInitialManagerValue(managerKey));
    const [docsSelected, setDocsSelected] = useState([]);

    useEffect(() => {
        let state = localStorage.getItem(managerKey);
        if(state == null || state == undefined || state ==""){
            localStorage.setItem(managerKey, JSON.stringify(managerState))
        }

    },[])

    const [parentWindow, setParentWindow] = useState(null);
    const [parentKey, setParentKey] = useState("");
    useEffect(()=>{
        if(window.parent){

            if (!/discover\/wf:[0-9]+\.resdb$/.test(window.parent.location.href)) {
                return;
            }

            let match = window.parent.location.href.match(/wf:[0-9]+\.resdb/)
            let urlKey = match ? match[0] : null;
            setParentKey(urlKey)

            setParentWindow(window.parent)
        }
    },[])

    const [windows, setWindows] = useState({});
    useEffect(() => {
    
        if(ftWindowsToOpen!==null){
            

                if(windows[ftWindowsToOpen.pn] && !windows[ftWindowsToOpen.pn].closed){
                    windows[ftWindowsToOpen.pn].focus();
                }else{
                    let newWindow = window.open("#/fulldocview/" + encodeURIComponent(ftWindowsToOpen.pn))
                    newWindow.starterSeqId = ftWindowsToOpen.seqIds
                    newWindow.starterKws = ftWindowsToOpen.kws
                    windows[ftWindowsToOpen.pn] = newWindow;
                    setWindows({...windows})
                }
            
            setFtWindowsToOpen(null)
        }
     
    }, [{...ftWindowsToOpen}]);


    const focusParent = () => {
        if(parentWindow !== null && parentWindow !== undefined){
            if(!parentWindow.closed){
                parentWindow.focus();
            }else{
                setParentWindow(null)
            }
        }
    }


    useEffect(() => {

        const storageListener = (event) => {
          if (event.key === managerKey) {
            setManagerState(event.newValue ? JSON.parse(event.newValue) : ftDocManagerState);
          }
        };
    
        // Add the storage event listener when the component mounts
        window.addEventListener('storage', storageListener);
            return () => {
          window.removeEventListener('storage', storageListener);
        };
    }, []);

    const handleDocCheck = (e, pn) =>{
       let checked = e.target.checked
       
       let dex = docsSelected.indexOf(pn)
       if(checked && dex === -1){
        docsSelected.push(pn);
       }else if(dex !== -1){
            docsSelected.splice(dex, 1)
       }
        setDocsSelected([...docsSelected])
    }

    const closeFtDocument = (pn) => {

        windows[pn].close()
        delete windows[pn]
        setWindows({...windows})
    }

    const focusFtDocument = (pn) => {
        if(!windows[pn].closed){
            windows[pn].focus()
        }
    }

    const applyKeywords = (keywords) => {

        for(let i =0; i< docsSelected.length; i++){
            let docString = localStorage.getItem(docsSelected[i]);
            let docObj = JSON.parse(docString);

            docObj.keywords = keywords;

            docString = JSON.stringify(docObj);
            localStorage.setItem(docsSelected[i], docString);

        }
    }

    const applySeqIds = (seqids)=>{
        for(let i =0; i< docsSelected.length; i++){
            let docString = localStorage.getItem(docsSelected[i]);
            let docObj = JSON.parse(docString);

            docObj.seqIds = seqids;

            docString = JSON.stringify(docObj);
            localStorage.setItem(docsSelected[i], docString);

        }
    }
 
    return (
    <div className="docContainerContainerContainer">
        <div className="docManagerX">
                <CloseIcon onClick={()=>closeManager()} style={{cursor: "pointer"}}/>
        </div>
        <div className="docContainerContainer">
            {managerState.documents !== null &&
            <div className="docManagerColumn" style={{width: "100%", paddingBottom: "10px"}}>
                <span className="pageTitle paddingLeft5" style={{marginTop: "-10px"}}>Document Manager</span>
                <div className="DocListContainer">
                {Object.entries(managerState.documents).length > 0 && Object.entries(managerState.documents).map(([key, value]) =>{
                    if(windows[key]){
                    return(
                    <div className="DocListItem" key={key+"ftdoc"}>
                        <div style={{display: 'flex', paddingLeft: "3px", paddingRight: '2px', height: "100%"}}>
                            <CheckBox 
                                checked={managerState.documents[key].selected}
                                onChange = {(e)=>{handleDocCheck(e, key)}}
                            ></CheckBox>
                        </div>
                        <div className="docManagerInfoBlock">
                            <div>
                                {value.title !== "" &&
                                <strong className="appTextColor appTextFont">{value.title}</strong>
                                }
                                {value.title === "" &&
                                <strong className="appTextColor appTextFont">Loading...</strong>
                                }
                            </div>
                            <div>
                                <span>PN: {key}</span>
                                {parentKey &&
                                <span onClick={()=>{focusParent()}}>
                                </span>
                                }
                            </div>
                        </div>
                        {windows[key] &&
                            <div className="docListItemGetX">
                                <span className="appLink" onClick={()=>{closeFtDocument(key)}}>Close</span>
                                <span className="appLink" onClick={()=>{focusFtDocument(key)}}>Focus</span>
                            </div>
                        }
                    </div>
                )}})}
                </div>
            </div>
            }
            <div>
                <KeywordsInContext
                    docsSelected={docsSelected}
                    applyKeywords={(keywords)=>{applyKeywords(keywords)}}
                    applySeqIds ={(seqids)=>{applySeqIds(seqids)}}
                >
                </KeywordsInContext>
            </div>
        </div>
    </div>

    );
}

export default FullDocManager
