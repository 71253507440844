/* eslint no-undef: "off"*/
(function (root, factory) {
    if (typeof define === "function" && define.amd) {
        define([], factory);
    } else if (typeof module === "object" && module.exports) {
        module.exports = factory();
    } else {
        root.lucenequeryparser = factory();
    }
})(this, function () {
    "use strict";
    /*
     * Generated by PEG.js 0.9.0.
     *
     * http://pegjs.org/
     */

    function peg$subclass(child, parent) {
        function ctor() { this.constructor = child; }
        ctor.prototype = parent.prototype;
        child.prototype = new ctor();
    }

    function peg$SyntaxError(message, expected, found, location) {
        this.message = message;
        this.expected = expected;
        this.found = found;
        this.location = location;
        this.name = "SyntaxError";

        if (typeof Error.captureStackTrace === "function") {
            Error.captureStackTrace(this, peg$SyntaxError);
        }
    }

    peg$subclass(peg$SyntaxError, Error);

    function peg$parse(input) {
        var options = arguments.length > 1 ? arguments[1] : {},
            parser = this,

            peg$FAILED = {},

            peg$startRuleFunctions = { start: peg$parsestart },
            peg$startRuleFunction = peg$parsestart,

            peg$c0 = function (space1, node) {
                if (space1 == null)
                    return node[0];
                return appendPos({
                    'space_before': space1,
                    'node': node[0]
                });
            },
            peg$c1 = function () {
                return {};
            },
            peg$c2 = function (operator) {
                return {
                    'operator': operator
                };
            },
            peg$c3 = function (operator, space1) {
                return {
                    'operator': operator,
                    'space_after': space1
                };
            },
            peg$c4 = function (operator, right) {
                return appendPos({
                    'operator': operator,
                    'right': right
                });
            },
            peg$c5 = function (stem_operator, left, operator, right) {
                var node = {
                    'left': left
                };

                //        var right = 
                //                right.length == 0 
                //                ? null 
                //                : right[0]['right'] == null 
                //                    ? right[0]['left'] 
                //                    : right[0];

                var realright;
                if (right.length == 0)
                    realright = null;
                else if (right[0]['right'] == null) {
                    realright = right[0]['left'];
                    if (realright) {
                        if (right[0]['bad_operator'])
                            realright['bad_operator'] = right[0]['bad_operator'];
                        else if (right[0]['operator'])
                            realright['operator'] = right[0]['operator'];
                    }

                } else
                    realright = right[0];

                if (realright != null)
                    appendPos(realright);

                if (operator != null) {
                    node['operator'] = operator;
                }

                if (stem_operator != null) {
                    node.left['stem_op'] = stem_operator;
                }

                if (realright != null) {
                    if (operator == null)
                        node['operator'] = '<implicit>';
                    node['right'] = realright;
                }

                return node;
            },
            peg$c6 = { type: "other", description: "word proximity query" },
            peg$c7 = function (start, space1, query, space2, end, space3) {
                var result = {};
                result['wp_start_char'] = start;
                result['space_before'] = space1;
                result['wp_query'] = query;
                result['space_between'] = space2;
                result['wp_end_char'] = end;
                result['space_after'] = space3;
                //      dump("Explicit query",result);
                return result;
            },
            peg$c8 = function (space1, query, space2) {
                var result = {};
                result['wp_start_char'] = null;
                result['space_before'] = space1;
                result['wp_query'] = query;
                result['space_after'] = space2;
                result['wp_end_char'] = null;
                //      dump("Logical query",result);
                return result;
            },
            peg$c9 = { type: "other", description: "query" },
            peg$c10 = function (exp1, op, exp2) {
                var result = {};
                result['left'] = exp1;
                result['wp_oper'] = op;
                result['right'] = exp2;
                //   dump('Complex query', result);
                return result;
            },
            peg$c11 = function (exp1) {
                var result = {};
                result['left'] = exp1;
                result['wp_oper'] = null;
                result['right'] = null;
                //   dump('Simplest query', result);
                return result;
            },
            peg$c12 = { type: "other", description: "word list" },
            peg$c13 = function (head, space, tail) {
                var result = {};
                //    dump('HEAD',head);
                //    dump('LIST',tail);
                var list = tail['wp_list'];
                var term_pair = {
                    wp_term: head,
                    space_after: space
                };
                list.splice(0, 0, term_pair);
                result['wp_list'] = list;
                //    dump('term list', result);
                return result;
            },
            peg$c14 = function (head) {
                //    dump('term only',head);
                var result = {};
                var list = [];
                var term_pair = {
                    wp_term: head,
                    space_after: ''
                };
                list.push(term_pair);
                result['wp_list'] = list;
                return result;
            },
            peg$c15 = { type: "other", description: "(word list)" },
            peg$c16 = function (left_paren, space1, tail, space2, right_paren) {
                var result = {};
                //    dump('HEAD',head);
                //    dump('LIST',tail);
                result['wp_list'] = tail['wp_list'];
                result['wp_leftp'] = {
                    left_paren: left_paren,
                    space_after: space1
                };
                result['wp_rightp'] = {
                    right_paren: right_paren,
                    space_before: space2
                };
                //    dump('term list', result);
                return result;
            },
            peg$c17 = { type: "other", description: "wp operator expression" },
            peg$c18 = function (space1, wordprox_oper, space2) {
                var result = {};
                result['space_before'] = space1;
                result['wp_oper'] = wordprox_oper;
                result['space_after'] = space2;
                //   dump('wp oper exp',result);
                return result;
            },
            peg$c19 = function (space1) {
            },
            peg$c20 = { type: "other", description: "operator" },
            peg$c21 = /^[dDwW]/,
            peg$c22 = { type: "class", value: "[dDwW]", description: "[dDwW]" },
            peg$c23 = function (op) {
                var result = {
                    wo_op: op.toUpperCase(),
                    wo_words: "0",
                    wo_op_text: op
                };
                //    dump('simp op', result);
                return result;
            },
            peg$c24 = /^[0-9]/,
            peg$c25 = { type: "class", value: "[0-9]", description: "[0-9]" },
            peg$c26 = function (starter, more, op) {
                var words = starter + more.join('');
                var nwords = parseInt(words);
                var result = {
                    wp_op: op.toUpperCase(),
                    wp_words: nwords,
                    wp_op_text: words + op
                };

                //    dump('dist op', result);
                return result;
            },
            peg$c27 = { type: "other", description: "ignored logical operator" },
            peg$c28 = { type: "other", description: "search term" },
            peg$c29 = function (term) {
                return term.join('');
            },
            peg$c30 = { type: "other", description: "prohibited term" },
            peg$c31 = function (decoration, decorated_term) {
                var result = appendPos({
                    'decoration': decoration,
                    'term': decorated_term
                });
                return result
            },
            peg$c32 = { type: "other", description: "term" },
            peg$c33 = function (ignored_wpterm) {
                var result = {};
                console.dir(ignored_wpterm);
                result['ignored_wpterm'] = ignored_wpterm;
                //    dump('Ignored WP Term', result);
                return result;
            },
            peg$c34 = function (unquoted_wpterm) {
                var result = {};
                result['unquoted_wpterm'] = unquoted_wpterm;
                //    dump('Unquoted WP Term', result);
                return result;
            },
            peg$c35 = function (decorated_term) {
                var result = {};
                result['decorated_wpterm'] = decorated_term;
                //    dump('Decorated WP Term', result);
                return result;
            },
            peg$c36 = function (quoted_term) {
                var result = {};
                result['quoted_term'] = quoted_term;
                //    dump('Quoted WP Term', result);
                return result;
            },
            peg$c37 = function (plist) {
                var result = {};
                result['wp_plist'] = plist;
                return result;
            },
            peg$c38 = { type: "other", description: "logical operator" },
            peg$c39 = /^[aA]/,
            peg$c40 = { type: "class", value: "[aA]", description: "[aA]" },
            peg$c41 = /^[nN]/,
            peg$c42 = { type: "class", value: "[nN]", description: "[nN]" },
            peg$c43 = /^[dD]/,
            peg$c44 = { type: "class", value: "[dD]", description: "[dD]" },
            peg$c45 = function () { return 'AND'; },
            peg$c46 = /^[oO]/,
            peg$c47 = { type: "class", value: "[oO]", description: "[oO]" },
            peg$c48 = /^[rR]/,
            peg$c49 = { type: "class", value: "[rR]", description: "[rR]" },
            peg$c50 = function () { return 'OR'; },
            peg$c51 = /^[tT]/,
            peg$c52 = { type: "class", value: "[tT]", description: "[tT]" },
            peg$c53 = function () { return 'NOT'; },
            peg$c54 = { type: "other", description: "separator" },
            peg$c55 = /^[()]/,
            peg$c56 = { type: "class", value: "[()]", description: "[()]" },
            peg$c57 = { type: "other", description: "Left bracket" },
            peg$c58 = "<",
            peg$c59 = { type: "literal", value: "<", description: "\"<\"" },
            peg$c60 = { type: "other", description: "Right bracket" },
            peg$c61 = ">",
            peg$c62 = { type: "literal", value: ">", description: "\">\"" },
            peg$c63 = function (field_exp, space1) {

                if (space1 == null) {
                    return field_exp;
                }

                return appendPos({
                    'group_exp': field_exp,
                    'space_after': space1
                }
                );
            },
            peg$c64 = function (paren_exp) {
                return paren_exp;
            },
            peg$c65 = function (wp_exp) {
                return wp_exp;
            },
            peg$c66 = function (left_paren, space1, node, space2, annotation, right_paren, mods, space3) {
                /*      return { node: node[0] }; */

                var ret = appendPos({
                    'left_paren': left_paren,
                    'space_before': space1,
                    'node': node[0],
                    'space_between': space2,
                    'query_count': annotation,
                    'right_paren': right_paren,
                    'space_after': space3
                });

                mergeIn(ret, mods);

                return ret;
            },
            peg$c67 = function (left_paren, space1, node, space2, right_paren, mods, space3) {
                /*      return { node: node[0] }; */

                var ret = appendPos({
                    'left_paren': left_paren,
                    'space_before': space1,
                    'node': node[0],
                    'space_between': space2,
                    'right_paren': right_paren,
                    'space_after': space3
                });

                mergeIn(ret, mods);

                return ret;
            },
            peg$c68 = function (fieldname, range) {
                range['field'] =
                    fieldname == ''
                        ? "<implicit>"
                        : fieldname;

                appendPos(range);

                return range;
            },
            peg$c69 = function (fieldname, node) {
                node['field'] = fieldname;
                appendPos(node);
                return node;
            },
            peg$c70 = function (fieldname, term) {
                var fieldexp = {
                    'field':
                        fieldname == ''
                            ? "<implicit>"
                            : fieldname
                };

                appendPos(fieldexp)

                for (var key in term)
                    fieldexp[key] = term[key];

                return fieldexp;
            },
            peg$c71 = function (fieldname) {   // This makes a field name with no value legal, which isn't right, but
                // they can just be ignored on the backend, so no need to throw a hissy fit about it
                var fieldexp = {
                    'field':
                        fieldname == ''
                            ? "<implicit>"
                            : fieldname
                };

                appendPos(fieldexp)

                return fieldexp;
            },
            peg$c72 = function (fieldname, space, wp_exp) {
                var fieldexp = {
                    'field':
                        fieldname == ''
                            ? "<implicit>"
                            : fieldname,
                    'wp_exp': wp_exp
                };

                appendPos(fieldexp)

                return fieldexp;
            },
            peg$c73 = { type: "other", description: "field name" },
            peg$c74 = function (fieldname, whitespace) {
                var result = createPosObj('fieldname', fieldname);
                if (whitespace && whitespace.length > 0) {
                    result['space_after'] = whitespace[0];
                }
                return result;
            },
            peg$c75 = function (op, decoration, decorated_term, mods, space1) {
                var result = appendPos({
                    'decoration': decoration,
                    'term': decorated_term
                });

                mergeIn(ret, mods);

                if (null != op) {
                    result['prefix'] = op;
                }

                result['space_after'] = space1;
                return result
            },
            peg$c76 = function (op, decoration, decorated_term, mods, space1) {
                var result = appendPos({
                    'decoration': decoration,
                    'term': decorated_term
                });

                mergeIn(result, mods);

                if (null != op) {
                    result['prefix'] = op;
                }

                result['space_after'] = space1;

                return result
            },
            peg$c77 = function (op, term, mods, space1) {
                var result = createPosObj('quoted_term', term);

                mergeIn(result, mods);

                if (null != op) {
                    result['prefix'] = op;
                }

                result['space_after'] = space1;

                return result;
            },
            peg$c78 = function (op, term, mods, space1) {
                var result = createPosObj('term', term);

                mergeIn(result, mods);

                if (null != op) {
                    result['prefix'] = op;
                }

                result['space_after'] = space1;

                return result;
            },
            peg$c79 = function (term) {
                return term.join('');
            },
            peg$c80 = ".",
            peg$c81 = { type: "literal", value: ".", description: "\".\"" },
            peg$c82 = /^[^: #!\t\r\n\f{}()"\^%~[\]|<>\^\u21AD\u21AE]/,
            peg$c83 = { type: "class", value: "[^: \\#\\!\\t\\r\\n\\f\\{\\}()\"^%~\\[\\]\\|\\<\\>^\\u21ad\\u21ae]", description: "[^: \\#\\!\\t\\r\\n\\f\\{\\}()\"^%~\\[\\]\\|\\<\\>^\\u21ad\\u21ae]" },
            peg$c84 = { type: "other", description: "" },
            peg$c85 = /^[\u21AE]/,
            peg$c86 = { type: "class", value: "[\\u21ae]", description: "[\\u21ae]" },
            peg$c87 = /^[^\u21AE]/,
            peg$c88 = { type: "class", value: "[^\\u21ae]", description: "[^\\u21ae]" },
            peg$c89 = function (annotation) {
                return annotation.join('');
            },
            peg$c90 = "\"",
            peg$c91 = { type: "literal", value: "\"", description: "\"\\\"\"" },
            peg$c92 = /^[^"]/,
            peg$c93 = { type: "class", value: "[^\"]", description: "[^\"]" },
            peg$c94 = function (term) {
                return term.join('');
            },
            peg$c95 = { type: "other", description: "boost and/or similarity/proximity modifier" },
            peg$c96 = function (proximity, similarity, boost) {      // psb ps
                var ret = appendPos({ 'modseq': ['p', 's', 'b'] });
                if (null != proximity) {
                    ret['proximity'] = proximity;
                }
                if (null != similarity) {
                    ret['similarity'] = similarity;
                }
                if (null != boost) {
                    ret['boost'] = boost;
                }
                return ret;
            },
            peg$c97 = function (proximity, boost, similarity) {      // pbs pb p
                var ret = appendPos({ 'modseq': ['p', 'b', 's'] });
                if (null != proximity) {
                    ret['proximity'] = proximity;
                }
                if (null != similarity) {
                    ret['similarity'] = similarity;
                }
                if (null != boost) {
                    ret['boost'] = boost;
                }
                return ret;
            },
            peg$c98 = function (similarity, proximity, boost) {      // spb sp
                var ret = appendPos({ 'modseq': ['s', 'p', 'b'] });
                if (null != proximity) {
                    ret['proximity'] = proximity;
                }
                if (null != similarity) {
                    ret['similarity'] = similarity;
                }
                if (null != boost) {
                    ret['boost'] = boost;
                }
                return ret;
            },
            peg$c99 = function (similarity, boost, proximity) {      // sbp sb s
                var ret = appendPos({ 'modseq': ['s', 'b', 'p'] });
                if (null != proximity) {
                    ret['proximity'] = proximity;
                }
                if (null != similarity) {
                    ret['similarity'] = similarity;
                }
                if (null != boost) {
                    ret['boost'] = boost;
                }
                return ret;
            },
            peg$c100 = function (boost, similarity, proximity) {      // bsp bs
                var ret = appendPos({ 'modseq': ['b', 's', 'p'] });
                if (null != proximity) {
                    ret['proximity'] = proximity;
                }
                if (null != similarity) {
                    ret['similarity'] = similarity;
                }
                if (null != boost) {
                    ret['boost'] = boost;
                }
                return ret;
            },
            peg$c101 = function (boost, proximity, similarity) {      // bps bp b
                var ret = appendPos({ 'modseq': ['b', 'p', 's'] });
                if (null != proximity) {
                    ret['proximity'] = proximity;
                }
                if (null != similarity) {
                    ret['similarity'] = similarity;
                }
                if (null != boost) {
                    ret['boost'] = boost;
                }
                return ret;
            },
            peg$c102 = { type: "other", description: "boost and/or proximity modifier" },
            peg$c103 = function (proximity, boost) {
                var ret = appendPos({ 'modseq': ['p', 'b'] });
                if (null != proximity) {
                    ret['proximity'] = proximity;
                }
                if (null != boost) {
                    ret['boost'] = boost;
                }
                return ret;
            },
            peg$c104 = function (boost, proximity) {
                var ret = appendPos({ 'modseq': ['b', 'p'] });
                if (null != proximity) {
                    ret['proximity'] = proximity;
                }
                if (null != boost) {
                    ret['boost'] = boost;
                }
                return ret;
            },
            peg$c105 = { type: "other", description: "proximity modifier" },
            peg$c106 = "~",
            peg$c107 = { type: "literal", value: "~", description: "\"~\"" },
            peg$c108 = function (space0, space1) {
                return appendPos({ 'value': null, 'space_before': space0, 'space_after': space1 });
            },
            peg$c109 = function (space0) {
                return appendPos({ 'value': null, 'space_before': space0 });
            },
            peg$c110 = function (space0, proximity) {
                return appendPos({ 'value': proximity, 'space_before': space0 });
            },
            peg$c111 = { type: "other", description: "boost and/or fuzzy modifier" },
            peg$c112 = function (similarity, boost) {
                var ret = appendPos({ 'modseq': ['s', 'b'] });
                if (null != similarity) {
                    ret['similarity'] = similarity;
                }
                if (null != boost) {
                    ret['boost'] = boost;
                }
                return ret;
            },
            peg$c113 = function (boost, similarity) {
                var ret = appendPos({ 'modseq': ['b', 's'] });
                if (null != similarity) {
                    ret['similarity'] = similarity;
                }
                if (null != boost) {
                    ret['boost'] = boost;
                }
                return ret;
            },
            peg$c114 = { type: "other", description: "boost modifier" },
            peg$c115 = "^",
            peg$c116 = { type: "literal", value: "^", description: "\"^\"" },
            peg$c117 = function (boost) {
                return boost;
            },
            peg$c118 = { type: "other", description: "fuzzy modifier" },
            peg$c119 = "%",
            peg$c120 = { type: "literal", value: "%", description: "\"%\"" },
            peg$c121 = function (space1) {
                return appendPos({ 'fuzziness': null, 'space_after': space1 });
            },
            peg$c122 = function () {
                return appendPos({ 'fuzziness': null });
            },
            peg$c123 = function (fuzziness) {
                return appendPos({ 'fuzziness': fuzziness });
            },
            peg$c124 = function () {
                return appendPos({ 'fuzziness': null });
            },
            peg$c125 = { type: "other", description: "number of replacements between 0 and 2" },
            peg$c126 = /^[012]/,
            peg$c127 = { type: "class", value: "[012]", description: "[012]" },
            peg$c128 = function (val) {
                return val;
            },
            peg$c129 = { type: "other", description: "decimal value" },
            peg$c130 = function (valM, valD) {
                return parseFloat(valM.join('') + '.' + valD.join(''));
            },
            peg$c131 = { type: "other", description: "integer value" },
            peg$c132 = function (val) {
                return parseInt(val.join(''));
            },
            peg$c133 = { type: "other", description: "range" },
            peg$c134 = "[",
            peg$c135 = { type: "literal", value: "[", description: "\"[\"" },
            peg$c136 = /^[Tt]/,
            peg$c137 = { type: "class", value: "[Tt]", description: "[Tt]" },
            peg$c138 = /^[Oo]/,
            peg$c139 = { type: "class", value: "[Oo]", description: "[Oo]" },
            peg$c140 = "]",
            peg$c141 = { type: "literal", value: "]", description: "\"]\"" },
            peg$c142 = function (term_min, space1, space2, term_max) {
                return appendPos({
                    'term_min': term_min,
                    'term_max': term_max,
                    'space_before_to': space1,
                    'space_after_to': space2,
                    'inclusive': true
                });
            },
            peg$c143 = "{",
            peg$c144 = { type: "literal", value: "{", description: "\"{\"" },
            peg$c145 = "}",
            peg$c146 = { type: "literal", value: "}", description: "\"}\"" },
            peg$c147 = function (term_min, space1, space2, term_max) {
                return appendPos({
                    'term_min': term_min,
                    'term_max': term_max,
                    'space_before_to': space1,
                    'space_after_to': space2,
                    'inclusive': false
                });
            },
            peg$c148 = function (space1, operator, space2) {
                var result = createPosObj('op', operator);
                result['space_before'] = space1;
                result['space_after'] = space2;
                return result;
            },
            peg$c149 = function (space1, operator, space2) {
                var result = createPosObj('op2', operator);
                result['space_before'] = space1;
                result['space_after'] = space2;
                return result;
            },
            peg$c150 = { type: "other", description: "OR/AND/NOT" },
            peg$c151 = function (or_operator, space1, not_operator) {
                var result = {};
                result['op'] = or_operator.op;
                result['op_text'] = or_operator.op_text;
                result['space_between'] = space1;
                result['op2'] = not_operator.op;
                result['op2_text'] = not_operator.op_text;
                appendPos(result);
                return result;
            },
            peg$c152 = function (and_operator, space1, not_operator) {
                var result = {};
                result['op'] = and_operator.op;
                result['op_text'] = and_operator.op_text;
                result['space_between'] = space1;
                result['op2'] = not_operator.op;
                result['op2_text'] = not_operator.op_text;
                appendPos(result);
                return result;
            },
            peg$c153 = { type: "other", description: "AND" },
            peg$c154 = function (op) {
                return appendPos({
                    'op': 'AND',
                    'op_text': op.join('')
                });
            },
            peg$c155 = "&&",
            peg$c156 = { type: "literal", value: "&&", description: "\"&&\"" },
            peg$c157 = function () {
                return appendPos({
                    'op': 'AND',
                    'op_text': '&&'
                });
            },
            peg$c158 = { type: "other", description: "OR" },
            peg$c159 = function (op) {
                return appendPos({
                    'op': 'OR',
                    'op_text': op.join('')
                });
            },
            peg$c160 = "||",
            peg$c161 = { type: "literal", value: "||", description: "\"||\"" },
            peg$c162 = function () {
                return appendPos({
                    'op': 'OR',
                    'op_text': '||'
                });
            },
            peg$c163 = { type: "other", description: "NOT" },
            peg$c164 = function (op) {
                return appendPos({
                    'op': 'NOT',
                    'op_text': op.join('')
                });
            },
            peg$c165 = { type: "other", description: "prefix operator (- or +)" },
            peg$c166 = function (operator) {
                var result = createPosObj('prefix_operator', operator);
                result['space_before'] = null;
                return result;
            },
            peg$c167 = { type: "other", description: "left parenthesis" },
            peg$c168 = "(",
            peg$c169 = { type: "literal", value: "(", description: "\"(\"" },
            peg$c170 = function () { return createPosObj('left_paren', '('); },
            peg$c171 = { type: "other", description: "right parenthesis" },
            peg$c172 = ")",
            peg$c173 = { type: "literal", value: ")", description: "\")\"" },
            peg$c174 = function () { return createPosObj('right_paren', ')'); },
            peg$c175 = { type: "other", description: "stemming" },
            peg$c176 = "!",
            peg$c177 = { type: "literal", value: "!", description: "\"!\"" },
            peg$c178 = "#",
            peg$c179 = { type: "literal", value: "#", description: "\"#\"" },
            peg$c180 = { type: "other", description: "prefix" },
            peg$c181 = "+",
            peg$c182 = { type: "literal", value: "+", description: "\"+\"" },
            peg$c183 = "-",
            peg$c184 = { type: "literal", value: "-", description: "\"-\"" },
            peg$c185 = { type: "other", description: "?" },
            peg$c186 = "?",
            peg$c187 = { type: "literal", value: "?", description: "\"?\"" },
            peg$c188 = "\u21AD",
            peg$c189 = { type: "literal", value: "\u21AD", description: "\"\\u21AD\"" },
            peg$c190 = ":",
            peg$c191 = { type: "literal", value: ":", description: "\":\"" },
            peg$c192 = { type: "other", description: "white space" },
            peg$c193 = /^[ \t\r\n\f ]/,
            peg$c194 = { type: "class", value: "[ \\t\\r\\n\\f ]", description: "[ \\t\\r\\n\\f ]" },
            peg$c195 = function (whitespace) {
                return createPosObj('whitespace', whitespace.join(''));
            },
            peg$c196 = { type: "other", description: "end of input" },
            peg$c197 = { type: "any", description: "any character" },

            peg$currPos = 0,
            peg$savedPos = 0,
            peg$posDetailsCache = [{ line: 1, column: 1, seenCR: false }],
            peg$maxFailPos = 0,
            peg$maxFailExpected = [],
            peg$silentFails = 0,

            peg$result;

        if ("startRule" in options) {
            if (!(options.startRule in peg$startRuleFunctions)) {
                throw new Error("Can't start parsing from rule \"" + options.startRule + "\".");
            }

            peg$startRuleFunction = peg$startRuleFunctions[options.startRule];
        }

        function text() {
            return input.substring(peg$savedPos, peg$currPos);
        }

        function location() {
            return peg$computeLocation(peg$savedPos, peg$currPos);
        }

        function expected(description) {
            throw peg$buildException(
                null,
                [{ type: "other", description: description }],
                input.substring(peg$savedPos, peg$currPos),
                peg$computeLocation(peg$savedPos, peg$currPos)
            );
        }

        function error(message) {
            throw peg$buildException(
                message,
                null,
                input.substring(peg$savedPos, peg$currPos),
                peg$computeLocation(peg$savedPos, peg$currPos)
            );
        }

        function peg$computePosDetails(pos) {
            var details = peg$posDetailsCache[pos],
                p, ch;

            if (details) {
                return details;
            } else {
                p = pos - 1;
                while (!peg$posDetailsCache[p]) {
                    p--;
                }

                details = peg$posDetailsCache[p];
                details = {
                    line: details.line,
                    column: details.column,
                    seenCR: details.seenCR
                };

                while (p < pos) {
                    ch = input.charAt(p);
                    if (ch === "\n") {
                        if (!details.seenCR) { details.line++; }
                        details.column = 1;
                        details.seenCR = false;
                    } else if (ch === "\r" || ch === "\u2028" || ch === "\u2029") {
                        details.line++;
                        details.column = 1;
                        details.seenCR = true;
                    } else {
                        details.column++;
                        details.seenCR = false;
                    }

                    p++;
                }

                peg$posDetailsCache[pos] = details;
                return details;
            }
        }

        function peg$computeLocation(startPos, endPos) {
            var startPosDetails = peg$computePosDetails(startPos),
                endPosDetails = peg$computePosDetails(endPos);

            return {
                start: {
                    offset: startPos,
                    line: startPosDetails.line,
                    column: startPosDetails.column
                },
                end: {
                    offset: endPos,
                    line: endPosDetails.line,
                    column: endPosDetails.column
                }
            };
        }

        function peg$fail(expected) {
            if (peg$currPos < peg$maxFailPos) { return; }

            if (peg$currPos > peg$maxFailPos) {
                peg$maxFailPos = peg$currPos;
                peg$maxFailExpected = [];
            }

            peg$maxFailExpected.push(expected);
        }

        function peg$buildException(message, expected, found, location) {

            function cleanupExpected(expected) {
                var i = 1;

                expected.sort(function (a, b) {
                    if (a.description < b.description) {
                        return -1;
                    } else if (a.description > b.description) {
                        return 1;
                    } else {
                        return 0;
                    }
                });

                while (i < expected.length) {
                    if (expected[i - 1] === expected[i]) {
                        expected.splice(i, 1);
                    } else {
                        i++;
                    }
                }
            }

            function buildMessage(expected, found) {
                function stringEscape(s) {
                    function hex(ch) { return ch.charCodeAt(0).toString(16).toUpperCase(); }

                    return s
                        .replace(/\\/g, '\\\\')
                        .replace(/"/g, '\\"')
                        .replace(/\x08/g, '\\b')
                        .replace(/\t/g, '\\t')
                        .replace(/\n/g, '\\n')
                        .replace(/\f/g, '\\f')
                        .replace(/\r/g, '\\r')
                        .replace(/[\x00-\x07\x0B\x0E\x0F]/g, function (ch) { return '\\x0' + hex(ch); })
                        .replace(/[\x10-\x1F\x80-\xFF]/g, function (ch) { return '\\x' + hex(ch); })
                        .replace(/[\u0100-\u0FFF]/g, function (ch) { return '\\u0' + hex(ch); })
                        .replace(/[\u1000-\uFFFF]/g, function (ch) { return '\\u' + hex(ch); });
                }

                var expectedDescs = new Array(expected.length),
                    expectedDesc, foundDesc, i;

                for (i = 0; i < expected.length; i++) {
                    expectedDescs[i] = expected[i].description;
                }

                expectedDesc = expected.length > 1
                    ? expectedDescs.slice(0, -1).join(", ")
                    + " or "
                    + expectedDescs[expected.length - 1]
                    : expectedDescs[0];

                foundDesc = found ? "\"" + stringEscape(found) + "\"" : "end of input";

                return "Expected " + expectedDesc + " but " + foundDesc + " found.";
            }

            if (expected !== null) {
                cleanupExpected(expected);
            }

            return new peg$SyntaxError(
                message !== null ? message : buildMessage(expected, found),
                expected,
                found,
                location
            );
        }

        function peg$parsestart() {
            var s0, s1, s2, s3;

            s0 = peg$currPos;
            s1 = peg$parse_();
            if (s1 === peg$FAILED) {
                s1 = null;
            }
            if (s1 !== peg$FAILED) {
                s2 = [];
                s3 = peg$parsenode();
                if (s3 !== peg$FAILED) {
                    while (s3 !== peg$FAILED) {
                        s2.push(s3);
                        s3 = peg$parsenode();
                    }
                } else {
                    s2 = peg$FAILED;
                }
                if (s2 !== peg$FAILED) {
                    peg$savedPos = s0;
                    s1 = peg$c0(s1, s2);
                    s0 = s1;
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }
            } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
            }
            if (s0 === peg$FAILED) {
                s0 = peg$currPos;
                s1 = [];
                s2 = peg$parse_();
                while (s2 !== peg$FAILED) {
                    s1.push(s2);
                    s2 = peg$parse_();
                }
                if (s1 !== peg$FAILED) {
                    peg$savedPos = s0;
                    s1 = peg$c1();
                }
                s0 = s1;
                if (s0 === peg$FAILED) {
                    s0 = peg$currPos;
                    s1 = peg$parseEOF();
                    if (s1 !== peg$FAILED) {
                        peg$savedPos = s0;
                        s1 = peg$c1();
                    }
                    s0 = s1;
                }
            }

            return s0;
        }

        function peg$parsenode() {
            var s0, s1, s2, s3, s4, s5;

            s0 = peg$currPos;
            s1 = peg$parseoperator_exp();
            if (s1 !== peg$FAILED) {
                s2 = peg$parseEOF();
                if (s2 !== peg$FAILED) {
                    peg$savedPos = s0;
                    s1 = peg$c2(s1);
                    s0 = s1;
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }
            } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
            }
            if (s0 === peg$FAILED) {
                s0 = peg$currPos;
                s1 = peg$parseoperator_exp();
                if (s1 !== peg$FAILED) {
                    s2 = peg$parse_();
                    if (s2 !== peg$FAILED) {
                        s3 = peg$parseEOF();
                        if (s3 !== peg$FAILED) {
                            peg$savedPos = s0;
                            s1 = peg$c3(s1, s2);
                            s0 = s1;
                        } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                        }
                    } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                    }
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }
                if (s0 === peg$FAILED) {
                    s0 = peg$currPos;
                    s1 = peg$parseoperator_exp();
                    if (s1 !== peg$FAILED) {
                        s2 = peg$parsenode();
                        if (s2 !== peg$FAILED) {
                            peg$savedPos = s0;
                            s1 = peg$c4(s1, s2);
                            s0 = s1;
                        } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                        }
                    } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                    }
                    if (s0 === peg$FAILED) {
                        s0 = peg$currPos;
                        s1 = peg$parsestem_operator();
                        if (s1 === peg$FAILED) {
                            s1 = null;
                        }
                        if (s1 !== peg$FAILED) {
                            s2 = peg$parsegroup_exp();
                            if (s2 !== peg$FAILED) {
                                s3 = peg$parseoperator_exp();
                                if (s3 === peg$FAILED) {
                                    s3 = null;
                                }
                                if (s3 !== peg$FAILED) {
                                    s4 = [];
                                    s5 = peg$parsenode();
                                    while (s5 !== peg$FAILED) {
                                        s4.push(s5);
                                        s5 = peg$parsenode();
                                    }
                                    if (s4 !== peg$FAILED) {
                                        peg$savedPos = s0;
                                        s1 = peg$c5(s1, s2, s3, s4);
                                        s0 = s1;
                                    } else {
                                        peg$currPos = s0;
                                        s0 = peg$FAILED;
                                    }
                                } else {
                                    peg$currPos = s0;
                                    s0 = peg$FAILED;
                                }
                            } else {
                                peg$currPos = s0;
                                s0 = peg$FAILED;
                            }
                        } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                        }
                    }
                }
            }

            return s0;
        }

        function peg$parsewordprox_total_query() {
            var s0, s1, s2, s3, s4, s5, s6;

            peg$silentFails++;
            s0 = peg$currPos;
            s1 = peg$parsewordprox_start();
            if (s1 !== peg$FAILED) {
                s2 = peg$parse_();
                if (s2 === peg$FAILED) {
                    s2 = null;
                }
                if (s2 !== peg$FAILED) {
                    s3 = peg$parsewordprox_query();
                    if (s3 !== peg$FAILED) {
                        s4 = peg$parse_();
                        if (s4 === peg$FAILED) {
                            s4 = null;
                        }
                        if (s4 !== peg$FAILED) {
                            s5 = peg$parsewordprox_end();
                            if (s5 !== peg$FAILED) {
                                s6 = peg$parse_();
                                if (s6 === peg$FAILED) {
                                    s6 = null;
                                }
                                if (s6 !== peg$FAILED) {
                                    peg$savedPos = s0;
                                    s1 = peg$c7(s1, s2, s3, s4, s5, s6);
                                    s0 = s1;
                                } else {
                                    peg$currPos = s0;
                                    s0 = peg$FAILED;
                                }
                            } else {
                                peg$currPos = s0;
                                s0 = peg$FAILED;
                            }
                        } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                        }
                    } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                    }
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }
            } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
            }
            if (s0 === peg$FAILED) {
                s0 = peg$currPos;
                s1 = peg$parse_();
                if (s1 === peg$FAILED) {
                    s1 = null;
                }
                if (s1 !== peg$FAILED) {
                    s2 = peg$parsewordprox_query();
                    if (s2 !== peg$FAILED) {
                        s3 = peg$parse_();
                        if (s3 === peg$FAILED) {
                            s3 = null;
                        }
                        if (s3 !== peg$FAILED) {
                            s4 = peg$currPos;
                            peg$silentFails++;
                            s5 = peg$parsewordprox_logical_op();
                            if (s5 === peg$FAILED) {
                                s5 = null;
                            }
                            peg$silentFails--;
                            if (s5 !== peg$FAILED) {
                                peg$currPos = s4;
                                s4 = void 0;
                            } else {
                                s4 = peg$FAILED;
                            }
                            if (s4 !== peg$FAILED) {
                                peg$savedPos = s0;
                                s1 = peg$c8(s1, s2, s3);
                                s0 = s1;
                            } else {
                                peg$currPos = s0;
                                s0 = peg$FAILED;
                            }
                        } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                        }
                    } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                    }
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }
            }
            peg$silentFails--;
            if (s0 === peg$FAILED) {
                s1 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c6); }
            }

            return s0;
        }

        function peg$parsewordprox_query() {
            var s0, s1, s2, s3;

            peg$silentFails++;
            s0 = peg$currPos;
            s1 = peg$parsewordprox_list();
            if (s1 !== peg$FAILED) {
                s2 = peg$parsewordprox_oper_exp();
                if (s2 !== peg$FAILED) {
                    s3 = peg$parsewordprox_query();
                    if (s3 !== peg$FAILED) {
                        peg$savedPos = s0;
                        s1 = peg$c10(s1, s2, s3);
                        s0 = s1;
                    } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                    }
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }
            } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
            }
            if (s0 === peg$FAILED) {
                s0 = peg$currPos;
                s1 = peg$parsewordprox_list();
                if (s1 !== peg$FAILED) {
                    s2 = peg$currPos;
                    peg$silentFails++;
                    s3 = peg$parsewordprox_oper_exp();
                    peg$silentFails--;
                    if (s3 === peg$FAILED) {
                        s2 = void 0;
                    } else {
                        peg$currPos = s2;
                        s2 = peg$FAILED;
                    }
                    if (s2 !== peg$FAILED) {
                        peg$savedPos = s0;
                        s1 = peg$c11(s1);
                        s0 = s1;
                    } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                    }
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }
            }
            peg$silentFails--;
            if (s0 === peg$FAILED) {
                s1 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c9); }
            }

            return s0;
        }

        function peg$parsewordprox_list() {
            var s0, s1, s2, s3;

            peg$silentFails++;
            s0 = peg$currPos;
            s1 = peg$parsewordprox_term();
            if (s1 !== peg$FAILED) {
                s2 = peg$parse_();
                if (s2 !== peg$FAILED) {
                    s3 = peg$parsewordprox_list();
                    if (s3 !== peg$FAILED) {
                        peg$savedPos = s0;
                        s1 = peg$c13(s1, s2, s3);
                        s0 = s1;
                    } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                    }
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }
            } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
            }
            if (s0 === peg$FAILED) {
                s0 = peg$currPos;
                s1 = peg$parsewordprox_term();
                if (s1 !== peg$FAILED) {
                    peg$savedPos = s0;
                    s1 = peg$c14(s1);
                }
                s0 = s1;
            }
            peg$silentFails--;
            if (s0 === peg$FAILED) {
                s1 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c12); }
            }

            return s0;
        }

        function peg$parsewordprox_plist() {
            var s0, s1, s2, s3, s4, s5;

            peg$silentFails++;
            s0 = peg$currPos;
            s1 = peg$parseleft_paren();
            if (s1 !== peg$FAILED) {
                s2 = peg$parse_();
                if (s2 === peg$FAILED) {
                    s2 = null;
                }
                if (s2 !== peg$FAILED) {
                    s3 = peg$parsewordprox_list();
                    if (s3 !== peg$FAILED) {
                        s4 = peg$parse_();
                        if (s4 === peg$FAILED) {
                            s4 = null;
                        }
                        if (s4 !== peg$FAILED) {
                            s5 = peg$parseright_paren();
                            if (s5 !== peg$FAILED) {
                                peg$savedPos = s0;
                                s1 = peg$c16(s1, s2, s3, s4, s5);
                                s0 = s1;
                            } else {
                                peg$currPos = s0;
                                s0 = peg$FAILED;
                            }
                        } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                        }
                    } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                    }
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }
            } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
            }
            peg$silentFails--;
            if (s0 === peg$FAILED) {
                s1 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c15); }
            }

            return s0;
        }

        function peg$parsewordprox_oper_exp() {
            var s0, s1, s2, s3;

            peg$silentFails++;
            s0 = peg$currPos;
            s1 = peg$parse_();
            if (s1 !== peg$FAILED) {
                s2 = peg$parsewordprox_oper();
                if (s2 !== peg$FAILED) {
                    s3 = peg$parse_();
                    if (s3 !== peg$FAILED) {
                        peg$savedPos = s0;
                        s1 = peg$c18(s1, s2, s3);
                        s0 = s1;
                    } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                    }
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }
            } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
            }
            if (s0 === peg$FAILED) {
                s0 = peg$currPos;
                s1 = peg$parse_();
                if (s1 !== peg$FAILED) {
                    s2 = peg$parsewordprox_oper();
                    if (s2 !== peg$FAILED) {
                        s3 = peg$parseEOF();
                        if (s3 !== peg$FAILED) {
                            peg$savedPos = s0;
                            s1 = peg$c19(s1);
                            s0 = s1;
                        } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                        }
                    } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                    }
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }
            }
            peg$silentFails--;
            if (s0 === peg$FAILED) {
                s1 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c17); }
            }

            return s0;
        }

        function peg$parsewordprox_oper() {
            var s0, s1, s2, s3, s4, s5;

            peg$silentFails++;
            s0 = peg$currPos;
            if (peg$c21.test(input.charAt(peg$currPos))) {
                s1 = input.charAt(peg$currPos);
                peg$currPos++;
            } else {
                s1 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c22); }
            }
            if (s1 !== peg$FAILED) {
                s2 = peg$currPos;
                peg$silentFails++;
                s3 = peg$parse_();
                peg$silentFails--;
                if (s3 !== peg$FAILED) {
                    peg$currPos = s2;
                    s2 = void 0;
                } else {
                    s2 = peg$FAILED;
                }
                if (s2 !== peg$FAILED) {
                    peg$savedPos = s0;
                    s1 = peg$c23(s1);
                    s0 = s1;
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }
            } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
            }
            if (s0 === peg$FAILED) {
                s0 = peg$currPos;
                if (peg$c24.test(input.charAt(peg$currPos))) {
                    s1 = input.charAt(peg$currPos);
                    peg$currPos++;
                } else {
                    s1 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c25); }
                }
                if (s1 !== peg$FAILED) {
                    s2 = [];
                    if (peg$c24.test(input.charAt(peg$currPos))) {
                        s3 = input.charAt(peg$currPos);
                        peg$currPos++;
                    } else {
                        s3 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c25); }
                    }
                    while (s3 !== peg$FAILED) {
                        s2.push(s3);
                        if (peg$c24.test(input.charAt(peg$currPos))) {
                            s3 = input.charAt(peg$currPos);
                            peg$currPos++;
                        } else {
                            s3 = peg$FAILED;
                            if (peg$silentFails === 0) { peg$fail(peg$c25); }
                        }
                    }
                    if (s2 !== peg$FAILED) {
                        if (peg$c21.test(input.charAt(peg$currPos))) {
                            s3 = input.charAt(peg$currPos);
                            peg$currPos++;
                        } else {
                            s3 = peg$FAILED;
                            if (peg$silentFails === 0) { peg$fail(peg$c22); }
                        }
                        if (s3 !== peg$FAILED) {
                            s4 = peg$currPos;
                            peg$silentFails++;
                            s5 = peg$parse_();
                            peg$silentFails--;
                            if (s5 !== peg$FAILED) {
                                peg$currPos = s4;
                                s4 = void 0;
                            } else {
                                s4 = peg$FAILED;
                            }
                            if (s4 !== peg$FAILED) {
                                peg$savedPos = s0;
                                s1 = peg$c26(s1, s2, s3);
                                s0 = s1;
                            } else {
                                peg$currPos = s0;
                                s0 = peg$FAILED;
                            }
                        } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                        }
                    } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                    }
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }
            }
            peg$silentFails--;
            if (s0 === peg$FAILED) {
                s1 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c20); }
            }

            return s0;
        }

        function peg$parseignored_wpterm() {
            var s0, s1;

            peg$silentFails++;
            s0 = peg$parsewordprox_logical_op();
            peg$silentFails--;
            if (s0 === peg$FAILED) {
                s1 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c27); }
            }

            return s0;
        }

        function peg$parseunquoted_wpterm() {
            var s0, s1, s2, s3;

            peg$silentFails++;
            s0 = peg$currPos;
            s1 = peg$currPos;
            peg$silentFails++;
            s2 = peg$parseprohibited_wpterm();
            peg$silentFails--;
            if (s2 === peg$FAILED) {
                s1 = void 0;
            } else {
                peg$currPos = s1;
                s1 = peg$FAILED;
            }
            if (s1 !== peg$FAILED) {
                s2 = [];
                s3 = peg$parseterm_char();
                if (s3 !== peg$FAILED) {
                    while (s3 !== peg$FAILED) {
                        s2.push(s3);
                        s3 = peg$parseterm_char();
                    }
                } else {
                    s2 = peg$FAILED;
                }
                if (s2 !== peg$FAILED) {
                    peg$savedPos = s0;
                    s1 = peg$c29(s2);
                    s0 = s1;
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }
            } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
            }
            peg$silentFails--;
            if (s0 === peg$FAILED) {
                s1 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c28); }
            }

            return s0;
        }

        function peg$parseprohibited_wpterm() {
            var s0, s1;

            peg$silentFails++;
            s0 = peg$parsewordprox_oper();
            peg$silentFails--;
            if (s0 === peg$FAILED) {
                s1 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c30); }
            }

            return s0;
        }

        function peg$parsewordprox_decorated_term() {
            var s0, s1, s2, s3;

            s0 = peg$currPos;
            s1 = peg$parseunquoted_wpterm();
            if (s1 !== peg$FAILED) {
                s2 = peg$parsedecoration_separator();
                if (s2 !== peg$FAILED) {
                    s3 = peg$parsequoted_term();
                    if (s3 !== peg$FAILED) {
                        peg$savedPos = s0;
                        s1 = peg$c31(s1, s3);
                        s0 = s1;
                    } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                    }
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }
            } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
            }
            if (s0 === peg$FAILED) {
                s0 = peg$currPos;
                s1 = peg$parsequoted_term();
                if (s1 !== peg$FAILED) {
                    s2 = peg$parsedecoration_separator();
                    if (s2 !== peg$FAILED) {
                        s3 = peg$parsequoted_term();
                        if (s3 !== peg$FAILED) {
                            peg$savedPos = s0;
                            s1 = peg$c31(s1, s3);
                            s0 = s1;
                        } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                        }
                    } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                    }
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }
            }

            return s0;
        }

        function peg$parsewordprox_term() {
            var s0, s1;

            peg$silentFails++;
            s0 = peg$currPos;
            s1 = peg$parseignored_wpterm();
            if (s1 !== peg$FAILED) {
                peg$savedPos = s0;
                s1 = peg$c33(s1);
            }
            s0 = s1;
            if (s0 === peg$FAILED) {
                s0 = peg$currPos;
                s1 = peg$parseunquoted_wpterm();
                if (s1 !== peg$FAILED) {
                    peg$savedPos = s0;
                    s1 = peg$c34(s1);
                }
                s0 = s1;
                if (s0 === peg$FAILED) {
                    s0 = peg$currPos;
                    s1 = peg$parsewordprox_decorated_term();
                    if (s1 !== peg$FAILED) {
                        peg$savedPos = s0;
                        s1 = peg$c35(s1);
                    }
                    s0 = s1;
                    if (s0 === peg$FAILED) {
                        s0 = peg$currPos;
                        s1 = peg$parsequoted_term();
                        if (s1 !== peg$FAILED) {
                            peg$savedPos = s0;
                            s1 = peg$c36(s1);
                        }
                        s0 = s1;
                        if (s0 === peg$FAILED) {
                            s0 = peg$currPos;
                            s1 = peg$parsewordprox_plist();
                            if (s1 !== peg$FAILED) {
                                peg$savedPos = s0;
                                s1 = peg$c37(s1);
                            }
                            s0 = s1;
                        }
                    }
                }
            }
            peg$silentFails--;
            if (s0 === peg$FAILED) {
                s1 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c32); }
            }

            return s0;
        }

        function peg$parsewordprox_logical_op() {
            var s0, s1, s2, s3, s4, s5;

            peg$silentFails++;
            s0 = peg$currPos;
            if (peg$c39.test(input.charAt(peg$currPos))) {
                s1 = input.charAt(peg$currPos);
                peg$currPos++;
            } else {
                s1 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c40); }
            }
            if (s1 !== peg$FAILED) {
                if (peg$c41.test(input.charAt(peg$currPos))) {
                    s2 = input.charAt(peg$currPos);
                    peg$currPos++;
                } else {
                    s2 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c42); }
                }
                if (s2 !== peg$FAILED) {
                    if (peg$c43.test(input.charAt(peg$currPos))) {
                        s3 = input.charAt(peg$currPos);
                        peg$currPos++;
                    } else {
                        s3 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c44); }
                    }
                    if (s3 !== peg$FAILED) {
                        s4 = peg$currPos;
                        peg$silentFails++;
                        s5 = peg$parsewordprox_separator();
                        peg$silentFails--;
                        if (s5 !== peg$FAILED) {
                            peg$currPos = s4;
                            s4 = void 0;
                        } else {
                            s4 = peg$FAILED;
                        }
                        if (s4 !== peg$FAILED) {
                            peg$savedPos = s0;
                            s1 = peg$c45();
                            s0 = s1;
                        } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                        }
                    } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                    }
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }
            } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
            }
            if (s0 === peg$FAILED) {
                s0 = peg$currPos;
                if (peg$c46.test(input.charAt(peg$currPos))) {
                    s1 = input.charAt(peg$currPos);
                    peg$currPos++;
                } else {
                    s1 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c47); }
                }
                if (s1 !== peg$FAILED) {
                    if (peg$c48.test(input.charAt(peg$currPos))) {
                        s2 = input.charAt(peg$currPos);
                        peg$currPos++;
                    } else {
                        s2 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c49); }
                    }
                    if (s2 !== peg$FAILED) {
                        s3 = peg$currPos;
                        peg$silentFails++;
                        s4 = peg$parsewordprox_separator();
                        peg$silentFails--;
                        if (s4 !== peg$FAILED) {
                            peg$currPos = s3;
                            s3 = void 0;
                        } else {
                            s3 = peg$FAILED;
                        }
                        if (s3 !== peg$FAILED) {
                            peg$savedPos = s0;
                            s1 = peg$c50();
                            s0 = s1;
                        } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                        }
                    } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                    }
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }
                if (s0 === peg$FAILED) {
                    s0 = peg$currPos;
                    if (peg$c41.test(input.charAt(peg$currPos))) {
                        s1 = input.charAt(peg$currPos);
                        peg$currPos++;
                    } else {
                        s1 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c42); }
                    }
                    if (s1 !== peg$FAILED) {
                        if (peg$c46.test(input.charAt(peg$currPos))) {
                            s2 = input.charAt(peg$currPos);
                            peg$currPos++;
                        } else {
                            s2 = peg$FAILED;
                            if (peg$silentFails === 0) { peg$fail(peg$c47); }
                        }
                        if (s2 !== peg$FAILED) {
                            if (peg$c51.test(input.charAt(peg$currPos))) {
                                s3 = input.charAt(peg$currPos);
                                peg$currPos++;
                            } else {
                                s3 = peg$FAILED;
                                if (peg$silentFails === 0) { peg$fail(peg$c52); }
                            }
                            if (s3 !== peg$FAILED) {
                                s4 = peg$currPos;
                                peg$silentFails++;
                                s5 = peg$parsewordprox_separator();
                                peg$silentFails--;
                                if (s5 !== peg$FAILED) {
                                    peg$currPos = s4;
                                    s4 = void 0;
                                } else {
                                    s4 = peg$FAILED;
                                }
                                if (s4 !== peg$FAILED) {
                                    peg$savedPos = s0;
                                    s1 = peg$c53();
                                    s0 = s1;
                                } else {
                                    peg$currPos = s0;
                                    s0 = peg$FAILED;
                                }
                            } else {
                                peg$currPos = s0;
                                s0 = peg$FAILED;
                            }
                        } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                        }
                    } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                    }
                }
            }
            peg$silentFails--;
            if (s0 === peg$FAILED) {
                s1 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c38); }
            }

            return s0;
        }

        function peg$parsewordprox_separator() {
            var s0, s1;

            peg$silentFails++;
            s0 = peg$parse_();
            if (s0 === peg$FAILED) {
                s0 = peg$parseEOF();
                if (s0 === peg$FAILED) {
                    if (peg$c55.test(input.charAt(peg$currPos))) {
                        s0 = input.charAt(peg$currPos);
                        peg$currPos++;
                    } else {
                        s0 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c56); }
                    }
                }
            }
            peg$silentFails--;
            if (s0 === peg$FAILED) {
                s1 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c54); }
            }

            return s0;
        }

        function peg$parsewordprox_start() {
            var s0, s1;

            peg$silentFails++;
            if (input.charCodeAt(peg$currPos) === 60) {
                s0 = peg$c58;
                peg$currPos++;
            } else {
                s0 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c59); }
            }
            peg$silentFails--;
            if (s0 === peg$FAILED) {
                s1 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c57); }
            }

            return s0;
        }

        function peg$parsewordprox_end() {
            var s0, s1;

            peg$silentFails++;
            if (input.charCodeAt(peg$currPos) === 62) {
                s0 = peg$c61;
                peg$currPos++;
            } else {
                s0 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c62); }
            }
            peg$silentFails--;
            if (s0 === peg$FAILED) {
                s1 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c60); }
            }

            return s0;
        }

        function peg$parsegroup_exp() {
            var s0, s1, s2;

            s0 = peg$currPos;
            s1 = peg$parsefield_exp();
            if (s1 !== peg$FAILED) {
                s2 = peg$parse_();
                if (s2 === peg$FAILED) {
                    s2 = null;
                }
                if (s2 !== peg$FAILED) {
                    peg$savedPos = s0;
                    s1 = peg$c63(s1, s2);
                    s0 = s1;
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }
            } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
            }
            if (s0 === peg$FAILED) {
                s0 = peg$currPos;
                s1 = peg$parseparen_exp();
                if (s1 !== peg$FAILED) {
                    peg$savedPos = s0;
                    s1 = peg$c64(s1);
                }
                s0 = s1;
                if (s0 === peg$FAILED) {
                    s0 = peg$currPos;
                    s1 = peg$parsewordprox_total_query();
                    if (s1 !== peg$FAILED) {
                        peg$savedPos = s0;
                        s1 = peg$c65(s1);
                    }
                    s0 = s1;
                }
            }

            return s0;
        }

        function peg$parseparen_exp() {
            var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9;

            s0 = peg$currPos;
            s1 = peg$parseleft_paren();
            if (s1 !== peg$FAILED) {
                s2 = peg$parse_();
                if (s2 === peg$FAILED) {
                    s2 = null;
                }
                if (s2 !== peg$FAILED) {
                    s3 = [];
                    s4 = peg$parsenode();
                    if (s4 !== peg$FAILED) {
                        while (s4 !== peg$FAILED) {
                            s3.push(s4);
                            s4 = peg$parsenode();
                        }
                    } else {
                        s3 = peg$FAILED;
                    }
                    if (s3 !== peg$FAILED) {
                        s4 = peg$parse_();
                        if (s4 === peg$FAILED) {
                            s4 = null;
                        }
                        if (s4 !== peg$FAILED) {
                            s5 = peg$parseannotation();
                            if (s5 !== peg$FAILED) {
                                s6 = peg$parseright_paren();
                                if (s6 !== peg$FAILED) {
                                    s7 = peg$parsemmodifier_group();
                                    if (s7 === peg$FAILED) {
                                        s7 = null;
                                    }
                                    if (s7 !== peg$FAILED) {
                                        s8 = peg$parse_();
                                        if (s8 === peg$FAILED) {
                                            s8 = null;
                                        }
                                        if (s8 !== peg$FAILED) {
                                            s9 = peg$parse_();
                                            if (s9 === peg$FAILED) {
                                                s9 = null;
                                            }
                                            if (s9 !== peg$FAILED) {
                                                peg$savedPos = s0;
                                                s1 = peg$c66(s1, s8, s3, s4, s5, s6, s7, s9);
                                                s0 = s1;
                                            } else {
                                                peg$currPos = s0;
                                                s0 = peg$FAILED;
                                            }
                                        } else {
                                            peg$currPos = s0;
                                            s0 = peg$FAILED;
                                        }
                                    } else {
                                        peg$currPos = s0;
                                        s0 = peg$FAILED;
                                    }
                                } else {
                                    peg$currPos = s0;
                                    s0 = peg$FAILED;
                                }
                            } else {
                                peg$currPos = s0;
                                s0 = peg$FAILED;
                            }
                        } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                        }
                    } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                    }
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }
            } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
            }
            if (s0 === peg$FAILED) {
                s0 = peg$currPos;
                s1 = peg$parseleft_paren();
                if (s1 !== peg$FAILED) {
                    s2 = peg$parse_();
                    if (s2 === peg$FAILED) {
                        s2 = null;
                    }
                    if (s2 !== peg$FAILED) {
                        s3 = [];
                        s4 = peg$parsenode();
                        if (s4 !== peg$FAILED) {
                            while (s4 !== peg$FAILED) {
                                s3.push(s4);
                                s4 = peg$parsenode();
                            }
                        } else {
                            s3 = peg$FAILED;
                        }
                        if (s3 !== peg$FAILED) {
                            s4 = peg$parse_();
                            if (s4 === peg$FAILED) {
                                s4 = null;
                            }
                            if (s4 !== peg$FAILED) {
                                s5 = peg$parseright_paren();
                                if (s5 !== peg$FAILED) {
                                    s6 = peg$parsemmodifier_group();
                                    if (s6 === peg$FAILED) {
                                        s6 = null;
                                    }
                                    if (s6 !== peg$FAILED) {
                                        s7 = peg$parse_();
                                        if (s7 === peg$FAILED) {
                                            s7 = null;
                                        }
                                        if (s7 !== peg$FAILED) {
                                            peg$savedPos = s0;
                                            s1 = peg$c67(s1, s2, s3, s4, s5, s6, s7);
                                            s0 = s1;
                                        } else {
                                            peg$currPos = s0;
                                            s0 = peg$FAILED;
                                        }
                                    } else {
                                        peg$currPos = s0;
                                        s0 = peg$FAILED;
                                    }
                                } else {
                                    peg$currPos = s0;
                                    s0 = peg$FAILED;
                                }
                            } else {
                                peg$currPos = s0;
                                s0 = peg$FAILED;
                            }
                        } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                        }
                    } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                    }
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }
            }

            return s0;
        }

        function peg$parsefield_exp() {
            var s0, s1, s2, s3;

            s0 = peg$currPos;
            s1 = peg$parsefieldname();
            if (s1 === peg$FAILED) {
                s1 = null;
            }
            if (s1 !== peg$FAILED) {
                s2 = peg$parserange_operator_exp();
                if (s2 !== peg$FAILED) {
                    peg$savedPos = s0;
                    s1 = peg$c68(s1, s2);
                    s0 = s1;
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }
            } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
            }
            if (s0 === peg$FAILED) {
                s0 = peg$currPos;
                s1 = peg$parsefieldname();
                if (s1 !== peg$FAILED) {
                    s2 = peg$parseparen_exp();
                    if (s2 !== peg$FAILED) {
                        peg$savedPos = s0;
                        s1 = peg$c69(s1, s2);
                        s0 = s1;
                    } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                    }
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }
                if (s0 === peg$FAILED) {
                    s0 = peg$currPos;
                    s1 = peg$parsefieldname();
                    if (s1 === peg$FAILED) {
                        s1 = null;
                    }
                    if (s1 !== peg$FAILED) {
                        s2 = peg$parseany_term();
                        if (s2 !== peg$FAILED) {
                            peg$savedPos = s0;
                            s1 = peg$c70(s1, s2);
                            s0 = s1;
                        } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                        }
                    } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                    }
                    if (s0 === peg$FAILED) {
                        s0 = peg$currPos;
                        s1 = peg$parsefieldname();
                        if (s1 !== peg$FAILED) {
                            s2 = peg$parseEOF();
                            if (s2 !== peg$FAILED) {
                                peg$savedPos = s0;
                                s1 = peg$c71(s1);
                                s0 = s1;
                            } else {
                                peg$currPos = s0;
                                s0 = peg$FAILED;
                            }
                        } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                        }
                        if (s0 === peg$FAILED) {
                            s0 = peg$currPos;
                            s1 = peg$parsefieldname();
                            if (s1 !== peg$FAILED) {
                                s2 = [];
                                s3 = peg$parse_();
                                while (s3 !== peg$FAILED) {
                                    s2.push(s3);
                                    s3 = peg$parse_();
                                }
                                if (s2 !== peg$FAILED) {
                                    s3 = peg$parsewordprox_total_query();
                                    if (s3 !== peg$FAILED) {
                                        peg$savedPos = s0;
                                        s1 = peg$c72(s1, s2, s3);
                                        s0 = s1;
                                    } else {
                                        peg$currPos = s0;
                                        s0 = peg$FAILED;
                                    }
                                } else {
                                    peg$currPos = s0;
                                    s0 = peg$FAILED;
                                }
                            } else {
                                peg$currPos = s0;
                                s0 = peg$FAILED;
                            }
                        }
                    }
                }
            }

            return s0;
        }

        function peg$parsefieldname() {
            var s0, s1, s2, s3, s4;

            peg$silentFails++;
            s0 = peg$currPos;
            s1 = peg$parseunquoted_term();
            if (s1 !== peg$FAILED) {
                s2 = peg$parsefieldname_terminator();
                if (s2 !== peg$FAILED) {
                    s3 = [];
                    s4 = peg$parse_();
                    while (s4 !== peg$FAILED) {
                        s3.push(s4);
                        s4 = peg$parse_();
                    }
                    if (s3 !== peg$FAILED) {
                        peg$savedPos = s0;
                        s1 = peg$c74(s1, s3);
                        s0 = s1;
                    } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                    }
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }
            } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
            }
            peg$silentFails--;
            if (s0 === peg$FAILED) {
                s1 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c73); }
            }

            return s0;
        }

        function peg$parseany_term() {
            var s0, s1;

            peg$silentFails++;
            s0 = peg$parsedecorated_term();
            if (s0 === peg$FAILED) {
                s0 = peg$parseterm();
            }
            peg$silentFails--;
            if (s0 === peg$FAILED) {
                s1 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c28); }
            }

            return s0;
        }

        function peg$parsedecorated_term() {
            var s0, s1, s2, s3, s4, s5, s6;

            s0 = peg$currPos;
            s1 = peg$parseprefix_operator_exp();
            if (s1 === peg$FAILED) {
                s1 = null;
            }
            if (s1 !== peg$FAILED) {
                s2 = peg$parseunquoted_term();
                if (s2 !== peg$FAILED) {
                    s3 = peg$parsedecoration_separator();
                    if (s3 !== peg$FAILED) {
                        s4 = peg$parseterm();
                        if (s4 !== peg$FAILED) {
                            s5 = peg$parsepmodifier_group();
                            if (s5 === peg$FAILED) {
                                s5 = null;
                            }
                            if (s5 !== peg$FAILED) {
                                s6 = peg$parse_();
                                if (s6 === peg$FAILED) {
                                    s6 = null;
                                }
                                if (s6 !== peg$FAILED) {
                                    peg$savedPos = s0;
                                    s1 = peg$c75(s1, s2, s4, s5, s6);
                                    s0 = s1;
                                } else {
                                    peg$currPos = s0;
                                    s0 = peg$FAILED;
                                }
                            } else {
                                peg$currPos = s0;
                                s0 = peg$FAILED;
                            }
                        } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                        }
                    } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                    }
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }
            } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
            }
            if (s0 === peg$FAILED) {
                s0 = peg$currPos;
                s1 = peg$parseprefix_operator_exp();
                if (s1 === peg$FAILED) {
                    s1 = null;
                }
                if (s1 !== peg$FAILED) {
                    s2 = peg$parsequoted_term();
                    if (s2 !== peg$FAILED) {
                        s3 = peg$parsedecoration_separator();
                        if (s3 !== peg$FAILED) {
                            s4 = peg$parseterm();
                            if (s4 !== peg$FAILED) {
                                s5 = peg$parsepmodifier_group();
                                if (s5 === peg$FAILED) {
                                    s5 = null;
                                }
                                if (s5 !== peg$FAILED) {
                                    s6 = peg$parse_();
                                    if (s6 === peg$FAILED) {
                                        s6 = null;
                                    }
                                    if (s6 !== peg$FAILED) {
                                        peg$savedPos = s0;
                                        s1 = peg$c76(s1, s2, s4, s5, s6);
                                        s0 = s1;
                                    } else {
                                        peg$currPos = s0;
                                        s0 = peg$FAILED;
                                    }
                                } else {
                                    peg$currPos = s0;
                                    s0 = peg$FAILED;
                                }
                            } else {
                                peg$currPos = s0;
                                s0 = peg$FAILED;
                            }
                        } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                        }
                    } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                    }
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }
            }

            return s0;
        }

        function peg$parseterm() {
            var s0, s1, s2, s3, s4;

            peg$silentFails++;
            s0 = peg$currPos;
            s1 = peg$parseprefix_operator_exp();
            if (s1 === peg$FAILED) {
                s1 = null;
            }
            if (s1 !== peg$FAILED) {
                s2 = peg$parsequoted_term();
                if (s2 !== peg$FAILED) {
                    s3 = peg$parsepmodifier_group();
                    if (s3 === peg$FAILED) {
                        s3 = null;
                    }
                    if (s3 !== peg$FAILED) {
                        s4 = peg$parse_();
                        if (s4 === peg$FAILED) {
                            s4 = null;
                        }
                        if (s4 !== peg$FAILED) {
                            peg$savedPos = s0;
                            s1 = peg$c77(s1, s2, s3, s4);
                            s0 = s1;
                        } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                        }
                    } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                    }
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }
            } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
            }
            if (s0 === peg$FAILED) {
                s0 = peg$currPos;
                s1 = peg$parseprefix_operator_exp();
                if (s1 === peg$FAILED) {
                    s1 = null;
                }
                if (s1 !== peg$FAILED) {
                    s2 = peg$parseunquoted_term();
                    if (s2 !== peg$FAILED) {
                        s3 = peg$parsesmodifier_group();
                        if (s3 === peg$FAILED) {
                            s3 = null;
                        }
                        if (s3 !== peg$FAILED) {
                            s4 = peg$parse_();
                            if (s4 === peg$FAILED) {
                                s4 = null;
                            }
                            if (s4 !== peg$FAILED) {
                                peg$savedPos = s0;
                                s1 = peg$c78(s1, s2, s3, s4);
                                s0 = s1;
                            } else {
                                peg$currPos = s0;
                                s0 = peg$FAILED;
                            }
                        } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                        }
                    } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                    }
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }
            }
            peg$silentFails--;
            if (s0 === peg$FAILED) {
                s1 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c28); }
            }

            return s0;
        }

        function peg$parseunquoted_term() {
            var s0, s1, s2, s3;

            peg$silentFails++;
            s0 = peg$currPos;
            s1 = peg$currPos;
            peg$silentFails++;
            s2 = peg$parsewordprox_oper();
            peg$silentFails--;
            if (s2 === peg$FAILED) {
                s1 = void 0;
            } else {
                peg$currPos = s1;
                s1 = peg$FAILED;
            }
            if (s1 !== peg$FAILED) {
                s2 = [];
                s3 = peg$parseterm_char();
                if (s3 !== peg$FAILED) {
                    while (s3 !== peg$FAILED) {
                        s2.push(s3);
                        s3 = peg$parseterm_char();
                    }
                } else {
                    s2 = peg$FAILED;
                }
                if (s2 !== peg$FAILED) {
                    peg$savedPos = s0;
                    s1 = peg$c79(s2);
                    s0 = s1;
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }
            } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
            }
            peg$silentFails--;
            if (s0 === peg$FAILED) {
                s1 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c28); }
            }

            return s0;
        }

        function peg$parseterm_char() {
            var s0;

            if (input.charCodeAt(peg$currPos) === 46) {
                s0 = peg$c80;
                peg$currPos++;
            } else {
                s0 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c81); }
            }
            if (s0 === peg$FAILED) {
                if (peg$c82.test(input.charAt(peg$currPos))) {
                    s0 = input.charAt(peg$currPos);
                    peg$currPos++;
                } else {
                    s0 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c83); }
                }
            }

            return s0;
        }

        function peg$parseannotation() {
            var s0, s1, s2, s3;

            peg$silentFails++;
            s0 = peg$currPos;
            if (peg$c85.test(input.charAt(peg$currPos))) {
                s1 = input.charAt(peg$currPos);
                peg$currPos++;
            } else {
                s1 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c86); }
            }
            if (s1 !== peg$FAILED) {
                s2 = [];
                if (peg$c87.test(input.charAt(peg$currPos))) {
                    s3 = input.charAt(peg$currPos);
                    peg$currPos++;
                } else {
                    s3 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c88); }
                }
                while (s3 !== peg$FAILED) {
                    s2.push(s3);
                    if (peg$c87.test(input.charAt(peg$currPos))) {
                        s3 = input.charAt(peg$currPos);
                        peg$currPos++;
                    } else {
                        s3 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c88); }
                    }
                }
                if (s2 !== peg$FAILED) {
                    if (peg$c85.test(input.charAt(peg$currPos))) {
                        s3 = input.charAt(peg$currPos);
                        peg$currPos++;
                    } else {
                        s3 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c86); }
                    }
                    if (s3 !== peg$FAILED) {
                        peg$savedPos = s0;
                        s1 = peg$c89(s2);
                        s0 = s1;
                    } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                    }
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }
            } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
            }
            peg$silentFails--;
            if (s0 === peg$FAILED) {
                s1 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c84); }
            }

            return s0;
        }

        function peg$parsequoted_term() {
            var s0, s1, s2, s3;

            peg$silentFails++;
            s0 = peg$currPos;
            if (input.charCodeAt(peg$currPos) === 34) {
                s1 = peg$c90;
                peg$currPos++;
            } else {
                s1 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c91); }
            }
            if (s1 !== peg$FAILED) {
                s2 = [];
                if (peg$c92.test(input.charAt(peg$currPos))) {
                    s3 = input.charAt(peg$currPos);
                    peg$currPos++;
                } else {
                    s3 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c93); }
                }
                while (s3 !== peg$FAILED) {
                    s2.push(s3);
                    if (peg$c92.test(input.charAt(peg$currPos))) {
                        s3 = input.charAt(peg$currPos);
                        peg$currPos++;
                    } else {
                        s3 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c93); }
                    }
                }
                if (s2 !== peg$FAILED) {
                    if (input.charCodeAt(peg$currPos) === 34) {
                        s3 = peg$c90;
                        peg$currPos++;
                    } else {
                        s3 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c91); }
                    }
                    if (s3 !== peg$FAILED) {
                        peg$savedPos = s0;
                        s1 = peg$c94(s2);
                        s0 = s1;
                    } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                    }
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }
            } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
            }
            peg$silentFails--;
            if (s0 === peg$FAILED) {
                s1 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c28); }
            }

            return s0;
        }

        function peg$parsemmodifier_group() {
            var s0, s1, s2, s3;

            peg$silentFails++;
            s0 = peg$currPos;
            s1 = peg$parseproximity_modifier();
            if (s1 !== peg$FAILED) {
                s2 = peg$parsefuzzy_modifier();
                if (s2 !== peg$FAILED) {
                    s3 = peg$parseboost_modifier();
                    if (s3 === peg$FAILED) {
                        s3 = null;
                    }
                    if (s3 !== peg$FAILED) {
                        peg$savedPos = s0;
                        s1 = peg$c96(s1, s2, s3);
                        s0 = s1;
                    } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                    }
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }
            } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
            }
            if (s0 === peg$FAILED) {
                s0 = peg$currPos;
                s1 = peg$parseproximity_modifier();
                if (s1 !== peg$FAILED) {
                    s2 = peg$parseboost_modifier();
                    if (s2 === peg$FAILED) {
                        s2 = null;
                    }
                    if (s2 !== peg$FAILED) {
                        s3 = peg$parsefuzzy_modifier();
                        if (s3 === peg$FAILED) {
                            s3 = null;
                        }
                        if (s3 !== peg$FAILED) {
                            peg$savedPos = s0;
                            s1 = peg$c97(s1, s2, s3);
                            s0 = s1;
                        } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                        }
                    } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                    }
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }
                if (s0 === peg$FAILED) {
                    s0 = peg$currPos;
                    s1 = peg$parsefuzzy_modifier();
                    if (s1 !== peg$FAILED) {
                        s2 = peg$parseproximity_modifier();
                        if (s2 !== peg$FAILED) {
                            s3 = peg$parseboost_modifier();
                            if (s3 === peg$FAILED) {
                                s3 = null;
                            }
                            if (s3 !== peg$FAILED) {
                                peg$savedPos = s0;
                                s1 = peg$c98(s1, s2, s3);
                                s0 = s1;
                            } else {
                                peg$currPos = s0;
                                s0 = peg$FAILED;
                            }
                        } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                        }
                    } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                    }
                    if (s0 === peg$FAILED) {
                        s0 = peg$currPos;
                        s1 = peg$parsefuzzy_modifier();
                        if (s1 !== peg$FAILED) {
                            s2 = peg$parseboost_modifier();
                            if (s2 === peg$FAILED) {
                                s2 = null;
                            }
                            if (s2 !== peg$FAILED) {
                                s3 = peg$parseproximity_modifier();
                                if (s3 === peg$FAILED) {
                                    s3 = null;
                                }
                                if (s3 !== peg$FAILED) {
                                    peg$savedPos = s0;
                                    s1 = peg$c99(s1, s2, s3);
                                    s0 = s1;
                                } else {
                                    peg$currPos = s0;
                                    s0 = peg$FAILED;
                                }
                            } else {
                                peg$currPos = s0;
                                s0 = peg$FAILED;
                            }
                        } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                        }
                        if (s0 === peg$FAILED) {
                            s0 = peg$currPos;
                            s1 = peg$parseboost_modifier();
                            if (s1 !== peg$FAILED) {
                                s2 = peg$parsefuzzy_modifier();
                                if (s2 !== peg$FAILED) {
                                    s3 = peg$parseproximity_modifier();
                                    if (s3 === peg$FAILED) {
                                        s3 = null;
                                    }
                                    if (s3 !== peg$FAILED) {
                                        peg$savedPos = s0;
                                        s1 = peg$c100(s1, s2, s3);
                                        s0 = s1;
                                    } else {
                                        peg$currPos = s0;
                                        s0 = peg$FAILED;
                                    }
                                } else {
                                    peg$currPos = s0;
                                    s0 = peg$FAILED;
                                }
                            } else {
                                peg$currPos = s0;
                                s0 = peg$FAILED;
                            }
                            if (s0 === peg$FAILED) {
                                s0 = peg$currPos;
                                s1 = peg$parseboost_modifier();
                                if (s1 !== peg$FAILED) {
                                    s2 = peg$parseproximity_modifier();
                                    if (s2 === peg$FAILED) {
                                        s2 = null;
                                    }
                                    if (s2 !== peg$FAILED) {
                                        s3 = peg$parsefuzzy_modifier();
                                        if (s3 === peg$FAILED) {
                                            s3 = null;
                                        }
                                        if (s3 !== peg$FAILED) {
                                            peg$savedPos = s0;
                                            s1 = peg$c101(s1, s2, s3);
                                            s0 = s1;
                                        } else {
                                            peg$currPos = s0;
                                            s0 = peg$FAILED;
                                        }
                                    } else {
                                        peg$currPos = s0;
                                        s0 = peg$FAILED;
                                    }
                                } else {
                                    peg$currPos = s0;
                                    s0 = peg$FAILED;
                                }
                            }
                        }
                    }
                }
            }
            peg$silentFails--;
            if (s0 === peg$FAILED) {
                s1 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c95); }
            }

            return s0;
        }

        function peg$parsepmodifier_group() {
            var s0, s1, s2;

            peg$silentFails++;
            s0 = peg$currPos;
            s1 = peg$parseproximity_modifier();
            if (s1 !== peg$FAILED) {
                s2 = peg$parseboost_modifier();
                if (s2 === peg$FAILED) {
                    s2 = null;
                }
                if (s2 !== peg$FAILED) {
                    peg$savedPos = s0;
                    s1 = peg$c103(s1, s2);
                    s0 = s1;
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }
            } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
            }
            if (s0 === peg$FAILED) {
                s0 = peg$currPos;
                s1 = peg$parseboost_modifier();
                if (s1 !== peg$FAILED) {
                    s2 = peg$parseproximity_modifier();
                    if (s2 === peg$FAILED) {
                        s2 = null;
                    }
                    if (s2 !== peg$FAILED) {
                        peg$savedPos = s0;
                        s1 = peg$c104(s1, s2);
                        s0 = s1;
                    } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                    }
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }
            }
            peg$silentFails--;
            if (s0 === peg$FAILED) {
                s1 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c102); }
            }

            return s0;
        }

        function peg$parseproximity_modifier() {
            var s0, s1, s2, s3;

            peg$silentFails++;
            s0 = peg$currPos;
            s1 = [];
            s2 = peg$parse_();
            while (s2 !== peg$FAILED) {
                s1.push(s2);
                s2 = peg$parse_();
            }
            if (s1 !== peg$FAILED) {
                if (input.charCodeAt(peg$currPos) === 126) {
                    s2 = peg$c106;
                    peg$currPos++;
                } else {
                    s2 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c107); }
                }
                if (s2 !== peg$FAILED) {
                    s3 = peg$parse_();
                    if (s3 !== peg$FAILED) {
                        peg$savedPos = s0;
                        s1 = peg$c108(s1, s3);
                        s0 = s1;
                    } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                    }
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }
            } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
            }
            if (s0 === peg$FAILED) {
                s0 = peg$currPos;
                s1 = [];
                s2 = peg$parse_();
                while (s2 !== peg$FAILED) {
                    s1.push(s2);
                    s2 = peg$parse_();
                }
                if (s1 !== peg$FAILED) {
                    if (input.charCodeAt(peg$currPos) === 126) {
                        s2 = peg$c106;
                        peg$currPos++;
                    } else {
                        s2 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c107); }
                    }
                    if (s2 !== peg$FAILED) {
                        s3 = peg$parseEOF();
                        if (s3 !== peg$FAILED) {
                            peg$savedPos = s0;
                            s1 = peg$c109(s1);
                            s0 = s1;
                        } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                        }
                    } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                    }
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }
                if (s0 === peg$FAILED) {
                    s0 = peg$currPos;
                    s1 = [];
                    s2 = peg$parse_();
                    while (s2 !== peg$FAILED) {
                        s1.push(s2);
                        s2 = peg$parse_();
                    }
                    if (s1 !== peg$FAILED) {
                        if (input.charCodeAt(peg$currPos) === 126) {
                            s2 = peg$c106;
                            peg$currPos++;
                        } else {
                            s2 = peg$FAILED;
                            if (peg$silentFails === 0) { peg$fail(peg$c107); }
                        }
                        if (s2 !== peg$FAILED) {
                            s3 = peg$parseint_exp();
                            if (s3 !== peg$FAILED) {
                                peg$savedPos = s0;
                                s1 = peg$c110(s1, s3);
                                s0 = s1;
                            } else {
                                peg$currPos = s0;
                                s0 = peg$FAILED;
                            }
                        } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                        }
                    } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                    }
                }
            }
            peg$silentFails--;
            if (s0 === peg$FAILED) {
                s1 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c105); }
            }

            return s0;
        }

        function peg$parsesmodifier_group() {
            var s0, s1, s2;

            peg$silentFails++;
            s0 = peg$currPos;
            s1 = peg$parsefuzzy_modifier();
            if (s1 !== peg$FAILED) {
                s2 = peg$parseboost_modifier();
                if (s2 === peg$FAILED) {
                    s2 = null;
                }
                if (s2 !== peg$FAILED) {
                    peg$savedPos = s0;
                    s1 = peg$c112(s1, s2);
                    s0 = s1;
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }
            } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
            }
            if (s0 === peg$FAILED) {
                s0 = peg$currPos;
                s1 = peg$parseboost_modifier();
                if (s1 !== peg$FAILED) {
                    s2 = peg$parsefuzzy_modifier();
                    if (s2 === peg$FAILED) {
                        s2 = null;
                    }
                    if (s2 !== peg$FAILED) {
                        peg$savedPos = s0;
                        s1 = peg$c113(s1, s2);
                        s0 = s1;
                    } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                    }
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }
            }
            peg$silentFails--;
            if (s0 === peg$FAILED) {
                s1 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c111); }
            }

            return s0;
        }

        function peg$parseboost_modifier() {
            var s0, s1, s2;

            peg$silentFails++;
            s0 = peg$currPos;
            if (input.charCodeAt(peg$currPos) === 94) {
                s1 = peg$c115;
                peg$currPos++;
            } else {
                s1 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c116); }
            }
            if (s1 !== peg$FAILED) {
                s2 = peg$parsedecimal_or_int_exp();
                if (s2 !== peg$FAILED) {
                    peg$savedPos = s0;
                    s1 = peg$c117(s2);
                    s0 = s1;
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }
            } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
            }
            peg$silentFails--;
            if (s0 === peg$FAILED) {
                s1 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c114); }
            }

            return s0;
        }

        function peg$parsefuzzy_modifier() {
            var s0, s1, s2;

            peg$silentFails++;
            s0 = peg$currPos;
            if (input.charCodeAt(peg$currPos) === 37) {
                s1 = peg$c119;
                peg$currPos++;
            } else {
                s1 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c120); }
            }
            if (s1 !== peg$FAILED) {
                s2 = peg$parse_();
                if (s2 !== peg$FAILED) {
                    peg$savedPos = s0;
                    s1 = peg$c121(s2);
                    s0 = s1;
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }
            } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
            }
            if (s0 === peg$FAILED) {
                s0 = peg$currPos;
                if (input.charCodeAt(peg$currPos) === 37) {
                    s1 = peg$c119;
                    peg$currPos++;
                } else {
                    s1 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c120); }
                }
                if (s1 !== peg$FAILED) {
                    s2 = peg$parseEOF();
                    if (s2 !== peg$FAILED) {
                        peg$savedPos = s0;
                        s1 = peg$c122();
                        s0 = s1;
                    } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                    }
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }
                if (s0 === peg$FAILED) {
                    s0 = peg$currPos;
                    if (input.charCodeAt(peg$currPos) === 37) {
                        s1 = peg$c119;
                        peg$currPos++;
                    } else {
                        s1 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c120); }
                    }
                    if (s1 !== peg$FAILED) {
                        s2 = peg$parsefuzzy_exp();
                        if (s2 !== peg$FAILED) {
                            peg$savedPos = s0;
                            s1 = peg$c123(s2);
                            s0 = s1;
                        } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                        }
                    } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                    }
                    if (s0 === peg$FAILED) {
                        s0 = peg$currPos;
                        if (input.charCodeAt(peg$currPos) === 37) {
                            s1 = peg$c119;
                            peg$currPos++;
                        } else {
                            s1 = peg$FAILED;
                            if (peg$silentFails === 0) { peg$fail(peg$c120); }
                        }
                        if (s1 !== peg$FAILED) {
                            peg$savedPos = s0;
                            s1 = peg$c124();
                        }
                        s0 = s1;
                    }
                }
            }
            peg$silentFails--;
            if (s0 === peg$FAILED) {
                s1 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c118); }
            }

            return s0;
        }

        function peg$parsedecimal_or_int_exp() {
            var s0;

            s0 = peg$parsedecimal_exp();
            if (s0 === peg$FAILED) {
                s0 = peg$parseint_exp();
            }

            return s0;
        }

        function peg$parsefuzzy_exp() {
            var s0, s1;

            peg$silentFails++;
            s0 = peg$parseint_exp();
            if (s0 === peg$FAILED) {
                s0 = peg$currPos;
                if (peg$c126.test(input.charAt(peg$currPos))) {
                    s1 = input.charAt(peg$currPos);
                    peg$currPos++;
                } else {
                    s1 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c127); }
                }
                if (s1 !== peg$FAILED) {
                    peg$savedPos = s0;
                    s1 = peg$c128(s1);
                }
                s0 = s1;
            }
            peg$silentFails--;
            if (s0 === peg$FAILED) {
                s1 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c125); }
            }

            return s0;
        }

        function peg$parsedecimal_exp() {
            var s0, s1, s2, s3, s4;

            peg$silentFails++;
            s0 = peg$currPos;
            s1 = [];
            if (peg$c24.test(input.charAt(peg$currPos))) {
                s2 = input.charAt(peg$currPos);
                peg$currPos++;
            } else {
                s2 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c25); }
            }
            if (s2 !== peg$FAILED) {
                while (s2 !== peg$FAILED) {
                    s1.push(s2);
                    if (peg$c24.test(input.charAt(peg$currPos))) {
                        s2 = input.charAt(peg$currPos);
                        peg$currPos++;
                    } else {
                        s2 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c25); }
                    }
                }
            } else {
                s1 = peg$FAILED;
            }
            if (s1 !== peg$FAILED) {
                if (input.charCodeAt(peg$currPos) === 46) {
                    s2 = peg$c80;
                    peg$currPos++;
                } else {
                    s2 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c81); }
                }
                if (s2 !== peg$FAILED) {
                    s3 = [];
                    if (peg$c24.test(input.charAt(peg$currPos))) {
                        s4 = input.charAt(peg$currPos);
                        peg$currPos++;
                    } else {
                        s4 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c25); }
                    }
                    if (s4 !== peg$FAILED) {
                        while (s4 !== peg$FAILED) {
                            s3.push(s4);
                            if (peg$c24.test(input.charAt(peg$currPos))) {
                                s4 = input.charAt(peg$currPos);
                                peg$currPos++;
                            } else {
                                s4 = peg$FAILED;
                                if (peg$silentFails === 0) { peg$fail(peg$c25); }
                            }
                        }
                    } else {
                        s3 = peg$FAILED;
                    }
                    if (s3 !== peg$FAILED) {
                        peg$savedPos = s0;
                        s1 = peg$c130(s1, s3);
                        s0 = s1;
                    } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                    }
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }
            } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
            }
            peg$silentFails--;
            if (s0 === peg$FAILED) {
                s1 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c129); }
            }

            return s0;
        }

        function peg$parseint_exp() {
            var s0, s1, s2;

            peg$silentFails++;
            s0 = peg$currPos;
            s1 = [];
            if (peg$c24.test(input.charAt(peg$currPos))) {
                s2 = input.charAt(peg$currPos);
                peg$currPos++;
            } else {
                s2 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c25); }
            }
            if (s2 !== peg$FAILED) {
                while (s2 !== peg$FAILED) {
                    s1.push(s2);
                    if (peg$c24.test(input.charAt(peg$currPos))) {
                        s2 = input.charAt(peg$currPos);
                        peg$currPos++;
                    } else {
                        s2 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c25); }
                    }
                }
            } else {
                s1 = peg$FAILED;
            }
            if (s1 !== peg$FAILED) {
                peg$savedPos = s0;
                s1 = peg$c132(s1);
            }
            s0 = s1;
            peg$silentFails--;
            if (s0 === peg$FAILED) {
                s1 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c131); }
            }

            return s0;
        }

        function peg$parserange_operator_exp() {
            var s0, s1, s2, s3, s4, s5, s6, s7, s8;

            peg$silentFails++;
            s0 = peg$currPos;
            if (input.charCodeAt(peg$currPos) === 91) {
                s1 = peg$c134;
                peg$currPos++;
            } else {
                s1 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c135); }
            }
            if (s1 !== peg$FAILED) {
                s2 = peg$parseunquoted_term();
                if (s2 !== peg$FAILED) {
                    s3 = peg$parse_();
                    if (s3 !== peg$FAILED) {
                        if (peg$c136.test(input.charAt(peg$currPos))) {
                            s4 = input.charAt(peg$currPos);
                            peg$currPos++;
                        } else {
                            s4 = peg$FAILED;
                            if (peg$silentFails === 0) { peg$fail(peg$c137); }
                        }
                        if (s4 !== peg$FAILED) {
                            if (peg$c138.test(input.charAt(peg$currPos))) {
                                s5 = input.charAt(peg$currPos);
                                peg$currPos++;
                            } else {
                                s5 = peg$FAILED;
                                if (peg$silentFails === 0) { peg$fail(peg$c139); }
                            }
                            if (s5 !== peg$FAILED) {
                                s6 = peg$parse_();
                                if (s6 !== peg$FAILED) {
                                    s7 = peg$parseunquoted_term();
                                    if (s7 !== peg$FAILED) {
                                        if (input.charCodeAt(peg$currPos) === 93) {
                                            s8 = peg$c140;
                                            peg$currPos++;
                                        } else {
                                            s8 = peg$FAILED;
                                            if (peg$silentFails === 0) { peg$fail(peg$c141); }
                                        }
                                        if (s8 !== peg$FAILED) {
                                            peg$savedPos = s0;
                                            s1 = peg$c142(s2, s3, s6, s7);
                                            s0 = s1;
                                        } else {
                                            peg$currPos = s0;
                                            s0 = peg$FAILED;
                                        }
                                    } else {
                                        peg$currPos = s0;
                                        s0 = peg$FAILED;
                                    }
                                } else {
                                    peg$currPos = s0;
                                    s0 = peg$FAILED;
                                }
                            } else {
                                peg$currPos = s0;
                                s0 = peg$FAILED;
                            }
                        } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                        }
                    } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                    }
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }
            } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
            }
            if (s0 === peg$FAILED) {
                s0 = peg$currPos;
                if (input.charCodeAt(peg$currPos) === 123) {
                    s1 = peg$c143;
                    peg$currPos++;
                } else {
                    s1 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c144); }
                }
                if (s1 !== peg$FAILED) {
                    s2 = peg$parseunquoted_term();
                    if (s2 !== peg$FAILED) {
                        s3 = peg$parse_();
                        if (s3 !== peg$FAILED) {
                            if (peg$c136.test(input.charAt(peg$currPos))) {
                                s4 = input.charAt(peg$currPos);
                                peg$currPos++;
                            } else {
                                s4 = peg$FAILED;
                                if (peg$silentFails === 0) { peg$fail(peg$c137); }
                            }
                            if (s4 !== peg$FAILED) {
                                if (peg$c138.test(input.charAt(peg$currPos))) {
                                    s5 = input.charAt(peg$currPos);
                                    peg$currPos++;
                                } else {
                                    s5 = peg$FAILED;
                                    if (peg$silentFails === 0) { peg$fail(peg$c139); }
                                }
                                if (s5 !== peg$FAILED) {
                                    s6 = peg$parse_();
                                    if (s6 !== peg$FAILED) {
                                        s7 = peg$parseunquoted_term();
                                        if (s7 !== peg$FAILED) {
                                            if (input.charCodeAt(peg$currPos) === 125) {
                                                s8 = peg$c145;
                                                peg$currPos++;
                                            } else {
                                                s8 = peg$FAILED;
                                                if (peg$silentFails === 0) { peg$fail(peg$c146); }
                                            }
                                            if (s8 !== peg$FAILED) {
                                                peg$savedPos = s0;
                                                s1 = peg$c147(s2, s3, s6, s7);
                                                s0 = s1;
                                            } else {
                                                peg$currPos = s0;
                                                s0 = peg$FAILED;
                                            }
                                        } else {
                                            peg$currPos = s0;
                                            s0 = peg$FAILED;
                                        }
                                    } else {
                                        peg$currPos = s0;
                                        s0 = peg$FAILED;
                                    }
                                } else {
                                    peg$currPos = s0;
                                    s0 = peg$FAILED;
                                }
                            } else {
                                peg$currPos = s0;
                                s0 = peg$FAILED;
                            }
                        } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                        }
                    } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                    }
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }
            }
            peg$silentFails--;
            if (s0 === peg$FAILED) {
                s1 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c133); }
            }

            return s0;
        }

        function peg$parseoperator_exp() {
            var s0, s1, s2, s3, s4;

            s0 = peg$currPos;
            s1 = peg$parse_();
            if (s1 === peg$FAILED) {
                s1 = null;
            }
            if (s1 !== peg$FAILED) {
                s2 = peg$parseoperator();
                if (s2 !== peg$FAILED) {
                    s3 = peg$parse_();
                    if (s3 !== peg$FAILED) {
                        peg$savedPos = s0;
                        s1 = peg$c148(s1, s2, s3);
                        s0 = s1;
                    } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                    }
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }
            } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
            }
            if (s0 === peg$FAILED) {
                s0 = peg$currPos;
                s1 = peg$parse_();
                if (s1 === peg$FAILED) {
                    s1 = null;
                }
                if (s1 !== peg$FAILED) {
                    s2 = peg$parseoperator();
                    if (s2 !== peg$FAILED) {
                        s3 = peg$parse_();
                        if (s3 !== peg$FAILED) {
                            s4 = peg$parseEOF();
                            if (s4 !== peg$FAILED) {
                                peg$savedPos = s0;
                                s1 = peg$c149(s1, s2, s3);
                                s0 = s1;
                            } else {
                                peg$currPos = s0;
                                s0 = peg$FAILED;
                            }
                        } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                        }
                    } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                    }
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }
            }

            return s0;
        }

        function peg$parseoperator() {
            var s0, s1;

            peg$silentFails++;
            s0 = peg$parseand_not_operator();
            if (s0 === peg$FAILED) {
                s0 = peg$parseor_not_operator();
                if (s0 === peg$FAILED) {
                    s0 = peg$parsenot_operator();
                    if (s0 === peg$FAILED) {
                        s0 = peg$parseor_operator();
                        if (s0 === peg$FAILED) {
                            s0 = peg$parseand_operator();
                        }
                    }
                }
            }
            peg$silentFails--;
            if (s0 === peg$FAILED) {
                s1 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c150); }
            }

            return s0;
        }

        function peg$parseor_not_operator() {
            var s0, s1, s2, s3;

            s0 = peg$currPos;
            s1 = peg$parseor_operator();
            if (s1 !== peg$FAILED) {
                s2 = peg$parse_();
                if (s2 !== peg$FAILED) {
                    s3 = peg$parsenot_operator();
                    if (s3 !== peg$FAILED) {
                        peg$savedPos = s0;
                        s1 = peg$c151(s1, s2, s3);
                        s0 = s1;
                    } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                    }
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }
            } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
            }

            return s0;
        }

        function peg$parseand_not_operator() {
            var s0, s1, s2, s3;

            s0 = peg$currPos;
            s1 = peg$parseand_operator();
            if (s1 !== peg$FAILED) {
                s2 = peg$parse_();
                if (s2 !== peg$FAILED) {
                    s3 = peg$parsenot_operator();
                    if (s3 !== peg$FAILED) {
                        peg$savedPos = s0;
                        s1 = peg$c152(s1, s2, s3);
                        s0 = s1;
                    } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                    }
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }
            } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
            }

            return s0;
        }

        function peg$parseand_operator() {
            var s0, s1, s2, s3, s4;

            peg$silentFails++;
            s0 = peg$currPos;
            s1 = peg$currPos;
            if (peg$c39.test(input.charAt(peg$currPos))) {
                s2 = input.charAt(peg$currPos);
                peg$currPos++;
            } else {
                s2 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c40); }
            }
            if (s2 !== peg$FAILED) {
                if (peg$c41.test(input.charAt(peg$currPos))) {
                    s3 = input.charAt(peg$currPos);
                    peg$currPos++;
                } else {
                    s3 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c42); }
                }
                if (s3 !== peg$FAILED) {
                    if (peg$c43.test(input.charAt(peg$currPos))) {
                        s4 = input.charAt(peg$currPos);
                        peg$currPos++;
                    } else {
                        s4 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c44); }
                    }
                    if (s4 !== peg$FAILED) {
                        s2 = [s2, s3, s4];
                        s1 = s2;
                    } else {
                        peg$currPos = s1;
                        s1 = peg$FAILED;
                    }
                } else {
                    peg$currPos = s1;
                    s1 = peg$FAILED;
                }
            } else {
                peg$currPos = s1;
                s1 = peg$FAILED;
            }
            if (s1 !== peg$FAILED) {
                peg$savedPos = s0;
                s1 = peg$c154(s1);
            }
            s0 = s1;
            if (s0 === peg$FAILED) {
                s0 = peg$currPos;
                if (input.substr(peg$currPos, 2) === peg$c155) {
                    s1 = peg$c155;
                    peg$currPos += 2;
                } else {
                    s1 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c156); }
                }
                if (s1 !== peg$FAILED) {
                    peg$savedPos = s0;
                    s1 = peg$c157();
                }
                s0 = s1;
            }
            peg$silentFails--;
            if (s0 === peg$FAILED) {
                s1 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c153); }
            }

            return s0;
        }

        function peg$parseor_operator() {
            var s0, s1, s2, s3;

            peg$silentFails++;
            s0 = peg$currPos;
            s1 = peg$currPos;
            if (peg$c46.test(input.charAt(peg$currPos))) {
                s2 = input.charAt(peg$currPos);
                peg$currPos++;
            } else {
                s2 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c47); }
            }
            if (s2 !== peg$FAILED) {
                if (peg$c48.test(input.charAt(peg$currPos))) {
                    s3 = input.charAt(peg$currPos);
                    peg$currPos++;
                } else {
                    s3 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c49); }
                }
                if (s3 !== peg$FAILED) {
                    s2 = [s2, s3];
                    s1 = s2;
                } else {
                    peg$currPos = s1;
                    s1 = peg$FAILED;
                }
            } else {
                peg$currPos = s1;
                s1 = peg$FAILED;
            }
            if (s1 !== peg$FAILED) {
                peg$savedPos = s0;
                s1 = peg$c159(s1);
            }
            s0 = s1;
            if (s0 === peg$FAILED) {
                s0 = peg$currPos;
                if (input.substr(peg$currPos, 2) === peg$c160) {
                    s1 = peg$c160;
                    peg$currPos += 2;
                } else {
                    s1 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c161); }
                }
                if (s1 !== peg$FAILED) {
                    peg$savedPos = s0;
                    s1 = peg$c162();
                }
                s0 = s1;
            }
            peg$silentFails--;
            if (s0 === peg$FAILED) {
                s1 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c158); }
            }

            return s0;
        }

        function peg$parsenot_operator() {
            var s0, s1, s2, s3, s4;

            peg$silentFails++;
            s0 = peg$currPos;
            s1 = peg$currPos;
            if (peg$c41.test(input.charAt(peg$currPos))) {
                s2 = input.charAt(peg$currPos);
                peg$currPos++;
            } else {
                s2 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c42); }
            }
            if (s2 !== peg$FAILED) {
                if (peg$c46.test(input.charAt(peg$currPos))) {
                    s3 = input.charAt(peg$currPos);
                    peg$currPos++;
                } else {
                    s3 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c47); }
                }
                if (s3 !== peg$FAILED) {
                    if (peg$c51.test(input.charAt(peg$currPos))) {
                        s4 = input.charAt(peg$currPos);
                        peg$currPos++;
                    } else {
                        s4 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c52); }
                    }
                    if (s4 !== peg$FAILED) {
                        s2 = [s2, s3, s4];
                        s1 = s2;
                    } else {
                        peg$currPos = s1;
                        s1 = peg$FAILED;
                    }
                } else {
                    peg$currPos = s1;
                    s1 = peg$FAILED;
                }
            } else {
                peg$currPos = s1;
                s1 = peg$FAILED;
            }
            if (s1 !== peg$FAILED) {
                peg$savedPos = s0;
                s1 = peg$c164(s1);
            }
            s0 = s1;
            peg$silentFails--;
            if (s0 === peg$FAILED) {
                s1 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c163); }
            }

            return s0;
        }

        function peg$parseprefix_operator_exp() {
            var s0, s1;

            peg$silentFails++;
            s0 = peg$currPos;
            s1 = peg$parseprefix_operator();
            if (s1 !== peg$FAILED) {
                peg$savedPos = s0;
                s1 = peg$c166(s1);
            }
            s0 = s1;
            peg$silentFails--;
            if (s0 === peg$FAILED) {
                s1 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c165); }
            }

            return s0;
        }

        function peg$parseleft_paren() {
            var s0, s1;

            peg$silentFails++;
            s0 = peg$currPos;
            if (input.charCodeAt(peg$currPos) === 40) {
                s1 = peg$c168;
                peg$currPos++;
            } else {
                s1 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c169); }
            }
            if (s1 !== peg$FAILED) {
                peg$savedPos = s0;
                s1 = peg$c170();
            }
            s0 = s1;
            peg$silentFails--;
            if (s0 === peg$FAILED) {
                s1 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c167); }
            }

            return s0;
        }

        function peg$parseright_paren() {
            var s0, s1;

            peg$silentFails++;
            s0 = peg$currPos;
            if (input.charCodeAt(peg$currPos) === 41) {
                s1 = peg$c172;
                peg$currPos++;
            } else {
                s1 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c173); }
            }
            if (s1 !== peg$FAILED) {
                peg$savedPos = s0;
                s1 = peg$c174();
            }
            s0 = s1;
            peg$silentFails--;
            if (s0 === peg$FAILED) {
                s1 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c171); }
            }

            return s0;
        }

        function peg$parsestem_operator() {
            var s0, s1;

            peg$silentFails++;
            if (input.charCodeAt(peg$currPos) === 33) {
                s0 = peg$c176;
                peg$currPos++;
            } else {
                s0 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c177); }
            }
            if (s0 === peg$FAILED) {
                if (input.charCodeAt(peg$currPos) === 35) {
                    s0 = peg$c178;
                    peg$currPos++;
                } else {
                    s0 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c179); }
                }
            }
            peg$silentFails--;
            if (s0 === peg$FAILED) {
                s1 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c175); }
            }

            return s0;
        }

        function peg$parseprefix_operator() {
            var s0, s1;

            peg$silentFails++;
            if (input.charCodeAt(peg$currPos) === 43) {
                s0 = peg$c181;
                peg$currPos++;
            } else {
                s0 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c182); }
            }
            if (s0 === peg$FAILED) {
                if (input.charCodeAt(peg$currPos) === 45) {
                    s0 = peg$c183;
                    peg$currPos++;
                } else {
                    s0 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c184); }
                }
            }
            peg$silentFails--;
            if (s0 === peg$FAILED) {
                s1 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c180); }
            }

            return s0;
        }

        function peg$parsefield_list_operator() {
            var s0, s1;

            peg$silentFails++;
            if (input.charCodeAt(peg$currPos) === 63) {
                s0 = peg$c186;
                peg$currPos++;
            } else {
                s0 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c187); }
            }
            peg$silentFails--;
            if (s0 === peg$FAILED) {
                s1 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c185); }
            }

            return s0;
        }

        function peg$parsedecoration_separator() {
            var s0, s1;

            peg$silentFails++;
            if (input.charCodeAt(peg$currPos) === 8621) {
                s0 = peg$c188;
                peg$currPos++;
            } else {
                s0 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c189); }
            }
            peg$silentFails--;
            if (s0 === peg$FAILED) {
                s1 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c84); }
            }

            return s0;
        }

        function peg$parsefieldname_terminator() {
            var s0;

            if (input.charCodeAt(peg$currPos) === 58) {
                s0 = peg$c190;
                peg$currPos++;
            } else {
                s0 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c191); }
            }

            return s0;
        }

        function peg$parse_() {
            var s0, s1, s2;

            peg$silentFails++;
            s0 = peg$currPos;
            s1 = [];
            if (peg$c193.test(input.charAt(peg$currPos))) {
                s2 = input.charAt(peg$currPos);
                peg$currPos++;
            } else {
                s2 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c194); }
            }
            if (s2 !== peg$FAILED) {
                while (s2 !== peg$FAILED) {
                    s1.push(s2);
                    if (peg$c193.test(input.charAt(peg$currPos))) {
                        s2 = input.charAt(peg$currPos);
                        peg$currPos++;
                    } else {
                        s2 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c194); }
                    }
                }
            } else {
                s1 = peg$FAILED;
            }
            if (s1 !== peg$FAILED) {
                peg$savedPos = s0;
                s1 = peg$c195(s1);
            }
            s0 = s1;
            peg$silentFails--;
            if (s0 === peg$FAILED) {
                s1 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c192); }
            }

            return s0;
        }

        function peg$parseEOF() {
            var s0, s1;

            peg$silentFails++;
            s0 = peg$currPos;
            peg$silentFails++;
            if (input.length > peg$currPos) {
                s1 = input.charAt(peg$currPos);
                peg$currPos++;
            } else {
                s1 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c197); }
            }
            peg$silentFails--;
            if (s1 === peg$FAILED) {
                s0 = void 0;
            } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
            }
            peg$silentFails--;
            if (s0 === peg$FAILED) {
                s1 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c196); }
            }

            return s0;
        }


        function getBegPos() { return peg$reportedPos; }
        function getEndPos() { return peg$currPos; }
        function appendPos(obj) {
            //      if (!obj)
            //          return;
            //      if ( !('renderTally' in obj) )
            //          obj [ 'renderTally' ] = 1;
            //      if ('renderBegPos' in obj) {
            //          var suffix = obj['renderTally'];
            //          obj[ 'renderBegPos' + suffix ] = getBegPos();
            //          obj[ 'renderEndPos' + suffix ] = getEndPos();
            //          obj[ 'renderRawTxt' + suffix ] = text();
            //          return obj;
            //      }
            //      obj[ 'renderBegPos' ] = getBegPos();
            //      obj[ 'renderEndPos' ] = getEndPos();
            //      obj[ 'renderRawTxt' ] = text();
            return obj;
        }

        function annotate(obj, notation) {
            if (!obj) return;
            if ('notation' in obj)
                obj['notation'] += notation;
            else
                obj['notation'] = notation;
        }

        function createPosObj(tag, value) {
            var result = value;
            if (typeof value == 'string' || typeof value == 'number') {
                result = {};
                if (tag == "whitespace") {
                    result[tag] = '';
                } else {
                    result[tag] = value;
                }

                //         result ['renderTally'] = 0;
            }
            //      result ['renderTally']++;

            //      appendPos(result);

            return result;
        }

        function mergeIn(obj, props) {
            if (!props) {
                return;
            }
            if (!obj) {
                return;
            }
            for (var prop in props) {
                if (typeof props[prop] !== 'function') {
                    obj[prop] = props[prop];
                }
            }
        }

        function debugNode(node, tag, indent) {
            if (!indent)
                indent = "";
            for (prop in node) {
                if (node[prop] != null && typeof node[prop] == 'object')
                    debugNode(node[prop], prop, indent + '___');
            }
        }

        function dump(a, b) {
            console.dir(b);
        }


        peg$result = peg$startRuleFunction();

        if (peg$result !== peg$FAILED && peg$currPos === input.length) {
            return peg$result;
        } else {
            if (peg$result !== peg$FAILED && peg$currPos < input.length) {
                peg$fail({ type: "end", description: "end of input" });
            }

            throw peg$buildException(
                null,
                peg$maxFailExpected,
                peg$maxFailPos < input.length ? input.charAt(peg$maxFailPos) : null,
                peg$maxFailPos < input.length
                    ? peg$computeLocation(peg$maxFailPos, peg$maxFailPos + 1)
                    : peg$computeLocation(peg$maxFailPos, peg$maxFailPos)
            );
        }
    }

    return {
        SyntaxError: peg$SyntaxError,
        parse: peg$parse
    };
});
  // });