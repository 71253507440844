import React, { useState, useEffect } from "react";
import {useParams, Link} from 'react-router-dom';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import Button from "@material-ui/core/Button";
import DiscoverService from "../../../services/discoverService";
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import {Col} from "react-bootstrap";

function ResultReportAnalysis(props){

    const {resid} = useParams();
    const [showDex, setShowDex] = useState('');

    let [sideMenuDetail, setSideMenuDetail] = useState({});
    const useStyles = makeStyles((theme) => ({}));
    const classes = useStyles();

    //edit and delete
    const [openEditUI, setOpenEditUI] = useState(false);
    const [openDelUI, setOpenDelUI] = useState(false);

    const [userNamedReport, setUserNamedReport] = useState("");
    const [oldName, setOldName] = useState("");
    const [deleteReportName, setDeleteReportName] = useState("");
    const [reportNames, setReportNames] = useState([]);

    const {reportFilters, apiKey, userDir} = props;

    const toggleSideMenuDetail = (sideMenuItem) => {
        if (sideMenuDetail) {// Wait for the initialization
            let val = (sideMenuDetail[sideMenuItem] ? false : true);
            sideMenuDetail[sideMenuItem] = val;
            setSideMenuDetail({...sideMenuDetail});
        }
    }

    const Accordion = withStyles({
        root: {
            boxShadow: 'none',
            '&:not(:last-child)': {
                borderBottom: 0,
            },
            '&:before': {
                display: 'none',
            },
            '&$expanded': {
                margin: 'auto',
            },
        },
        expanded: {},
    })(MuiAccordion);

    const AccordionSummary = withStyles({
        root: {
            minHeight: 3,
            width: '102%',
            margin: 0,
            '&$expanded': {
                minHeight: 3,
                margin: 0,
            },
        },
        content: {
            margin: '0',
            padding: '0',
            '&$expanded': {
                margin: '0',
            },
        },
        expanded: {},
    })(MuiAccordionSummary);

    const AccordionDetails = withStyles((theme) => ({
        root: {
            padding: '0 0 0 5px',
            margin: '0',
            display: 'block'
        },
    }))(MuiAccordionDetails);

    const changeOpenEditState = () =>{
        setOpenEditUI(!openEditUI);
    }

    function saveReportName(event) {
        if(event != null){
            if(event["target"]["value"] != null){
                setUserNamedReport(event["target"]["value"]);
            }
        }
    }

    async function getReports() {
        let data = {
            workflowId: resid.match(/\d+/)[0],
            userId: userDir
        }
        let response = await DiscoverService.getReportNames(data, apiKey);
        if (response) {
            setReportNames(response.reportNames);
        }
    }

    async function updateResultReport() {
        let data = {
            workflowId: resid.match(/\d+/)[0],
            userId: userDir,
            newReportName: userNamedReport,
            reportName: oldName,
        }
        await DiscoverService.updateResultReport(data, apiKey);
        getReports();
        setOpenEditUI(false);
    }

    async function removeResultReport() {
        let data = {
            workflowId: resid.match(/\d+/)[0],
            userId: userDir,
            reportName: deleteReportName
        }
        await DiscoverService.removeResultReport(data, apiKey);
        getReports();
        setOpenDelUI(false);
    }

    useEffect( async() => {
        localStorage.setItem(resid.match(/\d+/)[0] + '_reportFilters', JSON.stringify(reportFilters));
        if (reportNames.length === 0) {
            getReports();
        }
        setSideMenuDetail(props.sideMenuDetail);
    }, [reportFilters]);

    return (
        <div>
            <Accordion square expanded={sideMenuDetail['result_reports']} onChange={() => toggleSideMenuDetail('result_reports')}>
                <AccordionSummary aria-controls="panel-ab" className="subMenuHeading p-0">
                    <div>
                        {sideMenuDetail['result_reports']===true && <ArrowDropDownIcon className={classes.arrowIcon} />}
                        {sideMenuDetail['result_reports']!==true && <ArrowRightIcon className={classes.arrowIcon} />}
                        <span>Result Analysis</span>
                    </div>
                </AccordionSummary>
                <AccordionDetails className="appTextColor" style={{paddingLeft: '0px'}}>
                    <Col style={{paddingLeft: '0px'}}>
                    <Accordion square expanded={sideMenuDetail['result_report']} onChange={() => toggleSideMenuDetail('result_report')}>
                        <AccordionSummary aria-controls="panel-abReports" className="subMenuHeading p-0">
                            <div class="side-menu-item sidebar-tour-width" >
                                <p className="subMenuHeading m-0">
                                    {sideMenuDetail['result_report']===true && <ArrowDropDownIcon className={classes.arrowIcon} />}
                                    {sideMenuDetail['result_report']!==true && <ArrowRightIcon className={classes.arrowIcon} />}
                                    Result Report&nbsp;
                                    <Link target="_blank" to={{pathname: '/resultreport/'+resid.match(/\d+/)[0]}}>Create</Link>
                                </p>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails className="appTextColor">
                            {reportNames.length > 0 && reportNames.map((reportName, index) =>{

                                return(
                                    <Col style={{paddingLeft: '15px'}}>
                                    <div class="side-menu-item filter-no-wrap" key={index}
                                         onMouseOver={() => {setShowDex(reportName)}}
                                         onMouseLeave={() => {setShowDex('')}}
                                    >

                                        <span className={'custom-filter-label'}>{reportName}&nbsp;&nbsp;</span>

                                        {showDex === reportName &&
                                            <span>
                                            <Link
                                                target="_blank"
                                                to={{
                                                    pathname: '/resultreport/'+resid.match(/\d+/)[0]+"/"+encodeURIComponent(reportName),
                                                    state: {}
                                                }}>View</Link>

                                                {reportName !== "Unfiltered_Entire_Report" &&
                                                    <span>
                                                        <span> | </span>
                                                        <a onClick={()=>{setOldName(reportName); changeOpenEditState();}}>edit</a>
                                                        <span> | </span>
                                                        <a onClick={()=>{setDeleteReportName(reportName); setOpenDelUI(!openDelUI);}}>del</a>
                                                    </span>
                                                }

                                        </span>
                                        }
                                    </div></Col>
                                )
                            })}
                        </AccordionDetails>
                    </Accordion>
                    </Col>
                </AccordionDetails>
            </Accordion>

            {openEditUI == true &&
                <div>
                    <div className="saveAbPopup">
                        <div>
                            <Link href="#" className={"p-2 appTextColor"}><CloseIcon onClick={changeOpenEditState} /></Link>
                        </div>
                        <div className="ABjumbotron abHeight155">
                            <br></br>
                            <span><strong>Edit Result Report Name</strong></span>
                            <br></br>
                            <input
                                id="reportName"
                                onChange={(event)=>saveReportName(event)}
                                class="abInput"
                                type="text"
                                width={"100%"}
                                placeholder="Name your Report"
                                autoComplete="off" spellCheck="false"
                            >
                            </input>

                            <div className="ABSaveBuffer">
                                <Button className={"cancelButtonClass "} onClick={()=>{changeOpenEditState()}}>Cancel</Button>
                                <span className="ml-1"></span>
                                {userNamedReport === '' &&
                                <Button 
                                    className={"disableButtonClass"} onClick={()=>{updateResultReport()}}  disabled={!userNamedReport.trim()}> Save </Button>
                                }
                                {userNamedReport !== '' &&
                                <Button 
                                    className={"submitButtonClass"} onClick={()=>{updateResultReport()}}> Save </Button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            }
            {openDelUI == true &&
                <div>
                    <div className="saveAbPopup">
                        <div>
                            <Link href="#" className={"p-2 appTextColor"}><CloseIcon onClick={()=>{setOpenDelUI(!openDelUI)}} /></Link>
                        </div>
                        <div className="ABjumbotron alignCenter App abHeight140" width={"400px"}>
                            <br></br>
                            <span className="abDelPopupHeader" width={"100%"}>Are you sure you want to delete the report?</span>
                            <br></br>
                            <div class="paddingTop15pxFix">
                                <Button className={"cancelButtonClass "} onClick={()=>{setOpenDelUI(!openDelUI)}}>Cancel</Button>
                                <span className="ml-1"></span>
                                <Button className={"submitButtonClass"} onClick={()=>{removeResultReport()}}>Yes</Button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}

export default ResultReportAnalysis;
