import React, { memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1,
        width: '96%',
    },
    tableCSS: {
        display: 'flex',
        padding: '9px 265px 20px 21px',
    },
    table:{
        borderBottom: '1px solid #ccc',
        borderLeft: '1px solid #ccc',
        borderRight: '1px solid #ccc',
        borderTop: '1px solid #ccc',
        width: '100%',
        background: 'white'
    }
}));

const AlertWorkflow = memo((props) => {
    const classes = useStyles();
    const alertWorkflowDetails = props.alertWorkflowResult[props.baseWorkflowID] || [];

    console.log('alertWorkflowDetails: ',alertWorkflowDetails);

    function openLinkInNewTab(baseWfId) {
        const workflowUrl = "#/searchresseq/" + baseWfId;
        const newWindow = window.open(workflowUrl, '_blank');
        newWindow.window.focus();
    }
    return (
        <div className={classes.grow}>
            <div className={classes.tableCSS}>

                <table className = {classes.table}>
                    <thead className='resulttable_head'>
                        <tr>
                            <th className='columnLink' style={{ width: '32%', borderLeft: '1px solid white' }}>
                                Title
                            </th>
                            <th className='columnLink' style={{ width: '24%', borderLeft: '1px solid white' }}>
                                Number of Results
                            </th>
                            <th className='columnLink' style={{ width: '24%', borderLeft: '1px solid white' }}>
                                Submit Time
                            </th>
                            <th className='columnLink' style={{ width: '24%', borderLeft: '1px solid white' }}>
                                Status
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {alertWorkflowDetails.map((workflow, index) => (
                            <tr key={index} style={{borderTop: '1px solid #dcd7c0'}}>
                                <td>
                                    <a style={{ padding: '3px 0px 0px 3px' }} href={workflow.link} onClick={(e) => {
                                        e.preventDefault();
                                        openLinkInNewTab(workflow.id);
                                    }}>
                                        {workflow.text_label}
                                    </a>
                                </td>
                                <td>{workflow.total_nb_results}</td>
                                <td>{workflow.submit_time}</td>
                                <td>{workflow.status}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
});
export default AlertWorkflow;