import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
import AccountInfo from "./AccountInfo";
import ManageTeams from "./ManageTeams";
import Alerts from "./Alerts"


function AccountSettings(){

    const Tabs = ["Info", "Teams", "Alerts"]
    const [state, setState] = useState("Info");
    
    let location = useParams();

    useEffect(() =>{
    },[])

    function switchPage(pageName){
        setState(pageName);
    }

    return(
        <div>
            <div style= {{ marginTop: '-33px', marginLeft: '-5px' }}>
                <button className={state === "Info" ? "inactiveTab" : "accountTabs"} onClick={()=>switchPage("Info")}> My Account</button>
                <button className={state === "Teams" ? "inactiveTab" : "accountTabs"}  onClick={()=>switchPage("Teams")}>Manage Teams</button>
                <button className={state === "Alerts" ? "inactiveTab" : "accountTabs"}  onClick={()=>switchPage("Alerts")}>Alerts</button>
            </div>

            {state == "Info" &&
            <div>
                <AccountInfo></AccountInfo>
            </div>
            }
            {state == "Teams" &&
            <div>
                <ManageTeams></ManageTeams>
            </div>
            }
            {state == "Alerts" &&
            <div>
                <Alerts></Alerts>
            </div>
            }
        </div>
    );

}

export default AccountSettings;
