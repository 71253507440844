import React, { useState, useEffect } from "react";

import centerCircle from "../tableImages/centerCircle.png";

import greySquare from "../tableImages/greySquare.png";
import hcSquare from "../tableImages/hcSquare.png";
import lcSquare from "../tableImages/lcSquare.png";
import { Link } from 'react-router-dom';
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

//import "../TableStyles.css"


function DocMatchAll(props){

    const {resid, reportName} = useParams();
    const {queryAliDocStats, matchingDocumentsAll, collName} = props;

    const angles = [0, 45, 90, 135, 180, 225, 270, 315];

    const [colorArray, setColorArray] = useState([false, false, false, false, false, false, false, false]);
    const [thisData, setThisData] = useState({});

    function fillColors(obj) {

        var colorGrey = [];

        let arr = Object.keys(obj);

        if (arr.includes("lc")) {
            colorGrey[0]=true;
        }else{
            colorGrey[0]=false;
        }

        if (arr.includes("lc_cdr1")) {
            colorGrey[1]=true;
        }else{
            colorGrey[1]=false;
        }

        if (arr.includes("lc_cdr2")) {
            colorGrey[2]=true;
        }else{
            colorGrey[2]=false;
        }

        if (arr.includes("lc_cdr3")) {
            colorGrey[3]=true;
        }else{
            colorGrey[3]=false;
        }

        if (arr.includes("hc")) {
            colorGrey[4]=true;
        }else{
            colorGrey[4]=false;
        }

        if (arr.includes("hc_cdr1")) {
            colorGrey[5]=true;
        }else{
            colorGrey[5]=false;
        }

        if (arr.includes("hc_cdr2")) {
            colorGrey[6]=true;
        }else{
            colorGrey[6]=false;
        }

        if (arr.includes("hc_cdr2")) {
            colorGrey[7]=true;
        }else{
            colorGrey[7]=false;
        }

        setColorArray(colorGrey);

    }
    
    function getMatchAllPic(degree){

        let val = degree/45;

        if(!colorArray[val])
        {
            return greySquare;
        }

        if( degree>=180) {
            return hcSquare;
        }else {
            return lcSquare;
        }
    }

    useEffect(() =>{
        
        if(Object.keys(queryAliDocStats).length > 0){
            fillColors(queryAliDocStats);
        }

        var newdata = {}
        newdata["matchingDocuments"] = fixStrings(Object.keys(queryAliDocStats));
        newdata["groupBy"]= 'document';
        newdata["reportName"] = decodeURIComponent(reportName) == "undefined"? "Unfiltered_Entire_Report": decodeURIComponent(reportName)

        setThisData(newdata)
        
    },[queryAliDocStats])

    function fixStrings(totalQueries){
        for(var i=0; i< totalQueries.length; i++){
            totalQueries[i] = totalQueries[i].toUpperCase();
            totalQueries[i] = totalQueries[i].replaceAll('-','_');
        }
        return totalQueries;
    }


	return (
        <div class="tableFrame">
            <p class="pCenter textFlat paddingTitle"><strong>DOCUMENTS</strong> matching all CDRs and chains</p>
            <table class="tableMatchAll">
              
                <td id="imgMatchAllContainer" class="imgMatchAllContainer" height={"160px"}>
                    
                    {angles && angles.map((value, index) => {
                        return(
                            <img key={index} className={"imgMatchAll"+value} src={getMatchAllPic(value)}></img>
                        )
                    })
                    }

                    <img class="centerCircle" src={centerCircle}></img>
                    {/* <a className={matchingDocumentsAll == 0? "centerText disabled":"centerText"}  onClick={()=>{applyMatchingPNs(Object.keys(queryAliDocStats))}}>{matchingDocumentsAll}</a> */}
                    
                    <Link 
                    className={matchingDocumentsAll == 0? "centerText disabled":"centerText"}
                    target="blank"
                    to={{
                        pathname: '/discover/wf:'+resid+".resdb/antibody/"+encodeURIComponent(JSON.stringify(thisData)) + "/" + collName,
                    }}>{matchingDocumentsAll}</Link>
                    
                    
                </td>
            </table>

        <p class="bottomText pCenter">The number of patent documents matching all CDR and chain sequences. Click the numbers to see the results.</p>

        </div>
    );
}

export default DocMatchAll;
