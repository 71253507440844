export const url = {
    login: "do=gquser.login&format=json",
    searchResult: "do=mygq.get_welcome_page_v2&format=json",
    searchResultStatus: "do=gqworkflow.get_status&format=json&key=progress",
    news: "do=mygq.get_news_bullet&format=json",
    seqSearchInit: "do=gqft.launch_seq_search&format=json",
    seqSaveTemplate: "do=gqworkflow.save_ft_template&workflow_type=GqWfIpSearch&view_helper=ObjectViewer::full&format=json",
    seqSearchSubmit: "do=gqworkflow.submit_seq_search&workflow_type=GqWfIpSearch&view_helper=ObjectViewer::full&format=json",
    mostRecentTypeUrl: "query?do=gqresult2.redirect_nrb&db=wf:**.resdb", //resultsummNew/index.html#/resbrowse/wf:**.resdb/1
    mostRecentClassicUrl: "do=gqresult&db=wf:**.resdb&is_workspace_recognized=1&def_view=query",
    mostRecentClassicBlastUrl: "do=gqworkflow.show_result&workflow=id:**",
    mostRecentReportUrl: "fulltext/index.html#/antibody/**/Unfiltered_Entire_Report", //"resultsummNew/index.html#/antibody/overview/**",
    // mostRecentIPReportUrl: "fulltext/index.html#/antibody/**/Unfiltered_Entire_Report",
    progress: "do=gqworkflow.get_status&workflow=id:**&format=json",
    accountInfo: "do=gquser.get_info&format=json",
    getprojectFolder: "do=gqfolder.get_home_folder&format=json",
    projectFolderDetail: "do=gqfolder.get_info&format=json&form=dojo&depth=4&id=**",
    projectFolderData: "do=gqfolder.get_elements_v2&id=**&format=json",
    deleteSearchResult: "do=gqshareable.delete&format=json&id=**&is_recursive=true",
    forgotPassword: "do=gquser.recover_password_V2",
    searchResPaging: "do=gqfolder.get_elements&id=:id:&sort=:ST:&start=:S1:&stop=:S2:&nocache=:NC:&format=json",
    logout: "do=gquser.logout&format=json",
    createFolder: "do=gqfolder.create_subfolder&format=json&id=**&text_label=",
    deleteFolder: "do=gqshareable.delete&format=json&is_recursive=true&id=**", // same as deleteItems
    moveToFolder: "do=gqfolder.move_shareable_to&format=json&sharing_setting=inherit_recursive&id=FID&shareable_id=WID",
    deleteItems: "do=gqshareable.delete&format=json&is_recursive=true&id=**",
    getServerInfo: "do=gqft.get_server_info&format=json",
    addFolder: "do=gqfolder.create_subfolder&format=json&id=PFID&text_label=FNAME",
    copyright: "do=gqft.get_copyright_info&format=json",
    antibodySearch: "do=gqworkflow&workflow_type=GqWfABIpSearch&format=json&view_helper=ObjectViewer::full",
    authInfoAB: "do=gqresult2.get_auth_info_4ab&format=json&db=wf:ab.resdb",
    authInfoSt26: "do=gqresult2.get_auth_info_st26&format=json",
    seqWorkflow: "do=gqworkflow.get_info_ft&format=json&workflow=id:**",
    mergeStrategyInfo: "do=gqworkflow.merge_strategy&format=json&wfId=**",
    seqShareInfo: "do=gqshareable.get_acl_form&id=**&format=json",

    seqAlertInfo: "do=gqworkflow.get_schedule_form&id=**&format=json",
    seqTechnicalData: "do=gqworkflow.get_log&workflow=id:**",
    getAlertRedos: "do=gqworkflow.get_related_workflows_ft&bid=**&format=json",
    updateSeqNotes: "do=gqworkflow.update_ft_desc&id=WID&format=json&description=**",
    downloadQuerySeq: "do=gqworkflow.show_result&workflow=id:**&workflow_output_name=queries.fasta",
    updateAlertSetting: "do=gqworkflow.update_schedule&format=json&activate=on&relaunch_interval=**&id=WID",
    removeAlert: "do=gqworkflow.delete_schedule&format=json&relaunch_interval=&id=WID",

    removeResultShare: "do=gqshareable.update_acl&format=json&id=WID&read_sharee_id=UID&is_read_recursive=1&write_sharee_id=UID&is_write_recursive=1&request.preventCache=TIMESTAMP",

    //patentNumberLookup:"https://stage.genomequestlive.com/query?do=gqfetch.field_search&field=PN",
    browserDBLink: "do=mygq#4",
    patentNumLink: "do=gqfetch.get_field_search_form",
    alignSequencesDirectly: "do=gqsearch.get_align_pair_form",
    searchResultSet: "do=gqshareable.qsearch&format=json&text=**",
    advSearchResultSet: "do=gqshareable.search&format=json&sort=-update_time&filter=**",
    userList: "do=gqshareable.get_acl_form&id=**&format=json",
    browseSeqDB: "do=gqfetch&db=id:**",
    browseVSeqDB: "do=gqvfetch&db=id:**",
    getPageCount: "do=gquser.get_paging_size",
    updateSeqData: "do=gqworkflow.update_ft_desc&id=WID&format=json",
    fullTextQueryHistory: "do=gqft.get_ft_history&format=json",
    fullTextDeleteHistory: "do=gqft.delete_ft_history&format=json",
    fullTextSearchTerm: "do=gqft.suggest_term&format=json",
    fullTextSearchResult: "do=gqft.search_by_text&format=json",
    getSearchCount: "do=gqfolder.get_folder_info&format=json",
    mergeResults: "do=gqworkflow.merge_results&format=json",
    fullDocView: "do=gqft.get_patent_doc&format=json",
    fullTextQuery: "do=gqft.get_ft_query&format=json&id=**",
    shareableList: "do=gqAccessFt.shareable_list&id=:id:&format=json",
    addAccess: "do=gqAccessFt.add_shared_item&id=:id:&usr=:usr:&acl=:acl:&format=json",
    removeAccess: "do=gqAccessFt.remove_shared_item&id=:id:&usr=:usr:&format=json",
    removeAll: "do=gqAccessFt.remove_all&id=:id:&format=json",
    sharedWith: "do=gqAccessFt.get_shared_with&id=:id:&format=json",
    itemsSharedWithMe: "do=gqAccessFt.shared_with_me&format=json", //based on folder
    foldersSharedWithMe: "do=gqAccessFt.folders_shared_with_me&format=json",
    autoqueryfindterm: "do=gqft.find_term&format=json&term_id=**",
    //updateAutoQueryTerm: "json_query=:searchTerm&format=json&do=gqft.update_term&user_id=:UID&term_id=:TERMID",
    saveFTDocument: "do=gqft.save_ftdoc&workflow_type=GqWfSearchFT&format=json",
    moveSelection: "do=gqfolder.get_move_selection&id=:ids:&format=json",
    cancelWorkflow: "do=gqworkflow.cancel&format=json&workflow=id:**",
    personalDBs: "do=gqft.get_personal_dbs&format=json",
    personalDB: "do=gqft.get_personal_db&id=**&format=json",
    updateDbDesc: "do=gqft.update_personal_db&id=**&format=json",
    adminPanel: "do=gqrender&format=smarty:admin/admin_manager.tpl",
    getVmReport: "do=gqft.get_vm_report",

    queryNoHits: "do=gqworkflow.show_result&workflow=id:**&workflow_output_name=querywithnohit.fasta",
    queryWithHits: "do=gqworkflow.show_result&workflow=id:**&workflow_output_name=querywithhits.fasta",
    sequenceManagement: "do=gqipapplication.default",
    manageTeams: "do=gqteams.fetch&itemtype=team&itemlabel=team&dispatcher=gqteam&format=json",
    createManageTeams: "do=gqteam.get_create_form&format=json",
    deleteManageTeams:"do=gqteam.delete&format=json&id=**&is_recursive=true",
    newManageTeamData: "do=gqteam.create&format=json&text_label=**&description=$$&member_id=%%",
    updateManageTeamData: "do=gqteam.update&format=json&text_label=**&description=$$&member_id=%%",
    alertsData: "do=gqalerts.fetch&itemtype=alert&itemlabel=alert&dispatcher=gqalerts&format=json",
    deleteAlerts: "do=gqalerts.delete&format=json&id=**&is_recursive=true",
    alertWorkflow: "do=gqworkflow.get_related_workflows&bid=**&format=json",
    gqTeamInfo: "do=gqteam.get_info&id=**&format=json",
    gqUpdateTeamInfo: "do=gqteam.get_update_form&id=**&format=json",
    editManageTeamData: "do=gqteam.update&format=json&text_label=**&description=$$&id=##&member_id=%%",
    getDirectShareables: "do=gqteam.get_direct_shareables&id=**&format=json",
    manageTeamsSearch: 'do=gqteams.fetch&itemtype=team&itemlabel=team&dispatcher=gqteam&format=json&q=**',
    patentSearchResult: 'do=gqfetch.field_search&include_pm=**&format=json',
    allPatentSearchResult: 'do=gqfetch.field_search',
    allPatentsLink: 'do=gqfetch.browse_patents',
    allNucleotidePatentsLink: 'do=gqfetch.browse_patents&seq_type=NUC',
    allProteinPatentsLink:'do=gqfetch.browse_patents&seq_type=PRO',
    nucProPatentDbList: 'do=gqfetch.get_field_search_form&format=json',
    //old matching patent number 
    patentNumberOldLink: 'do=gqfetch&filter=(filters:!((O:CT,P:SEQUENCE_PN,V:**)),matchtype:AND,ver:2)&db=##&masterview=',
    relatedPatentNumber: 'do=gqfetch&masterview=&filter=(filters:!((O:CT,P:SEQUENCE_PN,V:**)),matchtype:AND,ver:2)&db=##',
    pnFamilyId: 'do=gqfetch&masterview=&filter=(filters:!((O:CT,P:SEQUENCE_PB,V:**)),matchtype:AND,ver:2)&db=##',
    matchingPN: 'do=gqfetch&db=##&masterview=&filter=(filters:!((O:CT,P:SEQUENCE_PN,V:%27**%27)),matchtype:AND,ver:2)',
    matchingPNorRF: 'do=gqfetch&db=##&masterview=&filter=(filters:!((O:CT,P:SEQUENCE_PN,V:%27**%27),(O:CT,P:SEQUENCE_PM,V:%27$$%27)),matchtype:OR,ver:2)'
}
