import ArrowDownIcon from '../../../assets/image/sort-down-19g.png';
import React, { useState, useEffect } from 'react';

function SortArrowManager(props){

    const isAsc = props.asc;

    useEffect(() => {

    });

	return (
        <span className='ArrowSize'>
            { isAsc &&
            <img src={ArrowDownIcon} className="ArrowDown"/> 
            }
            { !isAsc &&
            <img src={ArrowDownIcon} className="ArrowUp"/> 
            } 
        </span>
	)
}

export default SortArrowManager;
